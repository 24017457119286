export class Snapshot {
    public snapshotId : number;
    public name : string;
    public date : string;
    public plan : string;
    public frequency : string;
    public storageBytes : any;
    public provisionId : number;
    public instanceId : number;
    public instanceName : string;
    public diskName : string;
    public isBootDisk : boolean;
    // tslint:disable-next-line:cyclomatic-complexity
    constructor(data?: any) {
        this.snapshotId = data && data.snapshotId ? data.snapshotId : 0;
        this.provisionId = data && data.provisionId ? data.provisionId : 0;
        this.instanceId = data && data.instanceId ? data.instanceId : 0;
        this.storageBytes = data && data.storageBytes ? data.storageBytes : 0;
        this.name = data && data.name ? data.name : '';
        this.date = data && data.date ? data.date : '';
        this.plan = data && data.plan ? data.plan : '';
        this.frequency = data && data.frequency ? data.frequency : '';
        this.instanceName = data && data.instanceName ? data.instanceName : '';
        this.diskName = data && data.diskName ? data.diskName : '';
        this.isBootDisk = data && data.isBootDisk ? data.isBootDisk : false;
    }
}
