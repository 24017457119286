export class Gpu {
    public id: number;
    public gpuName: string;
    public gpuDescription: string;
    public agent: string;
    public gpuList: any [];

    constructor(data?: any) {
        this.id = data && data.id ? data.id : 0;
        this.gpuName = data && data.name ? data.name : '';
        this.gpuDescription = data && data.description ? data.description : '';
        this.agent = data && data.agent ? data.agent : '';
        this.gpuList = data && data.configurations ? data.configurations : [];
    }
}
