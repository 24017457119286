import { Frequency } from '../enums';
import { IntervalDate } from './interval-date';

export class Schedule {
    public id: number;
    public description: string;
    public frequency: number;
    public intervals: IntervalDate[];

    constructor(data?: any) {
        this.id = data && data.id ? data.id : 0;
        this.description = data && data.description ? data.description : '';
        this.frequency = data && data.frequency ? data.frequency : Frequency.Weekly;
        this.intervals = [];
        if (data && data.intervals && data.intervals.length) {
            this.intervals = data.intervals.map(item => new IntervalDate(item));
        }
    }
}
