import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

import {SheduleFrequencyProfileEnum} from 'src/app/cloud-manager/patch-profile-manage/enums';

const number7 = 7;

@Pipe({
  name: 'scheduleUpComing'
})
export class ScheduleUpComingPipe implements PipeTransform {

  /**
   * next Schedule for period
   * @param value String to transform
  */
 public transform(date: string, frecuency: SheduleFrequencyProfileEnum, tz: string, lang?: string): string {
    moment.locale( lang ? lang : 'en');
    let updateTime = moment();
    if (frecuency === SheduleFrequencyProfileEnum.WEEK) {
          updateTime = this.getScheduleWeek(date);
    } else {
      if (frecuency === SheduleFrequencyProfileEnum.MONTH) {
        updateTime = this.getScheduleMonth(date);
      } else {
        if (frecuency === SheduleFrequencyProfileEnum.MONTH_DAY) {
          updateTime = this.getScheduleMonthDay(date);
        }
      }
    }
    return moment(updateTime).tz(tz).format('LLLL');
 }

 private getScheduleWeek(date: string): moment.Moment {
      const currentDate = moment(new Date());
      const day = new Date(date).getDay();
      let updateTime = this.getUpdateTime(date);
      if (currentDate.day() ===  day) {
         // is same day
        if ( !this.isDateSavedAfter(updateTime)) {
            updateTime = moment(updateTime).add(number7, 'days');
        }
      } else {
        if (currentDate.day() > day) {
           // day is passed
          const diference = currentDate.day() - day;
          const daysInc = number7 - diference;
          updateTime = moment(updateTime).add(daysInc, 'days');
        } else {
          // day is prox
          const diference =  day - currentDate.day();
          updateTime = moment(updateTime).add(diference, 'days');
        }
      }
      return updateTime;
 }

 private getScheduleMonth(date: string): moment.Moment {
    let updateTime = this.getUpdateTime(date, true);
    if ( !this.isDateSavedAfter(updateTime)) {
      updateTime = moment(updateTime).add(1, 'months');
    }
    return updateTime;
 }

 private getScheduleMonthDay(date: string): moment.Moment {
    let  updateTime = this.getCurrentDateMonthWeek(date);
    if ( !this.isDateSavedAfter(updateTime)) {
          const next = moment().add(1, 'month').startOf('month');
          updateTime = this.getCurrentDateMonthWeek(date, next);
    }
    return updateTime;
 }

 private getCurrentDateMonthWeek(date: string, dateFrom?: moment.Moment): moment.Moment {
     const d = new Date(date);
     const dayWeek = d.getDay();
     const dayMonth = d.getDate();
     const week = Math.ceil(dayMonth / number7);
     const numberWeek = week === 0 ? 1 : week;
     let days;

     const initialDate = !dateFrom ? moment().startOf('month') : dateFrom;
     initialDate.add(numberWeek - 1, 'weeks');
     if ( dayWeek < initialDate.days() ) {
          days = number7 - initialDate.days() + dayWeek;
     } else {
        days = dayWeek - initialDate.days();
     }
     initialDate.set({'hours': d.getHours(), 'minutes': d.getMinutes()});
     initialDate.add('days', days);
     return initialDate;
 }

 private getUpdateTime(date: string, dayMonth?: boolean): moment.Moment {
    const timeSaved =  moment(new Date());
    const d = new Date(date);
    timeSaved.set({'hours': d.getHours(), 'minutes': d.getMinutes()});
    if (dayMonth) {
      timeSaved.set({'date': d.getDate()});
    }
    return timeSaved;
 }

 private isDateSavedAfter(date: moment.Moment): boolean {
    const timeNow = moment(new Date());
    return (date > timeNow);
 }

}
