export class Volume {
    public name: string;
    public mountTarget: string;
    public size: string;
    public type: string;
    public region: string;
    public netAppLevelType: string;
    public policy: any[];
    constructor(data ? : any) {
        this.name = data && data.name ? data.name : '';
        this.mountTarget = data && data.mountTarget ? data.mountTarget : '';
        this.size = data && data.size ? data.size : '';
        this.type = data &&  data.type ?  data.type : '';
        this.region = data && data['region']['name'] ? data['region']['name'] : '';
        this.netAppLevelType = data && data['netAppLevelType']['name'] ? data['netAppLevelType']['name'] : '';
        this.policy = data && data['cvSnapshotPolicyModelList'] ? this.getPolicies( data['cvSnapshotPolicyModelList']) : [];
    }

    public getPolicies(policies): any[] {
        if (policies === null) {
            return [];
        }
        const policy = [];
        policies.forEach(element => {
            const months = element['monthDays'] && element['monthDays'].toString() !== '' ? element['monthDays'] + ', ' : '';
            const weeks = element['weekDays'] && element['weekDays'].toString() !== '' ? element['weekDays'] + ', ' : '';
            const hours = element['hour'] && element['hour'].toString() !== '' ? element['hour'] + ':' : '00:';
            const minutes = element['minutes'] && element['minutes'].toString() !== '' ? element['minutes'] : '00';
            policy.push(months + weeks + hours + minutes);
        });
        return policy;
    }
}


