import { AbstractControl, ValidationErrors } from '@angular/forms';
export const ip_ipRangeValidator = function (control: AbstractControl): ValidationErrors | null {
  const value: string = control.value || '';
  if(!value) {
    return null;
  }
  const ipFormat = new RegExp(/^(?!0\.0\.0\.0|255\.255\.255\.255)((((2([0-4][0-9]|5[0-5]))|1[0-9]{2}|[1-9]?[0-9])\.){3}(((2([0-4][0-9]|5[0-5]))|1[0-9]{2}|[1-9]?[0-9])))$/);
  const rangeRegex = new RegExp(/([1-9]|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])(\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])){3}\/\d+$/);
  if (ipFormat.test(value) === false && rangeRegex.test(value) === false) {
    return { pattern : 'Invalid format' };
  }
};
