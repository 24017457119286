import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { UiSwitchModule } from 'ngx-toggle-switch';

import { DirectivesModule } from 'src/app/core/shared/directives/directives.module';
import { AutoUnsubscribeModule } from 'src/app/shared/auto-unsubscribe/auto-unsubscribe.module';
import { CrudViewsLayoutModule } from 'src/app/shared/crud-views-layout/crud-views-layout.module';
import { DatatableModule } from 'src/app/shared/datatable/datatable.module';
import { FormButtonsModule } from 'src/app/shared/form-buttons/form-buttons.module';
import { SectionLayoutModule } from 'src/app/shared/section-layout/section-layout.module';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { ViewsLayoutModule } from 'src/app/shared/views-layout/views-layout.module';
import { BrandingComponent } from './branding.component';
import { ImageUploadComponent } from './img-upload/img-upload.component';

@NgModule({
    imports: [
        CommonModule, ViewsLayoutModule, SpinnerModule, SectionLayoutModule,
        FormsModule, ReactiveFormsModule, NgbModule, FormButtonsModule,
        UiSwitchModule, TranslateModule, CrudViewsLayoutModule,
        DatatableModule, AutoUnsubscribeModule, DirectivesModule
    ],
    declarations: [ BrandingComponent, ImageUploadComponent ],
    exports: [ BrandingComponent ]
})
export class BrandingModule {}
