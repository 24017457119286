import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs/Subject';
import {ModalService} from '../../core/shared/services/modal.service';

import { AutoUnsubscribeComponent } from '../auto-unsubscribe/auto-unsubscribe.component';

import { locale as english } from './i18n/warning-modal-autoscaling.en';
import { locale as spanish } from './i18n/warning-modal-autoscaling.es';

const forceUpdateDefaultDate = '0001-01-01T00:00:00Z';
const five = 5;

@Component({
  selector: 'app-warning-modal-autoscaling',
  templateUrl: './warning-modal-autoscaling.component.html',
  styleUrls: ['./warning-modal-autoscaling.component.scss']
})
export class WarningModalAutoscalingComponent extends AutoUnsubscribeComponent implements OnInit {
  @Input()
  public bodyMessage: string;
  @Input()
  public deploymentTimeZone: string;
  @Input()
  public collectionProcess: any[];

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  public onActionConfirmation = new EventEmitter<any>();

  public canCloseModal$ = new Subject<boolean>();
  public isInvalid: boolean;
  public isForceUpdate: boolean;
  public forceUpdateDate: Date;
  public actionButtons: string[];
  public parsedTimeZone: string;
  public minDate: Date;
  public actionPressed: boolean;

  public processToApplyText: string;
  public processForceUpdateText: string;
  public processNoForceUpdateText: string;
  public processNote: string;
  public processNoteTitle: string;
  public errorRequired: string;
  public isRequired: boolean;

  constructor( private modalService: ModalService, private translate: TranslateService) {
    super();
    const canCloseModalSubs = this.canCloseModal$.subscribe((data) => {
      if (data) {
        this.closeWarningModal();
      }
    });
    super.addSubscriptions(canCloseModalSubs);
    this.isForceUpdate = true;
    this.minDate = moment().add(five, 'm').toDate();
  }

  public ngOnInit() {
    setTimeout(() => {
     this.setTranslations();
    });
    this.parsedTimeZone = this.buildTimeZoneLabel(this.deploymentTimeZone);
  }

  public closeWarningModal(): void {
    this.modalService.dismissAll();
  }

  public actionConfirmation(isActionConfirmation: boolean): void {
    if (isActionConfirmation) {
      if (!this.forceUpdateDate && !this.isForceUpdate) {
        this.isRequired = true;
        return;
      }
      let forceUpdateStringDate = moment().tz(this.deploymentTimeZone).format();
      if (!this.isForceUpdate && this.forceUpdateDate) {
        forceUpdateStringDate = moment(this.forceUpdateDate).tz(this.deploymentTimeZone).format();
      }
      this.onActionConfirmation.emit(forceUpdateStringDate);
      this.actionPressed = true;
    } else {
      this.closeWarningModal();
    }
  }

  private setTranslations(): void {
    const browserLang = this.translate.getBrowserLang();
    const mapLangI18nFile = { es: spanish, en: english };
    if (!mapLangI18nFile[browserLang]) {
      this.translate.use('en');
      this.translate.setTranslation('en', mapLangI18nFile['en']);
    } else {
      this.translate.use(browserLang);
      this.translate.setTranslation(browserLang, mapLangI18nFile[browserLang]);
    }
    if (!this.bodyMessage) {
      this.bodyMessage = this.translate.instant('warning_modal_autoscaling.FORCE_UPDATE_NOTE');
    }
    this.actionButtons = [
      this.translate.instant('warning_modal_autoscaling.CANCEL'),
      this.translate.instant('warning_modal_autoscaling.SAVE'),
      this.translate.instant('warning_modal_autoscaling.SAVING')
    ];
    this.processToApplyText = this.translate.instant('warning_modal_autoscaling.PROCESS_TO_APPLY');
    this.processForceUpdateText = this.translate.instant('warning_modal_autoscaling.FORCE_UPDATE');
    this.processNoForceUpdateText = this.translate.instant('warning_modal_autoscaling.NO_FORCE_UPDATE');
    this.processNote = this.translate.instant('warning_modal_autoscaling.NOTE');
    this.processNoteTitle = this.translate.instant('warning_modal_autoscaling.NOTE_TITLE');
    this.errorRequired = this.translate.instant('warning_modal_autoscaling.ERROR_REQUIRED');
  }

  public buildTimeZoneLabel(tz: string) {
    const offset = moment.tz(Date.now(), tz).format('Z z');
    const humanizeTz = tz.replace('_', ' ');
    return `(${offset}) ${humanizeTz}`;
  }
}
