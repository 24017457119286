import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { UserIdleModule } from 'angular-user-idle';

import { UserInactivityComponent } from './user-inactivity.component';

@NgModule({
  imports: [CommonModule, TranslateModule, UserIdleModule],
  declarations: [UserInactivityComponent],
  exports: [UserInactivityComponent]
})
export class UserInactivityModule {}
