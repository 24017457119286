import { SoltenantGroup } from '../common';

export class Instance {
    public instanceId: number;
    public name: string;
    public description:  string;
    public googleOs: string;
    public publicIp: Array<string>;
    public internalIp:  Array<string>;
    public standalone:  boolean;
    public dnsName: string;
    public instanceType: string;
    public core: number;
    public memory: number;
    public zone: string;
    public zoneId: number;
    public region: string;
    public regionId: number;
    public gpuId: number;
    public gpuName: string;
    public gpuDescription: string;
    public image: string;
    public labels: [];
    public disks: [];
    public firewallRules: [];
    public serverCode: string;
    public serverType: string;
    public statusCode: string;
    public roles: [];
    public winOS: boolean;
    public zoneDescription: string;
    public settings: Object;
    public soleTenantGroup: SoltenantGroup;
    public allow: boolean;
    public resources: {};
    public additionalSettings: {allowFirewallPorts: any, image: string,
                                imageUrl: string, roles: any, settings: any, sourceImage: string, imageName: string};
    public subnetName: string;
    public isOnline: boolean;
    public status: string;
    public loadingStatus: boolean;
    public failedProcess: string;
    public errors: string;
    public imageIsValid: boolean;

    // tslint:disable-next-line: cyclomatic-complexity
    constructor(data?: any) {
        this.instanceId = data && data.instanceId ? data.instanceId : 0;
        this.name = data && data.name ? data.name : '';
        this.description = data && data.description ? data.description : '';
        this.googleOs = data && data.googleOs ? data.googleOs : '';
        this.standalone = !!(data && data.standalone);
        this.publicIp = data && data.publicIp && data.publicIp instanceof Array ? data.publicIp : [];
        this.internalIp = data && data.internalIp && data.internalIp instanceof Array ? data.internalIp : [];
        this.dnsName = data && data.dnsName ? data.dnsName : '';
        this.instanceType = data && data.resources && data.resources.instanceType ?
                            data.resources.instanceType : '';
        this.core = data && data.resources && data.resources.core ?
                    data.resources.core : '';
        this.memory = data && data.resources && data.resources.memory ?
                    data.resources.memory : '';
        this.gpuId = data &&  data.resources && data.resources.gpuId ? data.resources.gpuId : '';
        this.gpuName = data && data.resources && data.resources.gpuName ?
                       data.resources.gpuName : '';
        this.gpuDescription = data && data.resources && data.resources.gpuDescription ?
                              data.resources.gpuDescription : '';
        this.image = data && data.additionalSettings && data.additionalSettings.image ? data.additionalSettings.image : '';
        this.labels = data && data.labels ? data.labels : [];
        this.disks = data && data.disks ? data.disks : [];
        this.firewallRules = data && data.additionalSettings && data.additionalSettings.allowFirewallPorts ?
                             data.additionalSettings.allowFirewallPorts : [];
        this.region = data && data.region ? data.region : '';
        this.regionId = data && data.regionId ? data.regionId : '';
        const regionToZoneDesc = this.region === '' ? this.region : ' (' + this.region + ')';
        this.zoneDescription = data && data.zone ? data.zone + regionToZoneDesc : '';
        this.zone = data && data.zone ? data.zone : '';
        this.zoneId = data && data.zoneId ? data.zoneId : '';
        this.serverCode = data && data.serverCode ? data.serverCode : '';
        this.serverType = data && data.serverType ? data.serverType : '';
        this.statusCode = data && data.statusCode ? data.statusCode : '';
        this.roles = data && data.additionalSettings && data.additionalSettings.roles ? data.additionalSettings.roles : '';
        this.winOS = !!(data && data.winOS);
        this.settings =  data && data.additionalSettings && data.additionalSettings.settings ? data.additionalSettings.settings : null;
        this.soleTenantGroup = data && data.soleTenantGroup ? data.soleTenantGroup : null;
        this.allow = data && data.allow ? data.allow : undefined;
        this.resources = data && data.resources ? data.resources : {};
        this.additionalSettings = data && data.additionalSettings ? data.additionalSettings : {};
        this.subnetName = data && data.subnetName ? data.subnetName : null;
        this.status = data && data.status ? data.status : '';
        this.imageIsValid = data && data.imageIsValid !== null && data.imageIsValid !== undefined ? data.imageIsValid : true;
    }

    public getStringPublicIp(): string {
        return this.publicIp.length > 0 ? this.publicIp.join(',') : '' ;
    }
    public getStringInternalIp(): string {
        return this.internalIp.length > 0 ? this.internalIp.join(',') : '' ;
    }
}
