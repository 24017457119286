export class Disk {
    public diskName: string;
    public diskType: string;
    public diskTypeDescription: string;
    public diskSize: number;
    public boot: boolean;
    public dataDisk: boolean;
    public autoDelete: boolean;
    public required: boolean;
    public diskSizeOriginal: number;

    constructor(data? : any) {
        this.diskName = data && data.diskName ? data.diskName : '';
        this.diskType = data && data.diskType ? data.diskType : data.diskType.name ? data.diskType.name : '';
        this.diskTypeDescription = data && data.diskTypeDescription ? data.diskTypeDescription : '';
        this.diskSize = data && data.diskSize ? data.diskSize : '';
        this.boot = data && data.boot ? true : false;
        this.dataDisk = data && data.dataDisk ? true : false;
        this.autoDelete = data && data.autoDelete ? true : false;
        this.required = data && data.required ? true : false;
    }
}
