import { SoltenantGroup } from 'src/app/models';

export class InfrastructureRegionProvision {
    public regionId: number;
    public subnet : string;
    public subnetName: string;
    public externalDnsName: string;
    public domainControllers: number;
    public brokers: number;
    public gateways: number;
    public collectionsPoolsId: Array<number>;
    public primaryZone: number;
    public secondaryZone: number;
    public soleTenantGroup: SoltenantGroup;
    public regionName: string;
    // tslint:disable-next-line: cyclomatic-complexity
    constructor(data?: any) {
     this.regionId =  data && data.regionId ? data.regionId : 0;
     this.regionName =  data && data.regionName ? data.regionName : 0;
     this.subnet =  data &&  data.subnet ? data.subnet : '';
     this.subnetName = data && data.subnetName ? data.subnetName : '';
     this.externalDnsName =  data &&  data.externalDnsName ? data.externalDnsName : '';
     this.domainControllers =  data && data.domainControllers ? data.domainControllers : 0;
     this.brokers =  data && data.brokers ? data.brokers : 0;
     this.gateways =  data && data.gateways ? data.gateways : 0;
     this.collectionsPoolsId =  data && data.collectionsPoolsId ? data.collectionsPoolsId : [];
     this.primaryZone =  data && data.primaryZone ? data.primaryZone : 0;
     this.secondaryZone =  data && data.secondaryZone ? data.secondaryZone : 0;
     this.soleTenantGroup =  data && data.soleTenantGroup ? data.soleTenantGroup : null;
    }
 }
