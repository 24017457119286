import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';

import { Deployment } from 'src/app/models';
import { ServerError } from 'src/app/models/common/server-error';

import { locale as english } from './i18n/metrics.en';
import { locale as spanish } from './i18n/metrics.es';

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss']
})
export class MetricsComponent implements OnInit {
  @Input()
  public serverError: ServerError;
  @Input()
  public deployment: Deployment;

  public errorList: any[];
  public clientName: string;
  public dataWithInvalidQuotes: boolean;
  public available: string;
  public needed: string;

  constructor(private translate: TranslateService) {
    this.errorList = [];
    this.setTranslations();
  }

  public ngOnInit() {
    setTimeout(() => {
      this.setTranslations();
    });
    this.parseErrors();
    this.dataWithInvalidQuotes = this.existInvalidQuotes();
    this.clientName = `${this.deployment.name} (${this.deployment.code})`;
  }

  public existInvalidQuotes(): boolean {
    const errorKeys = ['ExternalStaticAddress', 'Gpu', 'Cpu', 'StandardDisk', 'SsdDisk'];
    return _.some(errorKeys, (key) => this.errorList[key]);
  }

  private setTranslations(): void {
    const browserLang = this.translate.getBrowserLang();
    const mapLangI18nFile = { es: spanish, en: english };
    if (!mapLangI18nFile[browserLang]) {
      this.translate.use('en');
      this.translate.setTranslation('en', mapLangI18nFile['en']);
    } else {
      this.translate.use(browserLang);
      this.translate.setTranslation(browserLang, mapLangI18nFile[browserLang]);
    }
    this.available = this.translate.instant('metrics.AVAILABLE');
    this.needed = this.translate.instant('metrics.NEEDED');
  }
  /**
   * Parse errors array to show in view
   */
  private parseErrors(): void {
    this.setTranslations();
    if (this.serverError.errors) {
      this.serverError.errors.forEach(err => {
        if (typeof err === 'object') {
          if (!err.Allow) {
            if (err.Metric === 'ExternalStaticAddress') {
              if (!this.errorList['ExternalStaticAddress']) {
                this.errorList['ExternalStaticAddress'] = [];
              }
              this.errorList['ExternalStaticAddress'].push(err);
            }
            if (err.Metric.indexOf('Gpu') > -1) {
              if (!this.errorList['Gpu']) {
                this.errorList['Gpu'] = [];
              }
              this.errorList['Gpu'].push(err);
            }
            if (err.Metric === 'Cpus') {
              if (!this.errorList['Cpu']) {
                this.errorList['Cpu'] = [];
              }
              this.errorList['Cpu'].push(err);
            }
            if (err.Metric && err.Metric.toLowerCase() === 'diskstotalgb') {
              if (!this.errorList['StandardDisk']) {
                this.errorList['StandardDisk'] = [];
              }
              this.errorList['StandardDisk'].push(err);
            }
            if (err.Metric && err.Metric.toLowerCase() === 'ssdtotalgb') {
              if (!this.errorList['SsdDisk']) {
                this.errorList['SsdDisk'] = [];
              }
              this.errorList['SsdDisk'].push(err);
            }
          }
        }
      });
    }
  }

}
