import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'underscore';

export const domainAdminUsername = function (isNetApp: boolean): ValidationErrors | null {
  return (control: AbstractControl) => {
    const value: string = control.value || '';
    if (!value) {
      return null;
    }
    const domainRegEx = new RegExp(/^[a-zA-Z0-9]+([-.][a-z0-9]+)*\.[a-zA-Z]{2,}$/);
    const netBiosRegEx = new RegExp(/^[a-zA-Z0-9]+([-.][a-zA-Z0-9]+)*$/);
    const usernameRegEx = new RegExp(/^[a-zA-Z0-9]+([-._][a-zA-Z0-9]+)*$/);
    const netBiosValue = value ? value.split('\\')[0].split('.')[0] : '';
    const validNetBiosLength = 15;
    const validNetAppLength = 10;
    const twoValue = 2;
    let valid = false;
    let validPartial = false;
    if (value && value.includes('\\')) {
      const res = value.split('\\');
      if (res.length === twoValue) {
        validPartial = domainRegEx.test(res[0]);
        if (!validPartial) {
          validPartial = netBiosRegEx.test(res[0]);
          valid = validPartial;
        }
        if (validPartial) {
          if (res[1]) {
            valid = usernameRegEx.test(res[1]);
          }
        }
      }
    } else if (value && value.includes('@')) {
      const resSuffix = value.split('@');
      if (resSuffix.length === twoValue) {
        valid = usernameRegEx.test(resSuffix[0]) && domainRegEx.test(resSuffix[1]);
      }
    }
    if (!valid) {
      return {
        pattern: 'Invalid format'
      };
    }
    if (!isNetApp && netBiosValue && netBiosValue.length > validNetBiosLength) {
      return {
        pattern: `Netbios could not be longer than ${validNetBiosLength} characters.`
      };
    } else if (isNetApp && netBiosValue.length > validNetAppLength) {
      return {
        pattern: `Netbios could not be longer than ${validNetAppLength} characters.`
      };
    }
  };
};
