import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'descriptionAndNameRegion'
})
export class PipeDescriptionAndNameRegion implements PipeTransform {
  
  /**
   * Capitalize text
   * @param value String to transform
  */
  transform(name: string, description: string): any {
    return  `${description} (${name})`;
  }

}
