export class CloudApp {
  public cloudAppId: number;
  public name: string;
  public version: string;
  public companyId: number;
  public path: string;
  public companyUsers: Array<number>;
  public securityGroups: Array<number>;
  public collectionPoolIds: Array<number>;
  public cloudAppStatusId: number;
  public onCloud: boolean;

  constructor(data ? : any) {
    this.cloudAppId = data && data.cloudAppId ? data.cloudAppId : 0;
    this.name = data && data.name ? data.name : '';
    this.version = data && data.version ? data.version : '';
    this.companyId = data && data.companyId ? data.companyId : 0;
    this.path = data && data.path ? data.path : '';
    this.companyUsers = data && data.companyUsers ? data.companyUsers : [];
    this.securityGroups = data && data.securityGroups ? data.securityGroups : [];
    this.collectionPoolIds = data && data.collectionPoolIds ? data.collectionPoolIds : [];
    this.cloudAppStatusId = data && data.cloudAppStatusId ? data.cloudAppStatusId : 0;
    this.onCloud = data && data.onCloud ? data.onCloud : false;
}
}
