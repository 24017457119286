export const locale = {
  lang: 'es',
  'manage_resource_in_table' : {
    ERROR_REQUIRED: 'Campo requerido',
    ERROR_EMAIL: 'Este campo debe ser una dirección de correo electrónico en el siguiente formato: "user@example.com"',
    ERROR_ONLY_NUMBER: 'Debe introducir sólo números',
    ERROR_PATTERN: 'Campo inválido',
    ERROR_DUPLICATED: 'Recurso duplicado',
    MINSIZE: 'Tamaño mínimo no alcanzada',
    MAXSIZE: 'Tamaño máximo excedido',
    EDIT: 'Editar',
    RETRY: 'Retry',
    DELETE: 'Delete',
    ADD: 'Adicionar',
    SAVE: 'Salvar',
    CANCEL: 'Cancelar',
    ERROR_MINSIZE: 'Tamaño mínimo {{val}}',
    ERROR_MAXSIZE: 'Tamaño máximo {{val}}',
    PROPERTY_IN_USE: 'El elemento se encuentra en uso'
  }
};
