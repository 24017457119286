import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'underscore';

export const subNetValidator = function (control: AbstractControl): ValidationErrors | null {
  const value: string = control.value || '';
  if (!value) {
    return null;
  }
  const reg10 = new RegExp(/^10\.([01]?\d?\d|2[0-4]\d|25[0-5])\.([01]?\d?\d|2[0-4]\d|25[0-5])\.0\/(2[0-9])$/);
  const reg192 = new RegExp(/^192\.168\.([01]?\d?\d|2[0-4]\d|25[0-5])\.0\/([1-2][0-9])$/);
  const reg172 = new RegExp(/^172\.(1[6-9]|2[0-9]|3[0-1])\.([01]?\d?\d|2[0-4]\d|25[0-5])\.(25[0-4]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/([1-2][0-9])$/);
  if (reg10.test(value) === false && reg192.test(value) === false && reg172.test(value) === false === true) {
    return {
      pattern: 'Invalid format'
    };
  }
};
