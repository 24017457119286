export class GpuRestrictions {
    public zoneId: number;
    public minCpu: number;
    public maxCpu: number;
    public minRam: number;
    public maxRam: number;

    constructor(data?: any) {
        this.zoneId = data && data.zoneId ? data.zoneId : 0;
        this.minCpu = data && data.minCpu ? data.minCpu : 0;
        this.maxCpu = data && data.maxCpu ? data.maxCpu : 0;
        this.minRam = data && data.minRam ? data.minRam : 0;
        this.maxRam = data && data.maxRam ? data.maxRam : 0;
    }
}