import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import * as _ from 'underscore';

import { ExternalDnsNamePipe, subNetValidator } from 'src/app/core/shared/pipes';
import { externalDnsNameValidator } from 'src/app/core/shared/pipes/validator/externalDnsName.validator';
import { ListDto, ProvisionDomain, Region, ServerError, Subnet, VpcTypes } from 'src/app/models';
import { DomainManageService } from 'src/app/provision/directory-config/domain-manage/domain-manage.service';
import { AutoUnsubscribeComponent } from '../../auto-unsubscribe/auto-unsubscribe.component';
import { RegionsListService } from '../../regions/regions-list/regions-list.service';
import { ConfigureRegionCertificateComponent } from '../configure-region-certificate/configure-region-certificate.component';
import { InfrastructureRegionProvision } from '../model';
import * as c from './../const';

import { locale as english } from '../i18n/configure-region-post-provision.en';
import { locale as spanish } from '../i18n/configure-region-post-provision.es';


@Component({
  selector: 'app-configure-region-domain',
  templateUrl: './configure-region-domain.component.html',
  styleUrls: ['./configure-region-domain.component.scss']
})
export class ConfigureRegionDomainComponent extends AutoUnsubscribeComponent implements OnInit {

  @Input()
  public deploymentId: number;
  @Input()
  public deploymentCode: string;
  @Input()
  public settings: object;

  @Output()
  public regionSelectedEmitter = new EventEmitter<Region>();

  public isCreating: boolean;
  public regionForm: FormGroup;
  public validationType: Object;
  public translateObject: Object;
  public subnetEx: string;
  public loadingConfigRegion: boolean;
  public regionList: Array<Region>;
  public deploymentRegions: Array<Region>;
  public regionSelected: Region;
  public isNewVpc: boolean;
  public subnetsRegion : Array<Subnet>;
  public externalDnsName : string;
  public loadingSubnetworks : boolean;
  public vpcNetworkName: string;
  public serverErrors: ServerError;

  @ViewChild(ConfigureRegionCertificateComponent, {static: false})
  public regionCertificatedComponent: ConfigureRegionCertificateComponent;


  constructor(private translate: TranslateService, private formBuilder: FormBuilder,
              private regionService: RegionsListService, private domainService: DomainManageService,
              private externalDnsNamePipe: ExternalDnsNamePipe ) {
    super();
   }

  public ngOnInit() {
    this.isCreating = true;
    this.deploymentRegions = [];
    this.subnetsRegion = [];
    this.regionList = [];
    this.setTranslations();
    this.loadingConfigRegion = false;
    this.subnetEx = c.subnetExample;
    this.regionForm = this.formBuilder.group({
      Region: [{ value: '', disabled: !this.isCreating }],
      Subnet: [{ value: '', disabled: true}],
      Subnets: [{ value: '', disabled: true}],
      ExternalDnsName: [{ value: '', disabled: true }],
    });
    this.regionSelected = new Region();

    this.validationType = {
      'ExternalDnsName':  [Validators.compose([Validators.required, externalDnsNameValidator])],
      'Subnet': [Validators.compose([Validators.required, subNetValidator])],
      'Subnets': [Validators.compose([Validators.required])],
      'Region': [Validators.compose([Validators.required])]
    };
    this.vpcNetwork();
    this.getRegionsData();
  }

  get f() { return this.regionForm.controls; }


  public setValidations(input: string): void {
    for (const key in this.f) {
      if (key === input) {
        this.f[key].setValidators(this.validationType[key]);
        this.f[key].updateValueAndValidity();
        break;
      } else if (!input) {
        this.f[key].setValidators(this.validationType[key]);
        this.f[key].updateValueAndValidity();
      }
    }
  }

  public changeRegions($event: Region): void {
      this.regionSelected = $event;
      this.regionSelectedEmitter.emit(this.regionSelected);
      if ( this.isNewVpc ) {
          this.regionForm.get('Subnet').setValue(this.regionSelected.subnet);
          this.regionForm.get('Subnet').enable();
      } else {
          this.getSubnetworks();
      }

      this.externalDnsName =  this.externalDnsNamePipe.transform(this.deploymentCode, this.regionSelected.name, c.externalDnsDom);
      this.regionForm.get('ExternalDnsName').setValue(this.externalDnsName);
      this.regionForm.get('ExternalDnsName').enable();
      this.regionCertificatedComponent.setModeEdit(false);
  }

  public isSaveCertificateRegion(): boolean {
    return this.regionCertificatedComponent.isEdited;
  }

  public isValidSavedCertificate(): boolean {
    return this.regionCertificatedComponent.serverError === null;
  }

  /**
   * Refresh region if no is newVpc
   */
  public refreshRegionsData(): void {
    this.loadingConfigRegion = true;
    const getRegionsSub = this.getRegionsV3().pipe(finalize(() => {
      this.loadingConfigRegion = false;
    })).subscribe(
      (regions: ListDto<Region>) => {
        this.regionList = regions.list;
        this.filterRegions();
    });
    super.addSubscriptions(getRegionsSub);
  }

  public changeExternalDnsName($event): void {
    this.setValidations('ExternalDnsName');
    if (this.regionForm.get('ExternalDnsName').valid) {
      const valueField =  this.regionForm.get('ExternalDnsName').value;
      if ( valueField !== this.externalDnsName) {
        this.regionCertificatedComponent.setModeEdit(true);
      } else {
        this.regionCertificatedComponent.setModeEdit(false);
      }
    }
  }

  public isValidComponent() : boolean {
     this.setValidations('Region');
     this.resetErrorsForm();
     this.setValidations('ExternalDnsName');
     if ( !this.isNewVpc) {
        this.setValidations('Subnets');
     } else {
      this.setValidations('Subnet');
     }
     const isValidCertificate = this.regionCertificatedComponent.isValidComponent();
     return this.regionForm.valid && isValidCertificate;
  }

  public fillInfrastructureRegionModel(model: InfrastructureRegionProvision): void {
      Object.assign(model, {regionId:  this.regionSelected.regionId, regionName: this.regionSelected.name,
                            externalDnsName : this.regionForm.get('ExternalDnsName').value });
      if ( !this.isNewVpc) {
        model.subnet = this.regionForm.get('Subnets').value['ipRange'];
        model.subnetName = this.regionForm.get('Subnets').value['name'];
      } else {
        model.subnet = this.regionForm.get('Subnet').value;
      }
  }

  public setServerErrors(errors: ServerError): void {
      this.serverErrors = errors;
      this.setErrors(this.serverErrors.errors);
  }

  public vpcNetwork(): void {
    this.isNewVpc = true;
    const hasVpc = this.settings && this.settings[c.vpcKey] !== undefined;
    if ( hasVpc) {
        this.isNewVpc = !(this.settings[c.vpcKey] === VpcTypes.Existing  || this.settings[c.vpcKey] === VpcTypes.Shared);
    }
  }

  private resetErrorsForm(): void {
    this.regionForm.setErrors(null);
  }

  private getSubnetworks(): void {
    this.loadingSubnetworks = true;
    this.regionForm.get('Subnets').setValue(null);
    this.regionService.getRegionSubnetworks(this.deploymentId, this.regionSelected.name, this.vpcNetworkName)
     .pipe(finalize(() => {
      this.loadingSubnetworks = false;
    })).subscribe(
      (subnets: ListDto<Subnet>) => {
         this.subnetsRegion = subnets.list;
         if ( this.subnetsRegion.length === 1) {
          this.regionForm.get('Subnets').setValue(this.subnetsRegion[0]);
          this.regionForm.get('Subnets').disable();
         } else {
           this.regionForm.get('Subnets').setValue(this.subnetsRegion[0]);
           this.regionForm.get('Subnets').enable();
         }
    });
  }

  private getProvisionData(): Observable<ProvisionDomain> {
    return this.domainService.getProvisionData(this.deploymentId);
  }

  private setErrors(errors: Object) : void {
    if (!this.isNewVpc && errors['Subnet'] !== undefined) {
      Object.assign(errors, {'Subnets': errors['Subnet']});
    }
    if (this.regionForm) {
      Object.keys(errors).forEach(prop => {
        this.regionForm.get(prop).setErrors({ serverError: errors[prop] });
      });
    }
  }

  /**
   * Get regions list excluding the provision regions
   */
  private getRegionsData(): void {
    this.loadingConfigRegion = true;
    const requestList = [ this.getRegionsV3(), this.getDeploymentRegions() ];
    if (!this.isNewVpc) {
      requestList.push(this.getProvisionData());
    }
    const joinReqSub = forkJoin(requestList).subscribe((dataArray: Array<any>) => {
      if (dataArray.length === requestList.length) {
        this.regionList = (dataArray[0] as ListDto<Region>).list;
        this.deploymentRegions = (dataArray[1] as ListDto<Region>).list;
        this.filterRegions();
        if (!this.isNewVpc) {
           this.vpcNetworkName = (dataArray[c.two] as ProvisionDomain).vpcNetworkName;
        }
        this.loadingConfigRegion = false;
      }
      }, (ex) => {
          this.loadingConfigRegion = false;
      });
    super.addSubscriptions(joinReqSub);
 }

  private getRegionsV3(): Observable<any> {
    return this.regionService.getRegions(this.deploymentId);
  }

  private getDeploymentRegions(): Observable<any> {
    return this.regionService.getDeploymentRegions(this.deploymentId);
  }

  private filterRegions(): void {
    this.deploymentRegions.forEach( (element: Region) => {
      this.regionList = this.regionList.filter(x => x.regionId !== element.regionId);
    });
 }

  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }

    this.translateObject = {
        'RegionLabel': this.translate.instant('configure_region.REGION_LABEL'),
        'SubnetLabel': this.translate.instant('configure_region.SUBNET_LABEL'),
        'SubnetsLabel': this.translate.instant('configure_region.SUBNETS_LABEL'),
        'ErrorRequired': this.translate.instant('configure_region.ERROR_REQUIRED'),
        'ErrorPattern': this.translate.instant('configure_region.ERROR_PATTERN'),
        'ExternalDNSName': this.translate.instant('configure_region.EXTERNAL_DNS_NAME_LABEL'),
        'REFRESH': this.translate.instant('configure_region.REFRESH')
    };
  }
}
