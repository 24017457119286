export class FolderPermission {
  public name: string;
  public domain: string;
  public userOrGroupName: string;
  public permissions: string;
  public inheritance: string;
  public type: string;
  public cloudUsers: number [];
  public securityGroups: number [];
  public isInherited: boolean;

  constructor(data ? : Object) {
    this.name = data && data['name'] ? data['name'] : undefined;
    this.domain = data && data['domain'] ? data['domain'] : undefined;
    this.userOrGroupName = data && data['userOrGroupName'] ? data['userOrGroupName'] : undefined;
    this.permissions = data && data['permissions'] ? data['permissions'] : '';
    this.inheritance = data && data['inheritance'] ? data['inheritance'] : '';
    this.type = data && data['type'] ? data['type'] : undefined;
    this.cloudUsers = data && data['cloudUsers'] ? data['cloudUsers'] : [];
    this.securityGroups = data && data['securityGroups'] ? data['securityGroups'] : [];
    this.isInherited = data && data['isInherited'] ? data['isInherited'] : false;
}
}
