export const locale = {
  lang: 'en',
  'apis_check_manager' : {
    'GCompute': 'Compute Engine API',
    'GBilling': 'Google Cloud Billing API',
    'GDeployment': 'Deployment Manager API',
    'GBigquery': 'BigQuery API',
    'GManageAd': 'Google Managed Service for Microsoft Active Directory',
    'GNetworking': 'Service Networking API',
    'GSqlAdmin': 'Cloud SQL Admin API',
    'GNetApp': 'NetApp Cloud Volumes Service (CVS)',
    'GOsConfig': 'OS Config API',
    'GContainerAnalysis' : 'Container Analysis API',
    'API_NOT_LOAD': 'Api not load',
  }
};

