import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {ReplaySubject} from 'rxjs/ReplaySubject';

@Injectable({
  providedIn: 'root'
})
export class ModalService extends NgbModal {

  private isClosing$ = new ReplaySubject<boolean>(1);
  public isClosing = this.isClosing$.asObservable();
  public setCloseOnScape(closeOnScape: boolean) {
      if (closeOnScape) {
        super.dismissAll();
        this.isClosing$.next(closeOnScape);
      }
  }

  public open(content: any, options?: NgbModalOptions): NgbModalRef {
   try {
    const defaultOptions: NgbModalOptions = {
      backdrop: 'static',
      size: 'md',
      keyboard: true,
      centered: true
    };
    options = { ...defaultOptions, ...options };
    return super.open(content, options);
   } catch (error) {
    console.log(error);
   }
  }
}
