export class OrganizationUnit {
   public organizationalUnitId: number;
   public name : string;
   public path : string;
   public order: number;
   constructor(data?: any) {
    this.organizationalUnitId =  data && data.organizationalUnitId ? data.organizationalUnitId : 0;
    this.name =  data &&  data.name ? data.name : '';
    this.path =  data &&  data.path ? data.path : '';
    this.order =  data &&  data.order ? data.order : 0;
    }
}
