import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { InfrastructureRegionProvision } from './model';

@Injectable({
  providedIn: 'root'
})
export class ConfigureRegionProvisionService {

  constructor(private http: HttpClient) { }

  public addRegion(deploymentId: number, region: InfrastructureRegionProvision): Observable<InfrastructureRegionProvision> {
    const params = new HttpParams().set('skeepFailure', 'true');
    return this.http.post<InfrastructureRegionProvision>(`${environment.apiUrlV3}deployments/${deploymentId}/regions/add`, region, {params});
  }
}
