import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { auth } from 'firebase/app';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, forkJoin, from, Observable, of } from 'rxjs';

import { environment } from '../../../environments/environment';
import { User } from '../../models/cloud-desktops/user';
import { catchError, switchMap } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })

export class AuthenticationService {
  public userData: Observable<firebase.User>;
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public getCurrentUser:  Observable<User>;
  public getCurrentUserSubject: BehaviorSubject<any>;

  private isPassword : boolean;

  constructor(private http: HttpClient, private angularFireAuth: AngularFireAuth,
              private router: Router, private cookieService: CookieService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.getCurrentUserSubject = new BehaviorSubject<any>(null);
    this.currentUser = this.currentUserSubject.asObservable();
    this.getCurrentUser = this.getCurrentUserSubject.asObservable();
    this.userData = angularFireAuth.authState;
  }
  /* Sign up - Tested */
  public signUp = (email: string, password: string) => {
    this.angularFireAuth.auth.createUserWithEmailAndPassword(email, password)
      .then(res => {
        console.log('Successfully signed up!', res);
      })
      .catch(error => {
        console.log('Something is wrong:', error.message);
      });
  }

  /* Sign in  - Tested */
  public signIn = (email: string, password: string) => {
    this.isPassword = true;
    return this.angularFireAuth.auth.signInWithEmailAndPassword(email, password);
  }

  /* Sign out */
  public signOut = () => {
      this.logoutAccount().subscribe((settings: any) => {
        this.logout();
    }, () => {
        this.logout();
    });
  }

  public logout(): void {
    this.angularFireAuth.auth.signOut().then(() => {
      sessionStorage.clear();
      localStorage.clear();
      (<any>window).Intercom('shutdown');
      this.currentUserSubject.next(null);
      if (this.router.url !== '/login') {
          this.router.navigate(['/login']).then(()=>{
            location.reload();
          });
        
      }
    });
  }

  public logoutAccount(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}accounts/logout`, undefined);
  }
  public changePassword(password: string) {
    return this.angularFireAuth.auth.currentUser.updatePassword(password);
  }
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  public sendRecoverEmail(email: string): any {
    return this.angularFireAuth.auth.sendPasswordResetEmail(email);
  }

   public loginObservable (user) : void {
    try {
      if (user) {
        this.getTokenCookie(user.user._lat).subscribe(() => {
          sessionStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          this.userData = user;
          this.http.get<any>(`${environment.apiUrl}admins/current`, {withCredentials:true}).subscribe((current) => {
            if(current){
              this.getCurrentUserSubject.next({current: current, error:false});
            }
          });
        },
         (err) => {
          this.getCurrentUserSubject.next({current: null, error:err});
         });
      }
    } catch (error) {
      console.log(error);
    }
  }
 
  public earlyAccessAndPresentations (current, isFromInvite?: boolean) : void {
    let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    currentUser = Object.assign(currentUser, { current: current });
    sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
    this.currentUserSubject.next(currentUser);
    this.userData = currentUser;
    forkJoin([
      this.http.get<any>(`${environment.apiUrl}permissions/presentations`),
      this.http.get<any>(`${environment.apiUrl}admins/earlyAccess`)
    ]).subscribe((data: any) => {
      sessionStorage.setItem('routesPermission', JSON.stringify(data[0]));
      sessionStorage.setItem('earlyaccess', JSON.stringify(data[1].list));
    }, null, () => {
      if (isFromInvite) {
        this.router.navigate(['/dashboard']).then(() => {
          location.reload();
        });
      } else {
        if (this.isPassword) {
          this.router.navigate(['/dashboard']);
        } else {
          this.isPassword = false;
          location.reload();
        }
      }
    });
   }

  // Firebase SignInWithPopup
  public oAuthProvider = (provider) => {
    return this.angularFireAuth.auth.signInWithPopup(provider).catch((error) => {
      return error;
    });
  }
  // Firebase Google Sign-in
  public signinWithGoogle = () => {
    return from(this.oAuthProvider(new auth.GoogleAuthProvider()));
  }
  public signinWithMicrosoft(): any {
    return from(this.oAuthProvider(new auth.OAuthProvider('microsoft.com')));
  }
  public linkWithCredential(credential): any {
    return from(this.angularFireAuth.auth.currentUser.linkWithCredential(credential));
  }
  public fetchSignInMethodsForEmail(email): any {
    return from(this.angularFireAuth.auth.fetchSignInMethodsForEmail(email));
  }
  public renovateToken(): any {
    return this.angularFireAuth.auth.currentUser.getIdToken(true).then((token: string) => {
      const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
      this.getTokenCookie(token);
      sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
      this.currentUserSubject.next(currentUser);
    });
  }
  public getPermission(): any {
    this.http.get<any>(`${environment.apiUrl}permissions/presentations`).subscribe((routes) => {
      sessionStorage.setItem('routesPermission', JSON.stringify(routes));
    });
  }
  public sendProviderEvent(event, token): Observable<any> {
    const configuration = {
      headers: {
        Authorization: 'Bearer ' + token
      }
    };
    return this.http.post<any>(`${environment.apiUrl}admins/event`, event, configuration);
  }

  public getEarlyAcces(): void {
    this.http.get<any>(`${environment.apiUrl}admins/earlyAccess`).subscribe((earlyaccess) => {
      sessionStorage.setItem('earlyaccess', JSON.stringify(earlyaccess.list));
    });
  }

  public setCurrentProvider(currentProvider: string): void {
    let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    currentUser = Object.assign(currentUser, { currentProvider: currentProvider });
    sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
  }

  public setCurrentPartnerId(partnerId: number): void {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    currentUser.user = Object.assign(currentUser.user, { partnerId: partnerId });
    sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
  }

  public getIntercomHash(): Observable<Object> {
    return this.http.get(`${environment.apiUrl}intercom/myHmac`, {withCredentials:true});
  }

  public getCurrent(): any {
    return this.http.get<any>(`${environment.apiUrl}admins/current`, {withCredentials:true}).subscribe((current) => {
      let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
      currentUser = Object.assign(currentUser, { current: current });
      sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
      this.currentUserSubject.next(currentUser);
    });
  }

  private getTokenCookie(token: string): Observable<Object> {
     return this.http.post(`${environment.apiUrl}accounts/tokenCookie`, {token: token}, {withCredentials:true});
  }  
}
