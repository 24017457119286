import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { AuthenticationService } from '../../../core/security/authentication.service';
import {ModalService} from '../../../core/shared/services/modal.service';
import { Organization, ValueDto } from '../../../models/common';
import { SubscriptionComponentName } from '../../../models/enums/subscription-component';
import { AutoUnsubscribeComponent } from '../../auto-unsubscribe/auto-unsubscribe.component';
import { AddonSubscriptionService } from './addon-subscription.service';

import { locale as english } from './i18n/addon-subscription.en';
import { locale as spanish } from './i18n/addon-subscription.es';

const subscriptionEdit = '/suscription/buyaddons';

@Component({
  selector: 'app-addon-subscription',
  templateUrl: './addon-subscription.component.html',
  styleUrls: ['./addon-subscription.component.scss']
})
export class AddonSubscriptionComponent extends AutoUnsubscribeComponent implements OnInit {
  @Input()
  public actionsButtons: string[];
  @Input()
  public organizationId: number;
  @Input()
  public countexceeds: number;
  @Input()
  public price: number;
  @Output()
  public actionConfirmation = new EventEmitter<boolean>();


  public title: string;
  public addon: SubscriptionComponentName;
  public addWarningTitle: string;
  public addInEnOrEs: string;
  public each: string;

  public canCloseModal$ = new Subject<boolean>();
  public actionPressed: boolean;
  public buyPermissions: boolean;
  public ownerEmail: string;
  public loadingOwnerEmail: boolean;

  constructor(private addonSubService: AddonSubscriptionService,  private modalService: ModalService, private translate: TranslateService,
              private authService: AuthenticationService, private notifierService: NotifierService) {
    super();
    const canCloseModalSubs = this.canCloseModal$.subscribe((data) => {
      if (data) {
        this.closeAddonsModal();
      }
    });
    super.addSubscriptions(canCloseModalSubs);
    const currentUserSubs = this.authService.currentUser.subscribe((currenUser: Object) => {
      if (currenUser && currenUser['current'] && currenUser['current'].organizationId) {
        this.organizationId = currenUser['current'].organizationId;
      }
    });
    super.addSubscriptions(currentUserSubs);
  }

  public ngOnInit(): void {
    this.ownerEmail = '';
    this.buyPermissions = this.securityCheck();
    this.loadingOwnerEmail = true;
    const orgSub = this.addonSubService.getOrganization(this.organizationId)
    .pipe(finalize(() => {
      this.loadingOwnerEmail = false;
    }))
    .subscribe((response: Organization) => {
      this.ownerEmail = response.email;
      this.setTranslations();
    });
    super.addSubscriptions(orgSub);
    setTimeout(() => {
      this.setTranslations();
    });
  }

  public securityCheck(): boolean {
    const permissionView = JSON.parse(sessionStorage.getItem('routesPermission'));
    if (permissionView && permissionView.list) {
      return permissionView.list.some(routes => subscriptionEdit === routes);
    } else { return false; }
  }

  public closeAddonsModal(): void {
    this.modalService.dismissAll();
  }

  public onActionConfirmation($event): void {
    if ($event) {
      this.actionPressed = true;
      this.buyAddon();
    } else {
      this.closeAddonsModal();
    }
  }

  private buyAddon(): void {
    const addonSub = this.addonSubService.postBuyComponent(this.organizationId, this.addon, this.countexceeds)
    .subscribe((response: ValueDto<boolean>) => {
      this.actionConfirmation.emit(true);
      this.closeAddonsModal();
    }, (reject) => {
      this.actionPressed = false;
    });
    super.addSubscriptions(addonSub);
  }

  private notifySuccess(): void {
    const succesfullMessage = this.translate.instant('addon_subscription.SUCCESS');
    const notification = { type: 'success', message: succesfullMessage };
    this.notifierService.notify(notification.type, notification.message);
  }

  private setTranslations(): void {
    const browserLang = this.translate.getBrowserLang();
    const mapLangI18nFile = { es: spanish, en: english };
    if (!mapLangI18nFile[browserLang]) {
      this.translate.use('en');
      this.translate.setTranslation('en', mapLangI18nFile['en']);
    } else {
      this.translate.use(browserLang);
      this.translate.setTranslation(browserLang, mapLangI18nFile[browserLang]);
    }

    this.title = this.translate.instant('addon_subscription.BUY_ADDONS');
    this.addInEnOrEs = this.translate.instant(`addon_subscription.component.${this.addon.toUpperCase()}`);
    if (this.buyPermissions) {
    this.addWarningTitle = this.translate.instant('addon_subscription.BUY_ADDONS_TITLE', { addon: this.addInEnOrEs} );
    } else {
      this.addWarningTitle = this.translate.instant('addon_subscription.INFO_ADDONS_TITLE',
      { addon: this.addInEnOrEs, ownerEmail: this.ownerEmail} );
    }
    this.each = this.translate.instant('addon_subscription.EACH');

    this.actionsButtons = [this.translate.instant('addon_subscription.CANCEL'), this.translate.instant('addon_subscription.BUY_NOW'),
                           this.translate.instant('addon_subscription.UPDATING')];
  }
}
