import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { MetricsComponent } from './metrics.component';

@NgModule({
  declarations: [MetricsComponent],
  imports: [
    CommonModule, TranslateModule
  ],
  exports: [MetricsComponent]
})
export class MetricsModule { }
