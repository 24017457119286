import { Component, Input, OnChanges,  OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-widget-info',
  templateUrl: './widget-info.component.html',
  styleUrls: ['./widget-info.component.scss']
})
export class WidgetInfoComponent implements OnInit,  OnChanges {
  @Input()
  public widgetData: any;
  @Input()
  public isPercent: boolean;

  constructor() { }

  public ngOnInit() {
  }
  public ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.widgetData) {
      this.widgetData = changes.widgetData.currentValue;
    }
  }

}
