import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxFileDropModule } from 'ngx-file-drop';
import { UiSwitchModule } from 'ngx-toggle-switch';

import { DirectivesModule } from 'src/app/core/shared/directives/directives.module';
import { ExternalDnsNamePipe } from 'src/app/core/shared/pipes';
import {ApisCheckManagerModule} from 'src/app/shared/apis-check-manager/apis-check-manager.module';
import { MetricsModule } from 'src/app/shared/metrics/metrics.module';
import { SectionLayoutModule } from 'src/app/shared/section-layout/section-layout.module';
import { SelectResourcesModule } from 'src/app/shared/select-resources/select-resources.module';
import { ViewsLayoutModule } from '../../shared/views-layout/views-layout.module';
import { FormButtonsV2Module } from '../form-buttons-v2/form-buttons-v2.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { FormButtonsModule } from './../form-buttons/form-buttons.module';
import { NgroupSoletenantProvisionModule } from './../ngroup-soletenant-provision/ngroup-soletenant-provision.module';

import { ConfigureRegionAdvancedComponent } from './configure-region-advanced/configure-region-advanced.component';
import { ConfigureRegionCertificateComponent } from './configure-region-certificate/configure-region-certificate.component';
import { ConfigureRegionDomainComponent } from './configure-region-domain/configure-region-domain.component';
// tslint:disable-next-line: max-line-length
import { ConfigureRegionPostProvisionCardComponent } from './configure-region-post-provision-card/configure-region-post-provision-card.component';
import { ConfigureRegionServersRolesComponent } from './configure-region-servers-roles/configure-region-servers-roles.component';

@NgModule({
  declarations: [ConfigureRegionServersRolesComponent, ConfigureRegionDomainComponent,
                 ConfigureRegionAdvancedComponent,
                 ConfigureRegionPostProvisionCardComponent,
                 ConfigureRegionCertificateComponent],
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule, NgbModule, NgSelectModule, TranslateModule, NgxFileDropModule,
    UiSwitchModule, ClipboardModule, DirectivesModule, SectionLayoutModule, ViewsLayoutModule, FormButtonsV2Module,
    SpinnerModule, FormButtonsModule, SelectResourcesModule, NgroupSoletenantProvisionModule, MetricsModule, ApisCheckManagerModule
  ],
  providers: [ ExternalDnsNamePipe ],
  exports: [ConfigureRegionPostProvisionCardComponent],
})
export class ConfigureRegionPostProvisionModule { }

