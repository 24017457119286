export class NavPage {
  public pageTitle: string;
  public uiSref: string;
  public icon: string;
  public roundTabs: string;
  public active: boolean;
  constructor(data?: any) {
    this.pageTitle =  data && data.pageTitle ? data.pageTitle  : '';
    this.uiSref =  data && data.uiSref ? data.uiSref  : '';
    this.icon =  data && data.icon ? data.icon  : '';
    this.roundTabs =  data && data.roundTabs ? data.roundTabs  : '';
    this.active =  data && data.active ? data.active  : false;
  }
}
