export class GpuItem {
    public id: number;
    public name: string;
    public description: string;
    public label: string;
    public gpuName: string;
    public agent: string;
    public core: number;
    public restrictions: [];

    constructor(data?: any) {
        this.id = data && data.id ? data.id : 0;
        this.name = data && data.name ? data.name : '';
        this.description = data && data.description ? data.description : '';
        this.gpuName = data && data.gpuName ? data.gpuName : '';
        this.agent = data && data.agent ? data.agent : '';
        this.core = data && data.gpu ? data.gpu : 0;
        this.restrictions = data && data.restrictions ? data.restrictions : [];
        this.buildLabel();
    }

    public buildLabel(): void {
        this.label = this.description + ' (' + this.name + ')';
    }
}
