export class GoogleUser {
    public integrationId: number;
    public name: string;
    public email: string;
    public image: string;
    public url: string;

    constructor(data? : any) {
        this.integrationId = data && data.integrationId ? data.integrationId : null;
        this.name = data && data.name ? data.name : '';
        this.email = data && data.email ? data.email : '';
        this.image = data && data.image ? data.image : '';
        this.url = data && data.url ? data.url : '';
    }
}
