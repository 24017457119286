import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import * as _ from 'underscore';

import { EarlyAccessService } from 'src/app/core/security/early-access.service';
import { EarlyAccessFeature, ListDto, Region, SoltenantGroup } from 'src/app/models';
import { AutoUnsubscribeComponent } from '../auto-unsubscribe/auto-unsubscribe.component';
import { RegionsListService } from '../regions/regions-list/regions-list.service';

import { locale as english } from './i18n/ngroup-soletenant-provision.en';
import { locale as spanish } from './i18n/ngroup-soletenant-provision.es';

const notfound = 'notfound';

@Component({
  selector: 'app-ngroup-soletenant-provision',
  templateUrl: './ngroup-soletenant-provision.component.html',
  styleUrls: ['./ngroup-soletenant-provision.component.scss']
})
export class NgroupSoletenantProvisionComponent extends AutoUnsubscribeComponent implements OnInit, AfterViewInit, OnChanges {

  @Input()
  public region : Region;
  @Input()
  public deploymentId : number;
  @Input()
  public notSection : boolean;

  @Output()
  public isValid  = new EventEmitter<boolean>();

  public earlyAccessSoleTenant: boolean;
  public soltenantGroups: Array<Object>;
  public loadingSoleTenant: boolean;
  public isSoleTenantNotFound: boolean;
  public soletenantForm: FormGroup;
  public loadingConfigSoletenant: boolean;
  public originalSoletenantGroup: SoltenantGroup;
  public isSoletenantNotData: boolean;
  /**************TEXT******************/
  public soleTenantConfigurationText: string;
  public soleTenantGroupText: string;
  public soleTenantDescription: string;
  public soletenantNotFoundMsg: string;
  public soletanatNoData: string;
  public noItemsFound: string;

  constructor( private translate: TranslateService, private formBuilder: FormBuilder,
               private regionsListService: RegionsListService,
               private cdRef: ChangeDetectorRef, private earlyAccessService: EarlyAccessService) {
    super();
  }

  public ngOnInit() {
    this.soletenantForm = this.formBuilder.group({
          SoleTenantGroupSelect: [{ value: '', disabled: false }]
    });
    this.earlyAccessSoleTenant = this.earlyAccessService.verifyAccess(EarlyAccessFeature.ProvisionSoletenant);
    setTimeout(() => {
      this.setTranslations();
   });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.region && changes.region.currentValue) {
        this.originalSoletenantGroup = JSON.parse(JSON.stringify(changes.region.currentValue.soleTenantGroup));
        this.getSoltenantGroupByRegion();
    }
  }

  public ngAfterViewInit() {
    this.setTranslations();
  }

  public changeSoleTenant($event): void {
    let nGroup = null;
    if ($event) {
       nGroup = new SoltenantGroup({zone: $event.zone, name: $event.name});
    }
    this.region.soleTenantGroup = nGroup;
    this.isSoleTenantNotFound = false;
    this.isValid.emit(true);
    this.cdRef.detectChanges();
  }

  public isValidComponent(): boolean {
    const objectValue = this.soletenantForm.get('SoleTenantGroupSelect').value;
    const required = objectValue['name'] === notfound && objectValue['zone'] === notfound;
    this.isSoletenantNotData = required;
    return !required;
  }

  public getSoltenantGroupByRegion(): void {
    if (this.region) {
      this.loadingSoleTenant = true;
      const soleTenantCurrent = this.region &&  this.region.soleTenantGroup  ? this.region.soleTenantGroup : null;
      this.soltenantGroups = [];
      const soletenantSub = this.regionsListService.getSoltenantGroupByRegion(this.deploymentId, this.region.regionId)
      .pipe(finalize(() => {
          this.loadingSoleTenant = false;
          this.cdRef.detectChanges();
      }))
      .subscribe((response: ListDto<SoltenantGroup>) => {
        if ( response.list.length > 0 ) {
          this.soltenantGroups =  _.map(response.list, (element: SoltenantGroup) => {
              return {
                  name: element.name,
                  zone: element.zone,
                  label: element.name + ' (' + element.zone + ')'
              };
          });
          if (soleTenantCurrent) {
              const name = soleTenantCurrent.name;
              const select =  _.find(this.soltenantGroups, (element: any) => element.name === name);
              if (select) {
                this.soletenantForm.get('SoleTenantGroupSelect').setValue(select);
                this.isValid.emit(true);
              } else {
                this.isSoleTenantNotFound = true;
                this.isValid.emit(false);
                this.soletenantForm.get('SoleTenantGroupSelect').setValue(soleTenantCurrent.name + ' (' + soleTenantCurrent.zone + ')');
              }
          } else {
              this.isValid.emit(true);
          }
        } else {
          this.changeSoleTenant(null);
          const shadowElement = {
            name: notfound,
            zone: notfound,
            label: this.noItemsFound
          };
          this.soltenantGroups.push(shadowElement);
          this.soletenantForm.get('SoleTenantGroupSelect').setValue(shadowElement);
        }
      }, () => {
        this.isValid.emit(true);
      });
      super.addSubscriptions(soletenantSub);
    }
  }

  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
    this.soleTenantConfigurationText =  this.translate.instant('ngroupSoletenantProvision.SOLETENANT_CONFIGURATION');
    this.soleTenantDescription =  this.translate.instant('ngroupSoletenantProvision.SOLETENANT_DESCRIPTION');
    this.soleTenantGroupText =  this.translate.instant('ngroupSoletenantProvision.SOLETENANT_GROUP_DESCRIPTION');
    this.soletenantNotFoundMsg = this.translate.instant('ngroupSoletenantProvision.SOLETENANT_GROUP_NOT_FOUND');
    this.soletanatNoData = this.translate.instant('ngroupSoletenantProvision.SOLETENANT_NO_DATA');
    this.noItemsFound = this.translate.instant('ngroupSoletenantProvision.NOT_ITEMS_FOUND');
  }
}
