import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

const textNode = 3;
@Directive({
  selector: '[appVpnsStatus]'
})
export class VpnsStatusDirective implements OnInit, OnChanges {
  @Input()
  public vpnStatus: string;

  @Input()
  public displayStatus: string;

  @Input()
  public renderStatus: boolean;

  constructor(private el: ElementRef, private renderer: Renderer2, @Inject(DOCUMENT) private document) { }
  public ngOnInit() {
    this.getVpnStatus(this.vpnStatus, this.renderStatus);
  }
  public ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.renderStatus) {
      this.renderStatus = changes.renderStatus.currentValue;
      this.getVpnStatus(this.vpnStatus, this.renderStatus);
    }
    if (changes && changes.vpnStatus) {
      this.vpnStatus = changes.vpnStatus.currentValue;
      this.getVpnStatus(this.vpnStatus, this.renderStatus);
    }
  }
  private getVpnStatus(status: string, renderStatus: boolean) {
    const child = document.createElement('span');
    const childElements = this.el.nativeElement.childNodes;
    for (const children of childElements) {
        if (children.nodeType !== textNode && children.id !== 'copy') {
          this.renderer.removeChild(this.el.nativeElement, children);
        }
      }
    if (!renderStatus) {
        child.innerHTML = `<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="loading m-r-2">
          <circle cx="50" cy="50" r="45"/>
        </svg>`;
      } else {
    switch (status.toLowerCase()) {
      case 'ready':
        child.className = 'fas fa-circle text-success m-r-2';
        break;
      case 'not available':
        child.className = 'far fa-circle m-r-2';
        break;
      default:
        child.className = 'fas fa-circle text-danger m-r-2';
        break;
    }
  }
    child.setAttribute('title', this.displayStatus);
    this.renderer.insertBefore(this.el.nativeElement, child, this.el.nativeElement.firstChild);
  }
}
