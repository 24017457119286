import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BinaryExpressionService, OperationExpressionService, UnaryExpressionService } from 'src/app/core/shared/filters';
import { environment } from '../../../environments/environment';
import { Deployment, ListDto, SupportToken } from '../../models';

const maxPageSize = 200;

@Injectable({
    providedIn: 'root'
})
export class SupportTokenService {
    constructor(private http: HttpClient, private binaryExpression: BinaryExpressionService,
                private unaryExpression: UnaryExpressionService,
                private operationExpression: OperationExpressionService) { }
    public generateSupportToken(organizationId: number, supportToken: SupportToken): Observable<{value: string}> {
        return this.http.post<{value: string}>(`${environment.apiUrl}organizations/${organizationId}/supportToken`, supportToken);
    }
    public getDeployments(partnerId: number): Observable<ListDto<Deployment>> {
        const filter = {
            pageIndex: 1,
            pageSize: maxPageSize,
            filter: [
                {
                    propertyName: 'Active', operation: this.operationExpression.expressionType.EQUALS,
                    value: 'true', unaryOperation: this.unaryExpression.expressionType.NONE,
                    binaryOperation: this.binaryExpression.expressionType.NONE
                },
                {
                    propertyName: 'PartnerId', operation: this.operationExpression.expressionType.EQUALS,
                    value: partnerId, unaryOperation: this.unaryExpression.expressionType.NONE,
                    binaryOperation: this.binaryExpression.expressionType.AND
                }
            ],
            order: { columnName: 'Name', ascending: true }
        };
        return this.http.post<ListDto<Deployment>>(`${environment.apiUrl}deployments/search`, filter);
    }
}
