export class Notification {
  public contentID: number;
  public notificationTypeID: number;
  public createAt: string;
  public subject: string | any;
  public body: any;
  public isHTML: boolean;
  public notificationTypeName: string;
  public failedProcess: string;
  public retring: boolean;
  public timeStamp: string;
  public deploymentId: number;
  public deploymentCode: string;
  public deploymentName: string;
  // tslint:disable-next-line: cyclomatic-complexity
  constructor(data?: any) {
      this.contentID =  data && data.contentID ? data.contentID  : 0;
      this.notificationTypeID = data && data.notificationTypeID ? data.notificationTypeID : 0;
      this.createAt = data && data.createAt ? data.createAt : '';
      this.timeStamp = data.timeStamp || '';
      this.subject = data && data.subject ? data.subject : '';
      this.body = data && data.body ? data.body : '';
      this.isHTML = data && data.isHTML ? data.isHTML : false;
      this.notificationTypeName = data && data.notificationTypeName ? data.notificationTypeName : '';
      this.failedProcess = data && data.failedProcess ? data.failedProcess : '';
      this.retring = data && data.retring ? data.retring : false;
      this.deploymentId = data && data.deploymentId ? data.deploymentId : 0;
      this.deploymentCode = data && data.deploymentCode ? data.deploymentCode : '';
      this.deploymentName = data && data.deploymentName ? data.deploymentName : '';
    }
}

