import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/core/shared/directives/directives.module';
import { AutoUnsubscribeModule } from 'src/app/shared/auto-unsubscribe/auto-unsubscribe.module';
import { DatatableModule } from 'src/app/shared/datatable/datatable.module';
import {ModalLayoutModule} from 'src/app/shared/modal-layout/modal-layout.module';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { ProcessViewerContainerComponent } from './process-viewer-container/process-viewer-container.component';
import { ProcessViewerDetailsComponent } from './process-viewer-details/process-viewer-details.component';
import { ProcessViewerListComponent } from './process-viewer-list/process-viewer-list.component';

@NgModule({
  entryComponents: [ProcessViewerDetailsComponent],
  declarations: [ProcessViewerListComponent, ProcessViewerContainerComponent, ProcessViewerDetailsComponent],
  imports: [CommonModule, NgbModule, SpinnerModule, AutoUnsubscribeModule, DatatableModule,
            TranslateModule, ModalLayoutModule, DirectivesModule],
  exports: [ProcessViewerContainerComponent, ProcessViewerDetailsComponent]
})
export class ProcessViewerModule { }
