export const locale = {
  lang: 'en',
  deployments: {
    'ALL': `All deployments`,
    'CLOSE': `Close`,
    'NAME': `Deployment`,
    'CODE': `Code`,
    'SEARCH': `Search`,
    'REFRESH': `Refresh`,
    'NO_DATA': 'No deployments found',
    'TOTAL': 'total',
    'SELECTED': 'seleccionado',
    'SHOWING': 'showing',
    'FILL_NAME': 'Type the name of the deployment \"{{deployment}}\" to confirm',
    'REASON': 'Reason',
    'DELETE_DEPLOY_NOT_ONCLOUD': 'Deployment {{ name }} deleted',
    'DELETE_BTN': 'Delete',
    'DELETING': 'Deleting ...',
    'CANCEL_BTN': 'Cancel',
    'DELETE_COMPONENT_TITLE': 'Delete deployment',
    'ERROR_REQUIRED': 'This field is required',
    'DELETE_DETAILS_PART1': `Deleting a CAS deployment will also delete the following
     Google Cloud resources associated with the deployment:`,
    'DELETE_DETAILS_PART2': `Deleting a deployment WILL NOT delete other Google Cloud resources in the project,
     and will not delete the Google Cloud project itself.`,
    'DELETE_DETAILS_PART3': 'For more information, refer to the Help Center article',
    'DELETE_DETAILS_ELEMENTS': {
      'VMANDDISK': 'Compute VM instances and disks',
      'VPNS': 'VPNs, VPCs and subnets',
      'FR': 'Firewall rules',
      'RIPS': 'Reserved IPs',
      'CI': 'Custom Images',
      'DIP': 'Deployment and internal project'
    },
    'DELETE_A_DEPLOYMENT': 'Delete a Deployment',
    'DELETE_CONFIRMATION_ONCLOUD': 'Deployment {{ name }} will be deleted in 2 hours',
    'RESET_AD_BTN': 'Reset AD password',
    'DELETE_BIGQUERY': 'Delete Insights BigQuery dataset',
    'DEPLOYMENTS': 'Deployments',
    'NAMEMUSTMATCH': 'Enter {{ deployment }} to confirm.',
    'STATUS_NOT_ON_CLOUD': 'Not on cloud',
    'STATUS_ON_CLOUD': 'On cloud',
    'STATUS_PENDING_CONNECT': 'Pending to connect',
    'STATUS_PENDING_CREATE': 'Pending to create',
    'STATUS_PENDING_DELETE': 'Pending deletion',
    'STATUS_PENDING_UPDATE': 'Pending update',
    'STATUS_IN_PROGRESS': 'In progress',
    'DEPLOY_NAME': 'Name',
    'DEPLOY_CODE': 'Deployment Code',
    'DEPLOY_ID': 'ID',
    'ORGANIZATION': 'Organization'
  }
};
