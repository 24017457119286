import { AbstractControl, ValidationErrors } from '@angular/forms';

export const externalDnsNameValidator = function (control: AbstractControl): ValidationErrors | null {
  const value = control.value || '';
  if (!value) {
    return null;
  }
  const expression = new RegExp(
    /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9])*(?:\.[a-zA-Z]{2,})$/
  );
  if (expression.test(value) === false) {
    return { pattern : 'Invalid format' };
  }
  return null;
};