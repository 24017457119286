
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerModule } from '../spinner/spinner.module';

import { AutoUnsubscribeModule } from 'src/app/shared/auto-unsubscribe/auto-unsubscribe.module';
import { FormButtonsModule } from 'src/app/shared/form-buttons/form-buttons.module';
import { SectionLayoutModule } from 'src/app/shared/section-layout/section-layout.module';
import { ViewsLayoutModule } from 'src/app/shared/views-layout/views-layout.module';
import { NgroupSoletenantProvisionComponent } from './ngroup-soletenant-provision.component';

@NgModule({
  imports: [CommonModule, NgbModule, TranslateModule, ReactiveFormsModule,
            FormButtonsModule, AutoUnsubscribeModule, SectionLayoutModule, NgSelectModule, SpinnerModule, ViewsLayoutModule],
  declarations: [NgroupSoletenantProvisionComponent],
  exports: [NgroupSoletenantProvisionComponent]
})
export class NgroupSoletenantProvisionModule { }
