export class ServerUptime {
    public id : number;
    public name : string;
    public description : string;
    public status : string;
    public active: boolean;
    public instances: number [];

    constructor(data?: any) {
        this.id = data && data.id ? data.id : 0;
        this.name = data && data.name ? data.name : '';
        this.description = data && data.description ? data.description : '';
        this.active = data && data.active ? true : false;
        this.instances = data && data.instances ? data.instances : [];
        this.status = data && data.status ? data.status : '';
    }
}
