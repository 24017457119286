import { Injectable } from '@angular/core';
import * as _ from 'underscore';

import { RolTypes } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class RoleTypeUserService {

  constructor() { }

  public verifyAccess(roles: Array<RolTypes>): boolean {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const roleName = currentUser && currentUser['current'] && currentUser['current']['roleSecurityName'] ?
                     currentUser['current']['roleSecurityName'] : '';

    return _.find(roles, (rol: RolTypes) => rol.toLowerCase() === roleName.toLowerCase() ) ? true : false;
  }
}
