import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { ListDto, Pes, ValueDto } from 'src/app/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PesService {

  constructor(private http: HttpClient) { }

  public postDeploymentPes(deploymentId: number): Promise<Object> {
    return this.http.post(`${environment.apiUrl}deployments/${deploymentId}/pes`, {}).toPromise();
  }

  public getFirewallConfigPes(deploymentId: number, skeepFailure?: boolean): Observable<ValueDto<string>> {
    let params = new HttpParams();
    if (skeepFailure) {
      params = params.set('skeepFailure', 'true');
    }
    return this.http.get<ValueDto<string>>(`${environment.apiUrl}deployments/${deploymentId}/pes/firewallRulesConfig`, { params: params});
  }

  public getPesDeployment(deploymentId: number): Observable<Pes> {
    return this.http.get<Pes>(`${environment.apiUrl}deployments/${deploymentId}/pes`);
  }

  public updateSubnetPesDeployment(deploymentId: number, subnet: string, skeepFailure?: boolean): Observable<ValueDto<boolean>> {
    let params = new HttpParams().set('subnet', subnet);
    if (skeepFailure) {
      params = params.set('skeepFailure', 'true');
    }
    return this.http.patch<ValueDto<boolean>>(`${environment.apiUrl}deployments/${deploymentId}/pes/subnet`, {}, { params: params });
  }

  public getNetAppShareVpcConfiguration(deploymentId: number, skeepFailure?: boolean): Observable<ListDto<string>> {
    let params = new HttpParams();
    if (skeepFailure) {
      params = params.set('skeepFailure', 'true');
    }
    return this.http.get<ListDto<string>>(
      `${environment.apiUrl}deployments/${deploymentId}/pes/NetAppShareVpcConfiguration`, { params: params});
  }

  public getCloudSqlShareVpcConfiguration(deploymentId: number, skeepFailure?: boolean): Observable<ListDto<string>> {
    let params = new HttpParams();
    if (skeepFailure) {
      params = params.set('skeepFailure', 'true');
    }
    return this.http.get<ListDto<string>>(
      `${environment.apiUrl}deployments/${deploymentId}/pes/CloudSqlShareVpcConfiguration`, { params: params});
  }
}
