import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Injectable, Input, OnChanges, OnDestroy,
   OnInit, Output, Renderer2, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable/release/types';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'underscore';

import { locale as english } from './i18n/datatable.en';
import { locale as spanish } from './i18n/datatable.es';
import { CapitalizePipe } from '../../../core/shared/pipes/shared-pipes/capitalize.pipe';
import { Datatable } from '../../../models/ui-elements/datatable/datatable';

const defaultRowHeight = 35;
const defaultLimit = 25;
const defaultHeightFooter = 60;
@Injectable()
@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class ItopiaDatatableComponent<T> implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input()
  public columns: TableColumn[];
  @Input()
  public selected: any[];
  @Input()
  public selectionType: string;
  @Input()
  public showFooter: boolean;
  @Input()
  public sorts: any[];
  @Input()
  public messages: any[];
  @Input()
  public isLoaded: boolean;
  @Input()
  public rowHeight: any;
  @Input()
  public groupRowsBy: string;
  @Input()
  public groupExpansionDefault: boolean;
  @Input()
  public groupRows: boolean;
  @Input()
  public expandRows: boolean;
  @Input()
  public hideSearch?: boolean;
  @Input()
  public tableClasses: string;
  @Input()
  public rowId: string;

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  public onFetchDataRequired = new EventEmitter<Datatable<T>>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  public onSelected = new EventEmitter<any>();

  @Output()
  public search = new EventEmitter<string>();

  @Output()
  public rowClick = new EventEmitter<any>();

  public cssClassses = {
    sortAscending: 'datatable-icon-down',
    sortDescending: 'datatable-icon-up',
    pagerLeftArrow: 'datatable-icon-left',
    pagerRightArrow: 'datatable-icon-right',
    pagerPrevious: 'datatable-icon-prev',
    pagerNext: 'datatable-icon-skip'
  };

  // tslint:disable-next-line:variable-name
  public _gridModelInput = new BehaviorSubject<Datatable<T>>(undefined);

  @ViewChild('emptyTemplate', { static: false })
  public emptyTemplate: TemplateRef<any>;
  @ViewChild('itopiaTable', { static: false })
  public itopiaTable: any;
  @ViewChild('header', { static: false })
  public header: any;
  public showLabel: any;
  public perLabel: any;
  public previousEvent: any;
  public rowArray: any[];
  public rawEvent: any;
  public contextmenuRow: any;
  public contextmenuColumn: any;
  public refreshLabel = 'Refresh';
  public searchPlaceholder = '';
  public defaultHeightFooter: number;
  public minElementsPage : number;
  public toDelete: any[];

  @Input()
  set gridModelInput(value) {
    if (value !== undefined) {
      this._gridModelInput.next(value);
    }
  }

  get gridModelInput() {
    return this._gridModelInput.getValue();
  }


  public gridModel: Datatable<T>;
  public isLoading = false;
  @Input()
  public currentPageLimit: number;
  @Input()
  public pageLimitOptions: any;
  @ViewChild('search', { static: false })
  private searchs: ElementRef;

  constructor(private translate: TranslateService, private capitalizePipe: CapitalizePipe, private ref: ElementRef,
              private changeDetectorRef: ChangeDetectorRef, private renderer: Renderer2) {
    if (this.showFooter === undefined) {
      this.showFooter = true;
    }

    this.setTranslations();
  }

  public ngOnInit(): void {
    this.rowArray = [];
    this.toDelete= [];
    sessionStorage.setItem('rowId', this.rowId);
    this.selected = [];
    sessionStorage.setItem('dtSelected', JSON.stringify(this.selected));
    this.rowHeight = this.rowHeight ? this.rowHeight : defaultRowHeight;
    this.defaultHeightFooter = this.showFooter ? defaultHeightFooter : 0;
    this.showLabel = this.translate.getBrowserLang() === 'es' ? 'Mostrar' : 'Show';
    this.perLabel = this.translate.getBrowserLang() === 'es' ? 'por página' : 'per page';
    this.pageLimitOptions = this.pageLimitOptions ? this.pageLimitOptions : [
      { value: 5 },
      { value: 10 },
      { value: 25 },
      { value: 50 },
    ];
    this.minElementsPage = this.pageLimitOptions[0].value;
    this.currentPageLimit = this.currentPageLimit ? this.currentPageLimit : defaultLimit;
    this.gridModel = new Datatable<T>();

    this._gridModelInput.subscribe(gridModel => {
      if (gridModel) {
        gridModel.CurrentPageNumber = gridModel.CurrentPageNumber ? gridModel.CurrentPageNumber : 0;
        gridModel.PageSize = this.currentPageLimit;
        this.gridModel = gridModel;
        const tempData = [];
        this.rowArray = [];
        _.map(this.gridModel.Data, (newRow) => {
          this.rowArray = _.uniq(this.rowArray);
          const rowsId = _.pluck(this.rowArray, this.rowId);
          const contains = !this.rowId ? _.contains(rowsId, newRow[this.rowId]) :'';
          if (!contains) {
            tempData.push(newRow);
          } else {
            const rowFounded  = !this.rowId ?
              _.find(this.rowArray, (row) => row[this.rowId] === newRow[this.rowId]) : false;
            if (rowFounded && newRow[this.rowId] !== rowFounded[this.rowId]) {
              rowFounded[this.rowId] =  newRow[this.rowId];
            }
          }
        });
        const rows = [...tempData];
        _.each(rows, (row) => row.id =  row[this.rowId]);
        this.isLoading = false;
        this.rowArray = _.clone(rows);
        sessionStorage.setItem('dtSelected', JSON.stringify(this.selected));
        this.setSelected();
      }
    });
    this.loadPage(this.gridModel);
    this.hideSearch = this.hideSearch ? this.hideSearch : false;
  }
  public ngAfterViewInit() {
    if (this.itopiaTable && this.header) {
      this.itopiaTable.element.firstElementChild.className = !this.hideSearch ? 'm-t-30' : '';
      this.itopiaTable.element.firstElementChild.prepend(this.header.nativeElement);
    }
  }
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.expandRows && changes.expandRows.currentValue) {
      this.expandRows = changes.expandRows.currentValue;
      this.columns = changes.columns.currentValue;
    }
    if (changes.showFooter) {
      this.defaultHeightFooter = this.showFooter ? defaultHeightFooter : 0;
    }
  }
  public loadPage(pageEvent, isRefresh?: boolean) {
    if (isRefresh) {
      this.gridModel.Refreshing = true;
      this.searchs.nativeElement.value = '';
      this.selected = [];
      sessionStorage.setItem('dtSelected', JSON.stringify([]));
    } else {
      this.gridModel.Refreshing = false;
    }
    pageEvent = pageEvent ? pageEvent : { offset: 0 };
    this.isLoading = true;
    if (this.gridModel) {
      this.gridModel.CurrentPageNumber = pageEvent.CurrentPageNumber ? pageEvent.CurrentPageNumber - 1 : 0;
      this.onFetchDataRequired.emit(this.gridModel);
    }
  }
  public updateFilter($event): void {
    this.search.emit($event);
    this.rowArray = [];
}
  public onSort(event) {
    if (this.gridModel.SortBy !== event.sorts[0].prop) {
      this.gridModel.CurrentPageNumber = 0;
    }
    this.gridModel.SortBy =  event.sorts[0].prop;
    this.gridModel.SortDir = event.sorts[0].dir;
    event.sorts.forEach(sort => {
      if (sort.prop === event.column.prop) {
        this.gridModel.SortBy =  event.column.prop ;
        this.gridModel.SortDir = event.newValue ;
      }
    });
    this.gridModel.SortBy = this.capitalizePipe.transform(this.gridModel.SortBy);
    this.loadPage(this.gridModel);
  }

  public onLimitChange(limit: any): void {
    this.gridModel.PageSize = this.currentPageLimit = parseInt(limit, 0);
    this.gridModel.CurrentPageNumber = 0;
    this.gridModel.Data = [];
    this.loadPage(this.gridModel);
  }

  public setCurrentPage($event: any): void {
    this.gridModel.CurrentPageNumber = $event.page;
    this.gridModel.Data = [];
    this.loadPage(this.gridModel);
  }
  public ngOnDestroy(): void {
    this._gridModelInput.unsubscribe();
    sessionStorage.setItem('dtSelected', JSON.stringify([]));
    sessionStorage.setItem('rowId', JSON.stringify(''));
  }
  public onSelect({ selected }): void {
      selected = _.uniq(selected, this.rowId);
      if (this.selected) {
      const previousSelected = _.pluck(JSON.parse(sessionStorage.getItem('dtSelected')), 'id');
      previousSelected.forEach((item) => {
          if (!_.contains(_.pluck(selected, 'id'), item)) {
            this.toDelete.push(item);
          }
      });
      this.selected.splice(0, this.selected.length);
      this.selected.push(...selected);
      this.onSelected.emit(this.selected);
      sessionStorage.setItem('dtSelected', JSON.stringify(this.selected));
      console.log('onSelect dtSelected', this.selected);
    }
  }
  public onActivate($event) {
    // * Remove row click event to showDetails
    this.previousEvent = $event;
  }
  public toggleExpandGroup(group) {
    this.itopiaTable.groupHeader.toggleExpandGroup(group);
  }
  public toggleExpandRow(row) {
    this.rowArray = Object.entries(row);
    this.itopiaTable.rowDetail.toggleExpandRow(row);
  }
  public onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }
  public getRowIdentity(row): string {
    return  row.id;
  }
  private setSelected(): void {
    const sessionSelected = JSON.parse(sessionStorage.getItem('dtSelected'));
    const backendSelected = _.filter(this.rowArray, (row: any) => row.allow);
    const notDuplicated = [];
    const flatSelected = _.flatten([...sessionSelected , ... backendSelected]);
    flatSelected.forEach((row) => {
      const rowId = _.pluck(notDuplicated, this.rowId);
      const contains = !this.rowId ? _.contains(rowId, row[this.rowId]) : false;
      if (!contains && !_.contains(this.toDelete, row.id)) {
        notDuplicated.push(row);
      }
    });
    const resultArray = notDuplicated.length > 0 ? notDuplicated :  flatSelected;
    this.onSelect({selected: resultArray});
  }

  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
    this.searchPlaceholder = this.translate.instant('datatable.SEARCH');
  }
}
