export const locale = {
    lang: 'en',
    'notification_pane': {
        'VIEW_ALL': 'Ver todas las notificaciones',
        'ERRORS': 'Errores',
        'NO_ERR_NOTIF': 'No hay notificaciones de error',
        'NEW_ERR_NOTIF': '+ {{count}} Errores no mostrados',
        'OTHER': 'Otras notificaciones',
        'NO_OTHER_NOTIF': 'No hay otras notificaciones',
        'NEW_OTH_NOTIF': '+ {{count}} Nuevas notificaciones no mostradas',
        'SUCCESFULL_AUTOSCALING': 'Horario configurado correctamente',
        'RETRY' : 'Reintentar',
    }
};
