import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import * as _ from 'underscore';

import { DeploymentsService } from 'src/app/deployments/deployments.service';
import { DeploymentStatus } from 'src/app/models';
import { ProcessViewerService } from 'src/app/process-viewer/process-viewer.service';
import { AuthenticationService } from './authentication.service';
import {excludeUrlAuthGuard} from './exlude-url-auth-guard';

@Injectable({ providedIn: 'root' })

export class AuthGuard implements CanActivate {
  private prevState: string;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private deploymentsMenuService: DeploymentsService,
    private processViewerService:  ProcessViewerService,
    private translate:TranslateService
  ) { }

  public canActivate(route: any, state: RouterStateSnapshot): boolean {
    const currentUser = this.authenticationService.currentUserValue;
    let hasPermission = true;
    if (currentUser) {
      if (route.routeConfig.data && route.routeConfig.data.security) {
        hasPermission = this.securityCheck(route.routeConfig.data.security.resource, route.routeConfig.data.security.action);
        if (!hasPermission) {
          const viewTitle = route.routeConfig.data.security.module;
          const title = viewTitle[this.translate.getBrowserLang()]
          const r = !this.prevState ? route._routerState.url : this.prevState;
          this.router.navigateByUrl('/error', {
            state: {
              route: r,
              message: title
            }
          });
        } else {
          this.prevState = route._routerState.url;
          this.processViewerService.changeRoute(this.prevState);
          this.deploymentsMenuService.deploymentSelected.subscribe(deploymentSelected => {
            if (deploymentSelected === null) {
              this.deploymentsMenuService.deploymentRecent.subscribe((data) => {
                if (data === undefined) {
                    this.deploymentsMenuService.getRecentDeployments().subscribe((data) => {
                    this.deploymentsMenuService.setDeploymentRecent(data.list);
                    if (data.count === 0) {
                      this.router.navigate(['/dashboard/nodeployment/']);
                      return;
                    }});
                } else if (data.length === 0) {
                  this.router.navigate(['/dashboard/nodeployment/']);
                  return;
                }
              });
            } else if (deploymentSelected.companyId && !_.any(excludeUrlAuthGuard, (url: string) => this.prevState.indexOf(url) !== -1)) {
              switch (deploymentSelected.status.toLowerCase()) {
                case DeploymentStatus.PendingToCreate:
                      this.router.navigate([`/dashboard/creation/${deploymentSelected.companyId}`]);
                      break;
                case DeploymentStatus.InProgress:
                case DeploymentStatus.NotOnCloud:
                      this.router.navigate([`/provision/${deploymentSelected.companyId}/continue`]);
                      break;
                case DeploymentStatus.PendingDeletion:
                      this.router.navigate(['/dashboard/deletion/' + deploymentSelected.companyId]);
                      break;
                case DeploymentStatus.PendingToConnect:
                      this.router.navigate(['/dashboard/pending-connect/' + deploymentSelected.companyId]);
                      break;
              }
              if (this.prevState.includes('catalog')) {
                this.deploymentsMenuService.getDeploymentSettings(deploymentSelected.companyId).subscribe((data: Object) => {
                  const canActivateCatalog = _.some(data['settings'], (value: string, key: string) =>
                    key.toLowerCase() === 'sci' && value.toLowerCase() === 'true');
                  if (!canActivateCatalog) {
                    this.router.navigate(['/dashboard/oncloud/' + deploymentSelected.companyId]);
                  }
                });
              }
            }
          });
        }
      }
      return hasPermission;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login']);
    return false;
  }
  public securityCheck(resource, action): boolean {
    const permissionView = JSON.parse(sessionStorage.getItem('routesPermission'));
    const r = '/' + resource + '/' + action;
    if (permissionView && permissionView.list) {
      return permissionView.list.find(routes => r === routes) ? true : false;
    } else { return false; }
  }
}
