import {UserCurrentSessions} from './user-current-sessions';

export class UserSession {
  public cieloUserId: number;
  public userName: string;
  public isUserOnLine: boolean;
  public status: string;
  public sessions: UserCurrentSessions [];
  constructor(data ? : any) {
    this.cieloUserId = data && data.cieloUserId ? data.cieloUserId : 0;
    this.userName = data && data.userName ? data.userName : '';
    this.isUserOnLine = data && data.isUserOnLine ? data.isUserOnLine : false;
    this.status = data && data.status ? data.status : '';
    this.sessions = data && data.sessions ? data.sessions : undefined;
  }
}
