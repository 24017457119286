import { Injectable } from '@angular/core';
import { throwError } from 'rxjs/internal/observable/throwError';

import { environment } from 'src/environments/environment';
import { GmanagmentService } from './gmanagment.service';

declare const gapi: any;
const urlGetManaged = 'https://managedidentities.googleapis.com/v1beta1/projects/';
const urlServiceActivate = 'servicemanagement.googleapis.com/v1/services/managedidentities.googleapis.com:enable';

@Injectable({
  providedIn: 'root'
})
export class GmanageadService {

  constructor(private gmanagmentService: GmanagmentService) { }
  public service = 'managedidentities.googleapis.com';
  public loadManageAD(): void {
    try {
      if (!gapi || !gapi.client || !gapi.client.servicemanagement) {
        this.gmanagmentService.loadGManagment();
      }
      return gapi.client.load('dns', environment.gapi.apiVersion);
    } catch (exception) {
      throwError(exception);
      return;
    }
  }
  public getManagedIdEnities(projectId): Promise<any> {
    const request = gapi.client.request({
      path: `${urlGetManaged}${projectId}/locations/global/domains`,
      method: 'GET'
    });
    return request.then((response) => {
      return Promise.resolve(response);
    }).catch((reason) => {
      reason.request = {
        name: 'gapi.client.managedidentities',
      };
      return Promise.reject(reason);
    });
  }
  public hasEnabled(projectId): Promise<any> {
    return this.getManagedIdEnities(projectId)
      .then((entity) => {
        return Promise.resolve(entity);
      }).catch((reason) => {
        return Promise.reject(reason);
      });
  }
  public activate(consumer): Promise<any> {
    const params = {
        serviceName: this.service,
        consumerId: consumer
    };
    const request = gapi.client.servicemanagement.services.enable(params);
    return request.then((response) => {
        return Promise.resolve(response.result);
    }).catch((reason) => {
        reason.request = {
            name: urlServiceActivate,
            params: params
        };
        return Promise.reject(reason);
    });
}
}
