export class ProcessActivity {
    public name: string;
    public startAt: string;
    public target: string;
    public initProcess : string;
    public status: string;
    public taskId: number;
    public parentProcess: string;
    public parentProcessVisible: string;
    public endAt: string;
    public processId: string;
    public details: string;
    public resource: string;
    public entityId: number;
    public isHTML: boolean;
    constructor(data ? : any) {
      this.name = data && data.name ? data.name : '';
      this.startAt = data && data.startAt ? data.startAt : '';
      this.initProcess = data && data.initProcess ? data.initProcess : '';
      this.status = data && data.status ? data.status : null;
      this.target = data && data.target ? data.target : '';
      this.processId = data && data.processId ? data.processId : '';
      this.parentProcess = data && data.parentProcess ? data.parentProcess : '';
      this.endAt =  data && data.endAt ? data.endAt : '';
      this.details =  data && data.details ? data.details : '';
      this.resource =  data && data.resource ? data.resource : '';
      this.entityId =  data && data.entityId ? data.entityId : 0;
      this.taskId = data && data.taskId ? data.taskId : 0;
      this.parentProcessVisible = data && data.parentProcessVisible ? data.parentProcessVisible : '';
      this.isHTML = data && data.isHTML ? data.isHTML : false;
    }
}
