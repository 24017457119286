export const locale = {
  lang: 'es',
  login : {
    USERNAME_PH : 'Nombre de Usuario',
    PASSWORD_PH : 'Contraseña',
    FORGOT: `¿Olvidó su contraseña?`,
    LOGIN: `Autenticarse`,
    LOGININ: `Autenticando ...`,
    ITOPIA: `Autenticarse en itopia`,
    SIGN_UP_ITOPIA: `Registrarse en itopia`,
    SIGN_IN_WITH: `Autenticarse con:`,
    REMEMBER: `Recordarme`,
    DONT: `¿No tiene cuenta?`,
    SIGN_UP: `Ir al marketplace`,
    RECOVER: `Recuperar contraseña`,
    RECOVER_DATA: `¡Ingrese su correo electrónico y espere instrucciones!`,
    RESET: `Enviar instrucciones`,
    CANCEL: `Cancelar`,
    RESET_SUCCESS: `Se ha enviado un enlace de restablecimiento de contraseña a su dirección de correo electrónico`,
    CONNECTION_ERROR: `Error de conexión`,
    ARGUMENT_ERROR: `La dirección de correo electrónico debe ser una cadena válida`,
    INVALID_EMAIL_ERROR: `La dirección de correo electrónico está mal formateada`,
    EXPIRED_ERROR: `El código de restablecimiento de contraseña ha caducado`,
    RESET_CODE_ERROR: `El código de restablecimiento de contraseña no es válido`,
    USER_DISABLED_ERROR: `El usuario correspondiente al código de restablecimiento de contraseña dado ha sido deshabilitado`,
    USER_NOT_FOUND_ERROR: `No hay ningún usuario en CAS que corresponda a esta dirección de correo electrónico`,
    WEAK_PASSWORD_ERROR: `La nueva contraseña no es lo suficientemente segura`,
    UNEXPECTED_ERROR: `Error inesperado`,
    SENDING: `Enviando ...`,
    NOTIFICATION_TITLE: `Acceder`,
    NOTIFICATION_TEXT: `Este correo electrónico está asociado con una cuenta de itopia activa.
    ¿Estás seguro de que quieres autenticarte a través de`,
    NOTIFICATION_TEXT2: `de ahora en adelante?`,
    REQUIRED: `Campo requerido`,
    EMAIL: `Este campo debe ser una dirección de correo electrónico en el siguiente formato: \"user@example.com\"`,
    INVALID_EMAIL: `El campo de correo electrónico no es una dirección de correo válido`,
    INVALID_CREDENTIALS: 'La combinación de nombre de usuario y contraseña es incorrecta',
    CURRENT_FAILS: `Su cuenta no está registrada con nosotros. Regístrese en Google Cloud Marketplace.`,
    BACK_TO: `Volver a la autenticación`,
    NOTIFICATION_SIGN: `Acceder`,
    NOTIFICATION_SIGNING: 'Accediendo',
    ACCOUNT_DISABLED_ERROR: `El acceso a esta cuenta se ha inhabilitado temporalmente
    debido a muchos intentos fallidos de inicio de sesión.`
  }
};
