import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

const textNode = 3;
@Directive({
    selector: '[appDeploymentStatus]'
})
export class DeploymentStatusDirective implements OnInit, OnChanges {
    @Input()
    public deployStatus: string;
    @Input()
    public displayStatus: string;

    constructor(private el: ElementRef, private renderer: Renderer2, @Inject(DOCUMENT) private document) { }

    public ngOnInit() {
        this.getDeploymentStatus(this.deployStatus);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.deployStatus) {
            this.deployStatus = changes.deployStatus.currentValue;
            this.getDeploymentStatus(this.deployStatus);
        }
    }

    private getDeploymentStatus(status: string): void {
        const child = document.createElement('span');
        const childElements = this.el.nativeElement.childNodes;
        for (const children of childElements) {
            if (children.nodeType !== textNode && children.id !== 'copy') {
                if (!children.id || children.id !== 'code') {
                    this.renderer.removeChild(this.el.nativeElement, children);
                }
            }
        }
        switch (status.toLowerCase()) {
            case 'pending deletion':
            case 'pending to create':
            case 'pending update':
            case 'pending to connect':
                child.className = 'fas fa-circle c-orange m-r-2';
                break;
            case 'on cloud':
                child.className = 'fas fa-circle text-success m-r-2';
                break;
            case 'not on cloud':
                child.className = 'far fa-circle m-r-2';
                break;
        }
        child.setAttribute('title', this.displayStatus);
        this.renderer.insertBefore(this.el.nativeElement, child, this.el.nativeElement.firstChild);
    }
}
