export enum UserStatus {
ONLINE = 'Online',
OFFLINE = 'Offline',
IDLE = 'Idle',
LOCKED = 'Locked',
DISCONNECTED = 'Disconnected'
}
export enum UserStatusSpanish {
  ONLINE = 'En línea',
  OFFLINE = 'Cerró sesión',
  IDLE = 'Ausente',
  LOCKED = 'Bloqueado',
  DISCONNECTED = 'Disconnected'
  }
