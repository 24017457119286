import { SoltenantGroup } from './soltenant-group';

export class Zone {
    public name: string;
    public zoneId: number;
    public regionId: number;
    public description: string;
    public soleTenantGroup: SoltenantGroup;

    constructor(data ? : any) {
        this.name = data && data.name ? data.name : '';
        this.zoneId = data && data.zoneId ? data.zoneId : 0;
        this.regionId = data && data.regionId ? data.regionId : 0;
        this.description = data && data.description ? data.description : '';
        this.soleTenantGroup = data && data.soleTenantGroup ? data.soleTenantGroup : null;
    }
}
