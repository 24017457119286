import { Pipe, PipeTransform } from '@angular/core';
import {SoftwareTemplate} from '../../../../models/common';
import {GroupImageEnum} from '../../../../models/enums/group-image-enum';

@Pipe({
  name: 'groupImage'
})

export class GroupImagePipe implements PipeTransform {

  public transform(images: Array<SoftwareTemplate>, propertyForGroup: string, collectionPoolTypeCode?: string): Array<SoftwareTemplate> {
    return images.map(image => {
      if (image['family'].toLowerCase().includes(GroupImageEnum.Google.toLowerCase())) {
        image[propertyForGroup] = GroupImageEnum.Google;
      }
      if (image['name'] && image['name'].toLowerCase().includes(GroupImageEnum.SoleTenant.toLowerCase())
        || image['family'].toLowerCase().includes(GroupImageEnum.Custom.toLowerCase())) {
        image[propertyForGroup] = GroupImageEnum.Custom;
      }
      if (image['name'] && image['name'].toLowerCase().includes(GroupImageEnum.ShieldedVM.toLowerCase())) {
        image[propertyForGroup] = GroupImageEnum.Shielded;
      }
      if ((image['name'] && image['name'].toLowerCase().includes(GroupImageEnum.Byol.toLowerCase()) ||
        image['windowsDesktop'] && image['windowsDesktop'] === true || collectionPoolTypeCode &&
        collectionPoolTypeCode.toLowerCase().includes(GroupImageEnum.Windows10) && image['windowsDesktop']
        && image['windowsDesktop'] === true) && image[propertyForGroup] !== GroupImageEnum.Custom) {
        image[propertyForGroup] = GroupImageEnum.Byol;
      }
      return image;
    });
  }
}
