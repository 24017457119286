export const locale = {
  lang: 'en',
  'process_viewer' : {
    'TITLE': 'Recent Activity',
    'ERROR_TITLE': 'Errors:',
    'PROGRESS_TITLE': 'In Progress:',
    'TASK': 'Task',
    'TARGET': 'Target',
    'STATUS': 'Status',
    'START_TIME': 'Start time',
    'INITIATED_BY': 'Initiated by',
    'TASK_ID': 'Task ID',
    'SHOWING': 'showing',
    'SEARCH': 'Search',
    'REFRESH': 'Refresh',
    'TOTAL': 'total',
    'NO_DATA': 'No data',
    'DETAILS_TITLE': 'Task details',
    'COMPLETION_TIME': 'Completion time',
    'DETAILS': 'Details',
    'CLOSE': 'Close',
    'RETRY': 'Retry',
    'ERROR': 'Error',
    'COMPLETED': 'Completed',
    'PROGRESS': 'In Progress',
    'RETRYING': 'Retrying',
    'RETRYING_MSG': 'The process has been retried successfully',
    'TIMEZONE_DESCRIPTION': 'All times are displayed in'
  }
};

