import { Schedule } from './schedule';

export class PoolScheduler {
    public schedulerPoolId: string;
    public poolId: number;
    public timeZone: string;
    public useRegionTimeZone: boolean;
    public schedulers: Schedule[];

    constructor(data?: any) {
        this.schedulerPoolId = data && data.schedulerPoolId ? data.schedulerPoolId : '0';
        this.poolId = data && data.poolId ? data.poolId : 0;
        this.timeZone = data && data.timeZone ? data.timeZone : '';
        this.useRegionTimeZone = data && data.useRegionTimeZone ? data.useRegionTimeZone : false;
        this.schedulers = [];
        if (data && data.schedulers && data.schedulers.length) {
            this.schedulers = data.schedulers.map(item => new Schedule(item));
        }
    }
}
