export class Integration {
  public integrationId: number;
  public companyId: number;
  public name: string;
  public active: boolean;
  public platformId: number;
  public organizationId: number;
  public googleInt: {
    integrationId: number;
    projectId: string;
    clientSecret: string;
    clientId: string;
    fileP12: string;
    fileJson: string;
    kmsP12: string;
    kmsJson: string
  };
  public googleIntInf: {
    integrationId: number;
    name: string;
    email: string;
    image: string;
    url: string
  };
  constructor(data?: any) {
    this.integrationId = data && data.integrationId ? data.integrationId : null;
    this.name = data && data.name ? data.name : '';
    this.companyId = data && data.companyId ? data.companyId : '';
    this.active = data && data.active ? data.active : true;
    this.platformId = data && data.platformId ? data.platformId : 0;
    this.organizationId = data && data.organizationId ? data.organizationId : 0;
    this.googleInt = data && data.googleInt ? data.googleInt : {};
    this.googleIntInf = data && data.googleIntInf ? data.googleIntInf : null;
  }
}
