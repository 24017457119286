export class MappedDrive {
  public id: number;
  public name: string;
  public type: string;
  public status: string;
  public letter: string;
  public cloudUsers: number [];
  public securityGroups: number [];

  constructor(data ? : Object) {
    this.id = data && data['id'] ? data['id'] : 0;
    this.name = data && data['name'] ? data['name'] : '';
    this.letter = data && data['letter'] ? data['letter'] : '';
    this.status = data && data['status'] ? data['status'] : '';
    this.type = data && data['type'] ? data['type'] : '';
    this.cloudUsers = data && data['cloudUsers'] ? data['cloudUsers'] : [];
    this.securityGroups = data && data['securityGroups'] ? data['securityGroups'] : [];
}
}
