export class VpnTunnelsDashboardDto {
  public name: string;
  public description: string;
  public region: string;
  public ipAddreess: string;
  public amountTunnels: number;
  public amountTunnelsUp: number;
  public amountTunnelsDown: number;
  constructor( data?: any) {
    this.name =  data && data.Name ? data.Name  : '';
    this.description = data && data.description ? data.description : '';
    this.region = data && data.region ? data.region : '';
    this.ipAddreess = data && data.ipAddreess ? data.ipAddreess : '';
    this.amountTunnels = data && data.amountTunnels ? data.amountTunnels : 0;
    this.amountTunnelsUp = data && data.amountTunnelsUp ? data.amountTunnelsUp : 0;
    this.amountTunnelsDown = data && data.amountTunnelsDown ? data.amountTunnelsDown : 0;
  }
}
