export class CertificateDto {
    public fileName: string;
    public mimeType: string;
    public fileData: string;
    constructor(data ? : any) {
        this.fileName = data && data.fileName || '';
        this.mimeType = data && data.mimeType || '';
        this.fileData = data && data.fileData || '';
    }
}
