import { ProcessActivity } from '.';

export class ListDtoProcess {
    public inProgress: number;
    public error: number;
    public list : Array<ProcessActivity>;

    constructor(data ? : any) {
        this.inProgress = data && data.inProgress ? data.inProgress : 0;
        this.error = data && data.error ? data.error : 0;
        this.list = data &&  data.list ? data.list : [];
    }

}