import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Notification, NotificationType } from 'src/app/models';
import { AutoUnsubscribeComponent } from 'src/app/shared/auto-unsubscribe/auto-unsubscribe.component';
import { NotificationPaneActionService } from './notification-pane-action.service';

import { locale as english } from './i18n/notification-pane.en';
import { locale as spanish } from './i18n/notification-pane.es';

@Component({
  selector: 'app-notification-pane',
  templateUrl: './notification-pane.component.html',
  styleUrls: ['./notification-pane.component.scss']
})
export class NotificationPaneComponent extends AutoUnsubscribeComponent implements OnInit {

  public translations: Object;
  public countErrors: number;
  public countOthers: number;
  public notifErrors: Array<Notification>;
  public notifOthers: Array<Notification>;
  public failed: NotificationType;

  constructor(private translate: TranslateService, private notifPaneActionService: NotificationPaneActionService) {
    super();
    this.setTranslations();
  }

  public ngOnInit() {
    this.failed = NotificationType.Failed;
    this.setTranslations();
  }

  public closeSideNav(): void {
    this.notifPaneActionService.setCloseSideNav(false);
  }

  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
    this.translations = {
      viewAll: this.translate.instant('notification_pane.VIEW_ALL')
    };
  }

}
