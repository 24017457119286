import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { Certificate, CertificateDto, ListDto, Region, SoltenantGroup } from 'src/app/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegionsPostProvisionService {

  constructor(private http: HttpClient) { }
  public getRegionById(deploymentId: number, regionId: number): Observable<Region> {
    return this.http.get<Region>(`${environment.apiUrl}deployments/${deploymentId}/regions/${regionId}`);
  }

  public getCertificateByRegionId(deploymentId: number, regionId: number): Observable<CertificateDto> {
    return this.http.post<CertificateDto>(`${environment.apiUrl}deployments/${deploymentId}/regions/${regionId}/cert`, {});
  }

  public updateCertificateToRegion(deploymentId: number, regionId: number, cert: Certificate): Observable<Certificate> {
    return this.http.put<Certificate>(`${environment.apiUrl}deployments/${deploymentId}/regions/${regionId}/certificate`, cert);
  }

  public getDeploymentRegionsV2(deploymentId: number): Observable<ListDto<Region>> {
    return this.http.get<ListDto<Region>>(`${environment.apiUrl}deployments/${deploymentId}/regions`);
  }

  public validateExternalDns(deploymentId: number, regionId: number,  recordDns : string): Observable<{}> {
    return this.http.post(`${environment.apiUrl}deployments/` +
      `${deploymentId}/regions/${regionId}/externalDns?recordDns=${recordDns}`, {});
  }

  public saveExternalDns(deploymentId: number, regionId: number,  recordDns : string): Observable<{}> {
    const params = new HttpParams().set('externalDns', recordDns);
    return this.http.patch(`${environment.apiUrl}deployments/` + `${deploymentId}/regions/${regionId}/externalDns`, {}, { params: params });
  }

  public getSoltenantGroupByRegion(deploymentId: number, regionId: number): Observable< ListDto<SoltenantGroup> > {
    return this.http.get<ListDto<SoltenantGroup>>(`${environment.apiUrl}deployments/${deploymentId}/soleTenantsGroup/regions/${regionId}`);
  }

  public putDeploymentRegion(deploymentId: number, regionId: number, region: Region): Observable<Region> {
    return this.http.put<Region>(`${environment.apiUrlV3}deployments/${deploymentId}/regions/${regionId}`, region);
  }
}
