export class TaskLicense {
    public taskId: number;
    public taskInfoProperties: Object;
    public status: string;
    public certificate: string;
    public password: string;
    public organizationId: number;
    public rdLicense: {
        licenseProgramId: number,
        licenseCode: string,
        usersCount: number
    };
    constructor(data? : any) {
        this.taskId = data && data.taskId ? data.taskId : 0;
        this.taskInfoProperties = data && data.taskInfoProperties ? data.taskInfoProperties : {};
        this.status = data && data.status ? data.status : '';
        this.certificate = data &&  data['certificate'] ? data['certificate'] : '';
        this.password = data && data['password'] ? data['password'] : '';
        this.organizationId = data && data.organizationId ? data.organizationId : 0;
        this.rdLicense = data && data.rdLicense ? data.rdLicense : {
            licenseProgramId: 0,
            licenseCode: '',
            usersCount: 1
        };
    }
}


