import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit,
        Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import * as _ from 'underscore';

import { BinaryExpressionService, OperationExpressionService, UnaryExpressionService } from 'src/app/core/shared/filters';
import { NotificationActions } from 'src/app/core/shared/notifications/enum/notifications.actions';
import { NotificationResources } from 'src/app/core/shared/notifications/enum/notifications.resources';
import { NotificationTranslateService } from 'src/app/core/shared/notifications/notification-translate.service';
import { DeploymentsService } from 'src/app/deployments/deployments.service';
import { Deployment, HttpStatusCodes, Integration, ListDto, ProvisionTypes,
       Region, ServerError, Settings, SoleTenant, ValidationErrorsCodes } from 'src/app/models';
import { GoogleProjectService } from 'src/app/provision/google-project/google-project.service';

import { NotifierService } from 'angular-notifier';
import { ProvisionTypeApis } from '../../apis-check-manager/enum';
import { ServiceAccountRolesService } from '../../apis-check-manager/services/service-account-roles-service';
import { AutoUnsubscribeComponent } from '../../auto-unsubscribe/auto-unsubscribe.component';
import { ProvisionSoletenantService } from '../../provision-soletenant/provision-soletenant.service';
import { ConfigureRegionAdvancedComponent } from '../configure-region-advanced/configure-region-advanced.component';
import { ConfigureRegionDomainComponent } from '../configure-region-domain/configure-region-domain.component';
import { ConfigureRegionProvisionService } from '../configure-region-provision.service';
import { ConfigureRegionServersRolesComponent } from '../configure-region-servers-roles/configure-region-servers-roles.component';
import { InfrastructureRegionProvision } from '../model';
import * as c from './../const';

import { locale as english } from '../i18n/configure-region-post-provision.en';
import { locale as spanish } from '../i18n/configure-region-post-provision.es';

@Component({
  selector: 'app-configure-region-post-provision-card',
  templateUrl: './configure-region-post-provision-card.component.html',
  styleUrls: ['./configure-region-post-provision-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfigureRegionPostProvisionCardComponent extends AutoUnsubscribeComponent implements OnInit, AfterViewInit {

  @Input()
  public deploymentId: number;
  @Output()
  public actionConfirm = new EventEmitter<InfrastructureRegionProvision>();

  public translationObject: object;
  public submitted: boolean;
  public isValidComponent: boolean;
  public loadingConfigRegion: boolean;
  public deploymentSettings: Settings;
  public regionSelected: Region;
  public deployment: Deployment;
  public isWindow10: boolean;
  public selectedPoolsId: Array<number>;
  public paramsFilterPool : Array<Object>;
  public isValidating: boolean;
  public regionInfrastructure: InfrastructureRegionProvision;
  public serverError: ServerError;
  public isInvalidQuota: boolean;
  public isNeedAddons: boolean;
  public addonsMessage: string;
  public isStDeployment: boolean;
  public integration: Integration;
  public saMessage: string;
  public validPermission : boolean;
  public maxConfiguredMessage : string;

  @ViewChild(ConfigureRegionDomainComponent, {static: false})
  public regionDomainComponent: ConfigureRegionDomainComponent;

  @ViewChild(ConfigureRegionServersRolesComponent, {static: false})
  public regionServerRoles: ConfigureRegionServersRolesComponent;

  @ViewChild(ConfigureRegionAdvancedComponent, {static: false})
  public regionAdvanced: ConfigureRegionAdvancedComponent;

  constructor(private translate: TranslateService, private deploymentService: DeploymentsService,
              private operationExpression: OperationExpressionService,
              private binaryExpression: BinaryExpressionService,
              private unaryExpression: UnaryExpressionService, private cdr: ChangeDetectorRef,
              private configureRegionService:  ConfigureRegionProvisionService,
              private notifierTranslate: NotificationTranslateService,
              private provisionSoletenantService: ProvisionSoletenantService,
              private googleProjectService: GoogleProjectService,
              private serviceAccountRolesService:  ServiceAccountRolesService, private notifier: NotifierService ) {
    super();
   }

  public ngAfterViewInit() { this.setTranslations(); }

  public ngOnInit() {
    this.setTranslations();
    this.submitted = false;
    this.isValidComponent = false;
    this.loadingConfigRegion = false;
    this.isValidating = false;
    this.selectedPoolsId = [];
    this.saMessage = '';
    this.validPermission = false;
    this.subscribeDeployment();
    this.getData();

    this.paramsFilterPool = [{
      'propertyName': 'CollectionPoolType.Code',
      'operation': this.operationExpression.expressionType.EQUALS,
      'value': 'w10dd', 'toLower': true,
      'unaryOperation': this.unaryExpression.expressionType.NOT,
      'binaryOperation': this.binaryExpression.expressionType.AND_ALSO
    },                       {
      'propertyName': 'CollectionPoolType.Code',
      'operation': this.operationExpression.expressionType.EQUALS,
      'value': 'pcp', 'toLower': true,
      'unaryOperation': this.unaryExpression.expressionType.NOT,
      'binaryOperation': this.binaryExpression.expressionType.AND_ALSO
    }];
  }

  public clickedPool($event): void {
      this.selectedPoolsId = _.clone($event);
      this.isValidating = false;
  }

  public onActionConfirmation($event): void {
    if (!$event) {
        this.actionConfirm.emit(null);
    } else {
      this.submitted = true;
      this.validateComponent();
      if ( this.isValidComponent) {
        this.getDataToSaveRegion();
        this.saveMultiple();
      }
    }
  }

  public setRegionSelected($event: Region): void {
      this.regionSelected = $event;
      this.regionAdvanced.setRegionSelected(this.regionSelected);
  }

  public validateComponent(): void {
    this.isValidComponent = true;
    this.isValidating = true;
    this.isInvalidQuota = false;
    this.isNeedAddons = false;
    this.maxConfiguredMessage = '';
    this.saMessage = '';

    // Collection Pool
    const validPool = !this.isWindow10 ? this.selectedPoolsId.length > 0 : true;

    // Region Domain
    const validRegionDomain = this.regionDomainComponent.isValidComponent();

    // Region Server Roles
    const validRegionServerRoles = this.regionServerRoles.isValidComponent();

    // Region Advanced
    const validRegionAdvanced = this.regionAdvanced.isValidComponent();

    this.isValidComponent = validPool && validRegionDomain && validRegionServerRoles && validRegionAdvanced;

    this.cdr.detectChanges();

    if (!this.isValidComponent) { this.submitted = false; }

  }

  public getDataToSaveRegion(): void {
      this.regionInfrastructure = new InfrastructureRegionProvision();
      this.regionDomainComponent.fillInfrastructureRegionModel(this.regionInfrastructure);
      this.regionServerRoles.fillInfrastructureRegionModel(this.regionInfrastructure);
      this.regionAdvanced.fillInfrastructureRegionModel(this.regionInfrastructure);
      this.regionInfrastructure.collectionsPoolsId = this.selectedPoolsId;
  }

  private getDeploymentSettings(): Observable<Settings> {
    return this.deploymentService.getDeploymentSettings(this.deploymentId);
  }

  private getSoletenantDeployment(): Observable<ListDto <SoleTenant>> {
    return this.provisionSoletenantService.getSoleTenantConfiguration(this.deploymentId);
  }

  private getIntegration(): Observable<Integration> {
    return this.googleProjectService.getIntegration(this.deploymentId);
  }

  private getData(): void {
    this.loadingConfigRegion = true;
    const requestList = [this.getDeploymentSettings(), this.getSoletenantDeployment(), this.getIntegration()];
    const requestListSubs = forkJoin(requestList)
    .pipe(finalize(() => {
      this.loadingConfigRegion = false;
     }))
    .subscribe((data: Object[]) => {
      if (data.length === requestList.length) {
        this.deploymentSettings = data[0] as Settings;
        const listSt = data[1] as ListDto <SoleTenant>;
        this.isStDeployment = listSt.list.length > 0;
        this.integration = data[c.two] as Integration;
      }
     });
    super.addSubscriptions(requestListSubs);
  }

  private manageServerError(): void {
    if (this.serverError) {
      // 500 Error
      if (this.serverError.status === HttpStatusCodes.InternalServerError) {
        this.notifier.notify('error', this.translationObject['ErrorOnCloud']);
        return;
      }

      // Invalid Quotas
      if (this.serverError.code === ValidationErrorsCodes.ValidationError40032) {
      this.isInvalidQuota = true;
      } else {
        // Buy Addons
        if (this.serverError.code === ValidationErrorsCodes.ValidationError40031) {
            this.isNeedAddons = true;
            this.addonsMessage = this.serverError['message'];
        } else {
          // Maximum regions being configured
          if ( this.serverError.errors['Status'] && this.serverError.errors['Status'].includes(c.errorRegionMaximum)) {
            this.maxConfiguredMessage = this.serverError.errors['Status'];
          }
          // Errors domain
          this.regionDomainComponent.setServerErrors(this.serverError);
        }
      }
    }
  }

  private async saveMultiple(): Promise<void> {
      if ( this.regionServerRoles.isGenerateCloudSql() && !this.regionServerRoles.validApis ) {
        await this.validateApis();
      }
      await this.saveRegion();
  }


  private async validateApis(): Promise<void> {
        return new Promise(async (resolve, reject) => {
        // Check apis if generate cloud sql
        const googleInt = this.integration.googleInt;
        this.serviceAccountRolesService.initService(googleInt.projectId , googleInt.clientId, ProvisionTypeApis.CloudSql);

        // assign permissions service account
        if (!this.validPermission ) {
          await this.serviceAccountRolesService.assignPermissions();
        }

        if ( this.serviceAccountRolesService.errors) {
            // could not assign permissions
            this.saMessage = this.serviceAccountRolesService.errors['message'] ||
                              this.serviceAccountRolesService.errors['error'] &&
                              this.serviceAccountRolesService.errors['error']['message'];
            this.submitted = false;
            reject();

          } else {
            this.validPermission = true;
            const $promise = this.regionServerRoles.syncApis();
            $promise.then((value: boolean) => {
              if (value === true) {
                resolve();
              } else {
                  this.submitted = false;
                  reject();
              }
            });
          }
    });
  }

  private async saveRegion(): Promise<void> {
      // Add Region
      await this.addRegion();

       // Save region certificated if external Dns Name is changed
      if ( this.regionDomainComponent.isSaveCertificateRegion() ) {
          await this.regionDomainComponent.regionCertificatedComponent.saveCertificateToRegion();
      }
      this.submitted = false;
      this.actionConfirm.emit(this.regionInfrastructure);

      if (!this.regionDomainComponent.isValidSavedCertificate()) {
        this.notifierTranslate.warn(NotificationActions.create, NotificationResources.region,
          this.translationObject['MsgRegionPartially'], true);
      } else {
        this.notifierTranslate.success(NotificationActions.create, NotificationResources.region,
          this.translationObject['MsgRegionSuccess'], true);
      }
  }

  private addRegion(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const getRegionsSub = this.configureRegionService.addRegion(this.deploymentId, this.regionInfrastructure)
     .subscribe(
        (region: InfrastructureRegionProvision) => {
          resolve(true);
      }, (ex) => {
        this.submitted = false;
        this.serverError = ex.error || ex;
        this.manageServerError();
        reject(true);
      });
      super.addSubscriptions(getRegionsSub);
    });

  }

  private subscribeDeployment(): void {
    const deploymentSelectedSubs = this.deploymentService.deploymentSelected.subscribe((company: Deployment) => {
      if (company) {
         this.deployment = company;
         this.isWindow10 = company.provisionType === ProvisionTypes.W10;
      }
    });
    super.addSubscriptions(deploymentSelectedSubs);
  }

  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }

    this.translationObject = {
      'SectionTitle' : this.translate.instant('configure_region.SECTION_COLLECTION_TITLE'),
      'AddRegion': this.translate.instant('configure_region.ADD_REGION'),
      'ErrorOnCloud': this.translate.instant('configure_region.ERROR_ONCLOUD'),
      'MsgRegionSuccess': this.translate.instant('configure_region.MSG_SUCCESS_ADD_REGION'),
      'MsgRegionPartially': this.translate.instant('configure_region.MSG_SUCCESS_ADD_REGION_PARTIALLY'),
      'ErrorRequired': this.translate.instant('configure_region.ERROR_REQUIRED'),
      'ActionsButtons': [this.translate.instant('configure_region.CANCEL'),
                         this.translate.instant('configure_region.ADD'),
                         this.translate.instant('configure_region.ADDING')]
     };
  }

}
