import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { timer } from 'rxjs';
import * as _ from 'underscore';

import { fileExtensionValidator } from 'src/app/core/shared/pipes';
import { Certificate, CertificateDto } from 'src/app/models';
import { AutoUnsubscribeComponent } from '../../auto-unsubscribe/auto-unsubscribe.component';
import { ConfigureRegionCertificateService } from './configure-region-certificate.service';

import { locale as english } from './i18n/configure-region-certificate.en';
import { locale as spanish } from './i18n/configure-region-certificate.es';


const extensionList = ['.pfx'];
const fileKey = 'SslCertificateFile';
const passwordKey = 'SslCertificatePassword';

@Component({
  selector: 'app-configure-region-certificate',
  templateUrl: './configure-region-certificate.component.html',
  styleUrls: ['./configure-region-certificate.component.scss']
})
export class ConfigureRegionCertificateComponent extends AutoUnsubscribeComponent implements OnInit, AfterViewInit {
  @Input()
  public deploymentId: number;
  @Input()
  public regionId: number;

  public certificateFile: CertificateDto;
  public defaultCertificateFile: CertificateDto;
  public loadingCertificate: boolean;
  public showPasswordField: boolean;
  public hidePasswordValue: boolean;
  public loadingCurrentRegion: boolean;

  public certificateForm: FormGroup;
  public validationType: object;
  public translationObject: Object;
  public isEdited: boolean;
  public serverError: Object;

  constructor(private formBuilder: FormBuilder, private regionsService: ConfigureRegionCertificateService,
              private translate: TranslateService) {
    super();
  }

  get f() { return this.certificateForm ? this.certificateForm.controls : null; }

  public ngOnInit(): void {
    this.isEdited = false;
    this.serverError = null;
    this.setTranslations();
    this.certificateForm = this.formBuilder.group({
      SslCertificateFile: [{ value: '', disabled: true }],
      SslCertificatePassword: [{ value: '', disabled: false }]
    });

    this.validationType = {
      'SslCertificateFile': [Validators.required, fileExtensionValidator(extensionList)],
      'SslCertificatePassword': [Validators.required, Validators.pattern(/^[^\s]+(\w.*)?$/)]
    };

    this.defaultCertificateFile = new CertificateDto();
    this.certificateFile = new CertificateDto();

    this.setModeEdit(this.isEdited);
  }

  public ngAfterViewInit() {
    this.setTranslations();
  }

  public setModeEdit(edit: boolean): void {
      this.isEdited = edit;
      timer().subscribe(() => {
        this.certificateForm.get('SslCertificateFile').setErrors(null);
        this.certificateForm.get('SslCertificatePassword').setErrors(null);
      });
      if ( this.isEdited) {
        this.certificateForm.get('SslCertificateFile').setValue('');
        this.certificateForm.get('SslCertificateFile').enable();
      } else {
        this.certificateForm.get('SslCertificateFile').setValue( this.translationObject['ProvideItopiaText']);
        this.certificateForm.get('SslCertificateFile').disable();
      }
  }

  public isValidComponent(): boolean {
    if (this.isEdited) {
      this.setValidations(null);
      return this.certificateForm.valid;
    }
    return true;
  }

  public setValidations(input: string): void {
      // tslint:disable-next-line:forin
      for (const key in this.f) {
        if (_.isString(this.f[key].value)) {
          this.f[key].setValue(this.f[key].value.toString().trim());
        }
        if (key === input) {
          this.f[key].setValidators(this.validationType[key]);
          this.f[key].updateValueAndValidity();
          break;
        } else if (!input) {
          this.f[key].setValidators(this.validationType[key]);
          this.f[key].updateValueAndValidity();
        }
      }
  }

  public passChanged(): void {}

  public dropFile(files: NgxFileDropEntry[]): void {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.uploadFile(file);
        });
      } else {
        this.mapFileErrors({ prop: fileKey, message: this.translationObject['InvalidFormatCertificateLabel'] });
      }
    }
  }

  public saveCertificateToRegion(): Promise<boolean> {
    const data = new Certificate({
      name: this.certificateFile.fileName,
      data: this.certificateFile.fileData,
      password: this.f[passwordKey].value
    });
    this.serverError = null;
    return new Promise((resolve, reject) => {
      const updateCertSubs = this.regionsService.updateCertificateToRegion(this.deploymentId, this.regionId, data)
      .subscribe(() => {
          resolve(true);
      },
      (ex) => {
        this.serverError = ex;
        resolve(true);
      });
      super.addSubscriptions(updateCertSubs);
    });
  }

  private clearPass(): void {
    this.f.SslCertificatePassword.setValue(null);
    this.f.SslCertificatePassword.setValidators(null);
    this.f.SslCertificatePassword.setErrors(null);
    this.f.SslCertificatePassword.markAsUntouched();
    this.f.SslCertificatePassword.updateValueAndValidity();
  }

  private mapFileErrors(error: { prop: string, message: string }): void {
    this.certificateForm.get(error.prop).setErrors({ serverError: error.message });
  }

  private setErrors(isPendingUpload: boolean): void {
    this.f[fileKey].setErrors({uploadNew: isPendingUpload});
    this.f[fileKey].markAsTouched();
    if (!isPendingUpload) {
      this.f[fileKey].updateValueAndValidity();
      this.f[fileKey].markAsUntouched();
    }
  }

  private uploadFile(file: File): void {
    try {
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        const data = fileReader.result as string;
        const f = data.split('data:')[1],
          splited = f.split(';'),
          mimetype = splited[0] || 'text/plain',
          secondSplit = splited[1].split(','),
          content = secondSplit[1];
        this.certificateFile = new CertificateDto({
          fileName: file.name,
          mimeType: mimetype,
          fileData: content
        });
        this.f[fileKey].patchValue(this.certificateFile.fileName);
        this.f[fileKey].setErrors({uploadNew: null});
        this.setValidations(fileKey);
        this.showPasswordField = true;
        this.clearPass();
      };
      fileReader.readAsDataURL(file);
    } catch (ex) {
      this.mapFileErrors({ prop: fileKey, message: this.translationObject['InvalidFormatCertificateLabel'] });
    }
  }

  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }

    this.translationObject = {
      'SslCertificatedLabel' : this.translate.instant('configure_region_certificate.SSL_CERTIFICATE'),
      'UploadCertificateLabel' : this.translate.instant('configure_region_certificate.UPLOAD'),
      'PasswordCertificateLabel' : this.translate.instant('configure_region_certificate.PASSWORD'),
      'MaxSizeCertificateLabel' : this.translate.instant('configure_region_certificate.MAX_SIZE_VALIDATION'),
      'EmptyFileCertificateLabel' : this.translate.instant('configure_region_certificate.EMPTY_FILE'),
      'InvalidFormatCertificateLabel' : this.translate.instant('configure_region_certificate.FORMAT_VALIDATION'),
      'ErrorRequired' : this.translate.instant('configure_region_certificate.ERROR_REQUIRED'),
      'ErrorPattern' : this.translate.instant('configure_region_certificate.ERROR_PATTERN'),
      'ErrorUploadNew' : this.translate.instant('configure_region_certificate.ERROR_UPLOAD_NEW'),
      'CertificatedDescription': this.translate.instant('configure_region_certificate.CERTIFICATE_DESCRIPTION'),
      'ProvideItopiaText': this.translate.instant('configure_region_certificate.PROVIDE_ITOPIA_TEXT')
    };
  }
}
