import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { Notification, ListDto } from './../../models';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsListService {

  constructor(private http: HttpClient) { }

  public getNotifications(params): Observable<ListDto<Notification>> {
    const paramsObj = new HttpParams().set('pageIndex', params.pageIndex).set('pageSize', params.pageSize);
    return this.http.get<ListDto<Notification>>(`${environment.apiUrl}notifications`, {params: paramsObj});
  }
  public deleteNotification(notificationId: any): Observable<any> {
    return this.http.request('DELETE', `${environment.apiUrl}notifications/${notificationId}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {}
    });
  }
  public processRetryNotification(notificationId: number, taskId: any): Observable<any> {
    const params = new HttpParams().set('taskId', taskId.toString());
    return this.http.post<any>(`${environment.apiUrl}notifications/${notificationId}/processRetry`, params);
  }
  public executeAutoscaling(deploymentId: number, poolId: number, schedule: string): Observable<Object> {
    const params = new HttpParams().set('schedule', schedule);
    const url = `${environment.apiUrl}deployments/${deploymentId}/collections/${poolId}/autoScaling`;
    return this.http.post<Object>(url, {}, { params: params });
  }
  public executeAutoscalingV3(deploymentId: number, poolId: number, schedule: string, collectionId: number,
                              processId: string, action: number, applyAll: boolean): Observable<Object> {    
    const params = new HttpParams().set('processId', processId)
                                   .set('action', action.toString())
                                   .set('applyAll', applyAll.toString())
                                   .set('schedule', schedule)
                                   .set('retry', 'true');
    const url = `${environment.apiUrlV3}deployments/${deploymentId}/collectionPools/${poolId}/collection/${collectionId}/autoScaling`;
    return this.http.post<Object>(url, {}, { params: params });
  }
  public disableNotification(notificationId: number): Observable<Object> {
    return this.http.patch<Object>(`${environment.apiUrl}notifications/${notificationId}/inactive`, {});
  }
}
