import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import * as _ from 'underscore';

import { EarlyAccessService } from 'src/app/core/security/early-access.service';
import { Deployment, EarlyAccessFeature, Region, RolTypes } from 'src/app/models';
import {DeploymentsActionsService} from '../../../deployments/deployments-actions.service';
import { DeploymentsService } from '../../../deployments/deployments.service';
import { AutoUnsubscribeComponent } from '../../auto-unsubscribe/auto-unsubscribe.component';
import { InfrastructureRegionProvision } from '../../configure-region-post-provision/model';
import { ProvisionSoletenantService } from '../../provision-soletenant/provision-soletenant.service';
import { RegionStatusEnum } from '../enum';
import { RegionsPostProvisionService } from './../service/regions-post-provision.service';

import { locale as english } from './i18n/regions-post-provision.en';
import { locale as spanish } from './i18n/regions-post-provision.es';
import { RoleTypeUserService } from 'src/app/core/security';

const two = 2;
@Component({
  selector: 'app-regions-post-provision-list',
  templateUrl: './regions-post-provision-list.component.html',
  styleUrls: ['./regions-post-provision-list.component.scss']
})
export class RegionsPostProvisionListComponent extends AutoUnsubscribeComponent implements OnInit, OnChanges {
  public isLoadingRegion: boolean;
  public isEditing: boolean;
  public regions: Region[];
  public regionEditedIndex: number;
  public primaryLabel: string;
  public deployRegionsLabel: string;
  public regionsStatus: any;
  public regionDefinition1: string;
  public regionDefinition2: string;
  public regionDefinition3: string;
  public dnsFailed: string;
  public dnsVerified: string;
  public deploymentSelected: Deployment;
  public isSoltenantConfigDeployment: boolean;
  public showCertificate: boolean;
  public isAccessAddRegion: boolean;
  public isAddingRegion: boolean;
  public addRegionText: string;
  public statusRegion: Object;

  private deploymentId: number;

  constructor(private regionsPostProvisionSvc: RegionsPostProvisionService,
              private translate: TranslateService, private router: Router,
              private deploymentService : DeploymentsService, private deploymentActionService: DeploymentsActionsService,
              private provisionSoletenantService: ProvisionSoletenantService, private earlyAccessService: EarlyAccessService,
              private roleTypeUserService: RoleTypeUserService ) {
    super();
    this.setTranslations();
    this.regions = [];
  }

  public ngOnInit() {
    setTimeout(() => {
      this.setTranslations();
    });
    const accessRole = this.roleTypeUserService.verifyAccess([RolTypes.DeploymentOwner, RolTypes.OrganizationOwner, RolTypes.Edit ]);
    this.isAccessAddRegion = this.earlyAccessService.verifyAccess(EarlyAccessFeature.AddProvisionRegion) && accessRole;
    this.isLoadingRegion = true;
    const deploymentSelectedSubscription = this.deploymentService.deploymentSelected.subscribe(deploymentSelected => {
      this.setTranslations();
      if (deploymentSelected && deploymentSelected.companyId) {
        this.deploymentSelected = deploymentSelected;
        this.deploymentId = deploymentSelected.companyId;
        if ( deploymentSelected.companyId !== this.deploymentId && this.deploymentId ) {
            this.isAddingRegion = false;
            this.isEditing = false;
        }
        this.regions = [];
        this.getDeploymentSettings();
        this.getSoleTenantConfiguration();
        this.getDeploymentRegions();
        if (this.deploymentId) {
          this.deploymentActionService.setDeploymentId(deploymentSelected.companyId);
        }
      }
    });
    super.addSubscriptions(deploymentSelectedSubscription);

    this.isEditing = false;
    this.regionsStatus = [];
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.regions) {
      this.regions = changes.regions.currentValue;
    }
  }

  public editRegion($index: number) {
    this.isEditing = true;
    this.regionEditedIndex = $index;
  }

  public addRegion(): void {
      this.isAddingRegion = true;
  }

  public actionConfirm($event: InfrastructureRegionProvision): void {
      this.isAddingRegion = false;
      if ($event) {
       const regionAdd = new Region({
          regionId: $event.regionId,
          externalAccessEndpoint:  $event.externalDnsName,
          name: $event.regionName
        });
       this.regions.push(regionAdd);
       this.getDeploymentRegions(true);
      }
  }

  public cancelEditHandler(): void {
    this.isEditing = false;
    this.regionEditedIndex = null;
  }

  public regionUpdatedHandler(updatedRegion: Region): void {
    this.regions[this.regionEditedIndex] = updatedRegion;
  }

  private getDeploymentRegions(updateSingle?: boolean): void {
    if ( !updateSingle) {
      this.isLoadingRegion = true;
      this.regionsStatus = [];
    }
    this.regionsPostProvisionSvc.getDeploymentRegionsV2(this.deploymentId)
      .pipe(finalize(() => {
        this.isLoadingRegion = false;
        this.getDnsRecordsStatus();
      }))
      .subscribe(regions => {
        this.regions = [];
        if (regions.list.length > 0) {
          regions.list.forEach(region => {
            this.regions.push(new Region(region));
          });
          this.regions = _.sortBy( this.regions, (r: Region) => {
            return !r.primary;
          });
        }
      });
  }

  private getDnsRecordsStatus(): void {
    if (this.regions.length > 0) {
      _.forEach(this.regions, r => {
        const subscriptionExternalDns = this.regionsPostProvisionSvc.validateExternalDns(this.deploymentId, r.regionId, r.dnsRecord)
          .subscribe(data => {
            this.setTranslations();
            let text = data && data['value']  && r.status === RegionStatusEnum.Ready ? this.statusRegion['ready'] : this.dnsFailed;
            if (r.status !== RegionStatusEnum.Ready) {
                text = r.status === RegionStatusEnum.PendingConfiguration ?
                                    this.statusRegion['pec'] : this.dnsFailed;
            }
            this.regionsStatus[r.regionId] = {
              resolve: data['value'] && r.status === RegionStatusEnum.Ready,
              tooltip: text,
              status: r.status
            };
          }, () => {
            this.regionsStatus[r.regionId] = {
              resolve: false,
              status: r.status,
              tooltip: r.status === RegionStatusEnum.PendingConfiguration ? this.statusRegion['pec'] : this.dnsFailed
            };
          });
        super.addSubscriptions(subscriptionExternalDns);
      });
    }
  }

  public getSoleTenantConfiguration(): void {
    const getConfigurationSubs = this.provisionSoletenantService.getSoleTenantConfiguration(this.deploymentId)
      .subscribe(soleTenants => {
        this.isSoltenantConfigDeployment = soleTenants.count > 0;
      });
    super.addSubscriptions(getConfigurationSubs);
  }


  private getDeploymentSettings(): void {
    const settingsSubs = this.deploymentService.getDeploymentSettings(this.deploymentId).subscribe((deploymentSetting) => {
      try {
        this.showCertificate = deploymentSetting.settings.gtw.toLowerCase() === 'true';
      } catch (error) {
        this.showCertificate = false;
      }
    });
    super.addSubscriptions(settingsSubs);
  }

  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
    this.primaryLabel = this.translate.instant('regions_list_post.PRIMARY');
    this.deployRegionsLabel = this.translate.instant('regions_list_post.DEPLOY_REGIONS');
    this.regionDefinition1 = this.translate.instant('regions_list_post.DESCRIPTION_REGION_POST_PROVISION');
    this.regionDefinition2 = this.translate.instant('regions_list_post.DESCRIPTION_REGION_POST_PROVISION_1');
    this.regionDefinition3 = this.translate.instant('regions_list_post.DESCRIPTION_REGION_POST_PROVISION_2');
    this.dnsFailed = this.translate.instant('regions_list_post.DNS_FAILED');
    this.dnsVerified = this.translate.instant('regions_list_post.DNS_VERIFIED');
    this.addRegionText = this.translate.instant('regions_list_post.ADD_REGION');
    this.statusRegion = {
      'ready' : this.translate.instant('regions_list_post.READY'),
      'pec': this.translate.instant('regions_list_post.PENDING_CONFIGURATION')
    };
  }
}
