import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'underscore';

export const collectionAliasValidator = function (control: AbstractControl): ValidationErrors | null {
const value: string = control.value || '';
if (!value) {
    return null;
  }
const notAllowed = new RegExp(/^[\.\s]+$/);
if (notAllowed.test(value)) {
return {
  pattern: 'Invalid format'
};
}
};
