import { CatalogUpdate } from '..';
export class CatalogWsus {
  public workstationId: number;
  public type: string;
  public config: string;
  public pendingUpdates: CatalogUpdate[];

  constructor(data?: any) {
    if (data) {
        Object.keys(data).forEach(key => {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                this[key] = data[key];
            }
        });
    }
}
}
