// Angular Imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';

// This Module's Components
import { FormButtonsModule } from '../shared/form-buttons/form-buttons.module';
import { SpinnerModule } from '../shared/spinner/spinner.module';
import { ViewsLayoutModule } from '../shared/views-layout/views-layout.module';
import { CompleteAdminInviteComponent } from './complete-admin-invite.component';

@NgModule({
    imports: [
        CommonModule, ViewsLayoutModule, SpinnerModule,
        FormsModule, ReactiveFormsModule,
        InternationalPhoneNumberModule,
        NgbTooltipModule, FormButtonsModule
    ],
    declarations: [
        CompleteAdminInviteComponent,
    ],
    exports: [
        CompleteAdminInviteComponent
    ]
})
export class CompleteAdminInviteModule {

}
