import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { Certificate } from 'src/app/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigureRegionCertificateService {

  constructor(private http: HttpClient) { }

  public updateCertificateToRegion(deploymentId: number, regionId: number, cert: Certificate): Observable<Certificate> {
    return this.http.put<Certificate>
    (`${environment.apiUrl}deployments/${deploymentId}/regions/${regionId}/certificate?isCreatingRegion=true`, cert);
  }

}

