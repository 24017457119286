import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'underscore';


let property = '';
let listControls = [];
// tslint:disable-next-line: max-line-length
export const validateDuplicateValueControl = function (listRef: Array<string>, prop: string): ValidationErrors | null {
    property = prop;
    listControls = listRef;
    return (control: AbstractControl) => {
            const listValues = [];
            let uniqueList = [];
            listControls.forEach(controlName => {
                let value = control.parent.get(controlName).value;
                if (property) {
                    value = value[property];
                    listValues.push(value);
                } else {
                    listValues.push(value);
                }
            });
            uniqueList = _.uniq(listValues, (item) => item);
            if (uniqueList.length !== listValues.length) {
                return {
                    resourceDuplicate: true
                };
            }
         };
};
