import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

const textNode = 3;
@Directive({
  selector: '[appTasksStatus]'
})
export class TasksStatusDirective implements OnInit, OnChanges {
  @Input()
  public taskStatus: string;
  @Input()
  public displayStatus: string;

  constructor(private el: ElementRef, private renderer: Renderer2, @Inject(DOCUMENT) private document) { }

  public ngOnInit() {
    this.getTaskStatus(this.taskStatus);
  }
  public ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.taskStatus) {
      this.taskStatus = changes.taskStatus.currentValue;
      this.getTaskStatus(this.taskStatus);
    }
  }
  private getTaskStatus(status: string) {
    const child = document.createElement('span');
    const childElements = this.el.nativeElement.childNodes;
    for (const children of childElements) {
        if (children.nodeType !== textNode && children.id !== 'copy') {
          this.renderer.removeChild(this.el.nativeElement, children);
        }
      }
    switch (status.toLowerCase()) {
      case 'new':
        child.className = 'fas fa-circle c-orange m-r-2';
        break;
      case 'done':
        child.className = 'fas fa-circle text-success m-r-2';
        break;
      default:
        child.className = 'fas fa-circle c-orange m-r-2';
        break;
    }
    child.setAttribute('title', this.displayStatus);
    this.renderer.insertBefore(this.el.nativeElement, child, this.el.nativeElement.firstChild);
  }

}
