import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class MatAbstractServerSideDtService {
  public selected$: BehaviorSubject<any[]>;
  public selected: Observable<any[]>;

  public externalRefresh$: BehaviorSubject<boolean>;
  public externalRefresh: Observable<boolean>;

  public instanceUnavailable$: BehaviorSubject<boolean>;
  public instanceUnavailable: Observable<boolean>;

  constructor() {
    this.selected$ = new BehaviorSubject<any[]>(undefined);
    this.selected = this.selected$.asObservable();
    this.externalRefresh$ = new BehaviorSubject<boolean>(undefined);
    this.externalRefresh = this.externalRefresh$.asObservable();
    this.instanceUnavailable$ = new BehaviorSubject<boolean>(undefined);
    this.instanceUnavailable = this.instanceUnavailable$.asObservable();
  }
  public setSelected(selected: any[]): void {
    this.selected$.next(selected);
  }
  public setExternalRefresh(refresh: boolean): void {
    this.externalRefresh$.next(refresh);
  }
  public setInstanceUnavailable(instanceUnavailable: boolean) {
    this.instanceUnavailable$.next(instanceUnavailable);
  }
}
