export const locale = {
  lang: 'en',
  login : {
    USERNAME_PH : 'Username',
    PASSWORD_PH : 'Password',
    FORGOT: `Forgot your password?`,
    LOGIN: `Sign in`,
    LOGININ: `Signing in...`,
    ITOPIA: `Sign in to itopia`,
    SIGN_UP_ITOPIA: `Sign up to itopia`,
    SIGN_IN_WITH: `Sign in with:`,
    REMEMBER: `Remember me`,
    DONT: `Don't have an account?`,
    SIGN_UP: `Go to marketplace`,
    RECOVER: `Recover Password`,
    RECOVER_DATA: `Enter your Email and instructions will be sent to you!`,
    RESET: `SEND RECOVERY LINK`,
    CANCEL: `Cancel`,
    RESET_SUCCESS: `A password reset link has been sent to your email address`,
    CONNECTION_ERROR: `Connection error`,
    ARGUMENT_ERROR: `The email address must be a valid string`,
    INVALID_EMAIL_ERROR: `The email address is badly formatted`,
    EXPIRED_ERROR: `The password reset code has expired`,
    RESET_CODE_ERROR: `The password reset code is invalid`,
    USER_DISABLED_ERROR: `The user corresponding to the given password reset code has been disabled`,
    USER_NOT_FOUND_ERROR: `There is no user in CAS corresponding to this email address`,
    WEAK_PASSWORD_ERROR: `The new password is not strong enough`,
    UNEXPECTED_ERROR: `Unexpected error`,
    SENDING: `Sending ...`,
    NOTIFICATION_TITLE: `Sign in`,
    NOTIFICATION_TEXT: `This email is associated with an active itopia account. Are you sure you want to authenticate through`,
    NOTIFICATION_TEXT2: `from now on?`,
    REQUIRED: `This field is required`,
    EMAIL: `This field should be an e-mail address in the format \"user@example.com\"`,
    INVALID_EMAIL: `The e-mail field is not a valid email address`,
    INVALID_CREDENTIALS: 'The username and password combination is incorrect',
    CURRENT_FAILS: `Your account is not registered with us. Please sign up in Google Cloud Marketplace`,
    BACK_TO: `Back to login`,
    NOTIFICATION_SIGN: `Sign in`,
    NOTIFICATION_SIGNING: 'Signing',
    ACCOUNT_DISABLED_ERROR: 'Access to this account has been temporarily disabled due to many failed login attempts.'
  }
};
