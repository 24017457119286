export class MatColumnSize {
  public xs: string[];
  public sm: string[];
  public md: string[];
  public lg: string[];
  public xl: string[];
  constructor() {
    this.xs = [];
    this.sm = [];
    this.md = [];
    this.lg = [];
    this.xl = [];
  }
}
