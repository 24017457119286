import { AfterViewInit, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { timer } from 'rxjs';
import { filter, finalize } from 'rxjs/operators';
import {IdleTimeoutService} from '../../core/security/idle-timeout-service';
import { redirectExcludedRoutes } from '../../core/shared/const';

import { EarlyAccessService } from 'src/app/core/security/early-access.service';
import { NotificationPaneActionService } from 'src/app/notifications/notification-pane/notification-pane-action.service';
import { BrandingActionService } from '../../branding/branding.action.service';
import { BrandingService } from '../../branding/branding.service';
import { Branding, Deployment, EarlyAccessFeature } from '../../models';
import { SubscriptionManageService } from '../../subscription/subscription-manage/subscription-manage.service';
import { AuthenticationService } from './../../core/security/authentication.service';
import { HttpCancelService } from './../../core/shared/services/http-cancel.service';
import { MenuEventsService } from './../../core/shared/services/menu-events.service';
import { DeploymentsService } from './../../deployments/deployments.service';
import { AutoUnsubscribeComponent } from './../../shared/auto-unsubscribe/auto-unsubscribe.component';

import { locale as english } from './i18n/full.en';
import { locale as spanish } from './i18n/full.es';

const renovateValue = 3300000;
const renovatePeriod = 3000000;
const hundred = 100;
const seventyFive = 75;
const fullPx = 250;

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent extends AutoUnsubscribeComponent implements AfterViewInit, OnInit {

  @ViewChild('logoDark', { static: false })
  private logoDark: ElementRef;

  @ViewChild('logoLight', { static: false })
  private logoLight: ElementRef;

  @ViewChild('sidenav', { static: false })
  private sidenav: any;

  public config: PerfectScrollbarConfigInterface = {};
  public supportLabel: string;
  public contactLabel: string;
  public whyLabel: string;
  public isMiniSideBar: boolean;
  public prevDeploymentId: number;
  public deploymentSelected: Deployment;
  public previousUrl: string;
  public currentUrl: string;
  public deploymentChanged: boolean;
  public isExpandOrCollapse: boolean;
  public isBrandingLoaded = false;
  public isExpandedPW: number;

  private countProcess: {listItemsCount: number, totalCount: number};
  private isExpanded: boolean;

  constructor(public router: Router, private translate: TranslateService, private httpCancelService: HttpCancelService,
              private menuEventsService: MenuEventsService, private authenticationService: AuthenticationService,
              private deploymentsMenuService: DeploymentsService, private subscriptionService: SubscriptionManageService,
              private brandingService: BrandingService, private renderer: Renderer2,
              private idleTimeoutService: IdleTimeoutService, private earlyAccessService: EarlyAccessService,
              private brandingActionService: BrandingActionService, private notifPaneActionService: NotificationPaneActionService) {
    super();
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
    this.supportLabel = this.translate.instant('full.SUPPORT');
    this.contactLabel = this.translate.instant('full.CONTACT');
    this.whyLabel = this.translate.instant('full.WHY');
    this.isExpandOrCollapse = true;
  }

  public tabStatus = 'justified';

  public isCollapsed = false;

  public innerWidth: any;
  public defaultSidebar: any;
  public showSettings = false;
  public showMobileMenu = false;
  public expandLogo = true;

  public options = {
    theme: 'light', // two possible values: light, dark
    dir: 'ltr', // two possible values: ltr, rtl
    layout: 'vertical', // fixed value. shouldn't be changed.
    sidebartype: 'full', // four possible values: full, iconbar, overlay, mini-sidebar
    sidebarpos: 'fixed', // two possible values: fixed, absolute
    headerpos: 'fixed', // two possible values: fixed, absolute
    boxed: 'full', // two possible values: full, boxed
    navbarbg: 'skin3', // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
    logobg: 'skin3' // six possible values: skin(1/2/3/4/5/6)
  };
  public widthFromSideBar: number;
  public widthFromProcessViewer: number;
  public paddingBottom: number;

  opened= false;

  public ngAfterViewInit() {
    this.getSubscription();
  }

  public ngOnInit() {
    this.previousUrl = this.router.url;
    this.deploymentChanged = false;
    this.toggleIsExpandedPW(false);
    const getDeploymentRecentSubscription = this.deploymentsMenuService.deploymentRecent.subscribe((data) => {
      if (data !== undefined && data.length === 0) {
        this.router.navigate(['/dashboard/nodeployment/']);
        return;
      } else if (data === undefined) {
        const getRecentDeploymentsSubscription = this.deploymentsMenuService.getRecentDeployments().subscribe((data) => {
          this.deploymentsMenuService.setDeploymentRecent(data.list);
          if (data.count === 0) {
            this.router.navigate(['/dashboard/nodeployment/']);
            return;
          }});
        super.addSubscriptions(getRecentDeploymentsSubscription);
      }
    });
    super.addSubscriptions(getDeploymentRecentSubscription);
    const deploymentSelectedSubscription = this.deploymentsMenuService.deploymentSelected.subscribe(deploymentSelected => {
      if (deploymentSelected && deploymentSelected.companyId && !redirectExcludedRoutes.some(route => this.router.url.startsWith(route))) {
        try {
          this.deploymentSelected = deploymentSelected;
          this.prevDeploymentId = deploymentSelected.companyId;
          this.deploymentChanged = true;
          const showInProgress = this.deploymentSelected.status &&  (this.deploymentSelected.status.toLowerCase() === 'inProgress'
            || this.deploymentSelected.status.toLowerCase()) ===  'not on cloud' || !this.deploymentSelected.status ? true : false;
          const showPendingDeletion = this.deploymentSelected.companiesStatusId === 4;
          if (!showInProgress && !showPendingDeletion) {
              this.router.navigate(['/dashboard/oncloud/' + this.deploymentSelected.companyId]);
            } else if (showPendingDeletion) {
              this.router.navigate(['/dashboard/deletion/' + this.deploymentSelected.companyId]);
            } else if (showInProgress) {
              this.router.navigate([`/provision/${this.deploymentSelected.companyId}/continue`]);
            }
          } catch(e) {
            console.log(e);
          }
        }
    });
    super.addSubscriptions(deploymentSelectedSubscription);

    this.defaultSidebar = this.options.sidebartype;
    this.handleSidebar();
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      const currentUrl = event.url;
      this.previousUrl = this.currentUrl;
      this.currentUrl = currentUrl;
      this.navigateToRootModule(currentUrl, this.previousUrl);
    });
    const renovateTime = timer(renovateValue, renovatePeriod);
    renovateTime.subscribe(() => {
      this.authenticationService.renovateToken();
    });
    if (sessionStorage.length === 0) {
      // Ask other tabs for session storage
      localStorage.setItem('getSessionStorage', JSON.stringify(sessionStorage));
    }

    const getLogoDarkSubs = this.brandingActionService.getLogoDark().subscribe((data: string) => {
      if (this.logoDark) {
        this.renderer.setAttribute(this.logoDark.nativeElement, 'src', data);
      }
    });
    super.addSubscriptions(getLogoDarkSubs);

    const getLogoLightSubs = this.brandingActionService.getLogoLight().subscribe((data: string) => {
      if (this.logoLight) {
        this.renderer.setAttribute(this.logoLight.nativeElement, 'src', data);
      }
    });
    super.addSubscriptions(getLogoLightSubs);
    this.idleTimeoutService.connectionStart();

    const closeSub = this.notifPaneActionService.closeSideNav.subscribe((opened) => {
      this.opened = opened;
    });
    super.addSubscriptions(closeSub);
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
     if (this.opened && !this.sidenav._elementRef.nativeElement.contains(event.target)) {
        // clicked outside => close dropdown list
     this.opened = false;
     }
  }

  @HostListener('window:resize', ['$event'])
  public onResize() {
    this.handleSidebar();
  }

  public handleSidebar() {
    this.innerWidth = window.innerWidth;
    switch (this.defaultSidebar) {
      case 'full':
      case 'iconbar':
        if (this.innerWidth < 767) {
          this.widthFromProcessViewer = hundred;
        } else if (this.innerWidth < 1170) {
          this.isExpandOrCollapse = false;
          this.options.sidebartype = 'mini-sidebar';
          this.widthFromSideBar = this.widthFromSideBar = this.calculatePercentage(seventyFive, this.innerWidth);
          this.widthFromProcessViewer = hundred - this.widthFromSideBar;
        } else {
          this.options.sidebartype = this.defaultSidebar;
          this.widthFromSideBar = this.widthFromSideBar = this.calculatePercentage(fullPx, this.innerWidth);
          this.widthFromProcessViewer = hundred - this.widthFromSideBar;
          this.isExpandOrCollapse = true;
        }
        break;
      case 'overlay':
        if (this.innerWidth < 767) {
          this.options.sidebartype = 'mini-sidebar';
          this.widthFromSideBar = hundred;
          this.widthFromProcessViewer = hundred - this.widthFromSideBar;
        } else {
          this.options.sidebartype = this.defaultSidebar;
          this.widthFromSideBar = this.calculatePercentage(fullPx, this.innerWidth);
          this.widthFromProcessViewer = hundred - this.widthFromSideBar;
        }
        break;
      default:
        this.isExpandOrCollapse = true;
        this.widthFromSideBar = this.calculatePercentage(fullPx, this.innerWidth);
        this.widthFromProcessViewer = hundred - this.widthFromSideBar;
    }
    this.isMiniSideBar = this.options.sidebartype === 'mini-sidebar';
    this.menuEventsService.emitMiniSideBar(this.options.sidebartype === 'mini-sidebar');
  }

  public calculatePercentage(part: number, all: number): number {
    return (part * hundred / all);
  }
  public toogleSideNav($event: boolean): void {
    this.opened = $event;
  }

  public toggleSidebarType() {
    this.isExpandOrCollapse = !this.isExpandOrCollapse;
    if (this.isExpandOrCollapse) {
      this.options.sidebartype = 'full';
      this.menuEventsService.emitMiniSideBar(false);
      this.widthFromSideBar = this.widthFromSideBar = this.calculatePercentage(fullPx, this.innerWidth);
      this.widthFromProcessViewer = hundred - this.widthFromSideBar;
    } else {
      this.options.sidebartype = 'mini-sidebar';
      this.menuEventsService.emitMiniSideBar(true);
      this.widthFromSideBar = this.widthFromSideBar = this.calculatePercentage(seventyFive, this.innerWidth);
      this.widthFromProcessViewer = hundred - this.widthFromSideBar;
    }
    this.isMiniSideBar = this.options.sidebartype === 'mini-sidebar';
  }

  private navigateToRootModule(currentUrl: string, previousUrl: string): void {
    const urlRegVal = new RegExp(/\/deployment\/[^]+\/([-a-zA-Z0-9@:;%_\+.~#?&//=])+\/([0-9])+|create|import/);
    const isDiffUrl = this.previousUrl !== this.currentUrl && !this.currentUrl.includes(this.previousUrl);
    const currentTest = urlRegVal.test(currentUrl);
    const previusTest = urlRegVal.test(this.previousUrl);
    if ((currentTest && previusTest) && isDiffUrl) {
      const index = currentUrl.lastIndexOf('/');
      if (index !== -1) {
        const rootModule = currentUrl.substring(0, index);
        this.router.navigate([rootModule]);
      }
    }
  }

  private getSubscription(): void {
    this.authenticationService.currentUser.subscribe((currentUser: any) => {
      if (currentUser && currentUser.current) {
        const organizationId = currentUser.current.organizationId;
        const getSubscriptionsSubs = this.subscriptionService.getBy(organizationId)
          .subscribe(subscription => {
            sessionStorage.setItem('subscription', JSON.stringify(subscription));
            this.subscriptionService.setCurrentSubscription(subscription);
            if (subscription.status.toLowerCase() === 'canceled') {
              this.router.navigate([`/subscription/canceled`]);
            }
          });
        super.addSubscriptions(getSubscriptionsSubs);
        this.getBranding(organizationId);
      }
    });
  }

  private getBranding(organizationId: number): void {
    const getBrandSub = this.brandingService.getBranding(organizationId)
    .pipe(finalize(() => {
      this.isBrandingLoaded = true;
    }))
    .subscribe((branding: Branding) => {
      if (branding) {
        const brandObj = new Branding(branding);
        if (brandObj.options.brand) {
          if (brandObj.favIconFileStore && brandObj.favIconFileStore.fileData) {
            const favIcon: HTMLLinkElement = document.querySelector('#favIcon');
            favIcon.href = `data:image/png;base64,${brandObj.favIconFileStore.fileData}`;
          }
          if (brandObj.logoFileStore && brandObj.logoFileStore.fileData) {
            if (this.logoDark) {
              this.renderer.setAttribute(this.logoDark.nativeElement,
                'src', `data:image/png;base64,${brandObj.logoFileStore.fileData}`);
            }
            if (this.logoLight) {
              this.renderer.setAttribute(this.logoLight.nativeElement,
                'src', `data:image/png;base64,${brandObj.logoFileStore.fileData}`);
            }
          }
        }
      }
    });
    super.addSubscriptions(getBrandSub);
  }

  @HostListener('window:pagehide', ['$event'])
  public doLogout(): void {
    this.authenticationService.logout();
  }

  public toggleIsExpandedPW($event): void {
    this.isExpanded = $event;
    if (!this.isExpanded) {
      this.isExpandedPW = 55;
      this.paddingBottom = 150;
    } else if (this.countProcess.listItemsCount <= 1 && this.countProcess.listItemsCount === this.countProcess.totalCount) {
        this.isExpandedPW = 260;
        this.paddingBottom = 258;
      } else if (this.countProcess.listItemsCount > 1 && this.countProcess.listItemsCount <= 4
      && this.countProcess.listItemsCount === this.countProcess.totalCount) {
      this.isExpandedPW = 248;
      this.paddingBottom = 258;
    } else {
      this.updateProcessVContainer(this.countProcess);
    }
  }
  public updateProcessVContainer($count: {listItemsCount: number, totalCount: number}): void {
    this.countProcess = $count;
    if (this.isExpanded) {
      if (this.countProcess.listItemsCount < 4 && this.countProcess.listItemsCount !== this.countProcess.totalCount) {
        this.isExpandedPW = 320;
        this.paddingBottom = 400;
      } else if (this.countProcess.listItemsCount === 4) {
        this.isExpandedPW = 355;
        this.paddingBottom = 400;
      } else if (this.countProcess.listItemsCount > 4) {
        this.isExpandedPW = 388;
        this.paddingBottom = 400;
      } else {
        this.toggleIsExpandedPW(true);
      }
    }
  }

  @HostListener('window:mousemove')
  public refreshUserState(): void {
    this.idleTimeoutService.resetTimeExpiredToken();
  }

}
