export class Task {
    public taskId: number;
    public name: string;
    public companyName: string;
    public status: string;
    public type: string;
    public registrationAt: string;
    public rdLicense: boolean;
    public failedProcess: string;
    public displayStatus: string;
    constructor(data ? : any) {
        this.taskId = data && data.taskId ? data.taskId : 0;
        this.name = data && data.name ? data.name : '';
        this.companyName = data && data.companyName ? data.companyName : '';
        this.status = data && data['status']['name'] ? data['status']['name'] : '';
        this.type = data && data['type'] && data['type']['name'] ?  data['type']['name'] : '';
        this.registrationAt = data && data['registrationAt'] ? data['registrationAt'] : '';
        this.rdLicense = data && data['rdLicense'];
        this.failedProcess = data && data.failedProcess ? data.failedProcess : null;
        this.displayStatus = data && data.displayStatus ? data.displayStatus : '';
    }
}
