import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';

import { AuthenticationService } from '../../core/security/authentication.service';
import {ModalService} from '../../core/shared/services/modal.service';
import { Feedback } from '../../models/support/feedback';
import { AutoUnsubscribeComponent } from './../../shared/auto-unsubscribe/auto-unsubscribe.component';
import { FeedbackService } from './feedback.service';

import { locale as english } from './i18n/feedback.en';
import { locale as spanish } from './i18n/feedback.es';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})

export class FeedbackComponent extends AutoUnsubscribeComponent implements OnInit {

  public feedbackTooltip: string;
  public feedbackPlace: string;
  public feedbackSuccess: string;
  public feedbackForm: FormGroup;
  public loading: boolean;
  public validationErrors = '';
  public submitted: boolean;
  public modalReference: any;
  public closeResult: string;
  public notification: { type: string; message: string; };
  public actionValue: string;
  public actionsButtons: any;
  public formButtonsComponent: any;
  public validationType: Object;

  constructor(private translate: TranslateService,
              private modalService: NgbModal,
              private formBuilder: FormBuilder,
              private authenticationService: AuthenticationService,
              private notifierService: NotifierService,
              private feedbackService: FeedbackService) {
      super();
  }
  // tslint:disable-next-line:member-access
  public ngOnInit(): void {
    this.setTranslation();
    this.submitted = false;
    this.loading = false;
    this.feedbackForm = this.formBuilder.group({
      Text: [{ value: '', disabled: false}]
    });
    this.validationType = {
      'Text': [Validators.required],
    };
  }
  get f() { return this.feedbackForm.controls; }

  // tslint:disable-next-line:member-access
  public onSubmit(): void {
    this.submitted = true;
    // stop here if form is invalid
    if (this.feedbackForm.invalid) {
      this.setTranslation();
      return;
    }
    this.sendFeedback();
  }
  public sendFeedback(): void {
    this.loading = true;
    const feedb = new Feedback();
    feedb.text = this.f.Text.value;
    feedb.email = this.authenticationService.currentUserValue ? this.authenticationService.currentUserValue.user.email : 'nobboao@hma.com';
    const sendFeedbackSubs = this.feedbackService.sendFeedback(feedb).subscribe(
      data => {
        this.showNotification(this.notification = { type: 'success', message: this.feedbackSuccess });
        this.closeFeedbackModal();
      },
      error => {
        this.setTranslation();
        this.validationErrors = error.error.errors;
        Object.keys(this.validationErrors).forEach(prop => {
          this.feedbackForm.get(prop).setErrors({ serverError: this.validationErrors[prop] });
        });
        this.setValidations('Text');
        this.loading = false;
      });
    super.addSubscriptions(sendFeedbackSubs);
  }

  public showFeedbackModal(content): void {
    const ngbModalOptions: NgbModalOptions = { size: 'xl', backdrop: 'static', keyboard: false };
    this.modalReference = this.modalService.open(content, ngbModalOptions);
  }

  public closeFeedbackModal() {
    this.feedbackForm.reset();
    this.loading = false;
    this.submitted = false;
    this.modalReference.close();
  }

  public showNotification(notification: { type: string; message: string; }) {
    this.notifierService.notify(notification.type, notification.message);
  }

  public onActionConfirmation(confirm) {
    if (confirm) {
      this.onSubmit();
    } else {
      this.closeFeedbackModal();
    }
  }
  public setValidations(input: string): void {
// tslint:disable-next-line:forin
    for (const key in this.f) {
      if (key === input) {
        this.f[key].setValidators(this.validationType[key]);
        this.f[key].updateValueAndValidity();
        break;
      } else if (!input) {
        this.f[key].setValidators(this.validationType[key]);
        this.f[key].updateValueAndValidity();
      }
    }
  }
  public onTouched(): void {
    this.f['Text'].markAsTouched();
  }
  private setTranslation(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
    this.feedbackTooltip = this.translate.instant('feedback.FEEDBACK');
    this.feedbackPlace = this.translate.instant('feedback.PLACEHOLDER');
    this.feedbackSuccess = this.translate.instant('feedback.SUCCESS');
    this.actionsButtons = [this.translate.instant('feedback.CANCEL'),
                           this.translate.instant('feedback.SEND'), this.translate.instant('feedback.SENDING')];
  }
}
