import { OuItem } from './ou-item';

export class OuLdapDto {
    public ouDefault: string;
    public ldapTreeDto: OuItem;

    constructor(data? : any) {
        this.ouDefault = data && data.ouDefault ? data.ouDefault : '';
        this.ldapTreeDto = data && data.organizationalUnitLdapTreeDto ? data.organizationalUnitLdapTreeDto : null;
    }
}
