import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-crud-views-layout',
  templateUrl: './crud-views-layout.component.html',
  styleUrls: ['./crud-views-layout.component.scss']
})
export class CrudViewsLayoutComponent implements OnInit {
  @Input()
  public title: string;
  @Input()
  public crudSubtitle: string;

  constructor() { }

  public ngOnInit() {
  }

}
