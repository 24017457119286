import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'underscore';


@Pipe({
  name: 'suffixDay'
})
export class SuffixDayPipe implements PipeTransform {
    public transform(day: number, language: string) {
      return language== 'es' ? this.getSpanishSuffix(day) : this.getEnglishSuffix(day);
    }

    private getSpanishSuffix(day:number):string {
      const str = day.toString();
      const d = str.charAt(str.length-1);
      switch (d)
      {
          case '0':
          case '7':
              return "mo";
           case '3':
              return "ro";
          case '4':
          case '5':
          case '6':
              return "to";
          case '8':
              return "vo";
          case '9':
              return "no";
          case '1':
            return  day === 31 || day === 1 || day === 21 ? 'ro' : 'no';
          case '2':
            return  day === 12 ? 'mo' : 'do'
       }
    }
    
    private getEnglishSuffix(day:number):string {
        switch (day)
        {
            case 1:
            case 21:
            case 31:
                return "st";
            case 2:
            case 22:
                return "nd";
            case 3:
            case 23:
                return "rd";
            default:
                return "th";
        }
    }


}



