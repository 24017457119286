export const locale = {
  lang: 'es',
  'configure_region' : {
    'REGION_LABEL': 'Region GCP',
    'SUBNET_LABEL': 'Subred',
    'SUBNETS_LABEL': 'Subredes',
    'EXTERNAL_DNS_NAME_LABEL': 'Nombre del DNS externo',
    'ADD_REGION': 'Adicionar Región',
    'ERROR_REQUIRED': 'Campo requerido',
    'ERROR_PATTERN': 'Campo inválido',
    'SECTION_COLLECTION_TITLE': 'Collecciones',
    'ZONES_LABELS': 'Seleccionar zonas manualmente',
    'ZONES_DESCRIPTION': `Si está deshabilitado, CAS seleccionará automáticamente dos zonas en la región para
                          recursos de implementación.`,
    'ZONE1_LABEL': 'Zona1',
    'ZONE2_LABEL': 'Zona2',
    'SOLETENANT_LABEL': 'Use sole-tenant en esta región',
    'SOLETENANT_DESCRIPTION': `Si la compatibilidad con sole-tenant está habilitada en la implementación,
                                proporcione el grupo de nodos predeterminado para las máquinas virtuales en esta región.
                                Cambiar esta configuración no afecta a ninguna VM existente, solo a los nuevos VMS.`,
    'ADVANCED_SETINGS': 'Configuración Avanzada',
    'CANCEL': 'Cancelar',
    'ADD': 'Adicionar',
    'ADDING': 'Adicionando ...',
    'REFRESH': 'Recargar',
    'INVALID_ZONE': 'Zona Inválida',
    'MSG_SUCCESS_ADD_REGION': 'La region ha sido guardada satisfactoriamente.',
    'MSG_SUCCESS_ADD_REGION_PARTIALLY': 'La region ha sido guardada parcialmente.',
    'ERROR_ONCLOUD': '¡Ups! Ha ocurrido un error. Póngase en contacto con el servicio de asistencia.',
  }
};
