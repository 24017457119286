import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const collectionAliasForbbiden = function(control: AbstractControl): ValidationErrors {
      const field : string = control.value || '';
      if (!control.value) {
        return;
      }
      const notAllowed = new RegExp(/^[\.\s]+$/);
      if (notAllowed.test(control.value)) {
        return {pattern: 'Invalid Format'} ;
      }
  };
