import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ApiStatusComponent } from './api-status.component';

@NgModule({
  declarations: [ApiStatusComponent],
  imports: [CommonModule, NgbModule ],
  exports: [ApiStatusComponent]
})
export class ApiStatusModule { }
