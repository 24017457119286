import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

import { environment } from 'src/environments/environment';

declare const gapi: any;
const urlServiceActivate = 'servicemanagement.googleapis.com/v1/services/serviceconsumermanagement.googleapis.com:enable';

@Injectable({
  providedIn: 'root'
})
export class QserviceconsumerService {
  public service = 'serviceconsumermanagement.googleapis.com';
  constructor() { }

  public loadGconsumerService(): void {
    try {
      return gapi.client.load('servicemanagement', environment.gapi.apiVersion);
    } catch (exception) {
      throwError(exception);
      return;
    }
  }
  public activate(consumer) {
    const params = {
      serviceName: this.service,
      consumerId: consumer
    };
    const request = gapi.client.servicemanagement.services.enable(params);
    return request.then(function (response) {
      if (response && response.result && response.result.name) {
        return Promise.resolve(response.result.name);
      }
      Promise.resolve(false);
    });
  }
  public hasEnabled(operationName) {
    const params = {
      name: operationName
    };
    const request = gapi.client.servicemanagement.operations.get(params);
    return request.then(function (response) {
      if (response.result) {
        return Promise.resolve(response.result);
      }
      return Promise.resolve({ done: false });
    }).catch(function (reason) {
      reason.request = {
        name: urlServiceActivate,
        params: params,
        errors: reason.errors
      };
      return Promise.reject(reason);
    });
  }
}
