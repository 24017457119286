export class SubscriptionMetrics {
  public partnerId: number;
  public partnerOrganizationCode: string;
  public metrics: any[];

  constructor(data?: any) {
    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          this[key] = data[key];
        }
      }
    }
  }

}
