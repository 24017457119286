import { Component, Input, OnInit} from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { locale as english } from './i18n/text-area-copy.en';
import { locale as spanish } from './i18n/text-area-copy.es';

@Component({
  selector: 'app-text-area-copy',
  templateUrl: './text-area-copy.component.html',
  styleUrls: ['./text-area-copy.component.scss']
})
export class TextAreaCopyComponent implements OnInit {
  @Input()
  public textInput: string;

  public toolTipCopy: string;
  public commandsCopied: boolean;
  public isCopied: boolean;
  public copyText: string;
  public copiedText: string;

  constructor(private translate : TranslateService) {
    this.setTranslations();
  }
  public ngOnInit() {
    this.setTranslations();
    this.toolTipCopy = this.copyText;
  }

  public copyCommands(popover: NgbPopover): void {
    if (popover.isOpen()) {
      popover.close();
    }
    this.commandsCopied = true;
    this.isCopied = true;
    this.toolTipCopy = this.copiedText;
    popover.open();
  }
  public changeTooltipText(popover: NgbPopover): void {
    if (popover.isOpen()) {
      popover.close();
    }
    if (this.toolTipCopy !== this.copyText) {
      this.toolTipCopy = this.copyText;
    }
  }

  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
    this.copiedText = !this.copiedText ?  this.translate.instant('text-area-copy.COPIED') : this.copiedText;
    this.copyText =  !this.copyText ?  this.translate.instant('text-area-copy.COPY') : this.copyText;
  }

}
