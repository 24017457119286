import { AbstractControl, ValidationErrors } from '@angular/forms';
export const ipRangeValidator = function (control: AbstractControl): ValidationErrors | null {
  const value: string = control.value || '';
  if(!value) {
    return null;
  }
  const rangeRegex = new RegExp(/([1-9]|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])(\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])){3}\/\d+$/);
  if (rangeRegex.test(value) === false) {
    return { pattern : 'Invalid format' };
  }
};
