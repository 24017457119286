export enum Apis {
  GCompute = 'GCompute',
  GBilling = 'GBilling',
  GDeployment = 'GDeployment',
  GBigquery = 'GBigquery',
  GManagment = 'GManagment',
  GNetworking = 'GNetworking',
  GSqlAdmin = 'GSqlAdmin',
  GOsConfig = 'GOsConfig',
  GContainerAnalysis = 'GContainerAnalysis',
  GDns = 'GDns',
  GManageAd = 'GManageAd',
  GNetApp = 'GNetApp',
  GQserviceConsumer= 'GQserviceConsumer',
  GCloudResourceManager = 'GCloudResourceManager'
}
