export class Secret {
  public uid: string;
  public description: string;
  public creationDate: string;
  public username: string;
  public organizationId: number;
  public organizationSecretsId: number;
  public id: number;
  public token: string;
  public createdBy: string;
  public roleSecurityId: number;
  public allCompanies: boolean;
  public allowCompanies: number[];
  public secretsTokenTypeId: number;

  constructor(data?) {
    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          this[key] = data[key];
        }
      }
    }
  }
}
