import { SubscriptionComponentPrice } from './subscription-component-price';

export class SubscriptionComponent {
  public quantity: number;
  public featureId: number;
  public featureName: string;
  public price: number;
  public pricingScheme: string;
  public componentPriceses: SubscriptionComponentPrice[];

  constructor(data?: any) {
    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          this[key] = data[key];
        }
      }
    }
  }

}
