import * as _ from 'underscore';

export class OuItem {
    public id: string;
    public name: string;
    public children: OuItem[];

    constructor(data? : Object) {
        this.id = data && data['distinguishedName'] ? data['distinguishedName'] : '';
        this.name = data && data['name'] ? data['name'] : '';
        this.children = [];
    }
}
