import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs/Observable';

import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ResetUserPasswordService {
  constructor(private http: HttpClient) {
  }

  public resetUserPassword(data: any): Observable<any> {
    const params = new HttpParams().set('skeepFailure', 'true');
    return this.http.post<any>(`${environment.apiUrl}deployments/${data.deploymentId}/cloudUsers/${data.cloudUserId}/resetPassword`,
      data.passwordModel, {params});
  }

  public sendResetPasswordEmail(data: any): Observable<any> {
    let params = new HttpParams().set('skeepFailure', 'true');
    if (data.expireCurrentPassword) {
      params = params.set('expireCurrentPassword', 'true');
    }
    return this.http.post<any>(`${environment.apiUrl}deployments/${data.deploymentId}/cloudUsers/${data.username}/sendResetPasswordEmail`,
      {}, {params});
  }

  public resetPasswordMultiple(data: any): Observable<any> {
    const params = new HttpParams().set('skeepFailure', 'true');
    return this.http.post<any>(`${environment.apiUrl}deployments/${data.deploymentId}/cloudUsers/resetPasswordMultiple`,
      data.passwordModel, {params});
  }

}
