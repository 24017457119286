import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

declare const gapi: any;
const forbidden = 403;
@Injectable({
  providedIn: 'root'
})
export class GdeploymentService {
  public service = 'deploymentmanager.googleapis.com';
  constructor() {
  }
  public loadGDeployment(): void {
    try {
      return gapi.client.load('deploymentmanager', 'v2');
    } catch (exception) {
      throwError(exception);
      return;
    }
  }
  public getTypes(projectId): Promise<any> {
    const params = {
        project: projectId
    };
    const request = gapi.client.types.list(params);
    return request.then((response) => {
        return Promise.resolve(response.result);
    }).catch((reason) => {
        reason.request = {
            name: 'gapi.client.types.list',
            params: params
        };
        return Promise.reject(reason);
    });
}
  public hasEnabled(projectId): Promise<any> {
    const params = {
      project: projectId
  };
    return this.getTypes(projectId)
  .then(() => {
      return Promise.resolve(true);
    }).catch((reason) => {
        if (reason.result && reason.result.error) {
          if (reason.result.error.code === forbidden) {
            return Promise.resolve(false);
        }
        }
        return Promise.reject(reason);
  });

}
  public activate(consumer): Promise<any> {
  const params = {
      serviceName: this.service,
      consumerId: consumer
  };
  try {
    const request = gapi.client.servicemanagement.services.enable(params);
    return request.then( function (response) {
      return Promise.resolve(response.result);
  }).catch( function (reason) {
      reason.request = {
          name: 'servicemanagement.googleapis.com/v1/services/deploymentmanager.googleapis.com:enable',
          params: params
      };
      return Promise.reject(reason);
  });
  } catch (e) {
    console.log(e);
  }
}
}
