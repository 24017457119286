import { Directive, HostListener } from '@angular/core';

const backspace = 8;
const enter = 13;
const minNumber = 48;
const maxNumber = 57;
const nullValues = 0;

@Directive({
  selector: '[appExcludeSpecialCharacters]'
})
export class ExcludeSpecialCharactersDirective {

  constructor() { }
  @HostListener('keypress', ['$event'])
  public validateEspecialCharacters($event) {
    const which = $event.which;
    if (which !== backspace && which !== enter && which !== nullValues && which < minNumber || which > maxNumber) {
      $event.preventDefault();
    }

  }

}
