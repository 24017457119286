import {Zone} from '.';

export class RegionNomenclator {
    public name: string;
    public description: string;
    public regionId: number;
    public subnet: string;
    public gateway: string;
    public zones: Zone[];

    constructor(data ? : any) {
        this.regionId = data && data.regionId ? data.regionId : 0;
        this.name = data && data.name ? data.name : '';
        this.description = data && data.description ? data.description : '';
        this.subnet = data && data.subnet ? data.subnet : '';
        this.gateway = data && data.gateway ? data.gateway : '';
    }
}
