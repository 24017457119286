export class SecurityAdGroup {
  public securityGroupId: number;
  public name: string;
  public deploymentId: number;
  public allow: boolean;
  public samAccountName: string;
  public guid: string;

  constructor(data) {
      this.securityGroupId = data.securityGroupId || 0;
      this.name = data.name || '';
      this.deploymentId = data.deploymentId || 0;
      this.allow = data.allow || false;
      this.samAccountName = data.samAccountName || '';
      this.guid = data.guid || '';
  }
}
