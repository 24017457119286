import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { finalize } from 'rxjs/operators';
import * as _ from 'underscore';

import { AuthenticationService } from '../core/security/authentication.service';
import { Branding, FileBase, ServerError, ValidationErrorsCodes } from '../models';
import { AutoUnsubscribeComponent } from '../shared/auto-unsubscribe/auto-unsubscribe.component';
import { BrandingActionService } from './branding.action.service';
import { BrandingService } from './branding.service';


import { locale as english } from './i18n/branding.en';
import { locale as spanish } from './i18n/branding.es';

@Component({
    selector: 'app-branding',
    templateUrl: 'branding.component.html',
    styleUrls: ['branding.component.scss']
})
export class BrandingComponent extends AutoUnsubscribeComponent implements AfterViewInit, OnInit {

    public branding: Branding;
    public loadingBranding: boolean;
    public titleMsg: string;
    public titleLogo: string;
    public subTitleLogo: string;
    public titleIcon: string;
    public subTitleIcon: string;
    public logoLabel: string;
    public iconLabel: string;
    public errorRequired: string;
    public actionsButtons: string[];
    public brandingForm: FormGroup;
    public actionPressed: boolean;

    private organizationId: number;
    private originalBranding: Object;

    constructor(private brandingService: BrandingService, private authenticationService: AuthenticationService,
                private translate: TranslateService, private brandingActionService: BrandingActionService,
                private formBuilder: FormBuilder, private notifierService: NotifierService) {
        super();
        this.setTranslations();
    }

    public ngOnInit() {
        this.setTranslations();
        this.loadingBranding = true;
        this.branding = new Branding();
        const currentUserSubs = this.authenticationService.currentUser.subscribe((currenUser: Object) => {
            if (currenUser && currenUser['current'] && currenUser['current'].organizationId) {
                this.organizationId = Number(currenUser['current'].organizationId);
                this.initView();
                this.getBranding();
            }
        });
        super.addSubscriptions(currentUserSubs);
    }
    public ngAfterViewInit() {
        this.setTranslations();
    }

    get f() { return this.brandingForm.controls; }

    public setLogoImage(logo: FileBase): void {
        this.branding.logoFileStore = logo;
    }
    public setFavIconImage(favIcon: FileBase): void {
        this.branding.favIconFileStore = favIcon;
    }
    public validateImage($event): void {
        if (!$event) {
            this.brandingForm.updateValueAndValidity();
        }
    }
    public saveBranding($event: boolean): void {
        if ($event) {
            this.actionPressed = true;
            this.updateBranding();
        } else {
            this.ngOnInit();
        }
    }
    private updateBranding(): void {
        this.branding.options.brand = true;
        if ((!this.branding.favIconFileStore || !this.branding.favIconFileStore.fileData) &&
            (!this.branding.logoFileStore || !this.branding.logoFileStore.fileData)) {
            this.branding.options.brand = false;
        }
        const brand = {
            organizationId: this.branding.organizationId,
            logoFileStore: this.branding.logoFileStore,
            favIconFileStore: this.branding.favIconFileStore,
            options: JSON.stringify(this.branding.options)
        };
        const updateSub = this.brandingService.updateBranding(this.organizationId, brand)
        .pipe(finalize(() => {
            this.actionPressed = false;
        }))
        .subscribe(() => {
            this.originalBranding = JSON.parse(JSON.stringify(this.branding));
            this.setTranslations();
            this.notifierService.notify('success', this.translate.instant('branding.EDIT_SUCCESFULL'));
            this.updateAppBranding();
        }, (error) => {
            this.branding.options.brand = this.originalBranding['options'].brand;
            this.setServerErrors(error);
        });
        super.addSubscriptions(updateSub);
    }
    private updateAppBranding(): void {
        let favIconData = './favicon.ico';
        let logoData = 'assets/images/itopia/logo-new-white.png';
        if (this.branding.options.brand) {
            if (this.branding.favIconFileStore && this.branding.favIconFileStore.fileData) {
                favIconData = `data:image/png;base64,${this.branding.favIconFileStore.fileData}`;
            }
            if (this.branding.logoFileStore && this.branding.logoFileStore.fileData) {
                logoData = `data:image/png;base64,${this.branding.logoFileStore.fileData}`;
            }
        }
        this.setFavIcon(favIconData);
        this.setLogo(logoData);
    }
    private setLogo(data: string): void {
        this.brandingActionService.setLogoDark(data);
        this.brandingActionService.setLogoLight(data);
    }
    private setFavIcon(data: string): void {
        const favIcon: HTMLLinkElement = document.querySelector('#favIcon');
        favIcon.href = data;
    }
    private initView(): void {
        this.brandingForm = this.formBuilder.group({});
    }
    private getBranding(): void {
        const getBrandingSubs = this.brandingService.getBranding(this.organizationId)
        .pipe(finalize(() => {
            this.loadingBranding = false;
        }))
        .subscribe((branding: Branding) => {
            if (branding) {
                this.branding = new Branding(branding);
                this.originalBranding = JSON.parse(JSON.stringify(this.branding));
            }
        });
        super.addSubscriptions(getBrandingSubs);
    }
    private setServerErrors(errorResponse: { error: ServerError, status: number }): void {
        if (errorResponse.error.code === ValidationErrorsCodes.ValidationError40027 ||
            errorResponse.error.code === ValidationErrorsCodes.ValidationError40029) {
            const validationErrors = errorResponse && errorResponse.error && errorResponse.error.errors;
            _.each(validationErrors, (prop: any, key: any) => {
                if (this.f[key]) {
                    this.f[key].setErrors({ serverError: prop });
                }
            });
        }
    }
    private setTranslations(): void {
        if (this.translate.getBrowserLang() === 'es') {
            this.translate.use('es');
            this.translate.setTranslation('es', spanish);
        } else {
            this.translate.use('en');
            this.translate.setTranslation('en', english);
        }
        this.titleMsg = this.translate.instant('branding.TITLE');
        this.titleLogo = this.translate.instant('branding.TITLE_LOGO');
        this.subTitleLogo = this.translate.instant('branding.SUBTITLE_LOGO');
        this.titleIcon = this.translate.instant('branding.TITLE_ICON');
        this.subTitleIcon = this.translate.instant('branding.SUBTITLE_ICON');
        this.logoLabel = this.translate.instant('branding.LOGO_LABEL');
        this.iconLabel = this.translate.instant('branding.ICON_LABEL');
        this.errorRequired = this.translate.instant('branding.ERROR_REQUIRED');
        this.actionsButtons = [this.translate.instant('branding.CANCEL'),
                               this.translate.instant('branding.SAVE'),
                               this.translate.instant('branding.SAVING')];
    }
}
