export enum InstanceActionsRequest {
    Create = 1,
    Activate = 2,
    Clone = 3,
    Start = 4,
    Stop = 5,
    Delete = 6,
    ConvertToData = 7,
    Reset = 8,
    Redeploy= 9,
    CreateSnapshot= 10
}
