import { ApisStatus } from 'src/app/models';

export class ApisConfig {
    public title: string;
    public loading: boolean;
    public status: ApisStatus;
    public error: string;
    public urlError: string;
    public enableManually: boolean;
    public openWindowUrl: boolean;
    constructor(data?: any) {
        this.title =  data && data.title ? data.title : '';
        this.loading =  data && data.loading ? data.loading : false;
        this.error =  data && data.error ? data.error : '';
        this.urlError =  data && data.urlError ? data.urlError : '';
        this.status =  data && data.status ? data.status : undefined;
        this.enableManually =  data && data.enableManually ? true : false;
        this.openWindowUrl =  data && data.openWindowUrl ? true : false;
    }
 }