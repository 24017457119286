export class CatalogGroup {
  public adGroupId: number;
  public name: string;
  public adId: number;
  public parentADGroupId: number;
  public onCloud: boolean;
  public visible: boolean;
  public adGroupStatusId: number;  		
  		
  constructor(data?: any) {
    if (data) {
        Object.keys(data).forEach(key => {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                this[key] = data[key];
            }
        });
    }
}
}

