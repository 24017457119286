export class Organization {
    public  partnerId: number;
    public  companyName: string;
    public  phoneNumber: string;
    public  webSite: string;
    public  contactName: string;
    public  contactLastName: string;
    public  email: string;
    public  masterPartner: boolean;
    public  partnerCode: string;
    public  active: boolean;   
    public  organizationId: number; 
    public  billingEmail: string;    
    public  clientCount: number;
    public  planTypeId: number;
    public  organizationCode: string;
    public  kmsKeyRing: string;
    public  kmsKey: string;

    constructor(data?) {
      this.partnerId = data && data.partnerId ? data.partnerId : 0;
      this.companyName = data && data.companyName ? data.companyName : '';
      this.phoneNumber = data && data.phoneNumber ? data.phoneNumber : '';
      this.webSite = data && data.webSite ? data.webSite : '';
      this.contactName = data && data.contactName ? data.contactName : '';
      this.contactLastName = data && data.contactLastName ? data.contactLastName : '';
      this.email = data && data.email ? data.email : '';
      this.masterPartner = data && data.masterPartner ? data.masterPartner : false;
      this.partnerCode = data && data.partnerCode ? data.partnerCode : '';
      this.active = data && data.active ? data.active : false;
      this.billingEmail = data && data.billingEmail ? data.billingEmail : '';
      this.clientCount = data && data.clientCount ? data.clientCount : 0;
      this.planTypeId = data && data.planTypeId ? data.planTypeId : 0;
      this.organizationCode = data && data.organizationCode ? data.organizationCode : '';
      this.kmsKeyRing = data && data.kmsKeyRing ? data.kmsKeyRing : '';
      this.kmsKey = data && data.kmsKey ? data.kmsKey : '';
      this.organizationId = data && data.organizationId ? data.organizationId : 0;
    }
   
  
}
