export const locale = {
    lang: 'en',
    'notification_pane': {
        'VIEW_ALL': 'View all notifications',
        'ERRORS': 'Errors',
        'NO_ERR_NOTIF': 'No error notifications',
        'NEW_ERR_NOTIF': '+ {{count}} Errors not displayed',
        'OTHER': 'Other notifications',
        'NO_OTHER_NOTIF': 'No other notifications',
        'NEW_OTH_NOTIF': '+ {{count}} New notifications not displayed',
        'SUCCESFULL_AUTOSCALING': 'Schedule succesfully configured',
        'RETRY' : 'Retry',
    }
};
