const limit = 25;
export class Datatable<T> {
  public PageSize: number;
  public TotalElements: number;
  public TotalPages: number;
  public CurrentPageNumber: number;
  public SortBy: string;
  public SortDir: string;
  public Refreshing?: boolean;
  public Data: Array<T>;
  constructor(defaultSortBy: string = 'Id', defaultSortDir: string = 'asc') {
      this.PageSize = limit;
      this.TotalElements = 0;
      this.TotalPages = 0;
      this.CurrentPageNumber = 0;
      this.Refreshing = false;
      this.Data = new Array<T>();

      this.SortBy = defaultSortBy;
      this.SortDir = defaultSortDir;
  }
}
