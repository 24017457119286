import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UnaryExpressionService {
  public expressionType: any;

  constructor() {
    this.expressionType = {
      NONE: 0,
      NEGATE: 1,
      NOT: 2
    };
  }

  public match (item, name, value, expression) {

    expression = parseInt(expression, 0);

    switch (expression) {

        case this.expressionType.NONE:
            return false;

        case this.expressionType.NEGATE: // not use
            return false;

        case this.expressionType.NOT: // distinct

            const val = item[name];

            return val !== value;

        default:
            return false;
    }
}
}
