import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ApiStatusModule } from 'src/app/shared/api-status/api-status.module';
import { ApisCheckManagerComponent } from './apis-check-manager.component';

@NgModule({
  declarations: [ApisCheckManagerComponent],
  imports: [CommonModule, ApiStatusModule ],
  exports: [ApisCheckManagerComponent]
})
export class ApisCheckManagerModule { }
