export class Subnet {
    public ipRange: string;
    public name: string;
    public gateway: string;
    public regionId: number;
    constructor(data ? : any) {
        this.ipRange = data && data.ipRange ? data.ipRange : '';
        this.name = data && data.name ? data.name : '';
        this.gateway = data && data.gateway ? data.gateway : '';
        this.regionId = data && data.regionId ? data.regionId : 0;
    }
}
