import { Routes } from '@angular/router';

import { CompleteAdminInviteComponent } from './complete-admin-invite/complete-admin-invite.component';
import { AuthGuard } from './core/security/auth.guard';
import { FullComponent } from './layouts/full/full.component';
import { LoginComponent } from './login/login.component';
import { SubscriptionCanceledComponent } from './subscription-canceled/subscription-canceled.component';

// tslint:disable-next-line:variable-name
export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'deployment/:deploymentId/cloud_desktops',
        loadChildren: './cloud_desktops/cloud_desktops.module#CloudDesktopsModule'
      },
      {
        path: 'deployment/:deploymentId/cloud_manager',
        loadChildren: './cloud-manager/cloud-manager.module#CloudManagerModule'
      },
      {
        path: 'deployment/:deploymentId/tasks',
        loadChildren: './tasks/tasks.module#TasksModule'
      },
      {
        path: 'deployment/:deploymentId/insights',
        loadChildren: './insights/insights.module#InsightsModule'
      },
      {
        path: 'audits',
        loadChildren: './audits/audits.module#AuditsModule'
      },
      {
        path: 'profile',
        loadChildren: './profile/profile.module#ProfileModule'
      },
      {
        path: 'subscription',
        loadChildren: './subscription/subscription.module#SubscriptionModule'
      },
      {
        path: 'notifications',
        loadChildren: './notifications/notifications.module#NotificationsModule'
      },
      {
        path: 'provision',
        loadChildren: './provision/provision.module#ProvisionModule'
      },
      {
        path: 'error',
        loadChildren: './shared/error-notification/error-notification.module#ErrorNotificationModule'
      },
      {
        path: 'deployment/:deploymentId/settings',
        loadChildren: './deployment-settings/deployment-settings.module#DeploymentSettingsModule'
      },
      {
        path: 'deployment/:deploymentId/file_shares',
        loadChildren: './file-shares/file-shares.module#FileSharesModule'
      },
      {
        path: 'integrations',
        loadChildren: './integrations/integrations.module#IntegrationsModule'
      }
    ]
  },
  { path: 'invite', component: CompleteAdminInviteComponent},
  { path: 'login', component: LoginComponent },
  { path: 'subscription/canceled', component: SubscriptionCanceledComponent },
  { path: '**', redirectTo: '' }
];
