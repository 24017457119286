import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';

import { BinaryExpressionService, OperationExpressionService, UnaryExpressionService } from 'src/app/core/shared/filters';
import { BreakpointsObserverService } from 'src/app/core/shared/services/breakpoints-observer.service';
import { HttpCancelService } from 'src/app/core/shared/services/http-cancel.service';
import { Datatable, Deployment, DeploymentStatus, ListDto } from 'src/app/models';
import { AutoUnsubscribeComponent } from 'src/app/shared/auto-unsubscribe/auto-unsubscribe.component';
import * as _ from 'underscore';
import { DeploymentsService } from '../deployments.service';

import { locale as english } from '../i18n/deployments.en';
import { locale as spanish } from '../i18n/deployments.es';

const currentPageLimit = 10;
const timeoutValue = 600;

@Component({
    selector: 'app-deployment-ids-list',
    templateUrl: './deployment-ids-list.component.html',
    styleUrls: ['./deployment-ids-list.component.scss']
})
export class DeploymentIdsListComponent extends AutoUnsubscribeComponent implements AfterViewInit, OnInit {

    @Input()
    public title: string;
    @Input()
    public showAsSubsection: boolean;

    public gridModel: Datatable<Deployment> = new Datatable<Deployment>();
    public loadingIndicator: boolean;
    public showFooter: boolean;
    public sorts: Array<{ prop: string, dir: string }>;
    public translations: Object;
    public columns: TableColumn[];
    public currentPageLimit = currentPageLimit;

    @ViewChild('statusTemplate', { static: false })
    public statusTemplate: TemplateRef<ElementRef>;
    @ViewChild('companyIdTemplate', { static: false })
    public companyIdTemplate: TemplateRef<ElementRef>;

    constructor(private translate: TranslateService, private deploymentsService: DeploymentsService,
                private httpCancel: HttpCancelService, private binaryExpression: BinaryExpressionService,
                private unaryExpression: UnaryExpressionService, private operationExpression: OperationExpressionService,
                private breakpointsObserverService: BreakpointsObserverService, private cdRef: ChangeDetectorRef) {
        super();
        this.setTranslations();
    }

    public ngOnInit() {
        this.showFooter = false;
        this.sorts = [{ prop: 'name', dir: 'asc' }, { prop: 'companiesStatusId', dir: 'asc' }];
        this.detectScreenSize();
        this.setTranslations();
    }

    public ngAfterViewInit() {
        this.cdRef.detectChanges();
        this.detectScreenSize();
        this.setTranslations();
    }

    public setPage($event): void {
        this.loadingIndicator = true;
        const search = {
            pageIndex: $event.CurrentPageNumber || this.gridModel.CurrentPageNumber,
            pageSize: $event.PageSize || this.gridModel.PageSize,
            filter: [
                {
                    propertyName: 'Active',
                    operation: this.operationExpression.expressionType.EQUALS,
                    value: true,
                    toLower: true,
                    unaryOperation: this.unaryExpression.expressionType.NONE,
                    binaryOperation: this.binaryExpression.expressionType.AND
                }
            ],
            order: {
                columnName: 'CompaniesStatusId',
                ascending: false
            }
        };
        if ($event.value) {
            const f = [
                {
                    propertyName: 'Name',
                    operation: this.operationExpression.expressionType.CONTAINS,
                    value: $event.value,
                    toLower: true,
                    unaryOperation: this.unaryExpression.expressionType.NONE,
                    binaryOperation: this.binaryExpression.expressionType.OR
                },
                {
                    propertyName: 'Code',
                    operation: this.operationExpression.expressionType.CONTAINS,
                    value: $event.value,
                    toLower: true,
                    unaryOperation: this.unaryExpression.expressionType.NONE,
                    binaryOperation: this.binaryExpression.expressionType.OR
                }
            ];
            search.filter = _.union(f, search.filter);
        }
        const searchDeploymentsFilterSubs = this.deploymentsService.searchDeployments(search).subscribe((data: ListDto<Deployment>) => {
            $event.PageSize = $event.PageSize || this.gridModel.PageSize;
            $event.CurrentPageNumber = $event.CurrentPageNumber || this.gridModel.CurrentPageNumber;
            const total = Number(data.count / $event.PageSize);
            this.showFooter = data.count > currentPageLimit;
            this.setStatus(data.list);

            this.gridModel = {
                PageSize: $event.PageSize, TotalElements: data.count, TotalPages: Number(total.toFixed(0)) + 1,
                CurrentPageNumber: $event.CurrentPageNumber, SortBy: this.gridModel.SortBy,
                SortDir: this.gridModel.SortDir, Data: data.list
            };
        }, () => {
            this.loadingIndicator = false;
        }, () => {
            this.loadingIndicator = false;
        });
        super.addSubscriptions(searchDeploymentsFilterSubs);
    }

    public updateFilter($event): void {
        setTimeout(() => {
            this.httpCancel.cancelPendingRequests();
            $event.value = $event.target.value.toLowerCase();
            this.setPage($event);
        }, timeoutValue);
    }

    private initColumns(): void {
        this.columns = [
            { prop: 'name', name: this.translate.instant('deployments.DEPLOY_NAME').toUpperCase(),
              minWidth: 200, cellTemplate: this.statusTemplate },
            { prop: 'code', name: this.translate.instant('deployments.DEPLOY_CODE').toUpperCase(), minWidth: 150 },
            { prop: 'companyId', name: this.translate.instant('deployments.DEPLOY_ID').toUpperCase(),
              minWidth: 150, cellTemplate: this.companyIdTemplate }];
    }

    private detectScreenSize(): void {
        const mediaBreakpointSubs = this.breakpointsObserverService.mediaBreakpoint$.subscribe((value) => {
            this.setTranslations();
            switch (value) {
                case 'xs':
                case 'sm':
                case 'md':
                    this.columns = [
                        { prop: 'name', name: this.translate.instant('deployments.DEPLOY_NAME').toUpperCase(),
                          minWidth: 200, cellTemplate: this.statusTemplate },
                        { prop: 'companyId', name: this.translate.instant('deployments.DEPLOY_ID').toUpperCase(),
                          minWidth: 70, cellTemplate: this.companyIdTemplate }];
                    break;
                default:
                    this.initColumns();
                    break;
            }
        });
        super.addSubscriptions(mediaBreakpointSubs);
    }

    private setStatus(list: Deployment[]): void {
        _.map(list, (row: Deployment) => {
            switch (row.status.toLowerCase()) {
                case DeploymentStatus.PendingDeletion:
                    row['displayStatus'] = this.translations['status'].pendingDelete;
                    break;
                case DeploymentStatus.PendingToCreate:
                    row['displayStatus'] = this.translations['status'].pendingCreation;
                    break;
                case DeploymentStatus.InProgress:
                    row['displayStatus'] = this.translations['status'].inProgress;
                    break;
                case DeploymentStatus.PendingToConnect:
                    row['displayStatus'] = this.translations['status'].pendingConnect;
                    break;
                case DeploymentStatus.OnCloud:
                    row['displayStatus'] = this.translations['status'].onCloud;
                    break;
                case DeploymentStatus.NotOnCloud:
                    row['displayStatus'] = this.translations['status'].noOnCloud;
                    break;
            }
        });
    }

    private setTranslations(): void {
        if (this.translate.getBrowserLang() === 'es') {
            this.translate.use('es');
            this.translate.setTranslation('es', spanish);
        } else {
            this.translate.use('en');
            this.translate.setTranslation('en', english);
        }

        this.translations = {
            status: {
                onCloud: this.translate.instant('deployments.STATUS_ON_CLOUD'),
                noOnCloud: this.translate.instant('deployments.STATUS_NOT_ON_CLOUD'),
                pendingCreation: this.translate.instant('deployments.STATUS_PENDING_CREATE'),
                pendingConnect: this.translate.instant('deployments.STATUS_PENDING_CONNECT'),
                inProgress: this.translate.instant('deployments.STATUS_IN_PROGRESS'),
                pendingDelete: this.translate.instant('deployments.STATUS_PENDING_DELETE')
            },
            tableMessages : {
                'emptyMessage': this.translate.instant('deployments.NO_DATA'),
                'totalMessage': this.translate.instant('deployments.TOTAL'),
                'selectedMessage': this.translate.instant('deployments.SELECTED'),
                'showingMessage': this.translate.instant('deployments.SHOWING')
            }
        };
    }
}
