export class ImageFamily {
    public name: string;
    public domain: boolean;
    public isWindows: boolean;
    public family: string;
    public description: string;
    public diskSize: number;
    public deployment: boolean;

    constructor( data?: any) {
      this.name =  data && data.name ? data.name  : '';
      this.description = data && data.description ? data.description : '';
      this.domain =  data && data.domain ? data.domain  : false;
      this.isWindows = data && data.isWindows ? data.isWindows : false;
      this.diskSize =  data && data.diskSize ? data.diskSize  : 0;
      this.family =  data && data.family ? data.family  : '';
      this.deployment =  data && data.deployment ? data.deployment  : false;
  }
}

