export const locale = {
    lang: 'en',
    img_upload : {
        ERROR_REQUIRED: 'Campo requerido',
        ERROR_PATTERN: 'Campo no válido',
        IMG_LABEL: 'Seleccionar imagen (jpg, png, gif)',
        IMG_PLACEHOLDER: 'Ningún archivo seleccionado.',
        BROWSE_BTN: 'Buscar...',
        UPLOAD_BTN: 'Subir',
        REMOVE_BTN: 'Eliminar',
        INVALID_SIZE: 'Archivo demasiado grande. Máximo permitido: 100 KB',
        INVALID_EXTENSION: 'Debes seleccionar un archivo de imagen'
    }
};
