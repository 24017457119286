import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FormButtonsActionService {

    public actionPressed$: BehaviorSubject<boolean>;
    public actionPressed: Observable<boolean>;
    public disableCancelBtn$: BehaviorSubject<boolean>;
    public disableCancelBtn: Observable<boolean>;

    constructor() {
        this.actionPressed$ = new BehaviorSubject<boolean>(false);
        this.actionPressed = this.actionPressed$.asObservable();
        this.disableCancelBtn$ = new BehaviorSubject<boolean>(false);
        this.disableCancelBtn = this.disableCancelBtn$.asObservable();
    }

    public setActionPressed(actionPressed: boolean): void {
        this.actionPressed$.next(actionPressed);
    }
    public setDisableCancel(disableCancel: boolean): void {
      this.disableCancelBtn$.next(disableCancel);
    }
}
