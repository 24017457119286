import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-layout',
  templateUrl: './modal-layout.component.html',
  styleUrls: ['./modal-layout.component.scss']
})
export class ModalLayoutComponent implements OnInit {
  @Input()
  public title: string;
  @Input()
  public info: string;
  @Input()
  public feature: string;

  constructor() { }

  public ngOnInit() {
  }

}
