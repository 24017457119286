import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

const textNode = 3;
@Directive({
  selector: '[appImagesStatus]'
})
export class ImagesStatusDirective implements OnInit, OnChanges {
  @Input()
  public imageStatus: string;
  @Input()
  public displayStatus: string;
  @Input()
  public isFailed: boolean;
  @Input()
  public renderStatus: boolean;

  constructor(private el: ElementRef, private renderer: Renderer2, @Inject(DOCUMENT) private document) { }

  public ngOnInit() {
    this.getImageStatus(this.imageStatus, this.renderStatus);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if  (changes.renderStatus) {
        this.renderStatus = changes.renderStatus.currentValue;
      }
      if  (changes.imageStatus) {
        this.imageStatus = changes.imageStatus.currentValue;
      }
      if  (changes.isFailed) {
        this.isFailed = changes.isFailed.currentValue;
      }
      this.getImageStatus(this.imageStatus, this.renderStatus);
    }
  }

  private getImageStatus(status: string, renderStatus: boolean) {
    const child = document.createElement('span');
    const childElements = this.el.nativeElement.childNodes;
    for (const children of childElements) {
        if (children.nodeType !== textNode && children.id !== 'copy') {
          this.renderer.removeChild(this.el.nativeElement, children);
        }
      }
    if (!renderStatus) {
      child.innerHTML = `<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="loading m-r-2">
                            <circle cx="50" cy="50" r="45"/>
                         </svg>`;
    } else {
      if (this.isFailed) {
        child.className = 'fas fa-circle text-danger m-r-2';
      } else {
        switch (status) {
          case 'PCR':
            child.className = 'fas fa-circle c-orange m-r-2';
            break;
          case 'ACT':
            child.className = 'fas fa-circle text-success m-r-2';
            break;
          case 'PVD':
            child.className = 'fas fa-circle c-orange m-r-2';
            break;
          case 'VDF':
            child.className = 'fas fa-circle text-danger m-r-2';
            break;
          default:
            child.className = 'fas fa-circle c-orange m-r-2';
            break;
        }
      }
      child.setAttribute('title', this.displayStatus);
    }
    this.renderer.insertBefore(this.el.nativeElement, child, this.el.nativeElement.firstChild);
  }
}
