import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DirectivesModule } from 'src/app/core/shared/directives/directives.module';
import { SectionLayoutComponent } from './section-layout.component';



@NgModule({
  declarations: [SectionLayoutComponent],
  imports: [
    CommonModule, DirectivesModule, NgbModule, FlexLayoutModule
  ],
  exports: [SectionLayoutComponent]
})
export class SectionLayoutModule { }
