export enum InstanceStatusEnum {
  PTC = 'Pending to create',
  PTU = 'Pending to update',
  RDY = 'Ready',
  PTD = 'Pending to delete',
  OFFLINE = 'OffLine',
  READY = 'Ready',
  STOPPED = 'Stopped',
  UNASSIGNED = 'Unassigned',
  MM = 'Maintenance mode'
}
