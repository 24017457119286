import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs-compat/ReplaySubject';
import { Observable } from 'rxjs/Observable';

import { ListDto, Settings } from 'src/app/models';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MadExtendedEnableFirewallService {

  private savingSettingsChanged$ = new ReplaySubject<boolean>(1);
  public savingSettingsChanged = this.savingSettingsChanged$.asObservable();
  private firewallSettingChanged$ = new ReplaySubject<boolean>(1);
  public firewallSettingChanged = this.firewallSettingChanged$.asObservable();
  private dnsServerIpsChanged$ = new ReplaySubject<string[]>(null);
  public dnsServerIpsChanged = this.dnsServerIpsChanged$.asObservable();

  constructor(private http: HttpClient) {
  }

  public setSavingSettings(isSavingSettings: boolean) {
    this.savingSettingsChanged$.next(isSavingSettings);
  }
  public setFirewallSettingChanged(isFirewallSettingEnabled: boolean) {
    this.firewallSettingChanged$.next(isFirewallSettingEnabled);
  }
  public setDnsServerIpsChanged(dnsServerIps: string[]) {
    this.dnsServerIpsChanged$.next(dnsServerIps);
  }

  public getDeploymentSettings(deploymentId: number): Observable<Settings> {
    return this.http.get<Settings>(`${environment.apiUrl}deployments/${deploymentId}/settings`);
  }
  public putDeploymentSettings(deploymentId: number, params: any): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}deployments/${deploymentId}/settings`, params);
  }
  public getSharedVpcExistADConfig(deploymentId: number, dnsServerIps: string[], skeepFailure?: boolean): Observable<ListDto<string>> {
    let params = new HttpParams();
    if (skeepFailure) {
      params = params.set('skeepFailure', 'true');
    }
    params = params.set('dnsServerIps', dnsServerIps.join(','));
    return this.http.get<ListDto<string>>(
      `${environment.apiUrl}deployments/${deploymentId}/vpcNetwork/sharedFwrCommands`, { params: params});
  }
}
