// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  casUrl: 'https://cas-dev.itopia.com/',
  apiUrl: 'https://cas-rest-dev.itopia.com/api/v2/',
  apiUrlV1: 'https://cas-rest-dev.itopia.com/api/',
  apiUrlV3: 'https://cas-rest-dev.itopia.com/api/v3/',
  integrationsUrl: 'https://cas-dev.itopia.com/integrations/',
  firebaseConfig: {
    apiKey: 'AIzaSyA9D0wqpfmRzla3SVpi_Rp2SICAreRSGnI',
    authDomain: 'dev-by-alpha-1.firebaseapp.com',
    databaseURL: 'https://dev-by-alpha-1.firebaseio.com',
    projectId: 'dev-by-alpha-1',
    storageBucket: 'dev-by-alpha-1.appspot.com',
    messagingSenderId: '111589951704'
  },
  intercom: {
    appId: 'ycqzyevm'
  },
  mapKey: 'AIzaSyCMS_BNZH-0eIL-KO6MHoqxMP2BulzP6W4',
  casEventsUrl : 'https://cas-events-dev.azurewebsites.net/api/',
  casEventsEndpoint : 'cas',
  provisionSteps: 'https://provisionstep-dot-dev01-219013.appspot.com/api/',
  gapi: {
    clientId: '395862031553-hn6tignuo7o7qcj18teh5d8mt7i7u4ml.apps.googleusercontent.com',
    scopes: ['https://www.googleapis.com/auth/cloud-platform', 'profile', 'email'],
    apiVersion: 'v1',
    urlServiceActivate: 'servicemanagement.googleapis.com/v1/services/serviceconsumermanagement.googleapis.com:enable',
    prefix: 'itopia'
  },
  integration: {
    google: {
      apiName: '',
      apiVersion: '',
      apiScope: 'https://www.googleapis.com/auth/cloud-platform',
      apiKey: '',
      clientId: '395862031553-docb20fl16b5ogl11ciaf05qbul8esds.apps.googleusercontent.com',
      clientSecret: 'To3q3c2IcSZfSb3TA95uy0R5',
      prefix: 'itopia'
    },
    connectWise: {
      apiName: 'https://cas-integrations-connectwise-testing.azurewebsites.net'
    },
    eventPlatform: {
      apiName: 'https://cas-events-dev.azurewebsites.net/api',
      app: 'cas'
    }
  },
  gpo: {
    serviceAccountUser: 'cas-dev-itopia-gpo-reader',
    serviceAccountDomain: 'dev00-215713.iam.gserviceaccount.com'
  },
  statusPageApi: {
    page: 'ntbgvkbspjv6'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
