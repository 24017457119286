import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';

import { Apis, ApisStatus, Settings } from 'src/app/models';
import { ApisCheckManagerComponent } from '../../apis-check-manager/apis-check-manager.component';
import { ApisGroup } from '../../apis-check-manager/model';
import { InfrastructureRegionProvision } from '../model';
import * as c from './../const';

import { locale as english } from './i18n/configure-region-servers-roles.en';
import { locale as spanish } from './i18n/configure-region-servers-roles.es';

@Component({
  selector: 'app-configure-region-servers-roles',
  templateUrl: './configure-region-servers-roles.component.html',
  styleUrls: ['./configure-region-servers-roles.component.scss']
})
export class ConfigureRegionServersRolesComponent implements OnInit {

  @Input()
  public settings: object;
  @Input()
  public isWindow10: boolean;
  @Input()
  public projectId: string;
  @Input()
  public deploymentId: number;

  public regionRolesForm: FormGroup;
  public validationType: object;
  public translationObject: Object;
  public maxValQuantity = c.maxValQuantity;
  public minValQuantity = c.minValQuantity;
  public deploymentSettings: Settings;
  public isAdExist: boolean;
  public isMad: boolean;
  public isGtw: boolean;
  public validApis: boolean;

  @ViewChild(ApisCheckManagerComponent, {static: false})
  public apisCheckComponent: ApisCheckManagerComponent;

  constructor(private formBuilder: FormBuilder, private translate: TranslateService) { }

  public ngOnInit() {
    this.setTranslations();
    this.regionRolesForm = this.formBuilder.group({
      QuantityDomain: [{ value: 1, disabled: false }],
      QuantityBroker: [{ value: 1, disabled: false }],
      QuantityGateway: [{ value: 1, disabled: false }]
    });

    this.validationType = {
      'QuantityDomain': [Validators.pattern('^[1-4]$')],
      'QuantityBroker': [Validators.pattern('^[1-4]$')],
      'QuantityGateway': [Validators.pattern('^[1-4]$')]
    };

    this.validApis = false;
    this.isMad = this.settings ? (/true/i).test(this.settings['mad']) : false;
    this.isGtw = this.settings ? (/true/i).test(this.settings['gtw']) : false;
    this.isAdExist = this.settings ? (/true/i).test(this.settings['adexist']) : false;
  }

  public isValidComponent(): boolean {
    let value = 0;
    if ( !this.isMad && !this.isAdExist ) {
      value = this.regionRolesForm.get('QuantityDomain').value;
      if (value || value === 0 ) {
        this.setValidations('QuantityDomain');
      }
    }
    if (!this.isWindow10) {
      value = this.regionRolesForm.get('QuantityBroker').value;
      if (value || value === 0 ) {
        this.setValidations('QuantityBroker');
      }
    }
    if (this.isGtw) {
      value = this.regionRolesForm.get('QuantityGateway').value;
      if (value || value === 0) {
        this.setValidations('QuantityGateway');
      }
    }
    return this.regionRolesForm.valid;
  }

  public isGenerateCloudSql(): boolean {
    const value = this.regionRolesForm.get('QuantityBroker').value;
    return value && value > 1;
  }

  public fillInfrastructureRegionModel(model: InfrastructureRegionProvision): void {
    const dc = this.regionRolesForm.get('QuantityDomain').value;
    const brk = this.regionRolesForm.get('QuantityBroker').value;
    const gtw = this.regionRolesForm.get('QuantityGateway').value;
    if (dc && !this.isMad && !this.isAdExist) {
        model.domainControllers = dc;
    }
    if (brk && !this.isWindow10) {
        model.brokers = brk;
    }
    if (gtw && this.isGtw) {
        model.gateways = gtw;
    }
  }

  public loadRequirementApis(): void {
    const apisGroup = [];
    _.map(c.apis, (api) => {
    apisGroup.push( new ApisGroup({group: [api], principal: api }));
    });
    this.apisCheckComponent.setApisGroup(apisGroup);
    this.apisCheckComponent.skeepFailure = true;
    this.apisCheckComponent.setVisible(true);
    this.apisCheckComponent.modeBackground = true;
}

  public syncApis(): Promise<boolean> {
     this.validApis = false;
     return new Promise(async (resolve, reject) => {
        this.loadRequirementApis();
        const $promise = this.apisCheckComponent.syncEnableDisableSingleApis(true);
        $promise.then((value: boolean) => {
        if (value === true) {
          this.validApis = true;
          resolve(true);
        } else {
            this.apisCheckComponent.modeBackground = false;
            resolve(false); }
      });
        await $promise;
   });
}

  public async enableApiEvt(api: Apis): Promise<void> {
      this.apisCheckComponent.setStatusApis(ApisStatus.Reset, true, api);
      this.apisCheckComponent.manageActionApis(false, api);
}

  public checkAllApisEvt($event): void {
    if ($event) {
      this.validApis = $event;
    }
  }

  get f() { return this.regionRolesForm.controls; }

  public setValidations(input: string): void {
    for (const key in this.f) {
      if (key === input) {
        this.f[key].setValidators(this.validationType[key]);
        this.f[key].updateValueAndValidity();
        break;
      } else if (!input) {
        this.f[key].setValidators(this.validationType[key]);
        this.f[key].updateValueAndValidity();
      }
    }
  }

  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }

    this.translationObject = {
      'SectionTitle' : this.translate.instant('configure_region_server_roles.SECTION_TITLE'),
      'DomainControllerTitle' : this.translate.instant('configure_region_server_roles.DOMAIN_CONTROLLER_TITLE'),
      'DomainControllerDescription' : this.translate.instant('configure_region_server_roles.DOMAIN_CONTROLLER_DESCRIPTION'),
      'DomainControllerDescriptionAdExist' : this.translate.instant('configure_region_server_roles.DOMAIN_CONTROLLER_DESCRIPTION_ADEXIST'),
      'BrokerTitle' : this.translate.instant('configure_region_server_roles.BROKER_TITLE'),
      'BrokerDescription' : this.translate.instant('configure_region_server_roles.BROKER_DESCRIPTION'),
      'GatewayTitle' : this.translate.instant('configure_region_server_roles.GATEWAY_TITLE'),
      'GatewayDescription' : this.translate.instant('configure_region_server_roles.GATEWAY_DESCRIPTION'),
      'ErrorPattern' : this.translate.instant('configure_region_server_roles.ERROR_PATTERN')
     };
  }
}
