import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'externalDnsName'
})
export class ExternalDnsNamePipe implements PipeTransform {

  /**
   * External Dns Name Transform
   * @param value String to transform
  */
  public transform(deploymentCode: string, regionName: string, domain: string): any {
    return `${deploymentCode}-${regionName}${domain}`;
  }
}
