import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { DirectivesModule } from 'src/app/core/shared/directives/directives.module';
import { AutoUnsubscribeModule } from '../auto-unsubscribe/auto-unsubscribe.module';
import { CrudViewsLayoutModule } from '../crud-views-layout/crud-views-layout.module';
import { FormButtonsModule } from '../form-buttons/form-buttons.module';
import { ModalLayoutModule } from '../modal-layout/modal-layout.module';
import { MultiselectModule } from '../multiselect/multiselect.module';
import { SectionLayoutModule } from '../section-layout/section-layout.module';
import { ViewsLayoutModule } from '../views-layout/views-layout.module';

import { MultiselectAppsComponent } from './multiselect-apps/multiselect-apps.component';
import { MultiselectAdGroupsComponent } from './multiselect-groups-ad/multiselect-groups-ad.component';
import { MultiselectGroupsComponent } from './multiselect-groups/multiselect-groups.component';
import { MultiselectInstancesComponent } from './multiselect-instances/multiselect-instances.component';
import { MultiselectPoolsComponent } from './multiselect-pools/multiselect-pools.component';
import { MultiselectUsersComponent } from './multiselect-users/multiselect-users.component';


@NgModule({
    imports: [
        CommonModule, NgbModule, DirectivesModule, TranslateModule, ReactiveFormsModule,
        FormsModule, FormButtonsModule, AutoUnsubscribeModule, MultiselectModule,
        ViewsLayoutModule, SectionLayoutModule, CrudViewsLayoutModule, ModalLayoutModule
    ],
    declarations: [
        MultiselectUsersComponent, MultiselectGroupsComponent, MultiselectInstancesComponent, MultiselectAdGroupsComponent,
        MultiselectAppsComponent, MultiselectPoolsComponent
    ],
    exports: [
        MultiselectUsersComponent, MultiselectGroupsComponent, MultiselectInstancesComponent, MultiselectAdGroupsComponent,
        MultiselectAppsComponent, MultiselectPoolsComponent
    ]
})
export class SelectResourcesModule {
}
