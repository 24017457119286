import { Apis } from 'src/app/models';

export class ApiState {
    public loading: boolean;
    public enabled: boolean;
    public name : Apis;
    public failed: boolean;
    constructor(data?: any) {
        this.loading =  data && data.loading ? data.loading : false;
        this.enabled =  data && data.enabled ? data.enabled : false;
        this.name =  data && data.name ? data.name : null;
        this.enabled =  data && data.failed ? data.failed : false;
    }
 }