import { Icon } from './icon';

export class IntegrationItem {
    public id: number;
    public integrationId: number;
    public name: string;
    public icon: Icon;
    public active: boolean;
    public hasConfiguration: boolean;
    public activeConfiguration: boolean;
    public visible: boolean;
    public configurationDate: Date;
    public type: string;
    public hasSurvey: boolean;
    public loadingEnable: boolean;
    public tooltip: string;
    public properties: { user: string, picture: string, name: string, project_name: string, project_id: string, features: []};

    // tslint:disable-next-line: cyclomatic-complexity
    constructor(data?: any) {
        this.id = data && data.Id ? data.Id : 0;
        this.integrationId = data && data.IntegrationId ? data.IntegrationId : 0;
        this.name = data && data.Name ? data.Name : '';
        this.icon = new Icon(data && data.Icon ? data.Icon : null);
        this.active = data && data.Active ? data.Active : false;
        this.hasConfiguration = data && data.HasConfiguration ? data.HasConfiguration : false;
        this.activeConfiguration = data && data.ActiveConfiguration ? data.ActiveConfiguration : false;
        this.visible = data && data.Visible ? data.Visible : false;
        this.configurationDate = data && data.ConfigurationDate ? data.ConfigurationDate : '';
        this.type = data && data.Type ? data.Type : '';
        this.hasSurvey = data && data.HasSurvey ? data.HasSurvey : false;
        this.properties = (data && data.Properties) || {};
    }
}
