export const locale = {
  lang: 'es',
  deployments: {
    'ALL': `Todos los despliegues`,
    'CLOSE': `Cerrar`,
    'NAME': `Despliegue`,
    'CODE': `Código`,
    'SEARCH': `Buscar`,
    'REFRESH': `Recargar`,
    'NO_DATA': 'No se encontraron despliegues',
    'TOTAL': 'total',
    'SELECTED': 'selected',
    'SHOWING': 'mostrando',
    'FILL_NAME': 'Rellene el nombre del despliegue ({{deployment}}) para confirmar',
    'REASON': 'Motivo',
    'DELETE_DEPLOY_NOT_ONCLOUD': 'Despliegue {{ name }} borrado',
    'DELETE_BTN': 'Eliminar',
    'DELETING': 'Eliminando ...',
    'CANCEL_BTN': 'Cancelar',
    'DELETE_COMPONENT_TITLE': 'Eliminar despliegue',
    'ERROR_REQUIRED': 'Campo requerido',
    'DELETE_DETAILS_PART1': `Eliminar un despliegue de CAS también eliminará
    los siguientes recursos de Google Cloud asociados al despliegue:`,
    'DELETE_DETAILS_PART2': `Eliminar un despliegue NO eliminará otros recursos de Google Cloud en el proyecto,
    y no eliminará el proyecto de Google Cloud.`,
    'DELETE_DETAILS_PART3': 'Para obtener más información, consulte el artículo del Centro de ayuda',
    'DELETE_DETAILS_ELEMENTS': {
      'VMANDDISK': 'Instancias y discos',
      'VPNS': 'VPNs, VPCs y subredes',
      'FR': 'Reglas de firewall',
      'RIPS': 'IPs reservados',
      'CI': 'Imágenes personalizadas',
      'DIP': 'Despliegue y proyecto interno'
    },
    'DELETE_A_DEPLOYMENT': 'Eliminar un despliegue',
    'DELETE_CONFIRMATION_ONCLOUD': 'Despliegue {{ name }} será eliminado en 2 horas',
    'RESET_AD_BTN': 'Reiniciar contraseña AD',
    'DELETE_BIGQUERY': 'Eliminar dataset de BigQuery',
    'DEPLOYMENTS': 'Despliegues',
    'NAMEMUSTMATCH': 'Entre {{ deployment }} para confirmar.',
    'STATUS_NOT_ON_CLOUD': 'No esta en la nube',
    'STATUS_ON_CLOUD': 'En la nube',
    'STATUS_PENDING_CONNECT': 'Pendiente a conexión',
    'STATUS_PENDING_CREATE': 'Pendiente a creación',
    'STATUS_PENDING_DELETE': 'Pendiente a eliminar',
    'STATUS_PENDING_UPDATE': 'Pendiente a actualizar',
    'STATUS_IN_PROGRESS': 'En curso',
    'DEPLOY_NAME': 'Nombre',
    'DEPLOY_CODE': 'Código de despliegue',
    'DEPLOY_ID': 'ID',
    'ORGANIZATION': 'Organización'
  }
};
