import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lowerCamelCase'
})
export class LowerCamelCasePipe implements PipeTransform {

  /**
   * Capitalize text
   * @param value String to transform
  */
 public transform(value: string): string {
  if (value) {
    return `${value.charAt(0).toLowerCase() + value.slice(1)}`;
  }
  return '';
}
}
