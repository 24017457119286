import { Component, OnInit } from '@angular/core';
import { NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UserIdleConfig, UserIdleService } from 'angular-user-idle';
import { Subscription, timer } from 'rxjs';

import { WarningModalComponent } from 'src/app/shared/warning-modal/warning-modal.component';
import { AuthenticationService } from '../../core/security/authentication.service';
import {ModalService} from '../../core/shared/services/modal.service';

import { locale as english } from './i18n/user-inactivity.en';
import { locale as spanish } from './i18n/user-inactivity.es';

const optionsModal = { size: 'md', backdrop: 'static', keyboard: false, centered: true };
const idleServiceConfig: UserIdleConfig = { idle: 3539, timeout: 1 };
const oneSecond = 1000;
const oneMinute = 60000;

@Component({
  selector: 'app-user-inactivity',
  templateUrl: './user-inactivity.component.html'
})
export class UserInactivityComponent implements OnInit {
  private modalRef: NgbModalRef;
  private forceLogoutSubscription: Subscription;

  constructor(private userIdleService: UserIdleService,  private modalService: ModalService,
              private authenticationService: AuthenticationService, private translate: TranslateService) {
    this.userIdleService.setConfigValues(idleServiceConfig);
  }

  public ngOnInit() {
    setTimeout(() => {
      this.userIdleService.startWatching();
    });
    this.userIdleService.onTimerStart().subscribe();

    this.userIdleService.onTimeout().subscribe(() => {
      this.forceLogoutSubscription = timer(oneMinute).subscribe(() => {
        this.logout();
      });
      this.inactiveUserHandler();
    });
  }

  private inactiveUserHandler(): void {
    this.setTranslations();
    this.modalRef = this.modalService.open(WarningModalComponent, optionsModal as NgbModalOptions);
    this.modalRef.componentInstance.bodyMessage = this.translate.instant('user_inactivity.INACTIVE_SESSION');
    this.modalRef.componentInstance.isInfo = true;
    this.modalRef.componentInstance.hasCancelCallback = true;
    this.modalRef.componentInstance.actionButtons = [
      this.translate.instant('user_inactivity.STAY_CONNECTED'),
      this.translate.instant('user_inactivity.CONNECTING')
    ];

    this.modalRef.componentInstance.onActionConfirmation.subscribe((action: string) => {
      this.forceLogoutSubscription.unsubscribe();
      if (action === 'cancel') {
        this.logout();
      } else {
        this.authenticationService.renovateToken();
        this.userIdleService.resetTimer();
        timer(oneSecond).subscribe(() => {
          this.modalRef.componentInstance.canCloseModal$.next(true);
        });
      }
    });
  }

  private logout(): void {
    this.userIdleService.stopWatching();
    this.authenticationService.signOut();
    this.modalRef.componentInstance.canCloseModal$.next(true);
  }

  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
  }
}
