import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Observable } from 'rxjs/Observable';

import { environment } from 'src/environments/environment';

declare const gapi: any;
const forbidden = 403;
@Injectable({
  providedIn: 'root'
})
export class BigqueryService {
  public service = 'bigquery.googleapis.com';
  constructor(private http: HttpClient) {
  }
  public getBilling(partnerId: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/billings/subscription/${partnerId}`);
  }
  public getIntegrations(organizationId: number): Observable<any> {
    const params = new HttpParams().set('skeepFailure', 'true');
    // tslint:disable-next-line:max-line-length
    return this.http.get<any>(`${environment.casEventsUrl}integrations/configurations/getPartnerEventsConfigs/${organizationId}/${environment.casEventsEndpoint}`, { params });
  }
  public existsDataset(data: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}gcpBillingActivities/existDataset`, { params: data });
  }
  public loadBigquery(): void {
    try {
      return gapi.client.load('bigquery', 'v2');
    } catch (exception) {
      throwError(exception);
      return;
    }
  }
  public hasEnabled(projectId): Promise<any> {
  try {
    return this.getDatasets(projectId)
    .then(function () {
      return Promise.resolve(true);
    }).catch(function (reason) {
      if (reason.result.error.code === forbidden) {
        return Promise.resolve(false);
      }
      return Promise.reject(reason);
    });
  } catch {
    return Promise.reject({name: 'api-not-loaded'});
  }

  }
  public getDatasets(projectId): Promise<any> {
    const request = gapi.client.request({
      path: 'https://www.googleapis.com/bigquery/v2/projects/' + projectId + '/datasets/',
      method: 'GET'
    });
    return request.then(function (response) {
      const datasets = response.result;
      return Promise.resolve(datasets);
    }).catch(function (reason) {
      reason.request = {
        name: 'gapi.client.bigquery.datasets.list',
      };
      return Promise.reject(reason);
    });
  };
}
