import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit,
         Output, SimpleChanges} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { BreakpointsObserverService } from './../../../core/shared/services/breakpoints-observer.service';

import { locale as english } from './i18n/table-toolbar.en';
import { locale as spanish } from './i18n/table-toolbar.es';

@Component({
  selector: 'app-table-toolbar',
  templateUrl: './table-toolbar.component.html',
  styleUrls: ['./table-toolbar.component.scss']
})
export class TableToolbarComponent implements OnInit, OnChanges, AfterViewInit {

  @Input()
  public createRoute: string;
  @Input()
  public editRoute: string;
  @Input()
  public disableEditBtn: boolean;
  @Input()
  public selected: any;
  @Input()
  public securityControlSpecs: {'createRoute': {resource: string, action: string, disable: boolean},
                                'editRoute': {resource: string, action: string, disable: boolean}};
  @Input()
  public showCreateBtn: boolean;

  @Input()
  public showMoreBtn: boolean;

  @Input()
  public showEditBtn: boolean;

  @Output()
  public actionClicked = new EventEmitter<boolean>();

  public disablePopover: boolean;
  public createPopover: string;
  public editPopover: string;
  constructor(private translate: TranslateService, private breakpointsObserverService: BreakpointsObserverService,
              private cdRef: ChangeDetectorRef) {
    this.setTranslations();
    this.setDisabled(null);
  }
  public ngOnInit() {
    setTimeout(() => {
      this.setTranslations();
     });
    this.disablePopover = true;
    this.detectScreenSize();
    if (this.showCreateBtn === undefined) {
      this.showCreateBtn = true;
    }
    if (this.showEditBtn === undefined) {
      this.showEditBtn = true;
    }
  }
  public ngOnChanges(changes: SimpleChanges) {
    this.setTranslations();
    if (changes && changes.disableEditBtn) {
      this.disableEditBtn = changes.disableEditBtn.currentValue;
    }
  }
  public ngAfterViewInit() {
    this.setTranslations();
  }

  public onActionClicked($event): void {
    this.actionClicked.emit($event);
  }
  public setDisabled(mode: string): string {
    if (this.selected instanceof Array) {
      switch (this.selected.length) {
        case 0:
          return 'disabled';
        default:
            if (mode === 'single' && this.selected.length >= 2) {
              return 'disabled';
            }
      }
    }
  }
  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
    this.createPopover = this.translate.instant('table_toolbar.CREATE');
    this.editPopover = this.translate.instant('table_toolbar.EDIT');
  }
  private detectScreenSize(): void {
    this.breakpointsObserverService.mediaBreakpoint$.subscribe((value) => {
      if (value === 'xs' || value === 'sm') {
        this.setTranslations();
        this.disablePopover = false;
      } else {
        this.disablePopover = true;
      }
    });
  }
}
