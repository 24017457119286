export class SecurityGroup {
  public adGroupId: number;
  public name: string;
  public displayName: string;
  public parentADGroupId: number;
  public onCloud: boolean;
  public visible: boolean;
  public companyId: number;
  public deploymentId: number;
  public adGroupStatusId: number;
  public companyUsersIds: Array<number>;
  public status: string;
  public allow: boolean;
  public samAccountName: string;
  public importFrom: string;
  public disabled : boolean;
  public failedProcess: string;
  public displayStatus: string;
  public domain: string;

  constructor(data) {
      this.adGroupId = data.adGroupId || 0;
      this.name = data.name || '';
      this.displayName = data.displayName || '';
      this.parentADGroupId = data.parentADGroupId || 0;
      this.onCloud = data.onCloud || true;
      this.visible = data.visible || true;
      this.companyId = data.companyId || 0;
      this.adGroupStatusId = data.adGroupStatusId || 0;
      this.companyUsersIds = data.companyUsersIds || [];
      this.status = data.status || '';
      this.deploymentId = data.deploymentId || 0;
      this.allow = data.allow || false;
      this.samAccountName = data.samAccountName || '';
      this.importFrom = data.importFrom || '';
      this.disabled = data && data.disabled ? true : false;
      this.failedProcess = data.failedProcess || '';
      this.displayStatus = data.displayStatus || '';
      this.domain = data.domain || '';
  }
}
