import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { FormButtonsModule } from './../../form-buttons/form-buttons.module';

import { AutoUnsubscribeModule } from '../../auto-unsubscribe/auto-unsubscribe.module';
import { ModalLayoutModule } from '../../modal-layout/modal-layout.module';
import { AddonSubscriptionComponent } from './addon-subscription.component';

@NgModule({
    imports: [CommonModule, NgbModule, RouterModule, TranslateModule, FormButtonsModule,
              AutoUnsubscribeModule, FormsModule, ReactiveFormsModule, ModalLayoutModule],
    declarations: [AddonSubscriptionComponent],
    exports: [AddonSubscriptionComponent]
})
export class AddonSubscriptionComponentModule {
}
