
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Deployment, ListDto, SoleTenant, Suffix, TrustedAd, TrustedDomainFqdn, Settings, User, Organization } from 'src/app/models';
import { environment } from '../../environments/environment';
import { NetworkDataDto } from '../models/common/networkData-dto';
import { Page } from '../models/ui-elements/datatable/page';
import { PagedData } from '../models/ui-elements/datatable/paged-data';

@Injectable({
  providedIn: 'root'
})
export class DeploymentsService {
  public deploymentSelectedSubject: BehaviorSubject<Deployment>;
  public deploymentSelected: Observable<Deployment>;
  public deployments: Deployment[];
  public provisionDeletedSubject: BehaviorSubject<boolean>;
  public provisionDeleted: Observable<boolean>;

  public deploymentRecentSubject: BehaviorSubject<Deployment[]>;
  public deploymentRecent: Observable<Deployment[]>;

  public urlFromCatalogSubject: BehaviorSubject<string>;
  public urlFromCatalog: Observable<string>;

  public provisionCreateSubject: BehaviorSubject<boolean>;
  public provisionCreate: Observable<boolean>;

  constructor(private http: HttpClient) {
    let storageDeploymentSelected;
    try {
      storageDeploymentSelected = JSON.parse(sessionStorage.getItem('deploymentSelected'));
    } catch (error) {
      storageDeploymentSelected = null;
    }
    this.deploymentSelectedSubject = new BehaviorSubject<Deployment>(storageDeploymentSelected);
    this.deploymentSelected = this.deploymentSelectedSubject.asObservable();
    this.provisionDeletedSubject = new BehaviorSubject<boolean>(false);
    this.provisionDeleted = this.provisionDeletedSubject.asObservable();
    this.deploymentRecentSubject = new BehaviorSubject<Deployment[]>(undefined);
    this.deploymentRecent = this.deploymentRecentSubject.asObservable();
    this.urlFromCatalogSubject = new BehaviorSubject<string>(undefined);
    this.urlFromCatalog = this.urlFromCatalogSubject.asObservable();
    this.provisionCreateSubject = new BehaviorSubject<boolean>(false);
    this.provisionCreate = this.provisionCreateSubject.asObservable();
  }
  public getRecentDeployments(): Observable<any> {
    const params = new HttpParams().set('countVisit', '3');
    return this.http.get<any>(`${environment.apiUrl}deployments/recents`, {params: params});
  }
  public setDeploymentSelected(deployment): any {
    return this.http.post<any>(`${environment.apiUrl}deployments/${deployment.companyId}/recents`, deployment).subscribe(() => {
      sessionStorage.setItem('dtSelected', JSON.stringify([]));
      sessionStorage.setItem('selected', JSON.stringify([]));
      this.deploymentSelectedSubject.next(deployment);
    });
  }

  public getDeployments(page: Page): Observable<any> {
    const pageNumber = page.pageNumber + 1;
    const params = new HttpParams().set('pageIndex', pageNumber.toString()).set('pageSize', page.size.toString());
    return this.http.get<any>(`${environment.apiUrl}deployments`, {params: params});
  }

  public getDeployment(deploymentId: number): Observable<Deployment> {
    return this.http.get<Deployment>(`${environment.apiUrl}deployments/${deploymentId}`);
  }

  public getResults(page: Page, deployments: any): Observable<PagedData<any>> {
    return of(deployments).pipe(map(data => this.getPagedData(page, deployments)));
  }
  public searchDeployments(search: any): Observable<any> {
    search.pageIndex = Number(search.pageIndex) + 1;
    return this.http.post<any>(`${environment.apiUrl}deployments/search`, search);
  }
  public deleteDeployment(data: any): Observable<any> {
    return this.http.request('DELETE', `${environment.apiUrl}deployments/delete`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: data
    });
  }
  public recoverDeployment(deploymentId: number): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}deployments/${deploymentId}/recover`, null);
  }
  private getPagedData(page: Page, deployments: any): PagedData<any> {
    const pagedData = new PagedData<any>();
    page.totalElements = deployments.count;
    page.totalPages = Math.round(page.totalElements / page.size);
    const start = 0;
    const end = deployments.list.length;
    for (let i = start; i < end; i++) {
      deployments.list[i].originalName = deployments.list[i].name;
      const jsonObj = deployments.list[i];
      pagedData.data.push(jsonObj);
    }
    pagedData.page = page;
    return pagedData;
  }
  public getDeploymentSettings(deploymentId: number): Observable<Settings> {
    return this.http.get<Settings>(`${environment.apiUrl}deployments/${deploymentId}/settings`);
  }
  public getDeploymentInstanceTemplate(deploymentId: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}deployments/${deploymentId}/settings/instanceTemplate`);
  }
  public getDeploymentAgents(deploymentId: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}deployments/${deploymentId}/agents`);
  }
  public getTrustedDomains(page: Page, deploymentId: number): Observable<any> {
    const params = new HttpParams().set('pageIndex', page.pageNumber.toString()).set('pageSize', page.size.toString());
    return this.http.get<any>(`${environment.apiUrl}deployments/${deploymentId}/trustedDomains`, {params: params});
  }
  public getSuffixs(page: Page, deploymentId: number): Observable<ListDto <Suffix>> {
    const params = new HttpParams().set('pageIndex', page.pageNumber.toString()).set('pageSize', page.size.toString());
    return this.http.get<any>(`${environment.apiUrl}deployments/${deploymentId}/suffixs`, {params: params});
  }
  public getSites(page: Page, deploymentId: number): Observable<any> {
    const params = new HttpParams().set('pageIndex', page.pageNumber.toString()).set('pageSize', page.size.toString());
    return this.http.get<any>(`${environment.apiUrl}deployments/${deploymentId}/sites`, {params: params});
  }
  public getAddress(deploymentId: number, siteId: number, addressId: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}deployments/${deploymentId}/sites/${siteId}/address/${addressId}`);
  }
  public getLabels(deploymentId: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}deployments/${deploymentId}/labels`);
  }
  public searchDeploymentUsers(deploymentId: number, params: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}deployments/${deploymentId}/cloudUsers/search`, params);
  }
  public searchDeploymentUsersByCollection(deploymentId: number, collectionId: number, search: any): Observable<ListDto<User>> {
    return this.http
      .post<ListDto<User>>(`${environment.apiUrl}deployments/${deploymentId}/collections/${collectionId}/cloudusers/search`, search);
  }
  public searchDeploymentGroups(deploymentId: number, params: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}deployments/${deploymentId}/securityGroups/search`, params);
  }
  public searchDeploymentCollections(deploymentId: number, params: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}deployments/${deploymentId}/collections/search`, params);
  }
  public searchDeploymentInstances(deploymentId: number, params: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}deployments/${deploymentId}/cloudInstances/search`, params);
  }
  public updateDeploymentDetails(deploymentId: number, params: any): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}deployments/${deploymentId}`, params);
  }
  public updateDeploymentSettings(deploymentId: number, params: any): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}deployments/${deploymentId}/settings`, params);
  }
  public setProvisionDeleted(provisionDeleted: boolean): void {
    this.provisionDeletedSubject.next(provisionDeleted);
  }
  public createTrustedAd(deploymentId: number, trustedAd: TrustedAd): Observable<TrustedAd> {
    return this.http.post<TrustedAd>(`${environment.apiUrl}deployments/${deploymentId}/trustedDomains`, trustedAd);
  }
  public updateTrustedAd(deploymentId: number, trustedAd: TrustedAd ): Observable<TrustedAd> {
    return this.http.put<TrustedAd>(`${environment.apiUrl}deployments/${deploymentId}/trustedDomains/${trustedAd.trustedDomainId}`,
    trustedAd);
  }
  public addSuffix(deploymentId: number, suffix: Suffix): Observable<Suffix> {
    return this.http.post<Suffix>(`${environment.apiUrl}deployments/${deploymentId}/suffixs`, suffix);
  }
  public deleteSuffix(deploymentId: number, suffixId: number): Observable<Object> {
    return this.http.request('DELETE', `${environment.apiUrl}deployments/${deploymentId}/suffixs/${suffixId}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body:  null
    });
  }
  public getNetworkdata(deploymentId: number): Observable<NetworkDataDto> {
    return this.http.get<any>(`${environment.apiUrlV1}companies/onCloud/${deploymentId}/networkdata`);
  }
  public searchDeploymentAdGroups(deploymentId: number, params: Object): Observable<Object> {
    return this.http.post<any>(`${environment.apiUrl}deployments/${deploymentId}/directories/securityGroups/search`, params);
  }
  public searchDeploymentAdGroupsFilter(deploymentId: number, params: Object): Observable<Object> {
    return this.http.post<any>(`${environment.apiUrl}deployments/${deploymentId}/directories/securityGroups/filters/search`, params);
  }
  public setDeploymentRecent(deployments: Deployment[]): void {
    this.deploymentRecentSubject.next(deployments);
  }
  public setUrlFromCatalog(url: string): void {
    this.urlFromCatalogSubject.next(url);
  }
  public deleteVpcNetwork(deploymentId: number): Observable<Object> {
  return this.http.request('DELETE', `${environment.apiUrl}deployments/${deploymentId}/vpcNetwork`, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    body:  null
  });
  }

  public deleteTrustedAd(deploymentId: number, trustedDomainId: number): Observable<Object> {
    return this.http.request('DELETE', `${environment.apiUrl}deployments/${deploymentId}/trustedDomains/${trustedDomainId}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body:  null
      });
    }

  public disableMultipleTrustedAd(deploymentId: number, trustedDomainIds: number[]): Observable<Object> {
    return this.http.request('DELETE', `${environment.apiUrl}deployments/${deploymentId}/trustedDomains`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {list: trustedDomainIds}
    });
  }

  public getTrustedDomainsFqdn(deploymentId: number): Observable<ListDto<TrustedDomainFqdn>> {
      return this.http.post<ListDto<TrustedDomainFqdn>>(`${environment.apiUrl}deployments/${deploymentId}/trustedDomains/fqdns`, null);
    }

  public setProvisionCreate(provisionCreate: boolean): void {
    this.provisionCreateSubject.next(provisionCreate);
  }
  public getSoleTenantConfiguration(deploymentId: number): Observable<ListDto <SoleTenant>> {
    return this.http.get<ListDto <SoleTenant>>(`${environment.apiUrl}deployments/${deploymentId}/soleTenants`);
  }
  public getOrganizations(): Observable<ListDto<Organization>> {
    return this.http.get<ListDto<Organization>>(`${environment.apiUrl}organizations/search`);
  }
}
