import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { CapitalizePipe } from '../../core/shared/pipes/shared-pipes/capitalize.pipe';
import { DirectivesModule } from './../../core/shared/directives/directives.module';
import { ItopiaDatatableComponent } from './datatable/datatable.component';
import { TableToolbarComponent } from './table-toolbar/table-toolbar.component';
@NgModule({
  imports: [CommonModule, TranslateModule, NgbModule, RouterModule, NgxDatatableModule, FormsModule, DirectivesModule ],
    declarations: [TableToolbarComponent, ItopiaDatatableComponent],
    exports: [TableToolbarComponent, ItopiaDatatableComponent],
    providers: [CapitalizePipe]
})
export class DatatableModule {
}
