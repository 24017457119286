import { AbstractControl, ValidationErrors } from '@angular/forms';
export const serverIpDns = function (control: AbstractControl): ValidationErrors | null {
  const value: string = control.value || '';
  if (!value) {
    return null;
  }
  // tslint:disable-next-line: max-line-length
  const ipFormat = new RegExp(/^(([1-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){1}(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){2}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/);
  if ( ipFormat.test(value) === false ) {
    return { pattern : 'Invalid format' };
  }
};
