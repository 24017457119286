import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'underscore';

export const netBiosValidator = function (isNetApp: boolean): ValidationErrors | null {
  return (control: AbstractControl) => {
    const value: string = control.value || '';
    if (!value) {
        return null;
      }
    const regexp = new RegExp(/^[a-zA-Z0-9]+$/);
    const validNetBiosLength = 15;
    const validNetAppLength = 10;
    if (!regexp.test(value)) {
    return {
      pattern: 'Invalid format'
    };
    }
    if  (!isNetApp && value.length > validNetBiosLength) {
      return {
        pattern: `Netbios could not be longer than ${validNetBiosLength} characters.`
      };
    } else if  (isNetApp && value.length > validNetAppLength) {
      return {
        pattern: `Netbios could not be longer than ${validNetAppLength} characters.`
      };
    }
  };
};
