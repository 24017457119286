import { AbstractControl, ValidationErrors } from '@angular/forms';
export const gpoNameValidator = function (control: AbstractControl): ValidationErrors | null {
  const value: string = control.value || '';
  if (!value) {
    return null;
  }
  const regExpNotOnlyNumber = /^([0-9]*[a-z-A-Z_\W*])+[a-z-A-Z-0-9_\W*]*$/;
  const regExpNotOnlyPoint = /^([\.]*[a-z-A-Z-0-9@#$%&*+\-_(),+':;?,!"\s])+[a-z-A-Z-0-9_\W*]*$/;
  if (!regExpNotOnlyNumber.test(value) || !regExpNotOnlyPoint.test(value) ) {
    return { pattern : 'Invalid format' };
  }
};
