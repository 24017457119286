import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';


import { DirectivesModule } from './../../core/shared/directives/directives.module';
import { SpinnerModule } from './../spinner/spinner.module';
import { ManageResourceInTableComponent } from './manage-resource-in-table/manage-resource-in-table.component';

@NgModule({
  imports: [CommonModule, NgbModule, TranslateModule, ReactiveFormsModule, DirectivesModule, FormsModule,
            SpinnerModule, NgSelectModule],
  declarations: [ManageResourceInTableComponent],
  exports: [ManageResourceInTableComponent]
})
export class ManageResourceInTableModule { }
