export class CatalogApp {
  public appId: number;
  public name: string;
  public version: number;
  public workstationId: number;
  public assignmentType: string;
  public caption: string;
  public description: string; 
  public identifyingNumber: string;  
  public helpLink: string;  
  public language: string;  
  public packageCode: string;  
  public urlUpdateInfo: string;  
  public vendor: string;  
  		
  constructor(data?: any) {
    if (data) {
        Object.keys(data).forEach(key => {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                this[key] = data[key];
            }
        });
    }
}
}

  
    
    
  
   
   
