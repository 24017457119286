export const locale = {
  lang: 'en',
  table_toolbar : {
    'REFRESH': 'Refresh',
    'EDIT': 'Edit',
    'DELETE': 'Delete',
    'SETTINGS': `Settings`,
    'RESET_PASS': `Reset Password`,
    'LOG_OF': `Log off`,
    'UNLOCK': `UnLock`,
    'CREATE': `Create`,
    'SHOW': 'Show',
    'PER': 'per page',
    'SEARCH': `Search`,
    'ENABLE_ACTION': 'Enable',
    'DISABLE_ACTION': 'Disable',
    'IMPORT': 'Import from',
    'IMPORT_FROM': 'Import from',
    'IMPORT_EXCEL': 'Spreadsheet',
    'IMPORT_CATALOG': 'Catalog',
    'IMPORT_AD': 'Active Directory',
    'LICENSES': 'Licenses',
    'START': 'Start',
    'STOP': 'Stop',
    'RESTART': 'Restart',
    'CONNECT': 'Connect',
    'CLONE': 'Clone',
    'COMPLETE' : 'Complete',
    'COPY': 'Copy',
    'IMPORT_GCP': 'Import from GCP'
  }
};
