export const locale = {
  lang: 'es',
  multiselect : {
    NO_DATA: 'No hay datos que mostrar',
    TOTAL: 'total',
    SELECTED: 'seleccionado',
    SHOWING: 'mostrando',
    SEARCH_BY_NAME: 'Buscar por nombre',
    SEARCH_BY_LOCATION: 'Buscar por ubicacion',
    RESET_FILTERS: 'Resetear filtros'
  }
};
