export const locale = {
    lang: 'es',
    'complete_admin_invite' : {
        TITLE: 'Complete su perfil',
        FIRST_NAME: 'Nombre',
        LAST_NAME: 'Apellidos',
        USERNAME: 'Nombre de usuario de itopia',
        EMAIL: 'Correo',
        PASSWORD: 'Contraseña',
        CONFIRM_PASSWORD: 'Confirmar contraseña',
        COMPLETE: 'Completar',
        GOOGLE: 'Inicia sesión con Google',
        MICROSOFT: 'Inicia sesión con Microsoft',
        COMPLETING: 'Completando...',
        ERROR_REQUIRED: 'Campo requerido',
        ERROR_ONLY_NUMBER: 'Debe introducir sólo números',
        ERROR_PATTERN: 'Campo inválido',
        ERROR_MAX_LENGTH: 'Tamaño máximo {{val}}',
        ERROR_NAME_PATTERN: 'Debe introducir sólo letras, números o caracteres -\'_',
        PASSWORD_REQUIREMENTS: 'Requisitos para la complejidad de la contraseña',
        ERROR_PASSWORD_MUST_MATCH: 'Las contraseñas no coinciden',
        CONNECTION_ERROR: 'Error de conexión',
        ARGUMENT_ERROR: 'La dirección de correo electrónico debe ser una cadena válida',
        INVALID_EMAIL_ERROR: 'La dirección de correo electrónico está mal formateada',
        EXPIRED_ERROR: 'El código de restablecimiento de contraseña ha caducado',
        RESET_CODE_ERROR: 'El código de restablecimiento de contraseña no es válido',
        USER_DISABLED_ERROR: 'El usuario correspondiente al código de restablecimiento de contraseña dado ha sido deshabilitado',
        USER_NOT_FOUND_ERROR: 'No hay ningún usuario en CAS que corresponda a esta dirección de correo electrónico',
        WEAK_PASSWORD_ERROR: 'La nueva contraseña no es lo suficientemente segura',
        UNEXPECTED_ERROR: 'Error inesperado',
        CLOSED_BY_USER_ERROR: 'Acción cancelada por el usuario',
        UNAUTHORIZED_DOMAIN_ERROR: 'Dominio no autorizado',
        ACCOUNT_EXISTS_ERROR: 'Ya existe esta cuenta',
        USER_PASS_AUTH_TYPE: 'Iniciar sesión con contraseña',
        SSO_AUTH_TYPE: 'Usar inicio de sesión único (SSO)',
        SSO_AUTH_TYPE_INFO: 'Su cuenta SSO debe coincidir con su nombre de usuario de itopia',
        ERROR_EMAIL: 'Este campo debe ser una dirección de correo electrónico en el siguiente formato: "user@example.com"',
        EMAIL_EXISTS_ERROR: 'El correo ya existe'
    }
};
