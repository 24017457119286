export class Shared {
  public id: number;
  public name: string;
  public letter: string;
  public path: string;
  public regionName: string;
  public regionId: number;


  constructor(data ? : Object) {
    this.id = data && data['id'] ? data['id'] : 0;
    this.name = data && data['name'] ? data['name'] : '';
    this.letter = data && data['letter'] ? data['letter'] : '';
    this.regionName = data && data['regionName'] ? data['regionName'] : '';
    this.regionId = data && data['regionId'] ? data['regionId'] : 0;
    this.path = data && data['path'] ? data['path'] : '';
}
}
