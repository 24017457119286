import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';

import { ConfigVar, ListDto, ProvisionDomain } from 'src/app/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProvisionContainerService {
  private provisionStep$: BehaviorSubject<string>;
  public provisionStep: Observable<string>;
  private stepSubmited$: BehaviorSubject<any>;
  public stepSubmited: Observable<string>;
  private stepSubmitedSuccess$: BehaviorSubject<any>;
  public stepSubmitedSuccess: Observable<string>;
  private isLoadingForHide$: BehaviorSubject<boolean>;
  public isLoadingForHide: Observable<boolean>;
  private isExpanded$: BehaviorSubject<boolean>;
  public isExpanded: Observable<boolean>;
  private isLoadingData$: BehaviorSubject<boolean>;
  public isLoadingData: Observable<boolean>;

  constructor(private http: HttpClient) {
    this.provisionStep$ = new BehaviorSubject<string>('deployment-type');
    this.provisionStep = this.provisionStep$.asObservable();
    this.stepSubmited$ = new BehaviorSubject<any>(null);
    this.stepSubmited = this.stepSubmited$.asObservable();
    this.stepSubmitedSuccess$ = new BehaviorSubject<boolean>(false);
    this.stepSubmitedSuccess = this.stepSubmitedSuccess$.asObservable();
    this.isLoadingData$ = new BehaviorSubject<any>(false);
    this.isLoadingData = this.isLoadingData$.asObservable();
    this.isLoadingForHide$ = new BehaviorSubject<boolean>(false);
    this.isLoadingForHide = this.isLoadingForHide$.asObservable();
    this.isExpanded$ = new BehaviorSubject<boolean>(false);
    this.isExpanded = this.isExpanded$.asObservable();
  }
  public setStepSubmitedSuccess(success) {
    this.stepSubmitedSuccess$.next(success);
  }
  public setStepSubmited(deploymentId: number, step: string) {
    this.stepSubmited$.next({deploymentId: deploymentId, step: step});
  }
  public setProvisionStep(deploymentId: number, step: string) {
    this.patchDeploymentStep(deploymentId, step).subscribe(() => {
      this.provisionStep$.next(step);
    });
  }
  public setIsLoadingData(isLoadingData: boolean) {
    this.isLoadingData$.next(isLoadingData);
  }
  public setIsLoadingForHide(isLoadingData: boolean) {
    this.isLoadingForHide$.next(isLoadingData);
  }
  public setIsExpanded(isExpanded: boolean) {
    this.isExpanded$.next(isExpanded);
  }
  public getDeployment(deploymentId: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}deployments/${deploymentId}`);
  }
  public postDeployment(deployment: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}deployments`, deployment );
  }
  public putDeployment(deploymentId: number, deployment: any): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}deployments/${deploymentId}`, deployment );
  }
  public patchDeploymentStep(deploymentId: number, deploymentSteps: string): Observable<any> {
    return this.http.patch(`${environment.apiUrl}deployments/${deploymentId}/deploymentStep`, {value: deploymentSteps});
  }
  public getProvision(deploymentId: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}deployments/${deploymentId}/provisions`);
  }
  public putProvision(deploymentId: number, provisionId: number, provision: ProvisionDomain): Observable<ProvisionDomain> {
    return this.http.put<ProvisionDomain>(`${environment.apiUrl}deployments/${deploymentId}/provision/${provisionId}`, provision );
  }
  public searchDefaultProvisionOs(): Observable<ListDto<ConfigVar>> {
    const search = {
      pageIndex: 0,
      pageSize: 1,
      filter: [{ propertyName: 'Name', operation: 0, value: 'DefaultProvisionOs', toLower: 'true', unaryOperation: 0, binaryOperation: 0 }]
    };
    return this.http.post<ListDto<ConfigVar>>(`${environment.apiUrl}configVariables/search`, search);
  }
}
