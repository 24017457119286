import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

import { CollectionPoolStatus as collectionPoolStatus } from 'src/app/models/status/collection-pool-status';

const textNode = 3;
@Directive({
  selector: '[appCollectionStatus]'
})
export class CollectionStatusDirective implements OnInit, OnChanges {
  @Input()
  public collectionStatus: number;
  @Input()
  public displayStatus: string;
  @Input()
  public renderStatus: boolean;

  constructor(private el: ElementRef, private renderer: Renderer2, @Inject(DOCUMENT) private document) { }

  public ngOnInit() {
    this.getCollStatus(this.collectionStatus, this.renderStatus);
  }
  public ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.renderStatus) {
      this.renderStatus = changes.renderStatus.currentValue;
      this.getCollStatus(this.collectionStatus, this.renderStatus);
    }
    if (changes && changes.collectionStatus) {
      this.collectionStatus = changes.collectionStatus.currentValue;
      this.getCollStatus(this.collectionStatus, this.renderStatus);
    }
  }

  private getCollStatus(status: number, renderStatus: boolean) {
    const child = document.createElement('span');
    const childElements = this.el.nativeElement.childNodes;
    for (const children of childElements) {
      if (children.nodeType !== textNode && children.id !== 'copy') {
        this.renderer.removeChild(this.el.nativeElement, children);
      }
    }
    if (!renderStatus) {
      child.innerHTML = `<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="loading m-r-2">
        <circle cx="50" cy="50" r="45"/>
      </svg>`;
    } else {
    switch (status) {
      case collectionPoolStatus.Ready:
        child.className = 'fas fa-circle text-success m-r-2';
        break;
      case collectionPoolStatus.Failed:
        child.className = 'fas fa-circle text-danger m-r-2';
        break;
      default:
        child.className = 'fas fa-circle c-orange m-r-2';
        break;
    }
  }
    child.setAttribute('title', this.displayStatus);

    this.renderer.insertBefore(this.el.nativeElement, child, this.el.nativeElement.firstChild);
  }
}
