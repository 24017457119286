import { MachineType } from './machineType';

export class ScaleRange {
  public scaleRangeId: number;
  public from: number;
  public to: number;
  public machineType: MachineType;

  constructor(data) {
    this.from = data.from;
    this.to = data.to;
    this.machineType = new MachineType(data.machineType);
  }
}
