export class ServerType {
    public operationSystem: string;
    public machyneType: string;
    public core: number;
    public memory: number;
    public diskSize: number;
    public diskType: string;
    public count: number;
    public serverType: string;
    public provisionId: number;

    constructor(data ? : any) {
        this.operationSystem = data && data.operationSystem ? data.operationSystem : '';
        this.machyneType = data && data.machyneType ? data.machyneType : '';
        this.core = data && data.core ? data.core : 0;
        this.memory = data && data.memory ? data.memory : 0;
        this.diskSize = data && data.diskSize ? data.diskSize : 0;
        this.diskType = data && data.diskType ? data.diskType : '';
        this.count = data && data.count ? data.count : 0;
        this.serverType = data && data.serverType ? data.serverType : '';
        this.provisionId = data && data.provisionId ? data.provisionId : 0;
    }
}
