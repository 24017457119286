import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs/Observable';

import { ListDto, Scale } from 'src/app/models';
import { environment } from './../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MultiselectPoolsService {
  constructor(private http: HttpClient) { }

  public searchCollectionPools(deploymentId: number, filter: Object): Observable<ListDto<Scale>> {
    const url = `${environment.apiUrl}deployments/${deploymentId}/collections/search`;
    return this.http.post<ListDto<Scale>>(url, filter);
  }
}
