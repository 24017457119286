export class Time {
    public hour: number;
    public minute: number;
    public second: number;
    constructor(data? : any) {
        this.hour = data && data.hour ? data.hour : null;
        this.minute = data && data.minute ? data.minute : null;
        this.second = data && data.second ? data.second : null;
    }
}
