import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { NotifierService } from 'angular-notifier';
import { HttpCancelService } from 'src/app/core/shared/services/http-cancel.service';
import { HttpStatusCodes, ValidationErrorsCodes } from '../../../models/http';
import { AuthenticationService } from '../../security/authentication.service';
import { errorInterceptorExcludeNames} from '../const';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private notification: { type: string; message: string; };
  constructor(private authenticationService: AuthenticationService, private notifierService: NotifierService,
              private router: Router, private httpCancel : HttpCancelService) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const intercept = request.params.get('skeepFailure') !== 'true' && !errorInterceptorExcludeNames.some( route => request.url.endsWith(route));
    return next.handle(request).pipe(catchError(err => {
        if (intercept) {
        const errorCode = err.error.code;
        const error = err.error.message || err.statusText;
        let errorDeleteCollection;
        switch (err.status) {
          case HttpStatusCodes.Forbidden:
            if (err.error.code !== ValidationErrorsCodes.ValidationError40135 && err.url.indexOf('current') === -1) {
              this.authenticationService.signOut();
            } else if (err.error.code !== ValidationErrorsCodes.ValidationError40135 && err.url.indexOf('current') > -1) {
              this.httpCancel.cancelPendingRequests();
              this.authenticationService.logout();
            }
            break;
          case HttpStatusCodes.ValidationError:
            this.notification = { type: 'error', message: 'Validation error' };
            this.notifierService.notify(this.notification.type, error);
            break;
          default:
            let omit = false;
            if (err && err.error) {
              if (errorCode !== ValidationErrorsCodes.ValidationError40031 && errorCode !== ValidationErrorsCodes.ValidationError40040 &&
                errorCode === ValidationErrorsCodes.ValidationError40027) {
                errorDeleteCollection = err.error.errors ? err.error.errors.Collection : undefined;
              } else if (errorCode === ValidationErrorsCodes.ValidationError40034 && request.method === 'DELETE'
                && request.url.includes('cloudOs')) {
                omit = true;
              }
            }
            if (!omit) {
              this.notification = { type: 'error', message: error};
              this.notifierService.notify(this.notification.type, errorDeleteCollection ? errorDeleteCollection : error);
            }
            break;
        }
      }
        return throwError(err);
      }));
  }
}
