import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

import { environment } from 'src/environments/environment';
import { GutilsService } from './gutils.service';

declare const gapi: any;
@Injectable({
  providedIn: 'root'
})
export class GbillingService {
  public service = 'cloudbilling.googleapis.com';
  constructor(private gutilsService: GutilsService) { }

  public loadGBilling(): void {
    try {
      return gapi.client.load('cloudbilling', environment.gapi.apiVersion);
    } catch (exception) {
      throwError(exception);
      return;
    }
  }
  public getBillingInfo(projectId): Promise<any> {
    try {
      const name = this.gutilsService.getProjectName(projectId);
      const params = {
        name: name
      };
      const request = gapi.client.cloudbilling.projects.getBillingInfo(params);
      return request.then(function (response) {
        return Promise.resolve(response.result);
      }).catch(function (reason) {
        reason.request = {
          name: 'gapi.client.compute.zones.list',
          params: params
        };
        return Promise.reject(reason);
      });
    } catch {
      return Promise.reject({name: 'api-not-loaded'});
    }
  }

  public billingEnabled(projectId) {
    try {
      return this.getBillingInfo(projectId)
      .then(function (b) {
        return Promise.resolve(b.billingEnabled);
      }).catch(function (reason) {
        return Promise.reject(reason);
      });
    } catch {
      return Promise.reject({name: 'api-not-loaded'});
    }
  }
  public activate(consumer) {
    try {
       const params = {
        serviceName: this.service,
        consumerId: consumer
    };
       const request = gapi.client.servicemanagement.services.enable(params);
       return request.then((response) => {
        return Promise.resolve(response.result);
    }).catch((reason) => {
        reason.request = {
            name: 'servicemanagement.googleapis.com/v1/services/cloudbilling.googleapis.com:enable',
            params: params
        };
        return Promise.reject(reason);
    });
  } catch {
    return Promise.reject({name: 'api-not-loaded'});
  }
}
}
