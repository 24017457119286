export enum EarlyAccessFeature {
    PoolGpu = 'collection_gpu',
    DUForDedicated = 'dedicated_du',
    Branding = 'branding',
    NotifyUserEmail = 'create_user_notify_email',
    NotifyUserImportEmail = 'import_user_notify_email',
    MultipleCollections = 'multiple_collections',
    DeleteUserAd = 'delete_user_ad',
    ShadowSession = 'shadow_session',
    Redeploy = 'redeploy_uss',
    Delete_vmi_gcp = 'delete_vmi_gcp',
    MoveMultiplePools = 'move_multiple_pools',
    ImportUsersV2 = 'import_users_v2',
    UnassignedHost = 'unassigned_host',
    PoolCollectionType = 'pool_collection_type',
    AutoImport = 'auto_import',
    RdpPortal = 'rdp_portal',
    CloudAppV2 = 'cloud_app_v2',
    TrustAd = 'trust_ad_existing',
    InfrastructureServer = 'infrastructure_server',
    AppServerV2 = 'app_server_v2',
    CollectionLegacy = 'collections_legacy',
    ReassignUser = 'reassign_user',
    MultipleAdTrusts = 'multiple_ad_trusts',
    DeleteGroupAd = 'delete_group_ad',
    InstancesTableConfiguration = 'instances_table_configuration',
    SessionSettings = 'session_settings',
    UserMultipleCollectionsFromExcel = 'user_multiple_collections_from_excel',
    UserMultipleCollectionsFromCatalog = 'user_multiple_collections_from_catalog',
    UserMultipleCollectionsFromAd = 'user_multiple_collections_from_ad',
    GroupMultipleCollectionsFromAD = 'group_multiple_collections_from_ad',
    ApiKeyScim = 'api_key_scim',
    PoolSessionHost = 'pool_session_host_tab',
    ProcessViewer = 'process_viewer',
    InstanceReport = 'instace_report_insights',
    MaintenanceMode = 'maintenance_mode',
    AdExist = 'ad_exist',
    PreviewFeatures = 'preview-features',
    AdminEmailNotifications = 'admin_email_notifications',
    AddProvisionRegion = 'add_provision_region',
    AddCollectionRegion = 'add_collection_region',
    ProvisionSoletenant = 'provision_soletenant',
    ProvisionEnableFirewall = 'provision_enable_firewall',
    PoolSizingRatio = 'pool_sizing_ratio',
    MaintenanceWindows = 'maintenance_windows',
    InstanceCreateSnapshot = 'instance_create_snapshot',
    ProvisionImage = 'provision_image',
    AutoGrowDisks = 'auto_grow_disks',
    RestoreFromSnapshot = 'restore_from_snapshot',
    AdExistChanges = 'ad_exist_changes',
    RequiredSua = 'required_sua',
    AdGroupsFilters = 'ad_groups_filters',
    ResetPasswordV2 = 'reset_password_v2',
    ExpireCurrentPassword = 'expire_current_password',
    BulkResetPassword = 'bulk_reset_password',
    ManageADSubnetRange = 'manage_ad_subnet_range'
}
