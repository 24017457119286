export class CatalogWorkstationNetworkCard{
  public workstationId: number;
  public networkCardId: number;
  public ip: string;
  public mac: string;
    
  constructor(data?: any) {
    if (data) {
        Object.keys(data).forEach(key => {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                this[key] = data[key];
            }
        });
    }
}
}
