export class Country {
  public countryId: number;
  public name: string;
  public alpha2Code: string;
  public alpha3Code: string;
  public numericCode: string;
  public active: boolean;

  constructor(data?: any) {
    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          this[key] = data[key];
        }
      }
    }
  }
}
