import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'underscore';

export const adDomainValidator = function (isNetApp: boolean, isExistingDomain?: boolean): ValidationErrors | null {
  return (control: AbstractControl) => {
const value: string = control.value || '';
if (!value) {
    return null;
  }
const regexp = new RegExp(/^(?=.{1,253}\.?$)(?:[A-Za-z0-9_-]{2,63}(?:\.|$)){2,5}$/);
const rgxEnd = new RegExp(/\.$/);
const netBiosValue = value ? value.split('.')[0] : '';
const validNetBiosLength = 15;
const validNetAppLength = 10;
const rgxSubdomains = isExistingDomain
                    ? new RegExp(/^[a-zA-Z0-9_-]{1,28}(\.[a-zA-Z0-9_-]{1,28}){0,3}(\.[a-zA-Z0-9_-]{1,9}){1}$/)
                    : new RegExp(/^[a-zA-Z0-9_-]{1,28}(\.[a-zA-Z0-9_-]{1,28}){0,1}(\.[a-zA-Z0-9_-]{1,9}){1}$/);
if (!regexp.test(value) || rgxEnd.test(value) || !rgxSubdomains.test(value)) {
  return {
    pattern: 'Invalid format'
  };
}
if  (!isNetApp && netBiosValue && netBiosValue.length > validNetBiosLength) {
  return {
    pattern: `Netbios could not be longer than ${validNetBiosLength} characters.`
  };
} else if  (isNetApp && netBiosValue.length > validNetAppLength) {
  return {
    pattern: `Netbios could not be longer than ${validNetAppLength} characters.`
  };
}
};
};
