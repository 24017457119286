import { Component, Input, OnInit} from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import * as _ from 'underscore';

import { ListDto, ServerError, ValueDto } from 'src/app/models';
import { NotificationModalType } from 'src/app/models/enums/notification-modal-type';
import { PesService } from 'src/app/provision/deployment-size/pes.service';
import { AutoUnsubscribeComponent } from 'src/app/shared/auto-unsubscribe/auto-unsubscribe.component';

import {ModalService} from '../../core/shared/services/modal.service';
import { MadExtendedEnableFirewallService } from '../mad-extended-enable-firewall/mad-extended-enable-firewall.service';

import { locale as english } from './i18n/notifications-modal.en';
import { locale as spanish } from './i18n/notifications-modal.es';

@Component({
  selector: 'app-notifications-modal',
  templateUrl: './notifications-modal.component.html',
  styleUrls: ['./notifications-modal.component.scss']
})
export class NotificationsModalComponent extends AutoUnsubscribeComponent implements OnInit {

  @Input()
  public notificationModalType: NotificationModalType;
  @Input()
  public params: object;
  @Input()
  public modal: NgbModalRef;

  public deploymentId : number;
  public dataRef: string;
  public cancelText: string;
  public isSharedVpcFwr: boolean;
  public sharedVpcFwrSubtitles: string[];
  public dnsServerIps: string[];
  /**********Notification Script************/
  public commandText: string;
  public loadingScripts: boolean;
  public scriptValue: string;
  public errorGetCommand: string;
  /******************************************/

  constructor(private pesService: PesService, private translate: TranslateService,  private modalService: ModalService,
              private enableFirewallService: MadExtendedEnableFirewallService) {
    super();
    this.setTranslations();
  }

  public ngOnInit() {
    this.scriptValue = NotificationModalType.Script;
    if (this.notificationModalType === this.scriptValue) {
      if (this.params) {
        this.deploymentId = (this.params as any).deploymentId;
        this.dataRef = (this.params as any).dataRef;
        this.isSharedVpcFwr = (this.params as any).isSharedVpcFwr || false;
        if (this.isSharedVpcFwr) {
          this.sharedVpcFwrSubtitles = (this.params as any).sharedVpcFwrSubtitles;
          this.dnsServerIps = (this.params as any).dnsServerIps;
        }
        this.getCommands();
      }
    }
  }

  public getCommands(): void {
    this.loadingScripts = true;
    if (this.isSharedVpcFwr) {
      const configCommandSubs = this.enableFirewallService.
      getSharedVpcExistADConfig(this.deploymentId, this.dnsServerIps, true)
          .pipe(finalize(() => {
              this.loadingScripts = false;
          }))
          .subscribe((response: ListDto<string>) => {
            this.commandText = _.reduce(response && response.list || [], (memo, current) => memo.concat('\n', current), '');
            this.errorGetCommand = '';
        }, (ex: {error: ServerError}) => {
          this.errorGetCommand = ex.error.message;
        });
      super.addSubscriptions(configCommandSubs);
    } else {
      const configCommandSubs = this.pesService.
      getFirewallConfigPes(this.deploymentId, true)
          .pipe(finalize(() => {
              this.loadingScripts = false;
          }))
          .subscribe((response: ValueDto<string>) => {
            this.commandText = response.value;
            this.errorGetCommand = '';
        }, (ex) => {
          this.errorGetCommand = ex.error.message;
        });
      super.addSubscriptions(configCommandSubs);
    }
  }

  public closeModal(): void {
    if (this.modal) {
      this.modal.dismiss();
    }
  }

  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
    this.cancelText = this.translate.instant('notifications_modal.CANCEL');
  }
}
