export class OsPatching {
    public name : string;
    public description : string;
    public scheduleDate : string;
    public scheduleFrequencyCode : string;
    public deploymentId : number;
    public patchFilterTypeName :  string;
    public patchProfileStatusCode: string;
    public patchProfileId: number;

    constructor(data?: any) {
        this.patchProfileId = data && data.patchProfileId ? data.patchProfileId : '';
        this.name = data && data.name ? data.name : '';
        this.description = data && data.description ? data.description : '';
        this.scheduleFrequencyCode = data && data.scheduleFrequencyCode ? data.scheduleFrequencyCode : '';
        this.deploymentId = data && data.deploymentId ? data.deploymentId : 0;
        this.patchFilterTypeName = data && data.freqpatchFilterTypeCodeuency ? data.patchFilterTypeName : '';
        this.scheduleDate = data && data.scheduleDate ? data.scheduleDate : '';
        this.patchProfileStatusCode = data && data.patchProfileStatusCode ? data.patchProfileStatusCode : '';
    }
}
