import { GpuConfig } from './gpu-config';

export class GpuCard {
    public name: string;
    public description: string;
    public code: string;
    public agent: string;
    public active: boolean;
    public configurations: GpuConfig[];

    constructor(data?: any) {
        this.name = data && data.name ? data.name : '';
        this.description = data && data.description ? data.description : '';
        this.code = data && data.code ? data.code : '';
        this.agent = data && data.agent ? data.agent : '';
        this.active = data && data.active ? data.active : false;
        this.configurations = data && data.configurations ? data.configurations : [];
    }
}