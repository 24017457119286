export const excludeUrlAuthGuard = [
  'cloud_manager/vpns',
  '/integrations',
  '/audits',
  '/profile',
  '/subscription',
  '/notifications'
];


