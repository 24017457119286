export const locale = {
  lang: 'es',
  metrics : {
    METRIC_ERROR: '¡Vaya! La acción no ha sido ejecutada. Por favor revise las siguientes condiciones:',
    METRIC_MESSAGE_YOUR : 'Su ',
    METRIC_MESSAGE_FOR : 'para ',
    METRIC_MESSAGE_1_IP : 'cuota de IP en GCP ',
    METRIC_MESSAGE_1_GPU: 'cuota de GPU ',
    METRIC_MESSAGE_1_CPU: 'cuota de CPU ',
    METRIC_MESSAGE_1_SSD_DISK: 'cuota de Disco SSD',
    METRIC_MESSAGE_1_STANDARD_DISK: 'cuota de Disco Standard',
    METRIC_MESSAGE_NOT_ENOUGH: 'no es suficiente.',
    METRIC_FOLLOW: 'Por favor siga ',
    METRIC_GUIDE_IP_QUOTA: 'las instrucciones para incrementar las cuotas IP.',
    METRIC_GUIDE_GPU_QUOTA: 'las instrucciones para incrementar las cuotas de GPU.',
    METRIC_GUIDE_CPU_QUOTA: 'las instrucciones para incrementar las cuotas de CPU.',
    METRIC_GUIDE_SSD_DISK_QUOTA: 'las instrucciones para incrementar las cuotas de disco SSD.',
    METRIC_GUIDE_STANDARD_DISK_QUOTA: 'las instrucciones para incrementar las cuotas de disco Standard.',
    AVAILABLE: 'disponibles',
    NEEDED: 'necesita',
  }
};
