export const locale = {
  lang: 'en',
  'warning_modal_cancel_subscription' : {
    CANCEL_WHAT_HAPPEN: 'What\'s going to happen?',
    CANCEL_SUBCRIPTION_MESSAGE1: 'Cancellation takes effects right away when you select IMMEDIATELY.',
    CANCEL_SUBCRIPTION_MESSAGE2: 'Cancellation takes effect on {{date}} when you select END OF PERIOD.',
    CANCEL_SUBCRIPTION_MESSAGE3: 'The customer\'s credit card will no longer be charged after cancellation takes effect.',
    CANCEL_SUBCRIPTION_MESSAGE4: 'No refunds or prorations will be made.',
    CANCEL_SUBCRIPTION_MESSAGE5: 'Canceled subscription can be reactivated.',
    DELETE_IMMEDIATELY: 'IMMEDIATELY',
    DELETE_END_PERIOD: 'END OF PERIOD',
    CANCEL: 'Cancel',
    CANCELING: 'Canceling ...',
  }
};
