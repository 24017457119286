import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { environment } from 'src/environments/environment';
import { ListDto, Notification } from '../models';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private http: HttpClient) { }

  public getNews(notificationType?: number, isEqual?: boolean): Observable<ListDto<Notification>> {
    let params: HttpParams;
    if (notificationType !== undefined) {
      const equal = isEqual !== undefined ? isEqual : true;
      params = new HttpParams().set('type', `${notificationType}`)
                               .set('isEqual', `${equal}`);
    }
    return this.http.get<ListDto<Notification>>(`${environment.apiUrl}notifications/news`, { params });
  }
  public markAsRead(notificationType?: number, isEqual?: boolean): Observable<any> {
    let params: HttpParams;
    if (notificationType !== undefined) {
      const equal = isEqual !== undefined ? isEqual : true;
      params = new HttpParams().set('type', `${notificationType}`)
                               .set('isEqual', `${equal}`);
    }
    return this.http.post<any>(`${environment.apiUrl}notifications/read`, null, { params });
  }
}
