export class RegionReportDto {
  public regionId: number;
  public regionName: string;
  public regionDescription: string;
  public primary: boolean;
  constructor(data ? : any) {
    this.regionId = data && data.regionId ? data.regionId : 0;
    this.regionName = data && data.regionName ? data.regionName : '';
    this.regionDescription = data && data.regionDescription ? data.regionDescription : '';
    this.primary = data && data.primary ? data.primary : true;
  }
}
