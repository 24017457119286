import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {FlexModule} from '@angular/flex-layout';

import { ListViewLayoutComponent } from './list-view-layout.component';



@NgModule({
  declarations: [ListViewLayoutComponent],
    imports: [
        CommonModule,
        FlexModule
    ],
  exports: [ListViewLayoutComponent]
})
export class ListViewLayoutModule { }
