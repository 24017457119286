export const locale = {
    lang: 'en',
    support_token : {
        SUPPORT_TOKEN: 'Support token',
        DEPLOYMENTS: 'Deployments',
        EXPIRATION: 'Expiration',
        HOURS: 'hours',
        GENERATE: 'Generate',
        GENERATING: 'Generating ...',
        CANCEL: 'Cancel',
        CLOSE: 'Close',
        SUCCESS: 'Support token successfully generated',
        ERROR_REQUIRED: 'This field is required',
        INVALID_HOURS: 'Invalid hours',
        HOUR_HELP: 'Maximum 24',
        COPY: 'Copy',
        COPIED: 'Copied',
        COPY_MESSAGE: 'Support token is only visible now. Please copy and save it for your records.',
        TOKEN_INSTRUCTION: `The support token is used by itopia technical support staff to obtain view
            access to your deployment for a limited amount of time.`,
        TOKEN_TEXT_INFO: 'Note: This support token is only visible now. Please copy and save it for your records.',
    }
};
