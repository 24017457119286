export class AuditDetailDto {
  public date: string;
  public organizationId: number;
  public auditId: number;
  public ip: string;
  public userRequest: string;
  public type: string;
  public action: string;
  public description: string;
  public email: string;
  public fullNameUser: string;
  public fullNamePartner: string;
  public deploymentId: number;
  public oldObject: string;
  public newObject: string;
  public time: string;
  constructor( data?: any) {
  this.date = data && data.date ? data.date : '';
  this.organizationId = data && data.organizationId ? data.organizationId : undefined;
  this.auditId = data && data.auditId ? data.auditId : undefined;
  this.ip = data && data.ip ? data.ip : '';
  this.userRequest = data && data.userRequest ? data.userRequest : '';
  this.type = data && data.type ? data.type : '';
  this.action = data && data.action ? data.action : '';
  this.description = data && data.description ? data.description : '';
  this.email = data && data.email ? data.email : '';
  this.fullNameUser = data && data.fullNameUser ? data.fullNameUser : '';
  this.fullNamePartner = data && data.fullNamePartner ? data.fullNamePartner : '';
  this.deploymentId = data && data.deploymentId ? data.deploymentId : undefined;
  this.oldObject = data && data.oldObject ? data.oldObject : '';
  this.newObject = data && data.newObject ? data.newObject : '';
  this.time = data && data.time ? data.time : '';
  }
}

