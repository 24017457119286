import { Component, ElementRef, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';

import { MenuEventsService } from 'src/app/core/shared/services/menu-events.service';
import { Deployment } from 'src/app/models';
import {ModalService} from '../../core/shared/services/modal.service';
import { User } from '../../models/cloud-desktops/user';
import { AuthenticationService } from './../../core/security/authentication.service';
import { DeploymentsListComponent } from './../../deployments/deployments-list/deployments-list.component';
import { DeploymentsService } from './../../deployments/deployments.service';
import { AutoUnsubscribeComponent } from './../auto-unsubscribe/auto-unsubscribe.component';
import { ROUTES } from './menu-items';
import { SidebarListService } from './sidebar.service';

import { locale as english } from './i18n/sidebar.en';
import { locale as spanish } from './i18n/sidebar.es';

const timeout = 1000;
const ninetynine = 99;
const deploymentReady = 3;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent extends AutoUnsubscribeComponent implements OnInit {
  public showMenu = '';
  public showSubMenu = '';
  public sidebarnavItems: any[];
  public currentUser: User;
  public deploymentsLoaded = false;
  public taskCount = 0;
  public hideTaskCount = true;
  public organizationId: number;
  public deploymentId: number;
  public isNone: boolean;
  public subMenu: any;
  public showInProgress: boolean;
  public deploymentSelected: Deployment;
  public showPendingDeletion: boolean;
  public isMini: boolean;
  public listBtn: string;
  public showLabel: boolean;
  public settingNewAppModel: boolean;
  public isDeploymentReady: boolean;

  constructor(private translate: TranslateService, private authenticationService: AuthenticationService,
              private deploymentsMenuService: DeploymentsService, private sidebarListService: SidebarListService,
              private router: Router, private menuEventsService: MenuEventsService,  private modalService: NgbModal,
              private renderer: Renderer2) {
    super();
    this.settingNewAppModel = false;
    const getMiniSideBarSubs = this.menuEventsService.getMiniSideBar().subscribe((isMini) => {
      this.setTranslations();
      this.showLabel = isMini ? false : true;
    });
    super.addSubscriptions(getMiniSideBarSubs);
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.deploymentsMenuService.getRecentDeployments().subscribe(() => {
      setTimeout(() => {
        this.setTranslations();
        this.deploymentsLoaded = true;
      }, timeout);
    });
  }
  // End open close
  public ngOnInit() {
    this.subMenu = [];
    const deploymentSelectedSubs = this.deploymentsMenuService.deploymentSelected.subscribe(deploymentSelected => {
      if (deploymentSelected && deploymentSelected.organizationId) {
        this.deploymentSelected = deploymentSelected;
        this.organizationId = deploymentSelected.organizationId;
        this.deploymentId = deploymentSelected.companyId;
        this.isDeploymentReady = this.deploymentSelected.companiesStatusId === deploymentReady;
        if (this.isDeploymentReady) {
          const deploymentSettingsSubs = this.deploymentsMenuService.getDeploymentSettings(this.deploymentId)
            .subscribe((data: any) => {
              const newAppModel = _.find(data.settings, (value: string, key: string) => key === 'nam' && value === 'True');
              this.settingNewAppModel = newAppModel === 'True';
              if (deploymentSelected.provisionType === 'DEN') {
                const seoSetting = _.find(data.settings, (value: string, key: string) => key === 'seo' && value === 'True');
                this.isNone = seoSetting === 'True';
                this.loadSidebarItems();
              } else {
                this.isNone = false;
                this.loadSidebarItems();
              }
              const showCatalogItems = _.some(data.settings, (value: string, key: string) =>
                key.toLowerCase() === 'sci' && value.toLowerCase() === 'true');
              const catalog = _.findWhere(this.sidebarnavItems, { id: 'catalog' });
              if (catalog) {
                catalog['hidden'] = !this.isDeploymentReady || !showCatalogItems;
              }
            }, () => {
              const catalog = _.findWhere(this.sidebarnavItems, { id: 'catalog' });
              if (catalog) {
                catalog['hidden'] = true;
              }
            });
          super.addSubscriptions(deploymentSettingsSubs);
        }

      }
    });
    super.addSubscriptions(deploymentSelectedSubs);
    const getMiniSideBarSubs = this.menuEventsService.getMiniSideBar().subscribe((isMini) => {
      this.isMini = isMini;
    });
    super.addSubscriptions(getMiniSideBarSubs);
    this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
    this.setTranslations();
    this.sidebarnavItems.forEach((element) => {
      element.title = this.translate.instant(element.title);
      if (element.title === this.translate.instant('sidebar.TASKS')) {
        element.showBadged = true;
      }
      if (element.submenu.length > 0) {
        element.submenu.forEach((submenu) => {
          submenu.title = this.translate.instant(submenu.title);
        });
      }
    });
  }
  // this is for the open close
  public addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  public addActiveClass(element: any) {
    if (element.id === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element.id;
    }
  }
  public removeActiveNonSelected(element: any): void {
    this.sidebarnavItems.forEach(elementList => {
      if (elementList) {
        if (elementList.id !== element.id) {
          if (document.getElementById(elementList.id).childNodes) {
            const nodeList = document.getElementById(elementList.id).childNodes;
            this.renderer.removeClass(nodeList[2], 'active');
          }
        }
      }
    });
  }
  public isDisabled(link: string): boolean {
    const prevState = this.router.routerState.snapshot.url;
    if (link.indexOf('cloud_desktops') > -1 && this.isNone) {
      return true;
    } else {
      if (link.indexOf('dashboard') > -1 && prevState.indexOf('dashboard') > -1) {
        return true;
      } else {
        return false;
      }
    }
  }
  public showDeploymentsListModal(): void {
    const deployListModal = this.modalService.open(DeploymentsListComponent,
      { size: 'md', backdrop: 'static', keyboard: false, centered: true });
    deployListModal.componentInstance.showInModal = true;
  }
  private loadSidebarItems(): void {
    const urlRegVal = new RegExp(/\/deployment\/([0-9]+)|\/deployment\/:deploymentId/);
    const replaceStr = `/deployment/${this.deploymentId}`;
    this.sidebarListService.searchTasks(this.organizationId).subscribe((tasks: any) => {
        this.taskCount = tasks.count <= ninetynine ? tasks.count : '99+';
        this.hideTaskCount = this.taskCount === 0;
      });
    _.map(this.sidebarnavItems, (menu: any) => {
        if (this.isNone && menu.id === 'cloud_desktops') {
          this.subMenu = menu.submenu;
          menu.submenu = [];
        } else if (!this.isNone && menu.id === 'cloud_desktops') {
          menu.submenu = this.subMenu.length > 0 ? this.subMenu : menu.submenu;
        }
        menu.path = this.subMenu.length === 0 && menu.path !== '/dashboard' ? menu.path.replace(urlRegVal, replaceStr) : menu.path;
        _.map(menu.submenu, (item: any) => {
          item.path =  item.path.replace(urlRegVal, replaceStr);
        });
      });
  }
  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
    this.listBtn = this.translate.instant('sidebar.ALL');
  }
}
