import { Component, Input, ViewChild, OnInit } from '@angular/core';

@Component({
  selector: 'app-map-google',
  templateUrl: './map-google.component.html',
  styleUrls: ['./map-google.component.scss']
})
export class MapgoogleComponent implements OnInit {
  @ViewChild('map1', { static: true }) public map1;
  @ViewChild('map2', { static: true }) public map2;
  @ViewChild('map3', { static: true }) public map3;
  @ViewChild('map4', { static: true }) public map4;

  @Input() public lat;
  @Input() public lng;
  @Input() public latA;
  @Input() public lngA;
  @Input() public zoom;
  @Input() public infoWindow;
  @Input() public mapType;
  @Input() public mapTypeId;
  @Input() public regions: any[];


  public styles: any = [
    {
      featureType: 'all',
      stylers: [
        {
          saturation: -80
        }
      ]
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [
        {
          hue: '#00ffee'
        },
        {
          saturation: 50
        }
      ]
    },
    {
      featureType: 'poi.business',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    }
  ];

  constructor() {}

  public ngOnInit(): void {
    if (this.regions) {
      this.regions.map((region: any) => {
        region.icon = region.primary ? 'https://maps.google.com/mapfiles/ms/icons/red-dot.png'
                                     : 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png';
      });
    }
  }


}
