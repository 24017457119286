import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CrudViewsLayoutComponent } from './crud-views-layout.component';



@NgModule({
  declarations: [CrudViewsLayoutComponent],
  imports: [
    CommonModule
  ],
  exports: [CrudViewsLayoutComponent]
})
export class CrudViewsLayoutModule { }
