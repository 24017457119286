// https://console.cloud.google.com/apis/library/${apiService}?project=qa-by-bravo-4
export const apisUrl = {

    'GCompute' : `https://console.cloud.google.com/apis/library/compute.googleapis.com?project=`,

    'GBilling': `https://console.cloud.google.com/apis/library/cloudbilling.googleapis.com?project=`,

    'GDeployment': `https://console.cloud.google.com/apis/library/deploymentmanager.googleapis.com?project=`,

    'GBigquery': `https://console.cloud.google.com/apis/library/bigquery.googleapis.com?project=`,

    'GManageAd': `https://console.cloud.google.com/apis/library/managedidentities.googleapis.com?project=`,

    'GSqlAdmin': `https://console.cloud.google.com/apis/library/sqladmin.googleapis.com?project=`,

    'GNetworking': `https://console.cloud.google.com/apis/library/servicenetworking.googleapis.com?project=`,

    'GNetApp': `https://console.cloud.google.com/apis/library/cloudvolumesgcp-api.netapp.com?project=`,

    'GOsConfig': `https://console.cloud.google.com/apis/library/osconfig.googleapis.com?project=`,

    'GContainerAnalysis': `https://console.cloud.google.com/apis/library/containeranalysis.googleapis.com?project=`
  }
