export const locale = {
  lang: 'en',
  proservices : {
    PROSERVICES: `Pro Services`,
    PLACEHOLDER: `Description of services required`,
    REQUEST: `Request`,
    REQUESTING: `Requesting...`,
    CANCEL: `Cancel`,
    SUCCESS: `Pro Services request sended correctly`,
    ERROR_REQUIRED: `This field is required`,
    TITLE: `Request professional services`,
    NAME: `First name`,
    LASTNAME: `Last name`,
    EMAIL: `Email`,
    PHONE: `Contact phone number`,
    HOURS: `Hours`,
    ERROR_EMAIL: `This field should be an e-mail address in the format "user@example.com"`,
    HOURSVALUES: {
      1: `1 ($125)`,
      3: `3 ($336)`,
      5: `5 ($500)`,
    },
    ERROR_PATTERN: `The value in this field is invalid`,
    ACCESSTO: `You can access to`,
    PROSERVICESCONDITIONS: `Professional Services Terms and Conditions`,
    HERE: `Here`,
    PHONE_HELPER: 'Enter phone number',
  }
};
