import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-views-layout',
  templateUrl: './views-layout.component.html',
  styleUrls: ['./views-layout.component.scss']
})
export class ViewsLayoutComponent implements OnInit {
@Input()
public viewTitle: string;
@Input()
public subTitle: string;
@Input()
public feature: string;

constructor() { }

public ngOnInit() {
  }

}
