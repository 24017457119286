import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { AutosizeDirective } from './form/autosize.directive';
import { CopyCellDirective } from './common/copy-cell.directive';
import { ExcludeSpecialCharactersDirective } from './form/exclude-special-characters.directive';
import { InputFocusDirective } from './form/input-focus.directive';
import { BetaTagDirective } from './security/beta-tag.directive';
import { SecurityControlDirective } from './security/security-control.directive';
import { AdminsStatusDirective } from './status-render/admins-status.directive';
import { AppStatusDirective } from './status-render/app-status.directive';
import { CloudInstancesStatusDirective } from './status-render/cloud-instances-status.directive';
import { CollectionStatusDirective } from './status-render/collection-status.directive';
import { DeploymentStatusDirective } from './status-render/deployment-status.directive';
import { GroupsStatusDirective } from './status-render/groups-status.directive';
import { ImagesStatusDirective } from './status-render/images-status.directive';
import { TasksStatusDirective } from './status-render/tasks-status.directive';
import { UptimeStatusDirective } from './status-render/uptime-status.directive';
import { UserStatusDirective } from './status-render/user-status.directive';
import { VolumeStatusDirective } from './status-render/volume-status.directive';
import { VpnTunnelsStatusDirective } from './status-render/vpn-tunnels-status.directive';
import { VpnsStatusDirective } from './status-render/vpns-status.directive';
@NgModule({
  imports: [FormsModule, CommonModule, TranslateModule],
  declarations: [SecurityControlDirective, InputFocusDirective, BetaTagDirective, UserStatusDirective, AppStatusDirective,
                 VolumeStatusDirective, GroupsStatusDirective, CollectionStatusDirective, CloudInstancesStatusDirective,
                 UptimeStatusDirective, VpnsStatusDirective, VpnTunnelsStatusDirective, ImagesStatusDirective, TasksStatusDirective,
      AdminsStatusDirective, DeploymentStatusDirective, ExcludeSpecialCharactersDirective, AutosizeDirective, CopyCellDirective],
  exports: [SecurityControlDirective, InputFocusDirective, BetaTagDirective, UserStatusDirective, AppStatusDirective,
            GroupsStatusDirective, CollectionStatusDirective, CloudInstancesStatusDirective, UptimeStatusDirective,
            VpnsStatusDirective, VpnTunnelsStatusDirective, ImagesStatusDirective, TasksStatusDirective, AdminsStatusDirective,
      VolumeStatusDirective, ExcludeSpecialCharactersDirective, DeploymentStatusDirective, AutosizeDirective, CopyCellDirective]
})
export class DirectivesModule {}
