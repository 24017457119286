import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'underscore';

export const subNetAllValidator = function (control: AbstractControl): ValidationErrors | null {
const value: string = control.value || '';
if (!value) {
    return null;
  }
const reg = new RegExp(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/2[8-8]+$/);
if (reg.test(value) === false) {
    return {
      pattern: 'Invalid format'
    };
}
};
