import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { locale as english } from './i18n/section-layout.en';
import { locale as spanish } from './i18n/section-layout.es';

@Component({
  selector: 'app-section-layout',
  templateUrl: './section-layout.component.html',
  styleUrls: ['./section-layout.component.scss']
})
export class SectionLayoutComponent implements OnInit {
  @Input()
  public sectionTitle: string;
  @Input()
  public sectionSubtitle: string;
  @Input()
  public feature: string;
  @Input()
  public showAsSubsection: boolean;
  @Input()
  public showToolbar: boolean;
  @Input()
  public showToogle: boolean;
  @Input()
  public refreshSection: boolean;
  @Input()
  public disableRefreshBtn: boolean;
  @Input()
  public changeMarginTop: boolean;

  @Output()
  public refresh =  new EventEmitter<boolean>();

  public translation: object;

  constructor(private translate: TranslateService) { }

  public ngOnInit( ) {
    this.setTranslations();
  }

  public refreshAction(): void {
    if (this.refreshSection) {
      this.refresh.emit(true);
    }
  }

  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
    this.translation = {
      refreshText :  this.translate.instant('section_layout.REFRESH')
    };
  }
}
