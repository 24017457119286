import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';

import { AuthGuard } from 'src/app/core/security/auth.guard';
import { Subscription, User } from 'src/app/models';
import {ModalService} from '../core/shared/services/modal.service';
import { AutoUnsubscribeComponent } from '../shared/auto-unsubscribe/auto-unsubscribe.component';
import { WarningModalComponent } from '../shared/warning-modal/warning-modal.component';
import { SubscriptionManageService } from '../subscription/subscription-manage/subscription-manage.service';

import { locale as english } from './i18n/subscription-canceled.en';
import { locale as spanish } from './i18n/subscription-canceled.es';

const mapLangDateFormat = { en: 'MM/DD/YYYY', es: 'DD/MM/YYYY' };
const modalOpts = { size: 'md', backdrop: 'static', keyboard: false, centered: true };
const googleUrl = 'https://console.cloud.google.com/marketplace/details/itopia-public/itopia-cloud-virtual-desktop-and-app-management';

@Component({
  selector: 'app-subscription-canceled',
  templateUrl: './subscription-canceled.component.html',
  styleUrls: ['./subscription-canceled.component.scss']
})
export class SubscriptionCanceledComponent extends AutoUnsubscribeComponent implements OnInit {
  public title: string;
  public messageBody: string;
  public reactivateBtnLabel: string;
  public isReactivating: boolean;
  public canReactivate: boolean;
  public isCanceled: boolean;

  private subscription: Subscription;
  private currentUser: User;
  private subscriptionPrice: number;

  constructor(private translate: TranslateService, private authGuard: AuthGuard, private modalService: ModalService,
              private subscriptionService: SubscriptionManageService, private router: Router, private currencyPipe: CurrencyPipe) {
    super();
    this.subscription = JSON.parse(sessionStorage.getItem('subscription'));
    if (this.subscription.status.toLowerCase() !== 'canceled') {
      this.router.navigate(['/dashboard']);
    } else {
      this.isCanceled = true;
    }
  }

  public ngOnInit() {
    try {
      const user = JSON.parse(sessionStorage.getItem('currentUser'));
      this.currentUser = user.current;
      if (this.subscription.billingProviderCode !== 'GOO') {
        this.getSubscriptionComponents();
      }
    } catch (error) {
      this.currentUser = null;
    }
    this.canReactivate = this.authGuard.securityCheck('subscription', 'reactivate');
    this.setTranslations();
  }

  public onClickReactivate(): void {
    if (this.subscription.billingProviderCode === 'GOO') {
      window.open(googleUrl, '_blank');
    } else { // chargify
      const reactivateSubscriptionModal = this.modalService.open(WarningModalComponent, modalOpts as NgbModalOptions);
      const modalInstance = reactivateSubscriptionModal.componentInstance;
      this.setModalConfigs(modalInstance);

      const reactivateConfirmationSubs = modalInstance.onActionConfirmation.subscribe(() => {
        this.reactivateSubscription(modalInstance);
      });
      super.addSubscriptions(reactivateConfirmationSubs);
    }
  }

  private getSubscriptionComponents(): void {
    const getComponentsSubs = this.subscriptionService.getComponents(this.currentUser.organizationId)
      .subscribe(components => {
        this.subscriptionPrice = this.subscriptionService.calculateTotalPrice(this.subscription, components.list);
      });
    super.addSubscriptions(getComponentsSubs);
  }

  private setModalConfigs(modalInstance): void {
    this.setTranslations();
    modalInstance.bodyMessage = this.translate.instant('subscription_canceled.WHAT_HAPPEN');
    modalInstance.isInfo = true;
    modalInstance.htmlTemplate = this.buildModalBodyHtml();
    modalInstance.actionButtons = [
      this.translate.instant('subscription_canceled.REACTIVATE'),
      this.translate.instant('subscription_canceled.REACTIVATING'),
    ];
  }

  private reactivateSubscription(modalInstance): void {
    const cancelSubs = this.subscriptionService.reactivateSubscription(this.currentUser.organizationId)
      .pipe(finalize(() => {
        modalInstance.canCloseModal$.next(true);
      }))
      .subscribe(activatedSubscription => {
        sessionStorage.setItem('subscription', JSON.stringify(activatedSubscription));
        this.router.navigate(['/subscription/manage']);
      });
    super.addSubscriptions(cancelSubs);
  }

  private buildModalBodyHtml(): string {
    const li1 = this.translate.instant('subscription_canceled.REACTIVATE_SUBSCRIPTION_MSG1');
    const li2Params = { name: this.subscription.productName, price: this.currencyPipe.transform(this.subscriptionPrice) };
    const li2 = this.translate.instant('subscription_canceled.REACTIVATE_SUBSCRIPTION_MSG2', li2Params);
    const li3 = this.translate.instant('subscription_canceled.REACTIVATE_SUBSCRIPTION_MSG3');
    return `<ul class="pt-2">
      <li class="m-t-5 text-dark">${li1}</li>
      <li class="m-t-5 text-dark">${li2}</li>
      <li class="m-t-5 text-dark">${li3}</li>
    </ul>`;
  }

  private setTranslations(): void {
    let browserLang = this.translate.getBrowserLang();
    browserLang = browserLang !== 'es' ? 'en' : 'es';
    const mapLangI18nFile = { es: spanish, en: english };
    this.translate.use(browserLang);
    this.translate.setTranslation(browserLang, mapLangI18nFile[browserLang]);

    const cancelationDate = moment(this.subscription.canceledAt).format(mapLangDateFormat[browserLang]);
    this.title = this.translate.instant('subscription_canceled.SUBSCRIPTION_CANCELED_TITLE', { user: this.currentUser.firstName });
    this.messageBody = this.translate.instant('subscription_canceled.SUBSCRIPTION_INVALID', { cancelDate: cancelationDate });
    this.reactivateBtnLabel = this.translate.instant('subscription_canceled.REACTIVATE');
    if (!this.canReactivate) {
      this.messageBody = this.translate.instant('subscription_canceled.SUBSCRIPTION_INVALID_VIEWER', { cancelDate: cancelationDate });
    }
  }

}
