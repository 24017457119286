export const locale = {
  lang: 'en',
  multiselect : {
    NO_DATA: 'No data to display',
    TOTAL: 'total',
    SELECTED: 'selected',
    SHOWING: 'showing',
    SEARCH_BY_NAME: 'Search by name',
    SEARCH_BY_LOCATION: 'Search by location',
    RESET_FILTERS: 'Reset filters'
  }
};
