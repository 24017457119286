import { GpuRestrictions } from './gpu-restrictions';

export class GpuConfig {
    public id: number;
    public gpu: number;
    public memory: number;
    public restrictions: GpuRestrictions[];

    constructor(data?: any) {
        this.id = data && data.id ? data.id : 0;
        this.gpu = data && data.gpu ? data.gpu : 0;
        this.memory = data && data.memory ? data.memory : 0;
        this.restrictions = data && data.restrictions ? data.restrictions : [];
    }
}