export const locale = {
  lang: 'en',
  feedback : {
    'FEEDBACK': `Feedback`,
    'PLACEHOLDER': 'Describe your ideas',
    'SEND': 'Send',
    'SENDING': 'Sending...',
    'CANCEL': 'Cancel',
    'SUCCESS': 'Feedback received',
    'ERROR_REQUIRED': 'This field is required'
  }
};
