import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DirectivesModule } from 'src/app/core/shared/directives/directives.module';

import { ModalLayoutComponent } from './modal-layout.component';



@NgModule({
  declarations: [ModalLayoutComponent],
  imports: [
    CommonModule, FlexLayoutModule, DirectivesModule
  ],
  exports: [ ModalLayoutComponent ]
})
export class ModalLayoutModule { }
