import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscriber } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { EarlyAccessService } from 'src/app/core/security/early-access.service';
import { BinaryExpressionService, OperationExpressionService, UnaryExpressionService } from 'src/app/core/shared/filters';
import { DeploymentsService } from 'src/app/deployments/deployments.service';
import { AdUserFilter, Datatable, EarlyAccessFeature, ListDto, SecurityAdGroup } from 'src/app/models';
import { AdFiltersService } from 'src/app/shared/ad-filters/ad-filters.service';
import { AutoUnsubscribeComponent } from '../../auto-unsubscribe/auto-unsubscribe.component';
import { MultiselectComponent } from '../../multiselect/multiselect.component';
import { MultiselectGroupsAdActionService } from './multiselect-groups-ad-action.service';

import { locale as english } from './i18n/multiselect-groups-ad.en';
import { locale as spanish } from './i18n/multiselect-groups-ad.es';

const pageSizeValue = 10;
@Component({
  selector: 'app-multiselect-groups-ad',
  templateUrl: './multiselect-groups-ad.component.html',
  styleUrls: ['./multiselect-groups-ad.component.scss']
})
export class MultiselectAdGroupsComponent extends AutoUnsubscribeComponent implements OnInit, AfterViewInit {
  @Input()
  public deploymentId: number;
  @Input()
  public containerClass: string;
  @Input()
  public currentGroupIds: Array<number>;

  @Output()
  public selecteddGroups = new EventEmitter<Array<SecurityAdGroup>>();
  @Output()
  public isLoadingCloudGroups = new EventEmitter<boolean>();

  public groupsTitle: string;
  public isLoadingGroups: boolean;
  public selectedGroups: Array<number>;
  public selectModelGroups: Datatable<SecurityAdGroup> = new Datatable<SecurityAdGroup>();
  public adFilter: AdUserFilter;
  public isAdGroupsFiltersAccess: boolean;

  @ViewChild('multiselectGroup', {static: false})
  public multiselectGroup: MultiselectComponent;

  constructor(private deploymentsService: DeploymentsService, private translate: TranslateService,
              private operationExpression: OperationExpressionService, private unaryOperation: UnaryExpressionService,
              private binaryExpression: BinaryExpressionService, private groupsAdActionService: MultiselectGroupsAdActionService,
              private adFiltersService: AdFiltersService, private earlyAccessService: EarlyAccessService) {
                super();
                this.setCurrentLang();
  }

  public ngOnInit() {
    this.containerClass = this.containerClass ? this.containerClass : '';
    this.groupsAdActionService.setAdFilter(null);
    this.groupsAdActionService.adFilterUpdated$.observers = [];
    this.isAdGroupsFiltersAccess = this.earlyAccessService.verifyAccess(EarlyAccessFeature.AdGroupsFilters);
    this.setTranslations();
  }
  public ngAfterViewInit() {
    this.groupsAdActionService.adFilterUpdated$.subscribe((value) => {
      if (value && !value['noSearch'] && this.multiselectGroup) {
        this.adFilter = value;
        this.multiselectGroup.updateFilter();
        this.multiselectGroup.selectModel.CurrentPageNumber = 0;
        if (!this.multiselectGroup.filterItems.observers.length) {
          this.multiselectGroup.filterItems.observers.push(
            new Subscriber(() => this.getGroups(this.multiselectGroup.selectModel)));
        }
        this.multiselectGroup.filterItems.emit(this.multiselectGroup.selectModel);
      } else if (value && value['noSearch']) {
        this.adFilter = value;
        this.multiselectGroup.rows = [];
      }
    });
    this.setTranslations();
  }
  public selectGroup($event: Array<SecurityAdGroup>): void {
    const groupsTemp = [];
    $event.forEach((group: SecurityAdGroup) => {
      groupsTemp.push(group.guid);
    });
    this.selectedGroups = groupsTemp;
    this.selecteddGroups.emit($event);
  }
  public getGroups($event): void {
    if (!this.isAdGroupsFiltersAccess) {
      this.isLoadingGroups = true;
    }
    this.isLoadingCloudGroups.emit(true);
    this.adFiltersService.setDisabledSearch(true);

    const params = {
      trustedDomainId: this.adFilter.trustedDomainId,
      adGroup: this.adFilter && this.adFilter.memberOfGroup ? this.adFilter.memberOfGroup : '',
      adFilter: this.adFilter && this.adFilter.customLdapFilter ? this.adFilter.customLdapFilter : '',
      adUsersOu: this.adFilter && this.adFilter.organizationUnits ? this.adFilter.organizationUnits : '',
      pageIndex: $event.CurrentPageNumber + 1 || 1,
      pageSize: pageSizeValue,
      filter: []
    };

    if (this.adFilter && this.adFilter.isTrustedDomain !== null && this.adFilter.isTrustedDomain !== undefined) {
      params['trustedDomain'] = this.adFilter.isTrustedDomain.toString();
    }

    if ($event.searchValue) {
      params.filter.push({
        'propertyName': 'Name', 'operation': this.operationExpression.expressionType.CONTAINS, 'value': $event.searchValue,
        'toLower': true, 'unaryOperation': this.unaryOperation.expressionType.NONE,
        'binaryOperation': this.binaryExpression.expressionType.NONE
      });
    }
    const getGroupsSubs = this.deploymentsService.searchDeploymentAdGroupsFilter(this.deploymentId, params)
      .pipe(finalize(() => {
        if (!this.isAdGroupsFiltersAccess) {
          this.isLoadingGroups = false;
        }
        this.isLoadingCloudGroups.emit(false);
        this.adFiltersService.setDisabledSearch(false);
      }))
      .subscribe((groups: ListDto<SecurityAdGroup>) => {
        const mapGroupsAllowProp = groups.list.map(group => {
          if (this.currentGroupIds && this.currentGroupIds.includes(group.securityGroupId) ||
          this.selectedGroups && this.selectedGroups.includes(group.securityGroupId)) {
            group.allow = true;
          }
          return group;
        });
        this.selectModelGroups = {
          PageSize: pageSizeValue,
          TotalElements: groups.count,
          TotalPages: Math.ceil(groups.count / pageSizeValue),
          CurrentPageNumber: $event.CurrentPageNumber + 1,
          SortBy: 'name',
          SortDir: 'asc',
          Data: mapGroupsAllowProp
        };
        if (!this.multiselectGroup._selectModelInput.observers.length) {
          this.multiselectGroup._selectModelInput.observers.push(
            new Subscriber(() => this.multiselectGroup.observerSelectModelInput(this.selectModelGroups)));
          this.multiselectGroup._selectModelInput.next(this.selectModelGroups);
        }
      }, () => {
        this.selectModelGroups = {
          PageSize: 0,
          TotalElements: 0,
          TotalPages: 0,
          CurrentPageNumber: $event.CurrentPageNumber + 1,
          SortBy: 'name',
          SortDir: 'asc',
          Data: []
        };
      });
    super.addSubscriptions(getGroupsSubs);
  }
  public setCurrentLang(): void {
    const browserLang = this.translate.getBrowserLang();
    const mapLangI18nFile = { es: spanish, en: english };
    if (!mapLangI18nFile[browserLang]) {
      this.translate.use('en');
      this.translate.setTranslation('en', mapLangI18nFile['en']);
    } else {
      this.translate.use(browserLang);
      this.translate.setTranslation(browserLang, mapLangI18nFile[browserLang]);
    }
  }

  private setTranslations(): void {
    this.setCurrentLang();
    this.groupsTitle = this.translate.instant('multiselect_groups.USERS_GROUPS');
  }

}
