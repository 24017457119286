export class LicenseDto {
  public licenseID: number;
  public applicationLicenseFile: string;
  public mimeType: string;
  public filename: string;
  constructor( data?: any) {
    this.licenseID = data && data.licenseID ? data.licenseID  : 0;
    this.applicationLicenseFile = data && data.applicationLicenseFile ? data.applicationLicenseFile  : '';
    this.mimeType = data && data.mimeType ? data.mimeType  : '';
    this.filename = data && data.filename ? data.filename  : '';
  }
}
