import { Region } from './region';
import { ScaleRange } from './scale-range';
export class Scale {
    public scaleId: number;
    public companyId: number;
    public deploymentId: number;
    public usersPerServer: number;
    public status: number;
    public diskSize: number;
    public collectionTypeId: number;
    public collectionPoolTypeId: number;
    public active: boolean;
    public default: boolean;
    public applyAllUss: boolean;
    public operatingSystem: string;
    public collectionAlias: string;
    public description: string;
    public diskType: string;
    public collectionRegionModel: Region[];
    public ranges: ScaleRange[];
    public schedule: any;
    public settings: any;
    public override: any;
    public labels: {};
    public workload: string;
    public collectionPoolTypeCode: string;
    public collectionTypeCode: string;
    public machineType: any;
    public graphicCardId: number;
    public collectionStatusId: number;
    public unassignedHost?: number;
    public collectionName?: string;
    public regionId: number;
    public sizingRatioActive: boolean;
    public imageIsValid: boolean;
    // tslint:disable-next-line:cyclomatic-complexity
    constructor(data ? : any) {
      const tempRanges = [];
      if (data && data.ranges) {
          data.ranges.forEach((range) => tempRanges.push(new ScaleRange(range)));
        }
      this.scaleId = data && data.scaleId ? data.scaleId : 0;
      this.companyId = data && data.companyId ? data.companyId : 0;
      this.deploymentId = data && data.deploymentId ? data.deploymentId : 0;
      this.usersPerServer = data && data.usersPerServer ? data.usersPerServer : 0;
      this.status = data && data.status ? data.status : 0;
      this.diskSize = data && data.diskSize ? data.diskSize : 0;
      this.collectionTypeId = data && data.collectionTypeId ? data.collectionTypeId : 0;
      this.collectionPoolTypeId = data && data.collectionPoolTypeId ? data.collectionPoolTypeId : 0;
      this.active = data && data.active ? data.active : true;
      this.default = data && data.default ? data.default : false;
      this.applyAllUss = data && data.applyAllUss ? data.applyAllUss : false;
      this.operatingSystem = data && data.operatingSystem ? data.operatingSystem : '';
      this.collectionAlias = data && data.collectionAlias ? data.collectionAlias : '';
      this.description = data && data.description ? data.description : '';
      this.diskType = data && data.diskType ? data.diskType : '';
      this.schedule = data && data.schedule ? data.schedule : null;
      this.collectionRegionModel = data && data.collectionRegionModel ? data.collectionRegionModel : [];
      this.ranges = data && data.collectionRegionModel ?  tempRanges.length > 0 ? tempRanges : data.range : [];
      this.settings = data && data.settings ? data.settings : null;
      this.override = data && data.override ? data.override : null;
      this.labels = data && data.labels ? data.labels : {};
      this.machineType = data && data.machineType ? data.machineType : {};
      this.workload = data && data.workload ? data.workload : '';
      this.collectionPoolTypeCode = data && data.collectionPoolTypeCode ? data.collectionPoolTypeCode : '';
      this.collectionTypeCode = data && data.collectionTypeCode ? data.collectionTypeCode : '';
      this.graphicCardId = data && data.graphicCardId ? data.graphicCardId : 0;
      this.collectionStatusId = data && data.collectionStatusId ? data.collectionStatusId : 0;
      this.unassignedHost = data && data.unassignedHost ? data.unassignedHost : null;
      this.collectionName = data && data.collectionName ? data.collectionName : '';
      this.regionId = data && data.regionId ? data.regionId : 0;
      this.sizingRatioActive = data && data.sizingRatioActive ? data.sizingRatioActive : false;
      this.imageIsValid = data && data.imageIsValid !== null && data.imageIsValid !== undefined ? data.imageIsValid : true;
    }
}
