import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';

import { AuthenticationService } from '../../core/security/authentication.service';
import { AutoUnsubscribeComponent } from '../../shared/auto-unsubscribe/auto-unsubscribe.component';
import { ProServicesService } from './pro-services.service';
import { ProServicesHours } from '../../models/support/pro-services-hours';
import { ProServices } from '../../models/support/pro-services';

import { locale as english } from './i18n/pro-services.en';
import { locale as spanish } from './i18n/pro-services.es';

@Component({
  selector: 'app-pro-services',
  templateUrl: './pro-services.component.html',
  styleUrls: ['./pro-services.component.scss']
})

export class ProServicesComponent extends AutoUnsubscribeComponent implements OnInit {
  public proServicesSuccess: string;
  public proServicesForm: FormGroup;
  public loading: boolean;
  public validationErrors = '';
  public submitted: boolean;
  public modalReference: NgbModalRef;
  public closeResult: string;
  public notification: { type: string; message: string; };
  public actionValue: string;
  public actionsButtons: Array<string>;
  public formButtonsComponent: any;
  public validationType: object;
  public disableForm: boolean;
  public actionPressed: boolean;
  //Errors
  public errorRequired: string;
  public errorEmail: string;
  public errorPattern: string;
  //Labels
  public proServicesTooltip: string;
  public proServicesTitle: string;
  public proServicesPlace: string;
  public proServicesName: string;
  public proServicesLastName: string;
  public proServicesEmail: string;
  public proServicesPhone: string;
  public proServicesHours: string;
  public proServicesDescriptions: string;
  public proServicesAccessTo: string;
  public proServicesConditions: string;
  public here: string;
  public phoneHelpLabel: string | any;

  public hours: Array<ProServicesHours>;
  public currentUser: any;
  public proServicesUrl : string;

  constructor(private translate: TranslateService,
              private modalService: NgbModal,
              private formBuilder: FormBuilder,
              private authenticationService: AuthenticationService,
              private notifierService: NotifierService,
              private proServicesService: ProServicesService) {
      super();
  }
  public ngOnInit() {
    this.setTranslation();
    this.submitted = false;
    this.loading = false;

    this.proServicesForm = this.formBuilder.group({
      Name: [{ value: '', disabled: false }],
      LastName: [{ value: '', disabled: false }],
      Email: [{ value: '', disabled: false }],
      Phone: [{ value: '', disabled: false }],
      Hours: [{ value: '', disabled: false }],
      Description: [{ value: '', disabled: false }]
  });

    this.validationType = {
    'Name': [Validators.required, Validators.pattern('^([a-zA-Z0-9-_])+$')],
    'LastName': [Validators.required, Validators.pattern('^([a-zA-Z0-9-_])+$')],
    'Email': [Validators.required, Validators.pattern('^(\\S)+@([a-zA-Z0-9])+([\\.-]?([a-zA-Z0-9])+)*(\\.([a-zA-Z0-9]){2,15})+$')],
    'Description': [Validators.required],
    'Hours': [Validators.required]
};
    const currentUserSubs = this.authenticationService.currentUser.subscribe((currenUser: any) => {
      if (currenUser && currenUser.current) {
          this.f['Name'].setValue(currenUser.current.firstName);
          this.f['LastName'].setValue(currenUser.current.lastName);
          this.f['Email'].setValue(currenUser.current.email);
          this.f['Phone'].setValue(currenUser.current.personalPhoneNumber);
      }
    });
    super.addSubscriptions(currentUserSubs);
    this.proServicesUrl = "http://helpcenter.itopia.com/manage/professional-services";

  }
  get f() { return this.proServicesForm.controls; }


  public createProServices(): void {
    this.actionPressed = true;
    const proService = new ProServices();
    proService.firstName = this.f.Name.value;
    proService.lastName =  this.f.LastName.value;
    proService.email = this.f.Email.value;
    proService.hours = this.f.Hours.value.id;
    proService.description = this.f.Description.value;
    proService.phone = this.f.Phone.value
    const proServicetSubs = this.proServicesService.sendProServicesRequest(proService).subscribe(
        () => {
            this.notifierService.notify('success', this.proServicesSuccess);
            this.closeProServicesModal();
        },
        error => {
            this.validationErrors = error.error.errors;
            Object.keys(this.validationErrors).forEach(prop => {
                this.proServicesForm.get(prop).setErrors({ serverError: this.validationErrors[prop] });
            });
            this.actionPressed = false;
        });
    super.addSubscriptions(proServicetSubs);
}

  public showProServicesModal(content): void {
    this.ngOnInit();
    const ngbModalOptions: NgbModalOptions = { size: 'xl', backdrop: 'static', keyboard: false };
    this.modalReference = this.modalService.open(content, ngbModalOptions);
  }

  public closeProServicesModal() {
    this.proServicesForm.reset();
    this.loading = false;
    this.submitted = false;
    this.modalReference.close();
    this.actionPressed = false;
    this.modalService.dismissAll();
  }

  public showNotification(notification: { type: string; message: string; }) {
    this.notifierService.notify(notification.type, notification.message);
  }

  public onActionConfirmation(confirm: boolean): void {
    if (confirm) {
        const invalid = this.setValidations('');
        if (!invalid) {
            this.createProServices();
        }
    } else {
        this.closeProServicesModal();
    }
}
  public setValidations(input: string): boolean {
    this.disableForm = this.proServicesForm.invalid;
    Object.keys(this.f).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(this.f, key)) {
        if (key === input) {
            this.f[key].setValidators(this.validationType[key]);
            this.f[key].updateValueAndValidity();
        } else if (!input) {
            this.f[key].setValidators(this.validationType[key]);
            this.f[key].updateValueAndValidity();
        }
    }
    });
    return this.disableForm = this.proServicesForm.invalid;
  }

  public isEmptyData(): boolean {
    return !this.f.Name.value || !this.f.LastName.value || !this.f.Email.value || !this.f.Description.value || !this.f.Hours.value;
    }

  public onTouched(formKey: string): void {
    this.f[formKey].markAsTouched();
  }

  private setTranslation(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
    //Labels
    this.proServicesTitle =  this.translate.instant('proservices.TITLE');
    this.proServicesName =  this.translate.instant('proservices.NAME');
    this.proServicesLastName =  this.translate.instant('proservices.LASTNAME');
    this.proServicesEmail =  this.translate.instant('proservices.EMAIL');
    this.proServicesPhone =  this.translate.instant('proservices.PHONE');
    this.proServicesHours =  this.translate.instant('proservices.HOURS');
    this.proServicesDescriptions =  this.translate.instant('proservices.PLACEHOLDER');
    this.proServicesAccessTo =  this.translate.instant('proservices.ACCESSTO');
    this.proServicesConditions =  this.translate.instant('proservices.PROSERVICESCONDITIONS');
    this.here =  this.translate.instant('proservices.HERE');
    this.phoneHelpLabel = this.translate.instant('proservices.PHONE_HELPER');
    //Msgs
    this.proServicesTooltip = this.translate.instant('proservices.PROSERVICES');
    this.proServicesPlace = this.translate.instant('proservices.PLACEHOLDER');
    this.proServicesSuccess = this.translate.instant('proservices.SUCCESS');
    //Errors
    this.errorRequired = this.translate.instant('proservices.ERROR_REQUIRED');
    this.errorEmail = this.translate.instant('proservices.ERROR_EMAIL');
    this.errorPattern = this.translate.instant('proservices.ERROR_PATTERN');
    //Buttons
    this.actionsButtons = [this.translate.instant('proservices.CANCEL'),
                          this.translate.instant('proservices.REQUEST'), this.translate.instant('proservices.REQUESTING')];
    //Data
    this.hours = [{ name: this.translate.instant('proservices.HOURSVALUES.1'), id: 1 },
                  { name: this.translate.instant('proservices.HOURSVALUES.3'), id: 3 },
                  { name: this.translate.instant('proservices.HOURSVALUES.5'), id: 5 }];

  }
}
