const start = 0;
const limit = 20;

export class TrustedAd {
    public trustedDomainId: number | null;
    public domain: string;
    public preferredDomain: string;
    public usersOU: string;
    public groupsOU: string;
    public userName: string;
    public password: string;
    public deploymentId: number;
    public active: boolean;
    public domainType: string;
    public step: number;
    constructor(data?: any) {
      this.trustedDomainId =  data && data.trustedDomainId ? data.trustedDomainId : null;
      this.domain =  data && data.domain ? data.domain : '';
      this.preferredDomain =  data &&  data.preferredDomain ? data.preferredDomain : null;
      this.usersOU =  data &&  data.usersOU ? data.usersOU : '';
      this.groupsOU =  data &&  data.groupsOU ? data.groupsOU : '';
      this.userName =  data &&  data.userName ? data.userName : '';
      this.password =  data &&  data.password ? (data.password).substring(start, limit) : null;
      this.deploymentId =  data &&  data.deploymentId ? data.deploymentId : 0;
      this.step =  data &&  data.step ? data.step : 0;
      this.active =  data &&  data.active ? data.active : false;
      this.domainType = data && data.domainType ? data.domainType : '';
    }
}
