import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DirectivesModule } from 'src/app/core/shared/directives/directives.module';
import { ViewsLayoutComponent } from './views-layout.component';
@NgModule({
  declarations: [ViewsLayoutComponent],
  imports: [
    CommonModule, DirectivesModule
  ],
  exports: [ViewsLayoutComponent]
})
export class ViewsLayoutModule { }
