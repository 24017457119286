export const locale = {
    lang: 'en',
    support : {
        SUPPORT: 'Support',
        CREATE_TITLE: 'Create ticket',
        REQUESTER: 'Requester',
        SUBJECT: 'Subject',
        PRIORITY: 'Priority',
        CCS: 'CCs',
        PLACEHOLDER: 'Email addresses',
        DESCRIPTION: 'Description',
        LOW: 'Low',
        NORMAL: 'Normal',
        HIGH: 'High',
        SAVE: 'Save',
        SAVING: 'Saving ...',
        CANCEL: 'Cancel',
        SUCCESS: 'Ticket successfully created',
        ERROR_REQUIRED: 'This field is required',
        ERROR_EMAIL: 'This field should be an e-mail address in the format "user@example.com"',
        MAXLENGTH: 'Maximum length {{val}}'
    }
};
