
export class ProductCost {
    public cost: number;
    public id: string;

    constructor(data?: any) {
        this.cost = data && data.cost ? data.cost : 0;
        this.id = data && data.id ? data.id : '';
    }
}
