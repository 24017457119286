import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import {UiSwitchModule} from 'ngx-toggle-switch';
import { FormButtonsModule } from './../form-buttons/form-buttons.module';

import { AutoUnsubscribeModule } from '../auto-unsubscribe/auto-unsubscribe.module';
import { WarningModalComponent } from './warning-modal.component';

@NgModule({
  imports: [CommonModule, NgbModule, RouterModule, TranslateModule, FormButtonsModule,
    AutoUnsubscribeModule, FormsModule, ReactiveFormsModule, UiSwitchModule],
    declarations: [WarningModalComponent],
  exports: [WarningModalComponent]
})
export class WarningModalModule {
}
