import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { finalize } from 'rxjs/operators';
import * as _ from 'underscore';
import { RegionStatusEnum } from '../enum';

import { externalDnsNameValidator } from 'src/app/core/shared/pipes/validator/externalDnsName.validator';
import { Deployment, ListDto, Region, SoltenantGroup } from 'src/app/models';
import { AutoUnsubscribeComponent } from '../../auto-unsubscribe/auto-unsubscribe.component';
import { RegionCertificateComponent } from '../region-certificate/region-certificate.component';
import { RegionsPostProvisionService } from '../service/regions-post-provision.service';
import { StatusRequest } from './enum/status-request';

import { locale as english } from '../regions-post-provision-list/i18n/regions-post-provision.en';
import { locale as spanish } from '../regions-post-provision-list/i18n/regions-post-provision.es';

const earlyAccessSoleTenant = 'sole_tenant';
const deploymentReady = 3;
@Component({
  selector: 'app-regions-post-provision-manage',
  templateUrl: './regions-post-provision-manage.component.html',
  styleUrls: ['./regions-post-provision-manage.component.scss']
})
export class RegionsPostProvisionManageComponent extends AutoUnsubscribeComponent implements OnInit, AfterViewInit {
  @Input()
  public showCertificate: boolean;
  @Input()
  public isSoltenantConfigDeployment: boolean;
  @Input()
  public deployment: Deployment;
  @Input()
  public region: Region;
  @Input()
  public isResolvedDNS: boolean;
  @Input()
  public regionStatus: RegionStatusEnum;

  @Output()
  public cancelEditEmitter = new EventEmitter<boolean>();
  @Output()
  public regionUpdatedEmitter = new EventEmitter<Region>();


  public regionForm: FormGroup;
  public deployRegionLabel: string;
  public errorRequired: string;
  public errorPattern: string;
  public isPrimaryLabel: string;
  public subnetLabel: string;
  public externalDnsLabel: string;
  public externalIpLabel: string;
  public currentToolTip: string;
  public selectRegionLabel: string;
  public tooltipCopy: string;
  public tooltipCopied: string;
  public actionsButtons: string[];
  public dnsFailed: string;
  public dnsVerified: string;
  public isSaving: boolean;
  public isFormModified: boolean;
  public isUpdatedDNS: boolean;
  public multiplesRequest: object;
  // sole tenant
  public soleTenantConfigurationText: string;
  public soleTenantDescription: string;
  public soletenantNotFoundMsg: string;
  public loadingSoleTenant: boolean;
  public earlyAccessSoleTenant: boolean;
  public soltenantGroups: SoltenantGroup[];
  public isSoleTenantNotFound: boolean;
  public soleTenantGroupText: string;
  // cert
  public isDeploymentReady: boolean;
  public isCertificateInvalid: boolean;
  public hasAllCertificateFields: boolean;
  public savingCertificate: boolean;
  public loadCertificateRegion: boolean;
  public isSavingRegion: boolean;
  public isUpdatingDns: boolean;
  public isInvalidExternalDns: boolean;
  public isPendingConf: boolean;

  private validationType: Object;
  private putDNSSent: boolean;
  private putSent: boolean;


  @ViewChild(RegionCertificateComponent, {static: false})
  private regionCertificateComponent: RegionCertificateComponent;

  constructor(private translate: TranslateService, private formBuilder: FormBuilder, private cdr: ChangeDetectorRef,
              private regionsPostProvisionSvc: RegionsPostProvisionService, private notifierService: NotifierService) {
    super();
  }

  public ngOnInit() {
    setTimeout(() => {
      this.setTranslations();
    });
    this.isSavingRegion = this.isUpdatingDns = false;
    this.isFormModified = this.isInvalidExternalDns = false;
    this.isPendingConf = this.regionStatus === RegionStatusEnum.PendingConfiguration;
    this.buildForm();
    this.verifyAccessSoltenant();
    this.isDeploymentReady = this.deployment.companiesStatusId === deploymentReady;
    if (this.isSoltenantConfigDeployment) {
      this.getSoltenantGroupByRegion();
    }
    if (!this.showCertificate) {
      this.getRegionById();
    }
    this.initializeMultiplesRequest();
  }

  // tslint:disable-next-line:no-empty
  public ngAfterViewInit(): void {
  }

  get f() {
    return this.regionForm ? this.regionForm.controls : null;
  }

  public initializeMultiplesRequest(): void {
    this.multiplesRequest = {
      region: StatusRequest.None,
      certificate: StatusRequest.None,
      externalDns: StatusRequest.None
    };
  }

  public setValidations(input: string = ''): void {
    // tslint:disable-next-line:forin
    if (input === 'ExternalDnsName') {
      this.isUpdatedDNS = _.clone(this.region.dnsRecord !== this.f.ExternalDnsName.value);
      this.buildValidationType(this.isUpdatedDNS);
    }
    for (const key in this.f) {
      if (key === input) {
        this.f[key].setValidators(this.validationType[key]);
        this.f[key].updateValueAndValidity();
        break;
      } else if (!input) {
        this.f[key].setValidators(this.validationType[key]);
        this.f[key].updateValueAndValidity();
      }
    }
    this.setFormTouchedStatus();
    this.isInvalidExternalDns = false;
  }

  public cancelRegion(forceCancel?: boolean): void {
    if (this.executeCancel() || forceCancel) {
      this.clearValidations();
      this.cancelEditEmitter.emit(true);
    }
  }

  public executeCancel(): boolean {
    let iterate = true;
    let executeValue = false;
    Object.keys(this.multiplesRequest).forEach(prop => {
      if (iterate) {
        executeValue = this.multiplesRequest[prop] === StatusRequest.None || this.multiplesRequest[prop] === StatusRequest.Success;
        iterate = executeValue;
      }
    });
    return executeValue;
  }

  public copyIp(popover: NgbPopover): void {
    if (popover.isOpen()) {
      popover.close();
    }
    this.currentToolTip = this.tooltipCopied;
    popover.open();
  }

  public changeTooltipText(popover: NgbPopover): void {
    if (popover.isOpen()) {
      popover.close();
    }
    if (this.currentToolTip !== this.tooltipCopy) {
      this.currentToolTip = this.tooltipCopy;
    }
  }

  public changeSoleTenant(): void {
    this.isSoleTenantNotFound = false;
    this.isFormModified = this.f['SoleTenantGroupSelect'].value !== this.region.soleTenantGroup;
  }

  // certificate handlers
  public setRegionById($event: Region): void {
    this.region.ipRecord = $event.ipRecord;
    this.hasAllCertificateFields = $event.hasCertificate;
    this.loadCertificateRegion = $event.hasCertificate;
    this.f['ExternalIpAddress'].patchValue($event.ipRecord);
  }

  public savingCertificateHandler(isSavingCert: boolean): void {
    this.isSaving = isSavingCert || this.isSaving;
    if (!isSavingCert) {
      this.multiplesRequest['certificate'] = StatusRequest.Success;
      this.notificationSuccess(true);
      this.cancelRegion();
    }
  }

  public invalidCertificateHandler(isInvalid: boolean): void {
    this.isCertificateInvalid = isInvalid;
    this.loadCertificateRegion = false;
  }

  public hasPassword(hasPassword: boolean): void {
    this.hasAllCertificateFields = hasPassword;
    this.isFormModified = hasPassword;
  }

  // submit fns
  public onActionConfirmation(isSubmitAction: boolean): void {
    if (isSubmitAction) {
      this.saveRegion();
    } else {
      this.initializeMultiplesRequest();
      this.cancelRegion();
    }
  }

  private saveRegion() {
    this.setValidations();
    if (this.regionCertificateComponent && this.regionCertificateComponent.showPasswordField) {
      this.regionCertificateComponent.setValidations(undefined);
      this.isCertificateInvalid = this.regionCertificateComponent.certificateForm.invalid;
      this.isUpdatedDNS = this.regionCertificateComponent.isUpdatedDNS;
    }
    if (this.regionForm.invalid || this.isCertificateInvalid) {
      this.isSaving = false;
      this.cdr.detectChanges();
      return;
    }
    const soltenantGroup = this.regionForm.get('SoleTenantGroupSelect').value;
    const formValues = {
      dnsRecord: this.f['ExternalDnsName'].value,
    };
    if (soltenantGroup) {
      formValues['soleTenantGroup'] = new SoltenantGroup(soltenantGroup);
    }
    const regionToSave = Object.assign({}, this.f['Region'].value, formValues);
    this.editRegionAction(regionToSave);
  }

  private editRegionAction(regionToSave: Region): void {
    this.isSaving = true;
    this.savingCertificate = false;
    if (!this.region.soleTenantGroup && regionToSave.soleTenantGroup || regionToSave.soleTenantGroup && this.region.soleTenantGroup &&
      this.region.soleTenantGroup.name !== regionToSave.soleTenantGroup.name) {
      this.putSaveRegion(regionToSave);
    }
    if (this.region.dnsRecord !== regionToSave.dnsRecord) {
      this.updateExternalDnsRegion(regionToSave);
    } else {
      this.isUpdatedDNS = true;
    }
    if (this.isDeploymentReady && this.showCertificate && this.hasAllCertificateFields
      && !this.loadCertificateRegion && this.isUpdatedDNS) {
      this.savingCertificate = true;
      this.multiplesRequest['certificate'] = StatusRequest.Init;
    } else if (!this.isUpdatedDNS) {
      this.cancelRegion(true);
    }

  }

  private putSaveRegion(regionToSave: Region): void {
    this.isSavingRegion = true;
    this.multiplesRequest['region'] = StatusRequest.Init;
    this.putSent = true;
    const putRegionSub = this.regionsPostProvisionSvc.putDeploymentRegion(this.deployment.companyId, regionToSave.regionId, regionToSave)
      .subscribe(updatedRegion => {
        this.isSavingRegion = false;
        this.isSaving = !this.isSavingRegion && !this.isUpdatingDns;
        this.region.soleTenantGroup = updatedRegion.soleTenantGroup;
        this.notificationSuccess();
        this.regionUpdatedEmitter.emit(this.region);
        this.multiplesRequest['region'] = StatusRequest.Success;
        this.cancelRegion();
      }, errorResponse => {
        this.multiplesRequest['region'] = StatusRequest.Failed;
        this.isSaving = false;
        this.handleErrors(errorResponse.error);
        this.isSavingRegion = false;
      });
    super.addSubscriptions(putRegionSub);
  }

  private updateExternalDnsRegion(regionToSave: Region): void {
    this.multiplesRequest['externalDns'] = StatusRequest.Init;
    this.putDNSSent = true;
    this.isUpdatingDns = true;
    const putDNSSub = this.regionsPostProvisionSvc.saveExternalDns(this.deployment.companyId, regionToSave.regionId, regionToSave.dnsRecord)
      .pipe(finalize(() => {
        if (!this.putSent) {
          this.isSaving = this.isSavingRegion && this.isUpdatingDns;
        }
      }))
      .subscribe((response) => {
        if (response['value']) {
          this.isUpdatingDns = false;
          this.region.dnsRecord = regionToSave.dnsRecord;
          this.regionUpdatedEmitter.emit(this.region);
          this.multiplesRequest['externalDns'] = StatusRequest.Success;
          if (!this.putSent) {
            this.notificationSuccess();
            this.cancelRegion();
            this.isInvalidExternalDns = false;
          }
        }
      }, errorResponse => {
        if (this.isUpdatedDNS) {
          this.isUpdatedDNS = _.clone(false);
        }
        this.isUpdatingDns = false;
        this.multiplesRequest['externalDns'] = StatusRequest.Failed;
        this.handleExternalDnsError(errorResponse.error);
        this.notificationErrorOnDnsRecord();
        this.isSaving = false;
        this.isInvalidExternalDns = true;
      });
    super.addSubscriptions(putDNSSub);
  }

  private handleErrors(errorResponse): void {
    if (errorResponse && errorResponse.errors) {
      const keyList = _.keys(errorResponse.errors) || [];
      _.each(keyList, (key: string) => this.regionForm.get(key).setErrors({serverError: errorResponse.errors[key]}));
    }
  }

  private handleExternalDnsError(errorResponse) {
    if (errorResponse && errorResponse.errors) {
      this.handleErrors(errorResponse);
    } else {
      this.setTranslations();
      this.regionForm.get('ExternalDnsName').setErrors({serverError: this.translate.instant('regions_list_post.ERROR_DNS_RECORD_FIELD')});
    }
  }

  private notificationSuccess(certificate?: boolean): void {
    this.setTranslations();
    const notificationParams = {region: `${this.region.description} (${this.region.name})`};
    const notification = {
      type: 'success', message: !certificate ? this.translate.instant('regions_list_post.SAVED', notificationParams) :
        this.translate.instant('regions_list_post.SSL_SAVED', notificationParams)
    };
    this.notifierService.notify(notification.type, notification.message);
  }

  private notificationErrorOnDnsRecord(): void {
    this.setTranslations();
    const errorNotification = {type: 'error', message: this.translate.instant('regions_list_post.ERROR_DNS_RECORD')};
    this.notifierService.notify(errorNotification.type, errorNotification.message);
  }

  private buildForm(): void {
    this.regionForm = this.formBuilder.group({
      Subnet: [{value: '', disabled: true}],
      ExternalDnsName: [{value: '', disabled: this.isPendingConf}],
      ExternalIpAddress: [{value: '', disabled: true}],
      Region: [{value: '', disabled: true}],
      Primary: [{value: true, disabled: true}],
      SoleTenantGroupSelect: [{value: '', disabled: true}]
    });
    this.buildValidationType(false);
    this.regionForm.patchValue({
      'Region': this.region,
      'Primary': this.region.primary,
      'Subnet': this.region.subnet,
      'ExternalDnsName': this.region.dnsRecord,
      'ExternalIpAddress': this.region.ipRecord,
    });
  }

  private setFormTouchedStatus(): void {
    const mapFormRegionKeys = {
      ExternalDnsName: 'dnsRecord',
    };
    this.isFormModified = Object.keys(mapFormRegionKeys).some(key => this.region[mapFormRegionKeys[key]] !== this.f[key].value);
  }

  private verifyAccessSoltenant(): void {
    const earlyAccess = JSON.parse(sessionStorage.getItem('earlyaccess'));
    this.earlyAccessSoleTenant = _.find(earlyAccess, (access: any) => access.feature === earlyAccessSoleTenant) ? true : false;
  }

  private getSoltenantGroupByRegion(): void {
    this.loadingSoleTenant = true;
    const soleTenantCurrent = this.region.soleTenantGroup;
    const getSoltenantSub = this.regionsPostProvisionSvc.getSoltenantGroupByRegion(this.deployment.companyId, this.region.regionId)
      .pipe(finalize(() => {
        this.loadingSoleTenant = false;
      }))
      .subscribe((response: ListDto<SoltenantGroup>) => {
        if (response.list.length > 0) {
          this.soltenantGroups = _.map(response.list, element => {
            return {
              name: element.name,
              zone: element.zone,
              label: element.name + ' (' + element.zone + ')'
            };
          });
          if (soleTenantCurrent) {
            const name = soleTenantCurrent.name;
            const select = _.find(this.soltenantGroups, element => element.name === name);
            if (select) {
              this.regionForm.get('SoleTenantGroupSelect').setValue(select);
            } else {
              this.isSoleTenantNotFound = true;
              this.regionForm.get('SoleTenantGroupSelect').setValue(soleTenantCurrent.name + ' (' + soleTenantCurrent.zone + ')');
            }
          }
          this.regionForm.get('SoleTenantGroupSelect').enable();
        }
      });
    super.addSubscriptions(getSoltenantSub);
  }

  private getRegionById(): void {
    const getRegionSubs = this.regionsPostProvisionSvc.getRegionById(this.deployment.companyId, this.region.regionId)
      .subscribe(region => {
        this.setRegionById(region);
      });
    super.addSubscriptions(getRegionSubs);
  }

  private clearValidations(): any {
    Object.keys(this.f).forEach(prop => {
      this.regionForm.get(prop).setValidators(null);
      this.regionForm.get(prop).setErrors(null);
      this.regionForm.get(prop).updateValueAndValidity();
    });
  }

  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
    this.deployRegionLabel = this.translate.instant('regions_list_post.DEPLOY_REGION');
    this.isPrimaryLabel = this.translate.instant('regions_list_post.IS_PRIMARY');
    this.subnetLabel = this.translate.instant('regions_list_post.SUBNET');
    this.externalDnsLabel = this.translate.instant('regions_list_post.EXTERNAL_DNS_NAME');
    this.externalIpLabel = this.translate.instant('regions_list_post.EXTERNAL_IP_ADDRESS');
    this.errorRequired = this.translate.instant('regions_list_post.ERROR_REQUIRED');
    this.errorPattern = this.translate.instant('regions_list_post.ERROR_PATTERN');
    this.selectRegionLabel = this.translate.instant('regions_list_post.SELECT_REGION');
    this.actionsButtons = [
      this.translate.instant('regions_list_post.CANCEL_BTN'),
      this.translate.instant('regions_list_post.SAVE_BTN'),
      this.translate.instant('regions_list_post.SAVING')
    ];
    this.dnsFailed = this.translate.instant('regions_list_post.DNS_FAILED');
    this.dnsVerified = this.translate.instant('regions_list_post.DNS_VERIFIED');
    this.tooltipCopy = this.translate.instant('regions_list_post.COPY');
    this.tooltipCopied = this.translate.instant('regions_list_post.COPIED');
    this.soleTenantConfigurationText = this.translate.instant('regions_list_post.SOLETENANT_CONFIGURATION');
    this.soleTenantDescription = this.translate.instant('regions_list_post.SOLETENANT_DESCRIPTION');
    this.soleTenantGroupText = this.translate.instant('regions_list_post.SOLETENANT_GROUP_DESCRIPTION');
    this.soletenantNotFoundMsg = this.translate.instant('regions_list_post.SOLETENANT_GROUP_NOT_FOUND');
  }

  private buildValidationType(isUpdatedDns: boolean): void {
    if (isUpdatedDns) {
      this.validationType = {
        'ExternalDnsName': [Validators.required, externalDnsNameValidator],
        'ExternalIpAddress': [Validators.required],
      };
    } else {
      this.validationType = {
        'ExternalDnsName': [Validators.required],
        'ExternalIpAddress': [Validators.required],
      };
    }
  }
}
