export class CloudAppV2 {
  public cloudAppId: number;
  public name: string;
  public version: string;
  public companyId: number;
  public onCloud: boolean;
  public toCloud: boolean;
  public clientApplicationId: string;
  public applicationId: string;
  public cloudAppStatusId: number;
  public status: string;
  public remote: string;
  public restricted: string;
  public path: string;
  public startIn: string;
  public countCompanyUsers: number;
  public countSecurityGroups: number;
  public failedProcess: string;
  public isFailed: boolean;
  public collection: {scaleId: number, collectionAlias: string};
  public cloudUserIds: number[];
  public securityGroupIds: number[];
  public collectionPoolId: number;
  public allCollectionUsers: boolean;
  public migrationVersion: number;

  constructor (data? : any) {
    this.cloudAppId = data && data.cloudAppId ? data.cloudAppId : 0;
    this.name = data && data.name ? data.name : '';
    this.version = data && data.version ? data.version : '';
    this.companyId = data && data.companyId ? data.companyId : 0;
    this.onCloud = data && data.onCloud ? data.onCloud : true;
    this.toCloud = data && data.toCloud ? data.toCloud : true;
    this.applicationId = data && data.applicationId ? data.applicationId : '';
    this.clientApplicationId = data && data.clientApplicationId ? data.clientApplicationId : '';
    this.cloudAppStatusId = data && data.cloudAppStatusId ? data.cloudAppStatusId : 0;
    this.status = data && data.status ? data.status : '';
    this.remote = data && data.remote ? data.remote : '';
    this.restricted = data && data.restricted ? data.restricted : '';
    this.path = data && data.path ? data.path : '';
    this.startIn = data && data.startIn ? data.startIn : '';
    this.countCompanyUsers = data && data.countCompanyUsers ? data.countCompanyUsers : 0;
    this.countSecurityGroups = data && data.countSecurityGroups ? data.countSecurityGroups : 0;
    this.failedProcess = data && data.failedProcess ? data.failedProcess : '';
    this.isFailed = data && data.isFailed ? data.isFailed : false;
    this.collection = data && data.collections ? data.collections : undefined;
    this.cloudUserIds = data && data.cloudUserIds ? data.cloudUserIds : [];
    this.securityGroupIds = data && data.securityGroupIds ? data.securityGroupIds : [];
    this.collectionPoolId = data && data.collectionPoolId ? data.collectionPoolId : 0;
    this.allCollectionUsers = data && data.allCollectionUsers;
    this.migrationVersion = data && data.migrationVersion ? data.migrationVersion : 0;
  }
}

