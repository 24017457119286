import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';

import { AuthenticationService } from 'src/app/core/security/authentication.service';
import { PasswordStrengthValidator } from '../core/shared/pipes/validator/password-strength.validators';
import { passwordMatch } from '../core/shared/pipes/validator/password.match.validator';
import { HttpStatusCodes, Invitation, ServerError, ValidationErrorsCodes } from '../models';
import { AutoUnsubscribeComponent } from '../shared/auto-unsubscribe/auto-unsubscribe.component';
import { CompleteAdminInviteService } from './complete-admin-invite.service';

import { locale as english } from './i18n/complete-admin-invite.en';
import { locale as spanish } from './i18n/complete-admin-invite.es';

const maxLength = 30;
const minLengthPassword = 8;
@Component({
    selector: 'app-complete-admin-invite',
    templateUrl: 'complete-admin-invite.component.html',
    styleUrls: ['complete-admin-invite.component.scss']
})
export class CompleteAdminInviteComponent extends AutoUnsubscribeComponent implements OnInit, AfterViewInit {

    public invitedAdmin: Invitation;
    public loadingInvitation: boolean;
    public invitationToken: string;
    public firebaseError: string;

    // translates
    public titleMsg: string;
    public firstNameMsg: string;
    public lastNameMsg: string;
    public userNameMsg: string;
    public passwordMsg: string;
    public confirmPasswordMsg: string;
    public passwordReqTooltip: string;
    public googleMsg: string;
    public microsoftMsg: string;
    public errorRequired: string;
    public errorOnlyNumbers: string;
    public errorNamePattern: string;
    public errorMaxLengthName: string;
    public errorPasswordsMatch: string;
    public userPassAuthMsg: string;
    public ssoAuthMsg: string;
    public ssoAuthInfoMsg: string;
    public passActionsButtons: string[];

    public completeInviteForm: FormGroup;
    public validationType: any;

    public passActionPressed: boolean;
    public isPassAuthType: boolean;
    public hidePassword: boolean;
    public hidePasswordConfirm: boolean;
    public email: string;

    public options = {
        theme: 'light', // two possible values: light, dark
        dir: 'ltr', // two possible values: ltr, rtl
        layout: 'vertical', // fixed value. shouldn't be changed.
        headerpos: 'fixed', // two possible values: fixed, absolute
        boxed: 'full', // two possible values: full, boxed
        navbarbg: 'skin3', // six possible values: skin(1/2/3/4/5/6)
        logobg: 'skin3' // six possible values: skin(1/2/3/4/5/6)
    };

    constructor(private authService: AuthenticationService,
                private inviteService: CompleteAdminInviteService,
                private activatedRoute: ActivatedRoute,
                private translate: TranslateService,
                private formBuilder: FormBuilder,
                private router: Router,
                private cdRef: ChangeDetectorRef) {
        super();
        this.setTranslations();
    }

    public ngOnInit() {
        this.initView();
        this.getInvitation();
    }
    public ngAfterViewInit() {
        this.setTranslations();
    }

    get f() { return this.completeInviteForm.controls; }

    private initView(): void {
        this.completeInviteForm = this.formBuilder.group({
            FirstName: [{ value: '', disabled: false }],
            LastName: [{ value: '', disabled: false }],
            UserName: [{ value: '', disabled: true }],
            Password: [{ value: '', disabled: false }],
            ConfirmPassword: [{ value: '', disabled: false }],
            AuthType: [{ value: 'pass', disabled: false }],
            user: [{ value: '', disabled: false }],
        });
        this.setAuthType('pass');
        const nameValidators = [Validators.required, Validators.maxLength(maxLength),
                                Validators.pattern('^[-\'\\s\\w\u00F1\u00D1]+$')];
        const passwordValidators = [Validators.required, Validators.minLength(minLengthPassword), PasswordStrengthValidator];
        this.validationType = {
            'FirstName': nameValidators,
            'LastName': nameValidators,
            'UserName': [Validators.required],
            'Password': passwordValidators,
            'ConfirmPassword': [passwordMatch(this.f.Password)].concat(passwordValidators),
            'user': [],
        };
    }

    private getInvitation(): void {
        this.invitationToken = this.activatedRoute.snapshot.queryParamMap.get('token');
        this.loadingInvitation = true;
        const inviteSubs = this.inviteService.getInvitation(this.invitationToken)
            .subscribe(
                current => {
                    this.invitedAdmin = current;
                    this.email = this.invitedAdmin.email;
                    this.completeInviteForm.patchValue(
                        {
                        'FirstName': this.invitedAdmin.firstName,
                        'LastName': this.invitedAdmin.lastName,
                        'UserName': this.invitedAdmin.userName,
                        'user': this.invitedAdmin
                });
                },
                () => { this.goToLogin(); },
                () => { this.loadingInvitation = false; }
            );
        super.addSubscriptions(inviteSubs);
    }

    private setTranslations(): void {
        if (this.translate.getBrowserLang() === 'es') {
            this.translate.use('es');
            this.translate.setTranslation('es', spanish);
        } else {
            this.translate.use('en');
            this.translate.setTranslation('en', english);
        }

        // labels
        this.titleMsg = this.translate.instant('complete_admin_invite.TITLE');
        this.firstNameMsg = this.translate.instant('complete_admin_invite.FIRST_NAME');
        this.lastNameMsg = this.translate.instant('complete_admin_invite.LAST_NAME');
        this.userNameMsg = this.translate.instant('complete_admin_invite.USERNAME');
        this.passwordMsg = this.translate.instant('complete_admin_invite.PASSWORD');
        this.confirmPasswordMsg = this.translate.instant('complete_admin_invite.CONFIRM_PASSWORD');
        this.passwordReqTooltip = this.translate.instant('complete_admin_invite.PASSWORD_REQUIREMENTS');

        // errors
        this.errorRequired = this.translate.instant('complete_admin_invite.ERROR_REQUIRED');
        this.errorOnlyNumbers = this.translate.instant('complete_admin_invite.ERROR_ONLY_NUMBER');
        this.errorNamePattern = this.translate.instant('complete_admin_invite.ERROR_NAME_PATTERN');
        this.errorMaxLengthName = this.translate.instant('complete_admin_invite.ERROR_MAX_LENGTH', { val: maxLength });
        this.errorPasswordsMatch = this.translate.instant('complete_admin_invite.ERROR_PASSWORD_MUST_MATCH');

        // auth
        this.userPassAuthMsg = this.translate.instant('complete_admin_invite.USER_PASS_AUTH_TYPE');
        this.passActionsButtons = ['', this.translate.instant('complete_admin_invite.COMPLETE'),
                                   this.translate.instant('complete_admin_invite.COMPLETING')];
        this.ssoAuthMsg = this.translate.instant('complete_admin_invite.SSO_AUTH_TYPE');
        this.ssoAuthInfoMsg = this.translate.instant('complete_admin_invite.SSO_AUTH_TYPE_INFO');
        this.googleMsg = this.translate.instant('complete_admin_invite.GOOGLE');
        this.microsoftMsg = this.translate.instant('complete_admin_invite.MICROSOFT');
    }

    private goToLogin(): void {
        this.router.navigate(['/login']);
    }

    private setServerErrors(errorResponse: { error: ServerError, status: number }): void {
        if (errorResponse.error.code === ValidationErrorsCodes.ValidationError40027 ||
            errorResponse.error.code === ValidationErrorsCodes.ValidationError40029) {
            const validationErrors = errorResponse && errorResponse.error && errorResponse.error.errors;
            _.each(validationErrors, (prop: any, key: any) => {
                if (this.f[key]) {
                    this.f[key].setErrors({ serverError: prop });
                }
            });
        }
    }

    private completeCas(user: firebase.auth.UserCredential, password: string, currentProvider?: string): void {
        const invitation: Invitation = {
            accountId: this.invitedAdmin.accountId,
            roleTypeId: this.invitedAdmin.roleTypeId,
            url: this.invitedAdmin.url,
            allCompanies: this.invitedAdmin.allCompanies,
            allowCompanies: this.invitedAdmin.allowCompanies,
            roleSecurityId: this.invitedAdmin.roleSecurityId,
            personalPhoneNumber: this.invitedAdmin.personalPhoneNumber,
            personalPhoneNumberExt: this.invitedAdmin.personalPhoneNumberExt,

            firstName: this.f.FirstName.value,
            lastName: this.f.LastName.value,
            userName: this.f.UserName.value,
            email: this.email,
            password: password,
        };
        const completeSubs = this.inviteService.completeInvitation(this.invitationToken, invitation)
            .subscribe(() => {
                this.authService.loginObservable(user);
                this.authService.getCurrentUser.subscribe((obj: any) => {     
                  if(obj){
                    if(obj.current && !obj.error){
                       this.authService.setCurrentProvider(currentProvider || 'password');
                       this.authService.earlyAccessAndPresentations(obj.current, true);
                    }
                    this.loadingInvitation = false;
                   }
                });
            }, (errorResponse: { error: ServerError, status: number }) => {
                this.loadingInvitation = false;
                if (errorResponse.status === HttpStatusCodes.BadRequest) {
                    this.setServerErrors(errorResponse);
                }
            });
        super.addSubscriptions(completeSubs);
    }

    private manageFirebaseError(error: { code: string, message: string }): void {
        this.setTranslations();
        let message = '';
        switch (error.code) {
            case 'auth/network-request-failed':
                message = this.translate.instant('complete_admin_invite.CONNECTION_ERROR');
                break;
            case 'auth/argument-error':
                message = this.translate.instant('complete_admin_invite.ARGUMENT_ERROR');
                break;
            case 'auth/invalid-email':
                message = this.translate.instant('complete_admin_invite.INVALID_EMAIL_ERROR');
                break;
            case 'auth/expired-action-code':
                message = this.translate.instant('complete_admin_invite.EXPIRED_ERROR');
                break;
            case 'auth/invalid-action-code':
                message = this.translate.instant('complete_admin_invite.RESET_CODE_ERROR');
                break;
            case 'auth/user-disabled':
                message = this.translate.instant('complete_admin_invite.USER_DISABLED_ERROR');
                break;
            case 'auth/user-not-found':
            case 'auth/wrong-password':
                message = this.translate.instant('complete_admin_invite.INVALID_CREDENTIALS');
                break;
            case 'auth/weak-password':
                message = this.translate.instant('complete_admin_invite.WEAK_PASSWORD_ERROR');
                break;
            case 'auth/popup-closed-by-user':
              message = this.translate.instant('complete_admin_invite.CLOSED_BY_USER_ERROR');
              break;
            case 'auth/unauthorized-domain':
              message = this.translate.instant('complete_admin_invite.UNAUTHORIZED_DOMAIN_ERROR');
              break;
            case 'auth/account-exists-with-different-credential':
              message = this.translate.instant('complete_admin_invite.ACCOUNT_EXISTS_ERROR');
              break;
            case 'auth/email-already-in-use':
              message = this.translate.instant('complete_admin_invite.EMAIL_EXISTS_ERROR');
              break;
            default:
                message = this.translate.instant('complete_admin_invite.UNEXPECTED_ERROR');
                break;
        }

        this.firebaseError = message;
        this.disableLoadings();
        this.cdRef.detectChanges();
    }
    private manageFirebaseResponse(response: firebase.auth.UserCredential): void {
        if (response) {
            const provider = response.credential && response.credential.providerId;
            response.user.getIdToken()
                .then(password => {
                    this.completeCas(response, password, provider || 'password');
                })
                .catch(() => {
                    this.loadingInvitation = false;
                });
        } else {
            this.loadingInvitation = false;
        }
    }
    private enableDisableForm(enable: boolean): void {
        for (const key in this.f) {
            if (enable) {
                this.f[key].enable();
            } else {
                this.f[key].disable();
            }
        }
        if (enable) {
            this.completeInviteForm.enable();
        } else {
            this.completeInviteForm.disable();
        }
        this.f.UserName.disable();
    }
    private disableLoadings(): void {
        this.loadingInvitation = false;
        this.passActionPressed = false;
        this.enableDisableForm(true);
    }

    public setValidations(input: string, excludePassword?: boolean): void {
        const passKey = 'Password';
        for (const key in this.f) {
            if (key === input) {
                const hidePasswordErrors = excludePassword && key.includes(passKey);
                this.f[key].setValidators(hidePasswordErrors ? null : this.validationType[key]);
                this.f[key].updateValueAndValidity();
                break;
            } else if (!input) {
                const hidePasswordErrors = excludePassword && key.includes(passKey);
                this.f[key].setValidators(hidePasswordErrors ? null : this.validationType[key]);
                this.f[key].updateValueAndValidity();
            }
        }
    }

    public completeInvitation(): void {
        this.passActionPressed = true;
        this.enableDisableForm(false);
        this.firebaseError = undefined;
        this.cdRef.detectChanges();
        const passwordSubs = this.inviteService.completeSignUp(this.f.UserName.value, this.f.Password.value)
            .subscribe((response: firebase.auth.UserCredential) => {
                this.manageFirebaseResponse(response);
            }, (error) => {
                    this.manageFirebaseError(error);
                });
        super.addSubscriptions(passwordSubs);
    }
    public completeWithGoogle(): void {
        this.setValidations(undefined, true);
        if (this.completeInviteForm.invalid) {
            return;
        }
        this.loadingInvitation = true;
        this.firebaseError = undefined;
        this.cdRef.detectChanges();
        const googleSubs = this.inviteService.signinWithGoogle()
            .subscribe((response: firebase.auth.UserCredential) => {
                this.manageFirebaseResponse(response);
            }, (error) => {
                    this.manageFirebaseError(error);
                });
        super.addSubscriptions(googleSubs);
    }
    public completeWithMicrosoft(): void {
        this.setValidations(undefined, true);
        if (this.completeInviteForm.invalid) {
            return;
        }
        this.loadingInvitation = true;
        this.firebaseError = undefined;
        this.cdRef.detectChanges();
        const microsoftSubs = this.inviteService.signinWithMicrosoft()
            .subscribe((response: firebase.auth.UserCredential) => {
                this.manageFirebaseResponse(response);
            }, (error) => {
                    this.manageFirebaseError(error);
                });
        super.addSubscriptions(microsoftSubs);
    }
    public onPassActionConfirmation(): void {
        this.setValidations(undefined);
        if (this.completeInviteForm.invalid) {
            return;
        }

        this.completeInvitation();
    }
    public setAuthType(value: string): void {
        this.isPassAuthType = value === 'pass';
        if (this.isPassAuthType) {
            this.f.Password.enable();
            this.f.Password.setErrors(null);
            this.f.ConfirmPassword.enable();
            this.f.ConfirmPassword.setErrors(null);

        } else {
            this.f.Password.disable();
            this.f.Password.setValue('');
            this.f.ConfirmPassword.disable();
            this.f.ConfirmPassword.setValue('');
        }
        this.firebaseError = undefined;
        this.cdRef.detectChanges();
    }

}
