export class Deployment {
    public companyId: number;
    public organizationId: number;
    public partnerId: number;
    public onCloud: boolean;
    public status: string;
    public code: string;
    public name: string;
    public loginIdentifier: string;
    public originalName: string;
    public contactFirstName: string;
    public contactLastName: string;
    public companiesStatusId: number;
    public contactEmail: string;
    public phoneNumber: string;
    public discoveryToken: string;
    public emailDomain: any;
    public deploymentStep: string;
    public statusId: number;
    public provisionType: string;
    public website: string;
    public phoneNumberExt: string;
    public timeZone: string;
    public createDate: string;
    public removeDate: string;
    public sendToCloudDate: string;
    public settingGroupId: number;
    public usersCount: number;
    public active: boolean;

}
