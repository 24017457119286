import {Component, Input, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';

import { Deployment } from '../../models/deployment/deployment';
import { AutoUnsubscribeComponent } from '../../shared/auto-unsubscribe/auto-unsubscribe.component';
import {mustMatch} from './../../core/shared/pipes/validator/must.match.validator'
import { DeploymentsService } from './../deployments.service';

import {locale as english} from './../i18n/deployments.en';
import {locale as spanish} from './../i18n/deployments.es';

const validationError = 40027;
const three = 3;
const four = 4;

@Component({
  selector: 'app-deployments-delete',
  templateUrl: './deployments-delete.component.html',
  styleUrls: ['./deployments-delete.component.scss']
})
export class DeploymentsDeleteComponent extends AutoUnsubscribeComponent implements OnInit {
  @Input()
  public deploymentSelected: Deployment;
  @Input()
  public deleteModal: any;

  public loading: boolean;
  public deleteDeploymentForm: FormGroup;
  public submitted: boolean;
  public elementsInfo: any;
  public infoUrl: string;
  public deleteInfo: string;
  public reason: string;
  public fillTitle: string;
  public error: string;
  public mustMatchName: string;
  public actionsButtons: any;
  public validationType: any;
  public nameDeployment: string;
  public currentName : string;
  public deployments: Deployment[];
  public deleteSuccessful: boolean;
  public title: string;

  constructor(private formBuilder: FormBuilder, private translate: TranslateService, public router: Router,
              private deploymentService: DeploymentsService, private notifier: NotifierService) {
      super();
      this.setTranslation();
      this.infoUrl = 'https://helpcenter.itopia.com/en/articles/481954-delete-a-deployment#delete-a-deployment-after-the-provisioning';
      }

  public ngOnInit() {
    this.nameDeployment = this.deploymentSelected && this.deploymentSelected.name ? this.trimName( this.deploymentSelected.name.toLocaleLowerCase()) : '';
    this.currentName = '';
    this.deleteDeploymentForm = this.formBuilder.group({
      Name: [''],
      Reason: [''],
      DeleteBigQueryDataSet: [{value: false, disabled: false}]

    });
    this.setTranslation();
    this.loading = false;
    this.validationType = {
      'Name': [Validators.required, mustMatch(this.nameDeployment)],
      'Reason': [Validators.required]
    };
    this.deployments = [];
    this.deleteSuccessful = false;
  }

  public changeName(): void {
    const name = this.deleteDeploymentForm.get('Name').value;
    if (name) {
        this.currentName = this.trimName(name.toLocaleLowerCase());
    }
  }

// convenience getter for easy access to form fields
  get f() { return this.deleteDeploymentForm.controls; }

  public setValidations(input: string): any {
  for (const key in this.f) {
    if (key === input) {
      this.f[key].setValidators(this.validationType[key]);
      this.f[key].updateValueAndValidity();
      break;
    } else if (!input) {
      this.f[key].setValidators(this.validationType[key]);
      this.f[key].updateValueAndValidity();
    }
  }
}
  public onSubmit() {
  this.loading = true;
  this.submitted = true;
  this.changeName();
  if (this.deleteDeploymentForm.invalid || this.nameDeployment !== this.currentName) {
    return;
  }
  const data = {
    id: this.deploymentSelected.companyId,
    name: this.f['Name'] ? this.f.Name.value : '' ,
    reason: this.f['Reason'] ? this.f.Reason.value : '',
    deleteBigQueryDataSet: this.f['DeleteBigQueryDataSet'] ? this.f.DeleteBigQueryDataSet.value : false
  };
  this.setTranslation();
  const message = this.deploymentSelected.companiesStatusId === three ?
    this.translate.instant('deployments.DELETE_CONFIRMATION_ONCLOUD', { name: data.name }) :
    this.translate.instant('deployments.DELETE_DEPLOY_NOT_ONCLOUD', { name: data.name });
  const deleteDeploymentSubscription = this.deploymentService.deleteDeployment(data).subscribe((data: any) => {
    this.notifier.notify('success', message);
    this.closeModal();
    if (this.deploymentSelected.companiesStatusId === three && data && data.companiesStatusId === four) {
      this.deploymentSelected.companiesStatusId = data.companiesStatusId;
      this.router.navigate(['/dashboard/deletion/'+ this.deploymentSelected.companyId]);
    } else {
      const recentSubs = this.deploymentService.deploymentRecent.subscribe((data) => {
        this.deployments = data;
        if (this.deployments.length === 0) {
          this.router.navigate(['/dashboard/nodeployment/']);
          location.reload();
        } else {
          this.deploymentSelected = this.deployments[0];
          this.deploymentService.setProvisionDeleted(true);
          this.deleteSuccessful = true;
          this.router.navigate(['/dashboard/deletion/'+ this.deploymentSelected.companyId]);
        }
      });
      super.addSubscriptions(recentSubs);
    }
  }, (error) => {
    this.deleteDeploymentError(error);
  });
  super.addSubscriptions(deleteDeploymentSubscription);
}
  public closeModal(): void {
    this.deleteModal.close();
  }

  private trimName(name: string): string {
      return name ? name.replace(/(^\s+|\s+$)/g, '') : '';
  }

  private deleteDeploymentError(error): void {
  if (error && error.error && error.error.code === validationError) {
    const validationErrors = error.error.errors;
    Object.keys(validationErrors).forEach(prop => {
      this.deleteDeploymentForm.get(prop).setErrors({ serverError: validationErrors[prop] });
    });
  } else {
    this.closeModal();
  }
  this.loading = false;
}
  private setTranslation(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
    this.elementsInfo = [ this.translate.instant('deployments.DELETE_DETAILS_ELEMENTS.VMANDDISK'),
                          this.translate.instant('deployments.DELETE_DETAILS_ELEMENTS.VPNS'),
                          this.translate.instant('deployments.DELETE_DETAILS_ELEMENTS.FR'),
                          this.translate.instant('deployments.DELETE_DETAILS_ELEMENTS.RIPS'),
                          this.translate.instant('deployments.DELETE_DETAILS_ELEMENTS.CI'),
                          this.translate.instant('deployments.DELETE_DETAILS_ELEMENTS.DIP')];
    this.deleteInfo = this.translate.instant('deployments.DELETE_A_DEPLOYMENT');
    this.reason = this.translate.instant('deployments.REASON');
    this.error = this.translate.instant('deployments.ERROR_REQUIRED');
    if (this.deploymentSelected && this.deploymentSelected.name) {
      this.fillTitle = this.translate.instant('deployments.FILL_NAME', { deployment : this.deploymentSelected.name});
      this.mustMatchName = this.translate.instant('deployments.NAMEMUSTMATCH', { deployment : this.deploymentSelected.name});
      this.title = this.translate.instant('deployments.DELETE_COMPONENT_TITLE');
    }
    this.actionsButtons = [this.translate.instant('deployments.CANCEL_BTN'), this.translate.instant('deployments.DELETE_BTN'),
                           this.translate.instant('deployments.DELETING')];
  }
  public onActionConfirmation(confirm) {
    if (confirm) {
      this.setValidations(undefined);
      this.onSubmit();
    } else {
      this.closeModal();
    }
  }
  public enabledDeleteButton() {
    this.setValidations('Name');
    if (this.deleteDeploymentForm.valid && this.f.Name.value && this.f.Reason.value) {
      this.f['Name'].markAsTouched();
      this.f['Reason'].markAsTouched();
    }
  }
}
