import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AutoUnsubscribeComponent } from './auto-unsubscribe.component';
@NgModule({
    imports: [CommonModule],
    declarations: [AutoUnsubscribeComponent],
    exports: [AutoUnsubscribeComponent]
})
export class AutoUnsubscribeModule {
}
