import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { finalize } from 'rxjs/operators';
import * as _ from 'underscore';

import { fileExtensionValidator } from 'src/app/core/shared/pipes';
import { Certificate, CertificateDto, Region } from 'src/app/models';
import { AutoUnsubscribeComponent } from '../../auto-unsubscribe/auto-unsubscribe.component';
import { RegionsPostProvisionService } from '../service/regions-post-provision.service';

import { locale as english } from './i18n/region-certificate.en';
import { locale as spanish } from './i18n/region-certificate.es';

const extensionList = ['.pfx'];
const fileKey = 'SslCertificateFile';
const passwordKey = 'SslCertificatePassword';

@Component({
  selector: 'app-region-certificate',
  templateUrl: 'region-certificate.component.html',
  styleUrls: ['region-certificate.component.scss']
})
export class RegionCertificateComponent extends AutoUnsubscribeComponent implements OnInit, OnChanges, AfterViewInit {
  @Input()
  public deploymentId: number;
  @Input()
  public regionId: number;
  @Input()
  public isSaving: boolean;
  @Input()
  public isUpdatedDNS: boolean;
  @Input()
  public isRegionPendingConf: boolean;

  @Output()
  public isInvalid = new EventEmitter<boolean>();
  @Output()
  public isSavingCertificate = new EventEmitter<boolean>();
  @Output()
  public hasPassword = new EventEmitter<boolean>();
  @Output()
  public regionById = new EventEmitter<Region>();

  public sslCertificateLabel: string;
  public uploadCertificateLabel: string;
  public passwordCertificateLabel: string;
  public maxSizeCertificateLabel: string;
  public emptyFileCertificateLabel: string;
  public invalidFormatCertificateLabel: string;
  public errorRequired: string;
  public errorPattern: string;
  public errorUploadNew: string;
  public providedByItopiaText: string;

  public certificateFile: CertificateDto;
  public defaultCertificateFile: CertificateDto;
  public loadingCertificate: boolean;
  public showPasswordField: boolean;
  public hidePasswordValue: boolean;
  public loadingCurrentRegion: boolean;

  public certificateForm: FormGroup;
  public validationType: object;

  private isPendingUploadNew: boolean;

  constructor(private formBuilder: FormBuilder, private regionsService: RegionsPostProvisionService,
              private translate: TranslateService) {
    super();
    this.setTranslations();
  }

  get f() { return this.certificateForm ? this.certificateForm.controls : null; }

  public ngOnInit(): void {
    this.certificateForm = this.formBuilder.group({
      SslCertificateFile: [{ value: '', disabled: this.isRegionPendingConf }],
      SslCertificatePassword: [{ value: '', disabled: this.isRegionPendingConf }]
    });
    this.validationType = {
      'SslCertificateFile': [fileExtensionValidator(extensionList)],
      'SslCertificatePassword': [Validators.required, Validators.pattern(/^[^\s]+(\w.*)?$/)]
    };

    this.defaultCertificateFile = new CertificateDto();
    this.certificateFile = new CertificateDto();
    this.loadingCurrentRegion = true;
    const getRegionSubs = this.regionsService.getRegionById(this.deploymentId, this.regionId)
      .pipe(finalize(() => {
        this.loadingCurrentRegion = false;
      }))
      .subscribe((region: Region) => {
        this.regionById.emit(region);
        const hasCertificate = region && region.hasCertificate;

        if (hasCertificate) {
          this.loadingCertificate = true;
          const getCertSubs = this.regionsService.getCertificateByRegionId(this.deploymentId, this.regionId)
            .pipe(finalize(() => {
              this.loadingCertificate = false;
            }))
            .subscribe((certificate: CertificateDto) => {
              this.certificateFile = certificate;
              this.defaultCertificateFile = certificate;
              if (this.certificateFile) {
                this.f[fileKey].patchValue(this.certificateFile.fileName);
              }
            });
          super.addSubscriptions(getCertSubs);
        } else {
          if (this.isRegionPendingConf) {
            this.f[fileKey].setValue(this.providedByItopiaText);
          }
        }
      });
    super.addSubscriptions(getRegionSubs);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes.isSaving && !changes.isSaving.firstChange && changes.isSaving.currentValue &&
        changes.isSaving.currentValue !== changes.isSaving.previousValue) {
          this.saveCertificateToRegion(this.regionId);
      }
      if (changes.isUpdatedDNS && changes.isUpdatedDNS.currentValue && !changes.isSaving) {
        this.setErrorUploadNew(true);
      } else if (changes.isUpdatedDNS && !changes.isUpdatedDNS.firstChange && !changes.isUpdatedDNS.currentValue) {
        this.setErrorUploadNew(false);
      }
      if (changes.isRegionPendingConf && !changes.isRegionPendingConf.firstChange &&
        changes.isRegionPendingConf.currentValue !== changes.isRegionPendingConf.previousValue) {
          this.isRegionPendingConf = changes.isRegionPendingConf.currentValue;
      }

    }
  }

  public ngAfterViewInit() {
    this.setTranslations();
  }

  public setValidations(input: string): void {
    if (!this.isPendingUploadNew) {
      // tslint:disable-next-line:forin
      for (const key in this.f) {
        if (_.isString(this.f[key].value)) {
          this.f[key].setValue(this.f[key].value.toString().trim());
        }
        if (key === input) {
          this.f[key].setValidators(this.validationType[key]);
          this.f[key].updateValueAndValidity();
          break;
        } else if (!input) {
          this.f[key].setValidators(this.validationType[key]);
          this.f[key].updateValueAndValidity();
        }
      }
      if (this.certificateForm.invalid) {
        this.isInvalid.emit(true);
      } else {
        this.isInvalid.emit(false);
      }
      this.hasPassword.emit(this.f[passwordKey].value !== '');
    }
  }

  public passChanged(): void {
    this.isUpdatedDNS = true;
  }

  public dropFile(files: NgxFileDropEntry[]): void {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.uploadFile(file);
        });
      } else {
        this.mapFileErrors({ prop: fileKey, message: this.invalidFormatCertificateLabel });
      }
    }
  }

  private uploadFile(file: File): void {
    try {
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        const data = fileReader.result as string;
        const f = data.split('data:')[1],
          splited = f.split(';'),
          mimetype = splited[0] || 'text/plain',
          secondSplit = splited[1].split(','),
          content = secondSplit[1];
        this.certificateFile = new CertificateDto({
          fileName: file.name,
          mimeType: mimetype,
          fileData: content
        });
        this.f[fileKey].patchValue(this.certificateFile.fileName);
        this.f[fileKey].setErrors({uploadNew: null});
        this.isPendingUploadNew = false;
        this.setValidations(fileKey);
        this.showPasswordField = true;
        this.clearPass();
      };
      fileReader.readAsDataURL(file);
    } catch (ex) {
      this.mapFileErrors({ prop: fileKey, message: this.invalidFormatCertificateLabel });
    }
  }

  private clearPass(): void {
    this.f.SslCertificatePassword.setValue(null);
    this.f.SslCertificatePassword.setValidators(null);
    this.f.SslCertificatePassword.setErrors(null);
    this.f.SslCertificatePassword.markAsUntouched();
    this.f.SslCertificatePassword.updateValueAndValidity();
  }

  private mapFileErrors(error: { prop: string, message: string }): void {
    this.certificateForm.get(error.prop).setErrors({ serverError: error.message });
  }

  private setErrorUploadNew(isPendingUpload: boolean): void {
    this.f[fileKey].setErrors({uploadNew: isPendingUpload});
    this.f[fileKey].markAsTouched();
    if (!isPendingUpload) {
      this.f[fileKey].updateValueAndValidity();
      this.f[fileKey].markAsUntouched();
    }
    this.isInvalid.emit(isPendingUpload);
    this.isPendingUploadNew = isPendingUpload;
  }

  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
    this.sslCertificateLabel = this.translate.instant('region_certificate.SSL_CERTIFICATE');
    this.uploadCertificateLabel = this.translate.instant('region_certificate.UPLOAD');
    this.passwordCertificateLabel = this.translate.instant('region_certificate.PASSWORD');
    this.maxSizeCertificateLabel = this.translate.instant('region_certificate.MAX_SIZE_VALIDATION');
    this.emptyFileCertificateLabel = this.translate.instant('region_certificate.EMPTY_FILE');
    this.invalidFormatCertificateLabel = this.translate.instant('region_certificate.FORMAT_VALIDATION');
    this.errorRequired = this.translate.instant('region_certificate.ERROR_REQUIRED');
    this.errorPattern = this.translate.instant('region_certificate.ERROR_PATTERN');
    this.errorUploadNew = this.translate.instant('region_certificate.ERROR_UPLOAD_NEW');
    this.providedByItopiaText = this.translate.instant('region_certificate.PROVIDE_ITOPIA_TEXT');
  }

  private saveCertificateToRegion(regionId: number): void {
    const data = new Certificate({
      name: this.certificateFile.fileName,
      data: this.certificateFile.fileData,
      password: this.f[passwordKey].value
    });
    this.isSavingCertificate.emit(true);
    const updateCertSubs = this.regionsService.updateCertificateToRegion(this.deploymentId, regionId, data)
      .pipe(finalize(() => {
        this.isSavingCertificate.emit(false);
      }))
      .subscribe();
    super.addSubscriptions(updateCertSubs);
  }
}
