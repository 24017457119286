import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

import { environment } from 'src/environments/environment';

declare const gapi: any;
const forbidden = 403;

@Injectable({
  providedIn: 'root'
})
export class GcomputeService {
  public service = 'compute.googleapis.com';

  constructor() { }

  public loadGCompute(): void {
    try {
      return gapi.client.load('compute', environment.gapi.apiVersion);
    } catch (exception) {
      throwError(exception);
      return;
    }
  }
  public getZones(projectId): Promise<any> {
    try {
      const params = {
      project: projectId
      };
      const request = gapi.client.compute.zones.list(params);
      return request.then(function (response) {
      return Promise.resolve(response.result);
    }).catch(function (reason) {
      reason.request = {
        name: 'gapi.client.compute.zones.list',
        params: params
      };
      return Promise.reject(reason);
    });
  } catch {
    return Promise.reject({name: 'api-not-loaded'});
  }
  }
  public hasEnabled(projectId): Promise<boolean> {
    try {
      return this.getZones(projectId)
      .then(function () {
        return Promise.resolve(true);
      }).catch(function (reason) {
        if (reason.result.error.code === forbidden) {
          return Promise.resolve(false);
        }
        return Promise.reject(reason);
      });
    } catch {
      return Promise.reject({name: 'api-not-loaded'});
    }

  }
  public activate(consumer) {
    const params = {
        serviceName: this.service,
        consumerId: consumer
    };
    const request = gapi.client.servicemanagement.services.enable(params);
    return request.then(function (response) {
        return Promise.resolve(response.result);
    }).catch(function (reason) {
        reason.request = {
            name: 'servicemanagement.googleapis.com/v1/services/compute.googleapis.com:enable',
            params: params
        };
        return Promise.reject(reason);
    });
  }
}
