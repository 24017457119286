import {  Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { filter } from 'rxjs/operators';
import * as _ from 'underscore';

import { NotificationPaneActionService } from 'src/app/notifications/notification-pane/notification-pane-action.service';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../../core/security/authentication.service';
import { User } from '../../models';
import { SubscriptionManageService } from '../../subscription/subscription-manage/subscription-manage.service';
import { AutoUnsubscribeComponent } from '../auto-unsubscribe/auto-unsubscribe.component';


import { locale as english } from './i18n/header-navigation.en';
import { locale as spanish } from './i18n/header-navigation.es';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent extends AutoUnsubscribeComponent implements OnInit {

  @Output()
  openSideBar = new EventEmitter<boolean>();

  public config: PerfectScrollbarConfigInterface = {
    wheelPropagation: false
  };
  public currentUser: User;
  public links: any;
  public profileSubmenu: any;
  public logutLabel: string;
  public userName: string;
  public userEmail: any;
  public notificationsTooltip: string;
  public profileTooltip: string;
  public feedbackTooltip: string;
  public countErrors: number;
  public countOthers: number;
  public notifications: any;
  public helpCenterText: string;
  public helpTooltip: string;
  public integrationsLabel: string;
  public noNotifications: string;
  public checkAll: string;
  public retryLabel: string;
  public casUrl: string;
  public canGoToCas: boolean;
  public isCHYSubscription: boolean;
  public activeSubmenu: string;
  public globalInstance: any;
  public hasErrors: boolean;
  public translations: Object;
  public photoUrl: string;

  constructor(private translate: TranslateService, private authenticationService: AuthenticationService, private router: Router,
              private subscriptionService: SubscriptionManageService, private notifPaneActionService: NotificationPaneActionService) {
      super();
      this.setTranslations();
      this.casUrl = environment.casUrl;
    }

  public ngOnInit() {
    setTimeout(() => {
      this.setTranslations();
    });
    this.countErrors = 0;
    this.countOthers = 0;
    this.hasErrors = false;
    this.verifyAccess('go_to_cas');
    this.photoUrl = 'assets/images/users/blank-profile-picture.webp';
    const currentUserSubs = this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      if (x && x.user) {
        const info = x['current'] && x['current'].firstName ? `${x['current'].firstName} ${x['current'].lastName}` : x.user.email;
        this.userName = x.user.displayName ? x.user.displayName : info;
        this.userEmail = x.user.email;
        this.photoUrl = x.user['photoURL'] ? x.user['photoURL'] : this.photoUrl;
      }
    });
    super.addSubscriptions(currentUserSubs);

    const routerSubs =  this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((navigation: any) => {
      const submenuLinks = this.profileSubmenu.map(submenu => submenu.link);
      if (!submenuLinks.find(link => navigation.url.includes(link))) {
        this.activeSubmenu = null;
      }
      if (navigation.urlAfterRedirects) {
        let deploymentId = null;
        let auditsLink = null;
        if (navigation.urlAfterRedirects.indexOf('/deployment/') > -1) {
          deploymentId = navigation.urlAfterRedirects.split('/deployment/');
          deploymentId = navigation.urlAfterRedirects.split('/');
          auditsLink ='/audits';
        } else {
           deploymentId = navigation.urlAfterRedirects.split('/');
           auditsLink = '/audits';
        }
        this.setTranslations();
        this.links =
        [{id: 'audits', link: auditsLink , icon: 'mdi-format-list-bulleted',
          tooltip: this.translate.instant('header_navigation.AUDITS')}];
        }
    });
    super.addSubscriptions(routerSubs);

    this.subscriptionService.currentSubscription.subscribe(subscription => {
      if (subscription) {
        this.isCHYSubscription = subscription.billingProviderCode === 'CHY';
      }
    });

    this.notifPaneActionService.notificationInfo.subscribe(info => {
      if (info.hasErrors) {
        this.countErrors = info.count || 0;
      } else {
        this.countOthers = info.count || 0;
      }
    });
  }

  public openSideNav(): void {
    this.openSideBar.emit(true);
  }

  public logout() {
    this.authenticationService.signOut();
  }

  public onClickProfileSubmenu(subMenuId: string): void {
    this.activeSubmenu = subMenuId;
    const withTabSubmenus = ['manage', 'subscription'];
    const mapSubmenuIdKey = { manage: 'Manage', subscription: 'Subscription' };
    if (withTabSubmenus.includes(subMenuId)) {
      sessionStorage.setItem(`navigationInside${mapSubmenuIdKey[subMenuId]}`, 'false');
    }
  }

  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
    this.logutLabel = this.translate.instant('header_navigation.LOG_OUT');
    this.notificationsTooltip = this.translate.instant('header_navigation.NOTIFICATIONS');
    this.noNotifications = this.translate.instant('header_navigation.NO_NOTIFICATIONS');
    this.checkAll = this.translate.instant('header_navigation.CHECK_ALL');
    this.profileTooltip = this.translate.instant('header_navigation.PROFILE');
    this.feedbackTooltip = this.translate.instant('header_navigation.FEEDBACK');
    this.helpCenterText = this.translate.instant('header_navigation.HELP_CENTER');
    this.helpTooltip = this.translate.instant('header_navigation.HELP');
    this.integrationsLabel = this.translate.instant('header_navigation.INTEGRATIONS');
    this.retryLabel = this.translate.instant('header_navigation.RETRY');
    this.links = [{id: 'audits', link: '/audits' , icon: 'mdi-format-list-bulleted',
                   tooltip: this.translate.instant('header_navigation.AUDITS')}];
    this.profileSubmenu = [
      { id: 'myaccount', link: '/profile/my-account', icon: 'person', tooltip: this.translate.instant('header_navigation.MY_ACCOUNT')},
      { id: 'manage', link: '/profile/partner', icon: 'settings', tooltip: this.translate.instant('header_navigation.MANAGE')},
      { id: 'subscription', link: '/subscription', icon: 'sell', tooltip: this.translate.instant('header_navigation.SUBSCRIPTION')},
      { id: 'integrations', link: '/integrations', icon: 'device_hub',
        tooltip: this.translate.instant('header_navigation.INTEGRATIONS')}
    ];

    this.translations = {
      auditTooltip: this.translate.instant('header_navigation.AUDITS'),
      helpTooltip: this.translate.instant('header_navigation.HELP'),
      notificationTooltip: this.translate.instant('header_navigation.NOTIFICATIONS')
    };
  }
  private verifyAccess(feature: string): void {
    const earlyAccess = JSON.parse(sessionStorage.getItem('earlyaccess'));
    this.canGoToCas = _.find(earlyAccess, (access: any) => access.feature.toLowerCase() === feature.toLowerCase()) ? true : false;
  }
}
