export const locale = {
  lang: 'es',
  'configure_region_certificate': {
    'SSL_CERTIFICATE': 'Certificado SSL',
    'UPLOAD': 'Subir',
    'REGION_DATA_SAVED': 'Datos guardados para la región: {{region}}',
    'PASSWORD': 'Contraseña',
    'MAX_SIZE_VALIDATION': 'Tamaño de archivo excedido. Límite: 2 Mb.',
    'EMPTY_FILE': 'Fichero vacío',
    'FORMAT_VALIDATION': 'Sólo se permiten archivos del tipo Intercambio de Información Personal (PFX).',
    'ERROR_REQUIRED': 'Campo requerido',
    'ERROR_UPLOAD_NEW': 'Seleccione un nuevo certificado',
    'ERROR_PATTERN': 'Campo no válido',
    'CERTIFICATE_DESCRIPTION': `Cuando se utiliza el nombre DNS externo predeterminado, un certificado SSL
                                no es requerido. Si especifica un nombre DNS externo personalizado,
                                debe proporcionar un certificado SSL emitido por un público
                                Autoridad certificada como Verisign o GoDaddy.`,
    'PROVIDE_ITOPIA_TEXT' : 'Proporcionado por itopia'
  }
};
