import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'underscore';

import { GpuItem, MachineType } from 'src/app/models';

const allowedImages = ['windows-2016', 'windows-server-2016', 'windows-2019', 'windows-server-2019',
                       'windows-2022', 'windows-server-2022', 'Windows Server 2016', 'Windows Server 2019', 'Windows Server 2022'];
const gbToMb = 1024;
const decimal = 10;
const validateMachineType = function(objGraphic: GpuItem, machineType: MachineType, zoneId: number,
                                     validMtObj: { isValidRestrictions: boolean, isShared: boolean, notZoneRestriction: boolean }): void {
    const instanceSelected = machineType.name.toLowerCase();
    if (instanceSelected !== 'g1-small' && instanceSelected !== 'f1-micro' && instanceSelected !== 'custom') {
        validMtObj.isShared = false;
        validMtObj.notZoneRestriction = true;
        _.each(objGraphic.restrictions, (restriction: any) => {
            if (!validMtObj.isValidRestrictions) {
                if (zoneId === restriction.zoneId) {
                    validMtObj.notZoneRestriction = false;
                    if (machineType.cpu >= restriction.minCpu && machineType.cpu <= restriction.maxCpu &&
                        parseInt(machineType.ram, decimal) / gbToMb >= restriction.minRam &&
                        parseInt(machineType.ram, decimal) / gbToMb <= restriction.maxRam) {
                            validMtObj.isValidRestrictions = true;
                    }
                }
            }
        });
    } else if (instanceSelected === 'custom') {
        const core = machineType.cpu || 1;
        const ram = parseInt(machineType.ram, decimal) / gbToMb || 1;
        if (core && ram && objGraphic) {
            validMtObj.notZoneRestriction = true;
            _.each(objGraphic.restrictions, (restriction: any) => {
                if (!validMtObj.isValidRestrictions) {
                    if (zoneId === restriction.zoneId) {
                        validMtObj.notZoneRestriction = false;
                        if (core >= restriction.minCpu && core <= restriction.maxCpu &&
                            ram >= restriction.minRam && ram <= restriction.maxRam) {
                                validMtObj.isValidRestrictions = true;
                        }
                    }
                }
            });
        }
    } else {
        validMtObj.isShared = true;
    }
};

export const validateGpuInstance = function (imageName: string, machineType: MachineType,
                                             zoneId: number, activeSoleTenat?: boolean): ValidationErrors | null {
    return (control: AbstractControl) => {
        if (!control.value) {
           return null;
        }
        const objGraphic = new GpuItem(control.value);

        const isInvalidImage = imageName ? !_.some(allowedImages, img => imageName.toLowerCase().includes(img.toLowerCase())) : false;

        const validMtObj = { isValidRestrictions: true, isShared: false, notZoneRestriction: false };
        if (machineType && zoneId) {
            validMtObj.isValidRestrictions = false;
            validateMachineType(objGraphic, machineType, zoneId, validMtObj);
        }

        const noGpuSupport = !!(machineType && !machineType.gpuSupport);
        const isInvalid = isInvalidImage || !validMtObj.isValidRestrictions || noGpuSupport ||
                          validMtObj.isShared || validMtObj.notZoneRestriction || activeSoleTenat;
        if (isInvalid) {
            return {
                image: isInvalidImage,
                machineTypeShared: validMtObj.isShared,
                machineTypeRestriction: !validMtObj.isValidRestrictions,
                machineTypeN1: noGpuSupport,
                soleTenant: activeSoleTenat,
                zone: validMtObj.notZoneRestriction
            };
        }
        return null;
    };
};
