import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'underscore';

// tslint:disable-next-line:variable-name
export const PasswordStrengthValidator = function (control: AbstractControl): ValidationErrors | null {
const value: string = control.value || '';
if (!value) {
    return null;
  }
const userNameInPassword = function(user, password) {
    let result = false;
    if (user && user.userName) {
        const userName = user.userName.split('@')[0];
        const namesAndUsername = (user.firstName + ' ' + user.lastName).toLowerCase().split(' ');
        namesAndUsername.push(userName);
        if (user.lastName.length === 1) {
            result = false;
            namesAndUsername.splice(1, 1);
        }
        if (user.firstName.length === 1 ) {
            result = false;
            namesAndUsername.splice(0, 1);
        }
        if (password) {
            _.each(namesAndUsername, function(param) {
                if (password.toLowerCase().indexOf(param) > -1 && param.length > 2) {
                    result = true;
                }
            });
        }
    }
    return result;
};

const upperCaseCharacters = /[A-Z]+/g;
const lowerCaseCharacters = /[a-z]+/g;
const numberCharacters = /[0-9]+/g;
const specialCharacters = /[0-9a-zA-Z\@\#\%\^\&\=\+\>\<\!\|\*]+/;
const containsUserName = userNameInPassword(control.parent.value['user'], value);
if (upperCaseCharacters.test(value) === false || lowerCaseCharacters.test(value) === false
|| numberCharacters.test(value) === false || !specialCharacters.test(value) === true || containsUserName) {
return {
  pattern: containsUserName ? 'Cannot contain username or user\'s first or last name' : 'Invalid password format'
};
}
};
