import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'underscore';

export const cloudAppV2RelationsValidator = function (msg: string): ValidationErrors | null {
  return (control: AbstractControl) => {
    const value: { CloudUserIds: number[], SecurityGroupIds: number[], AllCollectionUsers: boolean } = control.value;
    if (!value) {
      return null;
    }

    if (!value.AllCollectionUsers &&
      _.isEmpty(value.CloudUserIds) && _.isEmpty(value.SecurityGroupIds)) {
        return {
          required: msg
        };
      }
};
};
