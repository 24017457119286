import { Injectable } from '@angular/core';
import { throwError } from 'rxjs/internal/observable/throwError';

import { environment } from 'src/environments/environment';

declare const gapi: any;
const pageSize = 200;

@Injectable({
  providedIn: 'root'
})
export class GmanagmentService {
  public service = 'servicemanagement.googleapis.com';

  constructor() { }

  public loadGManagment(): void {
    try {
      return gapi.client.load('servicemanagement', environment.gapi.apiVersion).then(
        () => {
          return true;
        }
      );
    } catch (exception) {
      throwError(exception);
      return;
    }
  }
  public load(): Promise<Object> {
    try {
      return gapi.client.load('servicemanagement', environment.gapi.apiVersion);
    } catch (exception) {
      throwError(exception);
      return;
    }
  }
  public activate(consumer) {
    const params = {
      serviceName: this.service,
      consumerId: consumer
    };
    const request = gapi.client.servicemanagement.services.enable(params);
    return request.then(function (response) {
      return Promise.resolve(response.result);
    }).catch(function (reason) {
      reason.request = {
        name: 'servicemanagement.googleapis.com/v1/services/bigquery-json.googleapis.com:enable',
        params: params
      };
      return Promise.reject(reason);
    });
  }
  public listServices(consumer) {
    const params = {
      pageSize: pageSize,
      consumerId: consumer
    };
    const request = gapi.client.servicemanagement.services.list(params);
    return request.then(function (response) {
      return Promise.resolve(response.result);
    }).catch(function (reason) {
      const requestObj = {
        name: 'servicemanagement.googleapis.com/v1/services',
        params: params
      };
      if (reason) {
        reason.request = requestObj;
      } else {
        reason = { request: requestObj };
      }
      return Promise.reject(reason);
    });
  }
}
