export const locale = {
  lang: 'en',
  'configure_region_server_roles' : {
    'SECTION_TITLE': 'Infrastructure roles',
    'DOMAIN_CONTROLLER_TITLE': 'Active Directory Domain Controllers:',
    'DOMAIN_CONTROLLER_DESCRIPTION': `This deployment uses Google Managed Service for Microsoft Active Directory.
                                      Domain controllers are not required.`,
    'DOMAIN_CONTROLLER_DESCRIPTION_ADEXIST': `This deployment uses existing domain. Domain controllers are not required.`,
    'BROKER_TITLE': 'Remote Desktop Connection Brokers:',
    'BROKER_DESCRIPTION': `Microsoft SQL is required to support Hight Availability for the RD Broker role.
                           A Google Managed SQL instance will be created for this region.`,
    'GATEWAY_TITLE': 'Remote Desktop Gateway Servers:',
    'GATEWAY_DESCRIPTION': `A Google Load Balancer will be created for this region.`,
    'ERROR_PATTERN': 'The value in this field is invalid'

  }
};
