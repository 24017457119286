import { AbstractControl, ValidationErrors } from '@angular/forms';
export const fqdnValidator = function (control: AbstractControl): ValidationErrors | null {
  const value: string = control.value || '';
  if (!value) {
    return null;
  }
  const expression = new RegExp(/(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)/);
  if (expression.test(value) === false) {
    return { pattern : 'Invalid format' };
  }
};
