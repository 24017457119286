export class Certificate {
    public name: string;
    public data: string;
    public password: string;
    constructor(data ? : any) {
        this.name = data && data.name || '';
        this.data = data && data.data || '';
        this.password = data && data.password || '';
    }
}
