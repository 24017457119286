import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'underscore';

import { GpuCard, MachineType } from 'src/app/models';

const allowedImages = ['windows-2016', 'windows-2019', 'windows-2022', 'Windows Server 2016',
                       'Windows Server 2019', 'Windows Server 2022', 'Windows 10 (BYOL)', 'Windows 10', 'windows-10'];
export const validateGpuImageMachineType =
function (image: string, machineType: MachineType, numberGpu: number): ValidationErrors | null {
    return (control: AbstractControl) => {
        const value = control.value ? new GpuCard(control.value) : null;
        if (!value) {
           return null;
        }

        const isInvalidImage = image && _.isString(image)
        ? !_.some(allowedImages, img => image.toLowerCase().includes(img.toLowerCase()))
        : true;

        const isInvalidMtShared = machineType && machineType.description && machineType.description.toLowerCase().includes('shared');

        const gbToMb = 1024;
        let validMtGpu = null;
        if (machineType) {
          const mtCpu = parseInt(machineType.cpu, 10);
          const mtRam = parseInt(machineType.ram, 10) / gbToMb;

          validMtGpu = _.find(value.configurations, cfg => {
            return cfg.restrictions && cfg.gpu === numberGpu &&
            _.some(cfg.restrictions, r => mtCpu >= r.minCpu && mtCpu <= r.maxCpu && mtRam >= r.minRam && mtRam <= r.maxRam);
          });
        }
        const isInvalidMt = (machineType && !machineType.gpuSupport) || !validMtGpu;
        const isInvalid = isInvalidImage || isInvalidMtShared || isInvalidMt;
        if (isInvalid) {
          return {
            image: isInvalidImage,
            machineTypeShared: isInvalidMtShared,
            machineTypeN1: machineType && !machineType.gpuSupport,
            machineType: !validMtGpu
          };
        } else {
          return null;
        }
    };
}
