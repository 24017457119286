import { CommonModule } from '@angular/common';

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule, MatMenuModule } from '@angular/material';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ClipboardModule } from 'ngx-clipboard';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { ModalLayoutModule } from 'src/app/shared/modal-layout/modal-layout.module';
import {FormButtonsV2Module} from '../shared/form-buttons-v2/form-buttons-v2.module';
import { AutoUnsubscribeModule } from './../shared/auto-unsubscribe/auto-unsubscribe.module';
import { FormButtonsModule } from './../shared/form-buttons/form-buttons.module';

import { FeedbackComponent } from './feedback/feedback.component';
import { ProServicesComponent } from './pro-services/pro-services.component';
import { SupportTokenComponent } from './support-token/support-token.component';
import { SupportComponent } from './support/support.component';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, TranslateModule, FormButtonsModule, AutoUnsubscribeModule,
              NgbModule, FormsModule, ReactiveFormsModule, ClipboardModule, MatIconModule, MatMenuModule,
              ModalLayoutModule, NgSelectModule, InternationalPhoneNumberModule.forRoot(), FormButtonsV2Module],
    declarations: [FeedbackComponent, SupportTokenComponent, SupportComponent, ProServicesComponent],
    exports: [FeedbackComponent, SupportTokenComponent, SupportComponent, ProServicesComponent]
})
export class HelpcenterModule {
}
