import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BooleanDto, StringDto, ValueDto } from 'src/app/models';
import { ApisServices } from 'src/app/models/enums/apis-services';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApisCheckManagerService {

  constructor(private http: HttpClient) { }

  public isEnabledApi(deploymentId: number, name: ApisServices, skeepFailure: boolean): Observable<StringDto> {
    const params = new HttpParams().set('skeepFailure', skeepFailure.toString());
    return this.http.get<StringDto>(`${environment.apiUrl}deployments/${deploymentId}/api/${name}/state`, {params});
  }

  public enableApi(deploymentId: number, name: ApisServices, skeepFailure: boolean): Observable<BooleanDto> {
    const params = new HttpParams().set('skeepFailure', skeepFailure.toString());
    return this.http.post<BooleanDto>(`${environment.apiUrl}deployments/${deploymentId}/api/${name}/enable`, {}, {params});
  }
}
