import { CloudAppV2RelationType } from '../enums/cloud-app-v2-relation-type';

export class CloudAppV2Relation {
  public id: number;
  public name: string;
  public type: CloudAppV2RelationType;
  public domain: string;

  constructor(data ? : any) {
    this.id = data && data.id ? data.id : 0;
    this.name = data && data.name ? data.name : '';
    this.type = data && data.type ? data.type : CloudAppV2RelationType.AllCollectionUsers;
    this.domain = data && data.domain ? data.domain : '';
}
}
