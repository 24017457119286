export class BrandOptions {
    public brand: boolean;
    public theme: string;
    public primaryColor: string;
    public secondaryColor: string;

    constructor(data?: any) {
        if (data) {
            data = JSON.parse(data);
        }
        this.brand = data && data.brand ? data.brand : false;
        this.theme = data && data.theme ? data.theme : '';
        this.primaryColor = data && data.primaryColor ? data.primaryColor : '';
        this.secondaryColor = data && data.secondaryColor ? data.secondaryColor : '';
    }
}
