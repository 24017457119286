import { AbstractControl, ValidationErrors } from '@angular/forms';

// custom validator to check that two fields match
export const mustMatch = function (nameDeployment: string): ValidationErrors | null {
  return (control: AbstractControl) => {
        const verifyMatch = (name) => {
            if ( name.toLocaleLowerCase()  !== nameDeployment.toLocaleLowerCase()  ) {
                return false;
            } else {
                return true;
            }
        };

        const valid = verifyMatch(control.parent.value['Name']);
        if (!valid) {
            return {
                mustMatch: true
            };
        }
    };
};
