import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameAndCodeDeployment'
})
export class NameAndCodeDeploymentPipe implements PipeTransform {
  
  /**
   * Capitalize text
   * @param value String to transform
  */
  transform(name: string, code: string): any {
    return  `${name} (${code})`;
  }

}
