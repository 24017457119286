import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { environment } from './../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {
  constructor(private http: HttpClient) {
  }

  public resetAdminPassword(data: any): Observable<any> {
    const params = new HttpParams().set('skeepFailure', 'true');
    const body = {
      'companiesId': data.companiesId,
      'password': data.password,
      'allClients': data.allClients
    };
    return this.http.put<any>(`${environment.apiUrl}admins/${data.adminId}/resetAdPassword`, body, {params});
  }

  public resetUserPassword(data: any): Observable<any> {
    const params = new HttpParams().set('skeepFailure', 'true');
    return this.http.post<any>(`${environment.apiUrl}deployments/${data.deploymentId}/cloudUsers/${data.cloudUserId}/resetPassword`,
      data.passwordModel, {params});
  }

}
