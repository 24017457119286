export class Pes {
    public pesId: number;
    public subnet: string;
    public projectId: string;
    public projectName: string;
    public projectNumber: string;
    public pesStatusId: number;
    public deploymentId: number;
    constructor(data?: any) {
      this.pesId =  data && data.pesId ? data.pesId : 0;
      this.subnet =  data && data.subnet ? data.subnet : '';
      this.projectId =  data &&  data.projectId ? data.projectId : '';
      this.projectName =  data &&  data.projectName ? data.projectName : '';
      this.deploymentId =  data &&  data.deploymentId ? data.deploymentId : 0;
      this.pesStatusId =  data &&  data.pesStatusId ? data.pesStatusId : 0;
    }

    public isEmpty(): boolean {
          return this.pesId === 0 || this.subnet === '' || this.projectName === '' || this.projectId === '';
    }
}
