import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdFiltersService {

  public disabledSearch$: BehaviorSubject<boolean>;

  constructor(private http: HttpClient) {
    this.disabledSearch$ = new BehaviorSubject<boolean>(false);
  }

  public getNetwork(deploymentId: number): Observable<Object> {
    return this.http.get<Object>(`${environment.apiUrl}deployments/${deploymentId}/networks`);
  }

  public setDisabledSearch(disabled: boolean) {
    this.disabledSearch$.next(disabled);
  }
}
