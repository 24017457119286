import { Directive, ElementRef, Input, OnInit } from '@angular/core';

import { AuthGuard } from '../../../security/auth.guard';
@Directive({
  selector: '[appSecurityControl]',
})

export class SecurityControlDirective implements OnInit {
  @Input()
  public resource: string;
  @Input()
  public action: string;
  @Input()
  public disable: boolean;
  constructor(private el: ElementRef, private authGuard: AuthGuard) {
 }
  public ngOnInit() {
  if (this.disable) {
    this.disableElement();
  } else {
    this.removeElement();
  }
}

  private removeElement(): void {
   if (!this.authGuard.securityCheck(this.resource, this.action)) {
    this.el.nativeElement.remove();
   }
 }
  private disableElement(): void {
  if (!this.authGuard.securityCheck(this.resource, this.action)) {
   this.el.nativeElement.disabled = true;
  }
}

}
