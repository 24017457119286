import { Directive, ElementRef, HostListener, Input, OnInit, OnChanges } from '@angular/core';

@Directive({
  selector: '[appAutosize]'
})
export class AutosizeDirective implements OnInit, OnChanges {
  @Input()
  public isEnabled: boolean;

  @HostListener('input', ['$event.target'])
  public onInput(): void {
    this.adjust();
  }
  constructor(public element: ElementRef) {
  }
  public ngOnInit() {
    this.adjust();
  }
  public ngOnChanges(){
    this.adjust();
  }
  public adjust(): void {
    this.element.nativeElement.style.overflow = 'hidden';
    this.element.nativeElement.style.height = 'auto';
    this.element.nativeElement.style.height = this.element.nativeElement.scrollHeight + 'px';
  }

}
