export class ComponentDashboard {
  public created_at: string;
  public description: string;
  public group: boolean;
  public group_id: string;
  public id: string;
  public name: string;
  public only_show_if_degraded: boolean;
  public page_id: string;
  public position: number;
  public showcase: boolean;
  public start_date: string;
  public status: string;
  public updated_at: string;
}
