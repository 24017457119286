import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

declare const gapi: any;
const api = 'osconfig';
const version = 'v1';

@Injectable({
  providedIn: 'root'
})
export class GOsConfig {
  public service = 'osconfig.googleapis.com';

  constructor() { }

  public loadGOsConfig(): void {
        try {
        return gapi.client.load(api, version);
        } catch (exception) {
        throwError(exception);
        return;
        }
    }
  public activate(consumer: string) {
        try {
            const params = {
                serviceName: this.service,
                consumerId: consumer
            };
            const request = gapi.client.servicemanagement.services.enable(params);
            return request.then((response) => {
                if (response && response.result && response.result.name) {
                    return Promise.resolve(response.result.name);
                }
                Promise.resolve(false);
            });
        } catch (ex) {
            Promise.resolve(ex);
        }

    }
}
