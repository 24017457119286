import { AbstractControl, ValidationErrors } from '@angular/forms';
export const emailValidator = function (control: AbstractControl): ValidationErrors | null {
  const value = control.value || '';
  if (!value) {
    return null;
  }
  const expression = new RegExp(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/);
  if (expression.test(value.value) === false) {
    return { pattern : 'Invalid format' };
  }
  return null;
};