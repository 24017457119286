import { AbstractControl, ValidationErrors } from '@angular/forms';
export const labelsFormatValidator = function (control: AbstractControl): ValidationErrors | null {
  const value: string = control.value || '';
  if (!value) {
    return null;
  }
  const rangeRegex = new RegExp(/^[a-zàèìòùáéíóúýâêîôûðãñõäëïöüÿåæœßçø][a-z0-9-_àèìòùáéíóúýâêîôûðãñõäëïöüÿåæœßçø]{0,62}$/);
  if (rangeRegex.test(value) === false) {
    return { pattern : 'Invalid format' };
  }
}
