import { Injectable } from '@angular/core';
import * as _ from 'underscore';


@Injectable({
  providedIn: 'root'
})
export class OperationExpressionService {
  public expressionType: any;

  constructor() {
    this.expressionType = {
      EQUALS: 0,
      GREATER_THAN: 1,
      LESS_THAN: 2,
      GREATER_THAN_OR_EQUAL: 3,
      LESS_THAN_OR_EQUAL: 4,
      CONTAINS: 5,
      START_WITH: 6,
      END_WITH: 7
    };
  }

  public match(item, name, value, expression) {
    expression = parseInt(expression, 0);

    switch (expression) {
      case this.expressionType.EQUALS:
        return item[name] === value;

      case this.expressionType.GREATER_THAN:
        const valgreater = item[name];
        if (_.isNumber(valgreater)) {
          return valgreater > value;
        }
        return false;

      case this.expressionType.LESS_THAN:
        const valless = item[name];

        if (_.isNumber(valless)) {

          return valless < value;
        }
        return false;
      case this.expressionType.GREATER_THAN_OR_EQUAL:

        const valgreaterthan = item[name];

        if (_.isNumber(valgreaterthan)) {

          return valgreaterthan >= value;
        }

        return false;

      case this.expressionType.LESS_THAN_OR_EQUAL:

        const vallessthan = item[name];

        if (_.isNumber(vallessthan)) {

          return vallessthan <= value;
        }

        return false;

      case this.expressionType.CONTAINS:

        const valcontains = item[name];

        if (_.isString(valcontains)) {
          return valcontains.indexOf(value) !== -1;
        }

        return false;

      case this.expressionType.START_WITH:

        const valstart = item[name];

        if (_.isString(valstart)) {

          return valstart.startsWith(valstart, value);
        }

        return false;

      case this.expressionType.END_WITH:

        const valend = item[name];

        if (_.isString(valend)) {

          return valend.endsWith(valend, value);
        }

        return false;

      default:
        return false;
    }
  }
}
