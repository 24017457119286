import { SettingItem } from './setting-item';

export class SettingsValues {
    public dis: SettingItem<string>[];
    public lods: SettingItem<string>[];
    public dbd: SettingItem<string>[];
    public dr: SettingItem<string>[];
    public oc: SettingItem<string>[];
    public sws: SettingItem<string>[];

    constructor(data?: any) {
        this.dis = data && data.dis ? this.getSettingItemsString(data.dis) : [];
        this.lods = data && data.lods ? this.getSettingItemsString(data.lods) : [];
        this.dbd = data && data.dbd ? this.getSettingItemsString(data.dbd) : [];
        this.dr = data && data.dr ? this.getSettingItemsString(data.dr) : [];
        this.oc = data && data.oc ? this.getSettingItemsString(data.oc) : [];
        this.sws = data && data.sws ? this.getSettingItemsString(data.sws) : [];
    }

    private getSettingItemsString(jsonString: string): SettingItem<string>[] {
        const obj: Object[] = JSON.parse(jsonString);
        return obj.map(item => new SettingItem<string>(item));
    }
}
