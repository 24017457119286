import { Apis } from 'src/app/models';

export const subnetExample = 'e.g 10.0.1.0/20';
export const  vpcKey = 'vpc';
export const externalDnsDom = '.cloudvdi.net';
export const two = 2;
export const maxValQuantity = 4;
export const minValQuantity = 1;
export const apis = [Apis.GSqlAdmin, Apis.GNetworking];
export const errorRegionMaximum = 'CAS can add a maximum of three (3) regions at a time';
