import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnInit, Renderer2 } from '@angular/core';
@Directive({
  selector: '[appVpnTunnelsStatus]'
})
export class VpnTunnelsStatusDirective implements OnInit {
  @Input()
  public tunnelStatus: string;

  constructor(private el: ElementRef, private renderer: Renderer2, @Inject(DOCUMENT) private document) { }
  public ngOnInit() {
    this.getTunnelStatus(this.tunnelStatus);
  }
  private getTunnelStatus(status: string) {
    const child = document.createElement('span');
    switch (status.toLowerCase()) {
      case 'up':
        child.className = 'fas fa-circle text-success m-r-2';
        break;
      case 'invalid':
        child.className = 'fas fa-exclamation-triangle text-danger m-r-2';
        break;
      case '':
        break;
      default:
        child.className = 'fas fa-circle text-danger m-r-2';
        break;
    }
    this.renderer.insertBefore(this.el.nativeElement, child, this.el.nativeElement.firstChild);
  }
}
