export class UssInstance {
  public instanceId: number;
  public name: string;
  public isOnline: boolean;
  constructor(data?: any ) {
    this.instanceId = data && data.instanceId ? data.instanceId : 0;
    this.name = data && data.name ? data.name : '';
    this.isOnline = data && data.isOnline ? data.isOnline : false;
  }
}
