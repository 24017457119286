import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule } from '@angular/material';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/core/shared/directives/directives.module';
import { FormButtonsModule } from '../form-buttons/form-buttons.module';

import { AutoUnsubscribeModule } from '../auto-unsubscribe/auto-unsubscribe.module';
import { ModalLayoutModule } from '../modal-layout/modal-layout.module';
import { ResetUserPasswordComponent } from './reset-user-password.component';

@NgModule({
    imports: [CommonModule, NgbModule, TranslateModule, ReactiveFormsModule, FormsModule,
              FormButtonsModule, AutoUnsubscribeModule, ModalLayoutModule,
              MatIconModule, MatButtonModule, MatFormFieldModule, MatInputModule, DirectivesModule
            ],
    declarations: [ResetUserPasswordComponent],
    exports: [ResetUserPasswordComponent]
})
export class ResetUserPasswordModule {
}
