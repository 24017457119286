import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

const textNode = 3;
@Directive({
  selector: '[appAdminsStatus]'
})
export class AdminsStatusDirective implements OnInit , OnChanges {
  @Input()
  public isActive: boolean;
  @Input()
  public isPending: boolean;
  @Input()
  public displayStatus: string;
  @Input()
  public renderStatus: boolean;

  constructor(private el: ElementRef, private renderer: Renderer2, @Inject(DOCUMENT) private document) { }

  public ngOnInit() {
    this.getAdminstatus(this.isActive, this.isPending, this.renderStatus);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.renderStatus) {
      this.renderStatus = changes.renderStatus.currentValue;
      this.getAdminstatus(this.isActive, this.isPending, this.renderStatus);
    }
  }
  private getAdminstatus(isActive: boolean, isPending: boolean, renderStatus: boolean) {
    const child = document.createElement('span');
    const childElements = this.el.nativeElement.childNodes;
    for (const children of childElements) {
      if (children.nodeType !== textNode && children.id !== 'copy') {
        this.renderer.removeChild(this.el.nativeElement, children);
      }
    }
    if (!renderStatus) {
      child.innerHTML = `<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="loading m-r-2">
        <circle cx="50" cy="50" r="45"/>
      </svg>`;
    } else {

      if (!isActive) {
        child.className = 'fa fa-circle text-dark m-r-2';
      } else if (isPending) {
        child.className = 'fas fa-circle c-orange m-r-2';
      }
  }
    child.setAttribute('title', this.displayStatus);

    this.renderer.insertBefore(this.el.nativeElement, child, this.el.nativeElement.firstChild);
  }
}
