export const locale = {
  lang: 'en',
  header_navigation : {
    AUDITS: `Audits`,
    FEEDBACK: `Feedback`,
    HELP: `Help`,
    HELP_CENTER: `Help center`,
    NOTIFICATIONS: `Notifications`,
    PROFILE: `Profile`,
    MY_ACCOUNT: 'My Account',
    MANAGE : `Manage Organization`,
    SUBSCRIPTION: 'Subscription',
    ADMINS: `Admins`,
    CLOUD_CALCULATOR: `Cloud Calculator`,
    SUSCRIPTION: `Subscription`,
    INTEGRATIONS: `Integrations`,
    LOG_OUT: 'Sign Out',
    CHECK_ALL: 'Go to notifications',
    RETRY : 'Retry',
    NO_NOTIFICATIONS: `You don't have new notifications`,
    SUPPORT: 'Support',
    SUCCESFULL_AUTOSCALING: 'Schedule succesfully configured',
    GO_TO_NOTIFICATIONS: 'Go to Notifications'
  }
};
