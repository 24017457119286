import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import * as _ from 'underscore';

import { BinaryExpressionService, OperationExpressionService, UnaryExpressionService } from 'src/app/core/shared/filters';
import { DeploymentsService } from 'src/app/deployments/deployments.service';
import { Datatable, EarlyAccessFeature, ListDto, PropertyObject, SecurityGroup } from 'src/app/models';
import { AutoUnsubscribeComponent } from '../../auto-unsubscribe/auto-unsubscribe.component';

import { locale as english } from './i18n/multiselect-groups.en';
import { locale as spanish } from './i18n/multiselect-groups.es';
import { EarlyAccessService } from 'src/app/core/security/early-access.service';

const onCloud = 3;
const pageSizeValue = 10;
@Component({
  selector: 'app-multiselect-groups',
  templateUrl: './multiselect-groups.component.html',
  styleUrls: ['./multiselect-groups.component.scss']
})
export class MultiselectGroupsComponent extends AutoUnsubscribeComponent implements OnInit, AfterViewInit {
  @Input()
  public deploymentId: number;
  @Input()
  public containerClass: string;
  @Input()
  public currentGroupIds: Array<number>;
  @Input()
  public isDetails: boolean;
  @Input()
  public extraParams: Array<Object>;
  @Input()
  public disableRows: Array<PropertyObject>;

  @Output()
  public selecteddGroups = new EventEmitter<Array<SecurityGroup>>();
  @Output()
  public isLoadingCloudGroups = new EventEmitter<boolean>();
  @Output()
  public loadedGroups = new EventEmitter<Array<Object>>();

  public groupsTitle: string;
  public isMultipleAdTrusts: boolean;
  public isLoadingGroups: boolean;
  public selectedGroups: Array<number>;
  public selectedGroupsRow: Array<SecurityGroup>;
  public selectModelGroups: Datatable<SecurityGroup> = new Datatable<SecurityGroup>();

  constructor(private deploymentsService: DeploymentsService, private translate: TranslateService,
              private operationExpression: OperationExpressionService,private unaryOperation: UnaryExpressionService,
              private binaryExpression: BinaryExpressionService, private earlyAccessService: EarlyAccessService,) {
                super();
                this.setCurrentLang();
  }

  public ngOnInit() {
    this.isMultipleAdTrusts = this.earlyAccessService.verifyAccess(EarlyAccessFeature.MultipleAdTrusts);
    this.containerClass = this.containerClass ? this.containerClass : '';
    this.selectedGroups = [];
    this.selectedGroupsRow = [];
    this.setTranslations();
  }
  public ngAfterViewInit() {
    this.setTranslations();
  }
  public activateItem(group: SecurityGroup): void {
      this.selectedGroups = this.selectedGroups && this.selectedGroups.length > 0 ? this.selectedGroups : [];
      if (this.currentGroupIds.indexOf(group.adGroupId) === - 1 ||
      this.selectedGroups.indexOf(group.adGroupId) === - 1) {
          this.selectedGroups.push(group.adGroupId);
          this.selectedGroupsRow.push(group);
          this.currentGroupIds.push(group.adGroupId);
      } else {
        this.selectedGroups.splice(this.currentGroupIds.indexOf(group.adGroupId), 1);
        this.selectedGroupsRow.splice(this.currentGroupIds.indexOf(group.adGroupId), 1);
        this.currentGroupIds.splice(this.currentGroupIds.indexOf(group.adGroupId), 1);
      }
      this.selecteddGroups.emit(this.selectedGroupsRow);
    }
  public getGroups($event): void {
    setTimeout(() => {
      this.isLoadingGroups = true;
    });
    const params = {
      pageIndex: $event.CurrentPageNumber + 1 || 1,
      pageSize: pageSizeValue,
      filter: [{
        'propertyName': 'ADGroupStatusId', 'operation': this.operationExpression.expressionType.EQUALS, 'value': onCloud,
        'toLower': true, 'unaryOperation': this.unaryOperation.expressionType.NONE,
        'binaryOperation': this.binaryExpression.expressionType.AND
      }]
    };
    if ($event.searchValue) {
      params.filter.push({
        'propertyName': 'Name', 'operation': this.operationExpression.expressionType.CONTAINS, 'value': $event.searchValue,
        'toLower': true, 'unaryOperation': this.unaryOperation.expressionType.NONE,
        'binaryOperation': this.binaryExpression.expressionType.AND
      });
    }
    if ($event.searchNameLocationValue) {
      if ($event.searchNameLocationValue.location) {
        params.filter.push({
          'propertyName': 'Domain', 'operation': this.operationExpression.expressionType.CONTAINS, 'value': $event.searchNameLocationValue.location,
          'toLower': true, 'unaryOperation': this.unaryOperation.expressionType.NONE,
          'binaryOperation': this.binaryExpression.expressionType.AND
        });
      }
      if ($event.searchNameLocationValue.name) {
        params.filter.push({
          'propertyName': 'Name', 'operation': this.operationExpression.expressionType.CONTAINS, 'value': $event.searchNameLocationValue.name,
          'toLower': true, 'unaryOperation': this.unaryOperation.expressionType.NONE,
          'binaryOperation': this.binaryExpression.expressionType.AND
        });
      }
    }
    if (!_.isEmpty(this.extraParams)) {
      _.each(this.extraParams, param =>  params.filter.push(param as any));
    }
    const getUsersSubs = this.deploymentsService.searchDeploymentGroups(this.deploymentId, params)
      .pipe(finalize(() => {
        this.isLoadingGroups = false;
        this.isLoadingCloudGroups.emit(this.isLoadingGroups);
      }))
      .subscribe((groups: ListDto<SecurityGroup>) => {
        const mapGroupsAllowProp = groups.list.map(group => {
          if (this.currentGroupIds && this.currentGroupIds.includes(group.adGroupId) ||
          this.selectedGroups && this.selectedGroups.includes(group.adGroupId)) {
            group.allow = true;
            if (!_.contains(this.selectedGroups, group.adGroupId)) {
              this.selectedGroups.push(group.adGroupId);
              this.selectedGroupsRow.push(group);
              this.setDisableGroup(group);
            }
          }
          if (this.isMultipleAdTrusts) {
            group.displayName = group.domain !== null ? group.name + ' ('+ group.domain + ')' : group.name;
          }
          return group;
        });
        this.selectModelGroups = {
          PageSize: pageSizeValue,
          TotalElements: groups.count,
          TotalPages: Math.ceil(groups.count / pageSizeValue),
          CurrentPageNumber: $event.CurrentPageNumber + 1,
          SortBy: 'name',
          SortDir: 'asc',
          Data: mapGroupsAllowProp
        };
        this.loadedGroups.emit(this.selectModelGroups.Data);

      });
    super.addSubscriptions(getUsersSubs);
  }
  public setCurrentLang(): void {
    const browserLang = this.translate.getBrowserLang();
    const mapLangI18nFile = { es: spanish, en: english };
    if (!mapLangI18nFile[browserLang]) {
      this.translate.use('en');
      this.translate.setTranslation('en', mapLangI18nFile['en']);
    } else {
      this.translate.use(browserLang);
      this.translate.setTranslation(browserLang, mapLangI18nFile[browserLang]);
    }
  }

  private setDisableGroup(group: SecurityGroup): void {
    if (this.disableRows !== undefined) {
      this.disableRows.forEach((element: PropertyObject) => {
      if ( group[element.property] === element.value ) {
         group.disabled = true;
         return; }
      });
    }

  }

  private setTranslations(): void {
    this.setCurrentLang();
    this.groupsTitle = this.translate.instant('multiselect_groups.USERS_GROUPS');
  }

}
