export class UserCurrentSessions {
   public cieloUserId : number;
   public userName : string;
   public isAccountLocked : boolean;
   public status : string;
   public isUserOnLine : boolean;
   public server : string;
   public cloudInstanceId : string;
   public loginIdentifier : string;
   public lastLogoff : string;
   public pendingUpdate : boolean;
   public externalUserId : string;
   public sessionId : string;
   public shadowSession : boolean;
   public logonTime : string;
   constructor(data ? : any) {
       this.cieloUserId = data && data.cieloUserId ? data.cieloUserId : 0;
       this.userName = data && data.userName ? data.userName : '';
       this.isAccountLocked = data && data.isAccountLocked ? data.isAccountLocked : false;
       this.status = data && data.status ? data.status : '';
       this.isUserOnLine = data && data.isUserOnLine ? data.isUserOnLine : false;
       this.server = data && data.server ? data.server : '';
       this.cloudInstanceId = data && data.cloudInstanceId ? data.cloudInstanceId : '';
       this.loginIdentifier = data && data.loginIdentifier ? data.loginIdentifier : '';
       this.lastLogoff = data && data.lastLogoff ? data.lastLogoff : '';
       this.pendingUpdate = data && data.pendingUpdate ? data.pendingUpdate : false;
       this.externalUserId = data && data.externalUserId ? data.externalUserId : '';
       this.sessionId = data && data.sessionId ? data.sessionId : '';
       this.shadowSession = data && data.shadowSession ? data.shadowSession : false;
       this.logonTime = data && data.logonTime ? data.logonTime : '';
   }
}
