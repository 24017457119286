export class DeploymentSize {
    public settingGroupId: number;
    public name: string;
    public description: string;
    public descriptionList: string[];
    public userLimitDescription: string;
    public minValue: number;
    public maxValue: number;
    public settings: Object;
    public code: string;
    constructor(data?: any) {
      this.settingGroupId =  data && data.settingGroupId ? data.settingGroupId : 0;
      this.name =  data && data.name ? data.name : '';
      this.description =  data &&  data.description ? data.description : '';
      this.descriptionList =  data &&  data.descriptionList ? data.descriptionList : [];
      this.userLimitDescription =  data &&  data.userLimitDescription ? data.userLimitDescription : '';
      this.minValue =  data &&  data.minValue ? data.minValue : 0;
      this.maxValue =  data &&  data.maxValue ? data.maxValue : 0;
      this.settings =  data &&  data.settings ? data.settings : null;
    }
}
