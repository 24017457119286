import { Injectable } from '@angular/core';
import { ajax } from 'rxjs/ajax';
import { Observable } from 'rxjs/Observable';
import * as _ from 'underscore';

import { GauthService } from './gauth.service';

const baseURl = `https://serviceusage.googleapis.com/v1/projects/:projectId/services`;
const serviceControlUrl = `https://servicecontrol.googleapis.com/v1/services/`;

@Injectable({
  providedIn: 'root'
})
export class GServiceUsageService {
  private authUser: any;
  private token: string;
  constructor(private gauthService: GauthService) {
    this.authUser = this.gauthService.getAuthUser();
    this.token = '';
    this.gauthService.user$.subscribe((user) => {
      if (user) {
        this.authUser = this.gauthService.getAuthUser();
        this.token = this.authUser.access_token;
      }
    });
  }

  public listEnabledServices(projectId: string): Observable<any> {
    let url = baseURl.replace(':projectId', projectId);
    url = `${url}?filter=state:ENABLED`;
    return ajax({
      url: url,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      body: {}
    });
  }
  public listDisabledServices(projectId: string): Observable<any> {
    let url = baseURl.replace(':projectId', projectId);
    url = `${url}?filter=state:DISABLED`;
    return ajax({
      url: url,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      body: {}
    });
  }

  public enableService(projectId: string, service: string): Observable<any> {
    let url = baseURl.replace(':projectId', projectId);
    url = `${url}/${service}:enable`;
    return ajax({
      url: url,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      body: {}
    });
  }
  public enableMultipleServices(projectId: string, services: string[]): Observable<any> {
    let url = baseURl.replace(':projectId', projectId);
    url = `${url}:batchEnable`;
    return ajax({
      url: url,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      body: {
        'serviceIds': services
      }
    });
  }

  public disableService(projectId: string, service: string): Observable<any> {
    let url = serviceControlUrl.replace(':projectId', projectId);
    url = `${url}/${service}:disable`;
    return ajax({
      url: url,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      body: {}
    });
  }
  public isEnable(projectId: string, service: string): boolean {
    const servicesEnabled = JSON.parse(sessionStorage.getItem('servicesEnabled'));
    if (servicesEnabled) {
      const mapped = servicesEnabled[projectId];
      if (mapped) {
        return _.contains(mapped, service);
      } else {
          return false;
      }
    } else {
      return false;
    }
  }

  public setEnabledServices(projectId: string, services: any[]): void {
    const originalServices = this.getEnabledServices() !== null ? this.getEnabledServices() : {};
    let servicesEnabled = _.toArray(services);
    servicesEnabled = _.pluck(servicesEnabled, 'name');
    servicesEnabled = _.map(servicesEnabled, (service: string) => {
          const index = service.lastIndexOf('/');
          return service  = service.substring(index + 1, service.length);
        });
    sessionStorage.setItem('servicesEnabled', JSON.stringify(Object.assign(originalServices, {[projectId]: servicesEnabled})));
  }
  public getEnabledServices(): any {
    return JSON.parse(sessionStorage.getItem('servicesEnabled'));
  }
}

