import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { FormButtonsModule } from './../form-buttons/form-buttons.module';

import { WarningModalAutoscalingComponent } from './warning-modal-autoscaling.component';

@NgModule({
  declarations: [WarningModalAutoscalingComponent],
  imports: [
    CommonModule, TranslateModule, FormButtonsModule, NgbModule, FormsModule, OwlDateTimeModule, OwlNativeDateTimeModule
  ],
  exports: [WarningModalAutoscalingComponent]
})
export class WarningModalAutoscalingModule { }
