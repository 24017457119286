export const locale = {
  lang: 'es',
  reset_password : {
    'RESET': 'Resetear contraseña',
    'NEW_PASS': 'Nueva contraseña',
    'CANCEL': 'Cancelar',
    'SAVE': 'Guardar',
    'SAVING': 'Guardando ...',
    'RESET_SUCCESS': 'Contraseña reseteada satisfactoriamente',
    'RESET_TOOLTIP': 'Requisitos para la complejidad de la contraseña',
    'ERROR_REQUIRED': 'Campo requerido',
    'CLOSE': 'Cerrar'
  }
};
