export class ScriptParams {
  public deploymentId : number;
  public title: string;
  public showSubtitles: boolean;
  public subtitles: string[];
  public link: string;
  public service: any;
  public action: any;
  public serviceCallback: any;

  constructor(data?: any) {
    if (data) {
        Object.keys(data).forEach(key => {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                this[key] = data[key];
            }
        });
    }
}
}
