export const locale = {
  lang: 'en',
  addon_subscription : {
    component: {
      USER: 'Users',
      USERS: 'Users',
      SERVER: 'Servers',
      SERVERS: 'Servers',
      INTEGRATION: 'Integrations',
      INTEGRATIONS: 'Integrations',
      ADMIN: 'Admin',
      ADMINS: 'Admins',
      ADMIN_ACCOUNTS: 'Admin accounts',
      DCO: 'Domain Controller',
      RDC: 'Redundant Domain Controller',
      SNA: 'Snapshot Automation',
      SUP: 'Server Uptime Scheduling',
      ALO: 'Audit Logs',
      DIS: 'Discovery',
      NDI: 'Network Discovery',
      INT: 'Integrations',
      RAP: 'Remote App',
      MUS: 'Spin up Multiple User Servers',
      RDS: 'RD Gateway',
      ATA: 'Task Automation',
      DMT: 'Data Migration Tool',
      UIN: 'Usage Analytics',
      VPN: 'Virtual Private Network',
      DVU: '1:1 User Server',
      SRT: 'Support',
      ASC: 'Customizable AutoScaling',
      GAA: 'Granular Admin Access',
      BRK: 'RD Connection Broker',
      RGTW: 'Redundant gateway',
      ADRDS: 'AD/RDS deployment',
      VDO: 'Virtual Desktop Orchestration',
      CSM: 'Cloud Server Manager',
      OPC: 'On-prem Catalog',
      UMHD: 'User Management and Help Desk Administration',
      GSSO: 'Gmail Single Sign-On',
      PSUP: 'Phone Support',
      AAC: 'Application Access Control',
      INS: 'Insights',
      MOB: 'Mobile Apps for iOS and Android',
      EADD: 'Extended active directory deployments',
      MRD: 'Multi region deployments',
      NOD: 'Number of Deployments',
      GWD: 'Graphic Workstation Deployments',
      GBUI: 'GCP Billing and Usage Insights',
      SEAT: 'Kind: Quantity-Based'
    },
    CANCEL: 'Cancel',
    BUY_ADDONS_TITLE: 'Oops! Sorry, you have reached the limit of {{addon}} for this subscription. Please buy to continue.',   
    BUY_ADDONS: 'Buy addons',
    BUY_NOW: 'Buy now',
    UPDATING: 'Updating...',
    SUCCESS: 'Congratulation! Your plan subscription has been updated',
    INFO_ADDONS_TITLE: `The subscription has reached the amount of allowed {{addon}}. Contact your account owner {{ownerEmail}}
      <a target='_blank'
        href='http://helpcenter.itopia.com/en/articles/778993-update-your-itopia-subscription'>
        to update your subscription.
      </a>`,
    EACH: 'each'
  }
};
