export class FilterMapped {
  public propertyName: string;
  public toLower: boolean;
  public binaryOperation: number;
  public unaryOperation: number;
  public operation: number;
  public value: any;
  constructor(data?: any) {
    this.propertyName =  data && data.propertyName ? data.propertyName  : '';
    this.toLower =  data && data.toLower ? data.toLower  : false;
    this.binaryOperation =  data && data.binaryOperation ? data.binaryOperation  : 0;
    this.unaryOperation =  data && data.unaryOperation ? data.unaryOperation  : 0;
    this.value =  data && data.value ? data.value  : '';
  }
}
