export class DiskType {
    public name: string;
    public displayName: string;
    public maxSize: number;
    public description: string;
    constructor(data ? : any) {
        this.name = data && data.name ? data.name : '';
        this.displayName = data && data.displayName ? data.displayName : data && data.name ? data.name : '';
        this.maxSize = data && data.maxSize ? data.maxSize : 0;
        this.description = data && data.description ? data.description : '';
    }
}
