import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

/** Material imports */
import {MatButtonModule} from '@angular/material/button';
import {MatChipsModule} from '@angular/material/chips';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { NotificationContainerComponent } from './notification-container/notification-container.component';
import { NotificationPaneComponent } from './notification-pane.component';

@NgModule({
  declarations: [NotificationPaneComponent, NotificationContainerComponent],
  imports: [
    CommonModule, FlexLayoutModule, MatListModule, RouterModule, MatButtonModule,
    MatChipsModule, MatDividerModule, MatIconModule, MatProgressSpinnerModule
  ],
  exports: [NotificationPaneComponent]
})
export class NotificationPaneModule { }
