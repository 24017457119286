export const locale = {
  lang: 'es',
  'subscription_canceled' : {
    SUBSCRIPTION_INVALID: 'Lamentablemente, su suscripción está cancelada desde el {{chargifyDate}}. Si usted requiere de CAS para administrar su entorno en la nube, haga clic en REACTIVAR y siga los pasos.',
    SUBSCRIPTION_CANCELED_TITLE: '{{user}}, su suscripción en itopia está cancelada',
    SUBSCRIPTION_INVALID_VIEWER: 'Lamentablemente, su suscripción está cancelada desde el {{chargifyDate}}. Póngase en contacto con el propietario de su cuenta para seguir utilizando CAS.',
    REACTIVATE: 'Reactivar',
    REACTIVATING: 'Reactivando ...',
    WHAT_HAPPEN: '¿Qué va a pasar?',
    REACTIVATE_SUBSCRIPTION_MSG1: 'Su suscripción se activará inmediatamente.',
    REACTIVATE_SUBSCRIPTION_MSG2: 'Se le cobrará {{price}} por {{name}}.',
    REACTIVATE_SUBSCRIPTION_MSG3: 'La fecha de facturación del cliente se restablecerá hasta hoy.',
  }
};
