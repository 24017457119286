import { SoltenantGroup } from '..';

export class RegionDataPoolDto {
    public regionId: number;
    public override: number;
    public soleTenantGroup: SoltenantGroup;
    public active: boolean;

    constructor(data ? : any) {
        this.regionId = data && data.regionId ? data.regionId : 0;
        this.override = data && data.override ? data.override : 0;
        this.soleTenantGroup = data && data.soleTenantGroup ? data.soleTenantGroup : null;
        this.active = data && data.active ? data.active : false;
      }
  }
