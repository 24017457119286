export class ConfigVar {
    public configVariableId: number;
    public name: string;
    public description: string;
    public value: string;
    constructor(data?: any) {
      this.configVariableId = data && data.configVariableId ? data.configVariableId  : 0;
      this.name = data && data.name ? data.name : '';
      this.description = data && data.description ? data.description : '';
      this.value = data && data.value ? data.value : '';
    }
}
