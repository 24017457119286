import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class FormButtonV2ActionServices {
  public actionPressed$: BehaviorSubject<boolean>;
  public actionPressed: Observable<boolean>;

  constructor() {
    this.actionPressed$ = new BehaviorSubject<boolean>(false);
    this.actionPressed = this.actionPressed$.asObservable();
  }

  public setActionPressed(actionPressed: boolean): void {
    this.actionPressed$.next(actionPressed);
  }
}
