import { EmailNotification } from './email-notification';

export class Admin {
    public id: string;
    public partnerId: number;
    public userName: string;
    public discriminator: string;
    public email: string;
    public firstName: string;
    public lastName: string;
    public personalPhoneNumber: string;
    public personalPhoneNumberExt: string;
    public supportPhoneNumber: string;
    public supportPhoneNumberExt: string;
    public supportEmail: string;
    public locked: boolean;
    public emailConfirmed: boolean;
    public phoneNumber: string;
    public phoneNumberConfirmed: boolean;
    public twoFactorEnabled: boolean;
    public lockoutEndDateUtc: string;
    public lockoutEnabled: boolean;
    public accessFailedCount: number;
    public userZendeskID: string;
    public lang: string;
    public organizationId: number;
    public accountRoleTypeId: number;
    public active:  boolean;
    public aspNetRoles: any[];
    public tokens: any [];
    public roleTypeId:  number;
    public allCompanies:  boolean;
    public allowCompanies: any [];
    public primary: boolean;
    public roleName: string;
    public roleSecurityName: string;
    public roleSecurityId: number;
    public isPending: boolean;
    public emailNotifications: Array<EmailNotification>;
    public displayStatus: string;
}
