import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ListDto, Subscription, SubscriptionComponent, SubscriptionMetrics, SubscriptionWidget } from 'src/app/models';

import { environment } from 'src/environments/environment';

const oneHundred = 100;

@Injectable({
  providedIn: 'root'
})
export class SubscriptionManageService {
  public currentSubscription: Observable<Subscription>;

  private currentSubscriptionSubject: BehaviorSubject<Subscription>;

  constructor(private http: HttpClient) {
    this.currentSubscriptionSubject = new BehaviorSubject<Subscription>(null);
    this.currentSubscription = this.currentSubscriptionSubject.asObservable();
  }

  public getBy(organizationId: number): Observable<Subscription> {
    return this.http.get<Subscription>(`${environment.apiUrl}organizations/${organizationId}/subscription`);
  }

  public getComponents(organizationId: number): Observable<ListDto<SubscriptionComponent>> {
    return this.http.get<any>(`${environment.apiUrl}organizations/${organizationId}/subscriptionComponents`);
  }

  public getWidget(organizationId: number): Observable<SubscriptionWidget> {
    return this.http.get<any>(`${environment.apiUrl}organizations/${organizationId}/subscriptionWidget`);
  }

  public getMetrics(organizationId: number): Observable<SubscriptionMetrics> {
    return this.http.get<SubscriptionMetrics>(`${environment.apiUrl}organizations/${organizationId}/subscription/metrics`);
  }

  public updateSubscriptionComponents(organizationId: number, components: Object[]): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}organizations/${organizationId}/subscription`, { list: components });
  }

  public cancelSubscription(organizationId: number, immediately: string): Observable<{}> {
    const qs = new HttpParams().set('immediately', immediately);
    return this.http.post<{}>(`${environment.apiUrl}organizations/${organizationId}/subscription/cancel`, null, { params: qs });
  }

  public reactivateSubscription(organizationId: number): Observable<Subscription> {
    return this.http.post<Subscription>(`${environment.apiUrl}organizations/${organizationId}/subscription/activate`, null);
  }

  public setCurrentSubscription(subscription: Subscription): void {
    this.currentSubscriptionSubject.next(subscription);
  }

  public calculateTotalPrice(subscription: Subscription, components: SubscriptionComponent[]): number {
    let addonsCost = 0;
    const balance = subscription.balanceInCents ? subscription.balanceInCents / oneHundred : 0;
    components.forEach((component) => {
      addonsCost += Number(component.quantity * component.price);
    });
    return subscription.productPrice + addonsCost + balance;
  }
}
