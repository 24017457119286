import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';

import { BinaryExpressionService, OperationExpressionService, UnaryExpressionService } from 'src/app/core/shared/filters';
import { DeploymentsService } from 'src/app/deployments/deployments.service';
import { CollectionPoolStatus, Datatable, Deployment, ListDto, Scale } from 'src/app/models';
import { AutoUnsubscribeComponent } from '../../auto-unsubscribe/auto-unsubscribe.component';
import { MultiselectPoolsService } from './multiselect-pools.service';

import { locale as english } from './i18n/multiselect-pools.en';
import { locale as spanish } from './i18n/multiselect-pools.es';

const pageSizeValue = 10;

@Component({
    selector: 'app-multiselect-pools',
    templateUrl: './multiselect-pools.component.html',
    styleUrls: ['./multiselect-pools.component.scss']
})
export class MultiselectPoolsComponent extends AutoUnsubscribeComponent implements OnInit, OnChanges {
    @Input()
    public deploymentId: number;
    @Input()
    public containerClass: string;
    @Input()
    public collectionPoolId: number;
    @Input()
    public userPools: number[];
    @Input()
    public selectionType: string;
    @Input()
    public extraParams: Array<Object>;

    @Output()
    public selectedPoolsEmitter = new EventEmitter<number[]>();
    @Output()
    public selectedPoolEmitter = new EventEmitter<number>();
    public isLoadingPools: boolean;
    public selectModelPools: Datatable<Scale> = new Datatable<Scale>();
    public poolsTitle: string;
    public deploymentSelected: Deployment;

    private selectedPools: number[];
    private selectedPool: number;
    private scalas: ListDto<Scale>;

    constructor(private translate: TranslateService, private operationExpression: OperationExpressionService,
                private unaryExpression: UnaryExpressionService, private binaryExpression: BinaryExpressionService,
                private multiselectPoolsService: MultiselectPoolsService, public router: Router,
                private deploymentsMenuService: DeploymentsService) {
        super();
        if (!this.deploymentId) {
          const deploymentSelectedSubs = this.deploymentsMenuService.deploymentSelected.subscribe(x => {
            this.deploymentSelected = x;
            if (this.deploymentSelected && !this.deploymentSelected.onCloud) {
              this.router.navigate(['/dashboard']);
            } else if (this.deploymentSelected) {
              this.deploymentId = this.deploymentSelected.companyId;
            }
          });
          super.addSubscriptions(deploymentSelectedSubs);
        }
    }

    public ngOnInit() {
        this.containerClass = this.containerClass ? this.containerClass : '';
        this.selectedPools = [];
        this.setTranslations();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.deploymentId && changes && changes.collectionPoolId && !changes.collectionPoolId.firstChange) {
            this.getPools();
        }
    }

    public getPools($event?: Datatable<Scale>): void {
        setTimeout(() => {
            this.isLoadingPools = true;
        });
        const params = {
            'pageIndex': $event.CurrentPageNumber + 1 || 1,
            'pageSize': pageSizeValue,
            'filter': []
        };
        if ($event && $event['searchValue']) {
            params.filter.push({
                'propertyName': 'CollectionName', 'operation': this.operationExpression.expressionType.CONTAINS,
                'value': $event['searchValue'], 'toLower': true, 'unaryOperation': this.unaryExpression.expressionType.NONE,
                'binaryOperation': this.binaryExpression.expressionType.OR
            },
            {
                'propertyName': 'CollectionAlias', 'operation': this.operationExpression.expressionType.CONTAINS,
                'value': $event['searchValue'], 'toLower': true, 'unaryOperation': this.unaryExpression.expressionType.NONE,
                'binaryOperation': this.binaryExpression.expressionType.OR
            });
        }
        params.filter.push({
            'propertyName': 'CollectionStatusId', 'operation': this.operationExpression.expressionType.EQUALS,
            'value': CollectionPoolStatus.PendingUpdate, 'unaryOperation': this.unaryExpression.expressionType.NONE,
            'toLower': false, 'binaryOperation': this.binaryExpression.expressionType.AND
        },
        {
            'propertyName': 'CollectionStatusId', 'operation': this.operationExpression.expressionType.EQUALS,
            'value': CollectionPoolStatus.Ready, 'unaryOperation': this.unaryExpression.expressionType.NONE,
            'toLower': false, 'binaryOperation': this.binaryExpression.expressionType.OR
        });
        if (!_.isEmpty(this.extraParams)) {
            _.each(this.extraParams, param =>  params.filter.push(param as any));
          }
        const getPoolsSubs = this.multiselectPoolsService.searchCollectionPools(this.deploymentId, params)
        .subscribe((poolsResp: ListDto<Scale>) => {
            let mapAppsAllowProp: Scale[];
            if (!this.scalas) {
              this.scalas = poolsResp;
            } else if (poolsResp.count === this.scalas.list.length) {
              poolsResp.list = this.scalas.list;
            } else {
              poolsResp.list.concat(...this.scalas.list);
            }
            mapAppsAllowProp = this.setAllowToUserPools(poolsResp);
            this.selectModelPools = {
                PageSize: pageSizeValue,
                TotalElements: poolsResp.count,
                TotalPages: Math.ceil(poolsResp.count / pageSizeValue),
                CurrentPageNumber: $event.CurrentPageNumber + 1,
                SortBy: 'collectionAlias',
                SortDir: 'asc',
                Data: mapAppsAllowProp
            };
            this.isLoadingPools = false;
            this.setTranslations();
        }, () => {
            this.isLoadingPools = false;
        });
        super.addSubscriptions(getPoolsSubs);
    }
    public setAllowToUserPools(poolsResp: ListDto<Scale>): Scale[]{
       let mapAppsAllowProp = poolsResp.list.map(pool => {
            const poolMap = new Scale({
                scaleId: pool.scaleId,
                collectionAlias: pool.collectionAlias
            });
            if (this.userPools && this.userPools.includes(pool.scaleId)) {
              poolMap['allow'] = true;
            }
            return poolMap;
          });
          return mapAppsAllowProp;
    }
    public selectPools($event: Scale[]): void {
        this.selectedPools = $event.map(pool => pool.scaleId);
        this.selectedPoolsEmitter.emit(this.selectedPools);
        const selectedPoolsRegions = $event.map(pool => ({poolId: pool.scaleId, regionId: pool.regionId }));
    }
    public rowclick($event: Scale): void {
        this.selectedPool = $event.scaleId;
        this.selectedPoolEmitter.emit(this.selectedPool);
        const selectedPoolRegion = {poolId: $event.scaleId, regionId: $event.regionId};
    }

    private setTranslations(): void {
        const browserLang = this.translate.getBrowserLang();
        const mapLangI18nFile = { es: spanish, en: english };
        if (!mapLangI18nFile[browserLang]) {
          this.translate.use('en');
          this.translate.setTranslation('en', mapLangI18nFile['en']);
        } else {
          this.translate.use(browserLang);
          this.translate.setTranslation(browserLang, mapLangI18nFile[browserLang]);
        }
        this.poolsTitle = this.translate.instant('multiselect_pools.POOLS_TITLE');

    }
}
