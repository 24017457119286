import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import {FormButtonsV2Component} from './form-buttons-v2.component';


@NgModule({
  imports: [CommonModule, NgbModule, TranslateModule, ReactiveFormsModule],
  declarations: [FormButtonsV2Component],
  exports: [FormButtonsV2Component]
})
export class FormButtonsV2Module {
}
