import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class GutilsService {
private prefix: string;
constructor() {
    this.prefix = environment.gapi.prefix;
  }

public cieloGuid (): string {
    const uuid = uuidv4();
    return this.prefix + '-' + uuid.split('-')[0];
}

public getProjectName (projectId): string {
    return 'projects/' + projectId;
}
}
