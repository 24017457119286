import {SelectionModel} from '@angular/cdk/collections';
import {Component, ViewChild} from '@angular/core';
import {MediaChange, MediaObserver} from '@angular/flex-layout';
import {MatPaginator, MatSort, MatTableDataSource, Sort} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {filter} from 'rxjs/operators';
import * as _ from 'underscore';

import {BinaryExpressionService, OperationExpressionService, UnaryExpressionService} from '../../core/shared/filters';
import {Configuration, MatColumnSize, MatDatatable, MatServerPage} from '../../models';
import {MatAbstractServerSideDtService} from './services/mat-abstract-server-side-dt.service';

@Component({
  templateUrl: './mat-datatable-server-side.component.html',
  styleUrls: ['./mat-datatable-server-side.component.scss']
})
export class MatDatatableServerSideComponent {

  public pageSize = 25;
  public datatable: MatDatatable;
  public selection: SelectionModel<any>;
  public dataSource = new MatTableDataSource<any>([]);
  public allowMultiSelect = true;
  public resolution: string;
  public displayedColumns: string[];
  public displayedColumnsByWindowsSize: MatColumnSize;
  public displayedColumnsConfig: Configuration[];
  public arrayElements: any[] = [];
  public selectedElements: any[] = [];
  public loading = true;
  public searchValue: string;
  public sortBy = '';
  public sortOrder = 'asc';
  public pageIndex = 1;
  public isSorting: boolean;
  public isSearching: boolean;
  public rowId: string;
  public realSelected: any[] = [];
  public count: number;
  public tableConfig: Configuration [];
  public english: any;
  public spanish: any;

  public hasColumnChooser: boolean;
  public hasFilterColumn: boolean;
  public originalConfigList: Configuration [];
  public originalBeforeScreenSize: Configuration [];
  public showTooltip: boolean;
  public clickedBtn: boolean;

  @ViewChild(MatSort, {static: false}) public sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) public paginator: MatPaginator;

  constructor(public mediaObserver: MediaObserver, public matAbstractServerSideDtService: MatAbstractServerSideDtService,
              public binaryExpression: BinaryExpressionService, public unaryExpression: UnaryExpressionService,
              public operationExpression: OperationExpressionService, public translate: TranslateService) {
    this.initDatatable();
    this.selection = new SelectionModel<any>(this.allowMultiSelect, []);
    const media$ = this.mediaObserver.asObservable().pipe( filter(() => true) );
    media$.subscribe((changes: MediaChange[]) => {
      this.resolution = changes[0].mqAlias;
      this.onResolutionChange(this.resolution);
    });
  }

  private initDatatable(): void {
    this.datatable = new MatDatatable({
      pageSize: this.pageSize,
      pageIndex: 0
    });
  }
  private onResolutionChange(resolution: string) {
    if (this.displayedColumns && this.displayedColumnsByWindowsSize) {
      if (!this.hasColumnChooser && this.hasFilterColumn) {
        this.displayedColumns = _.pluck(this.tableConfig, 'displayName');
      } else {
        this.displayedColumns = _.pluck(_.filter(this.tableConfig, (item: Configuration) => item.checked === true), 'displayName');
      }
      this.createColumnSizeForDisplay(this.tableConfig);
      this.displayedColumns = this.displayedColumnsByWindowsSize[resolution];
      if (!_.some(this.displayedColumns, x => x === 'select')) {
        this.displayedColumns.unshift('select');
      }
      if (!_.some(this.displayedColumns, x => x === 'columnSelection') && (this.hasColumnChooser || this.hasFilterColumn)) {
        this.displayedColumns.push('columnSelection');
      }
      if (this.hasColumnChooser) {
        this.mapColumnsAfterResolutionChange();
        this.originalConfigList = _.clone(this.tableConfig);
        this.originalBeforeScreenSize = _.clone(this.originalConfigList);
        sessionStorage.setItem('ConfigurationList', JSON.stringify(this.tableConfig));
        this.clickedBtn = false;
      }
    }
  }

  public mapColumnsAfterResolutionChange(): void {
    _.map(this.tableConfig, (item: Configuration) => {
      if (_.some(this.displayedColumns, value => value.toLowerCase().includes(item.fieldName.toLowerCase()) )) {
        if (!item.checked) {
          const index = this.displayedColumns.indexOf(item.fieldName);
          if (index > -1) {
            this.displayedColumns.splice(index, 1);
          }
        }
      } else {
        item.checked = false;
      }
    });
  }

  public setAllowMultipleSelection(multiple: boolean): void {
    this.allowMultiSelect = multiple;
    this.selection = new SelectionModel<any>(this.allowMultiSelect, []);
  }
  public isAllSelected(): boolean {
    const numSelected = _.filter(_.uniq(this.selection.selected, this.rowId), (typeObj: any) => typeObj[this.rowId] !== undefined).length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  public isAllSelectedInPage(): boolean {
    return this.comparePageWhitSelected();
  }
  public masterToggle($event?): void {
    if ($event.checked) {
      this.isAllSelectedInPage() ? this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
      this.afterSelectionBehavior();
    } else {
      this.isAllSelectedInPage() ?  this.dataSource.data.forEach(row => this.selection.deselect(row)) :
        this.dataSource.data.forEach(row => this.selection.select(row));
      this.afterSelectionBehavior();
    }

  }
  public select(row: any): void {
    this.selection.toggle(row);
    this.selectedElements = this.selection.selected;
    this.afterSelectionBehavior();
  }

  public sortData(sort: Sort): void {
    if (this.paginator) { this.paginator.pageIndex = 0; }
    this.pageIndex = 1;
    this.isSorting = true;
  }
  public refresh(): void {
    if (this.paginator) { this.paginator.pageIndex = 0; }
    this.pageIndex = 1;
    this.isSearching = false;
    this.selection.clear();
    this.realSelected = [];
    this.matAbstractServerSideDtService.setSelected(this.realSelected);
    sessionStorage.setItem('dtSelected', JSON.stringify(this.realSelected));
  }
  public paginate($event: MatServerPage): void {
    this.isSorting = false;
    this.paginator.pageIndex = $event.pageIndex;
    this.datatable.pageIndex = $event.pageIndex;
    this.paginator.pageSize = this.pageSize = $event.pageSize;
    this.pageIndex = $event.pageIndex + 1;
  }
  public searchEvent($event: any): void {
    this.isSearching = true;
    const text = $event.target.value.toLowerCase().trim();
    if (this.searchValue !== text) {
      this.searchValue = text;
      this.datatable.pageIndex = 0;
      this.pageIndex = 1;
    }
  }

  public populateRows(data?: any): void {
    this.arrayElements = data.list;
    this.datatable = new MatDatatable({
      total: data.count,
      totalPages: Math.ceil(data.count / this.pageSize),
      pageIndex: this.datatable.pageIndex,
      pageSize: this.pageSize,
      sortBy: this.sortBy,
      sortOrder: this.sortOrder
    });
    this.dataSource = new MatTableDataSource<any>(this.arrayElements);
    this.selection.clear();
    this.selection.select(...this.realSelected);
    if (this.selection.selected.length > 0) {
      this.dataSource.data.forEach(row => {
        if (this.selection.selected.some((p) => p[this.rowId] === row[this.rowId])) {
          const newArray = _.filter(this.selection.selected , item => item[this.rowId] !== row[this.rowId]);
          this.selection.clear();
          this.selection.select(...newArray);
          this.selection.toggle(row);
        }
      });
    }
    if (!this.hasColumnChooser && this.hasFilterColumn) {
      this.displayedColumns = _.pluck(this.tableConfig, 'displayName');
    } else {
      this.displayedColumns = _.pluck(_.filter(this.tableConfig, (item: Configuration) => item.checked === true), 'displayName');
    }
    this.createColumnSizeForDisplay(this.tableConfig);
    this.displayedColumns = this.displayedColumnsByWindowsSize[this.resolution === undefined ? 'xl' : this.resolution];
    this.displayedColumns.unshift('select');
    if (this.hasColumnChooser || this.hasFilterColumn) {
      this.displayedColumns.push('columnSelection');
      sessionStorage.setItem('ConfigurationList', JSON.stringify(this.tableConfig));
    }
  }
  public mapToCreateFilters(searchValue): any {
    const listFilterable = _.where(this.displayedColumnsConfig, {filterable: true, visible: true});
    return _.map(listFilterable, (configuration: Configuration) => {
      return {
        propertyName: configuration.filterValue, operation: this.operationExpression.expressionType.CONTAINS,
        value: searchValue, toLower: true, unaryOperation: this.unaryExpression.expressionType.NONE,
        binaryOperation: this.binaryExpression.expressionType.OR
      };
    });
  }
  public mapColumns(conf: Configuration [], fromExternal?: boolean): any {
    return conf.map(column => {
      return Object.assign(column, {
        columnDef: column.fieldName,
        header: column.displayName.toUpperCase,
        minWidth: column.minWidth,
        checked: fromExternal ? column.checked : column.default,
        cell: (element: any) => `${element[column.fieldName] ? element[column.fieldName] : ``}`
      });
    });
  }
  public createColumnSizeForDisplay(conf: Configuration []) {
    this.displayedColumnsByWindowsSize = new MatColumnSize();
    _.each(conf, (column: Configuration) => {
      if (column.checked) {
        if (column['visibleOn']) {
          if (column['visibleOn'][0] === 'all') {
            _.each(Object.keys(this.displayedColumnsByWindowsSize), key => {
              this.displayedColumnsByWindowsSize[key].push(column.fieldName);
            });
          } else {
            _.each(column['visibleOn'], (key: string) => {
              this.displayedColumnsByWindowsSize[key].push(column.fieldName);
            });
          }
        } else {
          _.each(Object.keys(this.displayedColumnsByWindowsSize), key => {
            this.displayedColumnsByWindowsSize[key].push(column.fieldName);
          });
        }
      }
    });
  }

  private afterSelectionBehavior() {
    this.realSelected = _.filter(_.uniq(this.selection.selected, this.rowId), (typeObj: any) => typeObj[this.rowId] !== undefined);
    this.matAbstractServerSideDtService.setSelected(this.realSelected);
    sessionStorage.setItem('dtSelected', JSON.stringify(this.realSelected));
  }

  private isElementInArray(arr, item): boolean {
    const itemAsString = JSON.stringify(item);
    return arr.some(element => {
      return JSON.stringify(element) === itemAsString;
    });
  }
  private comparePageWhitSelected(): boolean {
    let resp = false;
    let count = 0;
    _.forEach(this.dataSource.data, element => {
      resp = this.isElementInArray(this.selection.selected, element);
      if (resp) { count++; }
    });
    return resp && count === this.dataSource.data.length;
  }

  public mapColumnsTranslated(itemName: string, conf: Configuration []): any {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', this.spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', this.english);
    }
    return conf.map(column => {
      return Object.assign(column, {
        header: this.translate.instant(itemName.concat(column.displayName.toUpperCase())),
      });
    });
  }
  public onHover($event): void {
    if ($event.currentTarget.className.indexOf('ellipsis-class') !== -1) {
      this.showTooltip = $event.currentTarget.offsetWidth < $event.currentTarget.scrollWidth;
    }
  }
}
