import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AutoUnsubscribeModule } from '../auto-unsubscribe/auto-unsubscribe.module';
import { SpinnerComponent } from './spinner.component';

@NgModule({
    imports: [CommonModule, NgbModule, RouterModule, AutoUnsubscribeModule],
    declarations: [SpinnerComponent],
    exports: [SpinnerComponent],
})
export class SpinnerModule {
}
