import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { Integration } from 'src/app/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleProjectService {

  constructor(private http: HttpClient) { }

  public getIntegration(deploymentId: number): Observable<any> {
    const params = new HttpParams().set('skeepFailure', 'true');
    return this.http.get<any>(`${environment.apiUrl}deployments/${deploymentId}/integration`, {params});
  }
  public postIntegration(deploymentId: number, integration: Integration): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}deployments/${deploymentId}/integration`, integration);
  }
}
