export const locale = {
  lang: 'es',
  proservices: {
    PROSERVICES: `Servicios profesionales`,
    PLACEHOLDER: `Descripción de los servicios requeridos`,
    REQUEST: `Solicitar`,
    REQUESTING: `Solicitando...`,
    CANCEL: `Cancelar`,
    SUCCESS: `Solicitud de servicios profesionales enviada correctamente`,
    ERROR_REQUIRED: `Campo requerido`,
    TITLE: `Solicitar servicios profesionales`,
    NAME:`Nombre`,
    LASTNAME: `Apellido`,
    EMAIL: `Correo`,
    PHONE: `Teléfono`,
    HOURS: `Horas`,
    ERROR_EMAIL: `Este campo debe ser una dirección de correo electrónico en el siguiente formato: "user@example.com"`,
    HOURSVALUES:{
      1: `1 ($125)`,
      3: `3 ($336)`,
      5: `5 ($500)`,
    },
    ERROR_PATTERN: `Campo inválido`,
    ACCESSTO:`Puedes acceder a`,
    PROSERVICESCONDITIONS:`Servicios profesionales términos y condiciones`,
    HERE:`Aquí`,
    PHONE_HELPER: 'Escriba el número telefónico',
  }
};
