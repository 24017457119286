export class Vpn {
    public name: string;
    public description: string;
    public region: string;
    public ipAddreess: string;
    constructor( data?: any) {
      this.name =  data && data.name ? data.name  : '';
      this.region = data && data.region ? data.region : '';
      this.description = data && data.description ? data.description : '';
      this.ipAddreess = data && data.ipAddreess ? data.ipAddreess : '';
    }
}
