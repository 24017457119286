import { Pipe, PipeTransform } from '@angular/core';

import { GpuCard } from 'src/app/models';

@Pipe({
  name: 'groupGpu'
})
export class GroupGpuPipe implements PipeTransform {

  public transform(gpus: Array<GpuCard>): any {
    return gpus = gpus.map((gpu: GpuCard) => {
      if (gpu.name.toLowerCase().includes('vws')) {
        gpu['section'] = 'Virtual Workstation (NVIDIA GRID)';
      } else {
        gpu['section'] = 'Compute-only';
      }
      return gpu;
    });
  }

}
