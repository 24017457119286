import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/observable/of';
import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';

import { HttpCacheService } from '../services/http-cache.service';
@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  constructor(private cache: HttpCacheService) { }
  public intercept(req: HttpRequest<any>, next: HttpHandler) {
    const cachedResponse = this.cache.get(req);
    return cachedResponse ? Observable.of(cachedResponse) : this.sendRequest(req, next, this.cache);
  }

  public sendRequest(
    req: HttpRequest<any>,
    next: HttpHandler,
    cache: HttpCacheService): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          cache.put(req, event);
        }
      })
    );
  }
}
