export const locale = {
  lang: 'en',
  'regions_list_post' : {
    'PRIMARY': 'primary',
    'DEPLOY_REGION': 'Deployment Region',
    'DEPLOY_REGIONS': 'Deployment Regions',
    'IS_PRIMARY': 'This is the primary region',
    'SUBNET': 'VPC subnet',
    'ZONE_FLAG': 'Manually select zones',
    'ZONE_FLAG_HELP': 'If disabled, CAS automatically pick two zones in the region for deployment resources',
    'ZONE1': 'Zone 1',
    'ZONE2': 'Zone 2',
    'ADD_REGION': 'Add Region',
    'ERROR_REQUIRED': 'This field is required',
    'ERROR_PATTERN': 'The value in this field is invalid',
    'SELECT_REGION': 'Select a GCP Region',
    'CANCEL_BTN': 'Cancel',
    'SAVE_BTN': 'Save',
    'SAVING': 'Saving...',
    'DESCRIPTION_REGION_POST_PROVISION': 'Regions are the GCP datacenters that contain your deployment\'s infrastructure. Adding a region creates several infrastructure servers and services (AD, RD Brokers and Gateways, etc.) in that region and allows you to extend a Collection Pool to that region. ',
    'DESCRIPTION_REGION_POST_PROVISION_1': 'Removing a region deletes all servers and services in that region.',
    'DESCRIPTION_REGION_POST_PROVISION_2': 'You cannot remove the primary region.',
    'DNS_VERIFIED': 'Verified DNS',
    'DNS_FAILED': 'Failed DNS',
    'EXTERNAL_DNS_NAME': 'External DNS Name',
    'EXTERNAL_IP_ADDRESS': 'External IP Address',
    'COPY': 'Copy',
    'COPIED': 'Copied',
    'SAVED': 'Data saved for region: {{region}}',
    'SSL_SAVED': 'SSL Cerfificated saved for region: {{region}}',
    'SOLETENANT_CONFIGURATION': 'Sole-tenant configuration',
    'SOLETENANT_DESCRIPTION' : 'If sole-tenant node support is enabled in the deployment, ' +
                                'provide the default node group for VMs in this region. '  +
                                'Changing this setting does not affect any existing VMs, only new VMS.',
    'SOLETENANT_GROUP_DESCRIPTION': 'Sole-tenant node group for this region',
    'ERROR_DNS_RECORD': 'Unable to update dns record. Please check data and try again',
    'ERROR_DNS_RECORD_FIELD': 'Invalid dns record',
    'SOLETENANT_GROUP_NOT_FOUND': 'The selected node group could not be found in GCP',
    'PENDING_CONFIGURATION': 'Pending configuration',
    'READY': 'Ready'

  }
};
