export enum ValidationErrorsCodes  {
    ValidationError40027 = 40027,
    ValidationError40029 = 40029,
    ValidationError40434 = 40434,
    ValidationError40136 = 40136,
    ValidationError40134 = 40134,
    ValidationError40034 = 40034,
    ValidationError40135 = 40135,
    ValidationError40031 = 40031,
    ValidationError40032 = 40032,
    ValidationError40040 = 40040,
    ValidationError20007 = 20007
}
