export class CatalogNetworkNode {
  public networkNodeId: number;
  public deviceName: string;
  public vendor: string;
  public lastUpdate: string;
  public ip: string;
  public mac: string;
  public siteId: string;

  constructor(data?: any) {
    if (data) {
      Object.keys(data).forEach(key => {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          this[key] = data[key];
        }
      });
    }
  }
}
