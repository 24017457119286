import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BinaryExpressionService {
  public expressionType: any;

  constructor() {
    this.expressionType = {
      NONE: 0,
      AND: 1,
      AND_ALSO: 2,
      OR: 3,
      OR_ELSE: 4,
      EXCLUSIVE_OR: 5,
      ASSIGN: 6,
      ADD_CHECKED: 7,
      ADD_ASSIGN_CHECKED: 8,
      COALESCE: 9,
      DIVIDE: 10,
      LEFT_SHIFT: 11,
      RIGHT_SHIFT: 12,
      MODULO: 13
    };
  }

}
