export class ProvisionStep {
    public id: number;
    public startdate: string;
    public statics: any[];
    public message: string;
    public step: string;
    public enddate: string;
    public status: string;
    public parent: string;
    public deploymentId: number;
    public code: string;
    public description: string;

    constructor(data: any) {
      this.id =   data.id || 0;
      this.startdate =  data.startdate || '';
      this.statics = data.statics || [];
      this.message =  data.message || '';
      this.step =  data.step || '';
      this.enddate = data.enddate || '';
      this.status =  data.status || '';
      this.parent = data.parent || '';
      this.deploymentId =  data.deploymentId || 0;
      this.code =  data.code || '';
      this.description =  data.description || '';
}
}
