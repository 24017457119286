export class ApiKeyTypes {
  public secretsTokenTypeId: number;
  public code: string;
  public title: string;
  public description: string;

  constructor(data?) {
    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          this[key] = data[key];
        }
      }
    }
  }
}
