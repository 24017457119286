import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { WidgetInfoComponent } from './widget-info.component';

@NgModule({
    imports: [CommonModule, NgbModule],
    declarations: [WidgetInfoComponent],
    exports: [WidgetInfoComponent]
})
export class WidgetInfoModule {
}
