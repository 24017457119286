import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { Feedback } from '../../models/support/feedback';


@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private http: HttpClient) { }

  public sendFeedback = (feedback: Feedback) => {
    feedback.subject = '';
    return  this.http.post<any>(`${environment.apiUrl}feedbacks`, feedback);
    }

}
