export class InstanceStatus {
  public instanceId: number;
  public isOnline: boolean;
  public status: string;
  public internalIp: string [];

  constructor(data?: any) {
    this.instanceId = data && data.instanceId ? data.instanceId : 0;
    this.isOnline = data && data.isOnline ? data.isOnline : false;
    this.status = data && data.status ? data.status : '';
    this.internalIp = data && data.internalIp ? data.internalIp : [];
  }
}
