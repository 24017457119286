import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  Input,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import {
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';

const timeoutValue = 1000;
@Component({
  selector: 'app-spinner',
  template: `<div class="preloader" [ngClass]="{'position-relative': toElement}" *ngIf="isSpinnerVisible">
        <div class="spinner" [style.top]= "positionClass ? positionClass: '80px'"
          [ngClass]="{'diameter-25 mx-auto my-auto': toElement}">
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="loading" style="max-width: 30px;">
        <circle cx="50" cy="50" r="45" style="stroke: #137eff;"/>
      </svg>
        </div>
    </div>`,
  encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnDestroy {
  public isSpinnerVisible = true;


  @Input() public backgroundColor = 'rgba(0, 115, 170, 0.69)';
  @Input() public positionClass: any;
  @Input() public toElement: boolean;

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
     this.router.events.subscribe(
      event => {
        if (event instanceof NavigationStart) {
          this.isSpinnerVisible = true;
          setTimeout(() => {
            this.isSpinnerVisible = false;
          }, timeoutValue);
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationError
        ) {
          this.isSpinnerVisible = false;
        }
      },
      () => {
        this.isSpinnerVisible = false;
      });
  }

  // tslint:disable-next-line:member-access
  ngOnDestroy(): void {
    this.isSpinnerVisible = false;
  }
}
