import { KeyValue } from '@angular/common';
import {AfterViewInit, ChangeDetectorRef, Component, Input, TemplateRef, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import * as _ from 'underscore';

import { AuthenticationService } from 'src/app/core/security/authentication.service';
import { BinaryExpressionService, OperationExpressionService, UnaryExpressionService } from '../../core/shared/filters';
import { HttpCancelService } from '../../core/shared/services/http-cancel.service';
import {ModalService} from '../../core/shared/services/modal.service';
import { Datatable, Deployment, ListDto, Organization, Page } from '../../models';
import { AutoUnsubscribeComponent } from '../../shared/auto-unsubscribe/auto-unsubscribe.component';
import { ItopiaDatatableComponent } from '../../shared/datatable/datatable/datatable.component';
import { ResetPasswordComponent } from '../../shared/reset-password/reset-password.component';
import { DeploymentsActionsService } from '../deployments-actions.service';
import { DeploymentsService } from '../deployments.service';

import { locale as english } from '../i18n/deployments.en';
import { locale as spanish } from '../i18n/deployments.es';

const limit = 10;
const decimal = 10;
@Component({
  selector: 'app-deployments-list',
  templateUrl: './deployments-list.component.html',
  styleUrls: ['./deployments-list.component.scss']
})

export class DeploymentsListComponent extends AutoUnsubscribeComponent implements AfterViewInit {
  @Input()
  public showInModal: boolean;

  public allDeploymentsLabel: string;
  public deploymentSelected: any;
  public searchPlaceholder: string;
  public columns: any;
  public limit: number;
  public offset: number;
  public editing = {};
  public rows: any[] = [];
  public temp: any[] = [];
  public loadingIndicator: boolean;
  public reorderable = true;
  public page = new Page();
  public sorts: any[] = [];
  public filters: any[] = [];
  public messages: any;
  public deploymentSearch: any;
  public resetPasswordModal: any;
  public adUserBtn: string;
  public listBtn: string;
  public createRoute: any;
  public refreshLabel: string;
  public closeLabel: string;
  public statusOnCloud: string;
  public statusNoOnCloud: string;
  public statusPendingCreation: string;
  public statusPendingConnect: string;
  public statusPendingUpdate: string;
  public statusPendingDelete: string;
  public deployForm: FormGroup;
  public translations: Object;
  public partnerId: number;
  public organizations: Organization[];
  public securityControlSpecs = {'createRoute': { resource: 'clients', action: 'create', disable: true },
                                 'editRoute': { resource: 'clients', action: 'edit', disable: true }
                                };
  @ViewChild(ItopiaDatatableComponent, { static: false }) public grid: ItopiaDatatableComponent<any>;
  public gridModel: Datatable<any> = new Datatable<any>('Name', 'asc');
  @ViewChild('statusTemplate', { static: false })
  public statusTemplate: TemplateRef<any>;
  constructor(private translate: TranslateService, private router: Router, private cdRef : ChangeDetectorRef,
              private modalService: NgbModal, private deploymentsService: DeploymentsService, private formBuilder: FormBuilder,
              private binaryExpression: BinaryExpressionService, private unaryExpression: UnaryExpressionService,
              private operationExpression: OperationExpressionService, private httpCancelService: HttpCancelService,
              private authenticationService: AuthenticationService, private deploymentsActionsService: DeploymentsActionsService) {
    super();
    this.columns = [{ prop: 'name', name: this.translate.instant('deployments.NAME').toUpperCase(), minWidth: 490, width: 'auto',
                      cellTemplate: this.statusTemplate },
                    { prop: 'companiesStatusId', hide: true }];
    this.searchPlaceholder = this.translate.instant('deployments.SEARCH');
    this.sorts = [{ prop: 'name', dir: 'asc' }, { prop: 'companiesStatusId', dir: 'asc' }];
    this.limit = limit;
    this.deploymentsService = deploymentsService;
    this.page.pageNumber = 0;
    this.page.size = this.limit;
    this.deploymentSelected = [];
    this.offset = 0;
    this.loadingIndicator = true;
    this.messages = {
      'emptyMessage': this.translate.instant('deployments.NO_DATA'),
      'totalMessage': this.translate.instant('deployments.TOTAL'),
      'showingMessage': this.translate.instant('deployments.SHOWING')
    };
    this.createRoute = ['/provision'];

    this.deployForm = this.formBuilder.group({
      Organization: ['']
    });

    if (sessionStorage.getItem('partnerId') && sessionStorage.getItem('partnerId') !== '') {
      this.partnerId = parseInt(sessionStorage.getItem('partnerId'), decimal);
    }
    if (sessionStorage.getItem('organizationList') && Array.isArray(JSON.parse(sessionStorage.getItem('organizationList')))) {
      this.organizations = JSON.parse(sessionStorage.getItem('organizationList')) as Array<Organization>;
    } else {
      this.organizations = [];
      this.getOrganizations();
    }

    const currentUserSubs = this.authenticationService.currentUser.subscribe((currenUser: Object) => {
      if (currenUser && currenUser['current'] && currenUser['current'].partnerId) {
        if (!this.partnerId) {
          this.partnerId = Number(currenUser['current'].partnerId);
          sessionStorage.setItem('partnerId', this.partnerId.toString());
        }
        this.f['Organization'].setValue(this.partnerId);
      }
    });
    super.addSubscriptions(currentUserSubs);
    this.setTranslation();
  }

  public ngAfterViewInit() {
    this.loadingIndicator = true;
    if (this.showInModal === undefined) {
      this.showInModal = true;
      this.cdRef.detectChanges();
    }
    this.setTranslation();
    this.columns = !this.showInModal ?
    [{ headerCheckboxable: true, checkboxable: true, sortable: false, canAutoResize: false, draggable: false,
       resizeable: false, width: 55},
     { prop: 'name', name: this.translate.instant('deployments.NAME').toUpperCase(), minWidth: 490, width: 'auto',
       cellTemplate: this.statusTemplate }] :
    [{ prop: 'name', name: this.translate.instant('deployments.NAME').toUpperCase(), minWidth: 490, width: 'auto',
       cellTemplate: this.statusTemplate },
     { prop: 'companiesStatusId', hide: true }];

    const partnerIdSubs = this.deploymentsActionsService.partnerId.subscribe((id: number) => {
      if (id && !this.showInModal) {
        this.partnerId = id;
        this.updateFilter({ target: { value: this.deploymentSearch || '' }});
      }
    });
    super.addSubscriptions(partnerIdSubs);
  }

  get f() { return this.deployForm.controls; }

  /**
 * Populate the table with new data based on the page number
 * @param page The page to select
 */
  public setPage(pageInfo, isRefresh?: boolean) {
    this.loadingIndicator = true;
    this.page.size = pageInfo.PageSize;
    this.page.pageNumber = pageInfo && pageInfo.CurrentPageNumber ? pageInfo.CurrentPageNumber :
      pageInfo.pageNumber ? pageInfo.pageNumber : pageInfo.offset;
    this.page.pageNumber = isNaN(this.page.pageNumber) ? 0 : this.page.pageNumber;
    const search = this.constructFilters();
    const searchDeploymentsSubs = this.deploymentsService.searchDeployments(search).subscribe((data: any) => {
      const getResultsDeploymentsSubs = this.deploymentsService.getResults(this.page, data).subscribe((pagedData: any) => {
        this.setStatus(pagedData.data);
        this.page = pagedData.page;
        this.rows = pagedData.data;
        this.temp = pagedData.data;
        this.gridModel = {
          PageSize: this.page.size,
          TotalElements: pagedData.page.totalElements,
          TotalPages: pagedData.page.totalPages,
          CurrentPageNumber: this.page.pageNumber,
          SortBy: 'companiesStatusId' ,
          SortDir: pageInfo.SortDir && pageInfo.SortDir === 'desc' ? 'desc' : 'asc',
          Data: this.rows
        };
          this.loadingIndicator = false;
      });
      super.addSubscriptions(getResultsDeploymentsSubs);
    });
    super.addSubscriptions(searchDeploymentsSubs);
  }
  public onSelect($event) {
    this.deploymentSelected = $event;
    if (this.showInModal) {
       this.deploymentsService.setDeploymentSelected(this.deploymentSelected[0]);
       this.deploymentSelected = [];
       this.closeDeploymentModal();
    }
  }
  public closeDeploymentModal(): void {
    this.modalService.dismissAll();
  }

  public updateFilter(event) {
    const search = this.constructFilters(event);
    if (this.showInModal) {
      this.httpCancelService.cancelPendingRequests();
    }
    this.loadingIndicator = true;
    const searchDeploymentsFilterSubs = this.deploymentsService.searchDeployments(search).subscribe((data: any) => {
      const getResultsFilterSubs = this.deploymentsService.getResults(this.page, data).subscribe((pagedData: any) => {
        this.setStatus(pagedData.data);
        this.page = pagedData.page;
        this.rows = pagedData.data;
        this.temp = pagedData.data;
        this.gridModel = {
          PageSize: this.page.size,
          TotalElements: pagedData.page.totalElements,
          TotalPages: pagedData.page.totalPages,
          CurrentPageNumber: this.page.pageNumber,
          SortBy: 'companiesStatusId',
          SortDir: event.SortDir && event.SortDir === 'desc' ? 'desc' : 'asc',
          Data: this.rows
        };
        this.loadingIndicator = false;
      });
      super.addSubscriptions(getResultsFilterSubs);
    });
    super.addSubscriptions(searchDeploymentsFilterSubs);
  }

  public showResetPasswordModal(): void {
    this.resetPasswordModal = this.modalService.open(ResetPasswordComponent,
      { size: 'md', backdrop: 'static', keyboard: false, centered: true });
    this.resetPasswordModal.componentInstance.userSelected = this.deploymentSelected;
    this.resetPasswordModal.componentInstance.allClients = false;
    this.resetPasswordModal.componentInstance.isAdmin = true;
    this.setTranslation();
  }

  public goToProvision(): void {
    this.deploymentsService.setProvisionCreate(true);
    this.router.navigate(this.createRoute);
    this.closeDeploymentModal();
  }

  public setOrganization(org: Organization): void {
    this.partnerId = org.partnerId;
    sessionStorage.setItem('partnerId', this.partnerId.toString());
    this.updateFilter({ target: { value: this.deploymentSearch || '' }});
    this.deploymentsActionsService.setPartnerId(this.partnerId);
  }

  private getOrganizations(): void {
    const orgSub = this.deploymentsService.getOrganizations()
    .subscribe((response: ListDto<Organization>) => {
      this.organizations = response.list;
      sessionStorage.setItem('organizationList', JSON.stringify(this.organizations));
    });
    super.addSubscriptions(orgSub);
  }

  private constructFilters(searchEvent = null): Object {
    this.loadingIndicator = true;
    const search = {
      pageIndex: this.page.pageNumber ? this.page.pageNumber : 0,
      pageSize: this.page.size ? this.page.size : this.limit,
      filter: [
        {
          propertyName: 'Active',
          operation: this.operationExpression.expressionType.EQUALS,
          value: true,
          toLower: true,
          unaryOperation: this.unaryExpression.expressionType.NONE,
          binaryOperation: this.binaryExpression.expressionType.AND
        },
        {
          propertyName: 'PartnerId',
          operation: this.operationExpression.expressionType.EQUALS,
          value: this.partnerId as any,
          toLower: true,
          unaryOperation: this.unaryExpression.expressionType.NONE,
          binaryOperation: this.binaryExpression.expressionType.AND
        }
      ],
      order: {
        columnName: !this.showInModal ? 'Name' : 'CompaniesStatusId',
        ascending: 'false'
      }
    };

    if (searchEvent) {
      if (!this.deploymentSearch) {
        search.pageIndex = 0;
        this.page.pageNumber = 0;
      }
      this.deploymentSearch = searchEvent.target.value.toLowerCase();
      const filters = [
        {
          propertyName: 'Name',
          operation: this.operationExpression.expressionType.CONTAINS,
          value: this.deploymentSearch,
          toLower: true,
          unaryOperation: this.unaryExpression.expressionType.NONE,
          binaryOperation: this.binaryExpression.expressionType.AND_ALSO
        },
        {
          propertyName: 'CWSClientId',
          operation: this.operationExpression.expressionType.CONTAINS,
          value: this.deploymentSearch,
          toLower: true,
          unaryOperation: this.unaryExpression.expressionType.NONE,
          binaryOperation: this.binaryExpression.expressionType.OR
        }
      ];
      search.filter = filters.concat(search.filter);
    }
    if (!this.showInModal) {
      const filters = [
        {
          propertyName: 'CompaniesStatu.Name',
          operation: this.operationExpression.expressionType.EQUALS,
          value: 'On cloud' as any,
          toLower: true,
          unaryOperation: this.unaryExpression.expressionType.NONE,
          binaryOperation: this.binaryExpression.expressionType.NONE
        }
      ];
      search.filter = search.filter.concat(filters);
    }
    return search;
  }

  private setTranslation(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
    this.columns = !this.showInModal ?
      [{ headerCheckboxable: true, checkboxable: true, sortable: false, canAutoResize: false, draggable: false,
         resizeable: false, width: 55},
       { prop: 'name', name: this.translate.instant('deployments.NAME').toUpperCase(), minWidth: 490, width: 'auto',
         cellTemplate: this.statusTemplate }] :
      [{ prop: 'name', name: this.translate.instant('deployments.NAME').toUpperCase(), minWidth: 490, width: 'auto',
         cellTemplate: this.statusTemplate },
       { prop: 'companiesStatusId', hide: true }];
    this.searchPlaceholder = this.translate.instant('deployments.SEARCH');
    this.messages = {
      'emptyMessage': this.translate.instant('deployments.NO_DATA'),
      'totalMessage': this.translate.instant('deployments.TOTAL'),
      'showingMessage': this.translate.instant('deployments.SHOWING')
    };
    this.adUserBtn = this.translate.instant('deployments.RESET_AD_BTN');
    this.listBtn = this.translate.instant('deployments.ALL');
    this.refreshLabel = this.translate.instant('deployments.REFRESH');
    this.closeLabel = this.translate.instant('deployments.CLOSE');
    this.statusOnCloud = this.translate.instant('deployments.STATUS_ON_CLOUD');
    this.statusNoOnCloud = this.translate.instant('deployments.STATUS_NOT_ON_CLOUD');
    this.statusPendingCreation = this.translate.instant('deployments.STATUS_PENDING_CREATE');
    this.statusPendingConnect = this.translate.instant('deployments.STATUS_PENDING_CONNECT');
    this.statusPendingUpdate = this.translate.instant('deployments.STATUS_PENDING_UPDATE');
    this.statusPendingDelete = this.translate.instant('deployments.STATUS_PENDING_DELETE');
    this.translations = {
      organization:  this.translate.instant('deployments.ORGANIZATION')
    };
  }
  private setStatus(list: Object[]): void {
    _.map(list, (row: Object) => {
      switch (row['status'].toLowerCase()) {
        case 'pending deletion':
          row['displayStatus'] = this.statusPendingDelete;
          break;
        case 'pending to create':
          row['displayStatus'] = this.statusPendingCreation;
          break;
        case 'pending update':
          row['displayStatus'] = this.statusPendingUpdate;
          break;
        case 'pending to connect':
          row['displayStatus'] = this.statusPendingCreation;
          break;
        case 'on cloud':
          row['displayStatus'] = this.statusOnCloud;
          break;
        case 'not on cloud':
          row['displayStatus'] = this.statusNoOnCloud;
          break;
      }
    });
  }

}
