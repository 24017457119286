export const locale = {
    lang: 'en',
    branding : {
        TITLE: 'Custom Logo',
        TITLE_LOGO: 'Toolbar Logo',
        SUBTITLE_LOGO: `The toolbar logo is displayed in the top-left corner of the CAS admin console.
            Maximum dimensions are 200x60 px (larger images will be scaled). Maximum file size is 100 KB.`,
        TITLE_ICON: 'Favicon',
        SUBTITLE_ICON: `The favicon is diplayed in the tab of the web browser. Maximum dimensions are 32x32 px
            (larger images will be scaled). Maximum file size is 100 KB.`,
        LOGO_LABEL: 'Current logo:',
        ICON_LABEL: 'Current favicon:',
        ACTIVE_BRAND: 'Enable brand',
        SAVE: 'Save',
        SAVING: 'Saving',
        CANCEL: 'Cancel',
        EDIT_SUCCESFULL: 'Branding succesfully edited',
        ERROR_REQUIRED: 'This field is required',
        ERROR_ONLY_NUMBER: 'You must enter just numbers',
        ERROR_PATTERN: 'The value in this field is invalid',
        ERROR_MAX_LENGTH: 'Maximum length {{val}}',
    }
};
