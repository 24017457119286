import { IntegrationItem } from './integration-item';

export class EventPlatform {
    public integrations: Array<IntegrationItem>;
    public integration: IntegrationItem;
    public errors: Object;
    public name: string;
    public code: number;
    public status: string;

    constructor(data?: any) {
        this.integrations = data && data.Value && data.Value.List
            ? data.Value.List.map(integ => new IntegrationItem(integ)) : [];
        this.integration = data && data.Value ? new IntegrationItem(data.Value) : null;
        this.errors = data && data.Errors ? data.Errors : null;
        this.name = data && data.Name ? data.Name : '';
        this.code = data && data.Code ? data.Code : 0;
        this.status = data && data.Status ? data.Status : '';
    }
}
