export enum ApisServices {
    GCompute = 'compute.googleapis.com',
    GBilling = 'cloudbilling.googleapis.com',
    GDeployment = 'deploymentmanager.googleapis.com',
    GBigquery = 'bigquery.googleapis.com',
    GManagment = 'servicemanagement.googleapis.com',
    GNetworking = 'servicenetworking.googleapis.com',
    GSqlAdmin = 'sqladmin.googleapis.com',
    GOsConfig = 'osconfig.googleapis.com',
    GContainerAnalysis = 'containeranalysis.googleapis.com',
    GNetApp = 'cloudvolumesgcp-api.netapp.com',
    GQserviceConsumer = 'serviceconsumermanagement.googleapis.com',
    GCloudResourceManager = 'cloudresourcemanager.googleapis.com',
    GDns = 'dns.googleapis.com',
    GManageAd = 'managedidentities.googleapis.com'
  }
