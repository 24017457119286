const four = 4;

export class PaymentProfile {
  public id: number;
  public paymentProfileId: number;
  public active: boolean;
  public firstName: string;
  public lastName: string;
  public fullName: string;
  public customerId: number;
  public billingAddress: string;
  public billingAddress2: string;
  public billingCity: string;
  public billingState: string;
  public state: string;
  public billingZip: string;
  public billingCountry: string;
  public fullAddress: string;
  public paymentType: string;
  public paymentTypeLabel: string;
  public bankName: string;
  public bankRoutingNumber: string;
  public bankAccountNumber: string;
  public cardType: string;
  public cardNumber: string;
  public cardNumberReadable: string;
  public expirationMonth: number;
  public expirationYear: number;
  public expirationDate: string;

  constructor(data?: any) {
    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          this[key] = data[key];
        }
      }

      if (this.cardNumber) {
        this.cardNumberReadable = this.cardNumber.substring(this.cardNumber.length - four, this.cardNumber.length);
      }
      this.fullName = `${this.firstName} ${this.lastName}`;
      this.expirationDate = `${this.expirationMonth}/${this.expirationYear}`;
      const plainAddress = `${this.billingAddress} ${this.billingCity}, ${this.billingState} ${this.billingZip}, ${this.billingCountry}`;
      this.fullAddress = plainAddress.replace(/null/g, '');
    }
  }
}
