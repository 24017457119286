export const locale = {
  lang: 'es',
  'configure_region_server_roles' : {
    'SECTION_TITLE': 'Roles de infraestructura',
    'DOMAIN_CONTROLLER_TITLE': 'Controladores de dominio del Directorio Activo:',
    'DOMAIN_CONTROLLER_DESCRIPTION': `Esta implementación utiliza el servicio administrado de Google para Microsoft Active Directory.
                                      No se requieren controladores de dominio.`,
    'DOMAIN_CONTROLLER_DESCRIPTION_ADEXIST': `Esta implementación utiliza un dominio existente. No se requieren controladores de dominio.`,
    'BROKER_TITLE': 'Agentes de conexión a escritorio remoto:',
    'BROKER_DESCRIPTION': `Se requiere Microsoft SQL para admitir Hight Availability para el rol de Agente de Escritorio remoto.
                           Se creará una instancia de SQL administrado por Google para esta región.`,
    'GATEWAY_TITLE': 'Servidores de puerta de enlace de escritorio remoto:',
    'GATEWAY_DESCRIPTION': `Se creará un balanceador de carga de Google para esta región.`,
    'ERROR_PATTERN': 'Campo inválido',

  }
};
