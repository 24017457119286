import { SoltenantGroup } from '../common';

export class CollectionPool {
    public collectionPoolId: number;
    public collectionName: string;
    public collectionTypeCode: string;
    public operatingSystem: string;
    public usersPerHost: number;
    public usersPerServer: number;
    public diskSize: number;
    public diskTypeId: number;
    public machineType: Object;
    public workLoad: string;
    public soleTenantGroup: SoltenantGroup;
    public failedProcess: string;
    // tslint:disable-next-line:cyclomatic-complexity
    constructor(data ? : any) {
        this.collectionPoolId = data && data.collectionPoolId ? data.collectionPoolId : 0;
        this.collectionName = data && data.collectionName ? data.collectionName : '';
        this.collectionTypeCode = data && data.collectionTypeCode ? data.collectionTypeCode : '';
        this.operatingSystem = data && data.operatingSystem ? data.operatingSystem : '';
        this.usersPerHost = data && data.usersPerHost ? data.usersPerHost : 0;
        this.usersPerServer = data && data.usersPerServer ? data.usersPerServer : 0;
        this.diskSize = data && data.diskSize ? data.diskSize : 0;
        this.diskTypeId = data && data.diskTypeId ? data.diskTypeId : 0;
        this.machineType = data && data.machineType ? data.machineType : null;
        this.workLoad = data && data.workLoad ? data.workLoad : '';
        this.soleTenantGroup = data && data.soleTenantGroup ? data.soleTenantGroup : new SoltenantGroup();
        this.failedProcess = data && data.failedProcess ? data.failedProcess : '';
    }
}
