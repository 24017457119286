export const locale = {
  lang: 'es',
  'warning_modal_cancel_subscription' : {
    CANCEL_WHAT_HAPPEN: '¿Qué va a pasar?',
    CANCEL_SUBCRIPTION_MESSAGE1: 'La tarjeta de crédito del cliente ya no se cargará después de que la cancelación surta efecto.',
    CANCEL_SUBCRIPTION_MESSAGE2: 'La cancelación surte efecto inmediatamente si selecciona INMEDIATAMENTE.',
    CANCEL_SUBCRIPTION_MESSAGE3: 'La cancelación tiene efecto alrededor del {{date}} si selecciona FIN DEL PERIODO.',
    CANCEL_SUBCRIPTION_MESSAGE4: 'No se harán reembolsos ni prorrateos.',
    CANCEL_SUBCRIPTION_MESSAGE6: 'Se puede reactivar la suscripción cancelada.',
    DELETE_IMMEDIATELY: 'INMEDIATAMENTE',
    DELETE_END_PERIOD: 'FIN DEL PERIODO',
    CANCEL: 'Cancelar',
    CANCELING: 'Cancelando ...',
  }
};
