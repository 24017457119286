export class Ticket {
    public requester: string;
    public collaborators: string[];
    public priority: string;
    public subject: string;
    public description: string;
    constructor(data?: any) {
        this.requester = data && data.requester ? data.requester : '';
        this.collaborators = data && data.collaborators ? data.collaborators : [];
        this.priority = data && data.priority ? data.priority : '';
        this.subject = data && data.subject ? data.subject : '';
        this.description = data && data.description ? data.description : '';
    }
}
