export const locale = {
  lang: 'en',
  warning_modal_autoscaling: {
    FORCE_UPDATE: 'Begin now (servers are rebooted within 5 minutes)',
    NO_FORCE_UPDATE: 'Begin At:',
    FORCE_UPDATE_NOTE: 'The changes you have made require a redeployment of the existing Session Hosts in this Collection Pool. This will affect the availability of the Collection Pool and users may be unable to access the Collection Pool during this time.',
    CANCEL: 'Cancel',
    SAVE: 'Save',
    SAVING: 'Saving ...',
    PROCESS_TO_APPLY: 'There are autoscaling configurations to be applied:',
    NOTE: 'This process may take several hours (or more) depending on the number of Session Hosts.',
    NOTE_TITLE: 'NOTE:',
    ERROR_REQUIRED: 'This field is required'
  }
};
