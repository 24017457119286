import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'underscore';

export const fileExtensionValidator = function (extensionList: string[]): ValidationErrors | null {
    return (control: AbstractControl) => {
        const value: string = control.value || '';
        if (!value) {
            return null;
        }
        const separator = '.';
        const splited = value.split(separator);
        const extension = separator + splited[splited.length - 1];
        if (!_.contains(extensionList, extension)) {
            return {
                extension: 'Not allowed'
              };
        }
    };
};
