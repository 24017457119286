const mapSettingProperty = {
  'True': true, 'False': false
};
const defaultDbd = 32;
const defaultOc = 7;
const defaultTimeout = 30;

export class ScaleSettings {
  emit(settings: any) {
    throw new Error('Method not implemented.');
  }
  public im: boolean; // integrityMonitoring
  public lda: boolean; // localDriveAccess
  public pr: boolean; // printerRedirection
  public sb: boolean; // secureBoot
  public sla: boolean; // stackdriverLoggingAgent
  public sma: boolean; // stackdriverMonitoringAgent
  public tma: boolean; // taskManagerAccess
  public vTPM: boolean;
  public usto: string; // userSessionTimeOut
  public du: string; // dynamicUptime
  public fsl: boolean; // fsLogixProfileContainers
  public upd: boolean; // UserProfileDisk
  public npp: boolean; // NonPersistProfile
  public crdp: boolean; // Set custom RDP parameters
  public cb: boolean; // Clipboard
  public sct: boolean; // Timezone
  public csh: boolean; // Command shells
  public mxrdp: boolean; // Maximize
  public fuam: boolean; // Fullscreen
  public apr: boolean; // Audio Playback
  public acr: boolean; // Audio Capture
  public dbd: string; // Display Bit Depth
  public dr: string; // Display Resolution
  public oc: string; // Optimize Connection
  public sws: string; // Windows Shortcuts
  public dis: string; // Disconect session
  public lods: string; // Log off session

  constructor(settings: any) {
    this.im = mapSettingProperty[settings.im];
    this.lda = mapSettingProperty[settings.lda];
    this.pr = mapSettingProperty[settings.pr];
    this.sb = mapSettingProperty[settings.sb];
    this.sla = mapSettingProperty[settings.sla];
    this.sma = mapSettingProperty[settings.sma];
    this.tma = mapSettingProperty[settings.tma];
    this.vTPM = mapSettingProperty[settings.vTPM];
    this.usto = settings.usto;
    this.du = settings.du;
    this.fsl = mapSettingProperty[settings.fsl];
    this.upd = mapSettingProperty[settings.upd];
    this.npp = mapSettingProperty[settings.npp];
    this.crdp = mapSettingProperty[settings.crdp] ? true : false;
    this.cb = mapSettingProperty[settings.cb] ? true : false;
    this.sct = settings.sct ? mapSettingProperty[settings.sct] : true;
    this.csh = mapSettingProperty[settings.csh] ? true : false;
    this.mxrdp = settings.mxrdp ? mapSettingProperty[settings.mxrdp] : true;
    this.fuam = mapSettingProperty[settings.fuam];
    this.apr = settings.apr ? mapSettingProperty[settings.apr] : true;
    this.acr = settings.acr ? mapSettingProperty[settings.acr] : true;
    this.dbd = settings.dbd ? settings.dbd : defaultDbd;
    this.dr = settings.dr ? settings.dr : 'Auto';
    this.oc = settings.oc ? settings.oc : defaultOc;
    this.sws = settings.sws ? settings.sws : 'Fullscreen';
    this.dis = settings.dis ? settings.dis : defaultTimeout;
    this.lods = settings.lods ? settings.lods : defaultTimeout;
  }
}
