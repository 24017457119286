export class SnapshotPlan {
    public name : string;
    public description : string;
    public code : string;
    public retention : number;
    public frequency : any;
    public schedule : string;
    public serverIds: Array<number>;
    public backupPlanId: number;
    public frequencyCode: string;
    public lastDay: boolean;
    public day: number;
    constructor(data?: any) {
        this.name = data && data.name ? data.name : '';
        this.description = data && data.description ? data.description : '';
        this.code = data && data.code ? data.code : '';
        this.retention = data && data.retention ? data.retention : 0;
        this.frequency = data && data.frequency ? data.frequency : '';
        this.schedule = data && data.schedule ? data.schedule : '';
        this.serverIds = data && data.serverIds ? data.serverIds : [];
        this.backupPlanId = data && data.backupPlanId ? data.backupPlanId : 0;
        this.frequencyCode = data && data.frequencyCode ? data.frequencyCode.toLowerCase() : '';
        this.day =  data && Number.isInteger(data.day) ? data.day : null;
        this.lastDay = data && data.lastDay;
    }
}
