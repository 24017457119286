import { Injectable } from '@angular/core';
import * as _ from 'underscore';

import { GauthService, GcpService, GiamService } from 'src/app/core/shared/integrations';
import { saRolesProvType } from '../const';
import { ProvisionTypeApis } from '../enum';


@Injectable({
  providedIn: 'root'
})
export class ServiceAccountRolesService {

    public permissions : Object;
    public errors : Object;
    public projectId: string;
    public clientId;
    public provTypeApis : ProvisionTypeApis;

    constructor( private giamService: GiamService, private gcpService: GcpService,
                 private gauthService: GauthService) {

      this.loadDefaultApis();

    }

    public initService(projectId: string, clientId: string, provTypeApis : ProvisionTypeApis ) : void {
        this.projectId = projectId;
        this.clientId = clientId;
        this.provTypeApis = provTypeApis;
    }

    public assignPermissions(): Promise<void> {
       return new Promise((resolve, reject) => {
          this.errors = null;
          if (this.isClientPermissionsStorageSaved() ) {
              resolve();
          }
          const aditionalRol = [];
          const serviceAccount = {email: this.clientId};

          const roles = saRolesProvType[this.provTypeApis];
          for (let index = 0; index < roles.length; index++) {
            aditionalRol.push(this.gcpService.createRol(serviceAccount, roles[index]));
          }

          this.gcpService.addPolicy(this.projectId, serviceAccount, aditionalRol).then((policy) => {
              this.permissions = policy;
              this.saveInStoragePermissions();
              resolve();
          }).catch((ex) => {
              this.errors = ex;
              resolve();
          });
        });
    }

    private saveInStoragePermissions(): void {
      sessionStorage.setItem('clientId', this.clientId);
      sessionStorage.setItem('provisionTypeApis', this.provTypeApis);
    }

    private isClientPermissionsStorageSaved(): boolean {
      const clientId = sessionStorage.getItem('clientId');
      const provisionTypeApis = sessionStorage.getItem('provisionTypeApis');
      return ( clientId === this.clientId && provisionTypeApis === this.provTypeApis );
    }

    private loadDefaultApis(): void {
        this.gauthService.loadAuth2();
        this.giamService.loadGiam();
        this.gcpService.loadGp();
    }

}
