export class SoleTenant {
    public soleTenantConfigurationId: number;
    public configureLicensing: boolean;
    public licenseKey: string;
    public kmsServer: string;
    public deploymentId: number;
    public active: boolean;
    public maintenancePolicy: string;
    public byol: boolean;
    public licenseList: Array<object>;
    constructor(data?: any) {
      this.soleTenantConfigurationId =  data && data.soleTenantConfigurationId ? data.soleTenantConfigurationId : 0;
      this.configureLicensing =  data && data.configureLicensing ? data.configureLicensing : false;
      this.licenseKey =  data &&  data.licenseKey ? data.licenseKey : '';
      this.kmsServer =  data &&  data.kmsServer ? data.kmsServer : '';
      this.deploymentId =  data &&  data.deploymentId ? data.deploymentId : 0;
      this.active =  data &&  data.active ? data.active : false;
      this.byol =  data &&  data.byol ? data.byol : false;
      this.maintenancePolicy =  data &&  data.maintenancePolicy ? data.maintenancePolicy : '';
      this.licenseList = data && data.licenseList ? data.licenseList: [];
    }
}
