export class Folder {
  public id: number;
  public name: string;
  public parentFolderPath: string;
  public removeInheritedPermissions: boolean;

  constructor(data ? : Object) {
    this.id = data && data['id'] ? data['id'] : 0;
    this.name = data && data['name'] ? data['name'] : '';
    this.parentFolderPath = data && data['parentFolderPath'] ? data['parentFolderPath'] : '';
    this.removeInheritedPermissions = data && data['removeInheritedPermissions'] ? data['removeInheritedPermissions'] : false;
  }

  public isEmpty(): boolean {
    return this.id === 0 && this.name === '';
  }
}
