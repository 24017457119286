export class CloudAppSettings {
    public remote: boolean;
    public restricted: boolean;
    public path: string;

    constructor(data?: any) {
      this.remote = data && data.remote ? data.remote : false;
      this.restricted = data && data.restricted ? data.restricted : false;
      this.path = data && data.path ? data.path : '';
    }
  }
