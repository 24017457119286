import * as moment from 'moment';

export class IntervalDate {
    public weekday: number;
    public startDate: string;
    public endDate: string;

    constructor(data?: any) {
        this.startDate = data && data.startDate ? data.startDate : null;
        this.endDate = data && data.endDate ? data.endDate : null;
        this.weekday = this.startDate ? moment(this.startDate).weekday() : null;
    }
}
