import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'underscore';

let external = '';
const minValues = {
    'win': { 'min': 50 },
    'lin': { 'min': 10}
};

const maxValue = 65536;

export const validateBootDiskSizeRange = function (oS: string): ValidationErrors | null {
    external = oS;

    return (control: AbstractControl) => {
        const value: string = control.value || '';
        if (!value) {
            return null;
        }
        if (external === 'win') {
          if (control.value !== undefined && (isNaN(control.value) ||
          control.value < minValues['win']['min'])) {
            return { 'min': 'Error min value' };
        } else if (control.value !== undefined && (isNaN(control.value) ||
         control.value > maxValue)) {
          return { 'max': 'Error max value' };
        } else if (control.value !== undefined && (isNaN(control.value) ||
        control.value < minValues['win']['min'] || control.value > maxValue)) {
          return { 'max': 'Error total range value' };
        }
          return null;
        } else if (external === 'lin') {
          if (control.value !== undefined && (isNaN(control.value) ||
          control.value < minValues['lin']['min'])) {
            return { 'min': 'Error min value' };
        } else if (control.value !== undefined && (isNaN(control.value) ||
         control.value > maxValue)) {
          return { 'max': 'Error max value' };
        } else if (control.value !== undefined && (isNaN(control.value) ||
        control.value < minValues['lin']['min'] || control.value > maxValue)) {
          return { 'max': 'Error total range value' };
        }
          return null;
        }

    };
};

