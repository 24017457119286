import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { environment } from '../../../../environments/environment';
import { Organization, ValueDto } from '../../../models/common';
import { SubscriptionComponentName } from '../../../models/enums/subscription-component';

@Injectable({
  providedIn: 'root'
})
export class AddonSubscriptionService {

  constructor(private http: HttpClient) { }

  public postBuyComponent(organizationId: number,
                          subsComponent: SubscriptionComponentName, quantity: number): Observable<ValueDto<boolean>> {
    const paramsObj = new HttpParams().set('quantity', quantity.toString());
    return this.http.post<ValueDto<boolean>>
    (`${environment.apiUrl}organizations/${organizationId}/subscriptionComponents/${subsComponent}/buy`, {}, { params: paramsObj });
  }

  public getOrganization(organizationId: number): Observable<Organization> {
    return this.http.get<Organization>(`${environment.apiUrl}organizations/${organizationId}`);
}

}
