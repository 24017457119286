import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { FormButtonsModule } from './../form-buttons/form-buttons.module';

import { AutoUnsubscribeModule } from '../auto-unsubscribe/auto-unsubscribe.module';
import { ModalLayoutModule } from '../modal-layout/modal-layout.module';
import { ResetPasswordComponent } from './reset-password.component';

@NgModule({
    imports: [CommonModule, NgbModule, TranslateModule, ReactiveFormsModule,
              FormButtonsModule, AutoUnsubscribeModule, ModalLayoutModule],
    declarations: [ResetPasswordComponent],
    exports: [ResetPasswordComponent]
})
export class ResetPasswordModule {
}
