import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';

import { locale as english } from './i18n/manage-resource-in-table.en';
import { locale as spanish } from './i18n/manage-resource-in-table.es';

const enterKeyCode = 13;
@Component({
  selector: 'app-manage-resource-in-table',
  templateUrl: './manage-resource-in-table.component.html',
  styleUrls: ['./manage-resource-in-table.component.scss']
})
export class ManageResourceInTableComponent implements OnInit, OnChanges, AfterViewInit {

  @Input()
  public title: string;
  @Input()
  public titleHelper: string;
  @Input()
  public inputsArray: any[];
  @Input()
  public showActionsList: { 'delete': Array<object>, 'edit': Array<object> };
  @Input()
  public showEditAction: boolean;
  @Input()
  public showRetryAction: boolean;
  @Input()
  public isLoadingData: boolean;
  @Input()
  public resourcesList: any;
  @Input()
  public customStyles: {
    bgClass: string, innerContainerClass: string, resourceContainerClass: string, formClass?: string, formInputClass?: string
  };
  @Input()
  public optionsForm?:  { 'disabled': Array<object> }; // add new property to try in form
  @Input()
  public disabledComponent: boolean;
  @Input()
  public serverErrors: Object;
  @Input()
  public changeManageResource: boolean;

  @Output()
  public resourceUpdated = new EventEmitter<any>();
  @Output()
  public retryResource = new EventEmitter<any>();

  public resourcesForm: FormGroup;
  public validationType: any;
  public itemsArray: any[];
  public actionPressed: boolean;
  public errorRequired: string;
  public invalidPattern: string;
  public errorEmail: string;
  public actionsBtn: { type: any; label: any; }[];
  public errorMaxLength: string;
  public isEditing: boolean;
  public indexSelected: number;
  public errorDuplicated: string;
  public currentResourceArray: Array<object>;
  public propertyInUseText: string;
  public msgTranslateParam: string;
  public filterInputArray: Array<object>;

  constructor(private formBuilder: FormBuilder, private translate: TranslateService) {
    this.resourcesForm = this.formBuilder.group({});
  }

  public ngOnInit() {
    this.itemsArray = [];
    this.validationType = [];
    this.actionPressed = false;
    this.isEditing = false;
    this.currentResourceArray = [];
    if (!this.customStyles) {
      this.customStyles = {bgClass: '', innerContainerClass: '', resourceContainerClass: '', formClass: '', formInputClass: ''};
    }
    this.customStyles.bgClass = !this.customStyles.bgClass ? '' : this.customStyles.bgClass;
    this.customStyles.innerContainerClass = !this.customStyles.innerContainerClass ? '' : this.customStyles.innerContainerClass;
    this.customStyles.resourceContainerClass = !this.customStyles.resourceContainerClass ? '' : this.customStyles.resourceContainerClass;
    this.customStyles.formClass = this.customStyles.formClass ? this.customStyles.formClass : '';
    this.customStyles.formInputClass = this.customStyles.formInputClass ? this.customStyles.formInputClass : '';

    _.map(this.inputsArray, (input: any) => {
      this.addItem(input);
      this.validationType[input.id] = input.validations;
    });
    if ( this.disabledComponent) {
      this.resourcesForm.disable();
    }
    this.filterInputArray = this.inputsArray !== undefined ? this.inputsArray.filter( x => x.type !== 'hidden'): [];
    setTimeout(() => {
      this.setTranslations();
    });
  }
  public ngAfterViewInit() {
    this.setTranslations();
  }
  public ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.isLoadingData) {
      this.isLoadingData = changes.isLoadingData.currentValue;
    }
    if (changes && changes.showEditAction && changes.showEditAction.currentValue) {
        this.resetValues();
    }
    if (changes && changes.disabledComponent && !changes.disabledComponent.currentValue) {
      this.resourcesForm.enable();
    }
    if (changes && changes.inputsArray && !changes.inputsArray.firstChange) {
      this.inputsArray = changes.inputsArray.currentValue;
      this.updateInputsArrayValidations();
    }
  }
  get f() { return this.resourcesForm.controls; }

  public addItem(item): void {
    this.resourcesForm.addControl(item.id, new FormControl(item.value));
  }
  public setValidations(input: string): any {
    for (const key in this.f) {
      if (key === input) {
        this.f[key].setValidators(this.validationType[key]);
        this.f[key].updateValueAndValidity();
        break;
      } else if (!input) {
        this.f[key].setValidators(this.validationType[key]);
        this.f[key].updateValueAndValidity();
      }
      if (this.f[key].invalid) { this.f[key].markAsUntouched();}
    }
    this.setTranslations();
  }

  public filterElementListResource(item: any): object {
    return item.filter( x => x.type !== 'hidden');
  }

  public isDisabledPropertyFormByExp( property: string , item: object, items: Array<object>, type: string ): boolean {
      if (!this.optionsForm || !this.optionsForm[property]) {return true; }
      let findEl = null;
      let findProperty = null;
      if (this.optionsForm[property] instanceof Array ) {
            item['type'] = type;
            findEl = _.findWhere(this.optionsForm[property], item);
            if ( findEl && findEl.conditions instanceof Array) {
                  let run = true;
                  let j = 0;
                  while (run) {
                    findProperty = _.findWhere( items, findEl.conditions[j] );
                    run = findProperty && j < findEl.conditions.length - 1;
                    j++;
                  }
            } else { return true; }
      }
      return findEl && findProperty ? true : false;
  }

  /**
   * Display button by expression
   * @param item
   */
  public visibilityActionByExp(item: any, action: string): boolean {
      let visible = true;
      this.setTranslations();
      if (!this.showActionsList) {
            return false;
      }
      if (this.showActionsList[action] === true) {
         return this.showEditAction;
      }
      if (this.showActionsList[action] instanceof Array && this.showActionsList[action].length > 0 && item instanceof Array ) {
            let i = 0;
            let run = true;
            while (run) {
              visible = _.findWhere(item, this.showActionsList[action][i]) ? true : false;
              run = visible && i < this.showActionsList[action].length - 1;
              i++;
            }
        } else {
            visible = false;
        }
      return visible && this.showEditAction;
  }

  public resetValues(): void {
    this.isEditing = false;
    _.map(this.f, (formElement: any) => {
        formElement.reset();
        formElement.enable();
    });
  }

  public getValueItem(element: object, value: any): boolean {
      if (element['checkDefault'] === true &&  element['type'] === 'radio' && this.resourcesList.length === 0) {
         return true;
      } else {
        return _.isNull(value) ? element['value'] :  value;
      }
  }

  public updateCurrentResourceArray(): void {
      this.currentResourceArray = [];
      _.map(this.f, (formElement: any, key: any) => {
          const element = _.findWhere(this.inputsArray, {id: key});
          const row = {
              id: key,
              value:  this.getValueItem(element, formElement.value),
              disabled: true,
              type: element && element.notDefaultInput ? element.type : false
          };
          if ( row.type === 'radio') { this.resourcesForm.get(row.id).setValue(row.value);  }
          this.currentResourceArray.push(row);
      });
      _.forEach( this.currentResourceArray, (element : any) => {
          const disabled = this.isDisabledPropertyFormByExp( 'disabled' , { id: element.id }, this.currentResourceArray, 'list'  );
          element.disabled = disabled;
      });
  }

  public actionConfirmation(): void {
    this.updateCurrentResourceArray();
    this.setValidations(null);
    if (this.resourcesForm.invalid) {
      return;
    }

    if (!this.isEditing) {
      this.resourcesList.push(this.currentResourceArray);
    } else if (this.isEditing ) {
      this.resourcesList[this.indexSelected] = this.currentResourceArray;
      this.isEditing = false;
    }

    this.resourceUpdated.emit(this.resourcesList);
    // tslint:disable-next-line: forin
    for (const key in this.f) {
      this.f[key].setValidators(null);
      this.f[key].updateValueAndValidity();
      this.f[key].enable();
      this.f[key].markAsUntouched();
      const item = _.findWhere(this.filterInputArray, {id: key});
      if (item['type'] === 'text') { this.f[key].setValue(''); } else {this.f[key].reset(); }
    }
    this.setTranslations();
  }

  public deleteResource($index): void {
    this.resourcesList.splice($index, 1);
    this.resourceUpdated.emit(this.resourcesList);
    this.filterInputArray = this.inputsArray.filter( x => x.type !== 'hidden');
  }

  public editResource(items, index): void {
    this.indexSelected = index;
    _.map(items, (value : any) => {
      if (this.f[value.id]) {
        const disabled = this.isDisabledPropertyFormByExp( 'disabled' , { id: value.id }, items, 'form'  );
        disabled ? this.f[value.id].disable() : this.f[value.id].enable();
        this.resourcesForm.get(value.id).setValue(value.value);
      }
    });
    this.isEditing = true;
    this.setTranslations();
  }

  public retryResourceAction(item): void {
    this.retryResource.emit(item);
  }

  public manageKeys($event): void {
    if ($event.keyCode === enterKeyCode) {
      $event.preventDefault();
      this.actionConfirmation();
    }
  }

  public setMessageParam(translate: string, param): void {
    this.setTranslations();
    this.msgTranslateParam = this.translate.instant(translate, param);
  }

  public changeRadio(evt): void {
    _.forEach(this.resourcesList, (elements: Array<object>) => {
        const element = _.findWhere(elements, { id: evt.id, value: true });
        if (element) {
          element['value'] = false;
        }
    });
    evt.value = true;
    this.resourceUpdated.emit(this.resourcesList);
  }

  private updateInputsArrayValidations(): void {
    _.map(this.inputsArray, (input: any) => {
      this.validationType[input.id] = input.validations;
    });
  }

  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
    this.invalidPattern = this.translate.instant('manage_resource_in_table.ERROR_PATTERN');
    this.errorRequired = this.translate.instant('manage_resource_in_table.ERROR_REQUIRED');
    this.errorEmail = this.translate.instant('manage_resource_in_table.ERROR_EMAIL');
    this.errorMaxLength = this.translate.instant('manage_resource_in_table.MAXLENGTH');
    this.errorDuplicated = this.translate.instant('manage_resource_in_table.ERROR_DUPLICATED');
    this.propertyInUseText = this.translate.instant('manage_resource_in_table.PROPERTY_IN_USE');
    this.actionsBtn = [{ type: 'add', label: this.translate.instant('manage_resource_in_table.ADD') },
                       { type: 'delete', label: this.translate.instant('manage_resource_in_table.DELETE') },
                       { type: 'edit', label: this.translate.instant('manage_resource_in_table.EDIT') },
                       { type: 'retry', label: this.translate.instant('manage_resource_in_table.RETRY') },
                       { type: 'save', label: this.translate.instant('manage_resource_in_table.SAVE') },
                       { type: 'cancel', label: this.translate.instant('manage_resource_in_table.CANCEL')}];
  }

  public sizeValidationError(isMinSize: boolean, value: string): string {
    this.setTranslations();
    return isMinSize
      ? this.translate.instant('manage_resource_in_table.ERROR_MINSIZE', { val: value })
      : this.translate.instant('manage_resource_in_table.ERROR_MAXSIZE', { val: value });
  }
}
