export const locale = {
  lang: 'es',
  'sidebar' : {
    'DASHBOARD': `Inicio`,
    'CLOUD_DESKTOPS': `Escritorios en la nube`,
    'CLOUD_MANAGER': `Administración en la nube`,
    'CATALOG': `Catálogo`,
    'TASKS': `Tareas`,
    'INSIGHTS': `Reportes`,
    'USERS': `Usuarios`,
    'VM_INSTANCES': `Instancias`,
    'CLOUD_APPS': 'Aplicaciones',
    'CLOUD_GROUPS': 'Grupos de seguridad',
    'COLLECTION_POOLS': 'Pool de colecciones',
    'UPTIMES': 'Planificación de uso de servidor',
    'SNAPSHOTS': 'Respaldos',
    'VPNS': 'VPNs',
    'IMAGES': 'Imágenes',
    'FOLDERS': 'Carpetas/Carpetas compartidas',
    'WORKSTATIONS': 'Estaciones de trabajo',
    'PRINTERS': 'Impresoras',
    'NODES': 'Nodos',
    'SITES': 'Sitios',
    'SERVERS': 'Servidores',
    'FILES': 'Carpetas Compartidas',
    'SETTINGS': 'Configuraciones',
    'GENERAL': 'General',
    'REGIONS': 'Regiones',
    'ACTIVE_DIRECTORY': 'Directorio Activo',
    'DEPLOYMENT_SOLE_TENANT': 'Nodos de usuario único',
    'FILE_SHARES': 'Archivos compartidos',
    'ALL': `Todos los despliegues`,
    'PATCH_PROFILE': 'Parches del SO',
    'AUTO': 'Importación automática de Directorio Activo'
  }
};
