import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import {ModalService} from '../../core/shared/services/modal.service';

import { AutoUnsubscribeComponent } from '../auto-unsubscribe/auto-unsubscribe.component';

import { locale as english } from './i18n/warning-modal-continue.en';
import { locale as spanish } from './i18n/warning-modal-continue.es';



@Component({
  selector: 'app-warning-modal-continue',
  templateUrl: './warning-modal-continue.component.html',
  styleUrls: ['./warning-modal-continue.component.scss']
})
export class WarningModalContinueComponent extends AutoUnsubscribeComponent implements OnInit {
  @Input()
  public headerTittle: string;
  @Input()
  public bodyMessage: string;
  @Input()
  public bodyMessageText: string;
  @Input()
  public bodyInput: string;
  @Input()
  public isInfo: boolean;
  @Input()
  public isBodyMsgAsHtml: boolean;
  @Input()
  public hasCancelCallback: boolean;

  @Input()
  public actionButtons: string[];

  public canCloseModal$ = new Subject<boolean>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  public onActionConfirmation = new EventEmitter<any>();
  public actionPressed: boolean;
  public bodyInputModel: any;

  constructor( private modalService: ModalService, private translate: TranslateService) {
    super();
    const canCloseModalSubs = this.canCloseModal$.subscribe((data) => {
      if (data) {
        this.closeWarningModal();
      }
    });
    super.addSubscriptions(canCloseModalSubs);
  }

  // tslint:disable-next-line:member-access
  ngOnInit() {
    let cancelLabel = '';
    if (this.translate.getBrowserLang() === 'es') {
      cancelLabel = 'Cancelar';
    } else {
      cancelLabel = 'Cancel';
    }
    this.actionButtons.unshift(cancelLabel);
    this.actionPressed = false;
  }
  public closeWarningModal(): void {
    this.modalService.dismissAll();
  }
  public actionConfirmation(actionConfirmation): void {
    if (actionConfirmation) {
      this.onActionConfirmation.emit(this.bodyInputModel);
      this.actionPressed = true;
    } else {
      if (this.hasCancelCallback) {
        this.onActionConfirmation.emit('cancel');
      } else {
        this.closeWarningModal();
      }
    }
  }
}
