export const locale = {
  lang: 'es',
  'regions_list_post': {
    'PRIMARY': 'primaria',
    'DEPLOY_REGION': `Región del Despliegue`,
    'DEPLOY_REGIONS': `Regiones del Despliegue`,
    'IS_PRIMARY': 'Esta es la región primaria',
    'SUBNET': 'Subred VPC',
    'ZONE_FLAG': 'Seleccionar zonas manualmente',
    'ZONE_FLAG_HELP': 'Si está deshabilitado, CAS selecciona automáticamente dos zonas en la región para los recursos de implementación',
    'ZONE1': 'Zona 1',
    'ZONE2': 'Zona 2',
    'ADD_REGION': 'Adicionar Región',
    'ERROR_REQUIRED': 'Campo requerido',
    'ERROR_PATTERN': 'Campo inválido',
    'SELECT_REGION': 'Seleccionar una Región GCP',
    'CANCEL_BTN': 'Cancelar',
    'SAVE_BTN': 'Salvar',
    'SAVING': 'Salvando...',
    'DESCRIPTION_REGION_POST_PROVISION': 'Las regiones son los centros de datos de GCP que contienen la infraestructura de su despliegue. Agregar una región crea varios servidores y servicios de infraestructura (AD, RD Brokers y Gateways, etc.) en esa región y le permite extender un Pool de Colecciones a esa región. ',
    'DESCRIPTION_REGION_POST_PROVISION_1': 'Al eliminar una región, se eliminan todos los servidores y servicios de esa región.',
    'DESCRIPTION_REGION_POST_PROVISION_2': 'No puede eliminar la región primaria.',
    'DNS_VERIFIED': 'DNS verificado',
    'DNS_FAILED': 'DNS fallido',
    'EXTERNAL_DNS_NAME': 'Nombre del DNS externo',
    'EXTERNAL_IP_ADDRESS': 'Dirección IP externa',
    'COPY': 'Copiar',
    'COPIED': 'Copiado',
    'SAVED': 'Datos guardados para la región: {{region}}',
    'SSL_SAVED': 'Certificado SSL guardado para la región: {{region}}',
    'SOLETENANT_CONFIGURATION': 'Configuración de Sole-tenant',
    'SOLETENANT_DESCRIPTION' : 'Si la compatibilidad con sole-tenant está habilitada en la implementación, ' +
                                  'proporcione el grupo de nodos predeterminado para las máquinas virtuales en esta región. ' +
                                  'Cambiar esta configuración no afecta a ninguna VM existente, solo a los nuevos VMS.',
    'SOLETENANT_GROUP_DESCRIPTION': 'Grupo de nodos Sole-tenant para esta región',
    'ERROR_DNS': 'No se puede actualizar el registro dns. Verifique los datos y vuelva a intentarlo',
    'ERROR_DNS_RECORD_FIELD': 'Registro dns inválido',
    'SOLETENANT_GROUP_NOT_FOUND': 'El grupo de nodos seleccionado no se pudo encontrar en GCP',
    'PENDING_CONFIGURATION': 'Pendiente a configuración',
    'READY': 'Listo'
  }
};
