import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BrandingActionService {
    private logoDarkSubj = new Subject<string>();
    private logoDarkObs = this.logoDarkSubj.asObservable();
    private logoLightSubj = new Subject<string>();
    private logoLightObs = this.logoLightSubj.asObservable();

    constructor() {}

    public getLogoDark(): Observable<string> {
        return this.logoDarkObs;
    }
    public getLogoLight(): Observable<string> {
        return this.logoLightObs;
    }
    public setLogoDark(data: string): void {
        this.logoDarkSubj.next(data);
    }
    public setLogoLight(data: string): void {
        this.logoLightSubj.next(data);
    }
}
