import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnInit, Renderer2 } from '@angular/core';
import * as _ from 'underscore';

@Directive({
  selector: '[appBetaTag]'
})
export class BetaTagDirective implements OnInit {
  @Input()
  public feature: string;
  public earlyAccess: string;

  constructor(private el: ElementRef, private renderer: Renderer2, @Inject(DOCUMENT) private document) { }
  public ngOnInit() {
    this.earlyAccess = JSON.parse(sessionStorage.getItem('earlyaccess'));
    this.checkPermissions(this.feature);
  }
  private checkPermissions(feauture: string): any {
    const existAccess = _.find(this.earlyAccess, (access: any) => access.feature === this.feature);
    if (existAccess && existAccess.scope !== '') {
      const child = document.createElement('span');
      child.textContent = existAccess.scope;
      if (existAccess.scope === 'new') {
      child.className = `badged text-white text-uppercase bg-success beta-tag m-l-5`;
      } else {
        child.className = `badged text-white text-uppercase bg-orange beta-tag m-l-5`;
      }
      this.renderer.appendChild(this.el.nativeElement, child);
    } else {
      if ( !existAccess && this.feature) {
        this.renderer.addClass(this.el.nativeElement, 'd-none');
      }
    }
  }

}
