import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as timezone from 'moment-timezone';
import { timer } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as _ from 'underscore';

import { EarlyAccessService } from 'src/app/core/security/early-access.service';
import { DeploymentsService } from 'src/app/deployments/deployments.service';
import { Deployment, EarlyAccessFeature, TimeZoneModel } from 'src/app/models';
import { AutoUnsubscribeComponent } from 'src/app/shared/auto-unsubscribe/auto-unsubscribe.component';
import {ProvisionContainerService} from '../../provision/provision-container/provision-container.service';
import { expressionExcludeRoutes, processViewerAccessRoutes } from '../const';
import { DataProgress } from '../model';
import { ProcessViewerListComponent } from '../process-viewer-list/process-viewer-list.component';
import { ProcessViewerService } from '../process-viewer.service';

import { locale as english } from '../i18n/process-viewer.en';
import { locale as spanish } from '../i18n/process-viewer.es';

const deep = 5;

@Component({
  selector: 'app-process-viewer-container',
  templateUrl: './process-viewer-container.component.html',
  styleUrls: ['./process-viewer-container.component.scss']
})
export class ProcessViewerContainerComponent extends AutoUnsubscribeComponent implements OnInit {
  @Output()
  public isExpandedEmitter = new EventEmitter<boolean>();
  @Output()
  public processCount = new EventEmitter<{listItemsCount: number, totalCount: number}>();

  public isCollapsed: boolean;
  public translateObj: object;
  public allowLoad: boolean;
  public deploymentId: number;
  public deep: number;
  public isVisible: boolean;
  public showComponent: boolean;
  public url: string;
  public dataProgress: DataProgress;
  public tz: string;
  public tzDescription: string;

  @ViewChild(ProcessViewerListComponent, {static: false})
  public processViewList: ProcessViewerListComponent;

  constructor(private translate: TranslateService, private provisionContainerService: ProvisionContainerService,
              private deploymentsMenuService: DeploymentsService,
              private router: Router, private earlyAccessService: EarlyAccessService,
              private processViewerService:  ProcessViewerService ) {
    super();
    this.setTranslations();
   }

  public ngOnInit() {
     const showComponent = this.earlyAccessService.verifyAccess(EarlyAccessFeature.ProcessViewer);
     if (showComponent) {
        this.showComponent = true;
        this.isCollapsed = true;
        this.isVisible = false;
        this.deep = deep;
        this.url = null;
        this.dataProgress = new DataProgress();
        this.subscribeDeployment();
        this.subscribeRoute();
      }
  }

  public collapseAccordion(): void {
     this.isCollapsed = !this.isCollapsed;
     this.isExpandedEmitter.emit(!this.isCollapsed);
     this.provisionContainerService.setIsExpanded(!this.isCollapsed);
     if (!this.isCollapsed ) {
        timer().subscribe(() => {
          window.scrollTo(0, document.body.scrollHeight);
        });
     }
  }

  public setDataProgress($event: DataProgress): void {
      this.dataProgress = $event;
  }

  public refreshPage(): void {
    this.isCollapsed = true;
    this.processViewList.setPage({});
  }

  public getCount($count: {listItemsCount: number, totalCount: number}): void {
    this.processCount.emit($count);
  }

  private subscribeDeployment(): void {
    const deploymentSelectedSubs = this.deploymentsMenuService.deploymentSelected.subscribe((company: Deployment) => {
      if (company) {
        if ( this.url === null ) { this.isVisible = true; }
        if ( this.deploymentId && this.deploymentId !== company.companyId) {
           this.processViewList.resetGridModel();
           this.processViewList.deploymentId = company.companyId;
           this.processViewList.setPage({});
        }
        this.tz = company.timeZone;
        this.deploymentId = company.companyId;
        const offset = timezone.tz(Date.now(), this.tz).format('Z z');
        this.tzDescription =  this.translateObj['timeZoneDescription'] + ' (' + offset + ') ' + this.tz;
      }
    });
    super.addSubscriptions(deploymentSelectedSubs);
  }

  /**
   *  init and change ro route
   */
  private subscribeRoute(): void {
    this.router.events.pipe(
     filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
          if ( event && event.url) {
             this.verifyAccess(event.url);
          }
    });
    const sub =  this.processViewerService.processViewerRoute.subscribe((url) => {
      if (url) { this.verifyAccess(url); }
    });
    super.addSubscriptions(sub);
  }

  private verifyAccess(url: string): void {
    let include = true;
    let validExp = false;
    this.url = '';
    processViewerAccessRoutes.forEach(route => {
       if ( url.includes(route)) {
          expressionExcludeRoutes.forEach(e => {
            const expression = new RegExp(e);
            if (expression.test(url) === true) {
                validExp = true; return;
            }});
          if ( !validExp) {
            this.url = route;
            include = false;
            return;
          }
       }
     });
    this.isVisible = include;
  }

  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
    this.translateObj = {
        'title': this.translate.instant('process_viewer.TITLE'),
        'errorTitle': this.translate.instant('process_viewer.ERROR_TITLE'),
        'progressTitle': this.translate.instant('process_viewer.PROGRESS_TITLE'),
        'refresh': this.translate.instant('process_viewer.REFRESH'),
        'timeZoneDescription': this.translate.instant('process_viewer.TIMEZONE_DESCRIPTION')
    };
  }

}
