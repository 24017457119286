import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import * as _ from 'underscore';

import { AuthenticationService } from '../../core/security/authentication.service';
import {FormMode, Priority, Ticket, ValidationErrorsCodes} from '../../models';
import { AutoUnsubscribeComponent } from '../../shared/auto-unsubscribe/auto-unsubscribe.component';
import {FormButtonV2ActionServices} from '../../shared/form-buttons-v2/form-button-v2-action.services';
import { SupportService } from './support.service';

import { locale as english } from './i18n/support.en';
import { locale as spanish } from './i18n/support.es';

const subjectMaxLength = 150;
@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss']
})

export class SupportComponent extends AutoUnsubscribeComponent implements OnInit {

    public supportTitle: string;
    public createTitle: string;
    public descriptionLabel: string;
    public ccsLabel: string;
    public requesterLabel: string;
    public subjectLabel: string;
    public priorityLabel: string;
    public errorRequired: string;
    public errorMaxLengthSub: string;
    public errorEmail: string;
    public ticketSuccess: string;
    public supportForm: FormGroup;
    public actionPressed: boolean;
    public notification: { type: string; message: string; };
    public actionsButtons: string[];
    public validationType: any;
    public validationErrors: any;
    public requesterValue: string;
    public priorities: Array<Priority>;
    public isInvalid: boolean;
    public mode: string;

    constructor(private translate: TranslateService, private formButtonV2ActionServices: FormButtonV2ActionServices,
                private modalService: NgbModal,
                private formBuilder: FormBuilder,
                private authenticationService: AuthenticationService,
                private notifierService: NotifierService,
                private supportService: SupportService) {
        super();
    }

    public ngOnInit() {
        this.setTranslation();
        this.actionPressed = this.isInvalid = false;
        this.formButtonV2ActionServices.setActionPressed(false);
        this.mode = FormMode.Edit;
        this.requesterValue = '';
        this.supportForm = this.formBuilder.group({
            Requester: [{ value: '', disabled: true }],
            Priority: [{ value: '', disabled: false }],
            Email: [{ value: '', disabled: false }],
            Subject: [{ value: '', disabled: false }],
            Description: [{ value: '', disabled: false }]
        });
        this.validationType = {
            'Requester': [Validators.required],
            'Priority': [Validators.required],
            'Email': [Validators.pattern('^(\\S)+@([a-zA-Z0-9])+([\\.-]?([a-zA-Z0-9])+)*(\\.([a-zA-Z0-9]){2,15})+$')],
            'Subject': [Validators.required, Validators.maxLength(subjectMaxLength)],
            'Description': [Validators.required]
        };
        const currentUserSubs = this.authenticationService.currentUser.subscribe((currentUser: Object) => {
            if (currentUser && currentUser['current']) {
                this.requesterValue = currentUser['current'].userName;
                this.f['Requester'].setValue(currentUser['current'].userName);
                this.f['Priority'].markAsUntouched();
            }
        });
        super.addSubscriptions(currentUserSubs);
    }

    get f() { return this.supportForm.controls; }

    public createTicket(): void {
      this.actionPressed = true;
      this.formButtonV2ActionServices.setActionPressed(true);
      const formData = {
            requester: this.requesterValue,
            priority: this.f.Priority.value.id,
            collaborators: [this.f.Email.value],
            subject: this.f.Subject.value,
            description: this.f.Description.value
        };
      const ticket = new Ticket(formData);
      const createTicketSubs = this.supportService.createTicket(ticket).subscribe(
            () => {
                this.notifierService.notify('success', this.ticketSuccess);
                this.closeSupportModal();
            },
            error => {
              if (error.error.errors !== undefined) {
                this.validationErrors = error.error.errors;
                Object.keys(this.validationErrors).forEach(prop => {
                  this.supportForm.get(prop).setErrors({ serverError: this.validationErrors[prop] });
                });
              }
              if (error.error && error.error.code === ValidationErrorsCodes.ValidationError40135) {
                this.notifierService.notify('error', error.error.message);
              }
              this.actionPressed = false;
              this.formButtonV2ActionServices.setActionPressed(false);
            });
      super.addSubscriptions(createTicketSubs);
    }
    public showTicketModal(content): void {
        this.ngOnInit();
        const ngbModalOptions: NgbModalOptions = { size: 'xl', backdrop: 'static', keyboard: false, centered: true };
        this.modalService.open(content, ngbModalOptions);
    }
    public closeSupportModal(): void {
        this.supportForm.reset();
        this.actionPressed = false;
        this.modalService.dismissAll();
    }
    public onActionConfirmation(confirm: boolean): void {
      if (confirm) {
        this.actionPressed = true;
        this.formButtonV2ActionServices.setActionPressed(true);
        this.setValidations();
        if (!this.isInvalid) {
          this.createTicket();
        }
      } else {
        this.closeSupportModal();
      }
    }
    public setValidations(input?: string): boolean {
      if (input) {
        this.f[input].setValidators(this.validationType[input]);
        this.f[input].updateValueAndValidity();
      } else {
        _.each(this.f, (value, key) => {
          if (this.validationType.hasOwnProperty(key)) {
            this.f[key].setValidators(this.validationType[key]);
            this.f[key].updateValueAndValidity();
          }
        });
      }
      this.isInvalid = this.supportForm.invalid;
      if (!this.isInvalid && this.actionPressed) {
        this.actionPressed = false;
        this.formButtonV2ActionServices.setActionPressed(false);
      }
      return this.supportForm.invalid;
    }
    public isEmptyData(): boolean {
      return !this.f['Priority'].value || !this.f['Subject'].value || !this.f['Description'].value;
    }
    public onTouched(formKey: string): void {
      this.f[formKey].markAsTouched();
    }
    private setTranslation(): void {
        if (this.translate.getBrowserLang() === 'es') {
            this.translate.use('es');
            this.translate.setTranslation('es', spanish);
        } else {
            this.translate.use('en');
            this.translate.setTranslation('en', english);
        }
        this.supportTitle = this.translate.instant('support.SUPPORT');
        this.createTitle = this.translate.instant('support.CREATE_TITLE');
        this.descriptionLabel = this.translate.instant('support.DESCRIPTION');
        this.ccsLabel = this.translate.instant('support.CCS');
        this.requesterLabel = this.translate.instant('support.REQUESTER');
        this.subjectLabel = this.translate.instant('support.SUBJECT');
        this.priorityLabel = this.translate.instant('support.PRIORITY');
        this.errorRequired = this.translate.instant('support.ERROR_REQUIRED');
        this.errorMaxLengthSub = this.translate.instant('support.MAXLENGTH', { val: subjectMaxLength });
        this.errorEmail = this.translate.instant('support.ERROR_EMAIL');
        this.ticketSuccess = this.translate.instant('support.SUCCESS');
        this.actionsButtons = [this.translate.instant('support.CANCEL'),
                               this.translate.instant('support.SAVE'),
                               this.translate.instant('support.SAVING')];
        this.priorities = [{ name: this.translate.instant('support.LOW'), id: 'low' },
                           { name: this.translate.instant('support.NORMAL'), id: 'normal' },
                           { name: this.translate.instant('support.HIGH'), id: 'high' }];
    }
}
