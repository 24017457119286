import { Injectable } from '@angular/core';
import * as _ from 'underscore';

@Injectable({
  providedIn: 'root'
})
export class EarlyAccessService {

  constructor() { }

  public verifyAccess(feature: string): boolean {
    const earlyAccess = JSON.parse(sessionStorage.getItem('earlyaccess'));
    return _.find(earlyAccess, (access: any) => access.feature.toLowerCase() === feature.toLowerCase()) ? true : false;
  }
}
