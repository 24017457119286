import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { AuthenticationService } from './core/security/authentication.service';
import {ModalService} from './core/shared/services/modal.service';
import { UrlService } from './core/shared/services/url.service';
import { User } from './models';
const keyCodeScape = 27;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  public currentUser: User;
  public previousUrl: string = null;
  public currentUrl: string = null;
  constructor(private translate: TranslateService, private authenticationService: AuthenticationService,
              private router: Router, private urlService: UrlService, private modalService: ModalService) {
    this.translate.setDefaultLang('en');
    (<any>window).Intercom('boot', { app_id:  environment.intercom.appId });

    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      if (x && x.user && x.user.email) {
        this.authenticationService.getIntercomHash().subscribe((response: Object) => {
          (<any>window).Intercom('update',
            {
              email: x.user.email,
              user_id: (this.currentUser as any).current.id,
              user_hash: response['value'],
              company: {id: `cas-partner-${x.user.partnerId}`}
            });
        }, () => {
          (<any>window).Intercom('update', {
            email: x.user.email,
            company: {id: `cas-partner-${x.user.partnerId}`}
          });
        });
      }
    });
  }
  public ngOnInit() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
  ).subscribe((event: NavigationEnd) => {
    if (this.previousUrl !== this.currentUrl && this.modalService.hasOpenModals()) {
      this.modalService.setCloseOnScape(true);
    }
     this.previousUrl = this.currentUrl;
     this.urlService.setPreviousUrl(this.previousUrl);
     this.currentUrl = event.url;
  });
    if (sessionStorage.length === 0 || localStorage.length === 0) {
    // Ask other tabs for session storage
    localStorage.setItem('getSessionStorage', JSON.stringify(Date.now()));
  }
}

  @HostListener('window:storage', ['$event'])
  public onStorage($event) {
    if ($event.key === 'getSessionStorage') {
      if (sessionStorage.length === 0) {
        // Ask other tabs for session storage
        localStorage.setItem('getSessionStorage', JSON.stringify(sessionStorage));
      }
      // Some tab asked for the sessionStorage -> send it
      localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
    } else if ( $event.key === 'sessionStorage' &&  sessionStorage.length === 0) {
      // sessionStorage is empty -> fill it

      const data = JSON.parse(localStorage.getItem('sessionStorage'));

      // tslint:disable-next-line:forin
      for (const key in data) {
        sessionStorage.setItem(key, data[key]);
      }
      this.authenticationService.currentUserSubject.next(JSON.parse(sessionStorage.getItem('currentUser')));
      localStorage.removeItem('sessionStorage');
    }
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === keyCodeScape) {
      this.modalService.setCloseOnScape(true);
    }
  }
}

