import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Branding } from 'src/app/models';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BrandingService {

  constructor(private http: HttpClient) { }

  public getBranding(organizationId: number): Observable<Branding> {
      return this.http.get<Branding>(`${environment.apiUrl}organizations/${organizationId}/branding`);
  }

  public updateBranding(organizationId: number, model: Object): Observable<Branding> {
    return this.http.put<Branding>(`${environment.apiUrl}organizations/${organizationId}/branding`, model);
  }
}
