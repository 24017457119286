import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {AutosizeModule} from 'ngx-autosize';
import { ClipboardModule } from 'ngx-clipboard';

import { TextAreaCopyComponent } from './text-area-copy.component';

@NgModule({
  declarations: [TextAreaCopyComponent],
  imports: [
    CommonModule,
    NgbModule,
    AutosizeModule,
    ClipboardModule
  ],
  exports: [TextAreaCopyComponent]
})
export class TextAreaCopyModule { }
