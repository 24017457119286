export const locale = {
    lang: 'es',
    support_token : {
        SUPPORT_TOKEN: 'Token de soporte',
        DEPLOYMENTS: 'Despliegues',
        EXPIRATION: 'Expiración',
        HOURS: 'horas',
        GENERATE: 'Generar',
        GENERATING: 'Generando ...',
        CANCEL: 'Cancelar',
        CLOSE: 'Cerrar',
        SUCCESS: 'Token de soporte generado correctamente',
        ERROR_REQUIRED: 'Campo requerido',
        INVALID_HOURS: 'Horas incorrectas',
        HOUR_HELP: 'Máximo 24',
        COPY: 'Copiar',
        COPIED: 'Copiado',
        COPY_MESSAGE: 'El token de soporte será solo visible ahora. Cópielo y guárdelo en sus registros.',
        TOKEN_INSTRUCTION: `El token de soporte es utilizado por el personal de soporte técnico de itopia
            para obtener acceso de visualización a su implementación por un tiempo limitado.`,
        TOKEN_TEXT_INFO: 'Nota: Este token de soporte solo es visible ahora. Cópielo y guárdelo para sus registros.',
    }
};
