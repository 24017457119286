import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'underscore';

const maximumAllowedPort = 65535 ;
const minimumAllowedPort = 0 ;
const value1 = '{{value1}}';
const value2 = '{{value2}}';
let external;

// custom validator to check firewall rules port
export const validateFirewallRule = function (translate : any): ValidationErrors | null {
    external = translate;
    return (control: AbstractControl) => {
        const value: any = control.value || '';
        if (!value) {
            return null;
        }
        const isValidMinimumAndMaximum = (item: number) : boolean => {
            return item >= minimumAllowedPort && item <= maximumAllowedPort;
        };
        const ranges = value.split('-');
        if (ranges.length > 1) {
            // If a range
            const portInitial = parseInt(ranges[0], 10);
            const portEnd = parseInt(ranges[1], 10);
            if (portInitial >= portEnd) {
                let msg = external && external.invalid ? external.invalid : '';
                msg = msg.replace(value1, portInitial);
                msg = msg.replace(value2, portEnd);
                return {
                    customValidation :  msg
                  };
            } else if (!isValidMinimumAndMaximum(portInitial) || !isValidMinimumAndMaximum(portEnd)) {
                let msg = external && external.error ? external.error : '';
                msg = msg.replace(value1, minimumAllowedPort);
                msg = msg.replace(value2, maximumAllowedPort);
                return {
                    customValidation :  msg
                  };
            }
        } else {
            // If a number
            if (!isValidMinimumAndMaximum(ranges)) {
                let msg = external && external.error ? external.error : '';
                msg = msg.replace(value1, minimumAllowedPort);
                msg = msg.replace(value2, maximumAllowedPort);
                return {
                    customValidation :  msg
                  };
            }
        }
    }
};
