import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    id: 'dashboard',
    path: '/dashboard',
    title: 'sidebar.DASHBOARD',
    icon: 'mdi mdi-gauge',
    class: '',
    extralink: false,
    submenu: [],
    feature: ''
  },
  {
    id: 'cloud_desktops',
    path: '',
    title: 'sidebar.CLOUD_DESKTOPS',
    icon: 'mdi mdi-widgets',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        id: 'cloud_desktops/users',
        path: '/deployment/:deploymentId/cloud_desktops/users',
        title: 'sidebar.USERS',
        icon: 'fas fa-user',
        class: '',
        extralink: false,
        submenu: [],
        feature: ''
      },
      {
        id: 'cloud_desktops/apps',
        path: '/deployment/:deploymentId/cloud_desktops/apps',
        title: 'sidebar.CLOUD_APPS',
        icon: 'fas fa-cubes',
        class: '',
        extralink: false,
        submenu: [],
        feature: ''
      },
      {
        id: 'cloud_desktops/cloudapps',
        path: '/deployment/:deploymentId/cloud_desktops/cloudapps',
        title: 'sidebar.CLOUD_APPS',
        icon: 'fas fa-cubes',
        class: '',
        extralink: false,
        submenu: [],
        feature: 'cloud_app_v2'
      },
      {
        id: 'cloud_desktops/groups',
        path: '/deployment/:deploymentId/cloud_desktops/groups',
        title: 'sidebar.CLOUD_GROUPS',
        icon: 'fas fa-users',
        class: '',
        extralink: false,
        submenu: [],
        feature: ''
      },
      {
        id: 'cloud_desktops/folders',
        path: '/deployment/:deploymentId/cloud_desktops/folders',
        title: 'sidebar.FILE_SHARES',
        icon: 'fas fa-folder',
        class: '',
        extralink: false,
        submenu: [],
        feature: 'file_shares'
      },
      {
        id: 'cloud_desktops/pools',
        path: '/deployment/:deploymentId/cloud_desktops/pools',
        title: 'sidebar.COLLECTION_POOLS',
        icon: 'fas fa-object-group',
        class: '',
        extralink: false,
        submenu: [],
        feature: 'collection_v2'
      },
    ],
    feature: ''
  },
  {
    id: 'cloud_manager',
    path: '',
    title: 'sidebar.CLOUD_MANAGER',
    icon: 'mdi mdi-cloud',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        id: 'cloud_manager/instances',
        path: '/deployment/:deploymentId/cloud_manager/instances',
        title: 'sidebar.VM_INSTANCES',
        icon: 'fas fa-desktop',
        class: '',
        extralink: false,
        submenu: [],
        feature: ''
      },
      {
        id: 'cloud_manager/uptimes',
        path: '/deployment/:deploymentId/cloud_manager/uptimes',
        title: 'sidebar.UPTIMES',
        icon: 'fas fa-clock',
        class: '',
        extralink: false,
        submenu: [],
        feature: ''
      },
      {
        id: 'cloud_manager/snapshots',
        path: '/deployment/:deploymentId/cloud_manager/snapshots',
        title: 'sidebar.SNAPSHOTS',
        icon: 'fas fa-database',
        class: '',
        extralink: false,
        submenu: [],
        feature: ''
      },
      {
        id: 'cloud_manager/vpns',
        path: '/deployment/:deploymentId/cloud_manager/vpns',
        title: 'sidebar.VPNS',
        icon: 'mdi mdi-router-wireless',
        class: '',
        extralink: false,
        submenu: [],
        feature: ''
      },
      {
        id: 'cloud_manager/images',
        path: '/deployment/:deploymentId/cloud_manager/images',
        title: 'sidebar.IMAGES',
        icon: 'fas fa-th',
        class: '',
        extralink: false,
        submenu: [],
        feature: ''
      },
      {
        id: 'cloud_manager/patch-profile',
        path: '/deployment/:deploymentId/cloud_manager/patch-profile',
        title: 'sidebar.PATCH_PROFILE',
        icon: 'fa fa-download',
        class: '',
        extralink: false,
        submenu: [],
        feature: 'patch_profile'
      }
    ],
    feature: ''
  },
  {
    id: 'tasks',
    path: '/deployment/:deploymentId/tasks',
    title: 'sidebar.TASKS',
    icon: 'fas fa-check-square',
    class: '',
    extralink: false,
    submenu: [],
    feature: ''
  },
  {
    id: 'insights',
    path: '/deployment/:deploymentId/insights',
    title: 'sidebar.INSIGHTS',
    icon: 'fas fa-eye',
    class: '',
    extralink: false,
    submenu: [],
    feature: ''
  },
  {
    id: 'settings',
    path: '',
    title: 'sidebar.SETTINGS',
    icon: 'fas fa-cog',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        id: 'settings/general',
        path: '/deployment/:deploymentId/settings/general',
        title: 'sidebar.GENERAL',
        icon: 'fab fa-elementor',
        class: '',
        extralink: false,
        submenu: [],
        feature: ''
      },
      {
        id: 'settings/regions',
        path: '/deployment/:deploymentId/settings/regions',
        title: 'sidebar.REGIONS',
        icon: 'fas fa-globe',
        class: '',
        extralink: false,
        submenu: [],
        feature: ''
      },
      {
        id: 'settings/active-directory',
        path: '/deployment/:deploymentId/settings/active-directory',
        title: 'sidebar.ACTIVE_DIRECTORY',
        icon: 'fas fa-id-badge',
        class: '',
        extralink: false,
        submenu: [],
        feature: ''
      },
      {
        id: 'settings/sole-tenant',
        path: '/deployment/:deploymentId/settings/sole-tenant',
        title: 'sidebar.DEPLOYMENT_SOLE_TENANT',
        icon: 'fas fa-cogs',
        feature: '',
        class: '',
        extralink: false,
        submenu: []
      }
    ],
    feature: ''
  }
];
