export class Subscription {
  public planId: number;
  public customerId: number;
  public billingProviderCode: string;
  public status: string;
  public productCode: string;
  public productName: string;
  public productDescription: string;
  public balanceInCents: number;
  public productPrice: number;
  public totalRevenueInCents: number;
  public productFamilyId: number;
  public trialStarted: string;
  public trialEnd: string;
  public created: string;
  public activatedAt: string;
  public canceledAt: string;
  public couponCode: string;
  public currentPeriodEndsAt: string;
  public cancelAtEndOfPeriod: boolean;
  public cancellationMessage: string;
  public newPendingPlan: string;

  constructor(data?: any) {
    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          this[key] = data[key];
        }
      }
    }
  }
}
