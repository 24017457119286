import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Integration, Region, ServerError } from 'src/app/models';
import { ApisServices } from 'src/app/models/enums/apis-services';
import { environment } from 'src/environments/environment';

const excludeErrorsLaunch = ['Regions', 'FwgcpSetting'];

@Injectable({
  providedIn: 'root'
})
export class ProvisionCustomService {
  private updatingSettingsSubject: BehaviorSubject<boolean>;
  public updatingSettings: Observable<boolean>;
  private saveProvisionStepSubject: BehaviorSubject<boolean>;
  public saveProvisionStep: Observable<boolean>;
  private provisionStepSavedSubject: BehaviorSubject<boolean>;
  public provisionStepSaved: Observable<boolean>;
  private regionsUpdatedSubject: BehaviorSubject<Region[]>;
  public regionsUpdated: Observable<Region[]>;
  private usersPerHostSubject: BehaviorSubject<number>;
  public usersPerHost: Observable<number>;
  private networkNameSubject: BehaviorSubject<string>;
  public networkName: Observable<string>;
  private integrationSubject: BehaviorSubject<string>;
  public integration: Observable<string>;
  private integrationObjectSubject: BehaviorSubject<Integration>;
  public integrationObject : Observable<Integration>;
  private errorLaunchDeploymentObjectSubject: BehaviorSubject<ServerError>;
  public errorLaunchDeploymentObject : Observable<ServerError>;
  private soletenantActiveSubject: BehaviorSubject<boolean>;
  public soletenantActive: Observable<boolean>;
  private addCountRequestSubject: BehaviorSubject<number>;
  public addCountRequest: Observable<number>;
  private provisionStepSubmitSubject: BehaviorSubject<boolean>;
  public provisionStepSubmit: Observable<boolean>;
  private doRefreshComponentSubject : BehaviorSubject<boolean>;
  public doRefreshComponent: Observable<boolean>;
  private excludeErrorsLaunch: Array<string>;
  private deleteImageSubject: BehaviorSubject<boolean>;
  public deleteImage: Observable<boolean>;
  constructor(private http: HttpClient) {
    this.updatingSettingsSubject = new BehaviorSubject<boolean>(false);
    this.updatingSettings = this.updatingSettingsSubject.asObservable();
    this.saveProvisionStepSubject = new BehaviorSubject<boolean>(false);
    this.saveProvisionStep = this.saveProvisionStepSubject.asObservable();
    this.provisionStepSavedSubject = new BehaviorSubject<boolean>(false);
    this.provisionStepSaved = this.provisionStepSavedSubject.asObservable();
    this.regionsUpdatedSubject = new BehaviorSubject<Region[]>([]);
    this.regionsUpdated = this.regionsUpdatedSubject.asObservable();
    this.usersPerHostSubject = new BehaviorSubject<number>(0);
    this.usersPerHost = this.usersPerHostSubject.asObservable();
    this.networkNameSubject = new BehaviorSubject<string>(undefined);
    this.networkName = this.networkNameSubject.asObservable();
    this.integrationSubject = new BehaviorSubject<string>('');
    this.integration = this.integrationSubject.asObservable();
    this.integrationObjectSubject =  new BehaviorSubject<Integration>(null);
    this.integrationObject = this.integrationObjectSubject.asObservable();
    this.errorLaunchDeploymentObjectSubject =  new BehaviorSubject<ServerError>(null);
    this.errorLaunchDeploymentObject = this.errorLaunchDeploymentObjectSubject.asObservable();
    this.soletenantActiveSubject = new BehaviorSubject<boolean>(null);
    this.soletenantActive = this.soletenantActiveSubject.asObservable();
    this.addCountRequestSubject =  new BehaviorSubject<number>(null);
    this.addCountRequest = this.addCountRequestSubject.asObservable();
    this.provisionStepSubmitSubject =  new BehaviorSubject<boolean>(null);
    this.provisionStepSubmit = this.provisionStepSubmitSubject.asObservable();
    this.doRefreshComponentSubject =  new BehaviorSubject<boolean>(null);
    this.doRefreshComponent = this.doRefreshComponentSubject.asObservable();
    const values = Object.values(ApisServices);
    this.excludeErrorsLaunch = excludeErrorsLaunch.concat(values);
    this.deleteImageSubject = new BehaviorSubject<boolean>(false);
    this.deleteImage = this.deleteImageSubject.asObservable();
  }
  public setUpdatingSettings(isUpdating: boolean): void {
    this.updatingSettingsSubject.next(isUpdating);
  }
  public setSaveProvisionStep(saveProvisionStep: boolean): void {
    this.saveProvisionStepSubject.next(saveProvisionStep);
  }
  public setProvisionStepSaved(provisionStepSaved: boolean): void {
    this.provisionStepSavedSubject.next(provisionStepSaved);
  }
  public setRegionsUpdated(regionsUpdated: Region[]): void {
    this.regionsUpdatedSubject.next(regionsUpdated);
  }
  public setUsersPerHostSubject(usersPerHost: number): void {
    this.usersPerHostSubject.next(usersPerHost);
  }
  public setNetworkName(networkName: string): void {
    this.networkNameSubject.next(networkName);
  }
  public putDeploymentSettings(deploymentId: number, settings: any): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}deployments/${deploymentId}/settings`, settings );
  }
  public getDeploymentSettings(deploymentId: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}deployments/${deploymentId}/settings`);
  }
  public launchDeployment(deploymentId: number, acceptLegal: boolean): Observable<any> {
    const paramsObj = new HttpParams().set('acceptLegal', acceptLegal.toString());
    return this.http.post<any>(`${environment.apiUrlV3}deployments/${deploymentId}/launch`, {} , { params: paramsObj } );
  }
  public setIntegrationActionValue(validIntegration: string): void {
    this.integrationSubject.next(validIntegration);
  }
  public setIntegration(integration: Integration): void {
     this.integrationObjectSubject.next(integration);
  }
  public setErrorsLaunchDeployment(errors: ServerError): void {
    this.errorLaunchDeploymentObjectSubject.next(errors);
 }
  public setSoletenantActive(active: boolean): void {
    this.soletenantActiveSubject.next(active);
}
  public addCountRequestCustom(count: number): void {
  this.addCountRequestSubject.next(count);
}
  public changeSubmitStep(submit: boolean): void {
  this.provisionStepSubmitSubject.next(submit);
}

  public setRefreshComponents(refresh: boolean): void {
  this.doRefreshComponentSubject.next(refresh);
}

  public getExcludeErrorsLaunch(): Array<string> {
   return this.excludeErrorsLaunch;
}

  public setDeleteImage(deleteImage: boolean): void {
    this.deleteImageSubject.next(deleteImage);
  }

}
