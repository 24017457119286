import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import * as _ from 'underscore';

import { environment } from 'src/environments/environment';

declare const gapi: any;

@Injectable({
  providedIn: 'root'
})
export class GauthService {
  private clientId = environment.gapi.clientId;

  private scope = environment.gapi.scopes.join(' ');

  public auth2: any;
  public user$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private zone: NgZone) { }

  public getUserProfile() {
    if (gapi) {
        return gapi.auth2.GoogleUser.get().getBasicProfile();
    }
    return null;
  }
  public getAuthResponse () {
    if (this.auth2 && this.auth2.currentUser) {
      return this.auth2.currentUser.get().getAuthResponse();
    }
    return null;
  }

  public getAuthUser() {
    const response = this.getAuthResponse();
    if (_.isEmpty(response)) {
        return 0;
    }
    return response;
  }

  public signIn(): void {
      this.auth2.signIn().then(user => {
        this.zone.run(() => {
        const profile =  user.getBasicProfile();
        const newUser = {
            id: profile.getId(),
            name: profile.getName(),
            email: profile.getEmail(),
            imageUrl: profile.getImageUrl(),
            givenName: profile.getGivenName(),
            familyName: profile.getFamilyName(),
            token: this.getAuthUser() ? this.getAuthUser().access_token : ''
          };
        this.user$.next(newUser);
        this.isLoggedIn$.next(true);
      });
      });
  }
  public signOut(): void {
      this.auth2.signOut().then(() => {
          this.zone.run(() => {
              this.isLoggedIn$.next(false);
              this.user$.next(null);
          });
      },
          (err) => {
              console.error(err);
          });
  }
  public loadAuth2(): void {
      gapi.load('auth2', () => {
          gapi.auth2.init({
              client_id: this.clientId,
              scope: this.scope,
          }).then((auth) => {
              this.zone.run(() => {
                  this.auth2 = auth;
                  this.isLoaded$.next(true);
              });
          },
          );
      });
  }
}
