export class Network {
    public dnsName : string;
    public externalDnsName: string;
    public regions : any[];

    constructor(data?: any) {
     this.dnsName =  data.dnsName || null;
     this.externalDnsName =  data.externalDnsName || null;
     this.regions =  data.regions || [];
     }
 }
