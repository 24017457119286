import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import * as _ from 'underscore';

import { BinaryExpressionService, OperationExpressionService, UnaryExpressionService } from 'src/app/core/shared/filters';
import { DeploymentsService } from 'src/app/deployments/deployments.service';
import { Datatable, Instance, ListDto } from 'src/app/models';
import { AutoUnsubscribeComponent } from '../../auto-unsubscribe/auto-unsubscribe.component';

import { locale as english } from './i18n/multiselect-instances.en';
import { locale as spanish } from './i18n/multiselect-instances.es';

const pageSizeValue = 10;

@Component({
  selector: 'app-multiselect-instances',
  templateUrl: './multiselect-instances.component.html',
  styleUrls: ['./multiselect-instances.component.scss']
})
export class MultiselectInstancesComponent extends AutoUnsubscribeComponent implements OnInit, AfterViewInit {
  @Input()
  public deploymentId: number;
  @Input()
  public containerClass: string;
  @Input()
  public currentInstanceIds: Array<number>;

  @Output()
  public selecteddInstances = new EventEmitter<Array<Instance>>();
  @Output()
  public loadedInstances = new EventEmitter<Array<Object>>();
  @Output()
  public isLoadingInstances = new EventEmitter<boolean>();
  @Output()
  public hasFilter = new EventEmitter<boolean>();

  public instanceTitle: string;
  public isLoading: boolean;
  public selectedInstances: Array<number>;
  public selectModelInstances: Datatable<Object> = new Datatable<Object>();
  constructor(private deploymentsService: DeploymentsService, private translate: TranslateService,
              private operationExpression: OperationExpressionService,
              private unaryOperation: UnaryExpressionService, private binaryExpression: BinaryExpressionService) {
    super();
    this.setCurrentLang();
  }

  public ngOnInit() {
    this.containerClass = this.containerClass ? this.containerClass : '';
    this.setTranslations();
  }
  public ngAfterViewInit() {
    this.setTranslations();
  }
  public selectInstance($event: Array<Instance>): void {
    const instancesTemp = [];
    $event.forEach((instance: Instance) => {
      instancesTemp.push(instance.instanceId);
    });
    this.selectedInstances = instancesTemp;
    this.selecteddInstances.emit($event);
  }
  public getInstances($event): void {
    setTimeout(() => {
      this.isLoading = true;
    });
    const params = {
      pageIndex: $event.CurrentPageNumber + 1 || 1,
      pageSize: pageSizeValue,
      filter: [{'propertyName': 'InstanceStatu.Code',
                'operation': this.operationExpression.expressionType.EQUALS, 'value': 'rdy', 'toLower': true,
                'unaryOperation': this.unaryOperation.expressionType.NONE, 'binaryOperation': this.binaryExpression.expressionType.OR},
               { 'propertyName': 'Active', 'operation': this.operationExpression.expressionType.EQUALS, 'value': 'true', 'toLower': true,
                 'unaryOperation': this.unaryOperation.expressionType.NONE, 'binaryOperation': this.binaryExpression.expressionType.AND },
               {'propertyName': 'ServerType.Code', 'operation': this.operationExpression.expressionType.EQUALS,
                'value': 'img', 'toLower': true, 'unaryOperation': this.unaryOperation.expressionType.NOT,
                'binaryOperation': this.binaryExpression.expressionType.AND}]
    };
    if ($event.searchValue) {
      params.filter.push({
        'propertyName': 'Name', 'operation': this.operationExpression.expressionType.CONTAINS, 'value': $event.searchValue,
        'toLower': true, 'unaryOperation': this.unaryOperation.expressionType.NONE,
        'binaryOperation': this.binaryExpression.expressionType.NONE
      });
    }
    this.hasFilter.emit($event.searchValue !== undefined && $event.searchValue !== null);
    const getInstancesSubs = this.deploymentsService.searchDeploymentInstances(this.deploymentId, params)
      .pipe(finalize(() => {
        this.isLoading = false;
        this.isLoadingInstances.emit(this.isLoading);
      }))
      .subscribe((instances: ListDto<Instance>) => {
        let mapInstancesAllowProp = instances.list.map(instance => {
          if (this.currentInstanceIds && this.currentInstanceIds.includes(instance.instanceId)
          || this.selectedInstances && this.selectedInstances.includes(instance.instanceId)) {
            instance.allow = true;
          }
          return instance;
        });
        mapInstancesAllowProp = _.sortBy(mapInstancesAllowProp, 'allow');
        this.selectModelInstances = {
          PageSize: pageSizeValue,
          TotalElements: instances.count,
          TotalPages: Math.ceil(instances.count / pageSizeValue),
          CurrentPageNumber: $event.CurrentPageNumber + 1,
          SortBy: 'name',
          SortDir: 'asc',
          Data: mapInstancesAllowProp
        };
        this.loadedInstances.emit(this.selectModelInstances.Data);
      });
    super.addSubscriptions(getInstancesSubs);
  }
  public setCurrentLang(): void {
    const browserLang = this.translate.getBrowserLang();
    const mapLangI18nFile = { es: spanish, en: english };
    if (!mapLangI18nFile[browserLang]) {
      this.translate.use('en');
      this.translate.setTranslation('en', mapLangI18nFile['en']);
    } else {
      this.translate.use(browserLang);
      this.translate.setTranslation(browserLang, mapLangI18nFile[browserLang]);
    }
  }

  private setTranslations(): void {
    this.setCurrentLang();
    this.instanceTitle = this.translate.instant('multiselect_instances.TITLE');
  }
}
