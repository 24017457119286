import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, timer } from 'rxjs';
import { catchError, finalize, map, take } from 'rxjs/operators';
import * as _ from 'underscore';

import { Apis, ApisStatus, BooleanDto, HttpStatusCodes, ServerError, StringDto, ValidationErrorsCodes } from 'src/app/models';
import { ApisServices } from 'src/app/models/enums/apis-services';
import { ProvisionCustomService } from 'src/app/provision/provision-custom/provision-custom.service';
import { AutoUnsubscribeComponent } from '../auto-unsubscribe/auto-unsubscribe.component';
import { ApisCheckManagerService } from './apis-check-manager.service';
import { apisUrl } from './const/apis-url';
import { ApisConfig } from './model';
import { ApisGroup } from './model/apis-group';

import { locale as english } from './i18n/apis-check-manager.en';
import { locale as spanish } from './i18n/apis-check-manager.es';

const countCheked = 3;
const timeInterval = 500;
const timeUp = 1000;

@Component({
  selector: 'app-apis-check-manager',
  templateUrl: './apis-check-manager.component.html',
  styleUrls: ['./apis-check-manager.component.scss']
})
export class ApisCheckManagerComponent extends AutoUnsubscribeComponent implements OnInit {
  @Input()
  public projectId: string;
  @Input()
  public deploymentId: number;
  @Input()
  public modeBackground: boolean;

  public apiGroup : Array<ApisGroup>;
  public apisConfig: Object;
  public apis: Array<Apis>; // all principal apis
  public visible: boolean; // hide or show component
  public apiNotLoadText: string;
  public allApisGroups: Array<Apis>; // all apis groups
  public skeepFailure: boolean; // skip request errors (interceptor)
  public hasDelay: boolean; // exclude time expecting for request

  @Output()
  public checkAllApisEvt = new EventEmitter<boolean>();
  @Output()
  public enableApiEvt = new EventEmitter<Apis>();

  constructor(private apisCheckManagerService: ApisCheckManagerService,
              private ref: ChangeDetectorRef, private translate: TranslateService,
              private provisionCustomService : ProvisionCustomService) {
                super();
               }

  public ngOnInit() {
    this.skeepFailure = false;
    this.apis = [];
    this.hasDelay = true;
    this.visible = false;
    this.apiGroup = [];
    this.subscribeLaunchDeployment();
  }
  /**
   * Show or Hide component
   * @param value
   */
  public setVisible( value: boolean): void {
      this.visible = value;
      if (this.visible && this.apis.length === 0) {
        this.loadConfApis();
      }
      this.ref.detectChanges();
  }

  public setApisGroup( apGroups: Array<ApisGroup>): void {
      this.apiGroup = apGroups;
  }

  public enableApi(api: Apis): void {
    this.enableApiEvt.emit(api);
  }

  public refreshApi(api: Apis): void {
    this.manageActionApis(true, api);
  }

  public manageActionAllApis(isChecked: boolean): void {
    _.forEach(this.apis, (api) => this.manageActionApis(isChecked, api) );
  }

  public manageActionApis(isChecked: boolean, api: Apis): void {
    const apisGroup = this.getApisGroupFromPrincipal(api);
    if ( apisGroup.length > 1) {
        this.actionsJoinApis(isChecked, api);
    } else {
      this.checkAndEnableApis(isChecked, api);
    }
  }
 /**
  * @param status | Status action api
  * @param mask | true => shows the loading but does not modify the loading attribute
  * @param singleApi | api
  * @param error | error message
  */
  public setStatusApis(status: ApisStatus, mask: boolean, singleApi?: Apis, error?: string, enableManually?: boolean): void {
    let value;
    value = status === ApisStatus.Success ? true : status === ApisStatus.Failed ? false : value;
    if ( !singleApi ) {
      _.map(this.apis, (api: string) => {
          this.apisConfig[api]['status'] = value;
          this.apisConfig[api]['loading'] = status === ApisStatus.Reset && !mask;
         }
      );
    } else {
        if (error && !value) {
          this.apisConfig[singleApi]['error'] = error;
          this.apisConfig[singleApi]['enableManually'] = enableManually;
          this.apisConfig[singleApi]['urlError'] = apisUrl[singleApi] + this.projectId;
        }
        this.apisConfig[singleApi]['status'] = value;
        this.apisConfig[singleApi]['loading'] = status === ApisStatus.Reset && !mask;
    }
    this.ref.detectChanges();
  }

  public getAllApisByStatus(status : ApisStatus): Array<Apis> {
    const apisResult = [];
    let value;
    if (status === ApisStatus.Failed) {
        value = false;
    } else {
        if (status === ApisStatus.Success ) {  value = true; }
    }
    const keys = Object.keys(this.apisConfig);
    _.forEach( keys, (api: Apis) => {
        if ( this.apisConfig[api]['status'] === value) {
            apisResult.push(api);
        }
      });
    return apisResult;
  }
  /**
   * Generic for bulk apis
   * @param isCheked
   * @param api
   */
  public actionsJoinApis(isCheked: boolean, api: Apis ): void {
    if ( !this.apisConfig[api]['loading'] ) {
        this.setStatusApis(ApisStatus.Reset, false, api);
        this.ref.detectChanges();
        const requestList = this.getRequestListBulk(isCheked, api);
        !isCheked ? this.actionsJoinApisEnable(requestList, api) :
        this.actionsJoinApisCheked(requestList, api);
    }
  }
  /**
   * Generic for single api
   */
  public checkAndEnableApis( isCheked: boolean, api: Apis) : void {
    if (  !this.apisConfig[api]['loading'] ) {
      const request = !isCheked ? this.apisCheckManagerService.enableApi(this.deploymentId, ApisServices[api], this.skeepFailure )
                                  : this.apisCheckManagerService.isEnabledApi(this.deploymentId, ApisServices[api], this.skeepFailure );
      this.setStatusApis(ApisStatus.Reset, false, api);

      !isCheked ? this.enableSingleApi(request, api) :
      this.checkSingleApi(request, api);
     }
  }

  /**
   * Async enable o check apis, the call await for operation
   * @param check
   */
  public syncEnableDisableSingleApis(check: boolean): Promise<boolean> {
      return new Promise((resolve, reject) => {
        const requests = [];
        const apisFailed = [];
        this.allApisGroups.forEach(api => {
           const req = !check ? this.apisCheckManagerService.enableApi(this.deploymentId, ApisServices[api], this.skeepFailure ) :
                                this.apisCheckManagerService.isEnabledApi(this.deploymentId, ApisServices[api], this.skeepFailure );
           requests.push(req);
        });
        const requestListSubs = forkJoin(requests)
        .subscribe((data: any[]) => {
          if (data.length === requests.length) {
              data.forEach( ( element, index, arr) => {
                  const isSuccess = check ? (element as StringDto).value === ApisStatus.Success :
                                            ( element as BooleanDto).value === true;

                  const principal = this.getApiPrincipalFromSecondary(this.allApisGroups[index]);
                  if ( isSuccess && !_.contains( apisFailed, principal )) {
                      this.setStatusApis(ApisStatus.Success, false, principal);
                  } else {
                    apisFailed.push(principal);
                    this.setStatusApis(ApisStatus.Failed, false, principal);
                  }
              });
              const failed = check ?  data.find(x => (x as StringDto).value === ApisStatus.Failed) :
                                      data.find(x => (x as BooleanDto).value === false);
              resolve(!failed);
          }
         }, () => {
           resolve(false);
         });
        super.addSubscriptions(requestListSubs);
    });
  }

  public showRequestError(errors, enableManually?: boolean): void {
    if (errors !== null && errors.status === HttpStatusCodes.BadRequest &&
      errors.code === ValidationErrorsCodes.ValidationError40027 ) {
        if (errors.data) {
          const servicesKey = Object.keys(ApisServices);
          const keys = Object.keys(errors.data);
          _.forEach(keys, (s) => {
              const k = servicesKey.filter(x => ApisServices[x] === s);
              const api = k.length > 0 ? k[0] : null;
              if (api && this.apisConfig && this.apiGroup) {
                if (this.apisConfig[api]) {
                  // principal api
                  this.setStatusApis(ApisStatus.Failed, false, api as Apis, errors.data[s], enableManually);
                } else {
                  // secondary api
                  const apiSecundary = this.allApisGroups.find(x => x === api );
                  if (apiSecundary) {
                    const principal =  this.getApiPrincipalFromSecondary(apiSecundary);
                    if (principal) {
                      this.setStatusApis(ApisStatus.Failed, false, principal as Apis);
                      this.setErrorApisGroup(principal, apiSecundary, errors.data[s], enableManually);
                    }
                  }
                }
              }
        });
      }
  }
}
  public loadConfApis(): void {
      this.setTranslations();
      this.apis = [];
      this.allApisGroups = [];
      this.apisConfig = {};
      if ( this.apiGroup instanceof Array) {
        _.map(this.apiGroup, (api) => {
            this.apis.push(api.principal);
            api.group.forEach(element => { this.allApisGroups.push(element); });
            this.apisConfig[api.principal] = new ApisConfig({
              title: this.getTitleApi(api.principal)
            });
        });
      }
}

  /**** Private methods****/
  /*
   * Enable Single Api
   * @param request
   * @param api
   */
  private enableSingleApi(request, api: Apis ): void {
    const sub = request.pipe(finalize(() => {
      this.sendEvtExecuteAllApis();
    }))
    .subscribe( (data: BooleanDto) => {
        if (data.value) {
          this.retryCheckApis(api);
        }
    }, (ex) => {
        const error = ex && ex.error ?  ex.error.message : '';
        this.setStatusApis(ApisStatus.Failed, false, api, error);
        this.openWindowUrl(api);
    });
    super.addSubscriptions(sub);
  }

  /**
   * Check single Api
   * @param request
   * @param api
   * @param retry
   */
  private checkSingleApi(request, api: Apis, retry?: boolean ): Promise<ApisStatus> {
    return new Promise((resolve, reject) => {
        const sub = request.pipe(finalize(() => {
          this.sendEvtExecuteAllApis();
        }))
        .subscribe( (data: StringDto) => {
            if (data.value) {
              const status = data.value as ApisStatus;
              if (!retry) {
                this.setStatusApis(status, false, api); }
              resolve(status);
            } else {
              this.setStatusApis(ApisStatus.Failed, false, api);
            }
        }, () => {
          this.setStatusApis(ApisStatus.Failed, false, api);
          resolve(null);
        });
        super.addSubscriptions(sub);
    });
  }

  private async retryCheckApis(api: Apis, bulk?: boolean ): Promise<void> {
    let status = null;
    let count = 0;
    let continueRetry = true;
    while (continueRetry ) {
      count ++;
      const $promise = this.getPromiseRetry(bulk, api);
      $promise.then((value: ApisStatus) => {
        if (value === ApisStatus.Success) { status = value; }
      });
      await $promise;
      continueRetry = !status && count < countCheked;
      if (!continueRetry) {
        this.setStatusApis(status, false, api);
      }
      if ( this.hasDelay) {
        await this.delayInterval();
      }
    }
    if (!status) {
      this.setStatusApis(ApisStatus.Failed, false, api, this.apiNotLoadText );
      this.openWindowUrl(api);
    }
    this.sendEvtExecuteAllApis();
  }

  /**
   * Bulk enable
   */
  private actionsJoinApisEnable(requestList: Array<any>, api: Apis) : void {
    const joinReqSub = forkJoin(requestList).subscribe((dataArray: Array<BooleanDto>) => {
      if (dataArray.length === requestList.length) {
          this.retryCheckApis(api, true);
      }
    }, (ex) => {
      const e = ex.error && ex.error.message ? ex.error.message : '';
      this.setStatusApis(ApisStatus.Failed, false, api, e);
      this.openWindowUrl(api);

      this.sendEvtExecuteAllApis();
    });
    super.addSubscriptions(joinReqSub);
  }
  /**
   * Bulk check
   * @param requestList
   * @param api
   */
  private actionsJoinApisCheked(requestList: Array<any>, api: Apis, retry?: boolean) : Promise<ApisStatus> {
    return new Promise((resolve) => {
      const joinReqSub = forkJoin(requestList).subscribe((dataArray: Array<StringDto>) => {
        if (dataArray.length === requestList.length) {
          const enabled = !dataArray.find(x => x.value === ApisStatus.Failed);
          const status = enabled ? ApisStatus.Success : ApisStatus.Failed;
          if (!retry) {
            this.setStatusApis(status, false, api);
            this.sendEvtExecuteAllApis();
          }
          resolve(status);
        }
      }, (ex) => {
        this.setStatusApis(ApisStatus.Failed, false, api);
        this.sendEvtExecuteAllApis();
        resolve(null);
      });
      super.addSubscriptions(joinReqSub);
    });
  }

  private getRequestListBulk(isCheked: boolean, api: Apis): Array<any> {
    const requestList = [];
    const apisGroup = this.getApisGroupFromPrincipal(api);
    _.forEach(apisGroup, ( ap : Apis) => {
      const req = !isCheked ? this.apisCheckManagerService.enableApi(this.deploymentId, ApisServices[ap], this.skeepFailure )
                   : this.apisCheckManagerService.isEnabledApi(this.deploymentId, ApisServices[ap], this.skeepFailure );
      requestList.push(req);
    });
    return requestList;
  }

  private getPromiseRetry(bulk: boolean, api: Apis): any {
    let req = null;
    if (bulk) {
        req =  this.getRequestListBulk(true, api);
        return this.actionsJoinApisCheked(req, api, true);
    } else {
      req = this.apisCheckManagerService.isEnabledApi(this.deploymentId, ApisServices[api], this.skeepFailure );
      return this.checkSingleApi(req, api, true);
    }
  }

  /**
   * emit : null [not finish check]
   *        true [success check]
   *        false [failed check]
   */
  private sendEvtExecuteAllApis(): void {
    const emit = this.getStatusAllApis();
    if (emit !== null) {
      this.checkAllApisEvt.emit(emit);
    }
    this.ref.detectChanges();
  }

  private delayInterval(): Promise<void> {
    return new Promise((resolve) => {
      timer(timeInterval).subscribe(async () => { resolve(); });
    });
  }

  private getStatusAllApis(): boolean {
    let error = false;
    let loading = false;
    _.forEach(this.apis, (api) => {
       if ( this.apisConfig[api]['loading'] ) {
          loading = true;
          return;
       } else {
          if ( !this.apisConfig[api]['status'] ) {
               error = true; }}
    });
    if (!loading) {
        return error === false;
    }
    return null;
 }

  private getTitleApi(api: Apis): string {
    const transl = 'apis_check_manager.' + api;
    const title = this.translate.instant(transl );
    return title;
  }
  /**
   * Get list apis to check from principal Api
   * @param principal
   */
  private getApisGroupFromPrincipal(principal: Apis): Array<Apis> {
    let result = [];
    if (this.apiGroup instanceof Array) {
      const apiGroup = this.apiGroup.find(x => x.principal === principal);
      if ( apiGroup) { result = apiGroup.group; }
    }
    return result;
  }

  private getApiPrincipalFromSecondary(secondary: Apis): Apis {
    let principal = null;
    _.forEach(this.apiGroup, (g: ApisGroup) => {
       const group =  g.group.find( x => x === secondary );
       if (group) { principal = g.principal;  return; }
     });
    return principal;
  }

  private setErrorApisGroup(apiPrincipal: Apis, apiSec: Apis, error: string, enableManually?: boolean): void {
    if ( this.apisConfig[apiPrincipal] && error) {
      this.apisConfig[apiPrincipal]['error'] = error;
      this.apisConfig[apiPrincipal]['urlError'] =  apisUrl[apiSec] + this.projectId;
      this.apisConfig[apiPrincipal]['enableManually'] = enableManually;
      this.checkAllApisEvt.emit(false);
    }
  }

   /**
   * Call to new tab to open url defined for api
   * @param api
   */
  private openWindowUrl(api: Apis): void {
      this.apisConfig[api].openWindowUrl = false;
      timer(timeUp).subscribe( () => {
        this.apisConfig[api].openWindowUrl = true;
      });
  }

  private subscribeLaunchDeployment(): void {
    const subError = this.provisionCustomService.errorLaunchDeploymentObject.subscribe((errors: ServerError) => {
        this.showRequestError(errors);
    });
    super.addSubscriptions(subError);
  }

  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
    this.apiNotLoadText = this.translate.instant('apis_check_manager.API_NOT_LOAD');
  }
}
