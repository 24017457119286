export class TrustedDomainFqdn {
    public trustedDomainId: number | null;
    public trustType: string;
    public trustedDomain: string;
    constructor(data ? : any) {
        this.trustedDomainId = data && data.trustedDomainId ? data.trustedDomainId: null;
        this.trustedDomain = data && data.trustedDomain ? data.trustedDomain : '';
        this.trustType = data && data.trustType ? data.trustType : '';
    }
}
