import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'underscore';

import { MachineType } from 'src/app/models';
import { machineTypeExcludeNames } from '../../const';

const custom = 'custom';
const gb = 1024;
@Pipe({
  name: 'groupMachineType'
})
export class GroupMachineTypePipe implements PipeTransform {

  public transform(machinetypes: MachineType[], ...args: any[]): any {
    let result = [];
    let names = [];
    const allTypes = args && args.length && args[0].allTypes ? true : false;
    const indexedByName = [];
    const machineTypes = _.sortBy(machinetypes, 'name');
    machineTypes.map((machineType) => {
        const isAllowed = !machineTypeExcludeNames.some(name => machineType.name === name);
        if (allTypes || isAllowed) {
            const nameArray = machineType.name.split('-');
            if (nameArray.length > 1) {
              machineType.family = nameArray[0].charAt(0).toUpperCase() + nameArray[0].substring(1) + ' Class';
              const ramValue = Number(Number(machineType.ram) / gb).toFixed(1);
              machineType.displayName = machineType.name.includes(custom) ? machineType.name :
              `${machineType.name}  (${machineType.cpu}  vCPU, ${ramValue} GB )`;
            } else if (nameArray[0] === custom) {
              machineType.family = 'Custom Class';
              machineType.displayName = custom;
            }
            nameArray.pop();
            const name = nameArray.join('-');
            names.push(name);
            if (indexedByName[name] && indexedByName[name] instanceof Array) {
              indexedByName[name].push(machineType);
            } else {
              indexedByName[name] = [machineType];
            }
        }
      });
    names = _.uniq(names);
    names.map((name) => {
        indexedByName[name] = _.sortBy(indexedByName[name], 'cpu');
        result.push(indexedByName[name]);
      });
    result = _.flatten(result);
    const newResult = [];
    result.map((machineType) => {
        if (!machineType.name.includes(custom)) {
          newResult.push(machineType);
        } else {
          newResult.unshift(machineType);
        }
      });
    return _.sortBy(newResult, 'family');
  }

}
