import {Injectable} from '@angular/core';
import {NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {Subscription, timer} from 'rxjs';

import { locale as english } from '../../../app/shared/user-inactivity/i18n/user-inactivity.en';
import { locale as spanish } from '../../../app/shared/user-inactivity/i18n/user-inactivity.es';
import {WarningModalComponent} from '../../shared/warning-modal/warning-modal.component';
import {ModalService} from '../shared/services/modal.service';
import {AuthenticationService} from './authentication.service';

const milliseconds = 1000; // 1 sec in milisec
const ten = 10;
const oneSecond = 1000;

const timeMaxInactivity = 3000; // sec
const timeMinInactivity = 600; // sec
const optionsModal = { size: 'md', backdrop: 'static', keyboard: false, centered: true };

@Injectable({ providedIn: 'root' })

export class IdleTimeoutService {
  public timeInactivity: number;
  public timerSubscription: Subscription;
  public timerGlobalSubscription: Subscription;
  public modalRef: NgbModalRef;

  constructor(private modalService: ModalService, private authenticationService: AuthenticationService,
              private translate: TranslateService) {
  }

  public resetTimeExpiredToken(): void {
    this.timeInactivity = 0;
  }

  public connectionStart(): void {
    this.startSessionTimer(false);
    const t = timer(milliseconds * ten, milliseconds * ten);
    this.timerGlobalSubscription = t.subscribe(() => {
      this.timeInactivity += ten;
    });
  }

  public startSessionTimer(reset: boolean): void {
    if (this.timerSubscription && reset) {
      this.timerSubscription.unsubscribe();
    }
    const t = timer(timeMaxInactivity * milliseconds);
    this.timerSubscription = t.subscribe(() => {
      if ( this.timeInactivity > timeMinInactivity) {
        this.inactiveUserHandler();
      } else {
        this.authenticationService.renovateToken();
        this.startSessionTimer(true);
      }
    });
  }

  private inactiveUserHandler(): void {
    this.setTranslations();
    this.modalRef = this.modalService.open(WarningModalComponent, optionsModal as NgbModalOptions);
    this.modalRef.componentInstance.bodyMessage = this.translate.instant('user_inactivity.INACTIVE_SESSION');
    this.modalRef.componentInstance.isInfo = true;
    this.modalRef.componentInstance.hasCancelCallback = true;
    this.modalRef.componentInstance.actionButtons = [
      this.translate.instant('user_inactivity.STAY_CONNECTED'),
      this.translate.instant('user_inactivity.CONNECTING')
    ];

    this.modalRef.componentInstance.onActionConfirmation.subscribe((action: string) => {
      if (action === 'cancel') {
        this.authenticationService.logout();
      } else {
        this.authenticationService.renovateToken();
        this.resetTimeExpiredToken();
        timer(oneSecond).subscribe(() => {
          this.modalRef.componentInstance.canCloseModal$.next(true);
        });
      }
    });
  }
  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
  }
}







