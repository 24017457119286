export const locale = {
  lang: 'en',
  'sidebar' : {
    'DASHBOARD': `Dashboard`,
    'CLOUD_DESKTOPS': `Cloud Desktops`,
    'CLOUD_MANAGER': `Cloud Manager`,
    'CATALOG': `Catalog`,
    'TASKS': `Tasks`,
    'INSIGHTS': `Insights`,
    'USERS': `Users`,
    'VM_INSTANCES': `VM Instances`,
    'CLOUD_APPS': 'Applications',
    'CLOUD_GROUPS': 'Security Groups',
    'COLLECTION_POOLS': 'Collection Pools',
    'UPTIMES': 'Server Uptime',
    'SNAPSHOTS': 'Snapshots',
    'VPNS': 'VPNs',
    'IMAGES': 'Images',
    'FOLDERS': 'Folders/Shares',
    'WORKSTATIONS': 'Workstations',
    'PRINTERS': 'Printers',
    'NODES': 'Network Nodes',
    'SITES': 'Sites',
    'SERVERS': 'Servers',
    'FILES': 'Files shares',
    'SETTINGS': 'Settings',
    'GENERAL': 'General',
    'REGIONS': 'Regions',
    'ACTIVE_DIRECTORY': 'Active Directory',
    'DEPLOYMENT_SOLE_TENANT': 'Sole-tenant Nodes',
    'FILE_SHARES': 'File Shares',
    'ALL': `All deployments`,
    'PATCH_PROFILE': 'OS Patching',
    'AUTO': 'Active Directory Auto-Import'
  }
};
