import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormButtonV2ActionServices} from './form-button-v2-action.services';

@Component({
  selector: 'app-form-buttons-v2',
  templateUrl: './form-buttons-v2.component.html',
  styleUrls: ['./form-buttons-v2.component.scss']
})
export class FormButtonsV2Component implements OnInit  {
  @Input()
  public isLoading: boolean;
  @Input()
  public failedLoad: boolean;
  @Input()
  public actionPressed: boolean;
  @Input()
  public isInvalid: boolean;
  @Input()
  public actionsButtons: string[];
  @Input()
  public mode: string;
  @Input()
  public hideCancelBtn: boolean;

  @Output()
  public actionConfirmation = new EventEmitter<boolean>();

  constructor(private formButtonV2ActionService: FormButtonV2ActionServices) {
  }

  public ngOnInit(): void {
    this.formButtonV2ActionService.actionPressed.subscribe((actionPressed: boolean) => {
      this.actionPressed = actionPressed;
    });
  }

  public onActionConfirmation($event): void {
    if ($event === false) {
      this.actionPressed = false;
      this.actionConfirmation.emit(false);
    } else {
        this.actionPressed = true;
        this.actionConfirmation.emit(true);
    }
  }
}
