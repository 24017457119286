export class Settings {
    public companyId: number;
    public deploymentId : number;
    public suffix : string;
    public certificate: string;
    public password : string;
    public certificateKey: string;
    public status : string;
    public schedule : string;
    public certificatePath : string;
    public settings: any;
    constructor(data?: any) {
     this.companyId =  data && data.companyId ? data.companyId : 0;
     this.deploymentId =  data &&  data.deploymentId ? data.deploymentId : 0;
     this.suffix =  data &&  data.name ? data.name : '';
     this.certificate =  data &&  data.certificate ? data.certificate : '';    
     this.password =  data &&  data.password ? data.password : '';
     this.certificateKey =  data &&  data.certificateKey ? data.certificateKey : '';
     this.status =  data &&  data.status ? data.status : '';
     this.schedule =  data &&  data.schedule ? data.schedule : '';
     this.certificatePath =  data &&  data.certificatePath ? data.certificatePath : '';
     this.settings =  data &&  data.settings ? data.settings : {};
     }
 }
