export class AccordionElement {
    public isCollapsed : boolean;
    public properties: Array<object>;
    public title: string;
    public subTitle: string;
    public isNotCollapsible: boolean;

    constructor(data?: any) {
        this.isCollapsed = data && data.isCollapsed;
        this.properties = data && data.properties ? data.properties : [];
        this.title = data && data.title ? data.title : '';
        this.subTitle = data && data.subTitle ? data.subTitle : '';
        this.isNotCollapsible = data && data.isNotCollapsible;
    }

}