import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { environment } from './../../../environments/environment';

import { AgmCoreModule } from '@agm/core';

import { MapgoogleComponent } from './map-google/map-google.component';

@NgModule({
  imports: [CommonModule, AgmCoreModule.forRoot({ apiKey: environment.mapKey })],
  declarations: [MapgoogleComponent],
  exports: [MapgoogleComponent]
})
export class MapsModule {}
