import { AfterViewInit, Component, EventEmitter, Input, OnChanges,
   OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import * as _ from 'underscore';

import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { BreakpointsObserverService } from 'src/app/core/shared/services/breakpoints-observer.service';
import { Datatable, Deployment } from 'src/app/models';
import { AutoUnsubscribeComponent } from 'src/app/shared/auto-unsubscribe/auto-unsubscribe.component';
import { ModalActionsEnum, ProcessStatusEnum } from '../enum';
import { ListDtoProcess, ProcessActivity } from '../model';
import { DataProgress } from '../model/data-progress';
import { ProcessViewerDetailsComponent } from '../process-viewer-details/process-viewer-details.component';
import { ProcessViewerService } from '../process-viewer.service';

import { locale as english } from '../i18n/process-viewer.en';
import { locale as spanish } from '../i18n/process-viewer.es';

const currentPageLimit = 5;
const currentPage = 1;
const optionsModal = { windowClass: 'proccess-params-modal',
                       backdrop: 'static', keyboard: false, centered: true };
@Component({
  selector: 'app-process-viewer-list',
  templateUrl: './process-viewer-list.component.html',
  styleUrls: ['./process-viewer-list.component.scss']
})
export class ProcessViewerListComponent extends AutoUnsubscribeComponent implements OnInit, AfterViewInit, OnChanges  {

  @Input()
  public parentProcess: string;
  @Input()
  public deploymentId: number;
  @Input()
  public deep: number;
  @Input()
  public renderModal: boolean;
  @Input()
  public isRefresh: boolean;
  @Input()
  public tz: string;

  @Output()
  public selectOption = new EventEmitter<ModalActionsEnum>();
  @Output()
  public dataProgress = new EventEmitter<DataProgress>();
  @Output()
  public haveChildren = new EventEmitter<boolean>();
  @Output()
  public processCount = new EventEmitter<{listItemsCount: number, totalCount: number}>();

  public deploymentSelected: Deployment;
  public disablePopover: boolean;
  public loadingIndicator: boolean;
  public columns: TableColumn[];
  public sorts: Array<{prop: string, dir: string}>;
  public messages: object;
  public gridModel: Datatable<ProcessActivity> = new Datatable<ProcessActivity>();
  public showList: boolean;
  public securityControlSpecs = {'createRoute': { resource: 'patchprofile', action: 'create', disable: false } };
  public sectionTitle: string;
  public osPatchingText: string;
  public currentPageLimit: number;
  public statusError: ProcessStatusEnum;
  public statusInProgress: ProcessStatusEnum;
  public statusCompleted : ProcessStatusEnum;

  private nameTemplate: TemplateRef<any>;
  @ViewChild('nameTemplate', { static: false }) set statusTemplateContent(content: TemplateRef<any>) {
    if (content && !this.nameTemplate) {
      this.nameTemplate = content;
      this.detectScreenSize();
    }
}

  constructor(private translate: TranslateService, private processViewerService: ProcessViewerService,
              private breakpointsObserverService: BreakpointsObserverService,  private modalService: NgbModal) {
            super();
            this.setTranslations();
  }

  public ngOnInit() {
    this.currentPageLimit = currentPageLimit;
    this.disablePopover = true;
    this.setTranslations();
    this.loadingIndicator = true;
    this.deep--;
    this.statusError = ProcessStatusEnum.Error;
    this.statusInProgress = ProcessStatusEnum.InProgress;
    this.statusCompleted = ProcessStatusEnum.Completed;

    this.sorts = [{ prop: 'name', dir: 'asc' }, { prop: 'description', dir: 'desc' }, { prop: 'patchFilterTypeName', dir: 'desc' },
                  { prop: 'scheduleDate', dir: 'desc' }];
    this.messages = { 'emptyMessage': this.translate.instant('process_viewer.NO_DATA'),
                      'totalMessage': this.translate.instant('process_viewer.TOTAL'),
                      'selectedMessage': this.translate.instant('process_viewer.SELECTED'),
                      'showingMessage': this.translate.instant('process_viewer.SHOWING'),
                      'refresh': this.translate.instant('process_viewer.REFRESH')
                     };
    this.detectScreenSize();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.isRefresh && changes.isRefresh.currentValue) {
      this.setPage({});
    }
  }

  public ngAfterViewInit(): void {
    this.setTranslations();
  }

  private initColumns(): void {
   this.setTranslations();
   this.columns = [
      { prop: 'name', name: this.translate.instant('process_viewer.TASK').toUpperCase(),
        minWidth: this.renderModal ? 280 : 320,  cellTemplate: this.nameTemplate},
      { prop: 'target', name: this.translate.instant('process_viewer.TARGET').toUpperCase(),
        minWidth: this.renderModal ? 150 : 220},
      { prop: 'startAt', name: this.translate.instant('process_viewer.START_TIME').toUpperCase(),
        minWidth:  this.renderModal ? 200 : 300},
      { prop: 'initProcess', name: this.translate.instant('process_viewer.INITIATED_BY').toUpperCase(),
        minWidth: this.renderModal ? 150 : 250},
      ];
  }

  public setPage($event): void {
    if (this.deploymentId) {
      this.loadingIndicator = true;
      this.processCount.emit({listItemsCount: currentPage, totalCount: currentPage});
      const search = {
        pageSize: this.gridModel && this.gridModel.PageSize || currentPageLimit,
        pageIndex: this.gridModel && this.gridModel.CurrentPageNumber + 1 || 1,
      };
      this.processViewerService.listSubProcessActivity(this.deploymentId, this.parentProcess, search);
      this.processViewerService.listProcessActivity(this.deploymentId, search);

      const req = this.parentProcess ? this.processViewerService.listSubProcessActivity(this.deploymentId, this.parentProcess, search) :
                                      this.processViewerService.listProcessActivity(this.deploymentId, search );

      req.subscribe((data: ListDtoProcess) => {
        if ( this.parentProcess === null) {
            this.dataProgress.emit(new DataProgress({inProgress : data.inProgress, error: data.error}));
        }
        this.haveChildren.emit(data.list.length > 0);
        _.map(data.list, (row: ProcessActivity) => {
            if ( this.tz) {
                row.startAt =  moment(row.startAt).tz(this.tz).format('MMMM Do YYYY, h:mm:ss a');
            }
        });
        const count = data.inProgress + data.error;
        this.processCount.emit({listItemsCount: data.list.length, totalCount: count});
        $event.PageSize =  $event && $event.PageSize || this.gridModel && this.gridModel.PageSize;
        $event.CurrentPageNumber = $event && $event.CurrentPageNumber || this.gridModel.CurrentPageNumber;
        const total = Number(count / $event.PageSize);
        this.gridModel = { PageSize: $event.PageSize, TotalElements: count, TotalPages: Number(total.toFixed(0)) + 1,
                           CurrentPageNumber: $event.CurrentPageNumber, SortBy: this.gridModel.SortBy,
                           SortDir: this.gridModel.SortDir, Data: data.list };
      },
       () => {
        this.haveChildren.emit(false);
        this.loadingIndicator = false;
        this.showList = true;
      }, () => {
        this.loadingIndicator = false;
        this.showList = true;
      });
    }
  }

  public resetGridModel(): void {
    if (this.gridModel) {
      this.gridModel.CurrentPageNumber =  currentPage - 1;
      this.gridModel.PageSize = currentPageLimit;
    }
  }

  public getStatusTranslate(status: string): string {
    this.setTranslations();
    let title = '';
    switch (status) {
      case ProcessStatusEnum.Completed:
        title = this.translate.instant('process_viewer.COMPLETED');
        break;
      case ProcessStatusEnum.InProgress:
          title = this.translate.instant('process_viewer.PROGRESS');
          break;
      case ProcessStatusEnum.Error:
          title = this.translate.instant('process_viewer.ERROR');
          break;
    }
    return title;
 }

  public showDetailsModal(row: ProcessActivity, retry? : boolean ): void {
    if ( this.deep > 0) {
          if ( this.renderModal ) { this.selectOption.emit(ModalActionsEnum.CancelPrevious); }
          const modal = this.modalService.open(ProcessViewerDetailsComponent, optionsModal as NgbModalOptions);
          modal.componentInstance.deep = this.deep;
          modal.componentInstance.deploymentId = this.deploymentId;
          modal.componentInstance.proccessId = row.processId;
          modal.componentInstance.tz = this.tz;
          if (retry) {
              this.setPage({});
          }
          modal.componentInstance.optionSelected.subscribe((option: ModalActionsEnum) => {
           if (option ) {
               let parentProcessVisible  = '';
               if (  modal.componentInstance && option === ModalActionsEnum.Cancel || option === ModalActionsEnum.Retry  ) {
                parentProcessVisible =  modal.componentInstance.details  ? modal.componentInstance.details.parentProcessVisible : '';
              }
               modal.close();
               if (parentProcessVisible) {
                  this.deep ++;
                  this.showDetailsModal( new ProcessActivity({processId: parentProcessVisible}), option === ModalActionsEnum.Retry );
              } else {
                if ( option === ModalActionsEnum.Retry) {
                   this.setPage({});
                }
              }
            }
        });
    }
 }
  private detectScreenSize(): void {
    const mediaBreakpointSubs = this.breakpointsObserverService.mediaBreakpoint$.subscribe((value) => {
      this.setTranslations();
      switch ( value) {
        case 'xs':
          this.columns = [
            { prop: 'name', name: this.translate.instant('process_viewer.TASK').toUpperCase(),
              minWidth: 320, cellTemplate: this.nameTemplate }];
          break;
          case 'sm':
              this.columns = [
                { prop: 'name', name: this.translate.instant('process_viewer.TASK').toUpperCase(),
                  minWidth: 250, cellTemplate: this.nameTemplate
                },
                { prop: 'target', name: this.translate.instant('process_viewer.TARGET').toUpperCase(),
                  minWidth:  150},
                ];
              break;
          case 'md':
              this.columns = [
                { prop: 'name', name: this.translate.instant('process_viewer.TASK').toUpperCase(),
                  minWidth: 250,  cellTemplate: this.nameTemplate},
                { prop: 'target', name: this.translate.instant('process_viewer.TARGET').toUpperCase(),
                  minWidth:  150},
                { prop: 'startAt', name: this.translate.instant('process_viewer.START_TIME').toUpperCase(),
                  minWidth: 200},
                ];
              break;
            case 'lg':
                this.columns = [
                  { prop: 'name', name: this.translate.instant('process_viewer.TASK').toUpperCase(),
                    minWidth: 280,  cellTemplate: this.nameTemplate},
                  { prop: 'target', name: this.translate.instant('process_viewer.TARGET').toUpperCase(),
                    minWidth:  150},
                  { prop: 'startAt', name: this.translate.instant('process_viewer.START_TIME').toUpperCase(),
                    minWidth: this.renderModal ? 200 : 250},
                  { prop: 'initProcess', name: this.translate.instant('process_viewer.INITIATED_BY').toUpperCase(),
                    minWidth: this.renderModal ? 150 : 180},
                  ];
                break;
          default:
              this.initColumns();

        }
    });
    super.addSubscriptions(mediaBreakpointSubs);
  }

  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
  }
}
