export const locale = {
    lang: 'en',
    'complete_admin_invite' : {
        TITLE: 'Complete your profile',
        FIRST_NAME: 'First name',
        LAST_NAME: 'Last name',
        USERNAME: 'Itopia Username',
        EMAIL: 'Email',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm password',
        COMPLETE: 'Complete',
        GOOGLE: 'Sign in with Google',
        MICROSOFT: 'Sign in with Microsoft',
        COMPLETING: 'Completing...',
        ERROR_REQUIRED: 'This field is required',
        ERROR_ONLY_NUMBER: 'You must enter just numbers',
        ERROR_PATTERN: 'The value in this field is invalid',
        ERROR_MAX_LENGTH: 'Maximum length {{val}}',
        ERROR_NAME_PATTERN: 'You must enter just letters, numbers or characters -\'_',
        PASSWORD_REQUIREMENTS: 'Password complexity requirements',
        ERROR_PASSWORD_MUST_MATCH: 'Passwords don\'t match',
        CONNECTION_ERROR: 'Connection error',
        ARGUMENT_ERROR: 'The email address must be a valid string',
        INVALID_EMAIL_ERROR: 'The email address is badly formatted',
        EXPIRED_ERROR: 'The password reset code has expired',
        RESET_CODE_ERROR: 'The password reset code is invalid',
        USER_DISABLED_ERROR: 'The user corresponding to the given password reset code has been disabled',
        USER_NOT_FOUND_ERROR: 'There is no user in CAS corresponding to this email address',
        WEAK_PASSWORD_ERROR: 'The new password is not strong enough',
        UNEXPECTED_ERROR: 'Unexpected error',
        CLOSED_BY_USER_ERROR: 'Action canceled by user',
        UNAUTHORIZED_DOMAIN_ERROR: 'Unauthorized domain',
        ACCOUNT_EXISTS_ERROR: 'This account already exists',
        USER_PASS_AUTH_TYPE: 'Sign in with password',
        SSO_AUTH_TYPE: 'Use single sign-on (SSO)',
        SSO_AUTH_TYPE_INFO: 'Your SSO account must match your itopia username',
        ERROR_EMAIL: 'This field should be an e-mail address in the format "user@example.com"',
        EMAIL_EXISTS_ERROR: 'Email already exists'
    }
};
