export enum NotificationResources {
  users = 'USER',
  collection = 'COLLECTION',
  password = 'PASSWORD',
  password_link = 'PASSWORD_LINK',
  file = 'FILE',
  ad = 'AD',
  snapshotPlan = 'SNAPSHOT_PLAN',
  snapshot = 'SNAPSHOT',
  image = 'IMAGE',
  uptime = 'UPTIME',
  vpn = 'VPN',
  folder = 'FOLDER',
  folderPermission = 'FOLDER_PERMISSION',
  mappedDrive = 'MAPPED_DRIVE',
  shared = 'SHARED',
  securityGroups = 'SECURITYGROUPS',
  admin = 'ADMIN',
  license = 'LICENSE',
  appSetting = 'APPSETTING',
  app = 'APP',
  roles = 'ROLE',
  infrastructure = 'INFRAESTRUCTURE',
  vminstance = 'INSTANCE',
  ospatch = 'OSPATCH',
  region = 'REGION'
}
