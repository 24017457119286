import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'underscore';

export const subNetAllV2Validator = function (control: AbstractControl): ValidationErrors | null {
const value: string = control.value || '';
if (!value) {
    return null;
  }
const reg = new RegExp(/^(?:1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])\.(?:1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])\.(?:1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])\.(?:1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])\/(([1-9]|[12][0-9]|3[012]))$/);
if (reg.test(value) === false) {
    return {
      pattern: 'Invalid format'
    };
}
};
