import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { AutoUnsubscribeModule } from './../../shared/auto-unsubscribe/auto-unsubscribe.module';
import { WarningModalCancelSubscriptionComponent } from './warning-modal-cancel-subscription.component';

@NgModule({
  imports: [CommonModule, NgbModule, TranslateModule, AutoUnsubscribeModule],
  declarations: [WarningModalCancelSubscriptionComponent],
  exports: [WarningModalCancelSubscriptionComponent]
})
export class WarningModalCancelSubscriptionModule { }
