import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {MatDividerModule, MatIconModule} from '@angular/material';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {FlexModule} from '@angular/flex-layout';

import { MultiselectComponent } from './multiselect.component';
@NgModule({
  imports: [CommonModule, TranslateModule, NgbModule, RouterModule, NgxDatatableModule, FormsModule, MatDividerModule, MatIconModule, FlexModule],
    declarations: [MultiselectComponent],
    exports: [MultiselectComponent],
})
export class MultiselectModule {
}
