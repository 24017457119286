import { FileBase } from '../cloud-desktops/fileBase';
import { BrandOptions } from './brand-options';

export class Branding {
    public organizationId: number;
    public options: BrandOptions;
    public logoFileStore: FileBase;
    public favIconFileStore: FileBase;

    constructor(data? : any) {
        this.organizationId = data && data.organizationId ? data.organizationId : 0;
        this.options = data && data.options ? new BrandOptions(data.options) : new BrandOptions();
        this.logoFileStore = data && data.logoFileStore ? data.logoFileStore : null;
        this.favIconFileStore = data && data.favIconFileStore ? data.favIconFileStore : null;
    }
}