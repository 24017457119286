import { ApplicationRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { auth } from 'firebase/app';

import { HttpStatusCodes, ValidationErrorsCodes } from 'src/app/models/http/index';
import { AuthenticationService } from '../core/security/authentication.service';
import { ModalService } from '../core/shared/services/modal.service';
import { WarningModalComponent } from '../shared/warning-modal/warning-modal.component';
import { AutoUnsubscribeComponent } from './../shared/auto-unsubscribe/auto-unsubscribe.component';

import { locale as english } from './i18n/login.en';
import { locale as spanish } from './i18n/login.es';
import { tickStep } from 'd3';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['login.component.scss']
})
export class LoginComponent extends AutoUnsubscribeComponent implements OnInit {
  public loginForm: FormGroup;
  public loading = false;
  public submitted = false;
  public submittedReset = false;
  public returnUrl: string;
  public error = '';
  public errorProviders = '';
  public isExternalLogin: boolean;
  public hide: boolean;
  public loginform = true;
  public recoverform = false;
  public resetForm: FormGroup;
  public resetError: any;
  public oneProviderModal: any;
  public promptPasswordModal: any;
  public validationType: any;
  public forgotPassword: Object;
  public currentFails: boolean;
  public translations: Object;

  constructor(private formBuilder: FormBuilder, private router: Router, private authenticationService: AuthenticationService,
              private modalService: ModalService, private translate: TranslateService, private notification: NotifierService,
              private appRef: ApplicationRef) {
    super();
    this.setTranslation();
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  public showRecoverForm() {
    this.loginform = !this.loginform;
    this.recoverform = !this.recoverform;
  }

  // tslint:disable-next-line:member-access
  ngOnInit() {
    setTimeout(() => {
      this.setTranslation();
    });
    this.loginForm = this.formBuilder.group({
      username: [''],
      password: [''],
    });
    this.validationType = {
      'username': [Validators.required, Validators.email],
      'password': [Validators.required]
    };
    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
    this.isExternalLogin = false;
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  // convenience getter for easy access to form fields
  get resetf() { return this.resetForm.controls; }

  public setValidations(input: string): any {
    // tslint:disable-next-line:forin
    for (const key in this.f) {
      if (key === input) {
        this.f[key].setValidators(this.validationType[key]);
        this.f[key].markAsTouched();
        this.f[key].updateValueAndValidity();
        break;
      } else if (!input) {
        this.f[key].setValidators(this.validationType[key]);
        this.f[key].markAsTouched();
        this.f[key].updateValueAndValidity();
      }
    }
  }

  public backToLogin(): void {
    this.submitted = false;
    this.currentFails = false;
    this.isExternalLogin = false;
    this.errorProviders = undefined;
    this.error = undefined;
    this.setTranslation();
    this.appRef.tick();
  }

  public onSubmit() {
    this.submitted = true;
    this.setTranslation();
    if (!this.isExternalLogin) {
      this.setValidations(undefined);
      if (this.loginForm.invalid) {
        this.submitted = false;
        this.setTranslation();
      }
    }
    // stop here if form is invalid
    if (this.loginForm.invalid || this.isExternalLogin) {
      return;
    }
    this.loading = true;
    this.signIn();
  }
  public signUp(): void {
    this.authenticationService.signUp(this.f.username.value, this.f.password.value);
  }

  public signIn(): void {
    this.errorProviders = '';
    this.error = '';
    this.authenticationService.signIn(this.f.username.value, this.f.password.value).then(res => {
      
      this.authenticationService.loginObservable(res);
      this.authenticationService.getCurrentUser.subscribe((obj: any) => {     
        if(obj){
          if(obj.current && !obj.error){
            this.authenticationService.setCurrentProvider('password');
            this.authenticationService.setCurrentPartnerId(obj.current.partnerId);
            // EarlyAccess, Presentations
            this.authenticationService.earlyAccessAndPresentations(obj.current);
          } else {
            const err = obj.error;
            if (err.error.code === ValidationErrorsCodes.ValidationError40136 || err.code === ValidationErrorsCodes.ValidationError40136) {
              this.errorProviders = err.error.message ? err.error.message : err.message;
              this.loading = false;
            } else {
              this.error = this.getFailMessage(err);
              this.loading = false;
              if (err.error.code === ValidationErrorsCodes.ValidationError40134) {
                this.currentFails = true;
                this.appRef.tick();
              }
            }
            this.resetLoginBtn();
          } 
        }      
      });
    }).catch(err => {
      this.error = this.getFailMessage(err);
      this.loading = false;
      this.resetLoginBtn();
    });
  }

  public signOut() {
    this.authenticationService.signOut();
  }
  public signinWithGoogle(): any {
    this.errorProviders = '';
    this.error = '';
    this.f.username.setErrors(null);
    this.f.password.setErrors(null);
    this.isExternalLogin = true;
    this.authenticationService.signinWithGoogle().subscribe((resp) => {
      if (resp && resp.code) {
        this.errorProviders = resp.message;
        this.loading = false;
      } else {
        this.authenticationService.loginObservable(resp);
        this.authenticationService.getCurrentUser.subscribe((obj: any) => {     
          if(obj){
            if(obj.current && !obj.error){
              this.authenticationService.setCurrentProvider('google.com');
              this.authenticationService.setCurrentPartnerId(obj.current.partnerId);
              // EarlyAccess, Presentations
              this.authenticationService.earlyAccessAndPresentations(obj.current);
            } else {
              const err = obj.error;
              this.errorProviders = err.error.message;
              this.loading = false;
              if (err.error.code === ValidationErrorsCodes.ValidationError40134) {
                this.currentFails = true;
                this.appRef.tick();
              }
            }
          }
        });
       
      }
    }, (error) => {
      this.errorProviders = error.message;
      this.loading = false;
    });
  }
  public signinWithMicrosoft(): any {
    this.errorProviders = '';
    this.error = '';
    this.f.username.setErrors(null);
    this.f.password.setErrors(null);
    this.isExternalLogin = true;
    this.authenticationService.signinWithMicrosoft().subscribe((resp) => {
      if (resp && resp.code === 'auth/account-exists-with-different-credential') {
        this.setUniqueProvider('microsoft', resp);
      } else if (resp && resp.code) {
        this.errorProviders = resp.message;
        this.loading = false;
      } else {
        this.authenticationService.loginObservable(resp);
        this.authenticationService.getCurrentUser.subscribe((obj: any) => {     
          if(obj){
            if(obj.current && !obj.error){
              this.authenticationService.setCurrentProvider('microsoft.com');
              this.authenticationService.setCurrentPartnerId(obj.current.partnerId);
              // EarlyAccess, Presentations
              this.authenticationService.earlyAccessAndPresentations(obj.current);
            } else {
              const err = obj.error;
              this.errorProviders = err.error.message;
              this.loading = false;
              if (err.error.code === ValidationErrorsCodes.ValidationError40134) {
                this.currentFails = true;
                this.appRef.tick();
              }
            }
          }
        });

      }
    }, (error) => {
      this.errorProviders = error.message;
      this.loading = false;
    });
  }
  private setUniqueProvider(provider: string, error): void {
    const providerLabel = provider[0].toUpperCase() + provider.substring(1, provider.length);
    const message = this.translate.instant('login.NOTIFICATION_TEXT') + ' ' + providerLabel
      + ' ' + this.translate.instant('login.NOTIFICATION_TEXT2');
    this.oneProviderModal = this.modalService.open(WarningModalComponent,
      { size: 'sm', backdrop: 'static', keyboard: false, centered: true });
    this.oneProviderModal.componentInstance.bodyMessage = this.translate.instant('login.NOTIFICATION_TITLE');
    this.oneProviderModal.componentInstance.bodyMessageText = message;
    this.oneProviderModal.componentInstance.isInfo = true;
    this.oneProviderModal.componentInstance.hasCancelCallback = true;
    this.oneProviderModal.componentInstance.actionButtons = [this.translate.instant('login.NOTIFICATION_SIGN') + ' ' + provider,
                                                             this.translate.instant('login.NOTIFICATION_SIGNING')];
    this.appRef.tick();
    this.oneProviderModal.componentInstance.onActionConfirmation.subscribe((action: string) => {
      if (action === 'cancel') {
        this.isExternalLogin = false;
        this.resetLoginBtn();
        this.oneProviderModal.componentInstance.canCloseModal$.next(true);
        this.appRef.tick();
      } else {
        this.oneProviderModal.componentInstance.canCloseModal$.next(true);
        this.authenticationService.fetchSignInMethodsForEmail(error.email).subscribe((methods) => {
          switch (methods[0]) {
            case 'password': this.promptPassword(error.email, error.credential);
                             break;
            default:
              const authProvider = new auth.OAuthProvider(methods[0]);
              this.authenticationService.oAuthProvider(authProvider).then((result) => {
                result.user.linkAndRetrieveDataWithCredential(error.credential).then((usercred) => {
                  this.sendProviderEvent(methods[0], error.credential.providerId, usercred);
                }, () => {
                  this.error = error.message;
                  this.loading = false;
                });
              }, () => {
                this.error = error.message;
                this.loading = false;
              });
              break;
          }
        }, () => {
          this.error = error.message;
          this.loading = false;
        });
      }
    });
  }
  private sendProviderEvent(oldProvider, newProvider, usercred): void {
    const event = {
      oldProvider: oldProvider,
      newProvider: newProvider
    };
    this.authenticationService.sendProviderEvent(event, usercred.user._lat).subscribe(null, null,
      () => {
        this.authenticationService.loginObservable(usercred);
        this.authenticationService.setCurrentProvider(newProvider);
      });
  }
  private promptPassword(email, credential): void {
    this.promptPasswordModal = this.modalService.open(WarningModalComponent,
      { size: 'sm', backdrop: 'static', keyboard: false, centered: true });
    this.promptPasswordModal.componentInstance.bodyMessage = this.translate.instant('login.PROMPT_PASS_TITLE') + ' ' + email;
    this.promptPasswordModal.componentInstance.bodyInput = 'password';
    this.promptPasswordModal.componentInstance.isInfo = true;
    this.promptPasswordModal.componentInstance.actionButtons = [this.translate.instant('login.PROMPT_PASS_SIGN_IN')];
    this.appRef.tick();
    this.promptPasswordModal.componentInstance.onActionConfirmation.subscribe((passwordInput) => {
      this.promptPasswordModal.componentInstance.canCloseModal$.next(true);
      this.authenticationService.signIn(email, passwordInput).then((user) => {
        this.authenticationService.linkWithCredential(credential).subscribe((usercred) => {
          this.sendProviderEvent('password', credential.providerId, usercred);
        }, (error) => {
          this.error = error.message;
          this.loading = false;
        });
      }, (error) => {
        this.error = error.message;
        this.loading = false;
      });
    });
  }
  public sendRecoveryLink(): any {
    this.submittedReset = true;
    // stop here if form is invalid
    if (this.resetForm.invalid || this.isExternalLogin) {
      return;
    }
    this.authenticationService.sendRecoverEmail(this.resetf.email.value).then((data) => {
      this.setTranslation();
      const message = this.translate.instant('login.RESET_SUCCESS');
      this.notification.notify('info', message);
      location.reload();
    },
      (error) => {
        this.resetError = this.getFailMessage(error);
        this.resetForm.setErrors({ 'email': this.resetError });
      });
  }
  private getFailMessage(error): string {
    switch (error.code) {
      case 'auth/network-request-failed':
        return this.translate.instant('login.CONNECTION_ERROR');
      case 'auth/argument-error':
        return this.translate.instant('login.ARGUMENT_ERROR');
      case 'auth/invalid-email':
        return this.translate.instant('login.INVALID_EMAIL_ERROR');
      case 'auth/expired-action-code':
        return this.translate.instant('login.EXPIRED_ERROR');
      case 'auth/invalid-action-code':
        return this.translate.instant('login.RESET_CODE_ERROR');
      case 'auth/user-disabled':
        return this.translate.instant('login.USER_DISABLED_ERROR');
      case 'auth/user-not-found':
      case 'auth/wrong-password':
        return this.translate.instant('login.INVALID_CREDENTIALS');
      case 'auth/weak-password':
        return this.translate.instant('login.WEAK_PASSWORD_ERROR');
      case 'auth/too-many-requests':
        return this.translate.instant('login.ACCOUNT_DISABLED_ERROR');
      default:
        return this.translate.instant('login.UNEXPECTED_ERROR');
    }
  }
  public setValidForm(): void {
    this.submittedReset = false;
    this.resetError = undefined;
    this.resetForm.setErrors(null);
  }

  private resetLoginBtn(): void {
    this.submitted = false;
    this.setTranslation();
  }

  private setTranslation(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
    this.translations = {
      loginBtn: !this.submitted ? this.translate.instant('login.LOGIN') : this.translate.instant('login.LOGININ'),
      errorRequired: this.translate.instant('login.REQUIRED'),
      errorEmail: this.translate.instant('login.EMAIL'),
      titleLogin: this.translate.instant('login.ITOPIA'),
      titleSignUp: this.translate.instant('login.SIGN_UP_ITOPIA'),
      titleSignInWith: this.translate.instant('login.SIGN_IN_WITH'),
      rememberLabel: this.translate.instant('login.REMEMBER'),
      signUpLabel: this.translate.instant('login.SIGN_UP'),
      dontLabel: this.translate.instant('login.DONT'),
      currentFailsMsg: this.translate.instant('login.CURRENT_FAILS'),
      signUpMsg: this.translate.instant('login.BACK_TO'),
      backToBtn: this.translate.instant('login.BACK_TO'),
      usernamePh: this.translate.instant('login.USERNAME_PH'),
      passwordPh: this.translate.instant('login.PASSWORD_PH'),
      forgotPassword: {
        'title': this.translate.instant('login.FORGOT'),
        'reset': this.translate.instant('login.RESET'),
        'sending': this.translate.instant('login.SENDING'),
        'cancel': this.translate.instant('login.CANCEL'),
        'recover': this.translate.instant('login.RECOVER_DATA')
      },
    };
  }
}
