export class Configuration {
  // data for all Tables //
  public columnDef: string;
  public minWidth: number;
  public sortable : boolean;
  public header: string;
  public cell: any;
  public filterable : boolean;
  public visible: boolean;
  public filterValue : string;
  public fieldName: string;
  public displayName: string;
  public template: string;
  public visibleOn: string[];

  public instancesTableConfigurationId: number;
  public default: boolean;
  public required: boolean;
  public checked: boolean;
  public tableName : string;
  public checkedOriginal: boolean;
  constructor(data?: any) {
    this.instancesTableConfigurationId =  data && data.instancesTableConfigurationId ? data.instancesTableConfigurationId  : 0;
    this.visible =  data && data.visible ? data.visible  : false;
    this.default =  data && data.default ? data.default  : false;
    this.required =  data && data.required ? data.required  : false;
    this.fieldName =  data && data.fieldName ? data.fieldName  : '';
    this.displayName =  data && data.displayName ? data.displayName  : '';
    this.tableName =  data && data.tableName ? data.tableName  : '';
    this.sortable =  data && data.sortable ? data.sortable  : false;
    this.filterable =  data && data.filterable ? data.filterable  : false;
    this.filterValue =  data && data.filterValue ? data.filterValue  : '';
    this.minWidth =  data && data.minWidth ? data.minWidth  : 110;
    this.columnDef = data && data.columnDef ? data.columnDef : this.fieldName;
    this.header = data && data.columnDef ? data.columnDef : this.displayName;
    this.template =  data && data.template ? data.template  : false;
  }
}
