import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, HostListener, Inject, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appCopyCell]'
})
export class CopyCellDirective implements OnInit {
  @Input()
  public value : string;

  @Input()
  public iconClass: string;

  public globalInstance : any;

  private copyElement : ElementRef;

  constructor(private el: ElementRef, private renderer: Renderer2, @Inject(DOCUMENT) private document,
              private changeDetectorRef: ChangeDetectorRef) { }
  public ngOnInit() {
    this.createCopy();
  }

  private createCopy() {
    const child = document.createElement('span');
    const icon = document.createElement('icon');
    const def = 'far fa-copy c-pointer d-none';
    icon.className = this.iconClass ? def + ' ' + this.iconClass : def;
    child.appendChild(icon);
    this.copyElement = new ElementRef(icon);
    this.renderer.setAttribute(child, 'id', 'copy' );
    this.renderer.setAttribute(child, 'title', 'Copy to clipboard' );
    this.renderer.appendChild(this.el.nativeElement, child);
    this.changeDetectorRef.detectChanges();
    this.bindClick();
  }
  @HostListener('mouseenter', ['$event'])
  public onHover() {
    this.renderer.removeClass(this.copyElement.nativeElement, 'd-none');
    this.renderer.setAttribute(this.copyElement.nativeElement, 'title', 'Copy to clipboard' );
   }
  @HostListener('mouseleave', ['$event'])
  public onHoverOutside() {
    this.renderer.addClass(this.copyElement.nativeElement, 'd-none');
  }
  private bindClick() {
    this.globalInstance = this.renderer.listen(this.copyElement.nativeElement, 'click', ($event) => {
      this.copyText($event);
    });
  }

  private copyText($event) {
    const listener = (e: ClipboardEvent) => {
      const clipboard = e.clipboardData || window['clipboardData'];
      clipboard.setData('text', this.value);
      e.preventDefault();
      e.stopPropagation();
    };
    this.renderer.setAttribute(this.copyElement.nativeElement, 'title', 'Copied' );
    this.document.addEventListener('copy', listener, false);
    this.document.execCommand('copy');
    this.document.removeEventListener('copy', listener, false);
    $event.preventDefault();
    $event.stopPropagation();
  }

}
