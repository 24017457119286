import createNumberMask from 'text-mask-addons/dist/createNumberMask';
export  class LicenseCodeMask {
    public defaultLicense = {
        mask: createNumberMask({
          prefix : '',
          suffix :'',
          includeThousandsSeparator : false,
          allowDecimal : false,
          requireDecimal : false,
          allowNegative : false,
          allowLeadingZeroes : false,
          integerLimit : 8
        }),
        regExp: /^[1-9]\d{6,7}$/,
        placeholder: '99999999',
        programId: 10
    };
    public packLicense = {
        mask: [/\w/, /\w/, /\w/, /\w/, /\w/, '-',
               /\w/, /\w/, /\w/, /\w/, /\w/, '-',
               /\w/, /\w/, /\w/, /\w/, /\w/, '-',
               /\w/, /\w/, /\w/, /\w/, /\w/, '-',
               /\w/, /\w/, /\w/, /\w/, /\w/],
        regExp: /^(\w{5}-){4}\w{5}$/,
        placeholder: '*****-*****-*****-*****-*****',
        programId: 1
    };
    public openLicense = {
        mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,
               /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/,
               /\d/, /\d/, /\d/, /\d/, '-',
               /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
        regExp: /^[1-9]\d{7}[a-zA-Z]{3}\d{4}-\d{8}$/,
        placeholder: '99999999AAA9999-99999999',
        programId: 2
    };
}
