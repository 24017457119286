import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';

import { Deployment } from 'src/app/models';
import { MenuEventsService } from '../../core/shared/services/menu-events.service';
import { AutoUnsubscribeComponent } from '../../shared/auto-unsubscribe/auto-unsubscribe.component';
import { DeploymentsService } from '../deployments.service';

import { locale as english } from '../i18n/deployments.en';
import { locale as spanish } from '../i18n/deployments.es';

const two = 2;
const ten = 10;
const notFound = 404;
const unauthorized = 401;

@Component({
  selector: 'app-deployments-menu',
  templateUrl: './deployments-menu.component.html',
  styleUrls: ['./deployments-menu.component.scss']
})
export class DeploymentsMenuComponent extends AutoUnsubscribeComponent implements OnInit {
  public deployments: any;
  @Input()
  public changeDeploymentName: boolean;
  public showDeploymentName: boolean;
  public deploymentsLabel: string;
  public provisionDeleted: boolean;

  constructor(private deploymentsMenuService: DeploymentsService, private menuEventsService: MenuEventsService,
              private translate: TranslateService, private router: Router ) {
    super();
    this.deployments = [];
    const getRecentDeploymentsSubscription = this.deploymentsMenuService.getRecentDeployments().subscribe((data) => {
      this.deploymentsMenuService.setDeploymentRecent(data.list);
      if (data.count === 0) {
        this.router.navigate(['/dashboard/nodeployment/']);
        return;
      }
      this.deployments = data.list;
      const mostRecentDeployment = this.deployments[0];
      const matchDeploymentUrlRegExps = [/(^\/deployment\/[0-9]+)/g, /(^\/dashboard\/[\w-]+\/[0-9]+)/g, /(^\/provision\/[0-9]+)/g];
      const matchDeploymentUrlResult = matchDeploymentUrlRegExps.find(regExp => this.router.url.match(regExp));
      if (matchDeploymentUrlResult) {
        const deploymentId = this.router.url.match(matchDeploymentUrlResult)[0].split('/').pop();
        const findRecentDeployment = _.find(this.deployments, (deployment: Deployment) =>
          deployment.companyId === parseInt(deploymentId, ten)
        );
        if (findRecentDeployment) {
          this.deploymentsMenuService.setDeploymentSelected(findRecentDeployment);
        } else {
          const getDeploymentSubs = this.deploymentsMenuService.getDeployment(Number(deploymentId))
            .subscribe(deployment => {
              this.deploymentsMenuService.setDeploymentSelected(deployment);
            }, error => {
              if ([notFound, unauthorized].includes(error.status)) {
                // TODO redirect not found page, temp solution: redirect most recent dashboard
                this.deploymentsMenuService.setDeploymentSelected(mostRecentDeployment);
              }
            });
          super.addSubscriptions(getDeploymentSubs);
        }
      } else {
        this.deploymentsMenuService.setDeploymentSelected(mostRecentDeployment);
      }

      const getMiniSideBarSubs = this.menuEventsService.getMiniSideBar().subscribe((isMini) => {
        if (this.deployments && this.deployments.length > 0) {
          this.showDeploymentName = isMini ? false : true;
        }
      });
      super.addSubscriptions(getMiniSideBarSubs);
    });
    super.addSubscriptions(getRecentDeploymentsSubscription);
    this.setTranslation();
  }

  public ngOnInit() {
    this.setProvisionDeleted();
    const deploymentSelectedSubscription = this.deploymentsMenuService.deploymentSelectedSubject.subscribe((data) => {
      if (data) {
        const index = data.name.lastIndexOf('i>');
        const indexOriginal =  data.originalName ? data.originalName.lastIndexOf('i>') : -1;
        if (index !== -1 ||  indexOriginal !== -1) {
          data.name = data.name.substr(index + 1, data.name.length);
          const indexEnd = data.name.indexOf('<');
          data.name = data.name.substring(1, indexEnd);
        }
        data.originalName = data.originalName || data.name;
        const notSelected = this.deployments;
        const indexDeploy = notSelected.findIndex(deployment => {
          return deployment.companyId === data.companyId;
        });
        if (notSelected.length > 0) {
          switch (indexDeploy) {
            case -1:
              if (this.deployments.length > two) {
                this.deployments.splice(this.deployments.length - 1);
              }
              this.deployments.unshift(...[data]);
              break;
            case 1:
            case two:
              this.deployments.splice(indexDeploy, 1);
              this.deployments.unshift(...[data]);
              break;
          }
        }
      }

    });
    super.addSubscriptions(deploymentSelectedSubscription);
  }
  public getDeploymentStatus(status: string): string {
    if (!status) {
      status = 'not on cloud';
    }
    switch (status.toLocaleLowerCase()) {
      case 'on cloud':
        return 'text-success';
      case 'not on cloud':
        return '';
      default:
        return 'c-orange';
    }
  }

  public isInProgress(status): boolean {
    return !status || status.toLowerCase() === 'not on cloud';
  }

  public setDeploymentSelected(deployment): void {
    this.deploymentsMenuService.setDeploymentSelected(deployment);
  }

  private setTranslation(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
    this.deploymentsLabel = this.translate.instant('deployments.DEPLOYMENTS');
  }

  public setProvisionDeleted(): void {
    this.deploymentsMenuService.provisionDeleted.subscribe (provisionDeleted => {
      if (provisionDeleted) {
        this.provisionDeleted = provisionDeleted;
        const getDeploymentsSubs = this.deploymentsMenuService.getRecentDeployments().subscribe((data) => {
          this.deployments = data.list;
          this.setDeploymentSelected(this.deployments[0]);
        });
        super.addSubscriptions(getDeploymentsSubs);
      }
    });
  }
}
