export const locale = {
  lang: 'es',
  ngroupSoletenantProvision : {
    'SOLETENANT_CONFIGURATION': 'Configuración de Sole-tenant',
    'SOLETENANT_DESCRIPTION' : `Si la compatibilidad con sole-tenant está habilitada en la implementación,
                                   proporcione el grupo de nodos predeterminado para las máquinas virtuales en esta región.
                                   Cambiar esta configuración no afecta a ninguna VM existente, solo a los nuevos VMS.`,
    'SOLETENANT_GROUP_DESCRIPTION': 'Grupo de nodos Sole-tenant para esta región',
    'SOLETENANT_GROUP_NOT_FOUND': 'El grupo de nodos seleccionado no se pudo encontrar en GCP',
    'REQUIRED': 'Campo requerido',
    'SOLETENANT_NO_DATA': 'No existen  nodos de grupos sole tenant en el proyecto de GCP',
    'NOT_ITEMS_FOUND': 'No se encontraron elementos'
  }
};
