import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { ListDto, SoltenantGroup, Subnet } from 'src/app/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegionsListService {

  constructor(private http: HttpClient) { }

  public getRegions(deploymentId?: number): Observable<any> {
    let paramsObj = new HttpParams().set('pageIndex', '0').set('pageSize', '200');
    if (deploymentId) {
      paramsObj = paramsObj.set('deploymentId', `${deploymentId}`);
    }
    return this.http.get<any>(`${environment.apiUrlV3}regions`, { params: paramsObj });
  }

  public getZones(regionId: number): Observable<any> {
    const paramsObj = new HttpParams().set('pageIndex', '0').set('pageSize', '200');
    return this.http.get<any>(`${environment.apiUrlV3}regions/${regionId}/zones`, { params: paramsObj });
  }

  public getDeploymentRegions(deploymentId: number): Observable<any> {
    const paramsObj = new HttpParams().set('pageIndex', '0').set('pageSize', '200');
    return this.http.get<any>(`${environment.apiUrlV3}deployments/${deploymentId}/regions`, { params: paramsObj });
  }

  public postDeploymentRegion(deploymentId: number, region: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrlV3}deployments/${deploymentId}/regions`, region);
  }

  public putDeploymentRegion(deploymentId: number, regionId: number, region: any): Observable<any> {
    return this.http.put<any>(`${environment.apiUrlV3}deployments/${deploymentId}/regions/${regionId}`, region);
  }

  public deleteDeploymentRegion(deploymentId: number, regionId: any): Observable<any> {
    return this.http.request('DELETE', `${environment.apiUrlV3}deployments/${deploymentId}/regions/${regionId}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { }
    });
  }

  public getSoltenantGroupByRegion(deploymentId: number, regionId: number): Observable< ListDto<SoltenantGroup> > {
    const params = new HttpParams().set('skeepFailure', 'true');
    return this.http.get<ListDto<SoltenantGroup>>(`${environment.apiUrl}deployments/${deploymentId}/soleTenantsGroup/regions/${regionId}`, {params: params});
  }

  public getRegionSubnetworks(deploymentId: number, region: string, network: string): Observable<any> {
    const params = new HttpParams().set('region', region).set('skeepFailure', 'true');
    return this.http.get<ListDto<Subnet>>(`${environment.apiUrl}deployments/${deploymentId}/vpcNetwork/${network}/subNetworks`,
    { params: params });
  }
}
