import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'underscore';

const two = 2;
export const cpuMachineType = function (control: AbstractControl): ValidationErrors | null {
const value: number = control.value;
if (value === null) {
  return null;
}
const valid = value > 0 && value <= control.parent.value['MaxCpu']
                          && value % 1 === 0 && (value % two === 0 || value === control.parent.value['MinCpu']);

if (valid) {
    return null;
  } else {
    return {
      pattern: 'Invalid format'
     };
  }
};


