export const locale = {
  lang: 'en',
  'region_certificate' : {
    'SSL_CERTIFICATE': 'SSL Certificate',
    'UPLOAD': 'Upload',
    'PASSWORD': 'Password',
    'MAX_SIZE_VALIDATION': 'Maximum size exceeded. Limit: 2 Mb.',
    'EMPTY_FILE': 'Empty file',
    'FORMAT_VALIDATION': 'Only Personal Information Excange (PFX) files allowed.',
    'ERROR_REQUIRED': 'This field is required',
    'ERROR_UPLOAD_NEW': 'Upload new certificate',
    'ERROR_PATTERN': 'The value in this field is invalid',
    'PROVIDE_ITOPIA_TEXT' : 'Provided by itopia'

  }
};
