import { Site } from '../common/site';
import { Workstation } from '../common/workstation';

export class CatalogUser {
  public pcUserId: number;
  public name: string;
  public userName: string;
  public email: string;
  public phone: string;
  public location: Site;
  public locationName: string;
  public workstation: Workstation;
}
