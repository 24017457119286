import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { catchError, map } from 'rxjs/operators';
import * as _ from 'underscore';

import { NotificationActions } from 'src/app/core/shared/notifications/enum/notifications.actions';
import { NotificationResources } from 'src/app/core/shared/notifications/enum/notifications.resources';
import { NotificationTranslateService } from 'src/app/core/shared/notifications/notification-translate.service';
import { EarlyAccessFeature, HttpStatusCodes, RadioGroupType, ServerError, ValidationErrorsCodes } from 'src/app/models';

import { ModalService } from '../../core/shared/services/modal.service';
import { DeploymentsService } from '../../deployments/deployments.service';
import { Deployment } from '../../models/deployment/deployment';
import { AutoUnsubscribeComponent } from '../auto-unsubscribe/auto-unsubscribe.component';
import { ResetPasswordSelection } from './reset-password-selection';
import { ResetUserPasswordService } from './reset-user-password.service';

import { locale as english } from './i18n/reset-user-password.en';
import { locale as spanish } from './i18n/reset-user-password.es';

const trusted = 'TrustedDomain';
@Component({
  selector: 'app-reset-user-password',
  templateUrl: './reset-user-password.component.html',
  styleUrls: ['./reset-user-password.component.scss']
})
export class ResetUserPasswordComponent extends AutoUnsubscribeComponent implements OnInit {
  @Input()
  public userSelected: any[];
  @Input()
  public isResetPasswordV2: boolean;
  @Input()
  public hasExpireAccess: boolean;
  @Input()
  public hasSsprSetting: boolean;

  public resetPassForm: FormGroup;
  public submitted: boolean;
  public deploymentSelected: Deployment;
  public resetTooltip: string;
  public successMsg: string;
  public actionsButtons: any;
  public errorRequired: string;
  public title: string;
  public info: string;
  public errorOnReset: boolean;
  public listResetPasswordOption: RadioGroupType[];
  public resetPasswordOptionSelected: RadioGroupType;
  public sendLinkTitle: string;
  public sendLinkSubtitle: string;
  public manualResetTitle: string;
  public manualResetEmail: string;
  public sendPasswordByEmail: boolean;
  public expireCurrentPassword: boolean;
  public skipAndResetTrusted: boolean;
  public skipAndResetEmail: boolean;
  public skipAndResetAll: boolean;
  public dontSendMail: boolean;
  public expireCurrentPasswordLabel: string;
  public expireCurrentPasswordHelp: string;
  public passwordHide: boolean;
  public hasNoEmail: boolean;
  public allTrusted: boolean;
  public someEmailMissing: boolean;
  public someTrusted: boolean;
  public noEmailWarningLabel: string;
  public feature: string;
  public isMultiple: boolean;
  public amountEmails: number;
  public amountTrusted: number;

  public someEmailMultipleWarningLabel: string;
  public allEmailMultipleWarningLabel: string;
  public trustedMultipleWarningLabel: string;
  public trustedAllMultipleWarningLabel: string;
  public skipMultipleLabel: string;
  public noSendEmailMultipleLabel: string;
  public noUserToResetLabel: string;

  public invalidResetAll: boolean;
  public invalidResetTrusted: boolean;
  public invalidEmailOptions: boolean;
  public invalidFlags: boolean;

  @ViewChild('ref', { static: false }) public ref: any;

  constructor(private formBuilder: FormBuilder, private deploymentsMenuService: DeploymentsService,
              private resetPasswordService: ResetUserPasswordService, private modalService: ModalService,
              private notifierTranslate: NotificationTranslateService, private translate: TranslateService,
              private notifierService: NotifierService) {
    super();
    const deploymentSelectedSubscription = this.deploymentsMenuService.deploymentSelected.subscribe(deploymentSelected => {
      if (deploymentSelected && deploymentSelected.companyId) {
        this.deploymentSelected = deploymentSelected;
      }
    });
    super.addSubscriptions(deploymentSelectedSubscription);
  }

  public ngOnInit(): void {
    this.feature = this.isResetPasswordV2 ? EarlyAccessFeature.ResetPasswordV2 : '';

    this.isMultiple = this.userSelected && this.userSelected.length > 1;

    this.allTrusted = this.isMultiple && _.all(this.userSelected, user => user.importFrom  === trusted);
    this.someTrusted = this.isMultiple && !this.allTrusted && _.any(this.userSelected, user => user.importFrom  === trusted);

    this.hasNoEmail = this.isMultiple
      ? _.all(this.userSelected, user => _.isEmpty(user.email)) && !this.allTrusted
      : _.isEmpty(this.userSelected[0].email);
    this.someEmailMissing = this.isMultiple && !this.allTrusted &&
      !this.hasNoEmail && _.any(this.userSelected, user => user.importFrom  !== trusted && _.isEmpty(user.email));

    this.amountEmails = this.someEmailMissing
      ? _.filter(this.userSelected, user => user.importFrom  !== trusted && _.isEmpty(user.email)).length
      : 0;
    this.amountTrusted = this.someTrusted
      ? _.filter(this.userSelected, user => user.importFrom  === trusted).length
      : 0;

    this.resetPassForm = this.formBuilder.group({
      Password: ['', Validators.required],
      SendLink: [{ value: '', disabled: this.hasNoEmail }],
      ManualReset: [{ value: '', disabled: false }],
      ExpireCurrentPassword: [{ value: '', disabled: this.hasNoEmail }],
      SendPasswordByEmail: [{ value: '', disabled: this.hasNoEmail}]
    });
    this.errorOnReset = false;
    this.setTranslations();
    this.listResetPasswordOption = [
      new RadioGroupType({
        value: ResetPasswordSelection.ManualReset,
        title: this.manualResetTitle
      })
    ];
    this.sendPasswordByEmail = false;
    this.expireCurrentPassword = false;
    this.skipAndResetTrusted = false;
    this.skipAndResetEmail = false;
    this.skipAndResetAll = false;
    this.dontSendMail = false;
    this.passwordHide = true;
    if (this.hasSsprSetting) {
      this.listResetPasswordOption.unshift(new RadioGroupType({
        value: ResetPasswordSelection.SendLink,
        title: this.sendLinkTitle,
        subTitle: this.sendLinkSubtitle
      }));
    }
    if (this.listResetPasswordOption.length > 1) {
      this.setResetPasswordOption(this.listResetPasswordOption[this.hasNoEmail && this.hasSsprSetting ? 1 : 0]);
    } else {
      this.setResetPasswordOption(this.listResetPasswordOption[0]);
    }

    if (this.allTrusted) {
      this.resetPassForm.disable();
    }
  }
  // convenience getter for easy access to form fields
  get f() { return this.resetPassForm.controls; }

  public setResetPasswordOption(option: RadioGroupType): void {
    this.resetPasswordOptionSelected = option;
    const isSendLink = this.isResetPasswordV2 && this.resetPasswordOptionSelected.value === ResetPasswordSelection.SendLink;
    if (isSendLink) {
      this.f.Password.reset();
      this.f.Password.disable();
      this.sendPasswordByEmail = false;
    } else {
      this.f.Password.enable();
      this.expireCurrentPassword = false;
    }

    this.clearFlags();
  }
  public toggleSendPasswordByEmail(): void {
    this.sendPasswordByEmail = !this.sendPasswordByEmail;
    this.clearFlags();
  }
  public toggleExpireCurrentPassword(): void {
    this.expireCurrentPassword = !this.expireCurrentPassword;
    this.errorOnReset = false;
  }
  public toggleSkipAndResetTrusted(): void {
    this.skipAndResetTrusted = !this.skipAndResetTrusted;
    this.invalidResetTrusted = false;
    this.invalidFlags = false;
  }
  public toggleSkipAndResetAll(): void {
    this.skipAndResetAll = !this.skipAndResetAll;
    this.invalidResetAll = false;
    this.invalidFlags = false;
  }
  public toggleSkipAndResetEmail(): void {
    this.skipAndResetEmail = !this.skipAndResetEmail;
    if (this.sendPasswordByEmail) {
      this.dontSendMail = !this.skipAndResetEmail;
    }
    this.invalidEmailOptions = false;
    this.invalidFlags = false;
  }
  public toggleDontSendMail(): void {
    this.dontSendMail = !this.dontSendMail;
    this.skipAndResetEmail = !this.dontSendMail;
    this.invalidEmailOptions = false;
    this.invalidFlags = false;
  }
  public onActionConfirmation(confirm) {
    if (confirm) {
      this.onSubmit();
    } else {
      this.closeResetUserPasswordModal();
    }
  }
  public onSubmit(): void {
    this.submitted = true;
    if (this.isMultiple) {
      this.checkInvalidFlags();
    }

    if (this.resetPassForm.invalid || this.allTrusted || this.invalidFlags) {
      this.f.Password.markAsTouched();
      if (this.invalidFlags) {
        this.submitted = false;
      }
      return;
    }

    this.errorOnReset = false;
    this.setTranslations();
    this.resetPassForm.disable();

    if (!this.isMultiple) {
      this.resetPasswordToSingleUser();
    } else {
      this.resetPasswordToMultipleUsers();
    }

  }
  public closeResetUserPasswordModal(): void {
    this.submitted = false;
    this.modalService.dismissAll();
  }
  @HostListener('mouseleave', ['$event'])
  public onHoverOutside() {
    this.ref.close();
  }
  public setErrorOnReset(value: string): void {
    if (value.length > 0) {
      this.errorOnReset = false;
    }
  }
  public isManualResetSelected(): boolean {
    return this.resetPasswordOptionSelected.value === ResetPasswordSelection.ManualReset;
  }

  private clearFlags(): void {
    this.dontSendMail = false;
    this.skipAndResetEmail = false;
    this.skipAndResetTrusted = false;
    this.skipAndResetAll = false;

    this.invalidFlags = false;
    this.invalidResetAll = false;
    this.invalidResetTrusted = false;
    this.invalidEmailOptions = false;
    this.errorOnReset = false;
  }
  private checkInvalidFlags(): void {
    this.invalidResetAll = (this.someTrusted || this.someEmailMissing) && !this.skipAndResetAll;
    if (this.isManualResetSelected()) {
      this.invalidResetAll = this.invalidResetAll && !this.sendPasswordByEmail;
      this.invalidResetTrusted = this.sendPasswordByEmail && this.someTrusted && !this.skipAndResetTrusted;
      this.invalidEmailOptions = this.sendPasswordByEmail && this.someEmailMissing && !this.skipAndResetEmail && !this.dontSendMail;
    } else {
      this.invalidResetTrusted = false;
      this.invalidEmailOptions = false;
    }
    this.invalidFlags = this.invalidResetAll || this.invalidResetTrusted || this.invalidEmailOptions;
  }
  private resetPasswordSuccess(isSendLink?: boolean, amount: number = 0): void {
    const isMultiple = amount > 1;
    this.notifierTranslate.success(
        isSendLink
          ? NotificationActions.send
          : NotificationActions.reset,
        isSendLink
          ? NotificationResources.password_link
          : NotificationResources.password,
          isMultiple
          ? isSendLink
            ? NotificationResources.password_link
            : NotificationResources.password
          : (this.userSelected[0].username ? this.userSelected[0].username : ''),
        !isSendLink, isMultiple, isMultiple ? amount : 0);
    this.closeResetUserPasswordModal();
  }
  private resetPasswordError(errorResponse): void {
    const serverError = new ServerError(errorResponse.error);
    if (serverError.status === HttpStatusCodes.ValidationError ||
      serverError.code === ValidationErrorsCodes.ValidationError40027) {
      const validationErrors = serverError.errors;
      Object.keys(validationErrors).forEach(prop => {
        this.resetPassForm.get(prop).setErrors({ serverError: validationErrors[prop] });
      });
    } else if (serverError.status === HttpStatusCodes.NotFound &&
      serverError.code === ValidationErrorsCodes.ValidationError40434) {
      this.resetPassForm.get('Password').setErrors({ serverError: serverError.message });
      return;
    } else {
      if (serverError.status === HttpStatusCodes.BadRequest &&
        serverError.code === ValidationErrorsCodes.ValidationError40029) {
        this.notifierService.notify('error', serverError.message);
      }
      this.errorOnReset = true;
      this.setTranslations();
    }
    this.submitted = false;
  }
  private resetPasswordToSingleUser(): void {
    const isSendLink = this.isResetPasswordV2 && this.resetPasswordOptionSelected.value === ResetPasswordSelection.SendLink;
    const data =
      isSendLink
        ? {
          deploymentId: this.deploymentSelected.companyId,
          username: this.userSelected[0].username,
          expireCurrentPassword: this.expireCurrentPassword
        } :
        {
          deploymentId: this.deploymentSelected.companyId,
          cloudUserId: this.userSelected[0].cloudUserId,
          passwordModel: {
            password: this.f.Password.value,
            email: this.userSelected[0].email,
            sendByEmail: this.sendPasswordByEmail
          }
        };
    const resetUserPasswordSubs =
      (isSendLink
        ? this.resetPasswordService.sendResetPasswordEmail(data)
        : this.resetPasswordService.resetUserPassword(data)
      )
        .pipe(
          map(() => {
            this.resetPasswordSuccess(isSendLink);
          }),
          catchError((error) => {
            this.errorOnReset = true;
            this.resetPassForm.enable();
            if (isSendLink) {
              this.f.Password.disable();
            }
            this.resetPasswordError(error);
            return error;
          })
        )
        .subscribe();
    super.addSubscriptions(resetUserPasswordSubs);
  }
  private resetPasswordToMultipleUsers(): void {
    let usersToSend = this.skipAndResetTrusted || this.skipAndResetAll
      ? _.filter(this.userSelected, user => user.importFrom  !== trusted)
      : this.userSelected;
    if (this.skipAndResetEmail || this.skipAndResetAll) {
      usersToSend = _.filter(usersToSend, user => !_.isEmpty(user.email));
    }

    if (_.isEmpty(usersToSend)) {
      this.notifierService.notify('warning', this.noUserToResetLabel);
      this.closeResetUserPasswordModal();
      return;
    }

    const isSendLink = this.resetPasswordOptionSelected.value === ResetPasswordSelection.SendLink;

    const usersToSkipEmail = !isSendLink && this.sendPasswordByEmail && this.dontSendMail
      ? _.filter(usersToSend, user => user.importFrom  !== trusted && _.isEmpty(user.email))
      : [];

    const data = {
          deploymentId: this.deploymentSelected.companyId,
          passwordModel: {
            resetPasswordMode: isSendLink ? 0 : 1,
            cloudUserIds: _.pluck(usersToSend, 'cloudUserId'),
            expireCurrentPassword: this.expireCurrentPassword,
            password: this.f.Password.value,
            sendByEmail: this.sendPasswordByEmail,
            idsToSkipEmail: _.pluck(usersToSkipEmail, 'cloudUserId')
          }
        };
    const resetUserPasswordSubs =
      this.resetPasswordService.resetPasswordMultiple(data)
        .pipe(
          map(() => {
            this.resetPasswordSuccess(isSendLink, usersToSend.length);
          }),
          catchError((error) => {
            this.errorOnReset = true;
            this.resetPassForm.enable();
            if (isSendLink) {
              this.f.Password.disable();
            }
            this.resetPasswordError(error);
            return error;
          })
        )
        .subscribe();
    super.addSubscriptions(resetUserPasswordSubs);
  }


  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
    this.title = this.translate.instant('reset_user_password.RESET');
    this.info = this.isMultiple
      ? this.translate.instant('reset_user_password.MULTIPLE_USERS_SELECTED',
          { amount: this.userSelected && this.userSelected.length })
      : this.isResetPasswordV2 && this.userSelected && this.userSelected[0] && this.userSelected[0].username || '';
    this.resetTooltip = this.translate.instant('reset_user_password.RESET_TOOLTIP');
    this.successMsg = this.translate.instant('reset_user_password.RESET_SUCCESS');
    this.actionsButtons = [
      this.errorOnReset === false
        ? this.translate.instant('reset_user_password.CANCEL')
        : this.translate.instant('reset_user_password.CLOSE'),
      this.isResetPasswordV2
        ? this.translate.instant('reset_user_password.RESET_BTN')
        : this.translate.instant('reset_user_password.SAVE'),
      this.isResetPasswordV2
        ? this.translate.instant('reset_user_password.RESETTING')
        : this.translate.instant('reset_user_password.SAVING')
    ];
    this.errorRequired = this.translate.instant('reset_user_password.ERROR_REQUIRED');
    this.sendLinkTitle = this.translate.instant('reset_user_password.SEND_LINK_TITLE');
    this.sendLinkSubtitle = this.translate.instant('reset_user_password.SEND_LINK_SUBTITLE');
    this.manualResetTitle = this.translate.instant('reset_user_password.MANUAL_RESET_TITLE');
    this.manualResetEmail = this.translate.instant('reset_user_password.MANUAL_RESET_EMAIL');
    this.expireCurrentPasswordLabel = this.translate.instant('reset_user_password.EXPIRE_CURRENT_PASSWORD');
    this.expireCurrentPasswordHelp = this.translate.instant('reset_user_password.EXPIRE_CURRENT_PASSWORD_HELP');
    this.noEmailWarningLabel = this.translate.instant('reset_user_password.NO_EMAIL_WARNING');

    this.someEmailMultipleWarningLabel = this.amountEmails === 1
    ? this.translate.instant('reset_user_password.ONE_USER_WITH_NO_EMAIL')
    : this.translate.instant('reset_user_password.MULTIPLE_USERS_WITH_NO_EMAIL',
      { amount: this.amountEmails });
    this.trustedMultipleWarningLabel = this.amountTrusted === 1
    ? this.translate.instant('reset_user_password.ONE_USER_FROM_TRUSTED')
    : this.translate.instant('reset_user_password.MULTIPLE_USERS_FROM_TRUSTED',
      { amount: this.amountTrusted });
    this.allEmailMultipleWarningLabel = this.translate.instant('reset_user_password.MULTIPLE_USERS_WITH_NO_EMAIL_ALL');    
    this.trustedAllMultipleWarningLabel = this.translate.instant('reset_user_password.MULTIPLE_USERS_FROM_TRUSTED_ALL');
    this.skipMultipleLabel = this.translate.instant('reset_user_password.MULTIPLE_USERS_SKIP_RESET');
    this.noSendEmailMultipleLabel = this.translate.instant('reset_user_password.MULTIPLE_USERS_RESET_NO_EMAIL');
    this.noUserToResetLabel = this.translate.instant('reset_user_password.NO_USER_TO_RESET');
  }
}
