import { GpuConfig } from './gpu-config';
import { GpuErrors } from './gpu-errors';

export class GpuCardInfo {
    public isChecked: boolean;
    public isInvalid: boolean;
    public isDisabled: boolean;
    public validators: any;
    public gpuConfig: GpuConfig;
    public gpuErrors: GpuErrors;

    constructor(data?: any) {
        this.isChecked = data && data.isChecked ? data.isChecked : false;
        this.isInvalid = data && data.isInvalid ? data.isInvalid : false;
        this.isDisabled = data && data.isDisabled ? data.isDisabled : false;
        this.validators = data && data.validators ? data.code : [];
        this.gpuConfig = data && data.gpuConfig ? data.gpuConfig : [];
        this.gpuErrors = data && data.gpuErrors ? data.gpuErrors : null;
    }
}
