// Angular Imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import { NotifierModule } from 'angular-notifier';
import { WarningModalModule } from './../shared/warning-modal/warning-modal.module';

// This Module's Components
import { AddonSubscriptionComponent } from '../shared/subscription/addon-subscription/addon-subscription.component';
import { AddonSubscriptionComponentModule } from '../shared/subscription/addon-subscription/addon-subscription.module';
import { WarningModalAutoscalingComponent } from '../shared/warning-modal-autoscaling/warning-modal-autoscaling.component';
import { WarningModalAutoscalingModule } from '../shared/warning-modal-autoscaling/warning-modal-autoscaling.module';
import { WarningModalContinueComponent } from '../shared/warning-modal-continue/warning-modal-continue.component';
import { WarningModalComponent } from '../shared/warning-modal/warning-modal.component';
import { AutoUnsubscribeModule } from './../shared/auto-unsubscribe/auto-unsubscribe.module';
import { LoginComponent } from './login.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, AutoUnsubscribeModule, WarningModalModule,
            WarningModalAutoscalingModule, AddonSubscriptionComponentModule, NotifierModule.withConfig({
      position: {
        horizontal: { position: 'right' },
        vertical: { position: 'bottom', distance: 30 }
      }
    })],
  declarations: [LoginComponent],
  exports: [LoginComponent],
  entryComponents: [WarningModalComponent, WarningModalContinueComponent, WarningModalAutoscalingComponent, AddonSubscriptionComponent]
})
export class LoginModule {

}
