import { DedicatedInstance } from './dedicated-instance';
import { Pool } from './pool';

export class User {
    public cloudUserId: number;
    public username: string;
    public password: string;
    public firstName: string;
    public lastName: string;
    public fullName: string;
    public authdata?: string;
    public user: any;
    public department: string;
    public contactPhone: string;
    public companyId: number;
    public deploymentId: number;
    public onCloud: boolean;
    public source: string;
    public contactPhoneExt: string;
    public createDate: Date;
    public sendToCloudDate: Date;
    public companyUserStatusId: number;
    public countWorkstation: number;
    public location: string;
    public status: string;
    public regionId: number;
    public externalUserId: any;
    public scaleId: number;
    public collectionAlias: string;
    public region: string;
    public originalFullName: string;
    public statusUpdated: boolean;
    public importFrom: string;
    public domain: string;
    public failedProcess: string;
    public organizationId: number;
    public allow: boolean;
    public samAccountName: string;
    public collections: Pool[];
    public dedicatedInstances: DedicatedInstance[];
    public autoImported: boolean;
    public partnerId: number;
    public displayStatus: string;
    public loadingStatus: boolean;
}
