import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class NotificationPaneActionService {
  private notificationInfo$: BehaviorSubject<{ hasErrors: boolean, count: number }>;
  public notificationInfo: Observable<{ hasErrors: boolean, count: number }>;
  private closeSideNav$: BehaviorSubject<boolean>;
  public closeSideNav: Observable<boolean>;

  constructor() {
    this.notificationInfo$ = new BehaviorSubject<{ hasErrors: boolean, count: number }>({ hasErrors: false, count: 0 });
    this.notificationInfo = this.notificationInfo$.asObservable();
    this.closeSideNav$ = new BehaviorSubject<boolean>(false);
    this.closeSideNav = this.closeSideNav$.asObservable();
  }

  public setNotificationInfo(info: { hasErrors: boolean, count: number }): void {
    this.notificationInfo$.next(info);
  }

  public setCloseSideNav(opened: boolean): void {
    this.closeSideNav$.next(opened);
  }
}
