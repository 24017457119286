export const locale = {
  lang: 'es',
  'process_viewer': {
    'TITLE': 'Actividades recientes',
    'ERROR_TITLE': 'Errores:',
    'PROGRESS_TITLE': 'En curso:',
    'TASK': 'Tarea',
    'TARGET': 'Ubicación',
    'STATUS': 'Estado',
    'START_TIME': 'Hora inicial',
    'INITIATED_BY': 'Iniciado por',
    'TASK_ID': 'ID de la Tarea',
    'NO_DATA': 'No se encontro actividad',
    'SEARCH': `Buscar`,
    'REFRESH': 'Recargar',
    'TOTAL': 'total',
    'SELECTED': 'seleccionado',
    'SHOWING': 'mostrando',
    'DETAILS_TITLE': 'Detalles de la tarea',
    'COMPLETION_TIME': 'Tiempo de finalización',
    'DETAILS': 'Detalles',
    'CLOSE': 'Cerrar',
    'RETRY': 'Reintentar',
    'ERROR': 'Error',
    'COMPLETED': 'Completado',
    'PROGRESS': 'En Progreso',
    'RETRYING': 'Reintentando',
    'RETRYING_MSG': 'Se ha reintentado el proceso satisfactoriamente',
    'TIMEZONE_DESCRIPTION': 'Todos los tiempos se muestran en'
   }
};
