export const locale = {
  lang: 'es',
  header_navigation : {
    AUDITS: `Actividades`,
    FEEDBACK: `Retroalimentación`,
    HELP: `Ayuda`,
    HELP_CENTER: `Centro de ayuda`,
    NOTIFICATIONS: `Notificaciones`,
    PROFILE: `Perfil`,
    MY_ACCOUNT: 'Mi Cuenta',
    MANAGE : `Administrar Organización`,
    SUBSCRIPTION: 'Suscripción',
    ADMINS: `Administradores`,
    CLOUD_CALCULATOR: `Calculadora`,
    SUSCRIPTION: `Suscripción`,
    INTEGRATIONS: `Integraciones`,
    LOG_OUT: 'Salir',
    CHECK_ALL: 'Ir a notificaciones',
    RETRY : 'Reintentar',
    NO_NOTIFICATIONS: `No tiene nuevas notificaciones`,
    SUPPORT: 'Soporte',
    SUCCESFULL_AUTOSCALING: 'Horario configurado correctamente',
    GO_TO_NOTIFICATIONS: 'Ir a Notificationes'
  }
};
