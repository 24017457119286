export class CollectionPoolTypeModel {
  public collectionPoolTypeId : number;
  public name: string;
  public code: string;

  constructor(data?) {
    this.collectionPoolTypeId = data && data.collectionPoolTypeId ? data.collectionPoolTypeId : 0;
    this.name = data && data.name ? data.name : '';
    this.code = data && data.code ? data.code : '';
  }
}
