export const locale = {
  lang: 'en',
  ngroupSoletenantProvision : {
    'SOLETENANT_CONFIGURATION': 'Sole-tenant configuration',
    'SOLETENANT_DESCRIPTION' : `If sole-tenant node support is enabled in the deployment,
                                 provide the default node group for VMs in this region.
                                 Changing this setting does not affect any existing VMs, only new VMS.`,
    'SOLETENANT_GROUP_DESCRIPTION': 'Sole-tenant node group for this region',
    'SOLETENANT_GROUP_NOT_FOUND': 'The selected node group could not be found in GCP',
    'REQUIRED': 'Field required',
    'SOLETENANT_NO_DATA': 'There are no sole tenant node groups in the GCP project',
    'NOT_ITEMS_FOUND': 'No items found'
  }
};
