import { SafeResourceUrl } from '@angular/platform-browser';

export class Icon {
    public name: string;
    public content: string;
    public contentType: string;
    public imagePath: SafeResourceUrl;

    constructor(data?: any) {
        this.name = data && data.Name ? data.Name : '';
        this.content = data && data.Content ? data.Content : '';
        this.contentType = data && data.ContentType ? data.ContentType : '';
    }
}
