export const locale = {
    lang: 'en',
    img_upload : {
        ERROR_REQUIRED: 'This field is required',
        ERROR_PATTERN: 'The value in this field is invalid',
        IMG_LABEL: 'Select image (jpg, png, gif)',
        IMG_PLACEHOLDER: 'No file selected.',
        BROWSE_BTN: 'Browse...',
        UPLOAD_BTN: 'Upload',
        REMOVE_BTN: 'Remove',
        INVALID_SIZE: 'File too large. Maximum allowed: 100 KB',
        INVALID_EXTENSION: 'You must select a image file'
    }
};
