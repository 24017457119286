export class SessionHostCustom {
  public sessionHostId: number;
  public name: string;
  public region: string;
  public regionId: number;
  public statusDataBase: string;
  public status: string;
  public statusId: number;
  public userSessions: number;
  public failedProcess: string;
  public unassigned: boolean;
  public loadingStatus: boolean;
  public displayStatus: string;
  public statusSessionHost: string;
  public isLoadingCount: boolean;
  public serverTypeCode: string;
  public isOnline: boolean;
  constructor(data ? : any) {
  this.sessionHostId = data && data.sessionHostId ? data.sessionHostId : 0;
  this.name = data && data.name ? data.name : '';
  this.region = data && data.region ? data.region : '';
  this.regionId = data && data.regionId ? data.regionId : 0;
  this.statusDataBase = data && data.status ? data.status : '';
  this.status = data && data.status ? data.status : '';
  this.statusId = data && data.statusId ? data.statusId : 0;
  this.failedProcess = data && data.failedProcess ? data.failedProcess : '';
  this.serverTypeCode = data && data.serverTypeCode ? data.serverTypeCode : '';
  this.unassigned = data && data.unassigned ? data.unassigned : false;
  this.userSessions = data && data.userSessions ? data.userSessions : null;
  this.isOnline = data && data.isOnline ? data.isOnline : false;
  }
}
