export const locale = {
  lang: 'es',
  table_toolbar : {
    'REFRESH': 'Recargar',
    'EDIT': 'Editar',
    'DELETE': 'Eliminar',
    'SETTINGS': `Ajustes`,
    'RESET_PASS': `Reinciar Contraseña`,
    'LOG_OF': `Desconectar`,
    'UNLOCK': `Desbloquear`,
    'CREATE': `Crear`,
    'SHOW': 'Mostrar',
    'PER': 'por página',
    'SEARCH': `Buscar`,
    'ENABLE_ACTION': 'Habilitar',
    'DISABLE_ACTION': 'Deshabilitar',
    'IMPORT': 'Añadir desde',
    'IMPORT_FROM': 'Importar desde',
    'IMPORT_EXCEL': 'Hoja de cálculo',
    'IMPORT_CATALOG': 'Catálogo',
    'IMPORT_AD': 'Directorio Activo',
    'LICENSES': 'Licencias',
    'START': 'Iniciar',
    'STOP': 'Apagar',
    'RESTART': 'Reiniciar',
    'CONNECT': 'Conectar',
    'CLONE': 'Clonar',
    'COMPLETE' : 'Completar',
    'COPY': 'Copiar',
    'IMPORT_GCP': 'Importar desde GCP'
  }
};
