import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ProServices } from '../../models/support/pro-services';


@Injectable({
  providedIn: 'root'
})
export class ProServicesService {

  constructor(private http: HttpClient) { }

  public sendProServicesRequest (proServices: ProServices) : Observable<ProServices> {    
    return this.http.post<ProServices>(`${environment.apiUrl}support/proServices`, proServices);
    }   
    
}
