import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

import { environment } from 'src/environments/environment';

declare const gapi: any;

@Injectable({
  providedIn: 'root'
})
export class GcloudresourcemanagerService {
  public service = 'cloudresourcemanager.googleapis.com';
  constructor() { }

  public loadGcloudresourcemanager(): void {
    try {
      return gapi.client.load('servicemanagement', environment.gapi.apiVersion);
    } catch (exception) {
      throwError(exception);
      return;
    }
  }
  public activate(consumer) {
    const params = {
      serviceName: this.service,
      consumerId: consumer
    };
    const request = gapi.client.servicemanagement.services.enable(params);
    return request.then(function (response) {
      if (response && response.result && response.result.name) {
        return Promise.resolve(response.result.name);
      }
      Promise.resolve(false);
    });
  }
  public checkServices(consumerId) {
    try {
      const request = gapi.client.servicemanagement.services.list({ consumerId: consumerId, pageSize: 100 });
      return request.then((response) => {
        if (response.result) {
          return Promise.resolve(response.result.services);
        }
        return Promise.resolve({ done: false });
      }).catch(function (reason) {
        reason.request = {
          name: this.service,
          errors: reason.errors
        };
        return Promise.reject(reason);
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public hasEnabled(operationName) {
    const params = {
      name: operationName
    };
    const request = gapi.client.servicemanagement.operations.get(params);
    return request.then(function (response) {
      if (response.result) {
        return Promise.resolve(response.result);
      }
      return Promise.resolve({ done: false });
    }).catch(function (reason) {
      reason.request = {
        name: this.service,
        params: params,
        errors: reason.errors
      };
      return Promise.reject(reason);
    });
  }
}
