import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs/Subject';
import {ModalService} from '../../core/shared/services/modal.service';
import { AutoUnsubscribeComponent } from '../../shared/auto-unsubscribe/auto-unsubscribe.component';

import { locale as english } from './i18n/warning-modal-cancel-subscription.en';
import { locale as spanish } from './i18n/warning-modal-cancel-subscription.es';

const five = 5;
const two = 2;

@Component({
  selector: 'app-warning-modal-cancel-subscription',
  templateUrl: './warning-modal-cancel-subscription.component.html',
  styleUrls: ['./warning-modal-cancel-subscription.component.scss']
})
export class WarningModalCancelSubscriptionComponent extends AutoUnsubscribeComponent implements OnInit {
  @Input()
  public cancelDate: string;
  @Input()
  public cancelAtEndOfPeriod: boolean;

  @Output()
  public actionConfirmationEmitter = new EventEmitter<any>();

  public canCloseModal$ = new Subject<boolean>();
  public actionPressedNow: boolean;
  public actionPressedEndPeriod: boolean;
  public i18nMessages: string[];
  public modalTitle: string;
  public cancelBtn: string;
  public immediatelyBtn: string;
  public endPeriodBtn: string;
  public canceling: string;

  constructor( private modalService: ModalService, private translate: TranslateService) {
    super();
    const canCloseModalSubs = this.canCloseModal$.subscribe((data) => {
      if (data) {
        this.closeWarningModal();
      }
    });
    super.addSubscriptions(canCloseModalSubs);
  }

  public ngOnInit() {
    this.i18nMessages = [];
    this.setTranslations();
  }

  public actionConfirmation(actionConfirmation: string): void {
    if (actionConfirmation === 'cancel') {
      this.closeWarningModal();
    } else {
      this[`actionPressed${actionConfirmation}`] = true;
      this.actionConfirmationEmitter.emit(actionConfirmation);
    }
  }

  public closeWarningModal(): void {
    this.modalService.dismissAll();
  }

  private setTranslations(): void {
    let browserLang = this.translate.getBrowserLang();
    browserLang = browserLang === 'es' ? 'es' : 'en';
    const mapLangI18nFile = { es: spanish, en: english };
    this.translate.use(browserLang);
    this.translate.setTranslation(browserLang, mapLangI18nFile[browserLang]);

    this.modalTitle = this.translate.instant('warning_modal_cancel_subscription.CANCEL_WHAT_HAPPEN');
    this.cancelBtn = this.translate.instant('warning_modal_cancel_subscription.CANCEL');
    this.immediatelyBtn = this.translate.instant('warning_modal_cancel_subscription.DELETE_IMMEDIATELY');
    this.canceling = this.translate.instant('warning_modal_cancel_subscription.CANCELING');
    this.endPeriodBtn = this.translate.instant('warning_modal_cancel_subscription.DELETE_END_PERIOD');
    for (let index = 1; index <= five; index++) {
      if (index === two) {
        const msg = this.translate.instant(`warning_modal_cancel_subscription.CANCEL_SUBCRIPTION_MESSAGE${index}`, {date: this.cancelDate});
        this.i18nMessages.push(msg);
      } else {
        this.i18nMessages.push(this.translate.instant(`warning_modal_cancel_subscription.CANCEL_SUBCRIPTION_MESSAGE${index}`));
      }
    }
  }

}
