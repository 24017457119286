import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {NgbModal, NgbModalOptions, NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { ClipboardService } from 'ngx-clipboard';
import { finalize } from 'rxjs/operators';

import { AuthenticationService } from '../../core/security/authentication.service';
import {ModalService} from '../../core/shared/services/modal.service';
import { Deployment, ListDto, SupportToken } from '../../models';
import { AutoUnsubscribeComponent } from './../../shared/auto-unsubscribe/auto-unsubscribe.component';
import { SupportTokenService } from './support-token.service';

import { locale as english } from './i18n/support-token.en';
import { locale as spanish } from './i18n/support-token.es';

@Component({
    selector: 'app-support-token',
    templateUrl: './support-token.component.html',
    styleUrls: ['./support-token.component.scss']
})

export class SupportTokenComponent extends AutoUnsubscribeComponent implements OnInit {

    public supportTokenTitle: string;
    public deploymentsLabel: string;
    public expirationLabel: string;
    public hoursLabel: string;
    public tokenLabel: string;
    public deploymentsPlace: string;
    public tokenNote: string;
    public errorRequired: string;
    public errorHours: string;
    public generatedSuccess: string;
    public supportTokenForm: FormGroup;
    public actionPressed: boolean;
    public actionsButtons: string[];
    public validationType: Object;
    public validationErrors: Object;
    public showGeneratedToken: boolean;
    public deploysSelected: Array<number>;
    public deployments: Array<Deployment>;
    public loadingDeployments: boolean;
    public isCopied: boolean;
    public toolTipCopy: string;

    private organizationId: number;
    private partnerId: number;

    constructor(private translate: TranslateService,  private modalService: NgbModal,
                private formBuilder: FormBuilder, private authenticationService: AuthenticationService,
                private notifierService: NotifierService, private supportTokenService: SupportTokenService,
                private clipboardService: ClipboardService) {
        super();
    }

    public ngOnInit() {
        this.setTranslation();
        this.actionPressed = false;
        this.showGeneratedToken = false;
        this.deployments = [];
        this.deploysSelected = [];
        this.showGeneratedToken = false;
        this.isCopied = false;
        this.loadingDeployments = true;
        this.supportTokenForm = this.formBuilder.group({
            Deployments: [{ value: '', disabled: false }],
            ValidHours: [{ value: '', disabled: false }],
            Token: [{ value: '', disabled: false }],
        });
        this.validationType = {
            'Deployments': [Validators.required],
            'ValidHours': [Validators.required, Validators.pattern(/^(2[0-4]|1[0-9]|[1-9])$/)],
            'Token': []
        };
        this.f['Deployments'].markAsUntouched();
        const currentUserSubs = this.authenticationService.currentUser.subscribe((currenUser: Object) => {
            if (currenUser && currenUser['current'] && currenUser['current'].organizationId) {
                this.organizationId = Number(currenUser['current'].organizationId);
                this.partnerId = Number(currenUser['current'].partnerId);
                this.getDeployments();
            }
        });
        super.addSubscriptions(currentUserSubs);
        this.f['ValidHours'].setValue(1);
    }

    get f() { return this.supportTokenForm.controls; }

    public getDeployments(): void {
        this.loadingDeployments = true;
        const getDeploysSub = this.supportTokenService.getDeployments(this.partnerId).pipe(finalize(() => {
            this.loadingDeployments = false;
        })).subscribe((resp: ListDto<Deployment>) => {
            this.deployments = resp.list.map(deploy => {
                deploy.name = `${deploy.name} (${deploy.code})`;
                return deploy;
            });
        });
        super.addSubscriptions(getDeploysSub);
    }
    public generateSupportToken(): void {
        const supportToken = new SupportToken({ deployments: this.deploysSelected, validHours: this.f.ValidHours.value });
        const generateSupportTokenSubs = this.supportTokenService.generateSupportToken(this.organizationId, supportToken).subscribe(
            (token: { value: string }) => {
                this.notifierService.notify('success', this.generatedSuccess);
                this.showGeneratedToken = true;
                this.actionPressed = false;
                this.f['Token'].setValue(token.value);
                this.setTranslation();
            },
            error => {
                this.validationErrors = error.error.errors;
                Object.keys(this.validationErrors).forEach(prop => {
                    this.supportTokenForm.get(prop).setErrors({ serverError: this.validationErrors[prop] });
                });
                this.actionPressed = false;
            });
        super.addSubscriptions(generateSupportTokenSubs);
    }
    public copyToken(popover: NgbPopover): void {
        if (popover.isOpen()) {
            popover.close();
        }
        this.isCopied = true;
        this.toolTipCopy = this.translate.instant('support_token.COPIED');
        popover.open();
    }
    public changeTooltipText(popover: NgbPopover): void {
        if (popover.isOpen()) {
            popover.close();
        }
        if (this.toolTipCopy !== this.translate.instant('support_token.COPY')) {
            this.toolTipCopy = this.translate.instant('support_token.COPY');
        }
    }
    public updatePlaceholder(): void {
        this.deploymentsPlace = this.deploysSelected.length
            ? `${this.deploymentsLabel} (${this.deploysSelected.length})`
            : this.deploymentsLabel;
    }
    public showSupportTokenModal(content): void {
        this.ngOnInit();
        const ngbModalOptions: NgbModalOptions = { size: 'xl', backdrop: 'static', keyboard: false, centered: true };
        this.modalService.open(content, ngbModalOptions);
    }
    public closeSupportTokenModal(): void {
        if (this.f['Token'].value && !this.isCopied) {
            this.clipboardService.copy(this.f['Token'].value);
        }
        this.supportTokenForm.reset();
        this.actionPressed = false;
        this.modalService.dismissAll();
    }
    public actionConfirmation(confirm: boolean): void {
        if (confirm) {
            const invalid = this.setValidations('');
            this.actionPressed = !invalid;
            if (!invalid) {
                this.generateSupportToken();
            }
        } else {
            this.closeSupportTokenModal();
        }
    }
    public setValidations(input: string): boolean {
        Object.keys(this.f).forEach(key => {
            if (Object.prototype.hasOwnProperty.call(this.f, key)) {
                if (key === input || !input) {
                    this.f[key].setValidators(this.validationType[key]);
                    this.f[key].updateValueAndValidity();
                }
            }
        });
        return this.supportTokenForm.invalid;
    }
    public isEmptyData(): boolean {
        if (!this.f['Deployments'].value || !this.f['ValidHours'].value) {
            return true;
        }
        return false;
    }
    public onTouched(formKey: string): void {
      this.f[formKey].markAsTouched();
    }
    private setTranslation(): void {
        if (this.translate.getBrowserLang() === 'es') {
            this.translate.use('es');
            this.translate.setTranslation('es', spanish);
        } else {
            this.translate.use('en');
            this.translate.setTranslation('en', english);
        }
        this.supportTokenTitle = this.translate.instant('support_token.SUPPORT_TOKEN');
        this.deploymentsLabel = this.translate.instant('support_token.DEPLOYMENTS');
        this.deploymentsPlace = this.translate.instant('support_token.DEPLOYMENTS');
        this.expirationLabel = this.translate.instant('support_token.EXPIRATION');
        this.hoursLabel = this.translate.instant('support_token.HOURS');
        this.tokenLabel = this.translate.instant('support_token.TOKEN_INSTRUCTION');
        this.tokenNote = this.translate.instant('support_token.TOKEN_TEXT_INFO');
        this.toolTipCopy = this.translate.instant('support_token.COPY');
        this.errorRequired = this.translate.instant('support_token.ERROR_REQUIRED');
        this.errorHours = this.translate.instant('support_token.INVALID_HOURS');
        this.generatedSuccess = this.translate.instant('support_token.SUCCESS');
        this.actionsButtons = [this.showGeneratedToken ? this.translate.instant('support_token.CLOSE')
                               : this.translate.instant('support_token.CANCEL'),
                               this.translate.instant('support_token.GENERATE'),
                               this.translate.instant('support_token.GENERATING')];
    }
}
