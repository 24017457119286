import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { from } from 'rxjs';
import { Observable } from 'rxjs/Observable';

import { environment } from 'src/environments/environment';
import { Invitation } from '../models';


@Injectable({ providedIn: 'root' })

export class CompleteAdminInviteService {

  constructor(private http: HttpClient, private angularFireAuth: AngularFireAuth) {
  }

  public getInvitation(token: string): Observable<Invitation> {
    return this.http.get<Invitation>(`${environment.apiUrl}invites/${token}`);
  }

  public completeInvitation(token: string, invitation: Invitation): Observable<Invitation> {
    return this.http.put<Invitation>(`${environment.apiUrl}invites/${token}`, invitation);
  }

  public completeSignUp(email: string, password: string): Observable<auth.UserCredential> {
    return from(this.angularFireAuth.auth.createUserWithEmailAndPassword(email, password));
  }

  public signinWithGoogle(): Observable<auth.UserCredential> {
    return from(this.oAuthProvider(new auth.GoogleAuthProvider()));
  }

  public signinWithMicrosoft(): Observable<auth.UserCredential> {
    return from(this.oAuthProvider(new auth.OAuthProvider('microsoft.com')));
  }

  private oAuthProvider(provider): Promise<auth.UserCredential> {
    return this.angularFireAuth.auth.signInWithPopup(provider);
  }
}
