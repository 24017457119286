import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[appInputFocus]'
})
export class InputFocusDirective implements OnInit {

  constructor(private el: ElementRef) { }
  public ngOnInit() {}
  @HostListener('click')
  public onFocusSetToogle() {
    if (this.el !== undefined && this.el.nativeElement !== undefined && this.el.nativeElement.parentElement !== undefined) {
      this.el.nativeElement.parentElement.className = this.el.nativeElement.parentElement.className + ' ' + 'fg-toggled';
    }
  }
}
