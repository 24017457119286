export class SubscriptionWidget {
  public productName: string;
  public productDescription: number;
  public addons: [];

  constructor(data?: any) {
    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          this[key] = data[key];
        }
      }
    }
  }

}
