import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';


import { AuthenticationService } from '../../security/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private cookieService: CookieService) {

    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentUser = this.authenticationService.currentUserValue;
        const excludeWithCredential = request.headers.get('ExcludeWithCredential');
    
        if (currentUser) {
            let headersConfig = {
                Authorization: `Bearer `,
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
                'Access-Control-Allow-Methods': 'GET,POST,PUT,PATCH,DELETE,OPTIONS',
                'Content-Type': 'application/json;charset=utf-8',
                'responseType': 'blob' as 'json',
            };
    
            // Clonar la solicitud con o sin withCredentials según el identificador
            if (excludeWithCredential) {
                request = request.clone({ setHeaders: headersConfig });
            } else {
                request = request.clone({ setHeaders: headersConfig, withCredentials: true });
            }
        }
    
        return next.handle(request);
    }
    
  
}
