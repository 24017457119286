export class CollectionType {
  public collectionTypeId : number;
  public name: string;
  public code: string;

  constructor(data?: any) {
    this.collectionTypeId = data && data.collectionTypeId ? data.collectionTypeId : 0;
    this.name = data && data.name ? data.name : '';
    this.code = data && data.code ? data.code : '';
  }
}
