import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';

import { validateDuplicateValueControl } from 'src/app/core/shared/pipes/validator/duplicate-value-property-form';
import { ListDto, Region, Zone } from 'src/app/models';
import { AutoUnsubscribeComponent } from '../../auto-unsubscribe/auto-unsubscribe.component';
import { NgroupSoletenantProvisionComponent } from '../../ngroup-soletenant-provision/ngroup-soletenant-provision.component';
import { RegionsListService } from '../../regions/regions-list/regions-list.service';
import { InfrastructureRegionProvision } from '../model';
import * as c from './../const';

import { locale as english } from '../i18n/configure-region-post-provision.en';
import { locale as spanish } from '../i18n/configure-region-post-provision.es';

@Component({
  selector: 'app-configure-region-advanced',
  templateUrl: './configure-region-advanced.component.html',
  styleUrls: ['./configure-region-advanced.component.scss']
})
export class ConfigureRegionAdvancedComponent extends AutoUnsubscribeComponent implements OnInit {

  @Input()
  public deploymentId: number;

  @Input()
  public isStDeployment: boolean;

  public isCollapsed: boolean;
  public isCreating: boolean;
  public regionForm: FormGroup;
  public validationType: Object;
  public translateObject: Object;
  public region: Region;
  public loadingZones : boolean;
  public listZones: Array<Zone>;
  public isSoletenantDeployment: boolean;

  @ViewChild(NgroupSoletenantProvisionComponent, {static: false})
  public soletenantNodeComponent: NgroupSoletenantProvisionComponent;


  constructor( private translate: TranslateService, private formBuilder: FormBuilder,
               private regionService: RegionsListService) {
                 super();
  }

  public ngOnInit() {
    this.isCollapsed = false;
    this.setTranslations();
    this.region = null;
    this.isCreating = true;
    this.regionForm = this.formBuilder.group({
      Zone1: [{ value: '', disabled: !this.isCreating }],
      Zone2: [{ value: '', disabled: !this.isCreating}],
      ActiveZones: [{ value: false, disabled: true }],
      ActiveSoletenant: [{ value: false, disabled: true }],
    });

    this.validationType = {
      'Zone1':  [Validators.required,  validateDuplicateValueControl(['Zone1', 'Zone2'], 'zoneId') ],
      'Zone2': [Validators.required,  validateDuplicateValueControl(['Zone1', 'Zone2'], 'zoneId') ]
    };
  }

  public setRegionSelected(region: Region): void {
    this.region = region;
    if ( this.region && this.region.regionId > 0) {
      this.enableOptions();
    }
  }

  public loadSoletenantGroups(): void {
     this.soletenantNodeComponent.region = this.region;
     this.soletenantNodeComponent.getSoltenantGroupByRegion();
  }

  public openSettings(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  get f() { return this.regionForm.controls; }

  public setValidations(input: string): void {
    for (const key in this.f) {
      if (key === input) {
        this.f[key].setValidators(this.validationType[key]);
        this.f[key].updateValueAndValidity();
        break;
      } else if (!input) {
        this.f[key].setValidators(this.validationType[key]);
        this.f[key].updateValueAndValidity();
      }
    }
  }

  public validateZones(): void {
    this.setValidations('Zone1');
    this.setValidations('Zone2');
  }

  public getZonesByRegion(): void {
    this.loadingZones = true;
    this.resetZones();
    const getRegionsSub = this.regionService.getZones(this.region.regionId)
    .pipe(finalize(() => {
      this.loadingZones = false;
    })).subscribe(
      (zones: ListDto<Zone>) => {
          this.listZones = zones.list;
          if ( this.listZones.length >= c.two ) {
            this.regionForm.get('Zone1').setValue(this.listZones[0]);
            this.regionForm.get('Zone2').setValue(this.listZones[1]);
          }
    });
    super.addSubscriptions(getRegionsSub);
  }

  public setActiveZones($event: boolean) : void {
     this.regionForm.get('ActiveZones').setValue($event);
     if ($event) {
      this.getZonesByRegion();
    }
  }

  public setActiveSoletenant($event: boolean) : void {
    this.regionForm.get('ActiveSoletenant').setValue($event);
    this.soletenantNodeComponent.isSoletenantNotData = false;
    if ($event) {
      this.loadSoletenantGroups();
    }
 }

  public isValidComponent(): boolean {
    const activeZones =  this.regionForm.get('ActiveZones').value;
    const activeSoletenant =  this.regionForm.get('ActiveSoletenant').value;
    let validSt = true;
    if (activeZones) {
        this.validateZones();
    }
    if (activeSoletenant) {
      validSt = this.soletenantNodeComponent.isValidComponent();
    }
    const valid = this.regionForm.valid && validSt;
    if (!valid) {
      this.isCollapsed = false;
    }
    return valid;
 }

  public fillInfrastructureRegionModel(model: InfrastructureRegionProvision): void {
    const activeZones =  this.regionForm.get('ActiveZones').value;
    const activeSoletenant =  this.regionForm.get('ActiveSoletenant').value;
    if (activeZones) {
      const z1 = this.regionForm.get('Zone1').value;
      const z2 = this.regionForm.get('Zone2').value;
      if (z1) {
        model.primaryZone = z1['zoneId'];
      }
      if (z2) {
        model.secondaryZone = z2['zoneId'];
      }
    }
    if (activeSoletenant) {
      const st = this.soletenantNodeComponent.region.soleTenantGroup;
      if (st) {
        model.soleTenantGroup = st;
      }
    }
  }

  private enableOptions(): void {
    const controls = ['ActiveZones', 'ActiveSoletenant'];
    controls.forEach(element => {
      this.regionForm.get(element).setValue(false);
      this.regionForm.get(element).enable();
    });
 }

  private resetZones(): void {
    const controls = ['Zone1', 'Zone2'];
    controls.forEach(element => {
      this.regionForm.get(element).setValue('');
      this.regionForm.get(element).setErrors(null);
    });
  }

  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }

    this.translateObject = {
        'ZonesLabels': this.translate.instant('configure_region.ZONES_LABELS'),
        'ZonesDescription': this.translate.instant('configure_region.ZONES_DESCRIPTION'),
        'Zone1': this.translate.instant('configure_region.ZONE1_LABEL'),
        'Zone2': this.translate.instant('configure_region.ZONE2_LABEL'),
        'ErrorRequired': this.translate.instant('configure_region.ERROR_REQUIRED'),
        'ErrorPattern': this.translate.instant('configure_region.ERROR_PATTERN'),
        'SoletenantLabel': this.translate.instant('configure_region.SOLETENANT_LABEL'),
        'SoletenantDescription': this.translate.instant('configure_region.SOLETENANT_DESCRIPTION'),
        'AdvancedSettings':  this.translate.instant('configure_region.ADVANCED_SETINGS'),
        'InvalidZone': this.translate.instant('configure_region.INVALID_ZONE')
    };
  }
}
