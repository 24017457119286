export const locale = {
  lang: 'es',
  reset_user_password : {
    'RESET': 'Resetear contraseña',
    'NEW_PASS': 'Nueva contraseña',
    'CANCEL': 'Cancelar',
    'SAVE': 'Guardar',
    'SAVING': 'Guardando ...',
    'RESET_SUCCESS': 'Contraseña reseteada satisfactoriamente',
    'RESET_TOOLTIP': 'Requisitos para la complejidad de la contraseña',
    'ERROR_REQUIRED': 'Campo requerido',
    'CLOSE': 'Cerrar',
    'RESET_BTN': 'Resetear',
    'RESETTING': 'Reseteando ...',
    'SEND_LINK_TITLE': 'Enviar un enlace de restablecimiento de contraseña',
    'SEND_LINK_SUBTITLE': 'Los usuarios seleccionados recibirán un correo electrónico con instrucciones para establecer su propia contraseña en el Portal Cloud VDI',
    'MANUAL_RESET_TITLE': 'Establecer manualmente una contraseña',
    'MANUAL_RESET_EMAIL': 'Envíe por correo electrónico a los usuarios su nueva contraseña',
    'EXPIRE_CURRENT_PASSWORD': 'Expirar la contraseña actual',
    'EXPIRE_CURRENT_PASSWORD_HELP': `Si esta opción está habilitada, el indicador
      "El usuario debe establecer la contraseña en el próximo inicio de sesión"
      será activado en el Directorio Activo y el usuario no podrá iniciar sesión hasta que establezca una nueva contraseña.`,
    'NO_EMAIL_WARNING': `Este usuario no tiene una dirección de correo electrónico en WorkAnywhere.
      Todavía puede configurar manualmente su contraseña.`,
    'MULTIPLE_USERS_SELECTED': '{{ amount }} usuarios seleccionados',
    'MULTIPLE_USERS_WITH_NO_EMAIL': '{{ amount }} usuarios no tienen una dirección de correo electrónico en WorkAnywhere.',
    'MULTIPLE_USERS_WITH_NO_EMAIL_ALL': `Estos usuarios no tienen una dirección de correo electrónico en WorkAnywhere.
      Todavía puede configurar manualmente su contraseña.`,
    'MULTIPLE_USERS_FROM_TRUSTED': `{{ amount }} usuarios pertenecen a un dominio de confianza
      y no pueden restablecer su contraseña.`,
    'MULTIPLE_USERS_FROM_TRUSTED_ALL': 'Estos usuarios pertenecen a un dominio de confianza y no pueden restablecer su contraseña.',
    'MULTIPLE_USERS_SKIP_RESET': 'Omita estos usuarios y restablezca los usuarios restantes',
    'MULTIPLE_USERS_RESET_NO_EMAIL': 'Restablezca a estos usuarios pero no les envíe un correo electrónico.',
    'ONE_USER_WITH_NO_EMAIL': '1 usuario no tiene una dirección de correo electrónico en WorkAnywhere.',
    'ONE_USER_FROM_TRUSTED': '1 usuario pertenece a un dominio de confianza y no puede restablecer su contraseña.',
    'NO_USER_TO_RESET': 'No hay usuarios para restablecer su contraseña.'
  }
};
