/**
 * An object used to get page information from the server
 */
export class Page {
  // The number of elements in the page
  public size = 0;
  // The total number of elements
  public totalElements = 0;
  // The total number of pages
  public totalPages = 0;
  // The current page number
  public pageNumber = 0;

  constructor(data?: any) {
    this.size = data && data.size ? data.size : 0;
    this.totalElements = data && data.totalElements ? data.totalElements : 0;
    this.totalPages = data && data.totalPages ? data.totalPages : 0;
    this.pageNumber = data && data.pageNumber ? data.pageNumber : 0;
  }
}
