export const locale = {
  lang: 'en',
  'actions' : {
      'create': {
        'PROCESS': 'Creating',
        'BD': 'created',
      },
      'edit': {
        'PROCESS': 'Editing',
        'BD': 'edited',
      },
      'delete': {
        'PROCESS': 'Deleting',
        'BD': 'deleted',
      },
      'enable': {
        'PROCESS': 'Enabling',
        'BD': 'enabled',
      },
      'disable': {
        'PROCESS': 'Disabling',
        'BD': 'disabled',
      },
      'reset': {
        'PROCESS': 'Resetting',
        'BD': 'reseted',
      },
      'logoff': {
        'PROCESS': 'Loggin off',
        'BD': 'logged off',
      },
      'unlock': {
        'PROCESS': 'Unlocking',
        'BD': 'unlocked',
      },
      'import': {
        'PROCESS': 'Importing',
        'BD': 'imported',
      },
      'move': {
        'PROCESS': 'Moving',
        'BD': 'moved',
      },
      'start' : {
        'PROCESS': 'Starting',
        'BD': 'started',
      },
      'restart' : {
        'PROCESS': 'Re-starting',
        'BD': 're-started',
      },
      'stop' : {
        'PROCESS': 'Stopping',
        'BD': 'stopped',
      },
      'redeploy' : {
        'PROCESS': 'Re-deploying',
        'BD': 're-deployed',
      },
      'connect' : {
        'PROCESS': 'Connecting',
        'BD': 'connected',
      },
      'clone': {
        'PROCESS': 'Cloning',
        'BD': 'cloned',
      },
      'download': {
        'PROCESS': 'Downloading',
        'BD': 'downloaded',
      },
      'upload': {
        'PROCESS': 'Uploading',
        'BD': 'uploaded',
      },
      'restore': {
        'PROCESS': 'Restoring',
        'BD': 'restored',
      },
      'send': {
        'PROCESS': 'Sending',
        'BD': 'sent',
      },
    },
  'resources': {
      'USER': 'user',
      'USER_MULTI': 'users',
      'COLLECTION': 'collection',
      'PASSWORD': 'password for user',
      'PASSWORD_LINK': 'password link for user',
      'PASSWORD_MULTI': 'passwords for users',
      'PASSWORD_LINK_MULTI': 'password links for users',
      'FILE': 'from file',
      'AD': 'from active directory',
      'IMAGE' : 'image',
      'IMAGE_MULTI' : 'images',
      'SNAPSHOT_PLAN' : 'snapshot plan',
      'SNAPSHOT' : 'snapshot',
      'UPTIME' : 'uptime',
      'UPTIME_MULTI' : 'uptimes',
      'VPN': 'vpn',
      'VPN_MULTI': 'vpns',
      'FOLDER' : 'folder',
      'FOLDER_PERMISSION' : 'folder permission',
      'FOLDER_PERMISSION_MULTI' : 'folders permission',
      'MAPPED_DRIVE' : 'mapped drive',
      'SHARED' : 'shared',
      'SECURITYGROUPS' : ' security group',
      'SECURITYGROUPS_MULTI' : ' security groups',
      'ADMIN': 'administrator',
      'APP' : 'app',
      'APPSETTING' : 'app setting',
      'LICENSE' : 'license',
      'ROLE' : 'role',
      'INFRAESTRUCTURE' : 'infrastructure server',
      'INFRAESTRUCTURE_MULTI' : 'infrastructure servers',
      'INSTANCE' : 'instance',
      'INSTANCE_MULTI' : 'instances',
      'OSPATCH' : 'SO patching',
      'REGION': 'Region'
    }
};
