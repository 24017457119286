export class Suffix {
   public suffixId: number;
   public deploymentId : number;
   public name : string;
   public default: boolean;
   public delete : boolean;
   constructor(data?: any) {
    this.suffixId =  data && data.suffixId ? data.suffixId : 0;
    this.deploymentId =  data &&  data.deploymentId ? data.deploymentId : 0;
    this.name =  data &&  data.name ? data.name : '';
    this.default =  data &&  data.default ? data.default : false;
    this.delete =  false;
    }
}
