import { Restrictions } from '../common';

export class MachineType {
    public machineTypeId: number;
    public name: string;
    public displayName: string;
    public description: string;
    public family: string;
    public cpu: string;
    public ram: string;
    public restrictions: Restrictions;
    public gpuSupport: boolean;
    constructor(data ? : any) {
        this.machineTypeId = data && data.machineTypeId ? data.machineTypeId : 0;
        this.name = data && data.name ? data.name : '';
        this.displayName = data && data.displayName ? data.displayName : data && data.name ? data.name : '';
        this.description = data && data.description ? data.description : '';
        this.cpu = data && data.cpu ? data.cpu : '';
        this.ram = data && data.ram ? data.ram : '';
        this.restrictions = data && data.restrictions ? data.restrictions : null;
        this.gpuSupport = data && data.gpuSupport ? data.gpuSupport : false;
    }
}
