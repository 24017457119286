import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import {TranslateModule} from '@ngx-translate/core';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { UiSwitchModule } from 'ngx-toggle-switch';

import { CapitalizePipe } from '../core/shared/pipes/shared-pipes/capitalize.pipe';
import { ManageResourceInTableModule } from '../shared/manage-resource-in-table/manage-resource-in-table.module';
import { ModalLayoutModule } from '../shared/modal-layout/modal-layout.module';
import { RegionsPostProvisionModule } from '../shared/regions-post-provision/regions-post-provision.module';
import { ResetPasswordComponent } from '../shared/reset-password/reset-password.component';
import { ResetUserPasswordComponent } from '../shared/reset-user-password/reset-user-password.component';
import { ResetUserPasswordModule } from '../shared/reset-user-password/reset-user-password.module';
import {SectionLayoutModule} from '../shared/section-layout/section-layout.module';
import { SpinnerModule } from '../shared/spinner/spinner.module';
import { DirectivesModule } from './../core/shared/directives/directives.module';
import { AutoUnsubscribeModule } from './../shared/auto-unsubscribe/auto-unsubscribe.module';
import { DatatableModule } from './../shared/datatable/datatable.module';
import { FormButtonsModule } from './../shared/form-buttons/form-buttons.module';
import { ListViewLayoutModule } from './../shared/list-view-layout/list-view-layout.module';
import { ResetPasswordModule } from './../shared/reset-password/reset-password.module';
import { ViewsLayoutModule } from './../shared/views-layout/views-layout.module';
import { DeploymentIdsListComponent } from './deployment-ids-list/deployment-ids-list.component';
import { DeploymentsDeleteComponent } from './deployments-delete/deployments-delete.component';
import { DeploymentsListComponent } from './deployments-list/deployments-list.component';
import { DeploymentsMenuComponent } from './deployments-menu/deployments-menu.component';

@NgModule({
    entryComponents: [ResetPasswordComponent, DeploymentsDeleteComponent, ResetUserPasswordComponent],
    imports: [CommonModule, TranslateModule, DatatableModule, NgbModule, RegionsPostProvisionModule,
              ReactiveFormsModule, FormsModule, FormButtonsModule, AutoUnsubscribeModule, ResetPasswordModule, UiSwitchModule,
              InternationalPhoneNumberModule, SpinnerModule, ManageResourceInTableModule, NgSelectModule, DirectivesModule,
              ViewsLayoutModule, SectionLayoutModule, ModalLayoutModule, ListViewLayoutModule, ResetUserPasswordModule],
    declarations: [DeploymentsMenuComponent, DeploymentsListComponent, DeploymentsDeleteComponent, DeploymentIdsListComponent ],
    exports: [DeploymentsMenuComponent, DeploymentsListComponent, DeploymentsDeleteComponent, DeploymentIdsListComponent ],
    providers: [CapitalizePipe]
})
export class DeploymentsModule {
}
