import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'underscore';

import { urlExcludeInterceptor } from '../const';
import { HttpCancelService } from './../services/http-cancel.service';


@Injectable({providedIn: 'root'})
export class HttpCancelInterceptor implements HttpInterceptor {
  constructor(private httpCancelService: HttpCancelService) { }

  public intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    const element = _.find(urlExcludeInterceptor, (item) => req.url.includes(item) );
    if (!element) {
      return next.handle(req).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()));
    } else {
      return next.handle(req).pipe();
    }
  }
}
