import { RegionStatusEnum } from 'src/app/shared/regions-post-provision/enum';
import { SoltenantGroup, Subnet } from '..';
import { ListDto } from '../common';

export class Region {
    public regionName: string;
    public name: string;
    public description: string;
    public regionDesktopProvisionId: number;
    public regionId: number;
    public scaleId: number;
    public override: any;
    public active: boolean;
    public subnet: string;
    public subnetName: string;
    public subnets: any;
    public gateway: string;
    public primary: boolean;
    public primaryZone: any;
    public secondaryZone: any;
    public regionDescription: string;
    public isSaved: boolean;
    public dnsRecord: string;
    public ipRecord: string;
    public latitude: number;
    public longitude: number;
    public soleTenantGroup: SoltenantGroup;
    public hasCertificate: boolean;
    public countZones: number;
    public externalAccessEndpoint: string;
    public status: RegionStatusEnum;
    public sizingRatio: number;
    public isPersist: boolean;
    // tslint:disable-next-line:cyclomatic-complexity
    constructor(data ? : any) {
        this.regionDesktopProvisionId = data && data.regionDesktopProvisionId ? data.regionDesktopProvisionId : 0;
        this.regionId = data && data.regionId ? data.regionId : 0;
        this.scaleId = data && data.scaleId ? data.scaleId : 0;
        this.override = data && data.override ? data.override : null;
        this.active = data && data.active ? data.active : true;
        this.regionName = data && data.regionName ? data.regionName : '';
        this.name = data && data.name ? data.name : '';
        this.description = data && data.description ? data.description : '';
        this.subnet = data && data.subnet ? data.subnet : '';
        this.subnets = data && data.subnets ? data.subnets : undefined;
        this.subnetName = data && data.subnetName ? data.subnetName : undefined;
        this.gateway = data && data.gateway ? data.gateway : '';
        this.primary = data && data.primary ? data.primary : false;
        this.isSaved = data && data.isSaved ? data.isSaved : undefined;
        this.primaryZone = data && data.primaryZone ? data.primaryZone : null;
        this.secondaryZone = data && data.secondaryZone ? data.secondaryZone : null;
        this.regionDescription = data && data.regionDescription ? data.regionDescription : null;
        this.dnsRecord = data && data.externalAccessEndpoint ? data.externalAccessEndpoint : '';
        this.ipRecord = data && data.ipRecord ? data.ipRecord : null;
        this.latitude = data && (data.latitude || Number(data.latitude) === 0) ? data.latitude : null;
        this.longitude = data && (data.longitude || Number(data.longitude) === 0) ? data.longitude : null;
        this.regionName = this.regionName === '' && this.name !== '' ? this.name : this.regionName;
        this.soleTenantGroup = data && data.soleTenantGroup ? data.soleTenantGroup : null;
        this.hasCertificate = data && data.hasCertificate || false;
        this.countZones = data && data.countZones || 0;
        this.externalAccessEndpoint = data && data.externalAccessEndpoint || null;
        this.status = data && data.status ? data.status : RegionStatusEnum.PendingConfiguration;
        this.sizingRatio = data && data.sizingRatio ? data.sizingRatio : null;
        this.isPersist = data && data.isPersist ? true : false;
    }
}
