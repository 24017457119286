export const locale = {
  lang: 'es',
  feedback: {
    'FEEDBACK': `Retroalimentación`,
    'PLACEHOLDER': 'Describa su problema o comparta sus ideas',
    'SEND': 'Enviar',
    'SENDING': 'Enviando...',
    'CANCEL': 'Cancelar',
    'SUCCESS': 'Retroalimentación enviada correctamente',
    'ERROR_REQUIRED': 'Campo requerido',
  }
};
