export const locale = {
  lang: 'en',
  reset_password : {
    'RESET': 'Reset password',
    'NEW_PASS': 'New password',
    'CANCEL': 'Cancel',
    'SAVE': 'Save',
    'SAVING': 'Saving ...',
    'RESET_SUCCESS': 'Password reset successfully',
    'RESET_TOOLTIP': 'Password complexity requirements',
    'ERROR_REQUIRED': 'This field is required',
    'CLOSE': 'Close'
  }
};
