import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

import { environment } from 'src/environments/environment';

declare const gapi: any;
const url = 'https://www.googleapis.com/dns/v1/projects/';
const urlServiceActivate = 'servicemanagement.googleapis.com/v1/services/dns.googleapis.com:enable';

@Injectable({
  providedIn: 'root'
})
export class GdnsService {
  public service = 'dns.googleapis.com';
  constructor() { }

  public loadGDns(): void {
    try {
      return gapi.client.load('dns', environment.gapi.apiVersion);
    } catch (exception) {
      throwError(exception);
      return;
    }
  }
  public getDns (projectId) {
    const request = gapi.client.request({
        path: url + projectId,
        method: 'GET'
    });
    return request.then(function (response) {
      return Promise.resolve(response.result);
    }).catch(function (reason) {
        reason.request = {
            name: 'gapi.client.dns',
        };
        return Promise.reject(reason);
    });
  }
  public hasEnabled(projectId) {
    return this.getDns(projectId)
        .then(function(b) {
            return b;
        }).catch(function (reason) {
          return Promise.reject(reason);
        });
  }
  public activate(consumer) {
    const params = {
        serviceName: this.service,
        consumerId: consumer
    };
    const request = gapi.client.servicemanagement.services.enable(params);
    return request.then(function (response) {
        return Promise.resolve(response.result);
    }).catch(function (reason) {
        reason.request = {
            name: urlServiceActivate,
            params: params
        };
        return Promise.reject(reason);
    });
}
}
