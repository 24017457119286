export class SecurityRole {
    public roleSecurityId: number;
    public organizationId: number;
    public name: string;
    public description: string;
    public permissionsIds: Array<string>;
    public type: string;
    constructor(data?: any) {
      this.roleSecurityId = data && data.roleSecurityId ? data.roleSecurityId  : 0;
      this.organizationId = data && data.organizationId ? data.organizationId  : null;
      this.name = data && data.name ? data.name : '';
      this.description = data && data.description ? data.description : '';
      this.permissionsIds = data && data.permissionsIds ? data.permissionsIds : [];
      this.type = data && data.type ? data.type : '';
    }
}
