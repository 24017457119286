export class SoftwareTemplate {
    public name: string;
    public description: string;
    public domain: boolean;
    public isWindows: boolean;
    public family: string;
    public diskSize: number;
    public deployment: boolean;
    public section: string;

    constructor(data ? : any) {
        this.name = data && data.name ? data.name : '';
        this.description = data && data.description ? data.description : '';
        this.domain = data && data.domain ? data.domain : false;
        this.isWindows = data && data.isWindows ? data.gateway : false;
        this.family = data && data.family ? data.family : '';
        this.diskSize = data && data.diskSize ? data.diskSize : 0;
        this.deployment = data && data.deployment ? data.deployment : false;
    }
}
