import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

const textNode = 3;
@Directive({
  selector: '[appUptimeStatus]'
})
export class UptimeStatusDirective implements OnInit, OnChanges {

  @Input()
  public uptimeEnabled: boolean;
  @Input()
  public displayStatus: string;

  constructor(private el: ElementRef, private renderer: Renderer2, @Inject(DOCUMENT) private document) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if  (changes.uptimeEnabled) {
        this.uptimeEnabled = changes.uptimeEnabled.currentValue;
      }
      if  (changes.displayStatus) {
        this.displayStatus = changes.displayStatus.currentValue;
      }
      this.getUptimeStatus(this.uptimeEnabled);
    }
  }
  public ngOnInit() {
    this.getUptimeStatus(this.uptimeEnabled);
  }
  private getUptimeStatus(uptimeEnabled: boolean) {
    const child = document.createElement('span');
    const childElements = this.el.nativeElement.childNodes;
    for (const children of childElements) {
        if (children.nodeType !== textNode && children.id !== 'copy') {
          this.renderer.removeChild(this.el.nativeElement, children);
        }
      }
    if (uptimeEnabled) {
      child.className = 'fas fa-circle text-success m-r-2';
    } else {
      child.className = 'fas fa-circle c-gray m-r-2';
    }
    child.setAttribute('title', this.displayStatus);
    this.renderer.insertBefore(this.el.nativeElement, child, this.el.nativeElement.firstChild);
  }
}
