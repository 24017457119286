export class RadioGroupType {
  public title: string;
  public help: string;
  public value: string;
  public disabled: boolean;
  public label: string;
  public feature: string;
  public subTitle: string;
  public visible: boolean;
  public trustedDomainId: number | null;
  constructor(data? : any) {
    this.title = data && data.title ? data.title : '';
    this.trustedDomainId = data && data.trustedDomainId ? data.trustedDomainId : null;
    this.help = data && data.help ? data.help : '';
    this.label = data && data.label ? data.label : '';
    this.feature = data && data.feature ? data.feature : '';
    this.subTitle = data && data.subTitle ? data.subTitle : '';
    this.value = data && data.value ? data.value : undefined;
    this.disabled = data && data.disabled ? data.disabled : false;
    this.visible = data && data.visible ?  true : false;
  }
}
