import { ImageFamily } from 'src/app/models/cloud-manager/image-family';

export class Image {
  public imageId: number;
  public name: string;
  public description: string;
  public canClone: boolean;
  public domain: boolean;
  public image: string;
  public diskSize: number;
  public diskType: string;
  public instanceId: number;
  public instanceName: string;
  public instanceType: string;
  public instanceTypeDescription: string;
  public externalIp : string;
  public status: string;
  public family: ImageFamily;
  public creationDate: string;
  public statusId: number;
  public instanceStatusId: number;
  public failedProcess: string;
  public imageIsValid: boolean;

  // tslint:disable-next-line: cyclomatic-complexity
  constructor(data? : any) {
    this.imageId = data && data.imageId ? data.imageId : 0;
    this.name = data && data.name ? data.name : '';
    this.description = data && data.description ? data.description : '';
    this.canClone = data && data.canClone ? true : false;
    this.domain = data && data.domain ? data.domain : false;
    this.image = data && data.image ? data.image : '';
    this.diskSize = data && data.diskSize ? data.diskSize : 0;
    this.diskType = data && data.diskType ? data.diskType : '';
    this.instanceId = data && data.instanceId ? data.instanceId : '';
    this.instanceName = data && data.instanceName ? data.instanceName : '';
    this.instanceType = data && data.instanceType ? data.instanceType : '';
    this.instanceTypeDescription = data && data.instanceTypeDescription ? data.instanceTypeDescription : '';
    this.externalIp = data && data.externalIp ? data.externalIp : '';
    this.status = data.status || '';
    this.statusId = data.statusId || 0;
    this.instanceStatusId = data.instanceStatusId || 0;
    this.family = data.family ? new ImageFamily(data.family) : null;
    this.creationDate = data && data.creationDate ? data.creationDate : '';
    this.failedProcess = data && data.failedProcess ? data.failedProcess : '';
    this.imageIsValid = data && data.imageIsValid !== null && data.imageIsValid !== undefined ? data.imageIsValid : true;
  }

}

