export class AutoScaleSchedule {
  public scaleId: number;
  public collectionAlias: string;
  public regionDesktopProvisionId: number;
  public regionId: number;
  public regionName: string;
  public regionDescription: string;
  public schedule: string;
  public regions: string;

  constructor(data?: any) {
    this.scaleId = data && data.scaleId ? data.scaleId : 0;
    this.collectionAlias = data && data.collectionAlias ? data.collectionAlias : '';
    this.regionDesktopProvisionId = data && data.regionDesktopProvisionId ? data.regionDesktopProvisionId : 0;
    this.regionId = data && data.regionId ? data.regionId : 0;
    this.regionName = data && data.regionName ? data.regionName : '';
    this.regionDescription = data && data.regionDescription ? data.regionDescription : '';
    this.schedule = data && data.schedule ? data.schedule : '';
    this.regions = data && data.regions ? data.regions : '';
  }
}
