import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';

import { FileBase } from 'src/app/models';
import { AutoUnsubscribeComponent } from 'src/app/shared/auto-unsubscribe/auto-unsubscribe.component';

import { locale as english } from './i18n/img-upload.en';
import { locale as spanish } from './i18n/img-upload.es';

const thousand = 1000;
const maxKBytes = 102400;
@Component({
    selector: 'app-img-upload',
    templateUrl: './img-upload.component.html',
    styleUrls: ['./img-upload.component.scss']
})
export class ImageUploadComponent extends AutoUnsubscribeComponent implements OnInit {
    @Input()
    public title: string;
    @Input()
    public subTitle: string;
    @Input()
    public image: FileBase;
    @Input()
    public imgLabel: string;
    @Input()
    public isLogo: boolean;

    @Output()
    public setImage = new EventEmitter<FileBase>();
    @Output()
    public validateImage = new EventEmitter<boolean>();

    public hasError: boolean;
    public serverError: string;
    public file: File;
    public imagePreview: string;
    public showPreview: boolean;
    public imageUploadForm: FormGroup;
    public idFileInput: string;
    public uploadText: string;
    public removeText: string;

    constructor(private formBuilder: FormBuilder, private translate: TranslateService) {
        super();
        this.setTranslations();
    }

    public ngOnInit() {
        setTimeout(() => {
            this.setTranslations();
        });
        this.idFileInput = this.generateId();
        this.imageUploadForm = this.formBuilder.group({
            Image: [{ value: '', disabled: false }]
        });
        this.imagePreview = this.getImageData();
    }

    get f() { return this.imageUploadForm.controls; }

    public getImage($event): void {
        try {
            if ($event.target.files && $event.target.files.length) {
                this.file = $event.target.files[0];
                if (this.file) {
                    if (this.isValidImage()) {
                        this.hasError = false;
                        const reader = new FileReader();
                        reader.onload = ((file) => {
                            return (evt) => {
                                this.imagePreview = evt.target.result;
                                this.showPreview = true;
                                this.setImageData(file, evt.target.result);
                            };
                        })(this.file);
                        reader.readAsDataURL(this.file);
                    }
                }
            }
        } catch (ex) {
            console.error(ex);
        }
    }
    public removeImage(): void {
        this.imagePreview = '';
        this.showPreview = false;
        this.file = null;
        this.hasError = false;
        this.serverError = '';
        this.setImage.emit(null);
    }

    private getImageData(): string {
        this.showPreview = false;
        if (this.image && !_.isEmpty(this.image.fileData)) {
            this.showPreview = true;
            const byteString = atob(this.image.fileData);
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);

            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            const blob = new Blob([ab], { type: this.image.mimeType });
            this.file = new File([blob], this.image.fileName, { type: this.image.mimeType });
            this.f.Image.setValue(this.image.fileName);
            return `data:${this.image.mimeType};base64,${this.image.fileData}`;
        }
        return null;
    }
    private setImageData(file: File, data: string): void {
        const base64 = data ? data.split(',') : '';
        if (base64.length > 1) {
            const fileImage = {
                fileName: file.name,
                mimeType: file.type,
                fileData: base64[1]
            };
            this.setImage.emit(fileImage);
        }
    }
    private isValidImage(): boolean {
        let isValid = true;
        this.setTranslations();

        if (!this.file.size || !this.file.type) {
            isValid = this.setError(this.translate.instant('img_upload.INVALID_EXTENSION'));
        } else {
            if (!this.file.type.match('image.*')) {
                isValid = this.setError(this.translate.instant('img_upload.INVALID_EXTENSION'));
            } else if (this.file.size > maxKBytes) {
                isValid = this.setError(this.translate.instant('img_upload.INVALID_SIZE'));
            }
        }
        this.validateImage.emit(isValid);
        if (!isValid) {
            this.file = null;
            this.imagePreview = 'assets/images/no-preview.png';
            this.showPreview = true;
        }
        return isValid;
    }
    private setError(errorText: string): boolean {
        this.hasError = true;
        this.serverError = errorText;
        this.setImage.emit(null);
        return false;
    }
    private generateId(): string {
        return `id${Math.round(Math.random() * thousand)}`;
    }
    private setTranslations(): void {
        if (this.translate.getBrowserLang() === 'es') {
            this.translate.use('es');
            this.translate.setTranslation('es', spanish);
        } else {
            this.translate.use('en');
            this.translate.setTranslation('en', english);
        }
        this.uploadText = this.translate.instant('img_upload.UPLOAD_BTN');
        this.removeText = this.translate.instant('img_upload.REMOVE_BTN');
    }
}
