export const locale = {
  lang: 'en',
  'manage_resource_in_table' : {
    ERROR_REQUIRED: 'This field is required',
    ERROR_EMAIL: 'This field should be an e-mail address in the format "user@example.com"',
    ERROR_ONLY_NUMBER: 'You must enter just numbers',
    ERROR_PATTERN: 'The value in this field is invalid',
    ERROR_DUPLICATED: 'Duplicated resource',
    MINLENGTH: 'Minimum length not reached',
    MAXLENGTH: 'Maximum length exceded',
    EDIT: 'Edit',
    RETRY: 'Retry',
    DELETE: 'Delete',
    ADD: 'Add',
    SAVE: 'Save',
    CANCEL: 'Cancel',
    ERROR_MINSIZE: 'Minimum size {{val}}',
    ERROR_MAXSIZE: 'Maximum size {{val}}',
    PROPERTY_IN_USE: 'The key is already in use'
  }
};
