export enum SubscriptionComponentName {
  USER = 'USER',
  SERVER = 'SERVER',
  INTEGRATION = 'INTEGRATION',
  ADMIN = 'ADMIN',
  DCO = 'DCO',
  SNA = 'SNA',
  SUP = 'SUP',
  ALO = 'ALO',
  DIS = 'DIS',
  NDI = 'NDI',
  RAP = 'RAP',
  MUS = 'MUS',
  RDS = 'RDS',
  ATA = 'ATA',
  DMT = 'DMT',
  UIN = 'UIN',
  SRT = 'SRT',
  ASC = 'ASC',
  DVU = 'DVU',
  VPN = 'VPN',
  GAA = 'GAA',
  RDC = 'RDC',
  BRK = 'BRK',
  RGTW = 'RGTW',
  ADRDS = 'ADRDS',
  VDO = 'VDO',
  CSM = 'CSM',
  OPC = 'OPC',
  AAC = 'AAC',
  INS = 'INS',
  PSUP = 'PSUP',
  MOB = 'MOB',
  UMHD = 'UMHD',
  GSSO = 'GSSO',
  NOD = 'NOD',
  GWD = 'GWD',
  GBUI = 'GBUI',
  EADD = 'EADD',
  MRD = 'MRD',
  SEAT = 'SEAT'
}
