export class Tab {
  public name: string;
  public url: string;
  public isEarlyAccess: boolean;
  constructor(data) {
    this.name = data.name;
    this.url = data.url;
    this.isEarlyAccess = data.isEarlyAccess || false;
  }
}
