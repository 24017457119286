import { Pipe, PipeTransform } from '@angular/core';
import * as timezone from 'moment-timezone';
import * as _ from 'underscore';

import { TimeZoneModel } from 'src/app/models';

@Pipe({
  name: 'momentTimezoneList'
})
export class MomentTimezoneListPipe implements PipeTransform {


 public transform(): Array<TimeZoneModel> {
    const moments =  timezone.tz.names();
    let listTimeZone = [];
    _.each(moments, (element: any) => {
            const obj = new TimeZoneModel();
            obj.tz = element;
            const offset = timezone.tz(Date.now(), element).format('Z z');
            obj.offset = offset;
            const splitNames = element.split('/');
            if (splitNames.length === 1) {
            obj.group = '';
            obj.name = splitNames[0];
          } else {
            obj.group = splitNames[0];
            obj.name = this.getGenericNameTimeZone(splitNames);
          }
            obj.display =  obj.name + ' ' + '(' + obj.offset + ')';
            listTimeZone.push(obj);
        });
        return listTimeZone;
    }

    public getGenericNameTimeZone(arrayName: Array<string>): string {
        let name = '';
        for (let index = 1; index < arrayName.length; index++) {
        name = index === 1 ? arrayName[index] : name +'/' + arrayName[index];
        }
        return name;
    }
 }

