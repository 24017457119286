import {CdkTableModule} from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatBadgeModule,
  MatButtonModule,
  MatCheckboxModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatSidenavModule,
  MatSortModule,
  MatTableModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS } from '@angular/material/progress-spinner';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NotifierModule } from 'angular-notifier';
import { TextMaskModule } from 'angular2-text-mask';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import {CookieService} from 'ngx-cookie-service';

import { ProcessViewerModule} from 'src/app/process-viewer/process-viewer.module';
import { AutoUnsubscribeModule } from 'src/app/shared/auto-unsubscribe/auto-unsubscribe.module';
import { NotificationsModalComponent } from 'src/app/shared/notifications-modal/notifications-modal.component';
import { NotificationsModalModule } from 'src/app/shared/notifications-modal/notifications-modal.module';
import { SectionLayoutModule } from 'src/app/shared/section-layout/section-layout.module';
import { environment } from '../environments/environment';
import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrandingModule } from './branding/branding.module';
import { CompleteAdminInviteModule } from './complete-admin-invite/complete-admin-invite.module';
import { DirectivesModule } from './core/shared/directives/directives.module';
import { AuthInterceptor, CacheInterceptor, ErrorInterceptor, HttpCancelInterceptor } from './core/shared/interceptors';
import { CapitalizePipe, SafePipe } from './core/shared/pipes';
import { GlobalErrorHandler } from './core/shared/services/global-error-handler.service';
import { DeploymentsListComponent } from './deployments/deployments-list/deployments-list.component';
import { DeploymentsModule } from './deployments/deployments.module';
import { HelpcenterModule } from './helpcenter/helpcenter.module';
import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { LoginModule } from './login/login.module';
import { NotificationPaneModule } from './notifications/notification-pane/notification-pane.module';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { DatatableModule } from './shared/datatable/datatable.module';
import {FormButtonsV2Module} from './shared/form-buttons-v2/form-buttons-v2.module';
import { FormButtonsModule } from './shared/form-buttons/form-buttons.module';
import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { MapsModule } from './shared/maps/maps.module';
import { MatDatatableServerSideComponent } from './shared/mat-datatable-server-side/mat-datatable-server-side.component';
import { ScriptModalComponent } from './shared/script-modal/script-modal.component';
import { ScriptModalModule } from './shared/script-modal/script-modal.module';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { SpinnerModule } from './shared/spinner/spinner.module';
import { AddonSubscriptionComponentModule } from './shared/subscription/addon-subscription/addon-subscription.module';
import { TreeNgxModule } from './shared/tree-ngx/tree-ngx.module';
import { UserInactivityModule } from './shared/user-inactivity/user-inactivity.module';
import { WarningModalAutoscalingModule } from './shared/warning-modal-autoscaling/warning-modal-autoscaling.module';
import { WarningModalContinueModule } from './shared/warning-modal-continue/warning-modal-continue.module';
import { WarningModalModule } from './shared/warning-modal/warning-modal.module';
import { WidgetInfoModule } from './shared/widget-info/widget-info.module';
import { SubscriptionCanceledModule } from './subscription-canceled/subscription-canceled.module';
import { WarningModalCancelSubscriptionModule } from './subscription/warning-modal-cancel-subscription/warning-modal-cancel-subscription.module';
import { WarningModalCancelSubscriptionComponent } from './subscription/warning-modal-cancel-subscription/warning-modal-cancel-subscription.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 30
};
@NgModule({
  entryComponents: [DeploymentsListComponent, NotificationsModalComponent, ScriptModalComponent, WarningModalCancelSubscriptionComponent],
  declarations: [
    AppComponent,
    FullComponent,
    BlankComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    MatDatatableServerSideComponent,
  ],
  imports: [
    FlexLayoutModule,
    MatSidenavModule,
    NotificationPaneModule,
    MatSnackBarModule,
    NotificationsModalModule,
    ScriptModalModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    CommonModule,
    BrandingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    InternationalPhoneNumberModule.forRoot(),
    RouterModule.forRoot(Approutes),
    PerfectScrollbarModule,
    TextMaskModule,
    TreeNgxModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    WarningModalModule,
    WarningModalContinueModule,
    WarningModalAutoscalingModule,
    WarningModalCancelSubscriptionModule,
    AddonSubscriptionComponentModule,
    LoginModule,
    DeploymentsModule,
    DatatableModule,
    SpinnerModule,
    HelpcenterModule,
    FormButtonsModule,
    FormButtonsV2Module,
    DirectivesModule,
    WidgetInfoModule,
    SectionLayoutModule,
    AutoUnsubscribeModule,
    NotifierModule.withConfig({
      behaviour: {
        autoHide: 6000,
        onClick: 'hide'
      },
      position: {
        horizontal: {position: 'right'},
        vertical: {position: 'top', distance: 60}
      }
    }),
    MapsModule, NgxChartsModule, NgSelectModule,
    CompleteAdminInviteModule,
    SubscriptionCanceledModule,
    UserInactivityModule,
    ProcessViewerModule,
    MatIconModule, MatFormFieldModule, MatProgressBarModule, MatTableModule,
    MatSortModule, MatPaginatorModule, MatCheckboxModule, MatDividerModule,
    MatToolbarModule, MatMenuModule, MatTooltipModule, MatButtonModule, MatBadgeModule,
    CdkTableModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpCancelInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    /* { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true }, */
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    {provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' }},
    {provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS, useValue: { color: 'primary' }},
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true, closeOnNavigation: true}},
    CapitalizePipe, SafePipe, CookieService
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
