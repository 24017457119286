export class Invitation {
  public id?: string;
  public accountId: string;
  public email: string;
  public userName: string;
  public firstName: string;
  public lastName: string;
  public roleTypeId: number;
  public roleSecurityId?: number;
  public url: string;
  public personalPhoneNumber: string;
  public personalPhoneNumberExt: string;
  public password: string;
  public allCompanies:  boolean;
  public allowCompanies: any [];
}
