export class EmailNotification {
  public code: string;
  public name: string;
  public isChecked: boolean;
  constructor(data?: {}) {
    this.code = data && data['code'] || '';
    this.name = data && data['name'] || '';
    this.isChecked = false;
  }
}
