export class CatalogWorkstation {
  public workstationId: number;
  public name: string;
  public os: string;
  public publicIP: string;
  public city: string;
  public osArchitecture: string;
  public processor: string;  
  public manufacturer: string;  
  public computerModel: string;  
  public physicalMemory: number;  	
  public usableMemory: number;  
  public state: string;    
  public type: string;  
  public biosSerialNumber: string;   
  public date: string;   
  public countEventLogs: number;
  public biosReleaseDate: string;
  
  constructor(data?: any) {
    if (data) {
        Object.keys(data).forEach(key => {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                this[key] = data[key];
            }
        });
    }
}
}
