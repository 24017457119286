export class DedicatedInstance {
    public scaleId: number;
    public instanceId: number;
    public name: string;
    public collectionAlias?: string;
    constructor(data?: any) {
        this.scaleId = data && data.scaleId ? data.scaleId : 0;
        this.instanceId = data && data.instanceId ? data.instanceId : 0;
        this.name = data && data.name ? data.name : '';
        this.collectionAlias = data && data.collectionAlias ? data.collectionAlias : '';
    }
}
