export class GpuErrors {
    public image: Array<string>;
    public machineType: Array<string>;
    public soleTenant: Array<string>;
    public zone: Array<string>;

    constructor(data?: any) {
        this.image = data && data.image ? data.image : [];
        this.machineType = data && data.machineType ? data.machineType : [];
        this.soleTenant = data && data.soleTenant ? data.soleTenant : [];
        this.zone = data && data.zone ? data.zone : [];
    }
}