import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import * as _ from 'underscore';

import { environment } from 'src/environments/environment';
import { GutilsService } from './gutils.service';

declare const gapi: any;
const defaultPageSize = 50;

@Injectable({
  providedIn: 'root'
})

export class GcpService {
  public aditionalRol: any[];

  constructor(private gutilsService: GutilsService) { }

  public loadGp(): void {
    try {
      return gapi.client.load('cloudresourcemanager', environment.gapi.apiVersion).then(
        () => {
          return true;
        }
      );
    } catch (exception) {
      throwError(exception);
      return;
    }
  }

  public getProjectsByFilter(searchValue: string): Promise<any> {
      let value = searchValue;
      if (searchValue) {
        value = this.sanitizeString(searchValue);
      }
      const params = value ? {
        filter: 'name=*' + value + '* OR projectId=*' + value + '* lifecycleState=ACTIVE',
        pageSize: defaultPageSize
      } : {
          filter: 'lifecycleState=ACTIVE',
          pageSize: defaultPageSize
        };
      const request = gapi.client.cloudresourcemanager.projects.list(params);
      return request.then((response) => {
        const projects = response.result && response.result.projects
          ? response.result.projects : [];
        return Promise.resolve(projects);
      }).catch((reason) => {
        return Promise.reject(reason);
      });
  }
  public createRol(sa, role): any {
    const serviceAccount = 'serviceAccount:' + sa.email;
    const bindings = {
      role: `roles/${role}`,
      members: [serviceAccount]
    };
    return bindings;
  }
  public getPolicy(projectId): Promise<any> {
    const params = {
      resource: projectId
    };
    try {
      const request = gapi.client.cloudresourcemanager.projects.getIamPolicy(params);
      return request.then((response) => {
          return Promise.resolve(response.result);
        }).catch(function (reason) {
          reason.request = {
            name: 'gapi.client.cloudresourcemanager.projects.getIamPolicy',
            params: params
          };
          return Promise.reject(reason);
        });
    } catch (e) {
      e.request = {
        name: 'gapi.client.cloudresourcemanager.projects.getIamPolicy',
        params: params
      };
      return Promise.reject(e);
    }
  }
  public addPolicy(projectId, sa, aditionalRol): Promise<any> {
    this.aditionalRol = aditionalRol;

    const addPolicy = (policy) => {
      const rol = 'editor';
      const bindings = [this.createRol(sa, rol)];

      policy.bindings = _.union(policy.bindings, bindings, this.aditionalRol);

      const params = {
        resource: projectId,
        policy: policy
      };

      try {
        const request = gapi.client.cloudresourcemanager.projects.setIamPolicy(params);
        return request.then((response) => {
        return Promise.resolve(response.result);
      }).catch((reason) => {
        reason.request = {
          name: 'gapi.client.cloudresourcemanager.projects.setIamPolicy',
          params: params
        };
        return Promise.reject(reason);
      });
    } catch {
        return Promise.reject({name: 'api-not-loaded'});
      }
    };

    return this.getPolicy(projectId)
      .then((p) => {
        return addPolicy(p);
      })
      .catch((reason) => {
        console.log('Error: ' + reason.result.error.message);
        return Promise.reject(reason);
      });
  }

  public createProject(projectName: string): Promise<any> {
    const guid = this.gutilsService.cieloGuid();
    const params = {
      name: projectName,
      projectId: guid
    };

    const request = gapi.client.cloudresourcemanager.projects.create(params);
    return request.getPromise().then((resp) => {
      if (resp && resp['result'] && resp['result'].name) {
        return this.checkOperation(resp.result);
      } else {
        return false;
      }
    }, (error) => {
      return error;
    });
  }

  private checkOperation(operation: Object): Promise<any> {
    const request = gapi.client.cloudresourcemanager.operations.get({ name: operation['name'] });
    return request.getPromise().then((resp) => {
      if (resp.result.done) {
        if (resp.result.error) {
          return resp.result;
        } else {
          return resp.result.response;
        }
      } else {
          return this.checkOperation(resp.result);
      }
    }, (error) => {
      return error;
    });
  }

  private sanitizeString(str: string): string {
    str = str.replace(/[^a-z0-9áéíóúñü \.,_-]/gim, '');
    return str.trim();
  }
}
