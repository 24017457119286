export const locale = {
  lang: 'es',
  warning_modal_autoscaling: {
    FORCE_UPDATE: 'Comenzar ahora (los servidores se reinician en 5 minutos)',
    NO_FORCE_UPDATE: 'Comenzar a las:',
    FORCE_UPDATE_NOTE: ' Los cambios que ha realizado requieren una redistribución de los hosts de sesión existentes en este grupo de recopilación. Esto afectará la disponibilidad del Grupo de recopilación y es posible que los usuarios no puedan acceder al Grupo de recopilación durante este tiempo.',
    CANCEL: 'Cancelar',
    SAVE: 'Guardar',
    SAVING: 'Guardando ...',
    PROCESS_TO_APPLY: 'Hay configuraciones de escala automática para ser aplicadas:',
    NOTE: 'Este proceso puede tardar varias horas (o más) según la cantidad de hosts de sesión.',
    NOTE_TITLE: 'NOTA:',
    ERROR_REQUIRED: 'Campo requerido'
  }
};
