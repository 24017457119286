import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'underscore';

export const patchProfileValidator = function (control: AbstractControl): ValidationErrors | null {
const value: string = control.value || '';
if (!value) {
    return null;
  }
const reg = new RegExp(/^[A-Za-z0-9-\s]{1,}[A-Za-z0-9]$/);
if (reg.test(value) === false) {
    return {
      pattern: 'Invalid format'
    };
}
};
