import {
  AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input,
  OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { Observable } from 'rxjs/Observable';
import { debounceTime } from 'rxjs/operators';
import { EarlyAccessService } from 'src/app/core/security/early-access.service';
import { EarlyAccessFeature } from 'src/app/models';
import * as _ from 'underscore';

import { Datatable } from '../../models/ui-elements/datatable/datatable';

import { locale as english } from './i18n/multiselect.en';
import { locale as spanish } from './i18n/multiselect.es';

const timeoutValue = 1000;
const backSpace = 8;
const disabledCls = 'custom-disabled';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MultiselectComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  public rows: any[] = [];
  @Input()
  public columns: TableColumn[];
  @Input()
  public title: string;
  @Input()
  public displayColumn: string;
  @Input()
  public displaySecondary: string;
  @Input()
  public isLoading: boolean;
  @Input()
  public messages: any = {};
  @Input()
  public showFooter: boolean;
  @Input()
  public selectionType: string;
  @Input()
  public details?: boolean;
  @Input()
  public overrideItems: boolean;
  @Input()
  public customId: string;
  @Input()
  public rowId: string;
  @Input()
  public noInitSearching: boolean;
  @Input()
  public hasMoreSearch: boolean;

  @Output()
  public selectItem = new EventEmitter<any>();
  @Output()
  public filterItems = new EventEmitter<any>();
  @Output()
  public fetchDataRequired = new EventEmitter<Datatable<any>>();
  @Output()
  public rowClick = new EventEmitter<any>();

  @ViewChild('itopiaMultiselect', { static: false })
  public itopiaMultiselect: any;
  @ViewChild('searchValue', { static: false })
  public searchValue: any;
  @ViewChild('searchNameValue', { static: false })
  public searchNameValue: any;
  @ViewChild('searchLocationValue', { static: false })
  public searchLocationValue: any;
  public isMultipleAdTrusts: boolean;

  public readonly headerHeight = 0;
  public readonly rowHeight = 35;
  public readonly pageLimit = 10;
  public readonly paddingHeight = 50;
  public readonly paddingTotal = 5;

  public selected: any[];
  public toDelete: any[];
  // tslint:disable-next-line:variable-name
  public _selectModelInput = new BehaviorSubject<Datatable<any>>(undefined);
  public searchPlaceholder: string;
  public searchNamePlaceholder: string;
  public searchLocationPlaceholder: string;
  public resetFilterLbl: string
  public searchValue$: Observable<any>;
  public searchNameValue$: Observable<any>;
  public searchLocationValue$: Observable<any>;
  public searchVal: string;
  public searchNameLocationVal: {name: string, location: string};
  @Input()
  set selectModelInput(value) {
    if (value !== undefined) {
      this._selectModelInput.next(value);
    }
  }

  get selectModelInput() {
    return this._selectModelInput.getValue();
  }
  public selectModel: Datatable<any>;

  private isFromScrolling: boolean;
  private selectedResult : Array<any>;
  public emptyFilters: boolean;

  constructor(private el: ElementRef, private translate: TranslateService, private ref: ChangeDetectorRef,
              private earlyAccessService: EarlyAccessService,) { }

  public ngOnInit() {
    this.emptyFilters = true;
    this.searchNameLocationVal = {name: '', location: ''};
    this.toDelete = [];
    this.selectedResult = [];
    this.setTranslations();
    this.selected = [];
    this.customId = this.customId ? this.customId : '';
    sessionStorage.setItem(`selected${this.customId}`, JSON.stringify(this.selected));
    this.showFooter = false;
    this.details = this.details ? true : false;
    if (this.selectionType === undefined) {
      this.selectionType = 'multiClick';
    }
    this.columns = [{
      headerCheckboxable: true, checkboxable: true, sortable: false, canAutoResize: false,
      draggable: false, resizeable: false, width: 55
    },              { prop: this.displayColumn }];
    this.selectModel = new Datatable<any>();

    if (!this.noInitSearching) {
      this.loadPage(this.selectModel);
    }
    this.isMultipleAdTrusts = this.earlyAccessService.verifyAccess(EarlyAccessFeature.MultipleAdTrusts);
    this._selectModelInput.subscribe(selectModel => this.observerSelectModelInput(selectModel));
  }

  public ngAfterViewInit(): void {
    if (!this.hasMoreSearch || !this.isMultipleAdTrusts) {
      this.searchValue$ = fromEvent(this.searchValue.nativeElement, 'keyup');
      this.searchValue$.pipe(debounceTime(timeoutValue)).subscribe($event => {
            const searchValue = $event.target.value.toLowerCase().trim();
            if (searchValue || $event.keyCode === backSpace) {
            const event = new Datatable() as any;
            event.searchValue = searchValue;
            this.searchVal = searchValue;
            this.filterItems.emit(event);
            this.emptyFilters = false;
            } else {
              this.searchVal = undefined;
            }
      }
      );
    } else if (this.hasMoreSearch && this.isMultipleAdTrusts) {
      this.searchLocationValue$ = fromEvent(this.searchLocationValue.nativeElement, 'keyup');
      this.searchLocationValue$.pipe(debounceTime(timeoutValue)).subscribe($event => {
          const searchLocationValue = $event.target.value.toLowerCase().trim();
          if ((searchLocationValue && searchLocationValue !== '') || $event.keyCode === backSpace) {
            const event = new Datatable() as any;
            this.searchNameLocationVal.location = searchLocationValue;
            event.searchNameLocationValue = this.searchNameLocationVal;
            this.emptyFilters = false;
            this.filterItems.emit(event);
          } else {
            this.searchNameLocationVal.location = undefined;
          }
        }
      );
      this.searchNameValue$ = fromEvent(this.searchNameValue.nativeElement, 'keyup');
      this.searchNameValue$.pipe(debounceTime(timeoutValue)).subscribe($event => {
          const searchNameValue = $event.target.value.toLowerCase().trim();
          if ((searchNameValue && searchNameValue !== '') || $event.keyCode === backSpace) {
            const event = new Datatable() as any;
            this.searchNameLocationVal.name = searchNameValue;
            event.searchNameLocationValue = this.searchNameLocationVal;
            this.emptyFilters = this.searchNameLocationVal.name === '' && this.searchNameLocationVal.location === '';
            this.filterItems.emit(event);
          } else {
            this.searchNameLocationVal.name = undefined;
          }
        }
      );
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.selectionType && changes.selectionType.currentValue) {
      this.selectionType = changes.selectionType.currentValue;
    }
    if (changes && changes.details && !changes.details.firstChange && changes.details.currentValue) {
      if (this.searchValue) {
        this.searchValue.nativeElement.value = '';
      }
    }
    if (changes && changes.isLoading && changes.isLoading.currentValue) {
      this.isLoading = true;
    }
  }

  public ngOnDestroy() {
    sessionStorage.setItem(`selected${this.customId}`, JSON.stringify([]));
  }
  public onScroll(offsetY: number) {
    offsetY = offsetY === 0 ? 0 : this.rows.length * this.rowHeight;
    // total height of all rows in the viewport
    const viewHeight = this.el.nativeElement.getBoundingClientRect().height - this.headerHeight;
    // check if we scrolled to the end of the viewport
    if (!this.isLoading && offsetY + viewHeight >= this.rows.length * this.rowHeight &&
      this.selectModel && (this.selectModel.CurrentPageNumber <= this.selectModel.TotalPages)) {

      // total number of results to load
      let limit = this.pageLimit;

      // check if we haven't fetched any results yet
      if (this.rows.length === 0) {
        // calculate the number of rows that fit within viewport
        const pageSize = Math.ceil(viewHeight / this.rowHeight);

        // change the limit to pageSize such that we fill the first page entirely
        // (otherwise, we won't be able to scroll past it)
        limit = Math.max(pageSize, this.pageLimit);
      }
      this.isFromScrolling = true;
      this.loadPage({ offset: limit });
    }
  }
  public updateFilter(): void {
    this.isLoading = true;
    this.rows = [];
  }
  public onSelect({ selected }): void {
    if (this.selected) {
      const previousSelected = _.pluck(JSON.parse(sessionStorage.getItem(`selected${this.customId}`)), 'id');
      previousSelected.forEach((item) => {
          if (!_.contains(_.pluck(selected, 'id'), item)) {
            this.toDelete.push(item);
          }
      });
      this.selected.splice(0, this.selected.length);
      this.selected.push(...selected);
      _.each(this.selected, (row) => row.allow =  true);
      // ** unique id (only to send data to parent component) DO NOT TOUCH *//
     // const selectedIds = _.pluck(this.selected, 'id');
      this.selectedResult = [];
      this.selected.forEach((item) => {
        if (!this.selectedResult.find((r) => r.id === item.id)) {
          this.selectedResult.push(item);
        }
      });
      this.selectItem.emit(this.selectedResult);
      sessionStorage.setItem(`selected${this.customId}`, JSON.stringify(this.selected));
      console.log('onSelect selected', this.selectedResult);
    }
  }
  public activate(event): void {
    if (event.type === 'click' && event.row) {
        this.rowClick.emit(event.row);
    }
  }

  public getRowClass(row): string {
    if (row.disabled) {
        return disabledCls;
    }
  }

  public loadPage(pageEvent) {
    this.isLoading = true;
    pageEvent = pageEvent ? pageEvent : { offset: 0 };
    if (this.selectModel) {
      this.selectModel['searchValue'] = this.searchVal;
      this.fetchDataRequired.emit(this.selectModel);
    }
  }
  public getRowIdentity(row): string {
    return row.id;
  }
  public observerSelectModelInput(selectModel: Datatable<any>): void {
    if (selectModel) {
      selectModel.CurrentPageNumber = selectModel.CurrentPageNumber ? selectModel.CurrentPageNumber : 0;
      selectModel.PageSize = this.pageLimit;
      this.selectModel = selectModel;
      const tempData = [];
      _.map(this.selectModel.Data, (newRow) => {
        const rowsDisplay = _.pluck(this.rows, this.displayColumn);
        const contains = !this.displaySecondary ? _.contains(rowsDisplay, newRow[this.displayColumn]) :
        _.contains(rowsDisplay, newRow[this.displayColumn] + ` (${newRow[this.displaySecondary]})`);
        if (!contains) {
          if (this.displaySecondary) {
            newRow[this.displayColumn] = newRow[this.displayColumn] + ` (${newRow[this.displaySecondary]})`;
          }
          tempData.push(newRow);
        } else {
          const rowFounded  = !this.displaySecondary ?
            _.find(this.rows, (row) => row[this.displayColumn] === newRow[this.displayColumn]) :
          _.find(this.rows, (row) => row[this.displayColumn] + ` (${newRow[this.displaySecondary]})` === newRow[this.displayColumn]);
          if (rowFounded && newRow.allow !== rowFounded.allow) {
            rowFounded.allow =  newRow.allow;
          }
        }
      });
      let rows = [...this.rows, ...tempData];
      // **  Parse customId to id */
      _.each(rows, (row) => row.id =  row[this.rowId]);
      if (this.overrideItems && !this.isFromScrolling) {
        rows = selectModel.Data;
      } else if (this.isFromScrolling) {
        this.isFromScrolling = false;
      }
      this.rows = _.clone(rows);
      console.log('rows', this.rows);
      this.setSelected();
      this.isLoading = false;
      this.ref.detectChanges();
      // *Fix to empty rows after search when all rows are loaded*/
      if (!this.isFromScrolling && this.searchVal) {
        setTimeout(() => {
         try {
          this.itopiaMultiselect.element.getElementsByTagName('datatable-body')[0].scrollTop++;
         } catch (error) {
            return error;
         }
        }, 1);
      }
    }
  }
  private setSelected(): void {
    const sessionSelected = JSON.parse(sessionStorage.getItem(`selected${this.customId}`));
    const backendSelected = _.filter(this.rows, (row: any) => row.allow);
    const notDuplicated = [];
    const flatSelected = _.flatten([...sessionSelected , ... backendSelected]);
    flatSelected.forEach((row) => {
      const rowsDisplay = _.pluck(notDuplicated, 'id');
      if (!_.contains(rowsDisplay, row.id) && !_.contains(this.toDelete, row.id)) {
        notDuplicated.push(row);
      }
    });
    const resultArray = notDuplicated.length > 0 ? notDuplicated :  flatSelected;
    this.onSelect({selected: resultArray});
  }
  private setTranslations(): void {
    const browserLang = this.translate.getBrowserLang();
    this.searchPlaceholder = browserLang === 'es' ? 'Buscar' : 'Search';
    const mapLangI18nFile = { es: spanish, en: english };
    if (!mapLangI18nFile[browserLang]) {
      this.translate.use('en');
      this.translate.setTranslation('en', mapLangI18nFile['en']);
    } else {
      this.translate.use(browserLang);
      this.translate.setTranslation(browserLang, mapLangI18nFile[browserLang]);
    }
    this.searchNamePlaceholder = this.translate.instant('multiselect.SEARCH_BY_NAME');
    this.searchLocationPlaceholder = this.translate.instant('multiselect.SEARCH_BY_LOCATION');
    this.resetFilterLbl = this.translate.instant('multiselect.RESET_FILTERS');
    this.setDefaultMessages();
  }

  private setDefaultMessages(): void {
    const messagesI18nConfig = {
      emptyMessage: 'NO_DATA',
      totalMessage: 'TOTAL',
      selectedMessage: 'SELECTED',
      showingMessage: 'SHOWING'
    };
    _.map(messagesI18nConfig, (value, key) => {
      if (!this.messages || !this.messages.hasOwnProperty(key)) {
        this.messages[key] = this.translate.instant(`multiselect.${value}`);
      }
    });
  }

  onResetFilter() {
    this.searchNameValue.nativeElement.value = '';
    this.searchLocationValue.nativeElement.value = '';
    this.selectModel = new Datatable<any>();
    this.loadPage(this.selectModel);
  }
}
