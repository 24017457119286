import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs/Observable';

import { CloudApp, ListDto } from 'src/app/models';
import { environment } from './../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MultiselectAppsService {
  constructor(private http: HttpClient) { }

  public searchAppsByCollection(deploymentId: number, filter: Object): Observable<ListDto<CloudApp>> {
    const url = `${environment.apiUrl}deployments/${deploymentId}/collections/cloudApp/search`;
    return this.http.post<ListDto<CloudApp>>(url, filter);
  }
  public searchApps(deploymentId: number, filter: Object): Observable<ListDto<CloudApp>> {
    const url = `${environment.apiUrl}deployments/${deploymentId}/cloudApps/search`;
    return this.http.post<ListDto<CloudApp>>(url, filter);
  }
}
