
export class UptimeEvent {
    public uptimeId : number;
    public id : number;
    public action : string;
    public day : string;
    public hour : string;
    public minutes : string;
    public time: string;
    public datetime: string;
    public type: string;
    public interval: string;

    // tslint:disable-next-line:cyclomatic-complexity
    constructor(data?: any) {
        this.uptimeId = data && data.uptimeId ? data.uptimeId : 0;
        this.id = data && data.id ? data.id : 0;
        this.action = data && data.action ? data.action : '';
        this.day = data && data.day ? data.day : '';
        this.datetime = data && data.datetime ? data.datetime : '';
        this.hour = data && data.hour ? data.hour : '';
        this.minutes = data && data.minutes ? data.minutes : '';
        this.time = data && data.time ? data.time : '';
        this.type = data && data.type ? data.type : '';
        this.interval = data && data.interval ? data.interval : '';
    }
}
