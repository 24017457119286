import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import * as _ from 'underscore';

import { DeploymentsService } from '../../deployments/deployments.service';
import { Datatable, Deployment } from '../../models';
import { AutoUnsubscribeComponent } from '../auto-unsubscribe/auto-unsubscribe.component';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent extends AutoUnsubscribeComponent implements OnInit {
  @Input() public layout: any;
  public isShowDeployInfo: boolean;
  public pageInfo;
  public deploymentSelected: Deployment;
  public deploymentName: string;
  public deploymentCode: string;

  private notInfoRoutes = ['audits', 'profile/partner', 'my-account', 'subscription', 'integrations', 'notifications'];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private deploymentsService: DeploymentsService,
    private translate: TranslateService
  ) {
    super();
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter(route => route.outlet === 'primary'))
      .pipe(mergeMap(route => route.data))
      .subscribe(event => {
        this.titleService.setTitle(event['title']);
        if (this.router.url) {
          this.isShowDeployInfo = this.routeChecker(router.url);
          const tempArray = this.router.url.split('/deployment/');
          const deploymentSplited = tempArray && tempArray.length > 1 ? tempArray[1].split('/') : this.router.url;
          const deploymentId = deploymentSplited && deploymentSplited.length >= 1 ? deploymentSplited[0] : '';
          _.map(event.urls, (url: any) => {
            if (url.url && url.url.indexOf(':deploymentId') > -1) {
              url.url = url.url.replace(':deploymentId', deploymentId);
            }
          });
        }
        if (event.urls instanceof Object) {
          const lang = this.translate.getBrowserLang() === 'es' ? 'es' : 'en';
          const urls = event.urls[lang];
          this.pageInfo = Object.assign(event, { urls });
        }
        else {
          this.pageInfo = event;
        }


      });
  }
  public ngOnInit() {
    const deploymentCreate = this.deploymentsService.provisionCreate.subscribe(created => {
      if (created) {
        this.deploymentName = undefined;
        this.deploymentCode = undefined;
      } else {
        const deploymentSelectedSubs = this.deploymentsService.deploymentSelected.subscribe(deploymentSelected => {
          if (deploymentSelected) {
            this.deploymentSelected = deploymentSelected;
            this.deploymentName = this.deploymentSelected.name;
            this.deploymentCode = '(' + this.deploymentSelected.code + ')';
          }
        });
        super.addSubscriptions(deploymentSelectedSubs);
      }
    });


  }
  private routeChecker(url): boolean {
    let valid = true;
    this.notInfoRoutes.forEach(item => {
      if (url.includes(item)) {
        valid = false;
      }
    });
    return valid;
  }
}
