export class CatalogDevice {
  public workstationId: number;
  public deviceId: number;
  public name: string;
  public portName: string;
  public queued: boolean;
  public network: boolean;
  public local: string;
  public countUser: number;
  public cDefault: boolean;
  public capabilityDescriptions: string;

  constructor(data?: any) {
    if (data) {
        Object.keys(data).forEach(key => {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                this[key] = data[key];
            }
        });
    }
}
}
