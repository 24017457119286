import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { finalize } from 'rxjs/operators';

import { SafePipe } from 'src/app/core/shared/pipes';
import { ListDto, Notification, NotificationType } from 'src/app/models';
import { NotificationModalType } from 'src/app/models/enums/notification-modal-type';
import { AutoUnsubscribeComponent } from 'src/app/shared/auto-unsubscribe/auto-unsubscribe.component';
import { NotificationsModalComponent } from 'src/app/shared/notifications-modal/notifications-modal.component';
import { WarningModalAutoscalingComponent } from 'src/app/shared/warning-modal-autoscaling/warning-modal-autoscaling.component';
import { NotificationsListService } from '../../notifications-list/notifications-list.service';
import { NotificationsService } from '../../notifications.service';
import { NotificationPaneActionService } from '../notification-pane-action.service';

import { locale as english } from '../i18n/notification-pane.en';
import { locale as spanish } from '../i18n/notification-pane.es';

const limit = 3;
const timeoutValue = 600;
const prefixNotify = 'notify';
const prefixAutoscaling = 'AutoscalingConfig';
const modalOpts: NgbModalOptions = { size: 'md', backdrop: 'static', keyboard: false, centered: true };

@Component({
  selector: 'app-notification-container',
  templateUrl: './notification-container.component.html',
  styleUrls: ['./notification-container.component.scss']
})
export class NotificationContainerComponent extends AutoUnsubscribeComponent implements OnInit {

  @Input()
  public type: NotificationType;

  public notifications: Array<Notification>;
  public count: number;
  public translations: Object;
  public typeIcon: string;
  public typeColor: string;
  public isFaileds: boolean;
  public successConfig: string;
  public isLoading: boolean;

  constructor(private translate: TranslateService, private notifService: NotificationsService, private router: Router,
              private notifListService: NotificationsListService, private ref: ElementRef, private safePipe: SafePipe,
              private renderer: Renderer2, private modalService: NgbModal, private notifyService: NotifierService,
              private notifPaneActionService: NotificationPaneActionService) {
    super();
  }

  public ngOnInit() {
    this.typeIcon = this.type && this.type === NotificationType.Failed ? 'cancel' : 'check_circle';
    this.typeColor = this.type && this.type === NotificationType.Failed ? 'text-danger' : 'text-success';
    this.isFaileds = this.type && this.type === NotificationType.Failed || false;
    this.count = 0;
    this.notifications = [];
    this.isLoading = true;
    this.getNews();
    this.setTranslations();
  }

  public markAsRead(): void {
    if (this.notifications.length) {
      const markAsReadSubs = this.notifService.markAsRead(NotificationType.Failed, this.isFaileds)
      .pipe(finalize(() => {
        this.router.navigate(['/notifications']);
      }))
      .subscribe(() => {
        this.getNews();
      });
      super.addSubscriptions(markAsReadSubs);
    } else {
      this.router.navigate(['/notifications']);
    }
  }

  public goToNotifications(): void {
    this.router.navigate(['/notifications']);
  }

  public retryNotification(notification: Notification): void {
    notification.retring = true;
    const taskObj = JSON.parse(notification.failedProcess);
    if (taskObj.length && taskObj[0].TaskId) {
      const retryNotificationSubs = this.notifListService.processRetryNotification(notification.contentID, taskObj[0].TaskId)
      .subscribe(() => {
        notification.failedProcess = undefined;
        notification.retring = false;
      });
      super.addSubscriptions(retryNotificationSubs);
    } else {
      setTimeout(() => {
        notification.retring = false;
      }, timeoutValue);
    }
  }

  private getNews(): void {
    this.isLoading = true;
    this.notifications = [];
    this.count = 0;
    const notificationSubs = this.notifService.getNews(NotificationType.Failed, this.isFaileds)
    .pipe(finalize(() => {
      this.isLoading = false;
      this.setTranslations();
    }))
    .subscribe((news: ListDto<Notification>) => {
      news.list = news.list.slice(0, limit);
      const tempNotifications = news.list.map((notification: Notification) => {
        if (notification.isHTML) {
          this.setIdNotification(notification);
        }
        return notification;
      });
      this.count = news.count;
      this.notifications = tempNotifications;
      this.notifPaneActionService.setNotificationInfo({ hasErrors: this.isFaileds, count: this.count });
      this.registerAllEventsNotify();
    });
    super.addSubscriptions(notificationSubs);
  }

  private setIdNotification(notification: Notification): void {
    const dom = new DOMParser().parseFromString(notification.body, 'text/html');
    const isAutoscaling = dom.querySelector('#AutoscalingConfig');
    const buttonTemplate = dom.querySelector('a');
    const container = dom.querySelector('small');
    const classContainer = notification.failedProcess ? 'text-danger font-12' : 'text-muted font-12';
    if (container) {
      container.setAttribute('class', classContainer );
    }
    if (buttonTemplate) {
      if (isAutoscaling) {
        buttonTemplate.setAttribute('id', prefixAutoscaling + notification.contentID);
      } else {
        buttonTemplate.setAttribute('id', prefixNotify + notification.contentID);
      }
      buttonTemplate.setAttribute('href', '' );
      buttonTemplate.setAttribute('class', 'btn btn-link text-info d-contents font-12' );
      buttonTemplate.setAttribute('type', 'button');
    }
    notification.body = this.safePipe.transform((dom.firstChild as Element).outerHTML, 'html');
    const subject = new DOMParser().parseFromString(notification.subject, 'text/html');
    notification.subject = this.safePipe.transform((subject.firstChild as Element).outerHTML, 'html');
  }

  private registerAllEventsNotify(): void {
    setTimeout(() => {
      this.notifications.forEach((notification: Notification) => {
        if (notification.isHTML) {
          const isAutoscaling = this.ref.nativeElement.querySelector(`#${prefixAutoscaling}${notification.contentID}`);
          this.registerEventModal(notification.contentID, !!isAutoscaling);
        }
      });
    });
  }

  private registerEventModal(contentID: number, isAutoscaling: boolean): void {
    const id = isAutoscaling ? `#${prefixAutoscaling}${contentID}` : `#${prefixNotify}${contentID}`;
    const elementRef = this.ref.nativeElement.querySelector(id);
    if (elementRef ) {
      const deploymentId = elementRef.getAttribute('data-deploymentid');
      const dataRef = elementRef.getAttribute('data-ref');
      const poolId = elementRef.getAttribute('data-poolid');
      const timeZone = elementRef.getAttribute('data-timezone');
      const apiVersion: string = elementRef.getAttribute('data-apiversion');
      const collectionId: number = elementRef.getAttribute('data-collection');
      const processId: string = elementRef.getAttribute('data-processid');
      const action: number = elementRef.getAttribute('data-action');
      const applyAll: boolean = elementRef.getAttribute('data-applyall');
      if (isAutoscaling) {
        this.renderer.addClass(elementRef, 'disabled');
        if (deploymentId && poolId && timeZone) {
          this.renderer.removeClass(elementRef, 'disabled');
          this.renderer.listen(elementRef, 'click', (event) => {
            event.preventDefault();
            const modalRef = this.modalService.open(WarningModalAutoscalingComponent, modalOpts);
            modalRef.componentInstance.deploymentTimeZone = timeZone;
            modalRef.componentInstance.onActionConfirmation.subscribe((forceUpdateDate: string) => {
              const schedule = forceUpdateDate;
              const isV3 = apiVersion && apiVersion.toLocaleLowerCase() === 'v3';
              const executeSubs = (!isV3 ? this.notifListService.executeAutoscaling(deploymentId, poolId, schedule)
                : this.notifListService.executeAutoscalingV3(deploymentId, poolId, schedule,
                                                                     collectionId, processId, action, applyAll))
              .pipe(finalize(() => {
                modalRef.componentInstance.canCloseModal$.next(true);
              }))
              .subscribe(() => {
                const disableNotificationSubs = this.notifListService.disableNotification(contentID)
                .subscribe(() => {
                  const notification = { type: 'success', message: this.successConfig};
                  this.notifyService.notify(notification.type, notification.message);
                });
                super.addSubscriptions(disableNotificationSubs);
              });
              super.addSubscriptions(executeSubs);
            });
          });
        }
      } else {
        this.renderer.listen(elementRef, 'click', (event) => {
            event.preventDefault();
            const modal = this.modalService.open(NotificationsModalComponent, modalOpts);
            modal.componentInstance.params = {deploymentId: deploymentId, dataRef: dataRef };
            modal.componentInstance.notificationModalType =  NotificationModalType.Script;
            modal.componentInstance.modal = modal;
        });
      }
    }
  }

  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
    this.translations = this.isFaileds ? {
      title: this.translate.instant('notification_pane.ERRORS'),
      noNotifications: this.translate.instant('notification_pane.NO_ERR_NOTIF'),
      newNotifications: this.translate.instant('notification_pane.NEW_ERR_NOTIF', { count: this.count - this.notifications.length }),
    } : {
      title: this.translate.instant('notification_pane.OTHER'),
      noNotifications: this.translate.instant('notification_pane.NO_OTHER_NOTIF'),
      newNotifications: this.translate.instant('notification_pane.NEW_OTH_NOTIF', { count: this.count - this.notifications.length }),
    };
    this.translations['retry'] = this.translate.instant('notification_pane.RETRY');
    this.successConfig = this.translate.instant('notification_pane.SUCCESFULL_AUTOSCALING');
  }

}
