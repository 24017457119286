import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import * as _ from 'underscore';

import { BinaryExpressionService, OperationExpressionService, UnaryExpressionService } from 'src/app/core/shared/filters';
import { DeploymentsService } from 'src/app/deployments/deployments.service';
import {Datatable, EarlyAccessFeature, ListDto, User} from 'src/app/models';
import { CloudUserStatus } from 'src/app/models/enums/cloud-user-status';
import { AutoUnsubscribeComponent } from '../../auto-unsubscribe/auto-unsubscribe.component';

import { locale as english } from './i18n/multiselect-users.en';
import { locale as spanish } from './i18n/multiselect-users.es';

const pageSizeValue = 10;
@Component({
  selector: 'app-multiselect-users',
  templateUrl: './multiselect-users.component.html',
  styleUrls: ['./multiselect-users.component.scss']
})
export class MultiselectUsersComponent extends AutoUnsubscribeComponent implements OnInit, AfterViewInit {
  @Input()
  public collectionPoolId: number;
  @Input()
  public deploymentId: number;
  @Input()
  public containerClass: string;
  @Input()
  public currentUserIds: Array<number>;
  @Input()
  public extraParams: Array<Object>;

  @Output()
  public selecteddUsers = new EventEmitter<Array<User>>();
  @Output()
  public loadedUsers = new EventEmitter<Array<Object>>();
  @Output()
  public isLoadingCloudUsers = new EventEmitter<boolean>();
  @Output()
  public hasFilter = new EventEmitter<boolean>();

  public usersTitle: string;
  public isLoadingUsers: boolean;
  public selectedUsers: Array<number>;
  public selectModelUsers: Datatable<Object> = new Datatable<Object>();
  public isMultipleAdTrusts: boolean;

  constructor(private deploymentsService: DeploymentsService, private translate: TranslateService,
              private operationExpression: OperationExpressionService,
              private unaryOperation: UnaryExpressionService, private binaryExpression: BinaryExpressionService) {
    super();
    this.setCurrentLang();
  }


  public ngOnInit(): void {
    this.containerClass = this.containerClass ? this.containerClass : '';
    const earlyAccess = JSON.parse(sessionStorage.getItem('earlyaccess'));
    this.isMultipleAdTrusts = _.any(earlyAccess, (access: any) => access.feature === EarlyAccessFeature.MultipleAdTrusts);
    this.setTranslations();
  }

  public ngAfterViewInit() {
    this.setTranslations();
  }

  public selectUser($event: Array<User>): void {
    const usersTemp = [];
    $event.forEach((user: User) => {
      usersTemp.push(user.cloudUserId);
    });
    this.selectedUsers = usersTemp;
    this.selecteddUsers.emit($event);
  }

  public getUsers($event): void {
    setTimeout(() => {
      this.isLoadingUsers = true;
    });
    const params = {
      pageIndex: $event.CurrentPageNumber + 1 || 1,
      pageSize: pageSizeValue,
      filter: []
    };
    params.filter.push(
    {
      'propertyName': 'CompanyUserStatus.Name', 'operation': this.operationExpression.expressionType.EQUALS, 'value': 'On cloud',
      'toLower': true, 'unaryOperation': this.unaryOperation.expressionType.NONE,
      'binaryOperation': this.binaryExpression.expressionType.NONE
    });
    if ($event.searchValue) {
      params.filter.push({
        'propertyName': 'FullName', 'operation': this.operationExpression.expressionType.CONTAINS, 'value': $event.searchValue,
        'toLower': true, 'unaryOperation': this.unaryOperation.expressionType.NONE,
        'binaryOperation': this.binaryExpression.expressionType.NONE
      });
    }
    if (!_.isEmpty(this.extraParams)) {
      _.each(this.extraParams, param =>  params.filter.push(param));
    }
    this.hasFilter.emit($event.searchValue !== undefined && $event.searchValue !== null);

    const getUsersSubs = (this.collectionPoolId
      ? this.deploymentsService.searchDeploymentUsersByCollection(this.deploymentId, this.collectionPoolId, params)
      : this.deploymentsService.searchDeploymentUsers(this.deploymentId, params))
      .pipe(finalize(() => {
        this.isLoadingUsers = false;
        this.isLoadingCloudUsers.emit(this.isLoadingUsers);
      }))
      .subscribe((users: ListDto<User>) => {
        const displayUsers = _.filter(users.list, (display:  any) =>
        display.status.toLowerCase() === CloudUserStatus.OnCloud.toLowerCase());
        const mapUsersAllowProp = displayUsers.map(user => {
          const userName = this.isMultipleAdTrusts
            ? user.userPrincipalName
            : user.username.substr(0, user.username.indexOf('@'));
          user.fullName = user.firstName + ' ' + user.lastName + ' (' + userName + ')';
          if (this.currentUserIds && this.currentUserIds.includes(user.cloudUserId)
          || this.selectedUsers && this.selectedUsers.includes(user.cloudUserId)) {
            user.allow = true;
          }
          return user;
        });
        this.selectModelUsers = {
          PageSize: pageSizeValue,
          TotalElements: users.count,
          TotalPages: Math.ceil(users.count / pageSizeValue),
          CurrentPageNumber: $event.CurrentPageNumber + 1,
          SortBy: 'name',
          SortDir: 'asc',
          Data: mapUsersAllowProp
        };
        this.loadedUsers.emit(this.selectModelUsers.Data);
      });
    super.addSubscriptions(getUsersSubs);
  }

  public setCurrentLang(): void {
    const browserLang = this.translate.getBrowserLang();
    const mapLangI18nFile = { es: spanish, en: english };
    if (!mapLangI18nFile[browserLang]) {
      this.translate.use('en');
      this.translate.setTranslation('en', mapLangI18nFile['en']);
    } else {
      this.translate.use(browserLang);
      this.translate.setTranslation(browserLang, mapLangI18nFile[browserLang]);
    }
  }

  private setTranslations(): void {
    this.setCurrentLang();
    this.usersTitle = this.translate.instant('multiselect_users.USERS_TITLE');
  }

}
