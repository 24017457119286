import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { CapitalizePipe } from '../pipes';
import { NotificationActions } from './enum/notifications.actions';
import { NotificationResources } from './enum/notifications.resources';
import { NotificationType } from './enum/notifications.type';
import { locale as english } from './i18n/notifications.en';
import { locale as spanish } from './i18n/notifications.es';

@Injectable({
  providedIn: 'root'
})

export class NotificationTranslateService {

  constructor(private translate: TranslateService, private notifier: NotifierService, private capitalizePipe: CapitalizePipe) {
    this.isProcess = true;
    this.isMultiple = false;
    this.setTranslations();
  }
  public isProcess : boolean;
  public isMultiple : boolean;

  // Public Types of Calls to the NotifierService
  public success(ntAction: NotificationActions, ntResource: NotificationResources, ntResourceName: string,
                 isProcess?: boolean, isMultiple?: boolean, ntResourceNumber?: number): void {
    this.notifierService(ntAction, NotificationType.success, ntResource, ntResourceName,
      isProcess ? isProcess : false , isMultiple ? isMultiple : false, ntResourceNumber ? ntResourceNumber : 0);
  }
  public successWithCustomMsg(customMsg: string): void {
    this.notifier.notify(NotificationType.success, customMsg);
  }

  public error(ntAction: NotificationActions,
               ntResource: NotificationResources, ntResourceName: string, isProcess?: boolean, isMultiple?: boolean): void {
    this.notifierService(ntAction, NotificationType.failure, ntResource, ntResourceName, isProcess ? isProcess : false);
  }

  public warn(ntAction: NotificationActions,
    ntResource: NotificationResources, ntResourceName: string, isProcess?: boolean, isMultiple?: boolean): void {
    this.notifierService(ntAction, NotificationType.warning, ntResource, ntResourceName, isProcess ? isProcess : false);
  }

  // Calling the NotifierService from Public Methods
  private notifierService(ntAction: NotificationActions, ntType: NotificationType, ntResource: NotificationResources,
                          ntResourceName: string, ntIsProcess?: boolean, ntIsMultiple?: boolean, ntResourceNumber?: number) {
    let message;
    this.isProcess = ntIsProcess !== undefined ? ntIsProcess : this.isProcess;
    this.setTranslations();
    const customMsg = (ntResourceName !== 'FILE' && ntResourceName !== 'AD') ? ntResourceName :
    this.translate.instant(`resources.${ntResourceName}`);
    if (this.isProcess) {
      if (ntResourceNumber && ntResourceNumber > 0) {
        message = this.translate.instant(`actions.${ntAction}.PROCESS`) +
      ' ' + ntResourceNumber + ' ' + this.translate.instant(`resources.${ntResource}_MULTI`);
      } else {
        message = this.translate.instant(`actions.${ntAction}.PROCESS`);
        if (ntIsMultiple) {
          message = message + ' ' + this.translate.instant(`resources.${ntResource}_MULTI`) + ' ' + customMsg;
        } else {
          message = message + ' ' + this.translate.instant(`resources.${ntResource}`) + ' ' + customMsg;
        }
      }
    } else {
     if (ntResourceNumber && ntResourceNumber > 0) {
        if(this.translate.getBrowserLang() === 'es'){
          message = ntResourceNumber + ' ' + this.capitalizePipe.transform(this.translate.instant(`resources.${ntResource}_MULTI`)) +
      ' ' + (this.translate.instant(`actions.${ntAction}.BD`) + `s`);
        } else {
           message = ntResourceNumber + ' ' + this.capitalizePipe.transform(this.translate.instant(`resources.${ntResource}_MULTI`)) +
      ' ' + this.translate.instant(`actions.${ntAction}.BD`);
        }
      } else {
        message = this.capitalizePipe.transform(this.translate.instant(`resources.${ntResource}`)) + ' ' + (customMsg) +
      ' ' + this.translate.instant(`actions.${ntAction}.BD`);
      }
    }
    this.notifier.notify(ntType, message);
  }

  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
  }
}


