import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ModalLayoutModule } from 'src/app/shared/modal-layout/modal-layout.module';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { TextAreaCopyModule } from 'src/app/shared/text-area-copy/text-area-copy.module';
import { NotificationsModalComponent } from './notifications-modal.component';

@NgModule({
  declarations: [NotificationsModalComponent],
  imports: [
    CommonModule, NgbModule, ModalLayoutModule, TextAreaCopyModule, SpinnerModule
  ],
  exports: [NotificationsModalComponent]
})
export class NotificationsModalModule { }


