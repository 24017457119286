import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import { FormMode } from '../models';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';

const position = 2;

@Injectable({
  providedIn: 'root'
})
export class DeploymentsActionsService {
  constructor(private router: Router) {}

  public partnerId$ = new BehaviorSubject<number>(null);
  public partnerId = this.partnerId$.asObservable();
  public formMode$ = new BehaviorSubject<FormMode>(null);
  public formMode = this.formMode$.asObservable();
  public setFormMode(formMode: FormMode) {
    this.formMode$.next(formMode);
  }

  public setPartnerId(id: number) {
    this.partnerId$.next(id);
  }

  public setDeploymentId (companyId: number): void {
    let route = null;
    if (this.router.url && this.router.url.indexOf('deployment') > - 1) {
      let deploymentId = this.router.url.split('/deployment/');
      deploymentId = this.router.url.split('/');
      route = this.router.url.replace(deploymentId[position], companyId.toString());
    } else {
      route = this.router.url.replace(':deploymentId', companyId.toString());
    }
    this.router.navigateByUrl(route);
  }
}
