export class MatDatatable {
  public pageSize: number;
  public pageIndex: number;
  public total: number;
  public totalPages: number;
  public sortBy: string;
  public sortOrder: string;

  constructor(data: any) {
    this.pageSize = data.pageSize || 25;
    this.pageIndex = data.pageIndex || 0;
    this.total = data.total || 0;
    this.totalPages = data.totalPages || 0;
    this.sortBy = data.sortBy || null;
    this.sortOrder = data.sortOrder || null;
  }
}
