
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { ListDtoProcess, ProcessActivity, RetryController } from './model';
import { BooleanDto } from '../models';


@Injectable({
  providedIn: 'root'
})
export class ProcessViewerService {

  private processViewerRouteSubject : BehaviorSubject<string>;
  public processViewerRoute: Observable<string>;

  constructor(private http: HttpClient) {
      this.processViewerRouteSubject =  new BehaviorSubject<string>(null);
      this.processViewerRoute = this.processViewerRouteSubject.asObservable();
  }

  public changeRoute(url: string): void {
    this.processViewerRouteSubject.next(url);
  }

  public listProcessActivity(deploymentId: number, params?: object): Observable<ListDtoProcess> {
    const paramsObj = new HttpParams().set('pageIndex', params ? params['pageIndex'] : 0)
                                      .set('pageSize', params ? params['pageSize'] : 200 );
    return this.http.get<ListDtoProcess>(`${environment.apiUrl}deployments/${deploymentId}/process`, { params: paramsObj });
  }

  public getProcessActivity(deploymentId: number, processId: string): Observable<ProcessActivity> {
     return this.http.get<ProcessActivity>(`${environment.apiUrl}deployments/${deploymentId}/process/${processId}`);
  }

  public listSubProcessActivity(deploymentId: number, processId: string, params?: object): Observable<ListDtoProcess> {
    const paramsObj = new HttpParams().set('pageIndex', params ? params['pageIndex'] : 0)
                                      .set('pageSize', params ? params['pageSize'] : 200 );
    return this.http.get<ListDtoProcess>(`${environment.apiUrl}deployments/${deploymentId}/process/${processId}/subProcess`,
                                           { params: paramsObj });
 }

  public retryTaskFailed(taskId: number): Observable<boolean> {
     return this.http.post<boolean>(`${environment.apiUrl}tasks/${taskId}/processRetry`,{});
  }


  public retryProcessFailed(deploymentId: number, retryController: RetryController): Observable<boolean> {
    const url = retryController.hasDeployment ?
        `${retryController.apiUrl}deployments/${deploymentId}/${retryController.alliasResource}/${retryController.entityId}/processRetry` :
        `${retryController.apiUrl}${retryController.alliasResource}/${retryController.entityId}/processRetry`;
    return this.http.post<boolean>(url, {});
}
}


