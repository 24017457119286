export enum PoolRdpSettings {
    MXRDP = 'mxrdp',
    FUAM = 'fuam',
    DBD = 'dbd',
    DR = 'dr',
    OC = 'oc',
    SWS = 'sws',
    APR = 'apr',
    ACR = 'acr'
}
