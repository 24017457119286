export const locale = {
  lang: 'en',
  reset_user_password: {
    'RESET': 'Reset password',
    'NEW_PASS': 'New password',
    'CANCEL': 'Cancel',
    'SAVE': 'Save',
    'SAVING': 'Saving ...',
    'RESET_SUCCESS': 'Password reset successfully',
    'RESET_TOOLTIP': 'Password complexity requirements',
    'ERROR_REQUIRED': 'This field is required',
    'CLOSE': 'Close',
    'RESET_BTN': 'Reset',
    'RESETTING': 'Resetting ...',
    'SEND_LINK_TITLE': 'Send a password reset link',
    'SEND_LINK_SUBTITLE': 'The selected users will receive an email with instructions to set their own password in the Cloud VDI Portal',
    'MANUAL_RESET_TITLE': 'Manually set a password',
    'MANUAL_RESET_EMAIL': 'Email the users their new password',
    'EXPIRE_CURRENT_PASSWORD': 'Expire the current password',
    'EXPIRE_CURRENT_PASSWORD_HELP': `If this option is enabled, the "User must set password at next logon" flag
      is set in Active Directory and the user cannot log in until they set a new password.`,
    'NO_EMAIL_WARNING': 'This user does not have an email address in WorkAnywhere. You can still manually set their password.',
    'MULTIPLE_USERS_SELECTED': '{{ amount }} users selected',
    'MULTIPLE_USERS_WITH_NO_EMAIL': '{{ amount }} users do not have an email address in WorkAnywhere.',
    'MULTIPLE_USERS_WITH_NO_EMAIL_ALL': `These users do not have an email address in WorkAnywhere.
      You can still manually set their password.`,
    'MULTIPLE_USERS_FROM_TRUSTED': '{{ amount }} users are from a trusted AD domain and cannot have their password reset.',
    'MULTIPLE_USERS_FROM_TRUSTED_ALL': 'These users are from a trusted AD domain and cannot have their password reset.',
    'MULTIPLE_USERS_SKIP_RESET': 'Skip these users and reset the remaining users',
    'MULTIPLE_USERS_RESET_NO_EMAIL': 'Reset these users  but do not send them a email.',
    'ONE_USER_WITH_NO_EMAIL': '1 user does not have an email address in WorkAnywhere.',
    'ONE_USER_FROM_TRUSTED': '1 user is from a trusted AD domain and cannot have their password reset.',
    'NO_USER_TO_RESET': 'There are no users to reset their password.'
  }
};
