import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';

import { BinaryExpressionService, OperationExpressionService, UnaryExpressionService } from 'src/app/core/shared/filters';
import { CloudApp, Datatable, EarlyAccessFeature, ListDto } from 'src/app/models';
import { AutoUnsubscribeComponent } from '../../auto-unsubscribe/auto-unsubscribe.component';
import { MultiselectAppsService } from './multiselect-apps.service';

import { locale as english } from './i18n/multiselect-apps.en';
import { locale as spanish } from './i18n/multiselect-apps.es';

const pageSizeValue = 10;
const statusAppRdy = 6;

@Component({
  selector: 'app-multiselect-apps',
  templateUrl: './multiselect-apps.component.html',
  styleUrls: ['./multiselect-apps.component.scss']
})
export class MultiselectAppsComponent extends AutoUnsubscribeComponent implements OnInit, OnChanges {
  @Input()
  public deploymentId: number;
  @Input()
  public containerClass: string;
  @Input()
  public collectionPoolIds: number[];
  @Input()
  public userApps: number[];

  @Output()
  public selectedAppEmitter = new EventEmitter<number[]>();

  public isLoadingApps: boolean;
  public selectModelApps: Datatable<CloudApp> = new Datatable<CloudApp>();
  public appsTitle: string;
  public isMultipleCollPoolsAccess: boolean;

  private selectedApps: number[];

  constructor(private translate: TranslateService, private operationExpression: OperationExpressionService,
              private unaryExpression: UnaryExpressionService, private binaryExpression: BinaryExpressionService,
              private multiselectAppsService: MultiselectAppsService) {
    super();
  }

  public ngOnInit() {
    this.isMultipleCollPoolsAccess = this.verifyAccess(EarlyAccessFeature.MultipleCollections);
    this.containerClass = this.containerClass ? this.containerClass : '';
    this.selectedApps = [];
    this.setTranslations();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.collectionPoolIds && !changes.collectionPoolIds.firstChange) {
      this.getAppsByCollection();
    }
  }

  public getAppsByCollection($event?: Datatable<CloudApp>): void {
    if (this.collectionPoolIds && this.collectionPoolIds.length) {
      setTimeout(() => {
        this.isLoadingApps = true;
      });
      const params = {
        'collectionIds': [],
        'pageIndex': $event && $event.CurrentPageNumber || 1,
        'pageSize': pageSizeValue,
        'filter': []
      };

      if (!this.collectionPoolIds.length) {
        params.collectionIds.push(0);
      } else {
        params.collectionIds.push(...this.collectionPoolIds);
      }

      params.filter.push(
        {
          'propertyName': 'CloudAppStatusId', 'operation': this.operationExpression.expressionType.EQUALS,
          'value': statusAppRdy, 'toLower': true, 'unaryOperation': this.unaryExpression.expressionType.NONE,
          'binaryOperation': this.binaryExpression.expressionType.AND
        }
      );

      if ($event && $event['searchValue']) {
        params.filter.push({
          'propertyName': 'Name', 'operation': this.operationExpression.expressionType.CONTAINS, 'value': $event['searchValue'],
          'toLower': true, 'unaryOperation': this.unaryExpression.expressionType.NONE,
          'binaryOperation': this.binaryExpression.expressionType.NONE
        });
      }
      const getAppsRequest = this.multiselectAppsService.searchAppsByCollection(this.deploymentId, params);
      const getAppsSubs = getAppsRequest
        .subscribe(apps => {
          if (apps.list) {
            this.selectedApps = this.findUnwantedSelectedApp(apps.list, this.selectedApps);
            this.selectedAppEmitter.emit(this.selectedApps);
          }
          const mapAppsAllowProp = apps.list.map(app => {
            if (this.userApps && this.userApps.includes(app.cloudAppId)
              || this.selectedApps && this.selectedApps.includes(app.cloudAppId)) {
              app['allow'] = true;
            }
            return app;
          });
          this.selectModelApps = {
            PageSize: pageSizeValue,
            TotalElements: apps.count,
            TotalPages: Math.ceil((apps.count / pageSizeValue)),
            CurrentPageNumber: $event && $event.CurrentPageNumber ? Number($event.CurrentPageNumber) + 1 : params.pageIndex + 1,
            SortBy: 'name',
            SortDir: 'asc',
            Data: mapAppsAllowProp
          };
          this.isLoadingApps = false;
          this.setTranslations();
        }, () => {
          this.isLoadingApps = false;
        });
      super.addSubscriptions(getAppsSubs);
    }
  }

  public selectApp($event: CloudApp[]): void {
    this.selectedApps = $event.map(cloudApp => cloudApp.cloudAppId);
    this.selectedAppEmitter.emit(this.selectedApps);
  }

  private verifyAccess(feature: string): boolean {
    const earlyAccess = JSON.parse(sessionStorage.getItem('earlyaccess'));
    return _.find(earlyAccess, (access: any) => access.feature.toLowerCase() === feature.toLowerCase()) ? true : false;
  }

  private setTranslations(): void {
    const browserLang = this.translate.getBrowserLang();
    const mapLangI18nFile = { es: spanish, en: english };
    if (!mapLangI18nFile[browserLang]) {
      this.translate.use('en');
      this.translate.setTranslation('en', mapLangI18nFile['en']);
    } else {
      this.translate.use(browserLang);
      this.translate.setTranslation(browserLang, mapLangI18nFile[browserLang]);
    }
    this.appsTitle = this.translate.instant('multiselect_apps.APPS_TITLE');

  }
  public findUnwantedSelectedApp(appsList: CloudApp[], selectedApp: number[]): number[] {
    const tempSelectedApp = [...selectedApp];
    const tempAppList = [];

    appsList.forEach(app => {
      tempAppList.push(app.cloudAppId);
    });
    selectedApp.forEach(x => {
      if (!tempAppList.includes(x)) {
        tempSelectedApp.splice(tempSelectedApp.indexOf(x), 1);
      }
    });
    return tempSelectedApp;
  }
}
