export class CatalogWorkstationWarranty {
  public workstationId: number;
  public stateDescription: string;
  public state: boolean;
  public endDate: string;
    
  constructor(data?: any) {
    if (data) {
        Object.keys(data).forEach(key => {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                this[key] = data[key];
            }
        });
    }
}
}
