export class InstanceTypeReportDto {
  public instanceTypeId: number;
  public code: string;
  public name: string;
  constructor(data ? : any) {
    this.instanceTypeId = data && data.instanceTypeId ? data.instanceTypeId : 0;
    this.name = data && data.name ? data.name : '';
    this.code = data && data.code ? data.code : '';
  }
}
