export enum NotificationActions {
  create = 'create',
  edit = 'edit',
  delete = 'delete',
  enable = 'enable',
  disable = 'disable',
  reset = 'reset',
  logoff = 'logoff',
  unlock = 'unlock',
  reassign = 'reassign',
  import = 'import',
  move = 'move',
  clone = 'clone',
  download = 'download',
  upload = 'upload',
  start = 'start',
  restart = 'restart',
  stop = 'stop',
  redeploy = 'redeploy',
  connect = 'connect',
  restore = 'restore',
  send = 'send',
}
