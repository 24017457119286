import { Injectable } from '@angular/core';
import { throwError } from 'rxjs/internal/observable/throwError';

import { environment } from 'src/environments/environment';
import { GutilsService } from './gutils.service';

declare const gapi: any;
@Injectable({
  providedIn: 'root'
})
export class GiamService {
  public service = 'iam.googleapis.com';

  private serviceAccount: any;

  constructor(private gutilsService: GutilsService) { }

  public loadGiam(): void {
    try {
      return gapi.client.load('iam', environment.gapi.apiVersion).then(
        () => {
          return true;
        }
      );
    } catch (exception) {
      throwError(exception);
      return;
    }
  }
  public createServiceAccount(projectId): Promise<Object> {
    const name = this.gutilsService.getProjectName(projectId);
    const accountId = this.gutilsService.cieloGuid();
    const serviceAccount = {
      displayName: accountId
    };
    const params = {
      name: name,
      accountId: accountId,
      serviceAccount: serviceAccount
    };
    try {
      const request = gapi.client.iam.projects.serviceAccounts.create(params);
      return request.then((response: Object) => {
        this.serviceAccount = response['result'];
        return this.createKey(projectId, this.serviceAccount);
      }).catch((reason) => {
        reason.request = {
          name: 'gapi.client.iam.projects.serviceAccounts.create',
          params: params
        };
        return Promise.reject(reason);
      });
    } catch {
      return Promise.reject({name: 'api-not-loaded'});
    }
  }
  public createKey(projectId, sa) : Promise<Object> {
    const name = this.getKeyName(projectId, sa);
    const params = {
      name: name,
      privateKeyType: 'TYPE_GOOGLE_CREDENTIALS_FILE'
    };
    try {
      const request = gapi.client.iam.projects.serviceAccounts.keys.create(params);
      return request.then((response) => {
      this.serviceAccount.key = response.result;
      return Promise.resolve(this.serviceAccount);
    }).catch((reason) => {
      reason.request = {
        name: 'gapi.client.iam.projects.serviceAccounts.keys.create',
        params: params
      };
      return Promise.reject(reason);
    });
  } catch (e) {
    return Promise.reject(e);
  }
  }
  public activate (consumer): any {
    const params = {
        serviceName: this.service,
        consumerId: consumer
    };
    try {
      const request = gapi.client.servicemanagement.services.active(params);
      return request.then( (response) => {
          if (response && response.result && response.result.name) {
              return response.result.name;
          }
          return false;
      });
    } catch (reason) {
        reason.request = {
          name: 'servicemanagement.googleapis.com/v1/services/deploymentmanager.googleapis.com:enable',
          params: params
      };
        return Promise.reject(reason);
    }
}
  public hasEnabled(operationName) {
    const params = {
        name: operationName
    };
    const request = gapi.client.servicemanagement.operations.get(params);
    return request.then((response) => {
        if (response.result) {
            return response.result;
        }
        return { done: false };
    }).catch( (reason) => {
        reason.request = {
            name: environment.gapi.urlServiceActivate,
            params: params,
            errors: reason.errors
        };
        return reason;
    });
}
  public getKeyName(projectId, sa): string {
    const account = this.gutilsService.getProjectName(projectId);
    const name = account + '/serviceAccounts/' + sa.email;
    return name;
  }
  public getServiceAccount(): any {
    return this.serviceAccount;
  }
}
