import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'underscore';

let external = '';
const minValues = {
    'win': { 'boot': 50, 'data': 10 },
    'lin': { 'boot': 10, 'data': 10 }
};

const maxValue = 65536;

export const validateDiskSize = function (operatingSystem: string, fixedDiskSize?: number): ValidationErrors | null {
    external = operatingSystem;
    return (control: AbstractControl) => {
        const value: string = control.value || '';
        if (!value) {
            return null;
        }
        const diskSize = ( primary: boolean, size: number, edit: boolean, oS: string, minDiskSize?: number) => {
            if (size > maxValue) {
                return {
                    maxSize: { 'value': maxValue + ' GB' }
                };
            }
            if (minDiskSize)  {
                if (size < minDiskSize ) {
                    return {
                        minSize: { 'value': minDiskSize + ' GB' }
                    };
                }
            } else {
                if ( minValues[oS] ) {
                      const minValue = edit &&
                        control.parent.controls['initialValueDiskSize'].value >= minValues[oS][primary ? 'boot' : 'data']
                        ? control.parent.controls['initialValueDiskSize'].value : minValues[oS][primary ? 'boot' : 'data'];
                      if ( size < minValue ) {
                        return {
                            minSize: { 'value': minValue + ' GB' }
                        };
                      }
                }
                return false;
            }
        };

        const boot = control.parent.controls['boot'].value;
        const edition = control.parent.controls['edition'].value;
        const error = diskSize(boot, Number(value), edition, external, fixedDiskSize);
        if (error) {
           return error;
        }
    };
};
