import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'underscore';

let external = [];
let properties = [];
// tslint:disable-next-line: max-line-length
export const validateResourceDuplicate = function (list: Array<{'key': string, 'value': string}>, prop: Array<string>): ValidationErrors | null {
  external = list;
  properties = prop;
  return (control: AbstractControl) => {
        const key = control.parent.value[properties[0]];
        const value = control.parent.value[properties[1]];
        const verifyDuplicateRows = () => {
            if ( external.length > 0 ) {
                let run = true;
                let i = 0;
                let equal = false;
                while (run) {
                    equal = external[i][properties[0]] === key && external[i][properties[1]] === value;
                    run = !equal && i < external.length - 1;
                    i++;
                }
                return !equal;
            } else {
                return true;
            }
        };

        const valid = verifyDuplicateRows();
        if (!valid) {
            return {
                resourceDuplicate: true
            };
        }
    };
};
