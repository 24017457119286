export class Provision {
    public provisionId: number;
    public deploymentId: number;
    public organizationId: number;
    public companyId: number;
    public deploymentName: string;
    public deploymentCode: string;
    public isAutoGenerated: boolean;
    public currentStep: string;
    public deploymentType: string;
    public integration: Object;
    public regions: any;
    public directoryType: any;
    public networkinType: any;
    public vpcSelected: any;
    public settingGroupId: number;
    public settings: any;
    public externalDNS: string;
    public operatingSystem: string;
    public amountOfUsers: number;
    public collectionName: string;
    public defaultSite: string;
    // tslint:disable-next-line:cyclomatic-complexity
    constructor(data?: any) {
      this.provisionId =  data && data.provisionId ? data.provisionId : 0;
      this.organizationId =  data && data.organizationId ? data.organizationId : 0;
      this.deploymentId =  data && data.deploymentId ? data.deploymentId : data && data.companyId ? data.companyId : null;
      this.companyId =  data && data.companyId ? data.companyId : null;
      this.deploymentName =  data && data.deploymentName ? data.deploymentName : '';
      this.deploymentCode =  data &&  data.deploymentCode ? data.deploymentCode : null;
      this.isAutoGenerated =  data &&  data.isAutoGenerated ? data.isAutoGenerated : true;
      this.currentStep =  data &&  data.currentStep ? data.currentStep : '';
      this.deploymentType =  data &&  data.deploymentType ? data.deploymentType : '';
      this.integration =  data &&  data.integration ? data.integration : {};
      this.regions =  data &&  data.regions ? data.regions : null;
      this.directoryType =  data &&  data.directoryType ? data.directoryType : null;
      this.networkinType =  data &&  data.networkinType ? data.networkinType : null;
      this.vpcSelected =  data &&  data.vpcSelected ? data.vpcSelected : null;
      this.settingGroupId =  data &&  data.settingGroupId ? data.settingGroupId : 0;
      this.settings =  data &&  data.settings ? data.settings : {};
      this.externalDNS =  data &&  data.externalDNS ? data.externalDNS : '';
      this.operatingSystem =  data &&  data.operatingSystem ? data.operatingSystem : '';
      this.amountOfUsers =  data &&  data.amountOfUsers ? data.amountOfUsers : 0;
      this.collectionName =  data &&  data.collectionName ? data.collectionName : '';
      this.defaultSite =  data &&  data.defaultSite ? data.defaultSite : '';
    }
}
