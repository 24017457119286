import { OrganizationUnit } from './organizationUnit';
export class Gpo {
   public companyGpoId: number;
   public organizationalUnitId: number;
   public organizationalUnit: OrganizationUnit;
   public companyId: number;
   public gpoName : string;
   public gpoDomainName : string;
   public link: boolean;
   public urlDownload: string;
   public itopiaBucket: boolean;
   public errors: Object[];
   public isSaving: boolean;
   constructor(data?: any) {
    this.companyId =  data && data.companyId ? data.companyId : 0;
    this.organizationalUnitId =  data && data.organizationalUnitId ? data.organizationalUnitId : 0;
    this.organizationalUnit = data && data.organizationalUnit || null;
    this.companyGpoId =  data && data.companyGpoId ? data.companyGpoId : 0;
    this.gpoName =  data &&  data.gpoName ? data.gpoName : '';
    this.gpoDomainName =  data &&  data.gpoDomainName ? data.gpoDomainName : '';
    this.link =  data &&  data.link ? data.link : false;
    this.urlDownload =  data &&  data.urlDownload ? data.urlDownload : '';
    this.itopiaBucket =  data &&  data.itopiaBucket ? data.itopiaBucket : false;
    this.errors =  data && data.errors || undefined;
    this.isSaving =  data && data.isSaving || undefined;
    }
}
