import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { FormButtonsModule } from './../form-buttons/form-buttons.module';

import { AutoUnsubscribeModule } from '../auto-unsubscribe/auto-unsubscribe.module';
import { WarningModalContinueComponent } from './warning-modal-continue.component';

@NgModule({
    imports: [CommonModule, NgbModule, RouterModule, TranslateModule, FormButtonsModule,
              AutoUnsubscribeModule, FormsModule, ReactiveFormsModule],
    declarations: [WarningModalContinueComponent],
    exports: [WarningModalContinueComponent]
})
export class WarningModalContinueModule {
}
