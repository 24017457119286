import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuEventsService {
  public miniSideBarSubject: BehaviorSubject<any>;
  public miniSideBar: Observable<any>;

  constructor() {
    this.miniSideBarSubject = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('miniSideBar')));
    this.miniSideBar = this.miniSideBarSubject.asObservable();
  }

  public emitMiniSideBar(isMini: boolean): void {
    sessionStorage.setItem('miniSideBar', JSON.stringify(isMini));
    this.miniSideBarSubject.next(isMini);
  }
  public getMiniSideBar(): Observable<any> {
     sessionStorage.getItem('miniSideBar');
     return this.miniSideBar;
  }
}
