export class ProvisionDomain {
    public provisionType: number;
    public provisionId: number;
    public integrationId: number;
    public adDomain: string;
    public netBiosName: string;
    public externalDNS: string;
    public suffix: string;
    public operatingSystem: string;
    public amountOfUsers: number;
    public domainAdminUsername: string;
    public domainAdminPassword: string;
    public dnsServersIp: Array<string>;
    public specificDCs: Array<string>;
    public timeZone: string;
    public settingGroupId: number;
    public vpcNetworkName: string;
    public baseOu: string;
    public defaultSite: string;
    // tslint:disable-next-line: cyclomatic-complexity

    constructor(data?: any) {
      this.provisionType =  data && data.provisionType ? data.provisionType : '';
      this.provisionId =  data && data.provisionId ? data.provisionId : 0;
      this.integrationId =  data &&  data.integrationId ? data.integrationId : 0;
      this.adDomain =  data &&  data.adDomain ? data.adDomain : '';
      this.netBiosName =  data &&  data.netBiosName ? data.netBiosName : '';
      this.externalDNS =  data &&  data.externalDNS ? data.externalDNS : '';
      this.suffix =  data &&  data.suffix ? data.suffix : '';
      this.operatingSystem =  data &&  data.operatingSystem ? data.operatingSystem : '';
      this.amountOfUsers =  data &&  data.amountOfUsers ? data.amountOfUsers : 0;
      this.domainAdminUsername =  data &&  data.domainAdminUsername ? data.domainAdminUsername : '';
      this.domainAdminPassword =  data &&  data.domainAdminPassword ? data.domainAdminPassword : '';
      this.dnsServersIp =  data &&  data.dnsServersIp instanceof Array ? data.dnsServersIp : [];
      this.specificDCs =  data &&  data.specificDCs instanceof Array ? data.specificDCs : [];
      this.timeZone =  data &&  data.timeZone ? data.timeZone : '';
      this.settingGroupId =  data &&  data.settingGroupId ? data.settingGroupId : 0;
      this.vpcNetworkName =  data &&  data.vpcNetworkName ? data.vpcNetworkName : '';
      this.baseOu =  data &&  data.baseOu ? data.baseOu : '';
      this.defaultSite =  data &&  data.defaultSite ? data.defaultSite : '';
    }
}
