export const configRetryController = {
    SecurityGroup : {
        version: 'v2',
        hasDeployment : true,
    },
    CloudUser: {
        version: 'v2',
        hasDeployment : true
    },
    CloudImage : {
        version: 'v2',
        hasDeployment : true
    },
    CollectionPool : {
        version: 'v2',
        hasDeployment : true
    },
    CloudInstance  : {
        version: 'v2',
        hasDeployment : true
    },
    CloudApp : {
        version: 'v2',
        hasDeployment : true
    },
    Deployment : {
        version: 'v2',
        hasDeployment : false
    }
};
