export class CatalogDrive {    
  public driveId: number;
  public workstationId: number;
  public name: string;
  public description: string;
  public caption: string;
  public size: string;
  public path: string;
  public volumeName: string;
  public deviceLetter: string;
  public fileSystem: string;
  public freeSpace: string;
  public remote: boolean;
  public remoteString: string;
  public volumeSerialNumber: string;
  public countUser: number;

  constructor(data?: any) {
    if (data) {
        Object.keys(data).forEach(key => {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                this[key] = data[key];
            }
        });
    }
}
}
