export class TimeZoneModel {
    public display: string;
    public group: string;
    public name: string;
    public offset: string;
    public tz: string;
    constructor(data ? : any) {
        this.display = data && data.display ? data.display : '';
        this.name = data && data.name ? data.name : '';
        this.group = data && data.group ? data.group : '';
        this.offset = data && data.offset ? data.offset : '';
        this.tz = data && data.tz ? data.tz : '';
    }
}
