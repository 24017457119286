import { environment } from 'src/environments/environment';
import { configRetryController } from '../const';
import { ResourceRetryEnum } from '../enum';

export class RetryController {
    public entityId: number;
    public resource: string;
    public apiUrl: string;
    public hasDeployment: boolean;
    public alliasResource: string;

    constructor(data ? : any) {
        this.entityId = data && data.entityId ? data.entityId : 0;
        this.resource = data && data.resource ? data.resource : null;
        if (data.resource) {
            const resource = configRetryController[this.resource];
            this.alliasResource = ResourceRetryEnum[data.resource];
            if (resource) {
                switch (resource['version']) {
                    case 'v1':
                        this.apiUrl = environment.apiUrlV1;
                        break;
                    case 'v2':
                        this.apiUrl = environment.apiUrl;
                        break;
                    case 'v3':
                        this.apiUrl = environment.apiUrlV3;
                        break;
               }
                this.hasDeployment = resource['hasDeployment'];
            }
        }
    }
}
