export class UserOptions {
    public forcePasswordResetNextLogin: boolean;
    public noExpirationDate: boolean;
    public expirationDate: Date;    
    public passwordNeverExpire: boolean;   
  constructor(data?: UserOptions) {
        this.forcePasswordResetNextLogin =  data && data.forcePasswordResetNextLogin ? data.forcePasswordResetNextLogin : false;
        this.noExpirationDate =  data && data.noExpirationDate ? data.noExpirationDate : false;
        this.expirationDate =  data &&  data.expirationDate ? data.expirationDate : null;
        this.passwordNeverExpire =  data &&  data.passwordNeverExpire ? data.passwordNeverExpire : false;       
    }

}
