import { Component, Input, OnInit} from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import * as _ from 'underscore';

import { ServerError } from 'src/app/models';
import { AutoUnsubscribeComponent } from 'src/app/shared/auto-unsubscribe/auto-unsubscribe.component';

import { locale as english } from './i18n/script-modal.en';
import { locale as spanish } from './i18n/script-modal.es';
import { ScriptParams } from './model/script-params';

@Component({
  selector: 'app-script-modal',
  templateUrl: './script-modal.component.html',
  styleUrls: ['./script-modal.component.scss']
})
export class ScriptModalComponent extends AutoUnsubscribeComponent implements OnInit {

  @Input()
  public params: ScriptParams;
  @Input()
  public modal: NgbModalRef;

  public deploymentId : number;
  public title: string;
  public showSubtitles: boolean;
  public subtitles: string[];
  public link: string;
  public service: any;
  public action: any;
  public serviceCallback: any;

  public cancelText: string;
  public commandText: string;
  public loadingScripts: boolean;
  public errorGetCommand: string;

  constructor(private translate: TranslateService) {
    super();
    this.setTranslations();
  }

  public ngOnInit() {
    if (this.params) {
      this.deploymentId = this.params.deploymentId;
      this.title = this.params.title;
      this.service = this.params.service;
      this.action = this.params.action;
      this.serviceCallback = this.params.serviceCallback;
      this.showSubtitles = this.params.showSubtitles || false;
      if (this.showSubtitles) {
        this.subtitles = this.params.subtitles;
        this.link = this.params.link;
      }
      this.getCommands();
    }
  }

  public getCommands(): void {
    this.loadingScripts = true;
    if (this.showSubtitles) {
      const configCommandSubs = this.service[this.action](this.deploymentId, true)
          .pipe(finalize(() => {
              this.loadingScripts = false;
          }))
          .subscribe((response: any) => {
            this.errorGetCommand = '';
            this.commandText = this.serviceCallback(response);
        }, (ex: {error: ServerError}) => {
          this.errorGetCommand = ex.error.message;
        });
      super.addSubscriptions(configCommandSubs);
    }
  }

  public closeModal(): void {
    if (this.modal) {
      this.modal.dismiss();
    }
  }

  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
    this.cancelText = this.translate.instant('script_modal.CANCEL');
  }
}
