export class CloudAppDto {
  public cloudAppId: number;
  public name: string;
  public version: string;
  public companyId: number;
  public onCloud: boolean;
  public toCloud: boolean;
  public path: string;
  public restricted: boolean;
  public associatedApplication: number;
  public sendToCloud: boolean;
  public clientApplicationId: string;
  public applicationId: string;
  public companyUsers: number[];
  public securityGroups: number[];
  public collectionPoolIds: number;
  public cloudAppStatusId: number;
  public allCollectionUsers: boolean;
  public startIn: string;
  constructor (data? : any) {
    this.cloudAppId = data && data.cloudAppId ? data.cloudAppId : 0;
    this.name = data && data.name ? data.name : '';
    this.version = data && data.version ? data.version : '';
    this.companyId = data && data.companyId ? data.companyId : 0;
    this.onCloud = data && data.onCloud ? data.onCloud : true;
    this.toCloud = data && data.toCloud ? data.toCloud : true;
    this.applicationId = data && data.applicationId ? data.applicationId : '';
    this.clientApplicationId = data && data.clientApplicationId ? data.clientApplicationId : '';
    this.cloudAppStatusId = data && data.cloudAppStatusId ? data.cloudAppStatusId : 0;
    this.restricted = data && data.restricted ? data.restricted : false;
    this.path = data && data.path ? data.path : '';
    this.companyUsers = data && data.companyUsers ? data.companyUsers : [];
    this.securityGroups = data && data.securityGroups ? data.securityGroups : [];
    this.collectionPoolIds = data && data.collectionPoolIds ? data.collectionPoolIds : 0;
    this.allCollectionUsers = data && data.allCollectionUsers;
    this.associatedApplication = data && data.associatedApplication ? data.associatedApplication : 0;
    this.sendToCloud = data && data.sendToCloud ? data.sendToCloud : false;
    this.startIn = data && data.startIn ? data.startIn : '';
  }
}
