import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _ from 'underscore';

import { FormButtonsActionService } from './form-buttons-action.service';

@Component({
  selector: 'app-form-buttons',
  templateUrl: './form-buttons.component.html',
  styleUrls: ['./form-buttons.component.scss']
})
export class FormButtonsComponent implements OnInit, OnChanges {
  @Input()
  public actionButtons: string[];
  @Input()
  public isInfo: boolean;
  @Input()
  public isInvalid: boolean;
  @Input()
  public disableActionBtn: boolean;
  @Input()
  public showLoading: boolean;
  @Input()
  public isWizzard: boolean;
  @Input()
  public actionPressed: boolean;
  @Input()
  public showCreateEditBtn: boolean;
  @Input()
  public hasBorder: boolean;
  @Input()
  public hiddeCancel: boolean;
  @Input()
  public disableCancelBtn: boolean;
  @Input()
  public otherComponent: boolean;
  /** Form object reactive, it is entered when you want to compare with the initial values and disble button */
  @Input()
  public reactiveForm ?:  FormGroup;
  @Input()
  /** The petition that is being executed was satisfactory */
  public successExecute ?:  boolean;

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  public onActionConfirmation = new EventEmitter<boolean>();

  public cancelBtn: string;
  public originalFormValues: Object;
  public notChanges: boolean;
  public enabledByDefault: boolean;
  public actionCancelPressed: boolean;

  constructor(private formActionService: FormButtonsActionService) {
    if (this.showLoading === undefined) {
      this.showLoading = true;
    }
    if (this.isWizzard === undefined) {
      this.isWizzard = false;
    }
   }

  public ngOnInit() {
    this.actionPressed = false;
    this.enabledByDefault = false;
    this.actionCancelPressed = false;
    if (this.showCreateEditBtn === undefined) {
      this.showCreateEditBtn = true;
    }
    if (this.showLoading === undefined) {
      this.showLoading = true;
    }
    if (this.reactiveForm) {
        this.originalFormValues = _.clone(this.reactiveForm.value);
        this.detectChangesForm();
        this.notChanges = true;
    } else {
      this.notChanges = false;
    }
    this.formActionService.actionPressed.subscribe((isPressed) => {
      this.actionPressed = isPressed;
    });
    this.formActionService.disableCancelBtn.subscribe((isCanceled) => {
      this.disableCancelBtn = isCanceled;
    });
  }
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.isInvalid && !changes.isInvalid.currentValue) {
      this.actionPressed = false;
      this.isInvalid = changes.isInvalid.currentValue;
    }
    if (changes.actionPressed && !changes.actionPressed.firstChange) {
      this.actionPressed = changes.actionPressed.currentValue;
    }
    if (changes.successExecute && changes.successExecute.currentValue) {
      this.originalFormValues = _.clone(this.reactiveForm.value);
      this.notChanges = true;
    }
    if (changes.actionButtons && changes.actionButtons.previousValue && changes.actionButtons.currentValue
          && changes.actionButtons.previousValue !==  changes.actionButtons.currentValue) {
      this.actionCancelPressed = false;
    }
  }

  public actionConfirmation(action): void {
    if (action === 'cancel') {
      this.actionPressed = false;
      this.actionCancelPressed = true;
      this.onActionConfirmation.emit(false);
    } else {
      if (!this.actionPressed) {
        this.actionPressed = true;
        this.enabledByDefault = true;
      }
      this.onActionConfirmation.emit(true);
    }
  }

  private detectChangesForm(): void {
     this.reactiveForm.valueChanges.subscribe(x => {
        this.notChanges =  _.isMatch(this.originalFormValues, x );
     });
  }

}
