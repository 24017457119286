export class ServerError {
  public status: number;
  public code: number;
  public message: string;
  public data?: any;
  public errors?: any;

  constructor(data? : any) {
    this.status = data && data.status ? data.status : 0;
    this.code = data && data.code ? data.code : 0;
    this.message = data && data.message ? data.message : '';
    this.data = data && data.data;
    this.errors = data && data.errors;
  }
}
