export const locale = {
    lang: 'es',
    branding : {
        TITLE: 'Logo personalizado',
        TITLE_LOGO: 'Logo de la barra de herramientas',
        SUBTITLE_LOGO: `El logo de la barra de herramientas se muestra en la esquina superior izquierda de la consola de administración
            de CAS. Las dimensiones máximas son 200x60 px (las imágenes más grandes se escalarán). El tamaño máximo del archivo es 100 KB.`,
        TITLE_ICON: 'Favicon',
        SUBTITLE_ICON: `El favicon se muestra en la pestaña del navegador web. Las dimensiones máximas son 32x32 px
            (las imágenes más grandes se escalarán). El tamaño máximo del archivo es 100 KB.`,
        LOGO_LABEL: 'Seleccione imagen de logo',
        ICON_LABEL: 'Seleccione imagen de icono de pestaña',
        ACTIVE_BRAND: 'Habilitar marca',
        SAVE: 'Guardar',
        SAVING: 'Guardando ...',
        CANCEL: 'Cancelar',
        EDIT_SUCCESFULL: 'Marca editada correctamente',
        ERROR_REQUIRED: 'This field is required',
        ERROR_ONLY_NUMBER: 'You must enter just numbers',
        ERROR_PATTERN: 'The value in this field is invalid',
        ERROR_MAX_LENGTH: 'Maximum length {{val}}',
    }
};
