export const locale = {
    lang: 'es',
    support : {
        SUPPORT: 'Soporte',
        CREATE_TITLE: 'Crear ticket',
        REQUESTER: 'Solicitante',
        SUBJECT: 'Asunto',
        PRIORITY: 'Prioridad',
        CCS: 'CCs',
        PLACEHOLDER: 'Direcciones email',
        DESCRIPTION: 'Descripción',
        LOW: 'Baja',
        NORMAL: 'Normal',
        HIGH: 'Alta',
        SAVE: 'Guardar',
        SAVING: 'Guardando ...',
        CANCEL: 'Cancelar',
        SUCCESS: 'Ticket creado correctamente',
        ERROR_REQUIRED: 'Campo requerido',
        ERROR_EMAIL: 'Este campo debe ser una dirección de correo electrónico en el siguiente formato: "user@example.com"',
        MAXLENGTH: 'Tamaño máximo {{val}}'
    }
};
