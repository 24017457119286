export const locale = {
  lang: 'es',
  'actions' : {
      'create': {
        'PROCESS': 'Creando',
        'BD': 'creado',
      },
      'edit': {
        'PROCESS': 'Editando',
        'BD': 'editado',
      },
      'delete': {
        'PROCESS': 'Eliminando',
        'BD': 'eliminado',
      },
      'enable': {
        'PROCESS': 'Habilitando',
        'BD': 'habilitado',
      },
      'disable': {
        'PROCESS': 'Deshabilitando',
        'BD': 'deshabilitado',
      },
      'reset': {
        'PROCESS': 'Restableciendo',
        'BD': 'restablecido(a)',
      },
      'logoff': {
        'PROCESS': 'Desconectando',
        'BD': 'deconectado',
      },
      'unlock': {
        'PROCESS': 'Desbloqueando',
        'BD': 'desbloqueado',
      },
      'reassign': {
        'PROCESS': 'Reasignando',
        'BD': 'reasignado',
      },
      'import': {
        'PROCESS': 'Importando',
        'BD': 'importado',
      },
      'move': {
        'PROCESS': 'Moviendo',
        'BD': 'movido',
      },
      'start' : {
        'PROCESS': 'Iniciando',
        'BD': 'iniciado(a)',
      },
      'restart' : {
        'PROCESS': 'Reiniciando',
        'BD': 'reiniciado(a)',
      },
      'stop' : {
        'PROCESS': 'Deteniendo',
        'BD': 'detenido(a)',
      },
      'redeploy' : {
        'PROCESS': 'Re-desplegando',
        'BD': 're-desplegado(a)',
      },
      'connect' : {
        'PROCESS': 'Conectando',
        'BD': 'conectado(a)',
      },
      'clone': {
        'PROCESS': 'Clonando',
        'BD': 'clonado(a)',
      },
      'download': {
        'PROCESS': 'Descargando',
        'BD': 'descargado(a)',
      },
      'upload': {
        'PROCESS': 'Subiendo',
        'BD': 'subido(a)',
      },
      'restore': {
        'PROCESS': 'Restaurando',
        'BD': 'restaurado(a)',
      },
      'send': {
        'PROCESS': 'Enviando',
        'BD': 'enviado(a)',
      },
    },
  'resources': {
      'USER': 'usuario',
      'USER_MULTI': 'usuarios',
      'COLLECTION': 'coleccion',
      'PASSWORD': 'contraseña para el usuario',
      'PASSWORD_LINK': 'enlace de contraseña para el usuario',
      'PASSWORD_MULTI': 'contraseñas de usuario',
      'PASSWORD_LINK_MULTI': 'enlaces de contraseña para usuarios',
      'FILE': 'desde archivo',
      'AD': 'desde directorio activo',
      'UPTIME' : 'tiempo de actividad',
      'UPTIME_MULTI' : 'tiempos de actividad',
      'IMAGE' : 'imagen',
      'IMAGE_MULTI' : 'imagenes',
      'VPN': 'vpn',
      'VPN_MULTI': 'vpns',
      'SNAPSHOT_PLAN' : 'plan de respaldo',
      'SNAPSHOT' : 'snapshot',
      'FOLDER' : 'carpeta',
      'FOLDER_PERMISSION' : 'permiso de carpeta',
      'FOLDER_PERMISSION_MULTI' : 'permisos de carpeta',
      'MAPPED_DRIVE' : 'unidad mapeada',
      'SHARED' : 'recurso compartido',
      'SECURITYGROUPS' : 'grupo de seguridad',
      'SECURITYGROUPS_MULTI' : ' grupos de seguridad',
      'ADMIN': 'administrador',
      'LICENSE' : 'licencia',
      'APPSETTING' : 'configuracion de aplicacion',
      'APP' : 'aplicacion',
      'ROLE': 'rol',
      'INFRAESTRUCTURE' : 'infraestructura de servidor',
      'INFRAESTRUCTURE_MULTI' : 'infraestructura de servidores',
      'INSTANCE' : 'instancia',
      'INSTANCE_MULTI' : 'instancias',
      'OSPATCH' : 'parche de SO',
      'REGION': 'Región'
    }
};
