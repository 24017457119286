import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'underscore';

let defaultVal;
let message;
export const emptyListValidator = function (defaultValue: string, msg: string, property= null ): ValidationErrors | null {
  defaultVal = defaultValue;
  message = msg;
  return (control: AbstractControl) => {
    let value: String ;
    if (control.value instanceof String) {
       value = control.value || '';
    }
    if (control.value instanceof Object && property ) {
      value = control.value[property] || '';
    }

    if (defaultVal === value || !value ) {
    return {
      required: message
  };
 }

};
};
