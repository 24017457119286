import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import {ModalService} from '../../core/shared/services/modal.service';

import { AutoUnsubscribeComponent } from '../auto-unsubscribe/auto-unsubscribe.component';

@Component({
  selector: 'app-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss']
})
export class WarningModalComponent extends AutoUnsubscribeComponent implements OnInit {
  @Input()
  public bodyMessage: string;
  @Input()
  public bodyMessageCustomClasses: string;
  @Input()
  public bodyMessageText: string;
  @Input()
  public footerMessageText: string;
  @Input()
  public bodyInput: string;
  @Input()
  public isInfo: boolean;
  @Input()
  public hasCancelCallback: boolean;
  @Input()
  public actionButtons: string[];
  @Input()
  public optionSelect: boolean;
  @Input()
  public optionSelected: boolean;
  @Input()
  public optionText: string;
  @Input()
  public htmlTemplate: string;
  @Input()
  public truncateText = true;
  @Input()
  public disabledByOption: boolean;

  public canCloseModal$ = new Subject<boolean>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  public onActionConfirmation = new EventEmitter<any>();
  public actionPressed: boolean;
  public bodyInputModel: any;

  constructor( private modalService: ModalService, private translate: TranslateService) {
    super();
    const canCloseModalSubs = this.canCloseModal$.subscribe((data) => {
      if (data) {
        this.closeWarningModal();
      }
    });
    super.addSubscriptions(canCloseModalSubs);
  }

  // tslint:disable-next-line:member-access
  ngOnInit() {
    let cancelLabel = '';
    if (this.translate.getBrowserLang() === 'es') {
      cancelLabel = 'Cancelar';
    } else {
      cancelLabel = 'Cancel';
    }
    this.actionButtons.unshift(cancelLabel);
    this.actionPressed = false;
    if (!this.optionSelected) {
      this.optionSelected = false;
    }
    if(!this.disabledByOption) {
      this.disabledByOption = false;
    }
  }
  public closeWarningModal(): void {
    this.modalService.dismissAll();
  }
  public actionConfirmation(actionConfirmation): void {
    if (actionConfirmation) {
      this.onActionConfirmation.emit(this.bodyInputModel);
      this.actionPressed = true;
    } else {
      if (this.hasCancelCallback) {
        this.onActionConfirmation.emit('cancel');
      } else {
        this.closeWarningModal();
      }
    }
  }

  public resetOption(): void {
    this.optionSelected = !this.optionSelected;
  }
}
