import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-auto-unsubscribe',
  templateUrl: './auto-unsubscribe.component.html'
})
export class AutoUnsubscribeComponent implements OnDestroy {
  protected subscriptions: Subscription[] = [];

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  protected addSubscriptions(...subs: Subscription[]) {
    this.subscriptions.push(...subs);
  }
}
