import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject} from 'rxjs';
import * as _ from 'underscore';

import { ListDto, SoleTenant } from 'src/app/models';
import { environment } from 'src/environments/environment';
import { OsVersionLicense } from 'src/app/models/deployment/os-version-license';

@Injectable({
  providedIn: 'root'
})
export class ProvisionSoletenantService {

  private soletenantSubject: BehaviorSubject<SoleTenant>;
  public soletenant: Observable<SoleTenant>;

  constructor(private http: HttpClient) { 
    this.soletenantSubject = new BehaviorSubject<SoleTenant>(undefined);
    this.soletenant = this.soletenantSubject.asObservable();
  }

  public getSoleTenantConfiguration(deploymentId: number): Observable<ListDto <SoleTenant>> {
    return this.http.get<any>(`${environment.apiUrl}deployments/${deploymentId}/soleTenants`);
  }
  public getSoletenantLicenseOs(deploymentId: number): Observable<ListDto<OsVersionLicense>> {
    return this.http.get<ListDto<OsVersionLicense>>(`${environment.apiUrl}deployments/${deploymentId}/soleTenantsGroup/licenses`);
  }
  public createSoleTenantConfiguration(deploymentId: number, soletenant: SoleTenant): Observable<SoleTenant> {
    return this.http.post<SoleTenant>(`${environment.apiUrl}deployments/${deploymentId}/soleTenants`, soletenant);
  }
  public updateSoleTenantConfiguration(deploymentId: number, soletenant: SoleTenant ): Observable<SoleTenant> {
    return this.http.put<SoleTenant>(`${environment.apiUrl}deployments/${deploymentId}/soleTenants/${soletenant.soleTenantConfigurationId}`,
     soletenant);
  }
  public updateSoleTenantLicense(deploymentId: number, osVersionLicense: OsVersionLicense ): Observable<OsVersionLicense> {
    return this.http.put<OsVersionLicense>(`${environment.apiUrl}deployments/${deploymentId}/soleTenants/licenses`,
    osVersionLicense);
  }
  public deleteSoleTenantLicense(deploymentId: number, osVersionLicense: OsVersionLicense ): Observable<Object> {
    return this.http.request('DELETE', `${environment.apiUrl}deployments/${deploymentId}/soleTenants/licenses`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: osVersionLicense 
    });    
  }
  public setSoletenant(soletenant: SoleTenant): void {
    this.soletenantSubject.next(soletenant);
  }
}
