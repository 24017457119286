export class AdUserFilter {
    public trustedDomainId: number | null;
    public isTrustedDomain: boolean;
    public ou: string;
    public memberOfGroup: string;
    public customLdapFilter: string;
    public organizationUnits: string;

    constructor(data ? : any) {
        this.isTrustedDomain = data && data.isTrustedDomain ? data.isTrustedDomain : false;
        this.trustedDomainId = data && data.trustedDomainId ? data.trustedDomainId : null;
        this.ou = data && data.ou ? data.ou : '';
        this.memberOfGroup = data && data.memberOfGroup ? data.memberOfGroup : '';
        this.customLdapFilter = data && data.customLdapFilter ? data.customLdapFilter : '';
        this.organizationUnits = data && data.organizationUnits ? data.organizationUnits : '';
    }
}
