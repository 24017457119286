export const locale = {
  lang: 'es',
  addon_subscription : {
    component: {
      USER: 'Usuarios',
      USERS: 'Usuarios',
      SERVER: 'Servidores',
      SERVERS: 'Servidores',
      INTEGRATION: 'Integraciones',
      INTEGRATIONS: 'Integraciones',
      ADMIN: 'Administrador',
      ADMINS: 'Administradores',
      ADMIN_ACCOUNTS: 'Cuentas de administrador',
      DCO: 'Controlador de dominio',
      RDC: 'Controlador de dominio redundante',
      SNA: 'Respaldos',
      SUP: 'Actividad de servidor',
      ALO: 'Audit Logs',
      DIS: 'Discovery',
      NDI: 'Network Discovery',
      INT: 'Integraciones',
      RAP: 'Aplicación remota',
      MUS: 'Servidores de varios usuarios',
      RDS: 'Puerta de enlace RD',
      ATA: 'Tareas automatizadas',
      DMT: 'Herramienta de migración de datos',
      UIN: 'Usage Intelligence',
      VPN: 'Red Privada Virtual',
      DVU: '1:1 User Server',
      SRT: 'Soporte',
      ASC: 'Auto escala',
      GAA: 'Acceso granular a administradores',
      BRK: 'Broker de conexiones de escritorio remoto redundante',
      RGTW: 'Puerta de enlace redundante',
      ADRDS: 'Despliegue AD/RDS',
      VDO: 'Orquestación del escritorio virtual',
      CSM: 'Administración del servidor en la nube',
      OPC: 'Catálogo On-prem',
      UMHD: 'Administración de usuarios y administración de la mesa de ayuda',
      GSSO: 'Inicio de sesión único de Gmail',
      PSUP: 'Soporte telefónico',
      AAC: 'Control de acceso a la aplicación',
      INS: 'Reportes',
      MOB: 'Aplicaciones móviles para iOS y Android',
      EADD: 'Depliegues extendidos de directorio activo',
      MRD: 'Despliegue de múltiples regiones',
      NOD: 'Número de despliegues',
      GWD: 'Despliegue de estasiones de trabajo graficas',
      GBUI: 'Uso del reporte de Facturación GCP',
      SEAT: 'Tipo: Basado en la cantidad'
    },
    CANCEL: 'Cancelar',
    BUY_ADDONS_TITLE: '¡Vaya! Lo sentimos, has alcanzado el límite de {{addon}} para esta suscripción. Por favor, compre para continuar.',
    BUY_ADDONS: 'Comprar addons',
    BUY_NOW: 'Comprar ahora',
    UPDATING: 'Actualizando...',
    SUCCESS: '¡Enhorabuena! Se ha actualizado la suscripción del plan',
    INFO_ADDONS_TITLE: `La suscripción ha alcanzado la cantidad permitida {{addon}}. Comuníquese con el propietario de su cuenta {{ownerEmail}}
      <a target='_blank'
        href='http://helpcenter.itopia.com/en/articles/778993-update-your-itopia-subscription'>
        para actualizar su suscripción.
      </a>`,
    EACH: 'cada uno(a)'
  }
};
