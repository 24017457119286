export class Permission {
    public id: string;
    public name: string;
    public module: string;
    public allow: boolean;
    constructor(data?: any) {
      this.id = data && data.id ? data.id  : '';
      this.name = data && data.name ? data.name : '';
      this.module = data && data.module ? data.module : '';
      this.allow = data && data.allow ? true : false;
    }
}
