import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import * as _ from 'underscore';

import { NotificationActions } from 'src/app/core/shared/notifications/enum/notifications.actions';
import { NotificationResources } from 'src/app/core/shared/notifications/enum/notifications.resources';
import { NotificationTranslateService } from 'src/app/core/shared/notifications/notification-translate.service';
import { HttpStatusCodes, ServerError, ValidationErrorsCodes } from 'src/app/models';

import {ModalService} from '../../core/shared/services/modal.service';
import { Deployment } from '../../models/deployment/deployment';
import { AutoUnsubscribeComponent } from '../auto-unsubscribe/auto-unsubscribe.component';
import { AuthenticationService } from './../../core/security/authentication.service';
import { DeploymentsService } from './../../deployments/deployments.service';
import { ResetPasswordService } from './reset-password.service';

import { locale as english } from './i18n/reset-password.en';
import { locale as spanish } from './i18n/reset-password.es';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends AutoUnsubscribeComponent implements OnInit {
  @Input()
  public userSelected: any[];
  @Input()
  public allClients: boolean;
  @Input()
  public isAdmin: boolean;

  public resetPassForm: FormGroup;
  public submitted: boolean;
  public deploymentSelected: Deployment;
  public loading: boolean;
  public reseating: string;
  public resetTooltip: string;
  public successMsg: string;
  public actionsButtons: any;
  public hide: boolean;
  public errorRequired: string;
  public adminId: string;
  public title: string;
  public errorOnReset: boolean;

  @ViewChild('ref', {static: false}) public ref: any;

  constructor(private formBuilder: FormBuilder, private deploymentsMenuService: DeploymentsService,
              private resetPasswordService: ResetPasswordService,  private modalService: ModalService,
              private notifierTranslate: NotificationTranslateService, private translate: TranslateService,
              private authenticationService: AuthenticationService, private notifierService: NotifierService) {
    super();
    const deploymentSelectedSubscription = this.deploymentsMenuService.deploymentSelected.subscribe(deploymentSelected => {
     if (deploymentSelected && deploymentSelected.companyId) {
        this.deploymentSelected = deploymentSelected;
     }
    });
    super.addSubscriptions(deploymentSelectedSubscription);
  }

  public ngOnInit() {
    this.resetPassForm = this.formBuilder.group({
      password: ['', Validators.required]
    });
    this.errorOnReset = false;
    this.setTranslations();
    this.loading = false;
    this.authenticationService.currentUser.subscribe((currenUser: any) => {
      if (currenUser && currenUser.current) {
        this.adminId = currenUser.current.id;
      }
    });
  }
  // convenience getter for easy access to form fields
  get f() { return this.resetPassForm.controls; }

  // tslint:disable-next-line:member-access
  onSubmit() {
    this.submitted = true;
    if (this.resetPassForm.invalid) {
      return;
    }
    this.loading = true;
    const userSelectedIds = _.pluck(this.userSelected, 'cloudUserId');
    if (!this.isAdmin) {
      const data = {
        deploymentId: this.deploymentSelected.companyId,
        cloudUserId: userSelectedIds[0],
        passwordModel: {password: this.f.password.value, email: this.userSelected[0].email}
      };
      const resetUserPasswordSubs = this.resetPasswordService.resetUserPassword(data).subscribe((response) => {
        this.resetPasswordSuccess();
      }, (error) => {
        this.errorOnReset = true;
        this.resetPasswordError(error);
      });
      super.addSubscriptions(resetUserPasswordSubs);
    } else {
      const companyIds = _.pluck(this.userSelected, 'companyId');
      const data = {
        adminId: this.adminId,
        companiesId: companyIds,
        password: this.f.password.value,
        allClients: this.allClients
      };
      const resetAdminPasswordSubs = this.resetPasswordService.resetAdminPassword(data).subscribe((response) => {
        this.resetPasswordSuccess();
      }, (error) => {
        this.resetPasswordError(error);
      });
      super.addSubscriptions(resetAdminPasswordSubs);
    }

  }

  public closeResetPasswordModal(): void {
    this.submitted = false;
    this.modalService.dismissAll();
  }
  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
      this.reseating = 'reseating';
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
    this.title = this.translate.instant('reset_password.RESET');
    this.resetTooltip = this.translate.instant('reset_password.RESET_TOOLTIP');
    this.successMsg = this.translate.instant('reset_password.RESET_SUCCESS');
    this.actionsButtons = [this.errorOnReset === false ? this.translate.instant('reset_password.CANCEL') : this.translate.instant('reset_password.CLOSE'),
                           this.translate.instant('reset_password.SAVE'),
                           this.translate.instant('reset_password.SAVING')];
    this.errorRequired = this.translate.instant('reset_password.ERROR_REQUIRED');
  }
  private resetPasswordSuccess(): void {
    this.notifierTranslate.success(NotificationActions.reset,NotificationResources.password, (this.userSelected[0].username ?  this.userSelected[0].username : '') ,true);
    this.closeResetPasswordModal();
  }
  private resetPasswordError(errorResponse): void {
    const serverError = new ServerError(errorResponse.error);
    if (serverError.status === HttpStatusCodes.ValidationError) {
      const validationErrors = serverError.errors;
      Object.keys(validationErrors).forEach(prop => {
        this.resetPassForm.get(prop).setErrors({ serverError: validationErrors[prop] });
      });
    } else if (serverError.status === HttpStatusCodes.NotFound &&
      serverError.code === ValidationErrorsCodes.ValidationError40434) {
        this.resetPassForm.get('password').setErrors({ serverError: serverError.message });
        return;
    }  else {
      if (serverError.status === HttpStatusCodes.BadRequest &&
        serverError.code === ValidationErrorsCodes.ValidationError40029) {
          this.notifierService.notify('error', serverError.message);
      }
      this.errorOnReset = true;
      this.setTranslations();
    }
    this.loading = false;
    this.submitted = false;
  }
  public onActionConfirmation(confirm) {
    if (confirm) {
      this.submitted = true;
      this.onSubmit();
    } else {
      this.closeResetPasswordModal();
    }
  }
  @HostListener('mouseleave', ['$event'])
  public onHoverOutside() {
    this.ref.close();
   }

  public setErrorOnReset(value: string): void {
    if (value.length > 0) {
      this.errorOnReset = false;
    }
  }
}
