import { Injectable } from '@angular/core';
import { throwError } from 'rxjs/internal/observable/throwError';
import * as _ from 'underscore';

import { environment } from 'src/environments/environment';
import { GmanagmentService } from './gmanagment.service';

declare const gapi: any;

@Injectable({
  providedIn: 'root'
})
export class GnetappService {
  public service = 'cloudvolumesgcp-api.netapp.com';

  constructor(private gmanagmentService: GmanagmentService) { }

  public loadGNetApp(): void {
    try {
      return gapi.client.load('servicemanagement', environment.gapi.apiVersion);
    } catch (exception) {
      throwError(exception);
      return;
    }
  }
  public hasEnabled(consumer): Promise<any> {
    return this.gmanagmentService.listServices(consumer)
      .then((servicesList) => {
        let founded = false;
        if (servicesList.services) {
          founded = _.some(servicesList.services, (serv: any) => {
            return serv.serviceName === this.service;
          });
        }
        if (founded) {
          return Promise.resolve(true);
        } else {
          return Promise.reject(false);
        }
      })
      .catch((reason) => {
        return Promise.reject(reason);
      });
  }
  public activate(consumer): Promise<any> {
    const params = {
      serviceName: this.service,
      consumerId: consumer
    };
    const request = gapi.client.servicemanagement.services.enable(params);
    return request.then((response) => {
      return Promise.resolve(response.result);
    }).catch((reason) => {
      reason.request = {
        name: 'netapp',
        params: params,
        errors: reason.errors
      };
      return Promise.reject(reason);
    });
  }
}
