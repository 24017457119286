export const locale = {
  lang: 'en',
  metrics : {
    METRIC_ERROR: 'Oops! The action wasn\'t executed. Please check the following reasons:',
    METRIC_MESSAGE_YOUR : 'Your ',
    METRIC_MESSAGE_FOR : 'for ',
    METRIC_MESSAGE_1_IP : 'GCP IP quota ',
    METRIC_MESSAGE_1_GPU: 'Google GPU quota ',
    METRIC_MESSAGE_1_CPU: 'Google CPU quota ',
    METRIC_MESSAGE_1_SSD_DISK: 'Google Disk SSD quota ',
    METRIC_MESSAGE_1_STANDARD_DISK: 'Google Disk Standard quota ',
    METRIC_MESSAGE_NOT_ENOUGH: 'is not enough.',
    METRIC_FOLLOW: 'Please follow ',
    METRIC_GUIDE_IP_QUOTA: 'the guide to increase IP quotas.',
    METRIC_GUIDE_GPU_QUOTA: 'the guide to increase GPU quotas.',
    METRIC_GUIDE_CPU_QUOTA: 'the guide to increase CPU quotas.',
    METRIC_GUIDE_SSD_DISK_QUOTA: 'the guide to increase Disk SSD quotas.',
    METRIC_GUIDE_STANDARD_DISK_QUOTA: 'the guide to increase Disk Standard quotas.',
    INCREASE: 'Increase',
    AVAILABLE: 'available',
    NEEDED: 'needed',
  }
};
