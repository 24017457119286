import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

import { AdUserFilter } from 'src/app/models';

@Injectable({
    providedIn: 'root'
})
export class MultiselectGroupsAdActionService {

    public adFilterUpdated$: BehaviorSubject<AdUserFilter>;

    constructor() {
        this.adFilterUpdated$ = new BehaviorSubject<AdUserFilter>(null);
    }

    public setAdFilter(adFilter: AdUserFilter) {
        this.adFilterUpdated$.next(adFilter);
    }
}
