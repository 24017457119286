export const locale = {
  lang: 'es',
  'apis_check_manager': {
    'GCompute': 'Compute Engine API',
    'GBilling': 'Google Cloud Billing API',
    'GDeployment': 'Deployment Manager API',
    'GBigquery': 'BigQuery API',
    'GManageAd': 'Servicio administrado de Google para el Directorio Activo de Microsoft',
    'GNetworking': 'API de Servicio de Red',
    'GSqlAdmin': 'API de Cloud SQL',
    'GNetApp': 'Servicio de volúmenes en la nube de NetApp (CVS)',
    'GOsConfig': 'API de configuración del SO',
    'GContainerAnalysis' : 'API de análisis de contenedores',
    'API_NOT_LOAD': 'La api no pudo cargarse'
  }
};
