import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { SubscriptionCanceledComponent } from './subscription-canceled.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SubscriptionCanceledComponent],
  exports: [SubscriptionCanceledComponent],
  providers: [CurrencyPipe],
})
export class SubscriptionCanceledModule {}
