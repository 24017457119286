import {IncidentUpdatesDashboard} from './incident-updates-dashboard';
export class IncidentDashboard {
  public created_at: string;
  public id:string;
  public impact: string;
  public incident_updates: IncidentUpdatesDashboard[];
  public monitoring_at: string;
  public name: string;
  public page_id: string;
  public resolved_at: string;
  public shortlink: string;
  public status: string;
  public updated_at: string;
}
