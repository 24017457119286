import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxFileDropModule } from 'ngx-file-drop';
import { UiSwitchModule } from 'ngx-toggle-switch';

import { ClipboardModule } from 'ngx-clipboard';
import { DirectivesModule } from 'src/app/core/shared/directives/directives.module';
import { SectionLayoutModule } from 'src/app/shared/section-layout/section-layout.module';
import { ViewsLayoutModule } from '../../shared/views-layout/views-layout.module';
import {ConfigureRegionPostProvisionModule} from '../configure-region-post-provision/configure-region-post-provision.module';
import { FormButtonsV2Module } from '../form-buttons-v2/form-buttons-v2.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { FormButtonsModule } from './../form-buttons/form-buttons.module';
import { RegionCertificateComponent } from './region-certificate/region-certificate.component';
import { RegionsPostProvisionListComponent } from './regions-post-provision-list/regions-post-provision-list.component';
import { RegionsPostProvisionManageComponent } from './regions-post-provision-manage/regions-post-provision-manage.component';

@NgModule({
  declarations: [
    RegionsPostProvisionListComponent, RegionCertificateComponent, RegionsPostProvisionManageComponent
  ],
  imports: [
    CommonModule, NgbModule, TranslateModule, ReactiveFormsModule, DirectivesModule, FormsModule, SectionLayoutModule,
    SpinnerModule, NgSelectModule, NgxFileDropModule, UiSwitchModule, FormButtonsModule, ClipboardModule, ViewsLayoutModule,
    FormButtonsV2Module, ConfigureRegionPostProvisionModule
  ],
  exports: [ RegionsPostProvisionListComponent, RegionCertificateComponent]
})
export class RegionsPostProvisionModule { }
