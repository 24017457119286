export class Profile {
    public id: string;
    public email: string;
    public firstName: string;
    public lastName: string;
    public phoneNumber: string;
    public phoneNumberExt: number;
    public roleSecurityId: number;
    public emailNotificationList: Array<string>;
}
