import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { FormButtonsComponent } from './form-buttons.component';

@NgModule({
    imports: [CommonModule, NgbModule, TranslateModule, ReactiveFormsModule],
    declarations: [FormButtonsComponent],
    exports: [FormButtonsComponent]
})
export class FormButtonsModule {
}
