import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-view-layout',
  templateUrl: './list-view-layout.component.html',
  styleUrls: ['./list-view-layout.component.scss']
})
export class ListViewLayoutComponent implements OnInit {
  @Input()
  public title: string;
  @Input()
  public subtitle: string;
  @Input()
  public showAsSubsection: boolean;
  @Input()
  public hideSearch: boolean;
  constructor() { }

  public ngOnInit() {
  }

}
