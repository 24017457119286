export const locale = {
  lang: 'en',
  'subscription_canceled' : {
    SUBSCRIPTION_INVALID: 'Unfortunately, your subscription has been canceled on {{cancelDate}}. If you need CAS to manage your environment in the cloud, please click on REACTIVATE and follow the steps.',
    SUBSCRIPTION_CANCELED_TITLE: '{{user}}, your itopia subscription has been canceled',
    SUBSCRIPTION_INVALID_VIEWER: 'Unfortunately, your subscription has been canceled on {{cancelDate}}. Please contact your account owner in order to continue using CAS.',
    REACTIVATE: 'Reactivate',
    REACTIVATING: 'Reactivating ...',
    WHAT_HAPPEN: 'What\'s going to happen?',
    REACTIVATE_SUBSCRIPTION_MSG1: 'Your subscription will be activated immediately.',
    REACTIVATE_SUBSCRIPTION_MSG2: 'You will be charged {{price}} for {{name}}.',
    REACTIVATE_SUBSCRIPTION_MSG3: 'The customer\'s billing date will be reset to today.',
  }
};
