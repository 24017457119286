import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { environment } from '../../../environments/environment';
import { BinaryExpressionService } from './../../core/shared/filters/binary-expression.service';
import { OperationExpressionService } from './../../core/shared/filters/operation-expression.service';
import { UnaryExpressionService } from './../../core/shared/filters/unary-expression.service';
@Injectable({
  providedIn: 'root'
})
export class SidebarListService {

  constructor(private http: HttpClient, private unaryExpression: UnaryExpressionService,
              private operationExpression: OperationExpressionService, private binaryExpression: BinaryExpressionService) {
  }
  public searchTasks(organizationId: number): Observable<any> {
    const search = {
      pageIndex: 1,
      pageSize: 200,
      filter: [ {'propertyName': 'OrganizationId', 'operation': this.operationExpression.expressionType.EQUALS,
                 'value': organizationId, 'toLower': 'true',
                 'unaryOperation': this.unaryExpression.expressionType.NONE, 'binaryOperation': this.binaryExpression.expressionType.AND},
                { 'propertyName': 'TaskStatusId', 'operation': this.operationExpression.expressionType.EQUALS, 'value': '2',
                  'toLower': 'true', 'unaryOperation': this.unaryExpression.expressionType.NOT,
                  'binaryOperation': this.binaryExpression.expressionType.NONE },
                { 'propertyName': 'TaskStatusId', 'operation': this.operationExpression.expressionType.EQUALS, 'value': '3',
                  'toLower': 'true', 'unaryOperation': this.unaryExpression.expressionType.NOT,
                  'binaryOperation': this.binaryExpression.expressionType.NONE },
                { 'propertyName': 'TaskStatusId', 'operation': this.operationExpression.expressionType.EQUALS, 'value': '4',
                  'toLower': 'true', 'unaryOperation': this.unaryExpression.expressionType.NOT,
                  'binaryOperation': this.binaryExpression.expressionType.NONE },
                { 'propertyName': 'TaskStatusId', 'operation': this.operationExpression.expressionType.EQUALS, 'value': '6',
                  'toLower': 'true', 'unaryOperation': this.unaryExpression.expressionType.NOT,
                  'binaryOperation': this.binaryExpression.expressionType.NONE },
                { 'propertyName': 'TaskStatusId', 'operation': this.operationExpression.expressionType.EQUALS, 'value': '7',
                  'toLower': 'true', 'unaryOperation': this.unaryExpression.expressionType.NOT,
                  'binaryOperation': this.binaryExpression.expressionType.NONE }
              ] };
    return this.http.post<any>(`${environment.apiUrl}tasks/search`, search);
  }
}
