export const locale = {
  lang: 'en',
  'configure_region' : {
    'REGION_LABEL': 'GCP region',
    'SUBNET_LABEL': 'Subnet',
    'SUBNETS_LABEL': 'Subnets',
    'EXTERNAL_DNS_NAME_LABEL': 'External DNS Name',
    'ADD_REGION': 'Add Region',
    'ERROR_REQUIRED': 'This field is required',
    'ERROR_PATTERN': 'The value in this field is invalid',
    'SECTION_COLLECTION_TITLE': 'Collection Pools',
    'ZONES_LABELS': 'Manually Select Zones',
    'ZONES_DESCRIPTION': `If disabled, CAS will automatically pick two zones in the region for
                          deployment resources`,
    'ZONE1_LABEL': 'Zone1',
    'ZONE2_LABEL': 'Zone2',
    'SOLETENANT_LABEL': 'Use sole-tenant in this region',
    'SOLETENANT_DESCRIPTION': `If sole-tenant node support is enabled in the deployment,
                              provide the default node group for VMs in this region.
                              Changing this setting does not affect any existing VMs, only new VMS.`,
    'ADVANCED_SETINGS': 'Advanced Settings',
    'CANCEL': 'Cancel',
    'ADD': 'Add',
    'ADDING': 'Adding ...',
    'REFRESH': 'Refresh',
    'INVALID_ZONE': 'Invalid Zone',
    'MSG_SUCCESS_ADD_REGION': 'The region has been saved successfully.',
    'MSG_SUCCESS_ADD_REGION_PARTIALLY': 'The region has been partially saved.',
    'ERROR_ONCLOUD': 'Oops! An error has ocurred. Please contact support.',
  }
};

