import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

const four = 4;
const textNode = 3;
@Directive({
  selector: '[appVolumeStatus]'
})
export class VolumeStatusDirective implements OnInit, OnChanges {
  @Input()
  public volume: any;
  @Input()
  public renderStatus: boolean;
  @Input()
  public cloudVolumeStatus: string;

  constructor(private el: ElementRef, private renderer: Renderer2, @Inject(DOCUMENT) private document) { }
  public ngOnInit() {
    this.getVolumeStatus(this.volume, this.renderStatus);
  }
  public ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.renderStatus) {
      this.renderStatus = changes.renderStatus.currentValue;
      this.getVolumeStatus(this.volume, this.renderStatus);
    }
    if (changes && changes.volume) {
      this.volume = changes.volume.currentValue;
      this.getVolumeStatus(this.volume, this.renderStatus);
    }
  }
  private getVolumeStatus(volume: any, renderStatus: boolean): any {
    const child = document.createElement('span');
    const childElements = this.el.nativeElement.childNodes;
    for (const children of childElements) {
        if (children.nodeType !== textNode && children.id !== 'copy') {
          this.renderer.removeChild(this.el.nativeElement, children);
        }
      }
    if (!renderStatus) {
      child.innerHTML = `<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="loading m-r-2">
      <circle cx="50" cy="50" r="45"/>
    </svg>`;
    } else {
      switch (volume.cloudVolumeStatus.toLowerCase()) {
        case 'online':
          child.className = 'fas fa-circle text-success m-r-2';
          break;
        case 'failed':
          child.className = 'fas fa-circle text-danger m-r-2';
          break;
        case 'not on cloud':
          child.className = 'far fa-circle m-r-2';
          break;
        case 'locked':
        case 'disable':
          child.className = 'fas fa-circle c-yellow m-r-2';
          break;
        case 'offline':
          if (volume.companyVolumeStatusId === four) {
            child.className = 'fas fa-circle c-orange m-r-2';
            break;
          } else {
            child.className = 'fas fa-circle c-gray m-r-2';
            break;
          }
        case 'disconnected':
          child.className = 'fas fa-circle c-gray m-r-2';
          break;
        case 'on cloud':
        case 'listo':
        case 'ready':
          child.className = 'fas fa-circle text-success m-r-2';
          break;
        case 'not available':
          child.className = 'far fa-circle';
          const newChild = document.createElement('i');
          newChild.className = 'mdi mdi-refresh c-pointer m-l-5 m-r-2';
          this.renderer.appendChild(child, newChild);
          break;
        default:
          child.className = 'fas fa-circle c-orange m-r-2';
          break;
      }
      child.setAttribute('title', this.cloudVolumeStatus);
    }
    this.renderer.insertBefore(this.el.nativeElement, child, this.el.nativeElement.firstChild);
  }
}
