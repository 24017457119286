import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { timer } from 'rxjs';

import { NotifierService } from 'angular-notifier';
import * as timezone from 'moment-timezone';
import { finalize, map } from 'rxjs/operators';

import { AutoUnsubscribeComponent } from 'src/app/shared/auto-unsubscribe/auto-unsubscribe.component';
import {environment} from '../../../environments/environment';
import { ModalActionsEnum, ProcessStatusEnum } from '../enum';
import { ProcessActivity, RetryController } from '../model';
import { ProcessViewerService } from '../process-viewer.service';

import { locale as english } from '../i18n/process-viewer.en';
import { locale as spanish } from '../i18n/process-viewer.es';

const time = 1000;
@Component({
  selector: 'app-process-viewer-details',
  templateUrl: './process-viewer-details.component.html',
  styleUrls: ['./process-viewer-details.component.scss']
})
export class ProcessViewerDetailsComponent extends AutoUnsubscribeComponent implements OnInit {

  @Input()
  public deep: number;
  @Input()
  public deploymentId: number;
  @Input()
  public proccessId: string;
  @Input()
  public tz: string;
  @Input()
  public modal: NgbModalRef;

  @Output()
  public optionSelected = new EventEmitter<ModalActionsEnum>();

  public loadedDetails: boolean;
  public titleModal : string;
  public details: ProcessActivity;
  public translation: object;
  public initialLoaded: boolean;
  public isRefresh: boolean;
  public retrying: boolean;
  public optionCancel : ModalActionsEnum;
  public showList: boolean;
  public isExecChild: boolean;
  public statusError: ProcessStatusEnum;
  public tzDescription: string;

  constructor(private translate: TranslateService,
              private processViewerService: ProcessViewerService, private notifierService: NotifierService) {
    super();
  }

  public ngOnInit() {
    this.setTranslations();
    this.statusError = ProcessStatusEnum.Error;
    this.optionCancel = ModalActionsEnum.Cancel;
    this.getDetails();
    this.optionCancel = ModalActionsEnum.Cancel;
    this.showList = null;
    this.isExecChild = false;
    timer(time).subscribe(() => {
      this.isExecChild = true;
    });
    const offset = timezone.tz(Date.now(), this.tz).format('Z z');
    this.tzDescription =  this.translation['timeZoneDescription'] + ' (' + offset + ') ' + this.tz;
  }

  public getDetails(): void {
    this.loadedDetails = false;
    const sub = this.processViewerService.getProcessActivity(this.deploymentId, this.proccessId)
    .pipe(
      map((data: ProcessActivity) => {
        this.initialLoaded = true;
        if (data.isHTML) {
           data.details = this.parseDetails(data.details);
        }
        this.details = data;
        this.mapData();
      }),
      finalize(() => {
        this.loadedDetails = true;
     })
    ).subscribe();
    super.addSubscriptions(sub);
  }

  public selectOption(option: ModalActionsEnum): void {
    this.optionSelected.emit(option);
  }

  public parseDetails( html: string ): string {
    const arraySplit = html.split('<a', 1);
    if (arraySplit.length > 0) {
      const firstStr = arraySplit[0];
      const secondSplit = firstStr.split('<small>');
      if ( secondSplit.length > 1) {
          return secondSplit[1];
      }
    }
    return html;
  }

  public refreshData(): void {
      this.isRefresh = true;
      this.showList = null;
      this.getDetails();
      timer().subscribe(() => {
        this.isRefresh = false;
      });
  }

  public isShowRetry(): boolean {
    return this.details && this.details.resource && this.details.status === ProcessStatusEnum.Error &&
           (this.details.taskId > 0 || this.details.entityId > 0);
  }

  public retry(): void {
    if ( this.isShowRetry() ) {
      const retryController = new RetryController({
        entityId: this.details.entityId,
        resource: this.details.resource
      });
      const request = this.details.taskId > 0 && !this.details.entityId ? this.processViewerService.retryTaskFailed(this.details.taskId) :
                                                this.processViewerService.retryProcessFailed(this.deploymentId, retryController);
      this.retrying = true;
      const sub = request.pipe(
        map((data: boolean) => {
            if (data) {
            this.notifierService.notify('success', this.translation['retryingMsg']);
            timer(time).subscribe(() => {
                  this.selectOption(ModalActionsEnum.Retry);
              });
            }
        }),
        finalize(() => {
          this.retrying = false;
       })
      ).subscribe();
      super.addSubscriptions(sub);
    }
  }

  public haveChildren($event): void {
    this.showList = $event;
  }

  private mapData(): void {
    if (this.tz) {
      if (this.details.startAt) {
        this.details.startAt =  moment(this.details.startAt).tz(this.tz).format('MMMM Do YYYY, h:mm:ss a');
      }
      if (this.details.endAt) {
        this.details.endAt =  moment(this.details.endAt).tz(this.tz).format('MMMM Do YYYY, h:mm:ss a');
      }
   }
    if ( this.details.status) {
      this.details.status =  this.getStatusTranslate(this.details.status);
    }
  }

  private getStatusTranslate(status: string): string {
    this.setTranslations();
    let title = '';
    switch (status) {
      case ProcessStatusEnum.Completed:
        title = this.translate.instant('process_viewer.COMPLETED');
        break;
      case ProcessStatusEnum.InProgress:
          title = this.translate.instant('process_viewer.PROGRESS');
          break;
      case ProcessStatusEnum.Error:
          title = this.translate.instant('process_viewer.ERROR');
          break;
    }
    return title;
 }

  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
    this.translation = {
      task: this.translate.instant('process_viewer.TASK'),
      target: this.translate.instant('process_viewer.TARGET'),
      status: this.translate.instant('process_viewer.STATUS'),
      startTime: this.translate.instant('process_viewer.START_TIME'),
      initiatedBy: this.translate.instant('process_viewer.INITIATED_BY'),
      taskId:  this.translate.instant('process_viewer.TASK_ID'),
      title: this.translate.instant('process_viewer.DETAILS_TITLE'),
      completionTime: this.translate.instant('process_viewer.COMPLETION_TIME'),
      detailsTask: this.translate.instant('process_viewer.DETAILS'),
      close: this.translate.instant('process_viewer.CLOSE'),
      retry: this.translate.instant('process_viewer.RETRY'),
      refresh: this.translate.instant('process_viewer.REFRESH'),
      retrying: this.translate.instant('process_viewer.RETRYING'),
      retryingMsg: this.translate.instant('process_viewer.RETRYING_MSG'),
      timeZoneDescription: this.translate.instant('process_viewer.TIMEZONE_DESCRIPTION')
    };
  }
}
