import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';

import { locale as english } from './i18n/api-status.en';
import { locale as spanish } from './i18n/api-status.es';

@Component({
  selector: 'app-api-status',
  templateUrl: './api-status.component.html',
  styleUrls: ['./api-status.component.scss']
})
export class ApiStatusComponent implements OnInit, OnChanges {
  @Input()
  public isEnabledApi: boolean;
  @Input()
  public apiClass: string;
  @Input()
  public apiTitle: string;
  @Input()
  public apiError: string;
  @Input()
  public apiUrl: string;
  @Input()
  public checkMethod: string;
  @Input()
  public enableManually: boolean;
  @Input()
  public openUrl: boolean;

  @Output()
  public enableApi = new EventEmitter<boolean>();
  @Output()
  public refreshApi = new EventEmitter<string>();

  public pressOnlyEnable: boolean;
  public translation: object;

  constructor(private translate: TranslateService) { }

  public ngOnInit() {
    this.pressOnlyEnable = false;
    this.setTranslations();
  }
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.isEnabledApi && changes.isEnabledApi.currentValue) {
      this.isEnabledApi = changes.isEnabledApi.currentValue;
      this.apiError = !this.isEnabledApi ? undefined : this.apiError;
      this.pressOnlyEnable = false;
    }
    if (changes.apiError && changes.apiError.currentValue) {
      this.apiError = changes.apiError.currentValue;
    }
    if (changes.apiUrl && changes.apiUrl.currentValue) {
      this.apiUrl = changes.apiUrl.currentValue;
    }
    if (changes.checkMethod && changes.checkMethod.currentValue) {
      this.checkMethod = changes.checkMethod.currentValue;
    }
    if (changes.openUrl && changes.openUrl.currentValue) {
        this.linkToUrl();
    }
  }

  public enableApiAction(): void {
    if ( !this.enableManually ) {
      this.enableApi.emit(true);
    } else {
      this.pressOnlyEnable = true;
      this.linkToUrl();
    }
  }

  public refresh(): void {
    this.refreshApi.emit(this.checkMethod);
  }

  public linkToUrl(): void {
    if (this.apiUrl) {
      window.open(this.apiUrl, '_blank');
    }
  }

  private setTranslations(): void {
    if (this.translate.getBrowserLang() === 'es') {
      this.translate.use('es');
      this.translate.setTranslation('es', spanish);
    } else {
      this.translate.use('en');
      this.translate.setTranslation('en', english);
    }
    this.translation = {
      refreshText :  this.translate.instant('apis_status.REFRESH'),
      enableText: this.translate.instant('apis_status.ENABLE')
    };
  }
}
