export class FeatureValidate {
  public allow: boolean;
  public reasson: string;
  public countexceeds: number;
  public plan: string;
  public feature: string;
  public planTypeId: number;
  public addon: string;
  public billingProviderTypeCode: string;
  public price: number;
  public frequency: number;
  public payment: boolean;

  constructor(data?: any) {
    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          this[key] = data[key];
        }
      }
    }
  }
}
