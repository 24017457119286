export enum HttpStatusCodes  {
    BadRequest = 400,
    Forbidden = 401,
    Unauthorize = 403,
    NotFound = 404,
    ValidationError = 407,
    UnprocessableEntity = 422,
    Warning = 207,
    InternalServerError = 500
}
