export class ProServices {
  public firstName: string;
  public lastName: string;
  public email: string;
  public phone: string;
  public hours: number;
  public description: string;

  constructor(data?: any) {
    this.firstName = data && data.firstName ? data.firstName  : '';
    this.lastName = data && data.lastName ? data.lastName : '';
    this.email = data && data.email ? data.email : '';
    this.phone = data && data.phone ? data.phone : '';
    this.description = data && data.description ? data.description : '';
    this.hours = data && data.hours ? data.hours : 0;
  }
}

